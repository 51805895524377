import { useEffect } from "react";
import useAxiosPrivate from "./useAxiosPrivate";
import { useDispatch, useSelector } from "react-redux";

import {  getSRTableDetails } from "../slice/srSlice";

const useSRTableDetails = () => {

  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();
  const getAllSRTableDetails = async (param) => {

      const response = await Axios.post("/sr_call_table_details",param);
      const data = await response.data;
      dispatch(getSRTableDetails(data.body));
    }
      
  // useEffect(() => {
  //   getAllSRTableDetails();
  // }, []);

  return getAllSRTableDetails;
};

export default useSRTableDetails;
