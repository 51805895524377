import React, { useState } from "react";
import SchemeManagementTable from "./SchemeManagementTable";
import SchemeDropdown from "./SchemeDropdown";
import { Stack } from "@mui/material";
import Sidebar from "../Sidebar";
import StampTab from "../PerformanceManagement/StampTab";

const SchemeManagement = () => {
  const [dropDown, setdropDown] = useState({});

  return (
    <Stack sx={{ display: "flex", flexDirection: "row", backgroundColor:"#E5E5E5" }}>
      <Sidebar />
      <Stack sx={{ display: "flex", flexDirection: "column", gap: "1rem",width:"100vw",padding:"1rem" }}>
        <SchemeDropdown setdropDown={setdropDown}/>
        <SchemeManagementTable dropDown={dropDown}/>
      </Stack>
    </Stack>
  );
};

export default SchemeManagement;
