import React, { useState, useEffect } from "react";
import styles from "./styles/UserMgtUpdateForm.module.css";
import CreateIcon from "@mui/icons-material/Create";
import { Paper, TextField } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Button from "@mui/material/Button";
import { withStyles, makeStyles } from "@mui/styles";
import { styled } from "@mui/material/styles";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import CallOutlinedIcon from "@mui/icons-material/CallOutlined";
import LandscapeOutlinedIcon from "@mui/icons-material/LandscapeOutlined";
import RoomOutlinedIcon from "@mui/icons-material/RoomOutlined";
import DirectionsWalkOutlinedIcon from "@mui/icons-material/DirectionsWalkOutlined";
import { useSelector, useDispatch } from "react-redux";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
// import {
//   RetailerUpdateUserMgt,
//   UpdateUserMgt,
// } from "../../../actions/UserMgt/UserMgt";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BaseImgUrl, ShopBaseImgUrl } from "../../utility/BaseURL";
import EditLocationAltOutlinedIcon from "@mui/icons-material/EditLocationAltOutlined";
import { TableconvertDate } from "../Functions/function";
import useRetailerList from "../../hooks/useRetailerList";
import useUpdateRetailer from "../../hooks/useUpdateRetailer";

// const theme = createTheme({
//     overrides:{
//         MuiFormControl: {
//             root: {
//               height: '56px',
//             },
//           },
//           MuiInputBase: {
//             root: {
//               height: '36px',
//             },
//           },
//     }
// });

const useStyles = makeStyles((theme) => ({
  TextField: {
    color: "black",
  },
}));

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const RedButton = styled(Button)(({ theme }) => ({

}));

const GrayButton = styled(Button)(({ theme }) => ({

}));

const UserMgtUpdateForm = ({ zone }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [token, setToken] = useState("");
  const [form, setForm] = useState({});
  const [formError, setFormError] = useState({});
  //   const list = useSelector((state) => state.UserMgt.retailerlist);
  const Firsttoken = useSelector((state) => state.Token);
  //   const detail = useSelector((state) => state.UserMgt.retailerdetail);
  const [ZoneList, setZoneList] = useState(["Mumbai", "Bangalore"]);
  const [Zone, setZone] = React.useState("");
  const [edit, setEdit] = useState(false);
  const [RoleList, setRoleList] = useState(["asm", "se", "tsi"]);
  const [Role, setRole] = React.useState("");

  const retailerDetails = useSelector(
    (store) => store.retailer.retailerUserDetails
  );

  const [StatusList, setStatusList] = useState([
    "Active",
    "Inactive",
  ]);
  const [Status, setStatus] = React.useState("");

  useEffect(() => {
    setForm(retailerDetails);
  }, [retailerDetails]);

  const [Updatesuccess, setUpdatesuccess] = useState(false);
  const [obj, setObj] = useState({
    failed: false,
    message: "",
  });
  const [invalidToken, setInvalidToken] = useState(false);

  //   React.useEffect(() => {
  //     if (detail && detail !== "undefined") {
  //       setForm(detail);
  //       setZone(detail.zone_name);
  //       setRole(detail.role_name);
  //       setStatus(detail.status);
  //     }
  //   }, [detail]);

  React.useEffect(() => {
    if (
      Firsttoken &&
      Firsttoken.token &&
      Firsttoken.token !== null &&
      Firsttoken.token.length !== 0 &&
      Firsttoken !== "undefined"
    ) {
      setToken(Firsttoken.token);
    }
  }, [Firsttoken]);

  const IsValid = () => {
    let success = true;
    let error = {};

    // console.log(form);

    if (!form.zone_name.trim()) {
      error.zone = "Zone cannot be empty";
      success = false;
    }

    if (!form.name.trim()) {
      error.name = "name cannot be empty";
      success = false;
    }
    if (!form.surname.trim()) {
      error.surname = "surname cannot be empty";
      success = false;
    }
    // if (!form.mobile_no?.toString().trim()) {
    //     error.mobileno = "mobileno cannot be empty";
    //     success = false;
    // }
    // else if (!(/^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[6789]\d{9}$/g).test(form.mobile_no)) {
    //     error.mobileno = "Enter a Valid mobileno Number";
    //     success = false
    // }
    // if (!form.whatsapp_no?.toString().trim()) {
    //     error.whatsappno = "whatsappno cannot be empty";
    //     success = false;
    // }
    // else if (!(/^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[6789]\d{9}$/g).test(form.whatsapp_no)) {
    //     error.whatsappno = "Enter a Valid whatsapp Number";
    //     success = false
    // }

    if (!form.address.trim()) {
      error.address = "address cannot be empty";
      success = false;
    }
    if (!form.lati?.toString().trim()) {
      error.latitude = "latitude cannot be empty";
      success = false;
    }
    if (!form.longi.toString().trim()) {
      error.longitude = "longitude cannot be empty";
      success = false;
    }
    // if (!form.routeid.trim()) {
    //     error.routeid = "routeid cannot be empty";
    //     success = false;
    // }
    // if (!form.timestamp.trim()) {
    //     error.timestamp = "timestamp cannot be empty";
    //     success = false;
    // }

    setFormError(error);
    return success;
  };

  const handleZoneChange = (e) => {
    setZone(e.target.textContent);
    setForm({
      ...form,
      zone_name: e.target.textContent,
    });
  };

  const handleRoleChange = (e) => {
    setRole(e.target.textContent);
    setForm({
      ...form,
      role_name: e.target.textContent,
    });
  };

  const handleStatusChange = (e) => {
    setStatus(e.target.value);
    setForm({
      ...form,
      status: e.target.value,
    });
  };

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  let param = {
    admin_id: "1",
    retailer_id: form?.user_id,
    route_id: form?.route_id,
    name: form?.name,
    surname: form?.surname,
    mobile_no: form?.mobile_no,
    whatsapp_no: form?.whatsapp_no,
    address: form?.address,
    landmark: form?.landmark,
    shop_name: form?.shop_name,
    capacity: form?.capacity,
    village_id: form?.village_id,
    status: Status,
  };

  // console.log(param, "param", form);

  //   const handleUpdate = async (e) => {
  //     e.preventDefault();
  //     if (IsValid()) {
  //       const data = await dispatch(RetailerUpdateUserMgt(token, param, form));
  //       console.log(data, "data");

  //       if (data.code === 200) {
  //         console.log("here");
  //         toast.success("SuccessFully Updated Retailer");
  //         setEdit(false);
  //       } else {
  //         toast.error(data.message);
  //       }
  //     }
  //   };
  const getretailers = useRetailerList();
  const updateRetailrs = useUpdateRetailer(form);
  const handleSubmit = async(e) => {
    e.preventDefault();
   await updateRetailrs();
   await getretailers();
  };

  const handleCancel = () => {
    setEdit(false);
    // if (detail && detail !== "undefined") {
    //   setForm(detail);
    //   setZone(detail.zone_name);
    //   setRole(detail.role_name);
    //   setStatus(detail.status);
    // }
  };

  React.useEffect(() => {
    if (invalidToken) {
      toast.warning("Invalid Token");
      setInvalidToken(false);
    }
  }, [invalidToken]);

  const handleEdit = () => {
    setEdit(!edit);
  };

  // console.log(Status, form);
  return (
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <form>
        <div id="UserMgtRetailerDetail">
          <div
            className={styles.UserMgtUpdateFormContainer}
            style={{ gap: "1.5rem" }}
          >
            {/* {list && list.length !== 0 ? ( */}
            <>
              <div className={styles.UserMgtUpdateFormContainerOne}>
                {/* <div className={styles.UserMgtUpdateFormContainerSectionOne}>
                                    <div className={styles.UserMgtUpdateFormSectionOneTitleContainer}>
                                        <label className={styles.UserMgtUpdateFormSectionOneTitle}>User Details</label>
                                    </div>
   
                                </div> */}

                <div className={styles.UserMgtUpdateFormContainerSectionOneM}>
                  <div
                    className={styles.UserMgtUpdateFormSectionOneTitleContainer}
                  >
                    <label className={styles.UserMgtUpdateFormSectionOneTitle}>
                      {retailerDetails?.shop_name}
                    </label>
                  </div>
                  {!edit ? (
                    <div
                      className={
                        styles.UserMgtUpdateFormSectionOneEditContainer
                      }
                      onClick={handleEdit}
                    >
                      <CreateIcon
                        className={styles.UserMgtUpdateFormSectionOneEditIcon}
                      />
                    </div>
                  ) : null}
                </div>

                <div className={styles.UserMgtUpdateFormContainerSectionTwo}>
                  <div className={styles.UserMgtUpdateFormTwoSubSectionOne}>
                    <div
                      className={styles.UserMgtUpdateFormTwoSubSectionOneList}
                    >
                      <div
                        className={
                          styles.UserMgtUpdateFormTwoSubSectionOneListOne
                        }
                      >
                        <label
                          className={
                            styles.UserMgtUpdateFormTwoSubSectionOneListLabel
                          }
                        >
                          Zone
                        </label>
                      </div>
                      <div
                        className={
                          styles.UserMgtUpdateFormTwoSubSectionOneListTwo
                        }
                      >
                        <input
                          style={{
                            height: "1.8rem",
                            background: "white",
                            border: "1px solid rgba(0, 0, 0, 0.2)",
                          }}
                          variant="outlined"
                          size="small"
                          disabled={!edit}
                          value={retailerDetails?.zone_name}
                          className={
                            styles.UserMgtUpdateFormTwoSubSectionOneListField
                          }
                        />

                        {formError.zone && (
                          <span
                            className={
                              styles.UserMgtUpdateFormTwoSubSectionOneListTwoErrorLabel
                            }
                          >
                            {formError.zone}
                          </span>
                        )}
                      </div>
                    </div>

                    <div
                      className={styles.UserMgtUpdateFormTwoSubSectionOneList}
                    >
                      <div
                        className={
                          styles.UserMgtUpdateFormTwoSubSectionOneListOne
                        }
                      >
                        <label
                          className={
                            styles.UserMgtUpdateFormTwoSubSectionOneListLabel
                          }
                        >
                          Retailer ID
                        </label>
                      </div>
                      <div
                        className={
                          styles.UserMgtUpdateFormTwoSubSectionOneListTwo
                        }
                      >
                        <input
                          style={{
                            height: "1.8rem",
                            background: "white",
                            border: "1px solid rgba(0, 0, 0, 0.2)",
                          }}
                          variant="outlined"
                          size="small"
                          disabled={!edit}
                          value={retailerDetails?.retailer_id}
                          className={
                            styles.UserMgtUpdateFormTwoSubSectionOneListField
                          }
                        />
                        {formError.userid && (
                          <span
                            className={
                              styles.UserMgtUpdateFormTwoSubSectionOneListTwoErrorLabel
                            }
                          >
                            {formError.userid}
                          </span>
                        )}
                      </div>
                    </div>

                    <div
                      className={styles.UserMgtUpdateFormTwoSubSectionOneList}
                    >
                      <div
                        className={
                          styles.UserMgtUpdateFormTwoSubSectionOneListOne
                        }
                      >
                        <label
                          className={
                            styles.UserMgtUpdateFormTwoSubSectionOneListLabel
                          }
                        >
                          Role
                        </label>
                      </div>
                      <div
                        className={
                          styles.UserMgtUpdateFormTwoSubSectionOneListTwo
                        }
                      >
                        <input
                          style={{
                            height: "1.8rem",
                            background: "white",
                            border: "1px solid rgba(0, 0, 0, 0.2)",
                          }}
                          variant="outlined"
                          size="small"
                          disabled={!edit}
                          value="Retailer"
                          className={
                            styles.UserMgtUpdateFormTwoSubSectionOneListField
                          }
                        />

                        {formError.role && (
                          <span
                            className={
                              styles.UserMgtUpdateFormTwoSubSectionOneListTwoErrorLabel
                            }
                          >
                            {formError.role}
                          </span>
                        )}
                      </div>
                    </div>

                    <div
                      className={styles.UserMgtUpdateFormTwoSubSectionOneList}
                    >
                      <div
                        className={
                          styles.UserMgtUpdateFormTwoSubSectionOneListOne
                        }
                      >
                        <label
                          className={
                            styles.UserMgtUpdateFormTwoSubSectionOneListLabel
                          }
                        >
                         Name
                        </label>
                      </div>
                      <div
                        className={
                          styles.UserMgtUpdateFormTwoSubSectionOneListTwo
                        }
                      >
                        <input
                          style={{
                            height: "1.8rem",
                            background: "white",
                            border: "1px solid rgba(0, 0, 0, 0.2)",
                          }}
                          variant="outlined"
                          size="small"
                          disabled={!edit}
                          value={form?.first_name}
                          name="first_name"
                          className={
                            styles.UserMgtUpdateFormTwoSubSectionOneListField
                          }
                          onChange={handleChange}
                        />
                        {formError.name && (
                          <span
                            className={
                              styles.UserMgtUpdateFormTwoSubSectionOneListTwoErrorLabel
                            }
                          >
                            {formError.name}
                          </span>
                        )}
                      </div>
                    </div>

                    <div
                      className={styles.UserMgtUpdateFormTwoSubSectionOneList}
                    >
                      <div
                        className={
                          styles.UserMgtUpdateFormTwoSubSectionOneListOne
                        }
                      >
                        <label
                          className={
                            styles.UserMgtUpdateFormTwoSubSectionOneListLabel
                          }
                        >
                          Retailer code
                        </label>
                      </div>
                      <div
                        className={
                          styles.UserMgtUpdateFormTwoSubSectionOneListTwo
                        }
                      >
                        <input
                          style={{
                            height: "1.8rem",
                            background: "white",
                            border: "1px solid rgba(0, 0, 0, 0.2)",
                          }}
                          variant="outlined"
                          
                          size="small"
                          value={form?.retailer_code}
                          disabled="true"
                          name="retailer_code"
                          className={
                            styles.UserMgtUpdateFormTwoSubSectionOneListField
                          }
                          onChange={handleChange}
                        />
                        {formError.surname && (
                          <span
                            className={
                              styles.UserMgtUpdateFormTwoSubSectionOneListTwoErrorLabel
                            }
                          >
                            {formError.surname}
                          </span>
                        )}
                      </div>
                    </div>

                    <div
                      className={styles.UserMgtUpdateFormTwoSubSectionOneList}
                    >
                      <div
                        className={
                          styles.UserMgtUpdateFormTwoSubSectionOneListOne
                        }
                      >
                        <label
                          className={
                            styles.UserMgtUpdateFormTwoSubSectionOneListLabel
                          }
                        >
                          Mobile No
                        </label>
                      </div>
                      <div
                        className={
                          styles.UserMgtUpdateFormTwoSubSectionOneListTwo
                        }
                      >
                        <input
                          style={{
                            height: "1.8rem",
                            background: "white",
                            border: "1px solid rgba(0, 0, 0, 0.2)",
                          }}
                          variant="outlined"
                          size="small"
                          value={retailerDetails?.mobile_no}
                          disabled={!edit}
                          name="mobile_no"
                          onChange={handleChange}
                          className={
                            styles.UserMgtUpdateFormTwoSubSectionOneListField
                          }
                        />
                        {formError.mobileno && (
                          <span
                            className={
                              styles.UserMgtUpdateFormTwoSubSectionOneListTwoErrorLabel
                            }
                          >
                            {formError.mobileno}
                          </span>
                        )}
                      </div>
                    </div>

                    <div
                      className={styles.UserMgtUpdateFormTwoSubSectionOneList}
                    >
                      <div
                        className={
                          styles.UserMgtUpdateFormTwoSubSectionOneListOne
                        }
                      >
                        <label
                          className={
                            styles.UserMgtUpdateFormTwoSubSectionOneListLabel
                          }
                        >
                          Whatsapp ID
                        </label>
                      </div>
                      <div
                        className={
                          styles.UserMgtUpdateFormTwoSubSectionOneListTwo
                        }
                      >
                        <input
                          style={{
                            height: "1.8rem",
                            background: "white",
                            border: "1px solid rgba(0, 0, 0, 0.2)",
                          }}
                          variant="outlined"
                          size="small"
                          value={form?.whatsapp_no}
                          disabled={!edit}
                          name="whatsapp_no"
                          onChange={handleChange}
                          className={
                            styles.UserMgtUpdateFormTwoSubSectionOneListField
                          }
                        />
                        {formError.whatsappno && (
                          <span
                            className={
                              styles.UserMgtUpdateFormTwoSubSectionOneListTwoErrorLabel
                            }
                          >
                            {formError.whatsappno}
                          </span>
                        )}
                      </div>
                    </div>

                    <div
                      className={styles.UserMgtUpdateFormTwoSubSectionOneList}
                    >
                      <div
                        className={
                          styles.UserMgtUpdateFormTwoSubSectionOneListOne
                        }
                      >
                        <label
                          className={
                            styles.UserMgtUpdateFormTwoSubSectionOneListLabel
                          }
                        >
                          onboarded by
                        </label>
                      </div>
                      <div
                        className={
                          styles.UserMgtUpdateFormTwoSubSectionOneListTwo
                        }
                      >
                        <input
                          style={{
                            height: "1.8rem",
                            background: "white",
                            border: "1px solid rgba(0, 0, 0, 0.2)",
                          }}
                          variant="outlined"
                          size="small"
                          value={form?.onboarded_by}
                          disabled="true"
                          name="onboarded_by"
                          onChange={handleChange}
                          className={
                            styles.UserMgtUpdateFormTwoSubSectionOneListField
                          }
                        />
                        {formError.whatsappno && (
                          <span
                            className={
                              styles.UserMgtUpdateFormTwoSubSectionOneListTwoErrorLabel
                            }
                          >
                            {formError.whatsappno}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className={styles.UserMgtUpdateFormTwoSubSectionTwo}>
                    <div
                      className={styles.UserMgtUpdateFormTwoSubSectionOneList}
                    >
                      <div
                        className={
                          styles.UserMgtUpdateFormTwoSubSectionOneListOne
                        }
                      >
                        <label
                          className={
                            styles.UserMgtUpdateFormTwoSubSectionOneListLabel
                          }
                        >
                          Address
                        </label>
                      </div>
                      <div
                        className={
                          styles.UserMgtUpdateFormTwoSubSectionOneListTwo
                        }
                      >
                        <input
                          style={{
                            height: "1.8rem",
                            background: "white",
                            border: "1px solid rgba(0, 0, 0, 0.2)",
                          }}
                          variant="outlined"
                          size="small"
                          value={form?.address}
                          disabled={!edit}
                          multiline
                          rows={3}
                          name="address"
                          onChange={handleChange}
                          className={
                            styles.UserMgtUpdateFormTwoSubSectionOneListField
                          }
                        />
                        {formError.address && (
                          <span
                            className={
                              styles.UserMgtUpdateFormTwoSubSectionOneListTwoErrorLabel
                            }
                          >
                            {formError.address}
                          </span>
                        )}
                      </div>
                    </div>

                    {/* {zone.toLowerCase() !== "raipur" ? ( */}
                    <>
                      <div
                        className={styles.UserMgtUpdateFormTwoSubSectionOneList}
                      >
                        <div
                          className={
                            styles.UserMgtUpdateFormTwoSubSectionOneListOne
                          }
                        >
                          <label
                            className={
                              styles.UserMgtUpdateFormTwoSubSectionOneListLabel
                            }
                          >
                            Latitude
                          </label>
                        </div>
                        <div
                          className={
                            styles.UserMgtUpdateFormTwoSubSectionOneListTwo
                          }
                        >
                          <input
                            style={{
                              height: "1.8rem",
                              background: "white",
                              border: "1px solid rgba(0, 0, 0, 0.2)",
                            }}
                            variant="outlined"
                            size="small"
                            value={retailerDetails?.lat}
                            disabled={!edit}
                            className={
                              styles.UserMgtUpdateFormTwoSubSectionOneListField
                            }
                          />
                          {formError.latitude && (
                            <span
                              className={
                                styles.UserMgtUpdateFormTwoSubSectionOneListTwoErrorLabel
                              }
                            >
                              {formError.latitude}
                            </span>
                          )}
                        </div>
                      </div>

                      <div
                        className={styles.UserMgtUpdateFormTwoSubSectionOneList}
                      >
                        <div
                          className={
                            styles.UserMgtUpdateFormTwoSubSectionOneListOne
                          }
                        >
                          <label
                            className={
                              styles.UserMgtUpdateFormTwoSubSectionOneListLabel
                            }
                          >
                            Longitude
                          </label>
                        </div>
                        <div
                          className={
                            styles.UserMgtUpdateFormTwoSubSectionOneListTwo
                          }
                        >
                          <input
                            style={{
                              height: "1.8rem",
                              background: "white",
                              border: "1px solid rgba(0, 0, 0, 0.2)",
                            }}
                            variant="outlined"
                            size="small"
                            disabled={!edit}
                            value={retailerDetails?.lng}
                            className={
                              styles.UserMgtUpdateFormTwoSubSectionOneListField
                            }
                          />
                          {formError.longitude && (
                            <span
                              className={
                                styles.UserMgtUpdateFormTwoSubSectionOneListTwoErrorLabel
                              }
                            >
                              {formError.longitude}
                            </span>
                          )}
                        </div>
                      </div>
                    </>
                    {/* ) : null} */}

                    <div
                      className={styles.UserMgtUpdateFormTwoSubSectionOneList}
                    >
                      <div
                        className={
                          styles.UserMgtUpdateFormTwoSubSectionOneListOne
                        }
                      >
                        <label
                          className={
                            styles.UserMgtUpdateFormTwoSubSectionOneListLabel
                          }
                        >
                          status
                        </label>
                      </div>
                      {!edit ? (
                        <div
                          className={
                            styles.UserMgtUpdateFormTwoSubSectionOneListTwo
                          }
                        >
                          <input
                            style={{
                              height: "1.8rem",
                              background: "white",
                              border: "1px solid rgba(0, 0, 0, 0.2)",
                            }}
                            variant="outlined"
                            size="small"
                            disabled={!edit}
                            value={
                              retailerDetails?.status === true
                                ? "active"
                                : "inactive"
                            }
                            className={
                              styles.UserMgtUpdateFormTwoSubSectionOneListField
                            }
                          />
                        </div>
                      ) : (
                        <div
                          className={
                            styles.UserMgtUpdateFormTwoSubSectionOneListTwo
                          }
                        >
                          <Box sx={{ minWidth: 120, maxWidth: "100%" }}>
                            <FormControl fullWidth>
                              <Select
                                value={
                                  form?.status === true ? "Active" : form?.status === false ? "Inactive" : form?.status
                                }
                                onChange={handleStatusChange}
                                // size="small"
                                sx={{ height: "1.8rem" }}
                              >
                                {StatusList &&
                                  StatusList.length !== 0 &&
                                  StatusList !== "undefined" &&
                                  StatusList !== null &&
                                  StatusList.map((item, index) => (
                                    <MenuItem sx={{display:"flex !important", flexDirection:"column !important", gap:"5px !important", padding:"4px !important"}} value={item}>{item}</MenuItem>
                                  ))}
                              </Select>
                            </FormControl>
                          </Box>
                        </div>
                      )}
                    </div>

                    {/* <div
                      className={styles.UserMgtUpdateFormTwoSubSectionOneList}
                    >
                      <div
                        className={
                          styles.UserMgtUpdateFormTwoSubSectionOneListOne
                        }
                      >
                        <label
                          className={
                            styles.UserMgtUpdateFormTwoSubSectionOneListLabel
                          }
                        >
                          Route ID
                        </label>
                      </div>
                      <div
                        className={
                          styles.UserMgtUpdateFormTwoSubSectionOneListTwo
                        }
                      >
                        <input
                          style={{
                            height: "1.8rem",
                            background: "white",
                            border: "1px solid rgba(0, 0, 0, 0.2)",
                          }}
                          variant="outlined"
                          size="small"
                          value={form?.route_id}
                          disabled={!edit}
                          className={
                            styles.UserMgtUpdateFormTwoSubSectionOneListField
                          }
                        />
                        {formError.routeid && (
                          <span
                            className={
                              styles.UserMgtUpdateFormTwoSubSectionOneListTwoErrorLabel
                            }
                          >
                            {formError.routeid}
                          </span>
                        )}
                      </div>
                    </div> */}

                    <div
                      className={styles.UserMgtUpdateFormTwoSubSectionOneList}
                    >
                      <div
                        className={
                          styles.UserMgtUpdateFormTwoSubSectionOneListOne
                        }
                      >
                        <label
                          className={
                            styles.UserMgtUpdateFormTwoSubSectionOneListLabel
                          }
                        >
                          TimeStamp
                        </label>
                      </div>
                      <div
                        className={
                          styles.UserMgtUpdateFormTwoSubSectionOneListTwo
                        }
                      >
                        <input
                          style={{
                            height: "1.8rem",
                            background: "white",
                            border: "1px solid rgba(0, 0, 0, 0.2)",
                          }}
                          variant="outlined"
                          size="small"
                          value={TableconvertDate(form?.created_at)}
                          disabled={!edit}
                          className={
                            styles.UserMgtUpdateFormTwoSubSectionOneListField
                          }
                        />
                        {formError.timestamp && (
                          <span
                            className={
                              styles.UserMgtUpdateFormTwoSubSectionOneListTwoErrorLabel
                            }
                          >
                            {formError.timestamp}
                          </span>
                        )}
                      </div>
                    </div>

                    <div
                      className={styles.UserMgtUpdateFormTwoSubSectionOneList}
                    >
                    <div
                        className={
                          styles.UserMgtUpdateFormTwoSubSectionOneListOne
                        }
                      >
                        <label
                          className={
                            styles.UserMgtUpdateFormTwoSubSectionOneListLabel
                          }
                        >
                          Credit limit
                        </label>
                      </div>
                      <div
                        className={
                          styles.UserMgtUpdateFormTwoSubSectionOneListTwo
                        }
                      >
                        <input
                          style={{
                            height: "1.8rem",
                            background: "white",
                            border: "1px solid rgba(0, 0, 0, 0.2)",
                          }}
                          variant="outlined"
                          size="small"
                          name="credit_limit"
                          value={form?.credit_limit ?? ""}
                          disabled={!edit}
                          className={
                            styles.UserMgtUpdateFormTwoSubSectionOneListField
                          }
                          onChange={handleChange}
                        />
                        {formError.timestamp && (
                          <span
                            className={
                              styles.UserMgtUpdateFormTwoSubSectionOneListTwoErrorLabel
                            }
                          >
                            {formError.timestamp}
                          </span>
                        )}
                      </div>
                      </div>

                      <div
                      className={styles.UserMgtUpdateFormTwoSubSectionOneList}
                    >
                    <div
                        className={
                          styles.UserMgtUpdateFormTwoSubSectionOneListOne
                        }
                      >
                        <label
                          className={
                            styles.UserMgtUpdateFormTwoSubSectionOneListLabel
                          }
                        >
                          Credit days
                        </label>
                      </div>
                      <div
                        className={
                          styles.UserMgtUpdateFormTwoSubSectionOneListTwo
                        }
                      >
                        <input
                          style={{
                            height: "1.8rem",
                            background: "white",
                            border: "1px solid rgba(0, 0, 0, 0.2)",
                          }}
                          variant="outlined"
                          size="small"
                          name="credit_days"
                          value={form?.credit_days ?? ""}
                          disabled={!edit}
                          onChange={handleChange}
                          className={
                            styles.UserMgtUpdateFormTwoSubSectionOneListField
                          }
                        />
                        {formError.timestamp && (
                          <span
                            className={
                              styles.UserMgtUpdateFormTwoSubSectionOneListTwoErrorLabel
                            }
                          >
                            {formError.timestamp}
                          </span>
                        )}
                      </div>
                      </div>

                      <div
                      className={styles.UserMgtUpdateFormTwoSubSectionOneList}
                    >
                      <div
                        className={
                          styles.UserMgtUpdateFormTwoSubSectionOneListOne
                        }
                      >
                        <label
                          className={
                            styles.UserMgtUpdateFormTwoSubSectionOneListLabel
                          }
                        >
                          Beat Name
                        </label>
                      </div>
                      <div
                        className={
                          styles.UserMgtUpdateFormTwoSubSectionOneListTwo
                        }
                      >
                        <input
                          style={{
                            height: "1.8rem",
                            background: "white",
                            border: "1px solid rgba(0, 0, 0, 0.2)",
                          }}
                          variant="outlined"
                          size="small"
                          value={form?.route_names}
                          disabled={!edit}
                          name="route_names"
                          // onChange={handleChange}
                          className={
                            styles.UserMgtUpdateFormTwoSubSectionOneListField
                          }
                        />
                        {formError.whatsappno && (
                          <span
                            className={
                              styles.UserMgtUpdateFormTwoSubSectionOneListTwoErrorLabel
                            }
                          >
                            {formError.whatsappno}
                          </span>
                        )}
                      </div>
                    </div>
                    </div>
                  </div>
                </div>

              <Paper sx={{ flex: "0.9", padding: "5px" ,boxShadow:"none" }}>
                <div className={styles.UserMgtUpdateFormContainerTwo}>
                  <div
                    className={
                      styles.UserMgtUpdateFormContainerTwoFirmContainer
                    }
                  >
                    <img
                      src={
                        form &&
                        form.shop_image &&
                        form.shop_image !== "undefined" &&
                        form.shop_image !== null
                          ? form?.shop_image
                          : "https://i.guim.co.uk/img/media/24b25c23b97e37af3892230202b98b8ac20a10e0/0_31_5550_3330/master/5550.jpg?width=445&quality=45&auto=format&fit=max&dpr=2&s=389ec7167a0686c3ed084d7487c5be2c"
                      }
                      alt="firm"
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignContent: "center",
                      marginTop: "-60px",
                    }}
                  >
                    <div
                      className={
                        styles.UserMgtUpdateFormContainerTwoProfileContainer
                      }
                    >
                      <img
                        className={styles.UserMgtUpdateFormContainerTwoProfile}
                        src={
                          form &&
                          form.profile_image &&
                          form.profile_image !== "undefined" &&
                          form.profile_image !== null
                            ? form?.profile_image
                            : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSEpWWQrAJpIR6Xy7FhzhCT00vzSmT7xw9S2Q&usqp=CAU"
                        }
                        alt="prof"
                      />
                    </div>
                  </div>

                  <div
                    className={
                      styles.UserMgtUpdateFormContainerTwoListContainer
                    }
                  >
                    <div className={styles.UserMgtUpdateFormContainerTwoList}>
                      <div
                        className={styles.UserMgtUpdateFormContainerTwoListOne}
                      >
                        <span
                          className={
                            styles.UserMgtUpdateFormContainerTwoListOneIconContainer
                          }
                        >
                          <PersonOutlineIcon
                            className={
                              styles.UserMgtUpdateFormContainerTwoListOneIcon
                            }
                          />
                        </span>
                        <label>Name</label>
                      </div>
                      <div
                        className={styles.UserMgtUpdateFormContainerTwoListTwo}
                      >
                        {retailerDetails?.first_name}
                        {retailerDetails?.surname}
                      </div>
                    </div>

                    <div className={styles.UserMgtUpdateFormContainerTwoList}>
                      <div
                        className={styles.UserMgtUpdateFormContainerTwoListOne}
                      >
                        <span
                          className={
                            styles.UserMgtUpdateFormContainerTwoListOneIconContainer
                          }
                        >
                          <CallOutlinedIcon
                            className={
                              styles.UserMgtUpdateFormContainerTwoListOneIcon
                            }
                          />
                        </span>
                        <label>Mobile</label>
                      </div>
                      <div
                        className={styles.UserMgtUpdateFormContainerTwoListTwo}
                      >
                        {retailerDetails?.mobile_no}
                      </div>
                    </div>

                    <div className={styles.UserMgtUpdateFormContainerTwoList}>
                      <div
                        className={styles.UserMgtUpdateFormContainerTwoListOne}
                      >
                        <span
                          className={
                            styles.UserMgtUpdateFormContainerTwoListOneIconContainer
                          }
                        >
                          <LandscapeOutlinedIcon
                            className={
                              styles.UserMgtUpdateFormContainerTwoListOneIcon
                            }
                          />
                        </span>
                        <label>zone</label>
                      </div>
                      <div
                        className={styles.UserMgtUpdateFormContainerTwoListTwo}
                      >
                        {retailerDetails?.zone_name}
                      </div>
                    </div>

                    <div className={styles.UserMgtUpdateFormContainerTwoList}>
                      <div
                        className={styles.UserMgtUpdateFormContainerTwoListOne}
                      >
                        <span
                          className={
                            styles.UserMgtUpdateFormContainerTwoListOneIconContainer
                          }
                        >
                          <RoomOutlinedIcon
                            className={
                              styles.UserMgtUpdateFormContainerTwoListOneIcon
                            }
                          />
                        </span>
                        <label>Address</label>
                      </div>
                      <div
                        className={styles.UserMgtUpdateFormContainerTwoListTwo}
                      >
                        {retailerDetails?.address}
                      </div>
                    </div>

                    {/* <div className={styles.UserMgtUpdateFormContainerTwoList}>
                      <div
                        className={styles.UserMgtUpdateFormContainerTwoListOne}
                      >
                        <span
                          className={
                            styles.UserMgtUpdateFormContainerTwoListOneIconContainer
                          }
                        >
                          <EditLocationAltOutlinedIcon
                            className={
                              styles.UserMgtUpdateFormContainerTwoListOneIcon
                            }
                          />
                        </span>
                        <label>Landmark</label>
                      </div>
                      <div
                        className={styles.UserMgtUpdateFormContainerTwoListTwo}
                      >
                        {form.landmark}
                      </div>
                    </div> */}
                  </div>
                </div>
              </Paper>
            </>
            {/* ) : (
              <label>No Data</label>
            )} */}
          </div>
          {edit ? (
            <div
              style={{ display: "flex", justifyContent: "center", gap: "10px" }}
            >
              <RedButton onClick={handleSubmit} type="submit" sx={{ height: "1.8rem" }}>
                Update
              </RedButton>
              <GrayButton onClick={handleCancel} sx={{ height: "1.8rem" }}>
                Cancel
              </GrayButton>
            </div>
          ) : null}
        </div>
      </form>
    </>
  );
};

export default UserMgtUpdateForm;
