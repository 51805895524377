import React, { useEffect, useState } from "react";
import {
  Stack,
  Box,
  Typography,
  Card,
  ButtonBase,
  Switch,
  TextField,
} from "@mui/material";
import Radio from "@mui/material/Radio";
import { useSelector, useDispatch } from "react-redux";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import MapPolygon from "./MapPolygon";
import Divider from "@mui/material/Divider";
import Checkbox from "@mui/material/Checkbox";
import useAssignPincode from "../../hooks/useAssignPincode";
import { ToastContainer } from "react-toastify";
import CloseIcon from '@mui/icons-material/Close';
import {
  setDistId,
  setSeIdList,
  resetSeId,
  setAsmIdList,
  setSoIdList,
  setScId,
  resetData,
} from "../../slice/assignSEslice";
import {
  setSeId,
  setFrequency,
  setSoId,
  setAsmId,
  setDistIds,
  setClusteringStatus,
  setSuperCategoryId,
} from "../../slice/assignRouteSlice";
import { selectAssignSEData } from "../../slice/assignSEslice";
import { selectAssignRoute } from "../../slice/assignRouteSlice";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import {

  resetReqBoundryDetails,
  getAdminMapDetails,
  getRouteList,
} from "../../slice/adminMapSlice";
import Swal from "sweetalert2";
import { styled } from "@mui/material/styles";

import { FormGroup } from "@mui/material";
import Sidebar from "../Sidebar";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import useCreateRoute from "../../hooks/useCreateRoute";
import Select from "@mui/material/Select";
import "./assets/mapStyle.css";
import useDeployRoute from "../../hooks/useDeployRoute";
import LinearProgress from "@mui/material/LinearProgress";
import { TailSpin } from "react-loader-spinner";
import useRedeployRoute from "../../hooks/useRedeployRoute";
import useReqBoundryDetails from "../../hooks/useRequestedBoundryData";
import useAssgnedUserData from "../../hooks/useAssignedUserData";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import useSuperCategoryList from "../../hooks/useSuperCategoryList";
import useSMList from "../../hooks/useSMList";
import useSOList from "../../hooks/useSOList";
import usePincodeData from "../../hooks/useGetPincodeAssign";
import useAdminMap from "../../hooks/useAdminMap";
import useRouteList from "../../hooks/useRouteList";
import useAssignedRoute from "../../hooks/useAssignedRoute";
import useUpdateSFStatus from "../../hooks/useUpdateSFStatus";
import RetailerPopup from "./RetailerPopUp";
import useUpdateShapeFileName from "../../hooks/useUpdateShapeFileName";
import { useNavigate } from "react-router";

const DashboardContent = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: "flex",
    "&:active": {
      "& .MuiSwitch-thumb": {
        width: 12,
      },
      "& .MuiSwitch-switchBase.Mui-checked": {
        transform: "translateX(9px)",
      },
    },
    "& .MuiSwitch-switchBase": {
      padding: 2,
      "&.Mui-checked": {
        transform: "translateX(12px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor:
            theme.palette.mode === "dark" ? "#177ddc" : "#009C19",
        },
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
      width: 12,
      height: 12,
      borderRadius: 6,
      transition: theme.transitions.create(["width"], {
        duration: 200,
      }),
    },
    "& .MuiSwitch-track": {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor:
        theme.palette.mode === "dark"
          ? "rgba(255,255,255,.35)"
          : "rgba(0,0,0,.25)",
      boxSizing: "border-box",
    },
  }));
  const [day, setDay] = useState("");
  const [SFStatus, setSFStatus] = useState(false);
  const [currentFreq, setCurrentFreq] = useState("");
  const [reDeplyInfo, setReDeployInfo] = useState();
  const [showReqData, setShowReqData] = useState(false);
  const [selectedSeUserIds, setSelectedSeUserIds] = useState([]);
  const [selectedDistIds, setSelectedDistIds] = useState([]);
  const [distributersListCount, setDistributersListCount] = useState(0);
  const [sellerListCount, setSellerListCount] = useState(0);
  const [ASMCount, setASMCount] = useState(0);
  const [SOCount, setSOCount] = useState(0);
  const [selectedDistCode, setSelectedDistCode] = useState([]);
  const [selectedSEs, setSelectedSEs] = useState([]);
  const [value, setValue] = useState("1");
  const [bcIdList, setBcIdList] = useState([]);
  const [showDeployButton, setShowDeployButton] = useState(false);
  const [showDailyRoute, setShowDailyRoute] = useState(true);
  const [showRouteDate, setShowRouteDate] = useState(false);
  const [assignedRoutes, setAssignedRoutes] = useState("");
  const [selectedSCId, setSelectedSCId] = useState(2);
  const [filteredData, setFilteredData] = useState({
    distributers: [],
    ASM: [],
    SO: [],
    SE: [],
  });
  const [popupOpen, setPopupOpen] = useState(false);

  const [switchState, setSwitchState] = useState(false);

  const [selectedAsms, setSelectedAsms] = useState({ asmId: [], asmCode: [] });
  const [selectedSos, setSelectedSos] = useState({ soId: [], soCode: [] });
  ///// For Route
  const [SelectedRouteSE, setSelectedRouteSE] = useState([]);
  const [SelectedFreq, setSelectedFreq] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [textValue, setTextValue] = useState("");

  const adminMap = useSelector((state) => state.adminMap.adminMapDetails);
  const superCategory = useSelector(
    (state) => state.superCategory.superCategoryList
  );
  const polyDetails = useSelector((state) => state.adminMap.getPincodeDetails);
  const assignSEData = useSelector(selectAssignSEData);
  const assignRoute = useSelector(selectAssignRoute);
  const assignRouteDetails = useSelector(
    (state) => state.adminMap.getRouteClusterDetails
  );

  const assignedBoundryRoutes = useSelector(
    (state) => state.adminMap.getAssignedBoundryDetails
  );
  const assignedBoundryDetails = useSelector(
    (state) => state.adminMap.selectedPincodeDetails
  );
  const zoneData = useSelector((state) => state.app.curretDropdownData);
  const ASMList = useSelector((state) => state.sm.SMList);
  const SOList = useSelector((state) => state.so.SOList);
  const RouteUserData = useSelector((state) => state.adminMap.assignedUserData);

  const getSCList = useSuperCategoryList();
  const [expanded, setExpanded] = useState(false);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const updateSFName = useUpdateShapeFileName();
  const handleSFNameChange = (event) => {
    setTextValue(event.target.value);
  };
  const handleSaveClick = () => {
    setIsEditing(false);
    const param = {
      sf_name: textValue,
      shape_id: assignSEData?.shape_id,
      zone_id: zoneData?.zone_id,
    };
    updateSFName(param);
  };

  const getAdminMapData = useAdminMap();
  useEffect(() => {
    const fetchData = async () => {
      if (zoneData?.zone_id) {
        const param = {
          zone_id: zoneData.zone_id,
          sc_id: [selectedSCId]
        };
        try {
          const adminMapData = await getAdminMapData(param);
          dispatch(getAdminMapDetails(adminMapData));
        } catch (error) {
          console.error("Error fetching admin map data:", error);
        }

        try {
          const routeListData = await getRouteListData(param);
          dispatch(getRouteList(routeListData));
        } catch (error) {
          console.error("Error fetching route list data:", error);
        }
      } else {
        console.error("No matching zone found for zone_name:");
      }
    };

    fetchData();
  }, [zoneData]);
  const handleAccChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  //commit
  useEffect(() => {
    dispatch(setSeIdList(selectedSeUserIds));
    dispatch(setDistId(selectedDistIds));
    dispatch(setSeId(selectedSeUserIds));
    dispatch(setDistIds(selectedDistIds));
  }, [selectedSeUserIds, selectedDistIds]);

  useEffect(() => {
    dispatch(setAsmIdList(selectedAsms.asmId));
    dispatch(setSoIdList(selectedSos.soId));
    dispatch(setAsmId(selectedAsms.asmId));
    dispatch(setSoId(selectedSos.soId));
  }, [selectedAsms.asmId, selectedSos.soId]);

  useEffect(() => {
    dispatch(setFrequency(SelectedFreq));
  }, [SelectedRouteSE, SelectedFreq]);

  useEffect(() => {
    if (assignRouteDetails && assignRouteDetails.route_cluster_list) {
      const newBcIdList = assignRouteDetails.route_cluster_list.map(
        (item) => item.bc_id
      );
      setBcIdList(newBcIdList);
    }
  }, [assignRouteDetails]);

  const getSMList = useSMList();
  const getSOList = useSOList();

  useEffect(() => {
    getSMList();
    getSOList();
  }, [zoneData]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleSwitchChange = () => {
    const newSwitchState = !switchState; // Toggle the switch state
    setSwitchState(newSwitchState); // Update the local state
    dispatch(setClusteringStatus(newSwitchState)); // Dispatch the new state
  };

  useEffect(() => {
    if (
      polyDetails &&
      polyDetails.dist_data &&
      polyDetails.dist_data.length > 0
    ) {
      // Extract dist_id from dist_data and update selectedDistCodes
      const distIds = polyDetails.dist_data.map((dist) => dist.dist_id);
      setSelectedDistCode(distIds[0]);
      setSelectedDistIds(distIds[0]);
    } else {
      setSelectedDistCode([]);
      setSelectedDistIds([]);
    }
    if (
      polyDetails &&
      polyDetails.se_data &&
      polyDetails.se_data?.se_id?.length > 0
    ) {
      // Extract se_id from se_data and merge with existing selectedSEs
      const seIds = polyDetails.se_data.se_id;

      setSelectedSEs(seIds);
      setSelectedSeUserIds(seIds);
    } else {
      setSelectedSEs([]);
      setSelectedSeUserIds([]);
    }
    if (
      polyDetails &&
      polyDetails.asm_data &&
      polyDetails.asm_data?.asm_id?.length > 0
    ) {
      const asmIds = polyDetails.asm_data.asm_id;
      setSelectedAsms({
        asmId: asmIds,
        asmCode: asmIds,
      });
    } else {
      setSelectedAsms({
        asmId: [],
        asmCode: [],
      });
    }
    if (
      polyDetails &&
      polyDetails.so_data &&
      polyDetails.so_data?.so_id?.length > 0
    ) {
      const soIds = polyDetails.so_data.so_id;
      setSelectedSos({
        soId: soIds,
        soCode: soIds,
      });
    } else {
      setSelectedSos({
        soId: [],
        soCode: [],
      });
      dispatch(resetSeId());
    }
  }, [polyDetails]);

  useEffect(() => {
    setShowDailyRoute(true);
    setShowRouteDate(false);
  }, [assignSEData.pincode]);

  // useEffect(() => {
  //   // console.log(selectedSEs, "1111");
  //   console.log(selectedSCId, "selectedSCId")
  //    }, [selectedSCId]);

  useEffect(() => {
    const currentDate = new Date();
    const formattedDate = currentDate.toISOString().split("T")[0];
    const dayOfWeek = daysOfWeek[currentDate.getDay()];
    setDay(dayOfWeek);
    setCurrentFreq(assignedBoundryRoutes?.frequency);
    setAssignedRoutes(assignedBoundryRoutes);
  }, [assignedBoundryRoutes]);

  useEffect(() => {
    // Check if 'previous_request_date' is not empty, null, or just whitespace
    const isSet = Boolean(
      assignedBoundryRoutes?.previous_request_date &&
        assignedBoundryRoutes?.previous_request_date.trim()
    );
    setShowReqData(isSet);
  }, [assignedBoundryRoutes?.previous_request_date]);

  useEffect(() => {
    // Check if adminMap and distributers_list exist before counting
    if (filteredData && filteredData.distributers) {
      // Update the state with the count of distributers_list
      setDistributersListCount(filteredData?.distributers.length);
      setSellerListCount(filteredData?.SE.length);
    } else {
      // If adminMap or distributers_list is not available, set the count to 0
      setDistributersListCount(0);
      setSellerListCount(0);
    }
    if (filteredData.ASM && filteredData.SO) {
      setASMCount(filteredData?.ASM?.length);
      setSOCount(filteredData?.SO?.length);
    }
  }, [filteredData]);

  const handleRadioChange = (distId) => {
    // Check if distId is already selected
    const isSelected = selectedDistIds?.includes(distId);

    // Toggle the selection of the distId
    if (isSelected) {
      // If already selected, remove it
      setSelectedDistCode((prevSelectedDistIds) =>
        prevSelectedDistIds.filter((id) => id !== distId)
      );
      setSelectedDistIds((prevSelectedDistIds) =>
        prevSelectedDistIds.filter((id) => id !== distId)
      );
    } else {
      // If not selected, add it
      setSelectedDistIds((prevSelectedDistIds) => [
        ...prevSelectedDistIds,
        distId,
      ]);
      setSelectedDistCode((prevSelectedDistIds) => [
        ...prevSelectedDistIds,
        distId,
      ]);
    }
  };

  const handleAsmChange = (AsmId) => {
    // Check if AsmId is already selected
    const isSelected = selectedAsms.asmId.includes(AsmId);

    // Toggle the selection of the AsmId
    if (isSelected) {
      // If already selected, remove it
      setSelectedAsms((prevSelectedAsms) => ({
        ...prevSelectedAsms,
        asmId: prevSelectedAsms.asmId.filter((id) => id !== AsmId),
        asmCode: prevSelectedAsms.asmCode.filter((id) => id !== AsmId),
      }));
    } else {
      // If not selected, add it
      setSelectedAsms((prevSelectedAsms) => ({
        ...prevSelectedAsms,
        asmId: [...prevSelectedAsms.asmId, AsmId],
        asmCode: [...prevSelectedAsms.asmCode, AsmId],
      }));
    }
  };

  const handleSoChange = (SoId) => {
    // Check if SoId is already selected
    const isSelected = selectedSos.soId.includes(SoId);

    // Toggle the selection of the SoId
    if (isSelected) {
      // If already selected, remove it
      setSelectedSos((prevSelectedSos) => ({
        ...prevSelectedSos,
        soId: prevSelectedSos.soId.filter((id) => id !== SoId),
        soCode: prevSelectedSos.soCode.filter((code) => code !== SoId),
      }));
    } else {
      // If not selected, add it
      setSelectedSos((prevSelectedSos) => ({
        ...prevSelectedSos,
        soId: [...prevSelectedSos.soId, SoId],
        soCode: [...prevSelectedSos.soCode, SoId],
      }));
    }
  };

  const handleCategoryClick = (se) => {
    // If se is already selected, remove it; otherwise, add it
    if (selectedSEs.includes(se)) {
      setSelectedSeUserIds((prevSelectedSeUserIds) =>
        prevSelectedSeUserIds.filter((id) => id !== se)
      );
      setSelectedSEs((prevSelectedSEs) =>
        prevSelectedSEs.filter((id) => id !== se)
      );
    } else {
      // If se is not selected, add it
      setSelectedSeUserIds((prevSelectedSeUserIds) => [
        ...prevSelectedSeUserIds,
        se,
      ]);
      setSelectedSEs((prevSelectedSEs) => [...prevSelectedSEs, se]);
    }
  };

  // const handleRouteSEClick = (se) => {
  //   if (SelectedRouteSE.includes(se)) {
  //     setSelectedRouteSE(SelectedRouteSE.filter((item) => item !== se));
  //   } else {
  //     setSelectedRouteSE([...SelectedRouteSE, se]);
  //   }
  // };

  useEffect(() => {
    setSFStatus(assignedBoundryDetails?.shape_file_status);
    setTextValue(assignedBoundryDetails?.sf_name);
  }, [assignedBoundryDetails]);

  const handleChnageFreq = (event) => {
    setSelectedFreq(event.target.value);
  };
  const validateAssignSEData = (data) => {
    for (const key in data) {
      if (Array.isArray(data[key])) {
        // Check if the array is empty
        if (data[key].length === 0) {
          return false; // Empty array
        }
      } else if (data[key] === "" || data[key] === null) {
        return false; // Empty string or null
      }
    }
    return true; // All properties are filled
  };
  const setAssignPincode = useAssignPincode();

  const assignSE = async () => {
    try {
      // Validate assignSEData before making the API call
      if (!validateAssignSEData(assignSEData)) {
        // Handle validation failure (display an error, prevent submission, etc.)
        console.error("Some data properties are empty.");
        Swal.fire({
          title: "Validation Error",
          text: "Please fill in all required fields",
          icon: "error",
          showConfirmButton: true,
        });
        return;
      }

      const data = await setAssignPincode(assignSEData);
      if (data.code === 200) {
        console.log(data);
        Swal.fire({
          title: "Success",
          text: "Scales Executives assign successfully",
          icon: "success",
          showConfirmButton: false,
          timer: 2000,
        });
        const param = {
          shape_id: assignSEData?.shape_id,
          sc_id: [selectedSCId],
        };
        await getuserData(param);
        // setSelectedSeUserIds([]);
      } else {
        Swal.fire({
          title: "Failed",
          text: data?.message,
          icon: "error",
          showConfirmButton: false,
          timer: 2000,
        });
      }
    } catch (error) {
      console.error("Error in onZoneChange:", error);
    }
  };

  const getCreateRoute = useCreateRoute();

  const generateRoute = async () => {
    try {
      // Validate assignSEData before making the API call
      if (!validateAssignSEData(assignRoute)) {
        // Handle validation failure (display an error, prevent submission, etc.)
        console.error("Some data properties are empty.");
        Swal.fire({
          title: "Validation Error",
          text: "Please fill in all required fields",
          icon: "error",
          showConfirmButton: true,
        });
        return;
      }
      const data = await getCreateRoute(assignRoute);
      if (data.code === 200) {
        Swal.fire({
          title: "Success",
          text: "Scales Executives assign successfully",
          icon: "success",
          showConfirmButton: false,
          timer: 2000,
        });
        const currentDate = new Date();
        const { formattedDate, nextDay } = getDeploymentDay(
          currentDate,
          currentFreq,
          day
        );
        dispatch(resetReqBoundryDetails());
        setAssignedRoutes(null);
        setShowReqData(false);
        // dispatch(resetAssignedBoundryDetails());
        setShowRouteDate(true);
        setReDeployInfo({ formattedDate, nextDay });
        setShowDailyRoute(false);
        setShowDeployButton(true);
        // setSelectedFreq("");
        // setSelectedRouteSE([]);
      } else {
        Swal.fire({
          title: "Failed",
          text: "Not able to generate Route",
          icon: "error",
          showConfirmButton: false,
          timer: 2000,
        });
      }
    } catch (error) {
      console.error("Error in onZoneChange:", error);
    }
  };

  const getDeployRoute = useDeployRoute();
  const deployRoute = async () => {
    const param = {
      shape_id: assignRoute.shape_id,
      bc_ids: bcIdList,
    };
    const data = await getDeployRoute(param);
    if (data.code === 200) {
      Swal.fire({
        title: "Success",
        text: data.message,
        icon: "success",
        showConfirmButton: false,
        timer: 2000,
      });
    } else {
      Swal.fire({
        title: "Failed",
        text: "Not able to Deploy the route",
        icon: "error",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  };

  const setRedeployRoute = useRedeployRoute();
  const RedeployRoute = async () => {
    const param = {
      shape_id: assignRoute.shape_id,
      request_date: reDeplyInfo.formattedDate,
      bc_ids: bcIdList,
    };
    const data = await setRedeployRoute(param);
    if (data.code === 200) {
      Swal.fire({
        title: "Success",
        text: `Your New Route will be deployed on ${reDeplyInfo.formattedDate}(${reDeplyInfo.nextDay})`,
        icon: "success",
        showConfirmButton: false,
        timer: 2000,
      });
    } else {
      Swal.fire({
        title: "Failed",
        text: "Not able to Deploy the route",
        icon: "error",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  };

  const getDeploymentDay = (currentDate, frequency, day) => {
    const daysOfWeek = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const currentDayIndex = daysOfWeek.indexOf(day);

    switch (frequency) {
      case 1:
        const daysUntilNextMonday = (7 - currentDayIndex + 1) % 7;
        currentDate.setDate(currentDate.getDate() + daysUntilNextMonday);
        break;
      case 2:
        if (currentDayIndex >= 1 && currentDayIndex <= 3) {
          const daysUntilNextThursday = (4 - currentDayIndex + 7) % 7;
          currentDate.setDate(currentDate.getDate() + daysUntilNextThursday);
        } else {
          const daysUntilNextMonday = (7 - currentDayIndex + 1) % 7;
          currentDate.setDate(currentDate.getDate() + daysUntilNextMonday);
        }
        break;
      case 3:
        if (currentDayIndex <= 1) {
          const daysUntilNextWednesday = (3 - currentDayIndex + 7) % 7;
          currentDate.setDate(currentDate.getDate() + daysUntilNextWednesday);
        } else if (currentDayIndex <= 3) {
          const daysUntilNextFriday = (5 - currentDayIndex + 7) % 7;
          currentDate.setDate(currentDate.getDate() + daysUntilNextFriday);
        } else {
          const daysUntilNextMonday = (7 - currentDayIndex + 1) % 7;
          currentDate.setDate(currentDate.getDate() + daysUntilNextMonday);
        }
        break;
      case 6:
        currentDate.setDate(currentDate.getDate() + 1);
        break;
      default:
        // Handle unsupported frequency
        break;
    }

    const formattedDate = currentDate.toISOString().split("T")[0];
    const nextDay = daysOfWeek[currentDate.getDay()];

    return { formattedDate, nextDay };
  };

  const getReqBoundryData = useReqBoundryDetails();
  const getReqRoute = async () => {
    const param = {
      zone_id: assignRoute.zone_id,
      pincode: assignRoute.pincode,
      shape_id: assignRoute.shape_id,
    };

    const data = await getReqBoundryData(param);

    if (data.code === 200) {
      setShowDailyRoute(false);
      // dispatch(resetRouteClusterList());
      // dispatch(resetAssignedBoundryDetails());
      setAssignedRoutes(null);
    } else {
      return;
    }
  };

  useEffect(() => {
    if (adminMap && ASMList && SOList) handleSCRadioChange(selectedSCId);
  }, [adminMap, ASMList, SOList]);
  

  const getAssignPincodeData = usePincodeData();
  const getuserData = useAssgnedUserData();
  const adminMapData = useAdminMap();
  const getRouteListData = useRouteList();
  const getAssignedRoute = useAssignedRoute();
  useEffect(() => {
    const fetchData = async () => {
      if (assignSEData && zoneData?.zone_id) {
        const param = {
          shape_id: assignSEData?.shape_id,
          pincode: assignSEData?.pincode,
          sc_id: [selectedSCId],
          zone_id: zoneData?.zone_id,
        };

        await Promise.all([
          getAssignPincodeData(param),
          getuserData(param),
          getAssignedRoute(param),
        ]);
        const RouteData = await getRouteListData(param);
        dispatch(getRouteList(RouteData));
        const data = await adminMapData(param);
        dispatch(getAdminMapDetails(data));
      }
    };

    fetchData();
  }, [selectedSCId]);

  const handleSCRadioChange = (scId) => {
    setSelectedSCId(scId);
    dispatch(setScId([scId]));
    dispatch(setSuperCategoryId([scId]));
    if(scId === 1){
    navigate("/home/routemgt");
    }
    const filteredDistributers = adminMap?.distributers_list?.filter((item) =>
      item?.sc_id?.includes(scId)
    );
    const filteredASM = ASMList.filter((item) => item?.sc_id?.includes(scId));
    const filteredSO = SOList.filter((item) => item?.sc_id?.includes(scId));
    const filteredSE = adminMap?.se_list?.filter((item) =>
      item?.sc_id?.includes(scId)
    );

    setFilteredData({
      distributers: filteredDistributers,
      ASM: filteredASM,
      SO: filteredSO,
      SE: filteredSE,
    });
  };
  const updateSFStatus = useUpdateSFStatus();
  const getMapData = useAdminMap();
  const handleSFStatusChange = async (e) => {
    const isChecked = e.target.checked;
    // console.log(isChecked, "isChecked");

    const param = {
      shape_id: assignSEData?.shape_id,
      status: isChecked,
      zone_id: zoneData?.zone_id,
    };
    const data = await updateSFStatus(param);
    if (data.code === 200) {
      setSFStatus(isChecked);
      Swal.fire({
        title: "Success",
        text: "Shape File updated successfully",
        icon: "success",
        showConfirmButton: false,
        timer: 2000,
      });
      const param = {
        zone_id: zoneData?.zone_id,
        sc_id: assignSEData?.sc_id
      };
      const data = await getMapData(param);
      dispatch(getAdminMapDetails(data));
    } else {
      Swal.fire({
        title: "Failed",
        text: data.message,
        icon: "error",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  };
  const resetSelectedState = () => {
  dispatch(resetData()); 
  }
  return (
    <div>
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {adminMap === null ? (
        <div>
          <Box sx={{ width: "100%", position: "absolute", top: "1px" }}>
            <LinearProgress
              sx={{ color: "#EF6C1A", backgroundColor: "#EF6C1A" }}
            />
          </Box>
        </div>
      ) : (
        <></>
      )}
      <Stack
        sx={{
          flexDirection: "row",
          backgroundColor: "#E5E5E5",
          width: "100vw",
          fontFamily: "'Roboto', sans-serif",
          height: "fit-content",
          gap:"4px"
        }}
      >
        <Sidebar />
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", lg: "row" },
            gap: "6px",
            justifyContent: "space-between",
            // paddingLeft: "10px",
            paddingRight: "5px",
            paddingTop: "5px",
            backgroundColor: "#E5E5E5",
            height: "fit-content",
            width: "100%",
          }}
        >
          <Stack sx={{ width: "100%", height: "100%" }}>
            <MapPolygon
              retailerCord={adminMap?.retailers_list}
              pincodeCord={adminMap?.pincodes}
              showDailyRoute={showDailyRoute}
              assignedBoundryRoutes={assignedRoutes}
            />
          </Stack>
          <Card
            sx={{
              minWidth: "280px",
              width: { xs: "92%", lg: "300px" },
              minHeight: "75vh",
              display: "flex",
              flexDirection: "column",
              gap: "0.5rem",
              padding: "16px 10px",
              borderRadius: "12px",
              height: { xs: "fit-content", lg: "calc(100vh - 90px)"},
              position: "relative",
            }}
          >
            {assignSEData?.shape_id ? (
              <Stack>
                <Stack
                  sx={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    paddingLeft: "0.8rem",
                    paddingRight: "0.7rem",
                  }}
                >
                  <Stack flexDirection="row" sx={{ gap: "10px" }}>
                    {isEditing ? (
                      <TextField
                        value={textValue}
                        onChange={handleSFNameChange}
                        variant="outlined"
                        size="small"
                        autoFocus
                        sx={{ width: "140px" }}
                      />
                    ) : (
                      <Typography
                        variant="body1"
                        color="initial"
                        sx={{ fontSize: "18px", fontWeight: "500" }}
                      >
                        {textValue}
                      </Typography>
                    )}
                    {isEditing ? (
                      <button
                        style={{
                          backgroundColor: "#F0F8EB",
                          borderRadius: "14px",
                          fontSize: "14px",
                          fontWeight: "500",
                          padding: "3px 10px",
                          alignSelf: "center",
                          cursor: "pointer",
                        }}
                        onClick={handleSaveClick}
                      >
                        Save
                      </button>
                    ) : (
                      <button
                        style={{
                          backgroundColor: "#F8EBEB",
                          borderRadius: "14px",
                          fontSize: "14px",
                          fontWeight: "500",
                          padding: "3px 10px",
                          alignSelf: "center",
                          cursor: "pointer",
                        }}
                        onClick={handleEditClick}
                      >
                        Edit
                      </button>
                    )}
                  </Stack>
                  <CloseIcon
                    sx={{ cursor: "pointer" }}
                    onClick={resetSelectedState}
                  />
                </Stack>
                <Stack
                  sx={{ paddingLeft: "0.8rem", marginTop: "10px" }}
                  direction="row"
                  spacing={1}
                  alignItems="center"
                >
                  {SFStatus ? (
                    <Typography>Active</Typography>
                  ) : (
                    <Typography>Inactive</Typography>
                  )}
                  <AntSwitch
                    defaultChecked
                    inputProps={{ "aria-label": "ant design" }}
                    checked={SFStatus}
                    onChange={handleSFStatusChange}
                  />
                </Stack>
                <Stack
                  sx={{
                    textAlign: "left",
                    paddingLeft: "0.8rem",
                    paddingRight: "0.8rem",
                    marginTop: "10px",
                    fontSize: "14px",
                    fontWeight: "400",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  {assignSEData?.shape_id}
                  <Typography
                    sx={{
                      fontSize: "14px",
                      color: "#1A56EF",
                      fontWeight: "600",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setPopupOpen(true);
                    }}
                  >
                    {polyDetails?.retailer_data.length} Retailers
                  </Typography>

                  <RetailerPopup
                    isOpen={popupOpen}
                    onClose={() => setPopupOpen(false)}
                    retailerData={polyDetails?.retailer_data}
                  />
                </Stack>
              </Stack>
            ) : (
              <Stack sx={{ paddingLeft: "0.8rem" }} gap={0.5}>
                <Typography
                  variant="P"
                  color="initial"
                  sx={{
                    fontSize: "18px",
                    fontWeight: "700",
                    textAlign: "start",
                  }}
                >
                  {zoneData?.zone_name}
                </Typography>
                <Typography
                  variant="P"
                  color="initial"
                  sx={{
                    fontSize: "14px",
                    fontWeight: "400",
                    textAlign: "start",
                    lineHeight: "18px",
                  }}
                >
                  <span style={{ color: "#189903", fontWeight: "700" }}>
                    {adminMap?.no_of_shapeFiles}
                  </span>{" "}
                  / {adminMap?.pincodes?.length} Shape files Enabled
                </Typography>
              </Stack>
            )}

            <Divider
              variant="middle"
              sx={{ paddingTop: "6px", borderBottom: "2px Solid #BABABA" }}
            />
            <Stack
              sx={{
                textAlign: "left",
                paddingLeft: "0.8rem",
                flexDirection: "row",
                padding: "10px 0px",
                marginLeft: "0.3rem",
              }}
            >
              {superCategory?.map((data, index) => (
                <Stack sx={{ flexDirection: "row" }} key={index}>
                  <Radio
                    value={data.sc_id}
                    sx={{
                      height: "18px",
                      alignSelf: "center",
                      "& .MuiSvgIcon-root": {
                        color: "#1C1B1F",
                      },
                    }}
                    checked={selectedSCId === data.sc_id}
                    onChange={() => handleSCRadioChange(data.sc_id)}
                  />

                  <label>{data?.sc_name}</label>
                </Stack>
              ))}
            </Stack>
            <Stack
              sx={{
                textAlign: "start",
                paddingLeft: "1rem",
                fontSize: "16px",
                flexDirection: "row",
                gap: "5px",
              }}
            >
              No. of Retailers:
              <span style={{ fontWeight: "700" }}>
                {assignSEData?.shape_id
                  ? polyDetails?.retailer_data.filter((retailer) =>
                      Array.isArray(retailer.sc_id)
                        ? retailer.sc_id.includes(selectedSCId)
                        : retailer.sc_id === selectedSCId
                    ).length
                  : adminMap?.retailers_list?.length}
              </span>
            </Stack>

            <TabContext value={value}>
              {assignSEData?.shape_id ? (
                <Box
                  sx={{
                    border: "1px solid",
                    borderColor: "divider",
                    display: "flex",
                    justifyContent: "center",
                    borderRadius: "5px",
                    width: "95%",
                    alignSelf: "center",
                  }}
                >
                  <TabList
                    onChange={handleChange}
                    aria-label="lab API tabs example"
                    indicatorColor="transparent"
                    sx={{
                      width: "95%",
                      "& .Mui-selected": {
                        backgroundColor: "#58585A",
                        color: "#ffffff !important",
                        border: "none",
                        borderRadius: "4px",
                        margin: "4px",
                        minHeight: "15px",

                        // Height for the selected tab
                      },
                      "& .MuiTabs-root": {
                        minHeight: "30px", // Height for the entire TabList
                      },
                    }}
                  >
                    <Tab
                      variant="soft"
                      label="Assign"
                      value="1"
                      sx={{
                        height: "20px",
                        minHeight: "30px !important",
                        margin: "4px",
                        width: "50%",
                      }}
                    />
                    <Tab
                      label="Routes"
                      value="2"
                      sx={{
                        minHeight: "30px !important",
                        height: "20px !important",
                        margin: "4px",
                        width: "50%",
                      }}
                    />
                  </TabList>
                </Box>
              ) : null}
              <TabPanel sx={{ padding: "4px", height: "65%" }} value="1">
                <Stack
                  sx={{
                    height: "90%",
                    overflowY: "scroll",
                    /* Hide scrollbar for Firefox */
                    scrollbarWidth: "none",
                    /* Hide scrollbar for other browsers */
                    "::-webkit-scrollbar": {
                      display: "none",
                    },
                  }}
                >
                  <Stack>
                    <Accordion
                      expanded={expanded === "panel1"}
                      onChange={handleAccChange("panel1")}
                      elevation={0}
                      sx={{ padding: "0" }}
                    >
                      <AccordionSummary
                        expandIcon={<ArrowDropDownIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                        sx={{ padding: "0" }}
                      >
                        <Stack
                          sx={{
                            display: "flex",
                            gap: "18px",
                            paddingLeft: "0.8rem",
                            flexDirection: "row",
                            marginBottom: "6px",
                          }}
                        >
                          <Typography
                            sx={{ fontSize: "18px", fontWeight: "600" }}
                          >
                            Distributors
                          </Typography>
                          <div
                            style={{
                              backgroundColor: "rgba(0, 0, 0, 0.60)",
                              borderRadius: "50%",
                              width: "24px",
                              height: "24px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <span
                              style={{
                                color: "#fff",
                              }}
                            >
                              {distributersListCount}
                            </span>
                          </div>
                        </Stack>
                      </AccordionSummary>
                      <AccordionDetails
                        sx={{ padding: "0", textAlign: "start" }}
                      >
                        <FormControl
                          sx={{
                            padding: "0px 10px",
                            minHeight: "26vh",
                            height: "38%",
                            width: "90%",
                            maxHeight: "30vh",
                            overflowY: "scroll",
                          }}
                        >
                          {adminMap === null ? (
                            <Stack
                              sx={{
                                width: "100%",
                                height: "100%",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <TailSpin
                                visible={true}
                                height="40"
                                width="40"
                                color="#EF6C1A"
                                ariaLabel="tail-spin-loading"
                                radius="1"
                                wrapperStyle={{}}
                                wrapperClass=""
                              />
                            </Stack>
                          ) : (
                            <FormGroup>
                              {filteredData?.distributers?.map(
                                (data, index) => (
                                  <FormControlLabel
                                    key={data.dist_id}
                                    control={
                                      <Checkbox
                                        sx={{ color: "#1C1B1F" }}
                                        color="default"
                                        checked={selectedDistCode?.includes(
                                          data.dist_id
                                        )}
                                        onChange={() =>
                                          handleRadioChange(data.dist_id)
                                        }
                                      />
                                    }
                                    label={
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "column",
                                          textAlign: "start",
                                          fontSize: "14px",
                                          fontWeight: "500",
                                        }}
                                      >
                                        {data.dist_name}{" "}
                                        <span
                                          style={{
                                            fontWeight: "300",
                                            fontSize: "12px",
                                          }}
                                        >
                                          {data.dist_code}
                                        </span>
                                      </div>
                                    }
                                  />
                                )
                              )}
                            </FormGroup>
                          )}
                        </FormControl>
                      </AccordionDetails>
                    </Accordion>
                  </Stack>
                  <Divider
                    variant="middle"
                    sx={{ borderBottom: "1px Solid black" }}
                  />
                  <Stack>
                    <Accordion
                      expanded={expanded === "panel2"}
                      onChange={handleAccChange("panel2")}
                      elevation={0}
                      sx={{ padding: "0" }}
                    >
                      <AccordionSummary
                        expandIcon={<ArrowDropDownIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                        sx={{ padding: "0" }}
                      >
                        <Stack
                          sx={{
                            display: "flex",
                            gap: "18px",
                            paddingLeft: "0.8rem",
                            flexDirection: "row",
                            marginBottom: "6px",
                          }}
                        >
                          <Typography
                            sx={{ fontSize: "18px", fontWeight: "600" }}
                          >
                            ASM
                          </Typography>
                          <div
                            style={{
                              backgroundColor: "rgba(0, 0, 0, 0.60)",
                              borderRadius: "50%", // Use 50% for a perfect circle
                              width: "24px",
                              height: "24px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <span
                              style={{
                                color: "#fff",
                              }}
                            >
                              {ASMCount}
                            </span>
                          </div>
                        </Stack>
                      </AccordionSummary>
                      <AccordionDetails
                        sx={{ padding: "0", textAlign: "start" }}
                      >
                        <FormControl
                          sx={{
                            padding: "0px 10px",
                            minHeight: "26vh",
                            height: "38%",
                            width: "90%",
                            maxHeight: "30vh",
                            overflowY: "scroll",
                          }}
                        >
                          {adminMap === null ? (
                            <Stack
                              sx={{
                                width: "100%",
                                height: "100%",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <TailSpin
                                visible={true}
                                height="40"
                                width="40"
                                color="#EF6C1A"
                                ariaLabel="tail-spin-loading"
                                radius="1"
                                wrapperStyle={{}}
                                wrapperClass=""
                              />
                            </Stack>
                          ) : (
                            <FormGroup>
                              {filteredData?.ASM?.map((data, index) => (
                                <FormControlLabel
                                  key={index}
                                  control={
                                    <Checkbox
                                      sx={{ color: "#1C1B1F" }}
                                      color="default"
                                      checked={selectedAsms?.asmCode?.includes(
                                        data.user_id
                                      )}
                                      onChange={() =>
                                        handleAsmChange(data.user_id)
                                      }
                                    />
                                  }
                                  label={
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        textAlign: "start",
                                        fontSize: "14px",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {data.first_name}
                                      {/* <span
                                          style={{
                                            fontWeight: "300",
                                            fontSize: "12px",
                                          }}
                                        >
                                          {data.dist_code}
                                        </span> */}
                                    </div>
                                  }
                                />
                              ))}
                            </FormGroup>
                          )}
                        </FormControl>
                      </AccordionDetails>
                    </Accordion>
                  </Stack>
                  <Divider
                    variant="middle"
                    sx={{ borderBottom: "1px Solid black" }}
                  />
                  <Stack>
                    <Accordion
                      expanded={expanded === "panel3"}
                      onChange={handleAccChange("panel3")}
                      elevation={0}
                      sx={{ padding: "0" }}
                    >
                      <AccordionSummary
                        expandIcon={<ArrowDropDownIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                        sx={{ padding: "0" }}
                      >
                        <Stack
                          sx={{
                            display: "flex",
                            gap: "18px",
                            paddingLeft: "0.8rem",
                            flexDirection: "row",
                            marginBottom: "6px",
                          }}
                        >
                          <Typography
                            sx={{ fontSize: "18px", fontWeight: "600" }}
                          >
                            SO
                          </Typography>
                          <div
                            style={{
                              backgroundColor: "rgba(0, 0, 0, 0.60)",
                              borderRadius: "50%", // Use 50% for a perfect circle
                              width: "24px",
                              height: "24px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <span
                              style={{
                                color: "#fff",
                              }}
                            >
                              {SOCount}
                            </span>
                          </div>
                        </Stack>
                      </AccordionSummary>
                      <AccordionDetails
                        sx={{ padding: "0", textAlign: "start" }}
                      >
                        <FormControl
                          sx={{
                            padding: "0px 10px",
                            minHeight: "26vh",
                            height: "38%",
                            width: "90%",
                            maxHeight: "30vh",
                            overflowY: "scroll",
                          }}
                        >
                          {adminMap === null ? (
                            <Stack
                              sx={{
                                width: "100%",
                                height: "100%",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <TailSpin
                                visible={true}
                                height="40"
                                width="40"
                                color="#EF6C1A"
                                ariaLabel="tail-spin-loading"
                                radius="1"
                                wrapperStyle={{}}
                                wrapperClass=""
                              />
                            </Stack>
                          ) : (
                            <FormGroup>
                              {filteredData?.SO?.map((data, index) => (
                                <FormControlLabel
                                  key={index}
                                  control={
                                    <Checkbox
                                      sx={{ color: "#1C1B1F" }}
                                      color="default"
                                      checked={selectedSos.soCode?.includes(
                                        data.user_id
                                      )}
                                      onChange={() =>
                                        handleSoChange(data.user_id)
                                      }
                                    />
                                  }
                                  label={
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        textAlign: "start",
                                        fontSize: "14px",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {data?.first_name}
                                      {/* <span
                                          style={{
                                            fontWeight: "300",
                                            fontSize: "12px",
                                          }}
                                        >
                                          {data.dist_code}
                                        </span> */}
                                    </div>
                                  }
                                />
                              ))}
                            </FormGroup>
                          )}
                        </FormControl>
                      </AccordionDetails>
                    </Accordion>
                  </Stack>

                  <Divider
                    variant="middle"
                    sx={{ borderBottom: "1px Solid black" }}
                  />
                  <Stack sx={{ height: "45%" }}>
                    <Accordion
                      expanded={expanded === "panel4"}
                      onChange={handleAccChange("panel4")}
                      sx={{ padding: "0" }}
                      elevation={0}
                    >
                      <AccordionSummary
                        expandIcon={<ArrowDropDownIcon />}
                        aria-controls="panel2bh-content"
                        id="panel2bh-header"
                        sx={{ padding: "0" }}
                      >
                        <Stack
                          sx={{
                            display: "flex",
                            alignItems: "flex-start",
                            paddingLeft: "0.8rem",
                            flexDirection: "row",
                            gap: "18px",
                            paddingTop: "16px",
                            marginBottom: "8px",
                          }}
                        >
                          <Typography
                            sx={{ fontWeight: "600", fontSize: "18px" }}
                          >
                            SR
                          </Typography>
                          <div
                            style={{
                              backgroundColor: "rgba(0, 0, 0, 0.60)",
                              borderRadius: "50%", // Use 50% for a perfect circle
                              width: "24px",
                              height: "24px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <span
                              style={{
                                color: "#fff",
                              }}
                            >
                              {sellerListCount}
                            </span>
                          </div>
                        </Stack>
                      </AccordionSummary>
                      <AccordionDetails
                        sx={{ padding: "0", textAlign: "start" }}
                      >
                        <FormControl
                          sx={{
                            padding: "0px 10px",
                            minHeight: "27vh",
                            height: "35%",
                            maxHeight: "30vh",
                            width: "90%",
                            overflowY: "scroll",
                            marginBottom: "50px",
                          }}
                        >
                          {adminMap === null ? (
                            <Stack
                              sx={{
                                width: "100%",
                                height: "100%",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <TailSpin
                                visible={true}
                                height="40"
                                width="40"
                                color="#EF6C1A"
                                ariaLabel="tail-spin-loading"
                                radius="1"
                                wrapperStyle={{}}
                                wrapperClass=""
                              />
                            </Stack>
                          ) : (
                            <FormGroup>
                              {filteredData?.SE?.map((se, index) => (
                                <FormControlLabel
                                  key={se.user_id}
                                  control={
                                    <Checkbox
                                      sx={{ color: "#1C1B1F" }}
                                      color="default"
                                      onChange={() =>
                                        handleCategoryClick(se.user_id)
                                      }
                                      checked={selectedSEs?.includes(
                                        se.user_id
                                      )}
                                    />
                                  }
                                  label={
                                    <Stack
                                      sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        gap: "5px",
                                      }}
                                    >
                                      {/* <img
                                        style={{
                                          width: "24px",
                                          height: "24px",
                                          alignSelf: "center",
                                        }}
                                        src={getMarkerIcon(se?.zone_id)}
                                      /> */}
                                      <Stack
                                        sx={{
                                          display: "flex",
                                          flexDirection: "column",
                                          alignItems: "flex-start",
                                          justifyContent: "center",
                                          cursor: "pointer",
                                        }}
                                      >
                                        <Typography
                                          sx={{ fontSize: "14px" }}
                                        >{`${se?.first_name}`}</Typography>
                                      </Stack>
                                    </Stack>
                                  }
                                />
                              ))}
                            </FormGroup>
                          )}
                        </FormControl>
                      </AccordionDetails>
                    </Accordion>
                  </Stack>
                </Stack>
                {assignSEData?.shape_id ? (
                  <Button
                    className="submit button"
                    variant="contained"
                    onClick={assignSE}
                    sx={{
                      backgroundColor: "#EF6C1A",
                      ":hover": {
                        backgroundColor: "#fa731e", // Change the color for the hover effect
                      },
                      position: "absolute",
                      bottom: "16px",
                      width: { xs: "90%", sm: "95%", lg: "90%" },
                      left: "16px",
                    }}
                  >
                    Assign
                  </Button>
                ) : null}
              </TabPanel>

              <TabPanel value="2" sx={{ padding: "4px", height: "60%" }}>
                <Stack
                  sx={{
                    height: "90%",
                    overflowY: "scroll",

                    scrollbarWidth: "none",

                    "::-webkit-scrollbar": {
                      display: "none",
                    },
                  }}
                >
                  {polyDetails === null ? (
                    <Typography
                      sx={{
                        height: "100%",
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      Please Select a Pincode to Create Routes
                    </Typography>
                  ) : (
                    <div>
                      <Stack sx={{ gap: "12px" }}>
                        <Stack sx={{ height: "45%" }}>
                          <Stack
                            sx={{
                              display: "flex",
                              alignItems: "flex-start",
                              paddingLeft: "0.8rem",
                              flexDirection: "column",
                              gap: "18px",
                              paddingTop: "16px",
                              marginBottom: "8px",
                              gap: "0px",
                            }}
                          >
                            <Typography
                              sx={{ fontWeight: "600", fontSize: "18px" }}
                            >
                              Distributor:
                            </Typography>

                            {RouteUserData?.distributorData.map(
                              (dist, index) => (
                                <>
                                  <Typography
                                    variant="p"
                                    color="initial"
                                    fontSize="16px"
                                  >
                                    {" "}
                                    {dist.dist_name}
                                  </Typography>
                                  <Typography
                                    variant="p"
                                    color="initial"
                                    fontSize="16px"
                                    fontWeight="300"
                                  >
                                    {" "}
                                    {dist.dist_code}
                                  </Typography>
                                </>
                              )
                            )}
                          </Stack>

                          <Stack
                            sx={{
                              display: "flex",
                              alignItems: "flex-start",
                              paddingLeft: "0.8rem",
                              flexDirection: "column",
                              gap: "18px",
                              paddingTop: "16px",
                              marginBottom: "8px",
                              gap: "0px",
                            }}
                          >
                            <Typography
                              sx={{ fontWeight: "600", fontSize: "18px" }}
                            >
                              ASM:
                            </Typography>

                            {RouteUserData?.AsmData.map((asm, index) => (
                              <>
                                <Typography
                                  variant="p"
                                  color="initial"
                                  fontSize="16px"
                                >
                                  {" "}
                                  {asm?.first_name}
                                </Typography>
                                <Typography
                                  variant="p"
                                  color="initial"
                                  fontSize="16px"
                                  fontWeight="300"
                                  textAlign="start"
                                >
                                  {" "}
                                  {asm?.user_code}
                                </Typography>
                              </>
                            ))}
                          </Stack>

                          <Stack
                            sx={{
                              display: "flex",
                              alignItems: "flex-start",
                              paddingLeft: "0.8rem",
                              flexDirection: "column",
                              gap: "18px",
                              paddingTop: "16px",
                              marginBottom: "8px",
                              gap: "0px",
                            }}
                          >
                            <Typography
                              sx={{ fontWeight: "600", fontSize: "18px" }}
                            >
                              SO:
                            </Typography>

                            {RouteUserData?.SoData.map((so, index) => (
                              <Stack sx={{ marginBottom: "6px" }}>
                                <Typography
                                  variant="p"
                                  color="initial"
                                  fontSize="16px"
                                  fontWeight="400"
                                  textAlign="start"
                                >
                                  {" "}
                                  {so?.first_name}
                                </Typography>
                                <Typography
                                  variant="p"
                                  color="initial"
                                  fontSize="16px"
                                  fontWeight="300"
                                  textAlign="start"
                                >
                                  {" "}
                                  {so?.user_code}
                                </Typography>
                              </Stack>
                            ))}
                          </Stack>

                          <Stack
                            sx={{
                              display: "flex",
                              alignItems: "flex-start",
                              paddingLeft: "0.8rem",
                              flexDirection: "column",
                              gap: "18px",
                              paddingTop: "16px",
                              marginBottom: "8px",
                              gap: "0px",
                            }}
                          >
                            <Typography
                              sx={{ fontWeight: "600", fontSize: "18px" }}
                            >
                              SR:
                            </Typography>

                            {RouteUserData?.seData.map((se, index) => (
                              <Stack sx={{ marginBottom: "6px" }}>
                                <Typography
                                  variant="p"
                                  color="initial"
                                  fontSize="16px"
                                  fontWeight="400"
                                  textAlign="start"
                                >
                                  {" "}
                                  {se?.first_name}
                                </Typography>
                                <Typography
                                  variant="p"
                                  color="initial"
                                  fontSize="16px"
                                  fontWeight="300"
                                  textAlign="start"
                                >
                                  {" "}
                                  {se?.user_code}
                                </Typography>
                              </Stack>
                            ))}
                          </Stack>
                        </Stack>
                        <Stack
                          sx={{
                            flexDirection: "row",
                            gap: "10px",
                            alignItems: "center",
                            paddingLeft: "0.8rem",
                          }}
                        >
                          <Typography
                            variant="P"
                            color="initial"
                            sx={{ fontSize: "14px", fontWeight: "500" }}
                          >
                            Clustering
                          </Typography>

                          <Stack
                            direction="row"
                            spacing={1}
                            alignItems="center"
                          >
                            <AntSwitch
                              defaultChecked
                              inputProps={{ "aria-label": "ant design" }}
                              checked={switchState} // Controlled by the state variable
                              onChange={handleSwitchChange} // Function to handle state change
                            />
                          </Stack>
                        </Stack>
                        <Stack
                          sx={{
                            flexDirection: "row",
                            alignItems: "center",
                            width: "100%",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography variant="p" color="initial">
                            Weekly Frequency
                          </Typography>
                          <FormControl
                            sx={{ m: 1, minWidth: 120 }}
                            size="small"
                          >
                            <InputLabel id="demo-select-small-label">
                              Frequency
                            </InputLabel>
                            <Select
                              labelId="demo-select-small-label"
                              id="demo-select-small"
                              value={SelectedFreq}
                              label="Age"
                              onChange={handleChnageFreq}
                            >
                              <MenuItem value={1}>1</MenuItem>
                              <MenuItem value={2}>2</MenuItem>
                              <MenuItem value={3}>3</MenuItem>
                              <MenuItem value={6}>6</MenuItem>
                            </Select>
                          </FormControl>
                        </Stack>
                        <Button
                          className="submit button"
                          variant="outlined"
                          onClick={generateRoute}
                          sx={{
                            borderColor: "#000000",
                            color: "#000000",
                            textTransform: "none",
                            ":hover": { borderColor: "#000000" },
                          }}
                        >
                          {assignedBoundryRoutes?.route_cluster_list?.length > 0
                            ? "Regenerate Route"
                            : "Generate Route"}
                        </Button>
                        {showReqData ? (
                          <Stack>
                            <p style={{ margin: "4px" }}>
                              Your already have a Request on{" "}
                              <span>
                                {assignedBoundryRoutes?.previous_request_date}
                              </span>
                            </p>
                            <Button
                              sx={{ textTransform: "none" }}
                              onClick={getReqRoute}
                            >
                              View
                            </Button>
                          </Stack>
                        ) : (
                          <></>
                        )}
                        {showRouteDate ? (
                          <Typography
                            sx={{ marginBottom: "50px" }}
                            variant="p"
                            color="initial"
                          >
                            Your New Route will be deployed on{" "}
                            {reDeplyInfo?.formattedDate} ({reDeplyInfo?.nextDay}
                            )
                          </Typography>
                        ) : (
                          <></>
                        )}
                      </Stack>
                      {showDeployButton ? (
                        assignedBoundryRoutes?.route_cluster_list?.length >
                        0 ? (
                          <Button
                            className="submit button"
                            variant="contained"
                            onClick={RedeployRoute}
                            sx={{
                              backgroundColor: "#EF6C1A",
                              textTransform: "none",
                              ":hover": {
                                backgroundColor: "#fa731e",
                              },
                              position: "absolute",
                              bottom: "16px",
                              width: { xs: "90%", sm: "95%", lg: "90%" },
                              left: "16px",
                            }}
                          >
                            ReDeploy
                          </Button>
                        ) : (
                          <Button
                            className="submit button"
                            variant="contained"
                            onClick={deployRoute}
                            sx={{
                              backgroundColor: "#EF6C1A",
                              textTransform: "none",
                              ":hover": {
                                backgroundColor: "#fa731e",
                              },
                              position: "absolute",
                              bottom: "16px",
                              width: { xs: "90%", sm: "95%", lg: "90%" },
                              left: "16px",
                            }}
                          >
                            Deploy
                          </Button>
                        )
                      ) : (
                        <div></div>
                      )}
                    </div>
                  )}
                </Stack>
              </TabPanel>
            </TabContext>
          </Card>
        </Box>
      </Stack>
    </div>
  );
};

export default DashboardContent;
