import React, { useEffect, useState } from "react";
import useSRGraphDetails from "../../../hooks/useSRGraphDetails";
import useSRTableDetails from "../../../hooks/useSRTableDetails";
import useSRTimeTableDetails from "../../../hooks/useSRTimeTableDetails";
import useSRGraphTimeDetails from "../../../hooks/useSRGraphTimeDetails";
import { useSelector } from "react-redux";
const TimeDropDown = () => {
  const zoneData = useSelector((state) =>state.app.curretDropdownData);
  const date = new Date();
  const [currentSelectedDate, setCurrentSelectedDate] = useState(
    formatDate(date)
  );


  const getSRTimeDetails = useSRTimeTableDetails();
  const getSRGraphDetails = useSRGraphTimeDetails();

  useEffect(()=>{
    getTimeStampData();
  }, [zoneData,currentSelectedDate])
  const getTimeStampData = async() =>{
    let param={
      date: currentSelectedDate,
      zone_id: zoneData?.zone_id
    }
    await getSRTimeDetails(param);
    await getSRGraphDetails(param);
  }


  const handleDateChange = (e) => {
    // console.log(e.target.value);
    setCurrentSelectedDate(e.target.value);
  };

  // console.log(currentSelectedDate);

  return (
    <input
      type="date"
      id="dateSale"
      name="dateSale"
      style={{ height: "2rem", fontSize: "14px", fontFamily:"inherit", borderRadius:"8px"}}
      onChange={handleDateChange}
      value={currentSelectedDate}
    />
  );
};

const formatDate = (date) => {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  return `${year}-${month}-${day}`;
};

export default TimeDropDown;
