import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import {
  FormControl,
  Select,
  MenuItem,
  TextField,
  InputLabel,
  Stack,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import AddIcon from "@mui/icons-material/Add";
import { Input } from "@mui/icons-material";
import {
  getCategoryFormData,
  getCategoryList,
  resetCategoryFormData,
} from "../../slice/categorySlice";
import useCategoryImageUpload from "../../hooks/useCategoryImageUpload";
import useAddCategory from "../../hooks/useAddCategory";
import { BaseImgUrl } from "../../utility/BaseURL";
import { ToastContainer, toast } from "react-toastify";
import useCategoryList from "../../hooks/useCategoryList";
import PhotoCameraOutlinedIcon from "@mui/icons-material/PhotoCameraOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
// import { getZoneList } from "../slice/appSlice";
// import useZoneList from "../hooks/useZoneList";
// import useStateList from "../hooks/useStateList";
// import useDistributorList from "../hooks/useDistributorList";
// import useAddDistributor from "../hooks/useAddDistributor";

const viewImgPath = `${BaseImgUrl}/category/`;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

export default function AddCategory() {
  const [open, setOpen] = React.useState(false);
  const [enable, setEnable] = React.useState(false);
  const handleOpen = () => setOpen(true);

  const dispatch = useDispatch();
  const [form, setForm] = React.useState({});
  const getCategoryImageUpload = useCategoryImageUpload();
  const superCategoryList = useSelector(
    (state) => state.superCategory.superCategoryList
  );
  const addCategory = useAddCategory(form);
  const getAllCategoryList = useCategoryList();
  const handleClose = () => {
    setOpen(false);
    setForm({});
    setError({});
    dispatch(resetCategoryFormData());
  };
  const currentSelectedImage = useSelector(
    (state) => state.category.categoryFormData
  );
  const handleSuperCategory = (e) => {
    const trimmedValue = e.target.value.trim();
    let sc_id = 0;
    for (let i = 0; i < superCategoryList?.length; i++) {
      if (trimmedValue === superCategoryList[i]?.sc_name?.trim()) {
        sc_id = superCategoryList[i]?.sc_id;
        break;
      }
    }

    setForm((prev) => ({
      ...prev,
      superCategory: e.target.value,
      sc_id: sc_id,
    }));
  };

  const handleChange = (e) => {
    setForm((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const [error, setError] = React.useState({});
  const isValid = () => {
    let success = true;
    let err = {};

    if (
      form.category_name?.trim() === "" ||
      form.category_name?.trim() === undefined ||
      form.category_name?.trim() === null
    ) {
      err.name = "**Category Name Cannot be Empty**";
      success = false;
    }
    if (
      form.sc_id === "" ||
      form.sc_id=== undefined ||
      form.sc_id === null
    ) {
      err.super_category = "**Please Select a Super Category**";
      success = false;
    }

    if (
      form.selectedImage?.trim() === "" ||
      form.selectedImage?.trim() === undefined ||
      form.selectedImage?.trim() === null
    ) {
      err.selectedImage = "**Image should be selected**";
      success = false;
    }
    setError(err);
    return success;
  };

  useEffect(() => {
    setEnable(false);
  }, [currentSelectedImage]);

  const handleImageChange = (e) => {
    setForm((prev) => ({
      ...prev,
      [e.target.name]: e.target.files[0],
    }));
    setEnable(true);
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    getCategoryImageUpload(formData);

    if (e.target.files[0]) {
      setForm((prev) => ({
        ...prev,
        selectedImage: e.target.files[0].name,
      }));
    } else {
      setForm((prev) => ({
        ...prev,
        selectedImage: null,
      }));
    }
  };

  const handleSubmit = async(e) => {
    e.preventDefault();

    if (isValid()) {
      await addCategory();
      getAllCategoryList();
      dispatch(resetCategoryFormData());
      setOpen(false);
      setForm({});
    }
  };

  return (
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div>
        <Button
          onClick={handleOpen}
          sx={{
            height: "0.6rem",
            color: "white",
            background: "#EF6C1A",
            padding: "1rem",
            textWrap: "nowrap",
            textTransform:"none",
            ":hover": { backgroundColor: "#EF6C1A" },
          }}
        >
          <AddIcon sx={{ fontSize: "14px", fontWeight: "700" }} />
          Add Category
        </Button>
        <Modal
          open={open}
          // onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={{
              ...style,
              
              margin: "auto", // Center the modal horizontally
              borderRadius: "10px",
              padding: "1.4rem 2.8rem 1.4rem 2.2rem",
            }}>
            {/* <Typography id="modal-modal-title" variant="h6" component="h2">
            Text in a modal
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
          </Typography> */}

            <FormControl
              fullWidth
              size="small"
              sx={{ display: "flex", flexDirection: "column", gap: "1.2rem" }}
            >

              <Stack
                sx={{ display: "flex", flexDirection: "column", gap: "0.4rem" }}
              >
                <Stack sx={{ marginBottom: "1.5rem" }}>
                      <Typography sx={{ color: "#000000", fontWeight:"500",fontSize:"16px" }}>
                        Add Category
                      </Typography>
                    </Stack>
                <input
                  variant="outlined"
                  name="category_name"
                  placeholder="Enter category name"
                  onChange={handleChange}
                  style={{
                    height: "2.2rem",
                    paddingLeft: "0.5rem",
                    fontSize: "0.9rem",
                  }}
                />
                {error.name && (
                  <p
                    style={{
                      color: "red",
                      justifyContent: "center",
                      display: "flex",
                      fontSize: "0.8rem",
                      marginTop: "-0.1rem",
                    }}
                  >
                    {error.name}
                  </p>
                )}
              </Stack>
                {/* Close Icon */}
                <Stack
                    sx={{ position: "absolute", right: 0, cursor: "pointer" }}
                  >
                    <CloseOutlinedIcon onClick={handleClose} />
                  </Stack>
              <Stack sx={{ gap: "0.8rem" }}>
                <select
                  style={{
                    height: "2.6rem",
                    fontSize: "0.9rem",
                    cursor: "pointer",
                    width: "100%",
                    paddingLeft: "0.5rem",
                  }}
                  name="super_category"
                  onChange={handleSuperCategory}
                >
                  <option value="" disabled selected>
                    Select Super Category*
                  </option>
                  {superCategoryList?.map(
                    (item) =>
                      item.catalogue_status && (
                        <option key={item.sc_id}>{item.sc_name}</option>
                      )
                  )}
                </select>
                {error.super_category && (
                  <p
                    style={{
                      color: "red",
                      fontSize: "0.8rem",
                      margin: "0",
                      display:"flex",
                      alignItems:"center",
                      justifyContent:"center"
                    }}
                  >
                    {error.super_category}
                  </p>
                )}
              </Stack>
              <Stack
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "1rem",
                }}
              >
                <label htmlFor="sku_image" style={{ cursor: "pointer" }}>
                  <input
                    id="sku_image"
                    type="file"
                    size="small"
                    accept="image/*"
                    name="sku_image"
                    style={{
                      display: "none",
                    }}
                    onChange={handleImageChange}
                  />

                  <div
                    style={{
                      height: "160px",
                      fontSize: "0.9rem",
                      border: "1px solid #ccc",
                      borderRadius: "4px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      cursor: "pointer",
                      background: "#F2F2F2",
                    }}
                  >
                    {currentSelectedImage ? (
                      <img
                        style={{
                          height: "80%",
                          width: "80%",
                          objectFit: "contain",
                        }}
                        src={currentSelectedImage.full_path}
                        alt="sku_image"
                      />
                    ) : (
                      <>
                        {form.selectedImage ? (
                          form.selectedImage
                        ) : (
                          <Stack
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              justifyContent: "center",
                              gap: "0.5rem",
                            }}
                          >
                            <Stack
                              sx={{
                                background: "#FFFFFF",
                                padding: "0.8rem",
                                borderRadius: "50%",
                              }}
                            >
                              <PhotoCameraOutlinedIcon />
                            </Stack>
                            <Typography>Category Image</Typography>
                          </Stack>
                        )}
                      </>
                    )}
                  </div>
                </label>
                {error.selectedImage && (
                  <p
                    style={{
                      color: "red",
                      fontSize: "0.8rem",
                      margin: "0",
                      display:"flex",
                      alignItems:"center",
                      justifyContent:"center"
                    }}
                  >
                    {error.selectedImage}
                  </p>
                )}
              </Stack>
              <Stack
                  sx={{
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    gap: "0.4rem",
                  }}
                >
                  <Button
                    sx={{
                      background: "white !important",
                      color: "#FF5A5A !important",
                      border: "1px solid #FF5A5A",
                      height: "1.8rem",
                      padding: "18px 10px",
                      fontSize: "12px",
                      fontWeight: 600,
                      textTransform:"none"
                    }}
                    onClick={handleClose}
                  >
                    Discard
                  </Button>
                  <Button
                    sx={{
                      background: "#FF5A5A",
                      color: "white",
                      height: "1.8rem",
                      ":hover": { background: "#FF5A5A" },
                      padding: "18px 10px",
                      fontSize: "12px",
                      fontWeight: 500,
                      textTransform:"none"
                    }}
                    onClick={handleSubmit}
                    // disabled={disable}
                  >
                    Add New Category
                  </Button>
                </Stack>
            </FormControl>
          </Box>
        </Modal>
      </div>
    </>
  );
}
