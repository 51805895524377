import React from "react";
import useAxiosPrivate from "./useAxiosPrivate";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { BaseImgUrl } from "../utility/BaseURL";
const useDeleteSuperCategory = (skuId) => {
  const Axios = useAxiosPrivate();
  const deleteSC = async () => {
    let param = { sc_id: skuId };
    const response = await Axios.post("/delete_sku", param);
    const data = await response.data;
    // console.log(data, "distributorList");
    if (data.code === 200) {
      toast.success("Deleted SuccessFully");
    } else {
      toast.error("Failed to Delete");
    }

    console.log(data);
  };
  return deleteSC;
};

export default useDeleteSuperCategory;
