import useAxiosPrivate from "./useAxiosPrivate";
import { useDispatch } from "react-redux";
import {  } from "../slice/dashboardSlice";
import {  toast } from "react-toastify";
const useSalesDumpDownload = () => {
  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();
  const getSalesDumpDownload = async (date, zoneData) => {
    const param ={
      zone_id: zoneData.zone_id,
      from:date.from,
      to:date.to
    }
    const response = await Axios.post("/sale_dump_download", param);
    const data = await response.data;
    if (data?.code === 200) {
      toast.success(data?.message);
    } else {
      toast.error(data?.message);
    }
    // dispatch(getSRSalesDownloadList(data.body));
    
    return data.body;
  };
  return getSalesDumpDownload;
};

export default useSalesDumpDownload;
