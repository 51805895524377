import * as React from "react";
import { PieChart } from "@mui/x-charts/PieChart";
import { useSelector } from "react-redux";
import { Typography, Box } from "@mui/material";

export default function PieChartAmount() {
  const SRAmountChart = useSelector((state) => state.sr.SRAmountChartDetails);

  return (
    <Box >
    <PieChart
      series={[
        {
          data: SRAmountChart
            ? SRAmountChart?.map((sr, index) => ({
                id: index,
                value: sr.total_cost,
                label: `${sr?.first_name?.substring(0, 12)}...`,
              }))
            : [],
        },
      ]}
      width={400}
      height={250}
      slotProps={{
        legend: {
          direction: 'column',
          position: { vertical: 'middle', horizontal: 'right' },
          padding: 0,
          hidden:true,
        },
      }}
    />
    <Typography
        sx={{
          marginLeft: "6.5rem",
          marginTop: "0.5rem",
          color: "#000000",
          fontWeight: 500,
          fontSize: "22px",
        }}
      >
        Amount
      </Typography>
    </Box>
  );
}
