import React from "react";
import { Container, Typography, Paper, Stack } from "@mui/material";
import NavBar from "./NavBar";
import FooterHero from "./FooterHero";
import Footer from "./Footer";
import { Link as RouterLink } from "react-router-dom";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
const PrivacyPolicy = () => {
  return (
    <div>
      <Stack sx={{ display: { xs: "none", lg: "flex" } }}>
        <NavBar />
      </Stack>
      <Stack>
          <Stack
            sx={{ display: { xs: "flex", lg: "none" }, alignItems:"start" }}
            mt={{ xs: "2rem", lg: "0" }}
            ml={{ xs: "8px", lg: "0" }}
          >
            <RouterLink
              style={{ textDecoration: "none", color: "black" }}
              to={"/"}
            >
              <KeyboardArrowLeftIcon />
            </RouterLink>
          </Stack>
        </Stack>
      <Container maxWidth="lg" style={{ marginTop: "20px" }}>
        <Paper elevation={0} style={{ padding: "20px" }}>
          <Stack>
            <Typography
              variant="h6"
              gutterBottom
              sx={{ fontSize: "24px", fontWeight: "bold", textAlign: "center" }}
            >
              ** Privacy Policy for Distribution Chain Mobile App “Selorce” **
            </Typography>
            <Typography
              variant="body1"
              paragraph
              maxWidth="lg"
              textAlign="left"
            >
              This Privacy Policy describes how Selorce LLP ("we," "us,"
              or "our") collects, uses, and shares information collected from
              users ("you" or "your") of our Distribution Chain Mobile App
              ("Selorce"). We are committed to protecting your privacy and
              the confidentiality of your personal information. By using the
              App, you agree to the terms and conditions of this Privacy Policy.
            </Typography>
            <Typography
              variant="h6"
              sx={{ fontSize: "24px", fontWeight: "bold", textAlign: "left" }}
              marginBottom="20px"
            >
              Information We Collect:
            </Typography>
            <Typography
              variant="body1"
              paragraph
              maxWidth="lg"
              textAlign="left"
              marginBottom="20px"
            >
              1. Personal Information:
              <br />
              - When you register and create an account on the App, we may
              collect personal information such as your name, email address,
              phone number, and address.
              <br />
              2. Transaction Information:
              <br />
              - We may collect information related to your transactions on the
              App, including purchase history, products ordered, delivery
              information, and payment details.
              <br />
              3. Device Information:
              <br />
              - We may automatically collect information about your device,
              including your device type, operating system, unique device
              identifier, IP address, and mobile network information.
              <br />
              4. Location Information:
              <br />
              - With your consent, we may collect information about your precise
              location using GPS, Wi-Fi, or cellular data to provide
              location-based services.
              <br />
            </Typography>
            <Typography
              variant="h6"
              sx={{ fontSize: "24px", fontWeight: "bold", textAlign: "left" }}
              marginBottom="20px"
            >
              How We Use Your Information:
            </Typography>
            <Typography
              variant="body1"
              paragraph
              maxWidth="lg"
              textAlign="left"
              marginBottom="20px"
            >
              1. To Provide and Improve Our Services:
              <br />
              - We use your information to operate, maintain, and improve the
              functionality of the App, including providing customer support and
              troubleshooting technical issues.
              <br />
              2. To Personalize Your Experience:
              <br />
              - We may use your information to personalize your experience on
              the App, such as recommending products based on your purchase
              history and preferences.
              <br />
              3. To Process Transactions:
              <br />
              - We use your transaction information to process orders,
              facilitate payments, and provide order status updates.
              <br />
              4. To Communicate with You:
              <br />
              - We may use your contact information to send you important
              notifications, updates, promotional offers, and marketing
              communications related to our products and services.
              <br />
            </Typography>
            <Typography
              variant="h6"
              sx={{ fontSize: "24px", fontWeight: "bold", textAlign: "left" }}
              marginBottom="20px"
            >
              How We Share Your Information:
            </Typography>
            <Typography
              variant="body1"
              paragraph
              maxWidth="lg"
              textAlign="left"
              marginBottom="20px"
            >
              1. Third-Party Service Providers:
              <br />
              - We may share your information with third-party service providers
              who help us operate the App, process transactions, perform
              analytics, and deliver marketing communications.
              <br />
              2. Business Transfers:
              <br />
              - In the event of a merger, acquisition, reorganization, or sale
              of assets, your information may be transferred as part of the
              transaction. We will notify you via email or prominent notice on
              the App of any change in ownership or use of your information.
              <br />
              3. Legal Compliance:
              <br />
              - We may disclose your information if required by law, regulation,
              or legal process, or if we believe disclosure is necessary to
              protect the rights, property, or safety of our company, users, or
              others.
              <br />
            </Typography>
            <Typography
              variant="h6"
              sx={{ fontSize: "24px", fontWeight: "bold", textAlign: "left" }}
              marginBottom="20px"
            >
              Data Security:
            </Typography>
            <Typography
              variant="body1"
              paragraph
              maxWidth="lg"
              textAlign="left"
              marginBottom="20px"
            >
              We implement reasonable security measures to protect the
              confidentiality and integrity of your information. However, please
              be aware that no method of transmission over the internet or
              electronic storage is 100% secure, and we cannot guarantee
              absolute security.
            </Typography>
            <Typography
              variant="h6"
              sx={{ fontSize: "24px", fontWeight: "bold", textAlign: "left" }}
              marginBottom="20px"
            >
              Your Choices:
            </Typography>
            <Typography
              variant="body1"
              paragraph
              maxWidth="lg"
              textAlign="left"
              marginBottom="20px"
            >
              You may choose not to provide certain information, but this may
              limit your ability to use certain features of the App. You can
              update or correct your account information at any time by
              accessing your account settings on the App.
            </Typography>
            <Typography
              variant="h6"
              sx={{ fontSize: "24px", fontWeight: "bold", textAlign: "left" }}
              marginBottom="20px"
            >
              Age Restriction:
            </Typography>
            <Typography
              variant="body1"
              paragraph
              maxWidth="lg"
              textAlign="left"
              marginBottom="20px"
            >
              The App is not intended for individuals under the age of 18. We do
              not knowingly allow any person who is under the age of 18 to use
              the App. Additionally, we collect the consent of the user that
              he/she is above 18 years of age at the time of onboarding. App use
              is restricted in case consent is not provided.
            </Typography>
            <Typography
              variant="h6"
              sx={{ fontSize: "24px", fontWeight: "bold", textAlign: "left" }}
              marginBottom="20px"
            >
              Changes to This Privacy Policy:
            </Typography>
            <Typography
              variant="body1"
              paragraph
              maxWidth="lg"
              textAlign="left"
              marginBottom="20px"
            >
              We reserve the right to update or change this Privacy Policy at
              any time. We will notify you of any changes by posting the new
              Privacy Policy on this page. Your continued use of the App after
              any modifications indicate your acceptance of the changes.
            </Typography>
            <Typography
              variant="h6"
              sx={{ fontSize: "24px", fontWeight: "bold", textAlign: "left" }}
              marginBottom="20px"
            >
              Contact Us:
            </Typography>
            <Typography
              variant="body1"
              paragraph
              maxWidth="lg"
              textAlign="left"
              marginBottom="20px"
            >
              If you have any questions or concerns about this Privacy Policy,
              please contact us at snehal.p@vitarangt.com.
            </Typography>
            <Typography
              variant="h6"
              sx={{ fontSize: "24px", fontWeight: "bold", textAlign: "left" }}
              marginBottom="20px"
            >
              Effective Date:
            </Typography>
            <Typography
              variant="body1"
              paragraph
              maxWidth="lg"
              textAlign="left"
              marginBottom="20px"
            >
              This Privacy Policy is effective as of [Effective Date] and
              supersedes all previous versions.
            </Typography>
          </Stack>
        </Paper>
      </Container>
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
