import React, { useEffect, useState } from "react";

import { MenuItem, Stack, Typography, Button } from "@mui/material";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import useSuperCategoryList from "../../hooks/useSuperCategoryList";
import { useSelector } from "react-redux";
import useCategoryListBySuperCategory from "../../hooks/useCategoryListBySuperCategory";
import AddIcon from "@mui/icons-material/Add";
import { Link, useNavigate } from "react-router-dom";
import useSkuListByCategory from "../../hooks/useSkuListByCategory";
import useSchemeDetailsList from "../../hooks/useSchemeDetailsList";
import Popover from "@mui/material/Popover";
import Divider from "@mui/material/Divider";
import InputLabel from "@mui/material/InputLabel";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from "@mui/material";
import useAddCampaign from "../../hooks/useAddCampaign";
import useCampaignSchemeList from "../../hooks/useCampaignSchemeList";
import { getSkuListByCategory } from "../../slice/skuSlice";

const SchemeDropdown = ({setdropDown}) => {
  const navigate = useNavigate();
  const getSuperCategory = useSuperCategoryList();
  const getCategoryList = useCategoryListBySuperCategory();
  const getSkuList = useSkuListByCategory();
  const getSchemeList = useSchemeDetailsList();
  const superCategoryList = useSelector(
    (state) => state.superCategory.superCategoryList
  );
  const categoryList = useSelector(
    (state) => state.category.categoryListBySuperCategory
  );
  const skuList = useSelector((state) => state.sku.skuListByCategory);
  const zoneData = useSelector((state) => state.app.curretDropdownData);
  const [form, setForm] = React.useState({
    sc_name: superCategoryList?.[0]?.sc_name || '',
    sc_id: superCategoryList?.[0]?.sc_id || 0,
    scIdArray: superCategoryList?.[0] ? [superCategoryList[0].sc_id] : [],
    status: true,
  });
  const [openPopUp, setOpenPopUp] = useState(false);
  const [campaignName, setCampaignName] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState('All');
  const [selectedSku, setSelectedSku] = useState('All');
  const [categoryListWithAll, setCategoryListWithAll] = useState([]);
  const [skuListWithAll, setSkuListWithAll] = useState([]);
  useEffect(() => {
    if(categoryList){
  const NewcategoryList = [{ category_id: 0, category_name: 'All', status :true }, ...categoryList];
  setCategoryListWithAll(NewcategoryList);
    }
  },[categoryList]);

  useEffect(()=>{
    setdropDown(form);
    
  }, [form])
  useEffect(() => {
    if(skuList){
    const NewcategoryList = [{ sku_id: 0, sku_name: 'All', status :true }, ...skuList];
    setSkuListWithAll(NewcategoryList);
    }
    setForm((prev) => ({
      ...prev,
      sku_name:"All",
      sku_id:0,
    }))
  },[skuList]);
  useEffect(() => {
    getSuperCategory();
  }, []);

  useEffect(() => {
    if (superCategoryList?.length > 0) {
      const firstCategory = superCategoryList[0];
      setForm((prev) => ({
        ...prev,
        sc_name: firstCategory.sc_name,
        sc_id: firstCategory.sc_id,
        scIdArray: [firstCategory.sc_id]
      }));
      getCategoryList(firstCategory.sc_id);
    }
    
  }, [superCategoryList]);

  useEffect(() => {
    if (selectedCategory === "All") {
      const categoryIds = categoryList?.map(category => category.category_id);
      getSkuList(0, form.sc_id, categoryIds);
      getFirstSchemeList();
    }
   }, [categoryList, zoneData]);
   const getFirstSchemeList = async() =>{
    const param = {
      status: 'active',
      sku_id: form.sku_id,
      sc_id: form.sc_id,
      zone_id:zoneData?.zone_id
    };
    await getSchemeList(param);
   }
  const handleSuperCategory = async (e) => {
    let scId = 0;
    let scIdArray = [];
    let scName = null;

    for (let i = 0; i < superCategoryList?.length; i++) {
      if (e.target.value === superCategoryList[i]?.sc_name) {
        scId = superCategoryList[i]?.sc_id;
        scName = superCategoryList[i]?.sc_name;
        scIdArray.push(scId);
        break;
      }
    }

    // Update the state
    setForm((prev) => ({
      ...prev,
      sc_name: scName,
      sc_id: scId,
      scIdArray: scIdArray,
    }));

    await getCategoryList(scId);
  };

  const handleCategoryChange = async (e) => {
    const selectedValue = e.target.value;
    let CategoryId = 0;
    let CategoryIdArray = [];
    let CategoryName = selectedValue;

    if (selectedValue !== 'All') {
      for (let i = 0; i < categoryList.length; i++) {
        if (selectedValue === categoryList[i].category_name) {
          CategoryId = categoryList[i].category_id;
          CategoryIdArray.push(CategoryId);
          break;
        }
      }
    } else {
      CategoryIdArray = categoryList.map(category => category.category_id);
    }

    // Update the state
    setForm((prev) => ({
      ...prev,
      CategoryName: CategoryName,
      CategoryId: CategoryId,
      CategoryIdArray: CategoryIdArray,
    }));

    await getSkuList(CategoryId, form.sc_id, CategoryIdArray);
  };
  const handleSkuChange = async (e) => {
    let sku_id = 0;

    for (let i = 0; i < skuList?.length; i++) {
      if (e.target.value === skuList[i]?.sku_name) {
        sku_id = skuList[i]?.sku_id;
        break;
      }
    }
    setForm((prev) => ({
      ...prev,
      sku_id: sku_id,
    }));
    const param = {
      status: form.status,
      sku_id: sku_id,
      zone_id:zoneData?.zone_id,
      sc_id:form.sc_id
    };

    await getSchemeList(param);
  };

  const handleStatusChange = async (event) => {
    const status = event.target.value === "active";
    setForm((prev) => ({
      ...prev,
      status: status,
    }));
    const param = {
      status: status===true?"active":"inactive",
      sku_id: form.sku_id,
      sc_id: form.sc_id,
      zone_id:zoneData?.zone_id
    };
    await getSchemeList(param);
  };
  
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpenPopUp(false);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClickOpen = () => {
    setOpenPopUp(true);
  };
  const addCampaign = useAddCampaign();
  const getCampaignSchemes =  useCampaignSchemeList();
  const handleSubmit = async () => {

    const param = {
      campaign_name: campaignName
    };
    const data = await addCampaign(param);
    await getCampaignSchemes(data.body);
    setOpenPopUp(false);
    navigate('/home/addcampaign'); 
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

 
  return (
    <Box
      sx={{
        minWidth: 140,
        display: "flex",
        flexDirection: "row",
        gap: "1rem",
        width: "100%",
        justifyContent: "space-between",
        marginTop:"6px"
      }}
    >
      <Stack
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "1rem",
          alignItems: "center",
        }}
      >
        <Typography>Filter By:</Typography>
        <FormControl
          sx={{
            minWidth: 160,
            backgroundColor: "#fff",
            "& .MuiSelect-select": { backgroundColor: "#fff" },
          }}
        >
          <InputLabel sx={{ top: "-15px", color: "#989797" }}>
            Super Category
          </InputLabel>
          <Select
      labelId="sc-label"
      id="sc-select"
      value={form.sc_name}
      size="small"
      sx={{ height: "1.8rem" }}
      onChange={handleSuperCategory}
    >
      {superCategoryList?.filter((sku) => sku.status === true)
      .map((item, index) => (
        <MenuItem value={item.sc_name} key={item.sc_id}>
          {item.sc_name}
        </MenuItem>
      ))}
    </Select>
        </FormControl>
        {/* <FormControl
          sx={{
            minWidth: 160,
            backgroundColor: "#fff",
            "& .MuiSelect-select": { backgroundColor: "#fff" },
          }}
        >
          <InputLabel sx={{ top: "-15px", color: "#989797" }}>
            Category
          </InputLabel>
          <Select
      labelId="shape-id-label"
      id="shape-id-select"
      value={selectedCategory}
      size="small"
      sx={{ height: "1.8rem" }}
      onChange={(e) => {
        setSelectedCategory(e.target.value);
        handleCategoryChange(e);
      }}
    >
      {categoryListWithAll.filter((sku) => sku.status === true)
      .map((item) => (
        <MenuItem value={item.category_name} key={item.category_id}>
          {item.category_name}
        </MenuItem>
      ))}
    </Select>
        </FormControl> */}
        {/* <FormControl
          sx={{
            minWidth: 160,
            backgroundColor: "#fff",
            "& .MuiSelect-select": { backgroundColor: "#fff" },
          }}
        >
          <InputLabel sx={{ top: "-15px", color: "#989797" }}>
            SKU
          </InputLabel>
          <Select
            labelId="shape-id-label"
            id="shape-id-select"
              value={selectedSku}
            size="small"
            sx={{ height: "1.8rem" }}
            onChange={(e) => {
              setSelectedSku(e.target.value);
              handleSkuChange(e);
            }}
          >
            {skuListWithAll.filter((sku) => sku.status === true)
            ?.map((item, index) => (
              <MenuItem value={item.sku_name} key={item.sku_id}>
                {item.sku_name}
              </MenuItem>
            ))}
          </Select>
        </FormControl> */}
        <FormControl
          sx={{
            minWidth: 160,
            backgroundColor: "#fff",
            "& .MuiSelect-select": { backgroundColor: "#fff" },
          }}
        >
          <Select
            labelId="status-id-label"
            id="status-id-select"
            value={form.status ? "active" : "inactive"}
            size="small"
            sx={{ height: "1.8rem" }}
            onChange={handleStatusChange}
          >
            <MenuItem value="active">Active</MenuItem>
            <MenuItem value="inactive">Inactive</MenuItem>
          </Select>
        </FormControl>
      </Stack>
      <Stack>
        <div>
        <Link to="/home/addScheme" style={{ textDecoration: "none" }}>
          <Button
            aria-describedby={id}
            variant="contained"
            // onClick={handleClick}
            startIcon={<AddIcon />}
            sx={{
              textTransform: "none",
              padding: "15px 21.8px 15px 21.8px",
              fontSize: "14px",
              fontWeight: "700",
            }}
          >
            Create
          </Button>
          </Link>
          {/* <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            sx={{
              boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1) !important",
              borderRadius: "4px",
              border: "1px solid gray",
            }}
          >
            <Typography
              sx={{
                padding: "10px 16px",
                display: "flex",
                flexDirection: "column",
                gap: "2px",
              }}
            >
              <Link to="/home/addScheme" style={{ textDecoration: "none" }}>
                <Typography
                  sx={{
                    backgroundColor: "#fff !important",
                    color: "#000 !important",
                    textAlign: "center",
                  }}
                >
                  Scheme
                </Typography>
              </Link>
              <Divider
                variant="middle"
                sx={{ borderBottom: "2px Solid #BABABA" }}
              />
              <Typography
                sx={{
                  backgroundColor: "#fff !important",
                  color: "#000 !important",
                  textAlign: "center",
                  cursor: "pointer",
                }}
                onClick={handleClickOpen}
              >
                Campaign
              </Typography>
              <Dialog open={openPopUp} onClose={handleClose}>
                <DialogTitle
                  sx={{
                    padding: "16px 32px",
                    fontSize: "16px",
                    fontWeight: "700",
                  }}
                >
                  Create Campaign
                </DialogTitle>
                <DialogContent sx={{ p: 4, minWidth: "350px" }}>
                  <TextField
                    autoFocus
                    margin="dense"
                    placeholder="Enter Campaign Name *"
                    type="text"
                    size="small"
                    fullWidth
                    value={campaignName}
                    onChange={(e) => setCampaignName(e.target.value)}
                  />
                </DialogContent>
                <DialogActions sx={{ padding: "0px 32px 16px 16px" }}>
                  <Button onClick={handleClose} color="primary">
                    Cancel
                  </Button>
                  <Button onClick={handleSubmit} color="primary">
                    Submit
                  </Button>
                </DialogActions>
              </Dialog>
            </Typography>
          </Popover> */}
        </div>
      </Stack>
    </Box>
  );
};

export default SchemeDropdown;
