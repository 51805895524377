import React, { useState } from "react";
import BasicTabs from "./Tab";
import {Stack} from "@mui/material"
import Sidebar from "./Sidebar";
import InventoryMgt from "./InventoryMgt";

const SkuCategoryPage = () => {
  const [display, setDisplay] = useState("sku");
  return (
    <Stack
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        backgroundColor: "#E5E5E5",
      }}
    >
      <Stack>{/* <DashboardHeader /> */}</Stack>
      <Stack sx={{ display: "flex", flexDirection: "row", gap: "4px" }}>
        <Sidebar />
        {display === "sku" ? <BasicTabs setDisplay={setDisplay} /> : <InventoryMgt setDisplay={setDisplay}/>}
      </Stack>
    </Stack>
  );
};

export default SkuCategoryPage;
