import useAxiosPrivate from "./useAxiosPrivate";
import useImageAxiosPrivate from "./useImageAxiosPrivate";
import { ToastContainer, toast } from "react-toastify";
const useUploadInventoryExcel = () => {
  const ImageAxiosPrivate = useImageAxiosPrivate();

  const getUploadInventoryExcel = async (param) => {
    try {
      const response = await ImageAxiosPrivate.post("upload_inventory_excel", param);

      const data = response.data;
      if (data.code === 200) {
        toast.success(data.message);
      } else {
        toast.error(data.message);
      }
      return data;
    } catch (error) {
      console.error("Error data:", error);
      throw error;
    }
  };
  return getUploadInventoryExcel;
};
export default useUploadInventoryExcel;
