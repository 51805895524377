import useAxiosPrivate from "./useAxiosPrivate";
import { toast } from "react-toastify";
const useInventoryTemplateDownload = () => {
  const Axios = useAxiosPrivate();

  const getInventoryTemplate = async (param) => {
    try {
      const response = await Axios.post("inventory_template_download", param); 
      if (response.data.code === 200) {
        toast.success(response.data.message);
      } else {
        toast.error(response.data.message);
      }
      const data = response.data.body;
      return data;
    } catch (error) {
      console.error("Error data:", error);
      throw error;
    }
  };
  return getInventoryTemplate;
};
export default useInventoryTemplateDownload;
