import { useEffect } from "react";
import useAxiosPrivate from "./useAxiosPrivate";
import { useDispatch, useSelector } from "react-redux";
import { getDistributorList } from "../slice/distributorSlice";
import { getSOList } from "../slice/soSlice";
import {
  getSRGraphDetails,
  getSRList,
  getSRTableDetails,
  getSRTimeTableDetails,
} from "../slice/srSlice";

const useSRTimeTableDetails = () => {
  const currentzone = useSelector((state) => state.assignRoute.zone_id);
  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();
  const getAllTimeSRTableDetails = async (param) => {

      const response = await Axios.post("/sr_time_table_details", param);
      const data = await response.data;
      dispatch(getSRTimeTableDetails(data.body));
    
  };
  // useEffect(() => {
  //   getAllTimeSRTableDetails();
  // }, [month]);

  return getAllTimeSRTableDetails;
};

export default useSRTimeTableDetails;
