import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { useSelector } from "react-redux";
import useSRTableDetails from "../../../hooks/useSRTableDetails";

export default function CallStampTable({workingDaysInMonth}) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const row = [
    "SE",
    "Calls Target",
    "No Sale",
    "Sale",
    "Achieved",
    "% Achieved"
  ];

  //Redux
  const tableDetails = useSelector((store) => store.sr.SRTableDetails);

  //Api Calls
  // useSRTableDetails();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [searchTerm, setSearchTerm] = React.useState('');
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  return (
    <>
    <input
        type="text"
        placeholder="Search"
        style={{
          width:"15rem",
          height: "2rem",
          fontSize: "14px",
          fontFamily: "inherit",
          borderRadius: "8px",
          padding: "4px 8px", 
          border: "1px solid #c4c4c4", 
          outline: "none",
        }}
        onChange={handleSearchChange} 
        value={searchTerm} 
      />
    <Paper sx={{ width: "100%" }}>
      {/* <TableContainer sx={{ maxHeight: 440 }}></TableContainer> */}
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {row?.map((item, index) => (
                <TableCell
                  key={index}
                  sx={{
                    background: "#F8F8F8",
                    fontSize: "16px",
                    color: "#5F6868",
                    fontWeight: "500",
                  }}
                  align="start"
                  colSpan={1}
                >
                  {item}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {tableDetails
            ?.filter((sr)=>sr?.se_name?.toLowerCase()?.includes(searchTerm?.toLowerCase()))
              // ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              ?.map((data, index) => (
                <>
                  <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                    {/* <TableCell align="start" colSpan={1}>
                      <div key={index}>{data?.so_name}</div>
                    </TableCell> */}
                    <TableCell align="start" colSpan={1}>
                      <React.Fragment key={index}>
                        {data?.se_name}
                      </React.Fragment>
                    </TableCell>
                    {/* <TableCell align="start" colSpan={1}>
                      {data?.route_code}
                    </TableCell> */}
                    <TableCell align="start" colSpan={1}>
                      {data?.total_outlet}
                    </TableCell>
                    <TableCell align="start" colSpan={1}>
                      {data?.no_sale}
                    </TableCell>
                    <TableCell align="start" colSpan={1}>
                      {data?.sale}
                    </TableCell>
                    <TableCell align="start" colSpan={1}>
                      {parseFloat(data?.sale) + parseFloat(data?.no_sale)}
                    </TableCell>
                    <TableCell align="start" colSpan={1}>
                      {parseFloat(data?.total_outlet) === 0
                        ? "0"
                        : (
                            ((parseFloat(data?.sale) + parseFloat(data?.no_sale)) /parseFloat(data?.total_outlet)) *100).toFixed(2)}
                      %
                    </TableCell>
                  </TableRow>
                </>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      {/* <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={tableDetails?.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      /> */}
    </Paper>
    </>
    
  );
}
