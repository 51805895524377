import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getBeatList, getEmployeeAreaList } from "../../slice/routeSlice";
import useAxiosPrivate from "../useAxiosPrivate";


const useBeatList = () => {
  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();
  const getAllBeatList = async (param) => {
    
    const response = await Axios.post("route-list",param);
    const data = await response.data;
    dispatch(getBeatList(data.data));
    return data.data
  };

  return getAllBeatList
};

export default useBeatList;
