import { Button } from '@mui/material'
import React, { useState, useEffect } from 'react'
import styles from './styles/EmployeeAreaTopContainer.module.css';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { styled } from '@mui/material/styles';
import { useSelector, useDispatch } from 'react-redux'
import EmployeeAreaTable from './EmployeeAreaTable';


const RedButton = styled(Button)(({ theme }) => ({
    color: "white",
    backgroundColor: "#731D3A",
    '&:hover': {
        backgroundColor: "#731D3A",
    },
}));

const EmployeeAreaTopContainer = ({ token, setRootZone, rootzone }) => {

    return (
        <div className={styles.RouteMgtTopContainer}>
            <div className={styles.RouteMgtTopWrapper}>
               

            </div>
            <div className={styles.RouteMgtBottomWrapper}>
                <EmployeeAreaTable rootzone={rootzone}/>
            </div>

        </div>
    )
}

export default EmployeeAreaTopContainer
