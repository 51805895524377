import React from 'react';
import Styles from './styles/producttable.module.css';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { useSelector, useDispatch } from 'react-redux'
import {GETDETAIL} from "../constants/ProductMgt/product"



const RedButton = styled(Button)(({ theme }) => ({
    color: "#731D3A",
    backgroundColor: "white",
    '&:hover': {
        backgroundColor: "#731D3A",
        color: "white",
        border:"2px solid #731D3A"
    },
    border:"2px solid #731D3A"
}));


const useStyles = makeStyles({
    head: {
        borderBottom: "2px solid black"
    }
})
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#E5E5E5",
        color: "black",
        fontSize: "14px",
        fontWeight: "600"
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));


const ProductMgtTable = () => {
    const dispatch = useDispatch();
    const columns = [ "Product Id", "Product Name", "Cost", "Action"];
    const state = useSelector(state => state.ProductMgt);
    // let Id = useSelector(state => state.ProductMgt.id);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleClick=(item)=>{
        dispatch({type:GETDETAIL,payload:item})
        document.getElementById("ProductMgtDetail").scrollIntoView({ behavior: 'smooth',block: "end", inline: "nearest" })
    }

    

    return (
        <div className={Styles.HeirarchyContainer}>
            <TableContainer sx={{ maxHeight: 440, minWidth: "100%" }}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead className={Styles.head} sx={{ backgroundColor: "#c4c4c4" }}>
                        <TableRow className={Styles.head} sx={{ backgroundColor: "#c4c4c4" }}>
                            {columns.map((column, index) => (
                                <StyledTableCell
                                    key={index}
                                    align="center"
                                // style={{ minWidth: column.minWidth }}
                                >
                                    {column}
                                </StyledTableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            state && state.list && state.list.length !== 0 && state.list !== "undefined" && state.list !== null ? (
                                state.list.map((item, index) => (
                                    // <TableRow sx={Id === item.product_id ? ({ backgroundColor: "#faebf0" }) : ({ backgroundColor: "white" })}>
                                    <TableRow>
                                        {/* <TableCell align="center">
                                            {item.zone_name}
                                        </TableCell> */}
                                        <TableCell align="center">
                                            {item.product_id}
                                        </TableCell>
                                        <TableCell align="center">
                                            {item.product_name}
                                        </TableCell>
                                        <TableCell align="center">
                                        ₹ {item.cost}
                                        </TableCell>
                                        <TableCell  align="center">
                                            <RedButton variant="contained" onClick={()=>handleClick(item)}>Details</RedButton>
                                        </TableCell>
                                    </TableRow>
                                ))
                            ) :
                                (
                                    <TableRow>
                                        <TableCell align="center">
                                            No Data
                                        </TableCell>
                                    </TableRow>
                                )
                        }

                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={state && state.list && state.list.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </div>
    )
}

export default ProductMgtTable
