import useImageAxiosPrivate from "../useImageAxiosPrivate";
const useTargetDumpUpload = () => {
    const ImageAxiosPrivate = useImageAxiosPrivate();
  const getTargetDumpUpload = async (param) => {
    const response = await ImageAxiosPrivate.post("/target_dump_upload", param);
    const data = await response.data;
    return data;
  };
  return getTargetDumpUpload;
};

export default useTargetDumpUpload;
