
import useAxiosPrivate from "./useAxiosPrivate";
import { useDispatch } from "react-redux";

import { toast } from "react-toastify";
const useUpdateSaathiRouteStatus = () => {
  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();

  const UpdateRoute = async (param) => {
    try {
      const response = await Axios.post("/update_saathi_route_status", param);
      const data = response.data;
      if (data.code === 200) {
        toast.success(data?.message);
      } else {
        toast.error(data?.messag);
      } 
      return data;  
    } catch (error) {
      console.error("Error fetching admin map data:", error);
      throw error;  
    }
  };
  return UpdateRoute;
};

export default useUpdateSaathiRouteStatus;


