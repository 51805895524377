import { useSelector } from "react-redux";
import useAxiosPrivate from "./useAxiosPrivate";
import { toast } from "react-toastify";

const useEditScheme = () => {
  const Axios = useAxiosPrivate();

  const EditScheme = async (param) => {
    try {
      const response = await Axios.post("edit_scheme", param);
      const data = response.data;
      if (data.code === 200) {
        toast.success("Scheme Update SuccessFully");
      } else {
        toast.error("Update Failed");
      } 
      return data;  
    } catch (error) {
      console.error("Error fetching admin map data:", error);
      throw error;  
    }
  };
  return EditScheme;
};

export default  useEditScheme;