import { createSlice } from "@reduxjs/toolkit";

const retailerSlice = createSlice({
  name: "retailer",
  initialState: {
    retailerList: null,
    retailerUserDetails: null,
    schemaRetailerList: null,
  },
  reducers: {
    getRetailerList: (state, action) => {
      state.retailerList = action.payload;
    },
    getSchemeRetailerList: (state, action) => {
      state.schemaRetailerList = action.payload;
    },
    getRetailerUserDetails: (state, action) => {
      state.retailerUserDetails = action.payload;
    },
    getRouterPath: (state, action) => {
      state.routerPath = action.payload;
    },
  },
});

export default retailerSlice.reducer;
export const { getRetailerList, getRouterPath, getRetailerUserDetails,getSchemeRetailerList } =
  retailerSlice.actions;
