import React from "react";
import useAxiosPrivate from "./useAxiosPrivate";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { BaseImgUrl } from "../utility/BaseURL";
const useAddCategory = (form) => {
  const Axios = useAxiosPrivate();
  const categoryFormData = useSelector(
    (store) => store.category.categoryFormData
  );
  const addCategory = async () => {
    let param = {
      category_name: form?.category_name,
      category_image: categoryFormData?.full_path,
      sc_id: form?.sc_id,
    };
    const response = await Axios.post("/add_category", param);
    const data = await response.data;
    if (data.code === 200) {
      toast.success("Added SuccessFully");
    } else if (data.code === 400) {
      toast.error(data?.message);
    } else {
      toast.error("Failed to Add");
    }
  };
  return addCategory;
};

export default useAddCategory;
