import React, { useState, useEffect } from 'react';
import Styles from './styles/ApprovalCoinTransaction.module.css';
import TextField from '@mui/material/TextField';
import ApprovalCoinTransactionTable from './ApproveCoinTransactionTable';
import { useSelector } from 'react-redux';

const ApproveCoinTransaction = ({token}) => {
    const approval = useSelector(state => state.ApprovalMgt)
    const Searchcolumn = ["wd_id", "name", "surname", "trans_id"];
    const [q, setQ] = useState('');

    const Searchfilter = (datas) => {
        return datas.filter((row) =>
            Searchcolumn.some(column => row[column] !== null && row[column] !== "undefined" && row[column].length !== 0 ? (row[column].toString().toLowerCase().indexOf(q.toLowerCase()) > -1) : (""))

        )
    }


    return (
        <div className={Styles.container}>
            <div className={Styles.TitleContainer}>
                <label className={Styles.Title}>Coin Transaction</label>
            </div>
            <div className={Styles.TextContainer}>
                <TextField variant="outlined" size="small" placeholder='Search wD..' sx={{ width: "30%" }} onChange={e => setQ(e.target.value)} />
            </div>
            <div className={Styles.TableContainer}>
                {/* <ApprovalCoinTransactionTable list={Searchfilter(approval.ApprovalListCoin)} token={token}/> */}
                <ApprovalCoinTransactionTable  token={token}/>
            </div>
        </div>
    )
};

export default ApproveCoinTransaction;
