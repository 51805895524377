import useAxiosPrivate from "./useAxiosPrivate";

const useSRPerformanceEcoCallReport = () => {
  const Axios = useAxiosPrivate();
  const getSRPerformanceEcoCallReport = async (date, zone_id) => {
    // Replace '/' with '-' in the date
    const formattedDate = date.replace(/\//g, "-");

    const param = {
      date: formattedDate,
      zone_id: zone_id,
    };
    
    const response = await Axios.post("/sr_performance_eco_sales_report", param);
    const data = await response.data;
    return data.body;
  };
  return getSRPerformanceEcoCallReport;
};

export default useSRPerformanceEcoCallReport;
