import { useEffect } from "react";
import useAxiosPrivate from "./useAxiosPrivate";
import { useDispatch, useSelector } from "react-redux";
import { getInventoryList } from "../slice/inventorySlice";

const useDistInventoryList = () => {
  const currentzone = useSelector((state) => state.assignRoute.zone_id);
  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();
  const getDistInventoryList = async (param) => {
    if (currentzone) {
    const response = await Axios.post("/get_inventory_list", param);
    const data = await response.data;
    dispatch(getInventoryList(data.body));
    }
  };

  return getDistInventoryList;
};

export default useDistInventoryList;
