import { createSlice } from "@reduxjs/toolkit";

const appSlice = createSlice({
  name: "app",
  initialState: {
    stateList: null,
    zoneList: null,
    curretDropdownData: null,
    schemeZoneList:null,
    dashboardData: null
  },
  reducers: {
    getStateList: (state, action) => {
      state.stateList = action.payload;
    },
    getZoneList: (state, action) => {
      state.zoneList = action.payload;
    },
    getSchemeZoneList: (state, action) => {
      state.schemeZoneList = action.payload;
    },
    getCurrentDropdownData: (state, action) => {
      state.curretDropdownData = action.payload;
    },
    getDashboardData: (state, action) => {
      state.dashboardData = action.payload;
    },
  },
});

export default appSlice.reducer;
export const { getZoneList, getStateList, getCurrentDropdownData,getSchemeZoneList, getDashboardData } =
  appSlice.actions;
