import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { useSelector } from "react-redux";
// import SRGraphDetails from "./data.json"
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
      position: "bottom",
      labels: {
        boxWidth: 10,
        padding: 10,
        font: {
          size: 12,
        },
      },
    },
    title: {
      display: true,
      text: "Calls Chart",
    }
  },
  scales: {
    x: {
      stacked: true,
      ticks: {
        autoSkip: false, 
      },
    },
    y: {
      stacked: false,
    },
  },
};

function getRandomColors(count) {
  const colors = [];
  for (let i = 0; i < count; i++) {
    const r = Math.floor(Math.random() * 64);
    const g = Math.floor(Math.random() * 64);
    const b = Math.floor(Math.random() * 64);
    colors.push(`rgba(${r}, ${g}, ${b}, 0.8)`);
  }
  return colors;
}

export default function BarGraphNew() {
  const SRGraphDetails = useSelector((state) => state.sr.SRGraphDetails);
  const [randomColors, setRandomColors] = useState([]);

  useEffect(() => {
    if (SRGraphDetails?.body?.length) {
      const randomColors = getRandomColors(SRGraphDetails?.body?.length);
      setRandomColors(randomColors);
    }
  }, [SRGraphDetails]);

  const data = {
    labels: SRGraphDetails?.srList || [],
    datasets: [
      {
        label: "Total Calls",
        data: SRGraphDetails?.body?.map((item) => item.total_calls) || [],
        backgroundColor: randomColors,
      },
    ],
  };

  return (
    <div style={{ height: "500px", width: "100%" }}>
      <Bar options={options} data={data} />
    </div>
  );
}
