import { useEffect } from "react";
import useAxiosPrivate from "./useAxiosPrivate";
import { useDispatch, useSelector } from "react-redux";
import { getDistributorList } from "../slice/distributorSlice";
import { getSOList } from "../slice/soSlice";
import {
  getSRGraphDetails,
  getSRList,
  getSRSaleTableDetails,
  getSRTableDetails,
  getSRTimeTableDetails,
} from "../slice/srSlice";

const useSRSaleTableDetails = (date,currentFirstDate,currentLastDate) => {
  const zoneData = useSelector((state) =>state.app.curretDropdownData);
  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();
  const getAllSaleSRTableDetails = async () => {
    if (date || (currentFirstDate && currentLastDate)) {
      let param = {
        date: date,
        first_date: currentFirstDate,
        last_date: currentLastDate,
        zone_id: zoneData?.zone_id
      };
      const response = await Axios.post("/sr_sale_table_details", param);
      const data = await response.data;
      dispatch(getSRSaleTableDetails(data.body));
    }
  };
  useEffect(() => {
    getAllSaleSRTableDetails();
  }, [date,currentFirstDate,currentLastDate]);

  return getAllSaleSRTableDetails;
};

export default useSRSaleTableDetails;
