import { useEffect } from "react";
import useAxiosPrivate from "./useAxiosPrivate";
import { useDispatch } from "react-redux";
import { getCategoryList } from "../slice/categorySlice";
import { getSkuList, getSkuListByCategory, getSkuListByCategoryMrp, getSkuListByMrp } from "../slice/skuSlice";

const useSkuListByMrp = () => {
  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();
  const getAllSkuListByMrp = async (mrp) => {
    
    const filteredMrp=mrp?.filter((mrp)=>mrp!='All')
    let param={
      mrp: filteredMrp,
    }
    const response = await Axios.post("/sku_list_by_mrp",param);
    const data = await response.data;
    dispatch(getSkuListByMrp(data.body));
    return data.body
  };
  // useEffect(() => {
  //   getAllSkuList();
  // }, []);

  return getAllSkuListByMrp
};

export default useSkuListByMrp;
