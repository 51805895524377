import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
// import { GetUserMgtWholesalerList } from '../../../../actions/UserMgt/UserMgt';
import UserMgtWholesalerTable from "./UserMgtWholesalerTable";
import UserMgtWholesalerUpdateForm from "./UserMgtWholesalerUpdateForm";
import styles from "../styles/UserMgtWholesaler.module.css";
import Button from "@mui/material/Button";
import { FormControl, MenuItem, Select, Stack } from '@mui/material';
import { styled } from "@mui/material/styles";
import { IconButton, InputAdornment, TextField } from "@material-ui/core";
import SearchIcon from "@mui/icons-material/Search";
import Modal from "../../../utility/Modal";
import useDistributorList from "../../../hooks/useDistributorList";
import { Box } from "@mui/material";
import ExcelDownload from "./ExcelDownload";
import ExcelUpload from "./ExcelUpload";
import useSuperStockistList from "../../../hooks/useSuperStockistList";

const RedButton = styled(Button)(({ theme }) => ({
  color: "white",
  backgroundColor: "#EF6C1A",
  "&:hover": {
    backgroundColor: "#EF6C1A",
  },
}));

const SuperStockist = ({ zone }) => {
  const [q, setQ] = useState("");
  const userlist = useSelector((state) => state.UserMgt);
  const currentZone = useSelector((state) => state.app.curretDropdownData);
  const [status, setStatus] = useState(true); 

  const handleChange = (event) => {
    const value = event.target.value === 'Active' ? true : false;
    setStatus(value);
  };
  const [searchColumn, setSearchColumn] = useState([
    "user_id",
    "user_code",
    "name",
    "surname",
    "mobile_no",
  ]);
  const superStockistList = useSelector(
    (store) => store.distributor.superStockistList
  );

  
  const getSuperStockistList = useSuperStockistList();
  useEffect(() => {
    getSuperStockistList(status);
  }, [currentZone, status]);

  const [filterlist, setFilterlist] = useState([]);
  const [Userdetail, setUserDetail] = useState({});

  React.useEffect(() => {
    if (
      userlist &&
      userlist.wholesalerList &&
      userlist.wholesalerList.length !== 0 &&
      userlist.wholesalerList !== "undefined" &&
      userlist.wholesalerList !== null
    ) {
      setFilterlist(userlist.wholesalerList);
      setUserDetail(userlist.wholesalerList[0]);
    }
  }, [userlist]);


  const Searchfilter = (datas) => {
    return datas.filter((row) =>
      searchColumn.some((column) =>
        row[column] !== null &&
        row[column] !== "undefined" &&
        row[column].length !== 0
          ? row[column].toString().toLowerCase().indexOf(q.toLowerCase()) > -1
          : ""
      )
    );
  };


  const [isAdd, setIsAdd] = useState(false);

  const handleAdd = () => {
    setIsAdd(true);
  };

  const [query, setQuery] = useState("");
  const handleSearch = (e) => {
    setQuery(e.target.value);
  };
  const [filterDistributorList, setFilterDistributorList] = useState(null);

  useEffect(() => {
    if (superStockistList) {
      setFilterDistributorList(superStockistList);
    }
  }, [superStockistList]);
  useEffect(() => {
    const filteredData = superStockistList?.filter((distributor) => {
      return (
        distributor?.ss_name?.toLowerCase().includes(query?.toLowerCase()) ||
        distributor?.mobile_no?.toLowerCase().includes(query?.toLowerCase()) ||
        distributor?.ss_code?.toLowerCase().includes(query?.toLowerCase()) ||
        distributor?.zone_name?.toLowerCase().includes(query?.toLowerCase())
      );
    });

    setFilterDistributorList(filteredData);
  }, [query]);

  const handleExcelDownload = () => {};

  return (
    <>
      <div className={styles.UserMgtWholesalerTable}>
        <div className={styles.MidSubContainerOne}>
          <div className={styles.MidSubContainerOneList}></div>

          <div style={{ width: "100%" }}>
            {/* <label className={styles.MidSubContainerOneLabel}>
              Search User
            </label> */}
            <Box
              sx={{
                display: "flex !important",
                flexDirection: "row !important",
                justifyContent: "space-between !important",
                alignItems: "center !important",
                flexWrap: "wrap !important",
              }}
            >
              <Stack
                sx={{ flexDirection: "row", gap: "8px", alignItems: "center" }}
              >
              <TextField
                variant="outlined"
                sx={{
                  width: "300px",
                  "& .MuiOutlinedInput-root": {
                    padding: "0px 4px", // Adjust padding for height reduction
                  },
                  "& .MuiInputBase-input": {
                    fontSize: "0.875rem", // Decrease font size
                    lineHeight: "1.2", // Adjust line height
                  },
                }}
                size="small"
                value={query}
                onChange={handleSearch}
                placeholder="Search User"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton>
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <FormControl
                  fullWidth
                  size="small"
                  // variant="outlined"
                  sx={{
                    minWidth: 140,
                    maxWidth: 140,
                    padding: "8.5px 14px !important",
                    "& .MuiOutlinedInput-root": {
                      padding: "0px 4px !important",
                    },
                    "& .MuiOutlinedInput-input":{
                      padding: "8.5px 14px !important",
                    }
                  }}
                >
                  {/* <InputLabel id="status-label">Status</InputLabel>    */}
                  <Select
                    labelId="status-label"
                    value={status ? "Active" : "Inactive"}
                    onChange={handleChange}
                    label="Status"
                  >
                    <MenuItem value="Active">Active</MenuItem>
                    <MenuItem value="Inactive">Inactive</MenuItem>
                  </Select>
                </FormControl>
                </Stack>
              <Box sx={{ display: "flex", flexDirection: "row", gap: "1rem" }}>
                {/* <ExcelDownload distributorList={filterDistributorList} />
                <ExcelUpload /> */}
                <RedButton onClick={handleAdd} variant="contained">
                  <Modal type={1} />
                </RedButton>
              </Box>
            </Box>
          </div>
        </div>
        <UserMgtWholesalerTable
          filterlist={filterDistributorList}
          Searchfilter={Searchfilter}
          detail={
            filterlist && filterlist.length !== 0 && filterlist !== "undefined"
              ? Userdetail
              : {}
          }
          setUserDetail={setUserDetail}
        />
      </div>

      <div className={styles.UserMgtWholesalerUpdateFormScrollViewContainer}>
        <div id="UserMgtWholesalerUpdateFormScrollView">
          {superStockistList &&
          superStockistList?.length !== 0 &&
          superStockistList !== "undefined" ? (
            <UserMgtWholesalerUpdateForm
              detail={
                superStockistList &&
                superStockistList?.length !== 0 &&
                superStockistList !== "undefined"
                  ? Userdetail
                  : {}
              }
              list={
                superStockistList &&
                superStockistList?.length !== 0 &&
                superStockistList !== "undefined"
                  ? superStockistList
                  : []
              }
            />
          ) : (
            <h5>No Data</h5>
          )}
        </div>
      </div>
    </>
  );
};

export default SuperStockist;
