import React, { useState } from "react";
import { useSelector } from "react-redux";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import { Stack } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import * as XLSX from 'xlsx';
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import Loader from "../../RouteManagement/Loader";
import ValidationPopup from "./ValidationPopUp";
import useSrTargetDownload from "../../../hooks/TargetAssigned/useSrTargetDownload";

const RedButton = styled(Button)(({ theme }) => ({
    color: "#731D3A",
    backgroundColor: "white",
    fontSize:"14px !important",
    "&:hover": {
      backgroundColor: "#731D3A",
      color: "white",
      border: "none !important",
    },
    border: "none !important",
    textTransform:"none"
  }));
const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
});
const DownloadButtons = ({selectedMonth}) => {
  const [formData, setFormData] = useState({});
  const [isDownloading, setIsDownloading] = React.useState(false);
  const [loaderText, setLoaderText] = React.useState("");
  const [validationOpen, setValidationOpen]=useState(false)

  //----------------------Redux-----------------
  const zoneData = useSelector((state) => state.app.curretDropdownData);

  //---------------------API Call------------------
  const getSrTargetDownload=useSrTargetDownload()

  const handleUploadExcel = async (event) => {
    
    setLoaderText("Uploading Excel");
    const excelFile = event.target.files[0];

    // Create a new FormData instance
    const newFormData = new FormData();
    
    // Append required data to the new FormData object
    newFormData.append("target_dump", excelFile);
    newFormData.append("state_id", zoneData?.state_id);
    newFormData.append("zone_id", zoneData?.zone_id);
    newFormData.append("month", selectedMonth?.month_no);
    newFormData.append("year", selectedMonth?.year);
  
    // Update the state with the new form data
    setFormData(newFormData);
    
    setValidationOpen(true);
    // Clear the file input
    event.target.value = null;
  };

  const handleDownloadExcel = async() => {
    setIsDownloading(true);
    setLoaderText("Downloading Excel");
    const param = {
        zone_id:zoneData?.zone_id,
        month: selectedMonth?.month_no,
        year: selectedMonth?.year,
        state_id: zoneData?.state_id
    }

    const data = await getSrTargetDownload(param);
    const headers = [
        { v: 'SR ID' },
        { v: 'SR Name' },
        { v: 'Sales Target (Monthly)' },
        { v: 'Time Stamp (Daily)' },
        { v: 'Petrol / km (On Order)' },
        { v: 'Call Incentive / call (Monthly)' },
        { v: 'Time Incentive / hour (Monthly)' },
        { v: 'Sales Incentive / 1000 (Monthly)' },
        { v: 'Fixed Salary (Monthly)' },
        { v: 'Variable Salary (Monthly)' }
      ];
    
      // Format the data for Excel
      const formattedData = data.map(item => ({
        'SR ID':item.sr_id,
        "SR Name": item.sr_name,
        "Sales Target (Monthly)": item.sales_target,
        "Time Stamp (Daily)": item.time_stamp_daily,
        "Petrol / km (On Order)": item.petrol_per_km , 
        "Call Incentive / call (Monthly)": item.call_incentive_coins,
        "Time Incentive / hour (Monthly)": item.time_incentive_coins,
        "Sales Incentive / 1000 (Monthly)": item.sales_incentive_coins,
        "Fixed Salary (Monthly)": item.fixed_salary_coins,
        "Variable Salary (Monthly)": item.variable_salary
      }));
    
      // Create a new worksheet with the formatted data
      const worksheet = XLSX.utils.json_to_sheet(formattedData, { header: headers.map(h => h.v) });
    
      // Add custom column widths
      worksheet['!cols'] = [
        { wch: 10 },   // SR Id
        { wch: 25 },  // SR Name
        { wch: 20 },  // Sales Target (Monthly)
        { wch: 20 },  // Time Stamp (Daily)
        { wch: 20 },  // Petrol / km (On Order)
        { wch: 25 },  // Call Incentive / call (Monthly)
        { wch: 25 },  // Time Incentive / hour (Monthly)
        { wch: 25 },  // Sales Incentive / 1000 (Monthly)
        { wch: 25 },  // Fixed Salary (Monthly)
        { wch: 25 },  // Variable Salary (Monthly)
      ];
    
      // Create a new workbook and append the worksheet
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "SR_target_details");
    
      // Generate Excel file and download it
      XLSX.writeFile(workbook, "SR_target_details.xlsx");
      setIsDownloading(false);
  };
 
  const handleValidationClose = () => setValidationOpen(false);

  return (
    <Stack
      flexDirection="row"
      alignItems="center"
      justifyContent="flex-end"
      sx={{ width: "100%" }}
    >
      {isDownloading ? <Loader loaderText={loaderText} /> : null}
      <ValidationPopup
        open={validationOpen}
        handleClose={handleValidationClose}
        formData={formData}
        setIsDownloading={setIsDownloading}
        selectedMonth={selectedMonth}
      />

      <Stack flexDirection="row" gap="12px">
        <RedButton
          variant="outlined"
          sx={{
            backgroundColor: "#396b31 !important",
            "&:hover": {
              background: "#396b31 !important",
            },
          }}
          
          onClick={() => handleDownloadExcel()}
          endIcon={<FileDownloadIcon />}
        >
          Download Dump
        </RedButton>
        <Button
          sx={{
            textTransform: "none",
            boxShadow: "none",
            ":hover": {
              boxShadow: "none !important",
              border: "none !important",
            },
            fontSize: "14px !important",
            backgroundColor: "#ba4c4c !important",
            "&:hover": {
              background: "#ba4c4c !important",
            },
            border: "none !important",
          }}
          component="label"
          role={undefined}
          variant="contained"
          tabIndex={-1}
          startIcon={<CloudUploadIcon />}
        >
          Upload Excel
          <VisuallyHiddenInput
            type="file"
            accept=".xlsx, .xls"
            onChange={(event) => handleUploadExcel(event)}
            multiple
          />
        </Button>
      </Stack>
    </Stack>
  );
};

export default DownloadButtons;


