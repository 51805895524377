import { createSlice } from "@reduxjs/toolkit";

const adminMapSlice = createSlice({
  name: "admin",
  initialState: {
    adminMapDetails: null,
    getPincodeDetails: null,
    getRouteListDetails: null,
    getRouteClusterDetails: null,
    getAssignedBoundryDetails: null,
    assignedUserData: null,
    getReqBoundryDetails:null,
    selectedPincodeDetails: null,
  },
  reducers: {
    getAdminMapDetails: (state, action) => {
      state.adminMapDetails = action.payload;
    },
    assigngetPincodeDetails: (state, action) => {
      state.getPincodeDetails = action.payload;
    },
    getRouteList: (state, action) => {
      state.getRouteListDetails = action.payload;
    },
    getRouteClusterList: (state, action) => {
      state.getRouteClusterDetails = action.payload;
    },
    assignedBoundryDetails: (state, action) => {
      state.getAssignedBoundryDetails = action.payload;
    },
    getAssignedUserData: (state, action) => {
      state.assignedUserData = action.payload;
    },
    reqBoundryDetails: (state, action) => {
       state.getReqBoundryDetails = action.payload;
    },
    getSelectedPincodeDetails: (state, action) => {
      state.selectedPincodeDetails = action.payload;
   }, 
    resetReqBoundryDetails: (state) =>{
      state.getReqBoundryDetails = null;
    },
    resetRouteClusterList: (state) => {
      state.getRouteClusterDetails = null;
    },
    resetAssignedBoundryDetails: (state, action) => {
      state.getAssignedBoundryDetails = null;
    },
  },
});

export default adminMapSlice.reducer;
export const {
  getAdminMapDetails,
  assigngetPincodeDetails,
  getRouteList,
  getRouteClusterList,
  resetRouteClusterList,
  assignedBoundryDetails,
  getAssignedUserData,
  reqBoundryDetails,
  resetAssignedBoundryDetails,
  getSelectedPincodeDetails,
  resetReqBoundryDetails
} = adminMapSlice.actions;
