import React from "react";
import useAxiosPrivate from "./useAxiosPrivate";
import { useDispatch,useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
const useUpdateSku = (form) => {
  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();
  const currentSelectedImage = useSelector((state) => state.sku.skuFormData);
  const updateSkuDetails = async () => {
    let param = {
      // sku_name:form?.sku_name??form?.skuRowData?.sku_name,
      // ptr:form?.ptr??form?.skuRowData?.ptr,
      // ptw:form?.ptw??form?.skuRowData?.ptw,
      // mrp:form?.mrp??form?.skuRowData?.mrp,
      // status:form?.status==="Inactive"?false:true??form?.skuRowData?.status,
      // sku_id:form?.sku_id
      sku_name: form?.sku_name,
      sc_id: form?.sc_id,
      category_id: form?.category_id,
      price_per_piece: form?.price_per_piece,
      ladi: form?.ladi,
      price_per_ladi: form?.price_per_ladi,
      carton: form?.carton,
      price_per_carton: form?.price_per_carton,
      sku_image: currentSelectedImage?.full_path ?? form?.sku_image,
      status:form?.status==="Inactive"?false:true??form?.skuRowData?.status,
      sku_id: form?.sku_id,
    };

    const response = await Axios.post("/update_sku", param);
    const data = await response.data;

    if (data.code === 200) {
      toast.success("Updated SuccessFully");
    } else {
      toast.error("Update Failed");
    }

    console.log(data);
  };
  // useEffect(() => {
  //   getAllUserList();
  // }, []);
  return updateSkuDetails;
};

export default useUpdateSku;
