import * as React from "react";
import Paper from "@mui/material/Paper";
import { Stack, Typography } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useSelector } from "react-redux";
import useSRTableDetails from "../../../hooks/useSRTableDetails";
import useSRTimeTableDetails from "../../../hooks/useSRTimeTableDetails";

export default function TimeStampTable() {

  const tableDetails = useSelector((store) => store.sr.SRTimeTableDetails);
  useSRTimeTableDetails();
  const row = [
    // "SO",
    "SE",
    "First Time Stamp",
    "Last Time Stamp",
    "Hours Completed",
  ];

  const [page, setPage] = React.useState(0);
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = React.useState(false);
  const [categoryId, setCategoryId] = React.useState(null);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleDelete = (data) => {
    setIsConfirmDialogOpen(true);
    setCategoryId(data?.category_id);
  };

  const handleConfirmDialogClose = () => {
    setIsConfirmDialogOpen(false);
  };

  const handleConfirmApproveClick = () => {
    setIsConfirmDialogOpen(false);
  };

  const [open, setOpen] = React.useState(false);
  const [categoryEditId, setCategoryEditId] = React.useState(null);
  const [categoryRowData, setCategoryRowData] = React.useState(null);
  const handleClick = (data) => {
    setOpen(true);
    setCategoryEditId(data?.category_id);
    setCategoryRowData(data);
  };
  const [searchTerm, setSearchTerm] = React.useState('');
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  return (
    <>
      <input
        type="text"
        placeholder="Search"
        style={{
          width: "15rem",
          height: "2rem",
          fontSize: "14px",
          fontFamily: "inherit",
          borderRadius: "8px",
          padding: "4px 8px",
          border: "1px solid #c4c4c4",
          outline: "none",
        }}
        onChange={handleSearchChange}
        value={searchTerm}
      />
      <Paper sx={{ width: "100%" }}>
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {row?.map((item) => (
                  <TableCell
                    sx={{
                      background: "#F8F8F8",
                      fontSize: "16px",
                      color: "#5F6868",
                      fontWeight: "500",
                    }}
                    align="start"
                    colSpan={1}
                  >
                    {item}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            {/* <TableBody>
            {tableDetails
              ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((data, index) => {
                return (
                  <>
                    <TableRow hover role="checkbox" tabIndex={-1} key={data.id}>
                     
                      
                      <TableCell align="start" colSpan={1}>
                        {data?.so_users?.map((so_name)=>(
                          so_name?.first_name + " " +so_name?.last_name
                        ))}
                      </TableCell>
                      <TableCell align="start" colSpan={1}>
                        {data.sc_name}
                      </TableCell>
                      <TableCell align="start" colSpan={1}>
                        {data.total_sku ?? "N/A"}
                      </TableCell>
                      <TableCell align="start" colSpan={1}>
                        {data.category_name}
                      </TableCell>
                      <TableCell align="start" colSpan={1}>
                        {data.sc_name}
                      </TableCell>
                    </TableRow>
                  </>
                );
              })}
          </TableBody> */}
            <TableBody>
              {tableDetails
              ?.filter((sr)=>sr?.se_name?.toLowerCase()?.includes(searchTerm?.toLowerCase()))
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((data, index) => {
                  return (
                    <>
                      <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                        {/* <TableCell align="start" colSpan={1}>
                        {data?.so_name}
                      </TableCell> */}

                        <>
                          <TableCell align="start" colSpan={1}>
                            {data?.se_name}
                          </TableCell>
                        </>

                        <>
                          <TableCell align="start" colSpan={1}>
                            {data?.first_time_stamp ?? "N/A"}
                          </TableCell>
                          <TableCell align="start" colSpan={1}>
                            {data?.last_time_stamp ?? "N/A"}
                          </TableCell>
                          <TableCell align="start" colSpan={1}>
                            {data?.hour_difference ?? "N/A"}
                          </TableCell>
                        </>
                      </TableRow>
                    </>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={tableDetails?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </>
  );
}
