import { useEffect } from "react";
import useAxiosPrivate from "./useAxiosPrivate";
import { useDispatch, useSelector } from "react-redux";

import {
  getSRAmountChartDetails,

} from "../slice/srSlice";

const useSRAmountChartDetails = () => {
  const currentzone = useSelector((state) => state.assignRoute.zone_id);
  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();
  const getAllSRAmountChartDetails = async (param) => {

      const response = await Axios.post("/sr_amount_chart_details", param);
      const data = await response.data;
      dispatch(getSRAmountChartDetails(data.body));
    
  };

  return getAllSRAmountChartDetails;
};

export default useSRAmountChartDetails;
