import React, { useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useDispatch, useSelector } from "react-redux";
import Radio from "@mui/material/Radio";
import Styles from "./styles/index.module.css";
import Route from "./Route";
import VillageMgt from "./Village/Village";
import Sidebar from "../Sidebar";
import { Stack, Typography } from "@mui/material";
import useSuperCategoryList from "../../hooks/useSuperCategoryList";
import {
  setScId,
} from "../../slice/assignSEslice";
import {
  setSuperCategoryId,
} from "../../slice/assignRouteSlice";
import { useNavigate } from "react-router";
import EmployeeArea from "./EmployeeArea/EmployeeArea";
const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 2,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const RouteMgt = ({ zone, Statevalue }) => {
  const navigate = useNavigate();
  const superCategory = useSelector(
    (state) => state.superCategory.superCategoryList
  );
  const getSCList = useSuperCategoryList();
  const [selectedSCId, setSelectedSCId] = useState(1);
  const dispatch = useDispatch();
  const [token, setToken] = useState("");
  const [rootzone, setRootZone] = useState("");
  // const state = useSelector(state => state.Zone.list);
  // const route = useSelector(state => state.RouteMgt);
  // const Firsttoken = useSelector(state => state.Token);
  const Item = ["Employee Area","RouteMgt"];
  const [role, setRole] = useState("Employee Area");
  useEffect(() =>{
    getSCList();
  },[])
  const handleSCRadioChange = (scId) => {
    setSelectedSCId(scId);
    dispatch(setScId([scId]));
    dispatch(setSuperCategoryId([scId]));
    navigate("/home/routemgtmap")
  };

  return (
    <>
      <Stack sx={{ flexDirection: "row" }}>
        <Sidebar />
        <div className={Styles.RouteMgtContainer}>
          <Stack sx={{ flexDirection: "row", gap: "14px", justifyContent: role === "Employee Area" ? 'space-between' : 'initial'}}>
            <Stack sx={{display:"flex",flexDirection:"row"}}>       
            {Item.map((item, index) => (
              <div
                className={Styles.RouteMgtCarousel}
                onClick={() => setRole(item)}
              >
                <div
                  key={index}
                  className={
                    role === `${item}`
                      ? Styles.RouteMgtCarouselLabelWrapperOne
                      : Styles.RouteMgtCarouselLabelWrapperTwo
                  }
                >
                  <label
                    className={
                      role === `${item}`
                        ? Styles.RouteMgtCarouselLabelOne
                        : Styles.RouteMgtCarouselLabelTwo
                    }
                  >
                    {item}
                  </label>
                </div>
              </div>
            ))}
            </Stack>
           <Stack
              sx={{
                textAlign: "left",
                paddingLeft: "0.8rem",
                flexDirection: "row",
                padding: "10px 0px",
                marginLeft: "0.3rem",
                alignItems: "center",
                marginRight: "0.3rem",
              }}
            >
              <Typography sx={{ fontWeight: "600" }}>SuperCategory:</Typography>
              {superCategory?.map((data, index) => (
                <Stack
                  sx={{ flexDirection: "row", alignItems: "center" }}
                  key={index}
                >
                  <Radio
                    value={data.sc_id}
                    sx={{
                      height: "18px",
                      alignSelf: "center",
                      "& .MuiSvgIcon-root": {
                        color: "#1C1B1F",
                      },
                    }}
                    checked={selectedSCId === data.sc_id}
                    onChange={() => handleSCRadioChange(data.sc_id)}
                  />

                  <label>{data?.sc_name}</label>
                </Stack>
              ))}
            </Stack>
          </Stack>
          {role === "RouteMgt" ? (
            <Route rootzone={zone} token={token} setRootZone={setRootZone} />
          ) : role === "Employee Area" ? (
            <EmployeeArea
              rootzone={zone}
              token={token}
              setRootZone={setRootZone}
            />
          ) : (
            <VillageMgt
              rootzone={zone}
              token={token}
              setRootZone={setRootZone}
              Statevalue={Statevalue}
            />
          )}
        </div>
      </Stack>
    </>
  );
};

export default RouteMgt;
