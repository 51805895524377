
import useAxiosPrivate from "./useAxiosPrivate";
import { useDispatch } from "react-redux";


const useAssignPincode = () => {
  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();

  const setAssignPincode = async (param) => {
    try {
      const response = await Axios.post("assign-pincode", param);
      const data = response.data;
      return data;  
    } catch (error) {
      console.error("Error fetching admin map data:", error);
      throw error;  
    }
  };
  return setAssignPincode;
};

export default useAssignPincode;


