import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import {
  FormControl,
  Select,
  MenuItem,
  TextField,
  InputLabel,
  Stack,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import AddIcon from "@mui/icons-material/Add";
import { Input } from "@mui/icons-material";
import {
  getCategoryFormData,
  getCategoryList,
} from "../../slice/categorySlice";
import useCategoryImageUpload from "../../hooks/useCategoryImageUpload";
import useAddCategory from "../../hooks/useAddCategory";
import { BaseImgUrl } from "../../utility/BaseURL";
import { ToastContainer, toast } from "react-toastify";
import useCategoryList from "../../hooks/useCategoryList";
import useSuperCategoryList from "../../hooks/useSuperCategoryList";
import useSuperCategoryImageUpload from "../../hooks/useSuperCategoryImageUpload";
import useAddSuperCategory from "../../hooks/useAddSuperCategory";
import { resetSuperCategoryFormData } from "../../slice/superCategorySlice";
import PhotoCameraOutlinedIcon from "@mui/icons-material/PhotoCameraOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "10px",
  p: 4,
};

export default function AddSuperCategory() {
  const [open, setOpen] = React.useState(false);
  const [enable, setEnable] = React.useState(false);
  const [checked, setChecked] = React.useState({
    ladi: false,
    carton: false,
    piece: false,
  });
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setForm({});
    setError({});
    setChecked({
      ladi: false,
      carton: false,
      piece: false,
    })
    dispatch(resetSuperCategoryFormData());
  };
  const dispatch = useDispatch();
  const [form, setForm] = React.useState({});
  const currentSelectedImage = useSelector((state) => state.superCategory.getSuperCategoryFormData);
  const getSuperCategoryImageUpload = useSuperCategoryImageUpload();
  const addSuperCategory = useAddSuperCategory(form,checked);
  const getAllSuperCategoryList = useSuperCategoryList();
  const superCategoryImage = useSelector(
    (state) => state.superCategory.superCategoryFormData
  );
  

  const handleChange = (e) => {
    setForm((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const [error, setError] = React.useState({});
  const isValid = () => {
    let success = true;
    let err = {};

    if (
      form.super_category?.trim() === "" ||
      form.super_category?.trim() === undefined ||
      form.super_category?.trim() === null
    ) {
      err.name = "**Super Category Name Cannot be Empty**";
      success = false;
    }

    if (
      form.selectedImage?.trim() === "" ||
      form.selectedImage?.trim() === undefined ||
      form.selectedImage?.trim() === null
    ) {
      err.selectedImage = "**Image should be selected**";
      success = false;
    }
    setError(err);
    return success;
  };

  useEffect(() => {
    setEnable(false);
  }, [superCategoryImage]);

  const handleImageChange = (e) => {
    setForm((prev) => ({
      ...prev,
      [e.target.name]: e.target.files[0],
    }));
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    getSuperCategoryImageUpload(formData);
    if (e.target.files[0]) {
      setForm((prev) => ({
        ...prev,
        selectedImage: e.target.files[0].name,
      }));
    } else {
      setForm((prev) => ({
        ...prev,
        selectedImage: null,
      }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (isValid()) {
      addSuperCategory();
      getAllSuperCategoryList();
      setOpen(false);
      setForm({});
      setChecked({
        ladi: false,
        carton: false,
        piece: false,
      })
    }
  };

  const handleCheckbox = (e) => {
    
    console.log(e.target.checked);
    setChecked((prev) => ({
      ...prev,
      [e.target.name]: e.target.checked,
    }));
  };

  // console.log(checked,"ooooooooooo");

  return (
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div>
        <Button
          onClick={handleOpen}
          sx={{
            height: "0.6rem",
            color: "white",
            background: "#EF6C1A",
            padding: "1rem",
            textWrap: "nowrap",
            textTransform:"none",
            ":hover": { backgroundColor: "#EF6C1A" },
          }}
        >
          <AddIcon sx={{ fontSize: "14px", fontWeight: "700" }} />
          Add Super Category
        </Button>
        <Modal
          open={open}
          // onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <FormControl
              fullWidth
              size="small"
              sx={{ display: "flex", flexDirection: "column", gap: "0.8rem" }}
            >
              <Stack
                sx={{ display: "flex", flexDirection: "column", gap: "0.4rem" }}
              >
                <Stack sx={{ marginBottom: "1.5rem" }}>
                  <Typography sx={{ color: "#000000", fontWeight:"500",fontSize:"16px" }}>
                    Add New Super Category
                  </Typography>
                </Stack>
                <input
                  variant="outlined"
                  name="super_category"
                  placeholder="Enter super category name"
                  onChange={handleChange}
                  style={{
                    height: "2.2rem",
                    paddingLeft: "0.5rem",
                    fontSize: "0.9rem",
                  }}
                />
                {error.name && (
                  <p
                    style={{
                      color: "red",
                      justifyContent: "center",
                      display: "flex",
                      fontSize: "0.8rem",
                      marginTop: "-0.1rem",
                    }}
                  >
                    {error.name}
                  </p>
                )}
              </Stack>

              <Stack>
                <Typography>Choose a Quantity Type :</Typography>
                <FormGroup sx={{ display: "flex", flexDirection: "row" }}>
                  <FormControlLabel
                    control={<Checkbox onChange={(e) => handleCheckbox(e)} />}
                    label="Piece"
                    name="piece"
                  />
                  <FormControlLabel
                    control={<Checkbox onChange={(e) => handleCheckbox(e)} />}
                    label="Ladi"
                    name="ladi"
                  />
                  <FormControlLabel
                    control={<Checkbox onChange={(e) => handleCheckbox(e)} />}
                    label="Carton"
                    name="carton"
                  />
                </FormGroup>
              </Stack>
              <Stack
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "0.8rem",
                }}
              >
                <label htmlFor="sku_image" style={{ cursor: "pointer" }}>
                  <input
                    id="sku_image"
                    type="file"
                    size="small"
                    accept="image/*"
                    name="sku_image"
                    style={{
                      display: "none",
                    }}
                    onChange={handleImageChange}
                  />

                  <div
                    style={{
                      height: "180px",
                      fontSize: "0.9rem",
                      border: "1px solid #ccc",
                      borderRadius: "4px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      cursor: "pointer",
                      background: "#F2F2F2",
                    }}
                  >
                    {currentSelectedImage ? (
                      <img
                        style={{
                          height: "100%",
                          width: "100%",
                          objectFit: "contain",
                        }}
                        src={currentSelectedImage.full_path}
                        alt="sku_image"
                      />
                    ) : (
                      <>
                        {form.selectedImage ? (
                          form.selectedImage
                        ) : (
                          <Stack
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              justifyContent: "center",
                              gap: "0.5rem",
                            }}
                          >
                            <Stack
                              sx={{
                                background: "#FFFFFF",
                                padding: "0.8rem",
                                borderRadius: "50%",
                              }}
                            >
                              <PhotoCameraOutlinedIcon />
                            </Stack>
                            <Typography>Super Category Image</Typography>
                          </Stack>
                        )}
                      </>
                    )}
                  </div>
                </label>
                {error.selectedImage && (
                  <p
                    style={{
                      color: "red",
                      fontSize: "0.8rem",
                      margin: "0",
                    }}
                  >
                    {error.selectedImage}
                  </p>
                )}
              </Stack>
              {/* Close Icon */}
              <Stack sx={{ position: "absolute", right: 0, cursor: "pointer" }}>
                <CloseOutlinedIcon onClick={handleClose} />
              </Stack>
              <Stack
                sx={{
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  gap: "0.4rem",
                }}
              >
                  <Button
                    sx={{
                      background: "white !important",
                      color: "#FF5A5A !important",
                      border: "1px solid #FF5A5A",
                      height: "1.8rem",
                      padding: "18px 10px",
                      fontSize: "12px",
                      fontWeight: 700,
                      textTransform:"none"
                    }}
                    onClick={handleClose}
                  >
                    Discard
                  </Button>
                  <Button
                    sx={{
                      background: "#FF5A5A",
                      color: "white",
                      height: "1.8rem",
                      ":hover": { background: "#FF5A5A" },
                      padding: "18px 10px",
                      fontSize: "12px",
                      fontWeight: 700,
                      textTransform:"none"
                    }}
                    onClick={handleSubmit}
                    // disabled={disable}
                  >
                    Add New Super Category
                  </Button>
              </Stack>
            </FormControl>
          </Box>
        </Modal>
      </div>
    </>
  );
}
