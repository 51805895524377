import React,{useEffect} from "react";
import { Stack } from "@mui/material";
import Sidebar from "../Sidebar";
import StampTab from "./StampTab";
import {useSelector} from "react-redux"
import useSRList from "../../hooks/useSRList";
import useSRGraphDetails from "../../hooks/useSRGraphDetails";

const PerformanceMgt = () => {
  // useCategoryImageUpload()
  const currentZone = useSelector((state) => state.app.curretDropdownData);
  
  const getAllSRList = useSRList();
  // useSRGraphDetails()
  useEffect(() => {
    getAllSRList();
  }, [currentZone]);
  return (
    <Stack sx={{ display: "flex", flexDirection: "row" }}>
      <Sidebar />
      <StampTab />
    </Stack>
  );
};

export default PerformanceMgt;
