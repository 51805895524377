import { useEffect } from "react";
import useAxiosPrivate from "./useAxiosPrivate";
import { useDispatch, useSelector } from "react-redux";
import { getRetailerList, getSchemeRetailerList } from "../slice/retailerSlice";

const useSchemeRetailerList = () => {
  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();

  const getAllSchemeRetailerList = async (zoneId) => {
    if (zoneId) {
      const param = {
        zone_id: zoneId,
        type:"scheme"
      };
      try {
        const response = await Axios.post("/retailer_list", param);
        const data = await response.data;
        // if(zoneId!=null){
        //   dispatch(getRetailerList(data.body));
        // }else{
          dispatch(getSchemeRetailerList(data?.body))
        // }
        // console.log(data);
      } catch (error) {
        console.error("Error fetching retailer list:", error);
      }
    }
  };

  // useEffect(() => {
  //   getAllRetailerList();
  // }, [currentzone]);
  return getAllSchemeRetailerList;
};

export default useSchemeRetailerList;
