import React, { useState } from "react";
import Button from "@mui/material/Button";
import { Stack, Typography } from "@mui/material";
import { withStyles } from "@mui/styles";
import { styled } from "@mui/material/styles";
import { red, purple, white } from "@mui/material/colors";
import { style } from "@mui/system";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { HashLink as Link } from "react-router-hash-link";
import { useSelector, useDispatch } from "react-redux";
import TextField from "@mui/material/TextField";
import exportFromJSON from "export-from-json";
import { useEffect } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import RadioButton from "./RadioButton";
import Sidebar from "../Sidebar";
import useSkuListByCategory from "../../hooks/useSkuListByCategory";
import useCategoryListBySuperCategory from "../../hooks/useCategoryListBySuperCategory";
import useSuperCategoryList from "../../hooks/useSuperCategoryList";
import useAddSchemeState from "../../hooks/useAddSchemeState";
import { getSchemeCurrentData, getSchemeStateCurrentData } from "../../slice/schemeSlice";
import RetailerTable from "./RetailerTable";

const AddNewScheme = () => {
  const dispatch = useDispatch();
  const [dataFromChild, setDataFromChild] = useState(null);
  const [selectedSC, setSelectedSC] = useState(null);
  const [selectedSCId, setSelectedSCId] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const [selectedSku, setSelectedSku] = useState(null);
  const [selectedSkuId, setSelectedSkuId] = useState(null);
  const [obj, setObj] = useState({});
  const qtyType = [
    {
      id: 1,
      name: "ladi",
    },
    {
      id: 2,
      name: "carton",
    },
    {
      id: 3,
      name: "pieces",
    },
  ];

  const superCategoryList = useSelector(
    (store) => store.superCategory.superCategoryList
  );
  const categoryList = useSelector(
    (store) => store.category.categoryListBySuperCategory
  );
  const skuList = useSelector((store) => store.sku.skuListByCategory);

  useSuperCategoryList();
  const getAllCategoryListBySuperCategory = useCategoryListBySuperCategory();
  const getAllSkuListByCategory = useSkuListByCategory();
  

  useEffect(() => {
    dispatch(getSchemeCurrentData(obj));
  }, [obj]);

  const handleSCChange = (e) => {
    setSelectedSC(e.target.value);
    let scId = 0;
    for (let i = 0; i < superCategoryList?.length; i++) {
      if (e.target.value === superCategoryList[i].sc_name) {
        scId = superCategoryList[i].sc_id;
      }
    }

    setSelectedSCId(scId);
    setObj((prev) => ({
      ...prev,
      [e.target.name]: scId,
    }));
    getAllCategoryListBySuperCategory(scId);
  };

  const handleCategoryChange = (e) => {
    setSelectedCategory(e.target.value);
    let categoryId = 0;
    for (let i = 0; i < categoryList?.length; i++) {
      if (e.target.value === categoryList[i]?.category_name) {
        categoryId = categoryList[i]?.category_id;
      }
    }

    setSelectedCategoryId(categoryId);
    setObj((prev) => ({
      ...prev,
      [e.target.name]: categoryId,
    }));

    getAllSkuListByCategory(categoryId, selectedSCId);
  };

  const handleSkuChange = (e) => {
    setSelectedSku(e.target.value);
    let skuId = 0;
    for (let i = 0; i < skuList?.length; i++) {
      if (e.target.value === skuList[i]?.sku_name) {
        skuId = skuList[i]?.sku_id;
      }
    }

    setSelectedSkuId(skuId);
    setObj((prev) => ({
      ...prev,
      [e.target.name]: skuId,
    }));

    // getAllSkuListByCategory(categoryId, selectedSCId);
  };

  // Callback function to receive data from the child
  const handleDataFromChild = (data) => {
    setDataFromChild(data);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(name, "name");
    console.log(value, "value");

    setObj((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  console.log(obj, "fkajfkdsjfkjf");
  return (
    <Stack sx={{ display: "flex", flexDirection: "row" }}>
      <Sidebar />
      <Box
        sx={{
          minWidth: 140,
          display: "flex",
          flexDirection: "column",
          gap: "3rem",
          width: "100vw",
          padding: "1rem",
        }}
      >
        <Stack
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Typography sx={{ fontWeight: 700, fontSize: "1.3rem" }}>
            Add New Scheme
          </Typography>

          <Stack
            sx={{ display: "flex", flexDirection: "column", gap: "2.5rem" }}
          >
            <Stack sx={{ display: "flex", flexDirection: "row", gap: "1rem" }}>
              <FormControl sx={{ minWidth: 250 }}>
                <Select
                  labelId="shape-id-label"
                  id="shape-id-select"
                  name="sc_id"
                  value={selectedSC}
                  size="small"
                  sx={{ height: "2.2rem" }}
                  onChange={(e) => handleSCChange(e)}
                >
                  {superCategoryList &&
                    superCategoryList?.map((sc) => (
                      <MenuItem key={sc.sc_id} value={sc.sc_name}>
                        {sc.sc_name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <FormControl sx={{ minWidth: 250 }}>
                <Select
                  labelId="shape-id-label"
                  id="shape-id-select"
                  name="category_id"
                  value={selectedCategory}
                  size="small"
                  sx={{ height: "2.2rem" }}
                  onChange={(e) => handleCategoryChange(e)}
                >
                  {categoryList &&
                    categoryList?.map((category) => (
                      <MenuItem
                        key={category.category_id}
                        value={category.category_name}
                      >
                        {category.category_name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <FormControl sx={{ minWidth: 250 }}>
                <Select
                  labelId="shape-id-label"
                  id="shape-id-select"
                  name="sku_id"
                  value={selectedSku}
                  size="small"
                  sx={{ height: "2.2rem" }}
                  onChange={(e) => handleSkuChange(e)}
                >
                  {skuList &&
                    skuList?.map((sku) => (
                      <MenuItem key={sku?.sku_id} value={sku?.sku_name}>
                        {sku?.sku_name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Stack>

            <Stack sx={{ display: "flex", flexDirection: "row", gap: "1rem" }}>
              <Stack
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "1rem",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <label>Scheme Name</label>
                <input
                  variant="outlined"
                  name="scheme_name"
                  style={{
                    height: "2.2rem",
                    marginTop: "-0.2rem",
                    width: "18rem",
                  }}
                  size="small"
                  // value={query}
                  onChange={(e) => handleChange(e)}
                  placeholder="Buy One Get One "
                />
              </Stack>

              <Stack
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "1rem",
                }}
              >
                <label>From :</label>
                <input
                  type="date"
                  id="dateSale"
                  name="first_date"
                  style={{
                    height: "2rem",
                    fontSize: "1rem",
                    cursor: "pointer",
                  }}
                  onChange={(e) => handleChange(e)}
                  // value={currentSelectedDate} // Use the string format "YYYY-MM-DD" for the value
                />
              </Stack>
              <Stack
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "1rem",
                }}
              >
                <label>To :</label>
                <input
                  type="date"
                  id="dateSale"
                  name="last_date"
                  style={{
                    height: "2rem",
                    fontSize: "1rem",
                    cursor: "pointer",
                  }}
                  onChange={(e) => handleChange(e)}
                />
              </Stack>
              
            </Stack>
          </Stack>
        </Stack>
        <Stack>
          <RadioButton sendDataToParent={handleDataFromChild} />
          {dataFromChild?.outlets === true && <RetailerTable />}
        </Stack>
      </Box>
    </Stack>
  );
};

export default AddNewScheme;
