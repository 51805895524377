import { createSlice } from "@reduxjs/toolkit";

const adminViewSlice = createSlice({
  name: "adminView",
  initialState: {
    srList: [],
    srRoutes:[],
  },
  reducers: {
    getSrListBySC: (state, action) => {
      state.srList = action.payload;
    },
    getSrRoutes: (state, action) => {
      state.srRoutes = action.payload;
    },
  },
});

export default adminViewSlice.reducer;
export const { getSrListBySC, getSrRoutes } = adminViewSlice.actions;
