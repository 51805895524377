import React from "react";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import { Button } from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { ToastContainer, toast } from "react-toastify";

const ExcelDownload = ({ distributorList }) => {
  const downloadExcel = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Distributors");

    // Define columns
    worksheet.columns = [
      { header: "DistId", key: "dist_id", width: 10 }, // Not editable
      { header: "Distributor Code", key: "dist_code", width: 25 }, // Editable
      { header: "Distributor Name", key: "dist_name", width: 25 }, // Editable
      { header: "Firm Name", key: "shop_name", width: 25 }, // Editable
      { header: "Mobile", key: "mobile_no", width: 15 }, // Editable
      { header: "Whatsapp No.", key: "whatsapp_no", width: 15 }, // Editable
      { header: "Email", key: "email", width: 25 }, // Editable
      { header: "Address", key: "address", width: 50 }, // Editable
      { header: "Latitude", key: "lat", width: 15 }, // Editable
      { header: "Longitude", key: "lng", width: 15 }, // Editable
      { header: "Zone", key: "zone_name", width: 15 }, // Not editable
      { header: "Status", key: "status", width: 10 }, // Editable
    ];

    // Add data rows from distributorList
    distributorList?.forEach((dist) => {
      worksheet.addRow({
        dist_id: dist.dist_id,
        dist_code: dist.dist_code,
        dist_name: dist.dist_name,
        shop_name: dist.shop_name,
        mobile_no: dist.mobile_no,
        whatsapp_no: dist.whatsapp_no,
        email: dist.email,
        address: dist.address,
        lat: dist.lat,
        lng: dist.lng,
        zone_name: dist.zone_name,
        status: dist.status,
      });
    });

    // Create a dropdown for "Distributor Name" (column 3)
    const distributorNames = distributorList.map((dist) => dist.dist_name);

    worksheet
      .getColumn(3)
      .eachCell({ includeEmpty: true }, (cell, rowNumber) => {
        // Skip the header
        worksheet.getCell("C" + (rowNumber + 2)).dataValidation = {
          type: "list",
          allowBlank: false,
          formula1: '"Selected,Rejected,On-hold"', // List of names for the dropdown
          showErrorMessage: true,
          errorTitle: "Invalid Selection",
          error: "Please select a valid distributor name from the list",
        };
      });

    // Unlock all cells first
    worksheet.eachRow({ includeEmpty: true }, (row) => {
      row.eachCell((cell) => {
        cell.protection = { locked: false };
      });
    });

    // Lock specific columns (1 and 11) and the headers
    worksheet
      .getColumn(1)
      .eachCell({ includeEmpty: true }, (cell, rowNumber) => {
        if (rowNumber > 1) cell.protection = { locked: true }; // Lock DistId cells
      });
    worksheet
      .getColumn(11)
      .eachCell({ includeEmpty: true }, (cell, rowNumber) => {
        if (rowNumber > 1) cell.protection = { locked: true }; // Lock Zone cells
      });

    // Lock header row (row 1)
    worksheet.getRow(1).eachCell({ includeEmpty: true }, (cell) => {
      cell.protection = { locked: true }; // Lock header cells
    });

    // Protect the sheet
    // worksheet.protect("password", {
    //   selectLockedCells: false,
    //   selectUnlockedCells: true,
    // });

    // Generate the Excel file and trigger download
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(blob, "Distributors.xlsx");
    toast.success("Downloaded successfully")
  };

  

  return (
    <div>
      <Button
        sx={{
          textTransform: "none",
          padding: "14px 12px 14px 12px !important",
          fontSize: "14px !important",
          background: "#097524 !important",
          "&:hover": {
            background: "#097524 !important",
            border: "none !important",
          },
          border: "none !important",
        }}
        startIcon={<FileDownloadIcon />}
        onClick={downloadExcel}
      >
        Download Distributors
      </Button>
    </div>
  );
};

export default ExcelDownload;
