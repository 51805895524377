import React, { useState, useEffect } from "react";
import { Box } from "@material-ui/core";
import { Stack, Tooltip, Typography } from "@mui/material";

const ImprovedRequirement = (TopSRData) => {
 
  const data = [
    {
      rank: "1st",
      color: "#E8E9BD",
    },
    {
      rank: "2nd",
      color: "#D9D9D9",
    },
    {
      rank: "3rd",
      color: "#BE9B99",
    },
  ];

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        flexDirection: "column",
        gap: "0.6rem",
      }}
    >
      <Typography sx={{ display: "flex", alignSelf: "flex-start",fontWeight:"700", fontSize:"16px" }}>
      Improvement Required
      </Typography>
      <Stack sx={{ display: "flex", flexDirection: "row", gap: "25px",flexWrap:"wrap" }}>
        <Stack
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            gap: "1.5rem",
            flexWrap: "wrap",
          }}
        >
          {TopSRData?.TopSRData?.bottom_SRs?.map((item, index) => (
          <Stack
            key={index}
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              height:"68px",
              maxWidth: "200px",
            }}
          >
            <Typography
              sx={{
                boxShadow: 3,
                minWidth: "30px",
                maxWidth: "32px",
                width:"100%",
                height:"100%",
                borderTopLeftRadius: "0.6rem",
                borderBottomLeftRadius: "0.6rem",
                background: "#FFBCBC",
                fontSize:"14px",
                fontWeight:"700",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {data[index]?.rank}
            </Typography>
            <Stack
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                background: "#FFFFFF",
                gap:"10px",
                boxShadow: 3,
                minWidth:"170px",
                width:"100%",
                height:"100%",
                paddingLeft:"10px",
                borderTopRightRadius: "0.6rem",
                borderBottomRightRadius: "0.6rem",
              }}
            >
              <img
                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTSLU5_eUUGBfxfxRd4IquPiEwLbt4E_6RYMw&s"
                alt=""
                style={{ width: "35px", height: "35px" }}
              />
              <Stack sx={{flexDirection:"column"}}>
                <Tooltip title={item?.sr_name}>
              <Typography sx={{textAlign:"left", fontWeight:"400", fontSize:"14px"}}>
                {item?.sr_name.length > 12
                    ? `${item?.sr_name.slice(0, 12)}...`
                    : item?.sr_name}
              </Typography>
              </Tooltip>
              <Typography sx={{textAlign:"left", fontWeight:"700",fontSize:"16px", fontFamily:"Inter"}}>
                 ₹{item?.total_sale_amount}
              </Typography>
              </Stack>
            </Stack>
          </Stack>
          ))} 
        </Stack>
      </Stack>
    </Box>
  );
};

export default ImprovedRequirement;
