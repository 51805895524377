import { useEffect } from "react";
import useAxiosPrivate from "./useAxiosPrivate";
import { useDispatch } from "react-redux";
import { getCategoryList } from "../slice/categorySlice";
import { getSuperCategoryList } from "../slice/superCategorySlice";

const useSuperCategoryList = () => {
  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();
  const getAllSuperCategoryList = async () => {
    const response = await Axios.get("/super_category_list");
    const data = await response.data;
    dispatch(getSuperCategoryList(data.body));
    console.log(data);
    return data.body
  };
  // useEffect(() => {
  //   getAllSuperCategoryList();
  // }, []);

  return getAllSuperCategoryList;
};

export default useSuperCategoryList;
