import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import {
  FormControl,
  Select,
  MenuItem,
  TextField,
  InputLabel,
  Stack,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import useZoneList from "../hooks/useZoneList";
import useDistributorList from "../hooks/useDistributorList";
import useAddSuperStockist from "../hooks/useAddSuperStockist";
import useSuperStockistList from "../hooks/useSuperStockistList";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  borderRadius:"6px",
  boxShadow: 24,
  p: 4,
};

export default function BasicModal() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const getDropDownData = useSelector((state) => state.app.curretDropdownData);
  const dispatch = useDispatch();
  const [form, setForm] = React.useState({
    zone_id: getDropDownData.zone_id,
  });

  useEffect(() => {
    setForm((prevFormData) => ({
      ...prevFormData,
      zone_id: getDropDownData.zone_id,
    }));
  }, [getDropDownData, open]);
  const zoneList = useSelector((store) => store.app.zoneList);
  const stateList = useSelector((store) => store.app.stateList);
  const superCategoryList = useSelector(
    (store) => store.superCategory.superCategoryList
  );
  const getZoneList = useZoneList();

  const addSuperStockist = useAddSuperStockist(form);


  const handleChange = (e) => {
    setForm((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const [error, setError] = React.useState({});
  const isValid = () => {
    let success = true;
    let err = {};


    if (
      !form ||
      form.zone_id === 0 ||
      form.zone_id === null ||
      form.zone_id === undefined ||
      !form.zone_id
    ) {
      err.zone = "Please Select Zone";
      success = false;
    }

    if (
      !form ||
      form.sc_id === 0 ||
      form.sc_id === null ||
      form.sc_id === undefined ||
      !form.sc_id
    ) {
      err.super_category = "Please Select Super Category";
      success = false;
    }

    if (!form || !form?.name?.trim()) {
      err.dist = "Please Enter name";
      success = false;
    }
    if (!form || !form?.name?.trim()) {
      err.shop_name = "Please Enter Firm Name";
      success = false;
    }

    if (!form || !form?.mobile?.trim()) {
      err.mobile = "Please Enter mobile number";
      success = false;
    }

    setError(err);
    return success;
  };

  const getAllSuperStockistList = useSuperStockistList();
  const handleSubmit = async (e) => {
    // e.preventDefault();
    
    if (isValid()) {
      await addSuperStockist();
      await getAllSuperStockistList();
      setOpen(false);
      setForm({});
    }
  };

  const handleDiscard = (e) => {
    setOpen(false);
    setError({});
  };

  const handleSuperCategory = (e) => {
    let scId = 0;
    let scIdArray = [];
    for (let i = 0; i < superCategoryList?.length; i++) {
      if (e.target.value === superCategoryList[i]?.sc_name) {
        scId = superCategoryList[i]?.sc_id;
        scIdArray.push(scId);
        break;
      }
    }

    setForm((prev) => ({
      ...prev,
      sc_id: scId,
      scIdArray: scIdArray,
    }));
  };

  return (
    <div>
      <Button onClick={handleOpen} sx={{ height: "0.1rem", color: "white" }}>
        Add
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>

          <FormControl
            fullWidth
            size="small"
            sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}
          >


            <Stack
              sx={{ display: "flex", flexDirection: "column", gap: "0.4rem" }}
            >
              <label htmlFor="zone_id">Zone Name:</label>
              <TextField
                size="small"
                type="text"
                id="zone_id"
                name="zone_id"
                value={getDropDownData?.zone_name}
                fullWidth
                disabled
              />
            </Stack>

            <Stack
              sx={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}
            >
              <label>Super Category</label>
              <Select
                onChange={handleSuperCategory}
                label="Super Category"
                sx={{ height: "1.8rem" }}
              >
                {superCategoryList?.map((item, index) => (
                  <MenuItem value={item.sc_name} key={item.sc_id}>
                    {item.sc_name}
                  </MenuItem>
                ))}
              </Select>

              {error.super_category && (
                <label
                  style={{
                    fontSize: "12px",
                    color: "red",
                    fontWeight: 500,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  {"**" + error.super_category + "**"}
                </label>
              )}
            </Stack>
            <Stack
              sx={{ display: "flex", flexDirection: "column", gap: "0.4rem" }}
            >
              <label>Name</label>
              <input
                variant="outlined"
                // size="small"
                name="name"
                onChange={handleChange}
                style={{
                  height: "1.8rem",
                  border: "1px solid rgba(0, 0, 0, 0.3)",
                }}
              />
              {error.dist && (
                <label
                  style={{
                    fontSize: "12px",
                    color: "red",
                    fontWeight: 500,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  {"**" + error.dist + "**"}
                </label>
              )}
            </Stack>
            <Stack
              sx={{ display: "flex", flexDirection: "column", gap: "0.4rem" }}
            >
              <label>Firm Name</label>
              <input
                variant="outlined"
                // size="small"
                name="shop_name"
                onChange={handleChange}
                style={{
                  height: "1.8rem",
                  border: "1px solid rgba(0, 0, 0, 0.3)",
                }}
              />
              {error.shop_name && (
                <label
                  style={{
                    fontSize: "12px",
                    color: "red",
                    fontWeight: 500,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  {"**" + error.shop_name + "**"}
                </label>
              )}
            </Stack>
            <Stack
              sx={{ display: "flex", flexDirection: "column", gap: "0.4rem" }}
            >
              <label>Mobile Number</label>
              <input
                variant="outlined"
                size="small"
                name="mobile"
                onChange={handleChange}
                style={{
                  height: "1.8rem",
                  border: "1px solid rgba(0, 0, 0, 0.3)",
                }}
              />
              {error.mobile && (
                <label
                  style={{
                    fontSize: "12px",
                    color: "red",
                    fontWeight: 500,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  {"**" + error.mobile + "**"}
                </label>
              )}
            </Stack>

            <Stack
              sx={{ display: "flex", flexDirection: "column", gap: "0.4rem" }}
            >
              <label>Email</label>
              <input
                variant="outlined"
                size="small"
                name="email"
                type="email"
                onChange={handleChange}
                style={{
                  height: "1.8rem",
                  border: "1px solid rgba(0, 0, 0, 0.3)",
                }}
              />
              {/* {error.mobile && (
                <label
                  style={{
                    fontSize: "12px",
                    color: "red",
                    fontWeight: 500,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  {"**" + error.mobile + "**"}
                </label>
              )} */}
            </Stack>
            <Stack
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "flex-end",
              }}
            >
              <Stack
                sx={{ display: "flex", flexDirection: "row", gap: "0.5rem" }}
              >
                <Button
                  sx={{
                    background: "#fff",
                    color: "red",
                    height: "1.8rem",
                    border: "2px solid red",
                    width: "max-content",
                  }}
                  onClick={handleDiscard}
                >
                  Discard
                </Button>

                <Button
                  sx={{
                    background: "#EF6C1A",
                    '&:hover':{background: "#f76a11"},
                    color: "white",
                    height: "1.8rem",
                    border: "1px solid rgba(0, 0, 0, 0.2)",
                    width: "max-content",
                  }}
                  onClick={handleSubmit}
                >
                  Submit
                </Button>
              </Stack>
            </Stack>
          </FormControl>
        </Box>
      </Modal>
    </div>
  );
}
