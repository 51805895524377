
import useAxiosPrivate from "./useAxiosPrivate";
import { useDispatch } from "react-redux";
import { getRouteClusterList } from "../slice/adminMapSlice";

const useUpdateSaathiRoute = () => {
  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();

  const UpdateRoute = async (param) => {
    console.log(param, "update");
    try {
      const response = await Axios.post("update_saathi_route", param);
      // console.log(response, "create_route");
      const data = response.data;
      return data;  
    } catch (error) {
      console.error("Error fetching admin map data:", error);
      throw error;  
    }
  };
  return UpdateRoute;
};

export default useUpdateSaathiRoute;


