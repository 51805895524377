import { getSelecetedSFdata } from "../slice/dashboardSlice";
import useAxiosPrivate from "./useAxiosPrivate";
import { useDispatch } from "react-redux";

const useGetSelectedSFOrders = () => {
  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();

  const getSelectedSForders = async (param) => {
    try {
      const response = await Axios.post("dahboard_pincode_select", param);

      const data = response.data.body;
      dispatch(getSelecetedSFdata(data[0]));
      return data;
    } catch (error) {
      console.error("Error fetching dashboard data:", error);
      throw error;
    }
  };
  return getSelectedSForders;
};
export default useGetSelectedSFOrders;
