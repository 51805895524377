import React,{useEffect} from "react";
import Paper from "@mui/material/Paper";
import { Stack, Typography, TextField, IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { useSelector,useDispatch } from "react-redux";
import Checkbox from '@mui/material/Checkbox';
import {useState} from "react"
import { getSchemeCurrentData, getSchemeRetailerId } from "../../slice/schemeSlice";
import { getSchemeRetailerList } from "../../slice/retailerSlice";

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
// import useSRTableDetails from "../../../hooks/useSRTableDetails";

export default function RetailerTable() {
  const dispatch=useDispatch()
  const retailerList = useSelector(
    (store) => store.retailer.schemaRetailerList
  );
  //   useSRTableDetails();
  const row = [
    "Retailer Name",
    // "Shape File ID",
    "Moble Number",
    "Status",
  ];

  const [page, setPage] = React.useState(0);
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = React.useState(false);
  const [categoryId, setCategoryId] = React.useState(null);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredRetailerList, setFilteredRetailerList] = useState([]);

  useEffect(() => {
    // Initialize isChecked property for each retailer to false
    const updatedFilteredRetailerList = retailerList?.map(retailer => ({
      ...retailer,
      isChecked: false
    }));
    
    setFilteredRetailerList(updatedFilteredRetailerList);
  }, [retailerList]);
  
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleDelete = (data) => {
    setIsConfirmDialogOpen(true);
    setCategoryId(data?.category_id);
  };

  const handleConfirmDialogClose = () => {
    setIsConfirmDialogOpen(false);
  };

  const handleConfirmApproveClick = () => {
    setIsConfirmDialogOpen(false);
  };

  const [open, setOpen] = React.useState(false);
  const [categoryEditId, setCategoryEditId] = React.useState(null);
  const [categoryRowData, setCategoryRowData] = React.useState(null);
  const [retailerIdArray,setRetailerIdArray]=useState([])
  const [selectedRetailers, setSelectedRetailers] = useState([]);
  const handleClick = (data) => {
    setOpen(true);
    setCategoryEditId(data?.category_id);
    setCategoryRowData(data);
  };
  const handleCheckboxChange = (e, data) => {
    const isChecked = e.target.checked;
    const retailerId = data?.retailer_id;
  
    let updatedSelectedRetailers = [];
    if (isChecked) {
      updatedSelectedRetailers = [...selectedRetailers, retailerId];
    } else {
      updatedSelectedRetailers = selectedRetailers.filter(id => id !== retailerId);
    }
  
    // Update isChecked property for the retailer in filteredRetailerList
    const updatedFilteredRetailerList = filteredRetailerList.map(retailer => {
      if (retailer.retailer_id === retailerId) {
        return { ...retailer, isChecked: isChecked };
      }
      return retailer;
    });
  
    setSelectedRetailers(updatedSelectedRetailers);
    setFilteredRetailerList(updatedFilteredRetailerList);
    dispatch(getSchemeRetailerId(updatedSelectedRetailers));
  };
  
  

  // useEffect(()=>{
  //   dispatch(getSchemeRetailerId(retailerIdArray))
  // },[retailerIdArray])

  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
    filterRetailerList(event.target.value);
  };
  const filterRetailerList = (query) => {
    const filteredList = retailerList.filter(
      (retailer) =>
        (retailer.first_name + " " + retailer.last_name)
          .toLowerCase()
          .includes(query.toLowerCase())
    );
    setFilteredRetailerList(filteredList);
  };
  const handleRemoveSelectedRetailer = (retailerIdToRemove) => {
    // Update the isChecked property of the retailer in filteredRetailerList
    const updatedFilteredRetailerList = filteredRetailerList.map((retailer) => {
      if (retailer.retailer_id === retailerIdToRemove) {
        return { ...retailer, isChecked: false }; // Set isChecked to false for the removed retailer
      }
      return retailer;
    });
  
    // Update the filtered retailer list state
    setFilteredRetailerList(updatedFilteredRetailerList);
  
    // Remove retailerIdToRemove from selectedRetailers
    const updatedSelectedRetailers = selectedRetailers.filter(
      (id) => id !== retailerIdToRemove
    );
  
    // Update selected retailers state
    setSelectedRetailers(updatedSelectedRetailers);
  
    // Dispatch action with updated selected retailers
    dispatch(getSchemeRetailerId(updatedSelectedRetailers));
  };
  

  const renderSelectedRetailers = () => {
    return (
      <Stack
        sx={{
          gap: "10px",
          margin: "0px 8px",
          maxWidth: "-webkit-fill-available",
          overflowX: "scroll",
          scrollbarWidth: "none",
          "::-webkit-scrollbar": { display: "none" },
        }}
        direction="row"
        alignItems="center"
      >
        {selectedRetailers.map((retailerId) => {
          const retailer = retailerList.find(
            (item) => item?.retailer_id === retailerId
          );
          return (
            <Stack
              sx={{
                padding: "8px",
                borderRadius: "8px",
                backgroundColor: "rgba(239, 108, 26, 0.1)",
                border: "1px solid #EF6C1A",
              }}
            >
              <Typography
                key={retailerId}
                sx={{ fontSize: "14px", textWrap: "nowrap" }}
              >
                {retailer?.first_name} {retailer?.last_name}
                <IconButton sx={{p:"0px"}} onClick={() => handleRemoveSelectedRetailer(retailer.retailer_id)}>
                <Close />
              </IconButton>
              </Typography>
              {/* <Typography key={retailerId} variant="subtitle1">
                {retailer?.assigned_sr}
              </Typography> */}
            </Stack>
          );
        })}
      </Stack>
    );
  };

  return (
    <Paper sx={{ width: "100%", marginTop: "20px" }}>
      <Stack
        direction="row"
        alignItems="center"
        p={2}
        sx={{ overflowX: "hidden" }}
      >
        <TextField
          label="Search Retailers"
          variant="outlined"
          size="small"
          value={searchQuery}
          onChange={handleSearchInputChange}
          sx={{ minWidth: "140px" }}
        />
        {renderSelectedRetailers()}
      </Stack>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {row?.map(
                (
                  item,
                  index // Added index to map function arguments
                ) => (
                  <TableCell
                    key={index} // Added key prop with unique value
                    sx={{
                      background: "#E5E5E5",
                      fontSize: "16px",
                      color: "#5F6868",
                      fontWeight: "500",
                    }}
                    align="start"
                    colSpan={1}
                  >
                    {item}
                  </TableCell>
                )
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredRetailerList
              ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((data, index) => (
                <>
                  <TableRow hover role="checkbox" tabIndex={-1} key={data.id}>
                    {/* Render SO Users */}
                    <TableCell align="start" colSpan={1}>
                      <Checkbox
                        {...label}
                        checked={data.isChecked} // Update to use isChecked property
                        onChange={(e) => handleCheckboxChange(e, data)}
                      />
                      {data?.first_name + " "}
                      {data?.last_name}
                    </TableCell>
                    {/* <TableCell align="start" colSpan={1}>
                      {data?.shape_id}
                    </TableCell> */}
                    <TableCell align="start" colSpan={1}>
                      {data?.mobile_no}
                    </TableCell>
                    <TableCell align="start" colSpan={1}>
                      {data?.status === true ? "Active" : "InActive"}
                    </TableCell>
                  </TableRow>
                </>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      
      <TablePagination
        id="retailerTable"
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={retailerList?.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
