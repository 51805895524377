
import useAxiosPrivate from "./useAxiosPrivate";


const useMainDashboardData = () => {
  const Axios = useAxiosPrivate();

  const getDashboardData = async (param) => {  
    try {
      const response = await Axios.post("get_main_dashboard", param);
      const data = response.data?.body; 
      return data;  
    } catch (error) {
      console.error("Error fetching admin map data:", error);
      throw error;  
    }
  };
  return getDashboardData;
};

export default  useMainDashboardData;