import React, { useEffect, useState } from "react";

import { Box, Card, Stack, Typography } from "@mui/material";
import SalesTopAchievers from "./SalesAcheivers/SalesTopAchievers";
import SalesImprovedRequirement from "./SalesAcheivers/SalesImprovedRequirement";
import { useSelector } from "react-redux";
import useDashboard from "../../hooks/useDashboard";
import ComingSoonImg from "../../assets/data_not_available.png";
import CoinLineChart from "./Graph/CoinLineChart";
import useTopSRDashboard from "../../hooks/useTopSRDashboard";
import { SalesLineChart } from "./Graph/SalesLineChart";
const TopCards = ({ formData }) => {
  const [dashboardData, setDashboardData] = useState();
  const Data = useSelector((state) => state.app.curretDropdownData);
  const getTopSRDahboard = useTopSRDashboard();
  const getDashboardData = useDashboard();
  const [TopSRData, setTopSRData] = useState();
  useEffect(() => {
    if (Data && formData) {
      const param = {
        zone_id: Data?.zone_id,
        month: formData?.month_no,
        year: formData?.year,
      };
      getTopSRData(param);
      getDashboardData(param)
        .then((data) => {
          console.log(data, "getDashboardData");
          setDashboardData(data?.body);
        })
        .catch((error) => {
          console.error("Error fetching dashboard data:", error);
        });

      const id = setInterval(() => {
        getDashboardData(param)
          .then((data) => {
            console.log(data, "getDashboardData");
            setDashboardData(data?.body);
          })
          .catch((error) => {
            console.error("Error fetching dashboard data:", error);
          });
      }, 15000);

      return () => clearInterval(id);
    }
  }, [Data, formData]);

  const getTopSRData = async (param) => {
    const data = await getTopSRDahboard(param);
    setTopSRData(data);
  };
  return (
    <Stack sx={{ width: "100%", height: "100%" }}>
      <Stack sx={{ gap: "12px", display: "flex", flexDirection: "row" }}>
        <Stack
          sx={{
            display: "flex",
            flexWrap: "wrap",
            gap: "22px",
            // justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Card
            sx={{
              // flex: "1 1 160px",
              width: "8rem",
              alignItems: "center",
              textAlign: "center",
              padding: "6px",
              borderRadius: "6px",
              boxShadow: "0px 4px 8px 0px #0000004D",
              borderBottom: "3px solid #E47D03",
            }}
          >
            <Typography variant="h4" color="initial" sx={{ fontSize: "24px" }}>
              {dashboardData?.totalSR}
            </Typography>
            <Typography variant="h7" color="initial">
              SR's
            </Typography>
          </Card>
          <Card
            sx={{
              // flex: "1 1 160px",
              width: "8rem",
              alignItems: "center",
              textAlign: "center",
              padding: "6px",
              borderRadius: "6px",
              boxShadow: "0px 4px 8px 0px #0000004D",
              borderBottom: "3px solid #E40303",
            }}
          >
            <Typography variant="h4" color="initial" sx={{ fontSize: "24px" }}>
              {dashboardData?.totalRetailers}
            </Typography>
            <Typography variant="h7" color="initial">
              Retailers
            </Typography>
          </Card>
          <Card
            sx={{
              // flex: "1 1 160px",
              width: "8rem",
              alignItems: "center",
              textAlign: "center",
              padding: "6px",
              borderRadius: "6px",
              boxShadow: "0px 4px 8px 0px #0000004D",
              borderBottom: "3px solid #15E403",
            }}
          >
            <Typography variant="h4" color="initial" sx={{ fontSize: "24px" }}>
              ₹{dashboardData?.month_sales || 0}
            </Typography>
            <Typography variant="h7" color="initial">
              Sales
            </Typography>
          </Card>
          <Card
            sx={{
              width: "8rem",
              alignItems: "center",
              textAlign: "center",
              padding: "6px",
              borderRadius: "6px",
              boxShadow: "0px 4px 8px 0px #0000004D",
              borderBottom: "3px solid #E403C0",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                component="div"
                sx={{
                  fontSize: "24px", // Default font size
                  "@media (max-width:600px)": {
                    fontSize: "20px", // Adjust font size for smaller screens
                  },
                  "@media (min-width:600px)": {
                    fontSize: "24px", // Default font size for medium screens
                  },
                  "@media (min-width:960px)": {
                    fontSize: "28px", // Adjust font size for larger screens
                  },
                  lineHeight: 1,
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {dashboardData?.total_calls}/
                {dashboardData?.total_outlet_coverages}
              </Typography>
            </Box>
            <Typography variant="subtitle1" color="initial">
              Calls
            </Typography>
          </Card>
          <Card
            sx={{
              // flex: "1 1 160px",
              width: "8rem",
              alignItems: "center",
              textAlign: "center",
              padding: "6px",
              borderRadius: "6px",
              borderBottom: "3px solid #3003E4",
              boxShadow: "0px 4px 8px 0px #0000004D",
            }}
          >
            <Typography variant="h4" color="initial" sx={{ fontSize: "24px" }}>
              {dashboardData?.no_of_sales_calls}/{dashboardData?.total_calls}
            </Typography>
            <Typography variant="h7" color="initial">
              ECO
            </Typography>
          </Card>
          <Card
            sx={{
              // flex: "1 1 160px",
              width: "8rem",
              alignItems: "center",
              textAlign: "center",
              padding: "6px",
              borderRadius: "6px",
              boxShadow: "0px 4px 8px 0px #0000004D",
              borderBottom: "3px solid #15E403",
            }}
          >
            <Typography variant="h4" color="initial" sx={{ fontSize: "24px" }}>
              {parseFloat(dashboardData?.monthly_lpc)?.toFixed(2) || 0}
            </Typography>
            <Typography variant="h7" color="initial">
              Average LPC
            </Typography>
          </Card>
        </Stack>
        {/* <Stack sx={{ display: "flex", flexDirection: "column", gap: "0.8rem" }}>
        <Stack
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent:"center",
              alignItems:"center",
              // gap: "5rem",
              background: "white",
              minWidth:"calc(100vw - 250px)",
              // padding: "1rem 2.5rem 1rem 2.5rem",
              width:"100%",
              height:"100%",
            }}
          >
            <img style={{objectFit:"contain", width:"300px", height:"300px"}} src={ComingSoonImg} alt="coming soon"/>
            <Typography sx={{fontSize:"18px", color:"#464746", fontWeight:"600"}}>Sufficent Data is not Available</Typography>
            </Stack>
            
        </Stack> */}
        <Stack sx={{ gap: "10px", width: "100%" }}>
          <Stack
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "5rem",
              background: "white",
              padding: "16px",
              // width:"100%",
              boxShadow: 1,
              borderRadius: "4px",
              height: "fit-content",
            }}
          >
            <Stack
              sx={{ display: "flex", flexDirection: "column", gap: "1.4rem" }}
            >
              <Stack
                sx={{
                  alignSelf: "flex-start",
                  fontWeight: "700",
                  fontSize: "18px",
                }}
              >
                Sales
              </Stack>
              <Stack
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "3rem",
                  flexWrap: "wrap",
                }}
              >
                <SalesTopAchievers TopSRData={TopSRData} />
                <SalesImprovedRequirement TopSRData={TopSRData} />
              </Stack>
            </Stack>
          </Stack>

<Stack
  sx={{
    flexDirection: { xs: 'column', md: 'row' },
    width: '100%',
    gap:"8px"
  }}
>
  <Stack
    sx={{
      flex: 1,
      width: { xs: '100%', md: '50%' },
    }}
  >
    <Typography sx={{ textAlign: 'left', fontSize: '18px', fontWeight: '700', mb: '5px' }}>
      Sales ( Cumulative )
    </Typography>
    <SalesLineChart TopSRData={TopSRData} />
  </Stack>
  <Stack
    sx={{
      flex: 1,
      width: { xs: '100%', md: '50%' },
    }}
  >
    <Typography sx={{ textAlign: 'left', fontSize: '18px', fontWeight: '700', mb: '5px' }}>
      Coins
    </Typography>
    <CoinLineChart TopSRData={TopSRData} />
  </Stack>
</Stack>

        </Stack>
      </Stack>
    </Stack>
  );
};

export default TopCards;
