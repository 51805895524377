import React, { useState } from "react";

import { Stack, Typography } from "@mui/material";

import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import { useSelector, useDispatch } from "react-redux";
import TextField from "@mui/material/TextField";
import InputAdornment from '@mui/material/InputAdornment';

import { useEffect } from "react";

import RadioButton from "./RadioButton";
import Sidebar from "../Sidebar";
import RetailerTable from "./RetailerTable";
import useSuperCategoryList from "../../hooks/useSuperCategoryList";
import useCategoryListBySuperCategory from "../../hooks/useCategoryListBySuperCategory";
import useSkuListByCategory from "../../hooks/useSkuListByCategory";

import {
  getSchemeCurrentData,
} from "../../slice/schemeSlice";
import useSkuList from "../../hooks/useSkuList";
import Divider from "@mui/material/Divider";

const AddNewSchemeWithPercentage = () => {
  const dispatch = useDispatch();
  const [dataFromChild, setDataFromChild] = useState(null);
  const [selectedSC, setSelectedSC] = useState(null);
  const [selectedSCId, setSelectedSCId] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const [selectedSku, setSelectedSku] = useState(null);
  const [selectedSkuId, setSelectedSkuId] = useState(null);
  const [selectedFreeSku, setSelectedFreeSku] = useState(null);
  const [selectedFreeSkuId, setSelectedFreeSkuId] = useState(null);
  const [freeSkuScName, setFreeSkuScName] = useState("");
  const [obj, setObj] = useState({});
  const qtyType = [
    {
      id: 1,
      name: "ladi",
    },
    {
      id: 2,
      name: "carton",
    },
    {
      id: 3,
      name: "pieces",
    },
  ];
  const schemeType = [
    {
      id: 1,
      name: "Discount Scheme",
    },
    {
      id: 2,
      name: "Quantity Purchase",
    },
    {
      id: 3,
      name: "Text",
    },
  ];
  


  const superCategoryList = useSelector(
    (store) => store.superCategory.superCategoryList
  );
  const categoryList = useSelector(
    (store) => store.category.categoryListBySuperCategory
  );
  const allSkuList = useSelector((store) => store.sku.skuList);
  const skuList = useSelector((store) => store.sku.skuListByCategory);

  useSuperCategoryList();
  useSkuList();
  const getAllCategoryListBySuperCategory = useCategoryListBySuperCategory();
  const getAllSkuListByCategory = useSkuListByCategory();

  useEffect(() => {
    dispatch(getSchemeCurrentData(obj));
  }, [obj]);
  
  const campaignData = useSelector((store) => store.scheme.campaignDetails);

  useEffect(() => {
    setObj((prev) => ({
        ...prev,
        campaign_name: campaignData?.campaign_name,
        campaign_id: campaignData?.campaign_id
      }));
  }, [campaignData]);

  useEffect(() => {
    console.log(obj, "campaignData")
  }, [obj]);

  const handleSCChange = (e) => {
    setSelectedSC(e.target.value);
    let scId = 0;
    for (let i = 0; i < superCategoryList?.length; i++) {
      if (e.target.value === superCategoryList[i].sc_name) {
        scId = superCategoryList[i].sc_id;
      }
    }

    setSelectedSCId(scId);
    setObj((prev) => ({
      ...prev,
      [e.target.name]: scId,
    }));
    getAllCategoryListBySuperCategory(scId);
  };

  const handleCategoryChange = (e) => {
    setSelectedCategory(e.target.value);
    let categoryId = 0;
    for (let i = 0; i < categoryList?.length; i++) {
      if (e.target.value === categoryList[i]?.category_name) {
        categoryId = categoryList[i]?.category_id;
      }
    }

    setSelectedCategoryId(categoryId);
    setObj((prev) => ({
      ...prev,
      [e.target.name]: categoryId,
    }));

    getAllSkuListByCategory(categoryId, selectedSCId);
  };

  const handleSkuChange = (e) => {
    setSelectedSku(e.target.value);
    let skuId = 0;
    for (let i = 0; i < skuList?.length; i++) {
      if (e.target.value === skuList[i]?.sku_name) {
        skuId = skuList[i]?.sku_id;
      }
    }

    setSelectedSkuId(skuId);
    setObj((prev) => ({
      ...prev,
      [e.target.name]: skuId,
    }));

    // getAllSkuListByCategory(categoryId, selectedSCId);
  };

  const handleFreeSkuChange = (e) => {
    setSelectedFreeSku(e.target.value);
    let skuId = 0;
    let Sc_name = null;
    for (let i = 0; i < allSkuList?.length; i++) {
      if (e.target.value === allSkuList[i]?.sku_name) {
        skuId = allSkuList[i]?.sku_id;
        Sc_name = allSkuList[i]?.sc_name;
      }
    }
    setFreeSkuScName(Sc_name);
    setSelectedFreeSkuId(skuId);
    setObj((prev) => ({
      ...prev,
      [e.target.name]: skuId,
    }));
  };

  const handleDataFromChild = (data) => {
    setDataFromChild(data);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;


    setObj((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <Stack
      sx={{
        display: "flex",
        flexDirection: "row",
        backgroundColor: "#E5E5E5",
        gap: "4px",
      }}
    >
      <Box
        sx={{
          minWidth: 140,
          display: "flex",
          flexDirection: "column",
          gap: "3rem",
          padding: "1rem",
          width: "-webkit-fill-available",
          backgroundColor: "#fff",
          height: "fit-content",
          margin: "6px 6px 0px 0px",
          borderRadius: "8px",
        }}
      >
        <Stack
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "3rem",
            flexWrap: "wrap",
          }}
        >
          <Stack
            sx={{
              display: "flex",
              flexDirection: "column",
              flexWrap: "wrap",
              alignItems: "flex-start",
              gap: "2rem",
            }}
          >
            <Typography sx={{ fontWeight: 700, fontSize: "1.3rem" }}>
              Create Scheme
            </Typography>

            <Stack
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "1rem",
                flexWrap: "wrap",
              }}
            >
              <FormControl sx={{ minWidth: 250 }}>
                <InputLabel sx={{ top: "-10px", color: "#989797" }}>
                  Super Category
                </InputLabel>
                <Select
                  labelId="shape-id-label"
                  id="shape-id-select"
                  name="sc_id"
                  value={selectedSC}
                  size="small"
                  displayEmpty
                  sx={{ height: "2.2rem" }}
                  onChange={(e) => handleSCChange(e)}
                >
                  {superCategoryList &&
                    superCategoryList?.filter((sku) => sku.status === true).map((sc) => (
                      <MenuItem key={sc.sc_id} value={sc.sc_name}>
                        {sc.sc_name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <Divider
                orientation="vertical"
                flexItem
                sx={{ borderRight: "1px solid #B7B7B7", margin: "0 1px" }}
              />
              <FormControl sx={{ minWidth: 250 }}>
                <InputLabel sx={{ top: "-10px", color: "#989797" }}>
                  Category
                </InputLabel>
                <Select
                  labelId="shape-id-label"
                  id="shape-id-select"
                  name="category_id"
                  value={selectedCategory}
                  size="small"
                  sx={{ height: "2.2rem" }}
                  onChange={(e) => handleCategoryChange(e)}
                >
                  {categoryList &&
                    categoryList?.filter((sku) => sku.status === true).map((category) => (
                      <MenuItem
                        key={category.category_id}
                        value={category.category_name}
                      >
                        {category.category_name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <Divider
                orientation="vertical"
                flexItem
                sx={{ borderRight: "1px solid #B7B7B7", margin: "0 1px" }}
              />
              <FormControl sx={{ minWidth: 250 }}>
                <InputLabel sx={{ top: "-10px", color: "#989797" }}>
                  SKU
                </InputLabel>
                <Select
                  labelId="shape-id-label"
                  id="shape-id-select"
                  name="sku_id"
                  value={selectedSku}
                  size="small"
                  sx={{ height: "2.2rem" }}
                  onChange={(e) => handleSkuChange(e)}
                >
                  {skuList &&
                    skuList?.filter((sku) => sku.status === true).map((sku) => (
                      
                      <MenuItem key={sku?.sku_id} value={sku?.sku_name}>
                        {sku?.sku_name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Stack>
          </Stack>

          <Stack
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "1rem",
              flexWrap: "wrap",
            }}
          >
            <Stack
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "1rem",
                alignItems: "center",
                alignSelf: "self-start",
              }}
            >
              <label
                style={{
                  display: "flex",
                  justifyContent: "center",
                  fontWeight: "500",
                }}
              >
                Name
              </label>

              <input
                variant="outlined"
                name="scheme_name"
                style={{
                  height: "2.2rem",
                  width: "470px",
                  fontSize: "14px",
                  borderRadius: "4px",
                  border: "1px solid #CDCFD4",
                }}
                size="small"
                // value={query}
                onChange={(e) => handleChange(e)}
                placeholder="Enter Name"
              />
            </Stack>

            <Stack
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "center",
                gap: "1rem",
                flexWrap: "wrap",
              }}
            >
              <Stack
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "1rem",
                  flexWrap: "wrap",
                }}
              >
                <Divider
                  orientation="vertical"
                  flexItem
                  sx={{ borderRight: "1px solid #B7B7B7", margin: "0 1px" }}
                />
                <Stack
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "1rem",
                    flexWrap: "wrap",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <label>Type</label>

                  <FormControl sx={{ minWidth: 200 }}>
                    <Select
                      labelId="shape-id-label"
                      id="shape-id-select"
                      name="scheme_type"
                      value={obj?.scheme_type}
                      size="small"
                      sx={{ height: "2.2rem" }}
                      onChange={(e) => handleChange(e)}
                    >
                      {schemeType &&
                        schemeType?.map((scheme) => (
                          <MenuItem key={scheme.id} value={scheme.name}>
                            {scheme.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Stack>
                <Divider
                  orientation="vertical"
                  flexItem
                  sx={{ borderRight: "1px solid #B7B7B7", margin: "0 1px" }}
                />
                <Stack
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "1rem",
                  }}
                >
                  <label>From</label>
                  <input
                    type="date"
                    id="dateSale"
                    name="first_date"
                    style={{
                      height: "2rem",
                      fontSize: "14px",
                      padding: "4px",
                      borderRadius: "4px",
                      border: "1px solid #CDCFD4",
                    }}
                    onChange={(e) => handleChange(e)}
                    // value={currentSelectedDate} // Use the string format "YYYY-MM-DD" for the value
                  />
                </Stack>
                <Typography sx={{ fontSize: "16px", fontWeight: "700" }}>
                  -
                </Typography>
                <Stack
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "1rem",
                  }}
                >
                  <label>To</label>
                  <input
                    type="date"
                    id="dateSale"
                    name="last_date"
                    style={{
                      height: "2rem",
                      fontSize: "14px",
                      padding: "4px",
                      cursor: "pointer",
                      borderRadius: "4px",
                      border: "1px solid #CDCFD4",
                    }}
                    onChange={(e) => handleChange(e)}
                  />
                </Stack>
              </Stack>
              {obj?.scheme_type === "Discount Scheme" ? (
                <Stack
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    marginLeft: "1rem",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "1rem",
                    flexWrap: "wrap",
                    padding: "16px 12px",
                    backgroundColor: "#F4F4F4",
                    borderRadius: "8px",
                  }}
                >
                  <Stack
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "10px",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <label style={{ fontWeight: "600", fontSize: "14px" }}>
                      Percentage
                    </label>
                    <div
                      style={{
                        position: "relative",
                        display: "inline-block",
                        width: "190px",
                      }}
                    >
                      <TextField
                        variant="outlined"
                        name="percentage"
                        sx={{
                          height: "2.2rem",
                          marginTop: "-0.2rem",
                          width: "170px",
                          borderRadius: "4px",

                        }}
                        InputProps={{
                          startAdornment: <InputAdornment position="start">%</InputAdornment>,
                        }}
                        size="small"
                        onChange={(e) => handleChange(e)}
                      />
 
                    </div>
                  </Stack>

                  <Stack
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "1rem",
                      alignItems: "center",
                    }}
                  >
                    <label style={{ fontWeight: "600", fontSize: "14px" }}>
                      Min Qty
                    </label>
                    <input
                      variant="outlined"
                      name="min_qty"
                      style={{
                        height: "2.2rem",
                        marginTop: "-0.2rem",
                        width: "6rem",
                        border: "1px solid #CDCFD4",
                        justifyContent: "center",
                        borderRadius: "4px",
                      }}
                      size="small"
                      // value={query}
                      onChange={(e) => handleChange(e)}
                      placeholder="Qty"
                    />
                  </Stack>
                  <FormControl sx={{ minWidth: 180 }}>
                    <Select
                      labelId="shape-id-label"
                      id="shape-id-select"
                      name="qty_type"
                      //   value={selectedShapeId}
                      size="small"
                      sx={{ height: "2.2rem" }}
                      onChange={(e) => handleChange(e)}
                    >
                      {qtyType &&
                        qtyType
                        .filter((qty) => !(selectedSC === "Oil" && qty.name === "ladi")).map((qty) => (
                          <MenuItem key={qty?.id} value={qty?.name}>
                            {qty?.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Stack>
              ) : obj?.scheme_type === "Quantity Purchase" ? (
                <Stack
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    marginLeft: "1rem",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "1rem",
                    flexWrap: "wrap",
                    padding: "16px 12px",
                    backgroundColor: "#F4F4F4",
                    borderRadius: "8px",
                  }}
                >
                  <Stack
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "2rem",
                      alignItems: "start",
                    }}
                  >
                    <Stack
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "1rem",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <label>Free Qty of</label>
                      <input
                        variant="outlined"
                        name="free_sku_qty"
                        style={{
                          height: "2.2rem",
                          marginTop: "-0.2rem",
                          width: "6rem",
                          borderRadius: "4px",
                          border: "1px solid #CDCFD4",
                        }}
                        size="small"
                        // value={query}
                        onChange={(e) => handleChange(e)}
                        placeholder="Qty"
                      />
                    </Stack>

                    <FormControl sx={{ minWidth: 140 }}>
                      <Select
                        labelId="shape-id-label"
                        id="shape-id-select"
                        name="free_sku_qty_type"
                        //   value={selectedShapeId}
                        size="small"
                        sx={{ height: "2.2rem" }}
                        onChange={(e) => handleChange(e)}
                      >
                        {qtyType && qtyType.filter((qty) => !(freeSkuScName === "Oil" && qty.name === "ladi")).map((qty) => (
                            <MenuItem key={qty?.id} value={qty?.name}>
                              {qty?.name}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                    <FormControl sx={{ minWidth: 140, maxWidth:140 }}>
                      <InputLabel sx={{ top: "-10px", color: "#989797" }}>
                        SKU
                      </InputLabel>
                      <Select
                        labelId="shape-id-label"
                        id="shape-id-select"
                        name="free_sku_id"
                        value={selectedFreeSku}
                        size="small"
                        sx={{ height: "2.2rem" }}
                        onChange={(e) => handleFreeSkuChange(e)}
                      >
                        {allSkuList &&
                          allSkuList?.filter((sku) => sku.sc_id === selectedSCId && sku.status === true)
                          .map((sku) => (
                            <MenuItem key={sku?.sku_id} value={sku?.sku_name}>
                              {sku?.sku_name}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Stack>

                  <Stack
                    sx={{ display: "flex", flexDirection: "row", gap: "2rem" }}
                  >
                    <Stack
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "1rem",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <label style={{ width: "82px" }}>For Every</label>
                      <input
                        variant="outlined"
                        name="qty"
                        style={{
                          height: "2.2rem",
                          marginTop: "-0.2rem",
                          width: "6rem",
                          borderRadius: "4px",
                          border: "1px solid #CDCFD4",
                        }}
                        size="small"
                        // value={query}
                        onChange={(e) => handleChange(e)}
                        placeholder="Qty"
                      />
                    </Stack>
                    <FormControl sx={{ minWidth: 140 }}>
                      <Select
                        labelId="shape-id-label"
                        id="shape-id-select"
                        name="qty_type"
                        //   value={selectedShapeId}
                        size="small"
                        sx={{ height: "2.2rem" }}
                        onChange={(e) => handleChange(e)}
                      >
                        {qtyType &&
                        qtyType
                        .filter((qty) => !(selectedSC === "Oil" && qty.name === "ladi")).map((qty) => (
                            <MenuItem key={qty?.id} value={qty?.name}>
                              {qty?.name}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                    <FormControl sx={{ minWidth: 120 }}>
                      <input
                        variant="outlined"
                        disabled="true"
                        style={{
                          height: "2.2rem",
                          marginTop: "-0.2rem",
                          borderRadius: "4px",
                          fontSize: "14px",
                          border: "1px solid #CDCFD4",
                          width: "140px",
                        }}
                        size="small"
                        value={selectedSku}
                      />
                    </FormControl>
                  </Stack>
                </Stack>
              ) : null}
            </Stack>
          </Stack>
        </Stack>
        <Divider
              variant="middle"
              sx={{ paddingTop: "6px", borderBottom: "1px Solid #BABABA" }}
            />
        <Stack>
          <RadioButton sendDataToParent={handleDataFromChild} />
          {dataFromChild?.outlets === true && <RetailerTable />}
        </Stack>
      </Box>
    </Stack>
  );
};

export default AddNewSchemeWithPercentage;
