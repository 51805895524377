import { useState } from "react";
import { Drawer, Stack, Typography } from "@mui/material";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Link as RouterLink } from "react-router-dom";

const NavDrawer = ({ open, setOpen }) => {
  const [selectedLink, setSelectedLink] = useState("home");

  const handleLinkClick = (link) => {
    setSelectedLink(link);
  };

  return (
    <Stack>
      <Drawer
        sx={{ height: "100vh" }}
        PaperProps={{
          elevation: 8,
          sx: {
            width: "14rem",
            height: "100vh",
            borderRadius: "5px",
            padding: "5px",
            backgroundColor: "#2B2A41",
          },
        }}
        anchor={"left"}
        open={open}
        onClose={() => setOpen(!open)}
      >
        <Stack onClick={() => setOpen(!open)} m={"2rem 1rem"}>
          <CloseIcon sx={{ color: "white", fontSize: "2rem" }} />
        </Stack>
        <Stack direction={"column"} gap={2}>
          <Stack sx={{ textDecoration: "none", color: "white" }}>
            <RouterLink
              style={{
                textDecoration: "none",
                backgroundColor:
                  selectedLink === "home" ? "#3C3A59" : "transparent",
                borderBottomRightRadius: "50px",
                borderTopRightRadius: "50px",
              }}
              to={"/"}
              onClick={() => handleLinkClick("home")}
            >
              <Typography
                sx={{
                  textDecoration: "none",
                  color: "white",
                  paddingLeft: "1rem",
                  padding: "10px",
                }}
              >
                Home
              </Typography>
            </RouterLink>
          </Stack>
          <Stack
            sx={{
              textDecoration: "none",
              color: "white",
            }}
          >
            <RouterLink
              style={{
                textDecoration: "none",
                backgroundColor:
                  selectedLink === "contact" ? "#3C3A59" : "transparent",
                borderBottomRightRadius: "50px",
                borderTopRightRadius: "50px",
              }}
              to={"/contactus"}
              onClick={() => handleLinkClick("contact")}
            >
              <Typography
                sx={{ textDecoration: "none", color: "white", padding: "10px" }}
              >
                Contact Us
              </Typography>
            </RouterLink>
          </Stack>
          {/* <Stack
            sx={{
              textDecoration: "none",
              color: "white",
            }}
          >
            <RouterLink
              style={{
                textDecoration: "none",
                backgroundColor:
                  selectedLink === "partner" ? "#3C3A59" : "transparent",
                borderBottomRightRadius: "50px",
                borderTopRightRadius: "50px",
              }}
              to={"/vitaranpartner"}
              onClick={() => handleLinkClick("partner")}
            >
              <Typography
                sx={{ textDecoration: "none", color: "white", padding: "10px" }}
              >
                Vitaran Partner
              </Typography>
            </RouterLink>
          </Stack> */}
        </Stack>
      </Drawer>
    </Stack>
  );
};

export default NavDrawer;
