import { createSlice } from "@reduxjs/toolkit";

const categorySlice = createSlice({
  name: "category",
  initialState: {
    categoryList: null,
    categoryFormData: null,
    categoryListBySuperCategory: null,
  },
  reducers: {
    getCategoryList: (state, action) => {
      state.categoryList = action.payload;
    },
    getCategoryListBySuperCategory: (state, action) => {
      state.categoryListBySuperCategory = action.payload;
    },
    getCategoryFormData: (state, action) => {
      state.categoryFormData = action.payload;
    },
    resetCategoryFormData: (state, action) => {
      state.categoryFormData = null;
    },
  },
});

export default categorySlice.reducer;
export const {
  getCategoryList,
  getCategoryFormData,
  resetCategoryFormData,
  getCategoryListBySuperCategory,
} = categorySlice.actions;
