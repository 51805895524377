import { useEffect } from "react";
import useAxiosPrivate from "./useAxiosPrivate";
import { useDispatch, useSelector } from "react-redux";
import { getDistributorList } from "../slice/distributorSlice";
import { getSOList } from "../slice/soSlice";
import { getSRList } from "../slice/srSlice";

const useSRList = () => {
  const currentzone = useSelector((state) => state.assignRoute.zone_id);
  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();
  const getAllSRList = async (status) => {
    if (currentzone) {
      const param = {
        zone_id: currentzone,
        status:status
      };
    const response = await Axios.post("/sr_list", param);
    const data = await response.data;
    dispatch(getSRList(data.body));
    }
  };
  // useEffect(() => {
  //   getAllDistributorList();
  // }, []);

  return getAllSRList;
};

export default useSRList;
