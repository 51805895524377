import axios from "axios";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { getRefreshToken } from "../slice/loginSlice";
import { baseUrl } from "../utility/BaseURL";

const useRefreshToken = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const GETACCESSTOKEN = localStorage.getItem("refreshToken");

  const Axios = axios.create({
    baseURL: baseUrl,
    headers: {
      Authorization: `Bearer ${GETACCESSTOKEN}`,
    },
  });

  const refresh = async () => {
    try {
      const { data } = await Axios.post("/NewAccessToken");
      // console.log(data);
      dispatch(getRefreshToken(data));
      return data?.access_token;
    } catch (error) {
      // console.log("error response", error);
      navigate("/login");
    }
  };
  return refresh;
};

export default useRefreshToken;
