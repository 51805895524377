import { useEffect } from "react";
import useAxiosPrivate from "./useAxiosPrivate";
import { useDispatch } from "react-redux";
import { getCoinTableData } from "../slice/performanceSlice";

const useCoinDetails = () => {
  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();
  const getCoinDetails = async (param) => {
    const response = await Axios.post("get_coin_details", param);
    const data = await response.data;
    dispatch(getCoinTableData(data.body));
  };

  return getCoinDetails;
};

export default useCoinDetails;
