import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Styles from '../styles/UpdateVillage.module.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Button, TextField } from '@mui/material';
import { styled } from '@mui/material/styles'
import CloseIcon from '@mui/icons-material/Close';
// import { UpdateVillage } from '../../../../actions/Route/RouteMgt';
// import { RETAINDATA, VILLAGEDATA } from '../../../../constants/RouteMgt/Route';

const RedButton = styled(Button)(({ theme }) => ({
    color: "white",
    backgroundColor: "#731D3A",
    border: "2px solid #731D3A",
    '&:hover': {
        backgroundColor: "#731D3A",
        color: "white",
        border: "2px solid #731D3A"
    },
}));

const RedButtonUpload = styled(Button)(({ theme }) => ({

    height: "35px",
    color: "white",
    backgroundColor: "#731D3A",
    border: "2px solid #731D3A",
    '&:hover': {
        backgroundColor: "#731D3A",
        color: "white",
        border: "2px solid #731D3A"
    },
}));


const OutLineButton = styled(Button)(({ theme }) => ({
    color: "#731D3A",
    backgroundColor: "white",
    border: "2px solid #731D3A",
    '&:hover': {
        backgroundColor: "white",
        color: "#731D3A",
        border: "2px solid #731D3A"
    },
}));

const UpdateVillageMgt = ({ rootzone, Statevalue }) => {
    const dispatch = useDispatch()
    const Firsttoken = useSelector(state => state.Token);
    const route = useSelector(state => state.RouteMgt);
    const [rootObj, setRootObj] = useState({
        village_id: 0,
        village_name: "",
        zone_name: "",
        created_at: "",
        route_id: 0,
        updated_at: null,
        route_code: "",
        available: false,
        population:0,
        lati:'',
        longi:''
    })
    const [obj, setObj] = useState({
        village_id: 0,
        village_name: "",
        zone_name: "",
        created_at: "",
        route_id: 0,
        updated_at: null,
        route_code: "",
        available: false,
        population:0,
        lati:'',
        longi:''
    })
    const [village, setVillage] = useState('');
    const [addSuccess, setAddsuccess] = useState(false);
    const [addFailed, setAddFailed] = useState({
        failed: false,
        message: ''
    })

    useEffect(() => {
        if (route && route.village && route.village !== "undefined" && route.village !== null) {
            setObj({
                ...obj,
                village_id: route.village.village_id,
                village_name: route.village.village_name,
                zone_name: route.village.zone_name,
                created_at: route.village.created_at,
                route_id: route.village.route_id,
                updated_at: route.village.updated_at,
                route_code: route.village.route_code,
                available: route.village.available,
                population:route.village.population,
                lati:route.village.lati,
                longi:route.village.longi
            })
            setRootObj({
                ...rootObj,
                village_id: route.village.village_id,
                village_name: route.village.village_name,
                zone_name: route.village.zone_name,
                created_at: route.village.created_at,
                route_id: route.village.route_id,
                updated_at: route.village.updated_at,
                route_code: route.village.route_code,
                available: route.village.available,
                population:route.village.population,
                lati:route.village.lati,
                longi:route.village.longi
            })
        }

    }, [route])

    useEffect(()=>{
         setObj({
            village_id: 0,
            village_name: "",
            zone_name: "",
            created_at: "",
            route_id: 0,
            updated_at: null,
            route_code: "",
            available: false,
            population:0,
            lati:'',
            longi:''
         })
    },[rootzone])


    const handleChange = (e) => {
        setObj({
            ...obj,
            [e.target.name]:e.target.value
        })
    }

    const handleCancel = () => {
        setObj(rootObj)
        // dispatch({type:RETAINDATA,payload:rootObj})
    }



    const handleRemoveFile = () => {
        setObj({
            ...obj,
            route_code: ''
        })
    }

    const IsValid = () => {
       let success = true;

   

       if(!obj?.village_name?.trim()){
           toast.error("Village cannot be empty");
           success = false
       }

       if(!obj?.population?.toString().trim()){
        toast.error("Population cannot be empty");
        success = false
    }

    if (!obj?.lati?.trim()) {
        success = false;
        toast.error('Latitude cannot be empty')
    }

    if (!obj?.longi?.trim()) {
        success = false;
        toast.error('Longitude cannot be empty')
    }

    if(parseInt(obj?.population)<0){
        toast.error("Population cannot be negative");
        success = false
    }

    if (parseFloat(obj?.lati)<=0) {
        success = false;
        toast.error('Latitude cannot be Negative OR zero!! Please Enter a valid One')
    }

    if (parseFloat(obj?.longi)<=0) {
        success = false;
        toast.error('Longitude cannot be Negative OR zero!! Please Enter a valid One')
    }

       return success
    }

    const handleUpdate = () => {
           if(IsValid()){
            if (Firsttoken && Firsttoken.token && Firsttoken.token !== null && Firsttoken.token.length !== 0 && Firsttoken !== "undefined") {
                let param = {
                    "village_id": `${obj.village_id}`,
                    "village_name": `${obj.village_name}`,
                    "route_id": obj.route_code && obj.route_code.length!==0 && obj.route_code!=="undefined" ? (obj.route_id) : (`0`),
                    "population":`${obj.population}`,
                    "lati":`${obj.lati}`,
                    "longi":`${obj.longi}`
                }

                
            //    dispatch(UpdateVillage(Firsttoken.token,param,setAddsuccess,addFailed,setAddFailed))
            }
           }
    }

    React.useEffect(() => {
        if (addSuccess) {
            toast.success("Updated Village SuccessFully");
            setAddsuccess(false);
            // dispatch({type:RETAINDATA,payload:obj})

            
        }
    }, [addSuccess])

    React.useEffect(() => {
        if (addFailed.failed) {
            toast.error(`${addFailed.message}`);
            setAddFailed({
                ...addFailed,
                failed: false,
                message: ''

            })

        }
    }, [addFailed])


    return (
        <>
            <ToastContainer
                position="bottom-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />

            <div className={Styles.Container}>

                <div className={Styles.TopContainer}>
                    <div className={Styles.List}>
                        <div className={Styles.TitleContainer}>
                            <label>State:</label>
                        </div>
                        <div className={Styles.TextContainer}>
                            <TextField variant="outlined" size="small" value={Statevalue} disabled={true} />
                        </div>

                    </div>

                    <div className={Styles.List}>
                        <div className={Styles.TitleContainer}>
                            <label>Zone:</label>
                        </div>
                        <div className={Styles.TextContainer}>
                            <TextField variant="outlined" size="small" value={obj.zone_name} disabled={true} />
                        </div>

                    </div>

                    <div className={Styles.List}>
                        <div className={Styles.TitleContainer}>
                            <label>Village:</label>
                        </div>
                        <div className={Styles.TextContainer}>
                            <TextField variant="outlined" size="small" name="village_name" value={obj.village_name} onChange={handleChange} />
                        </div>

                    </div>


                    <div className={Styles.List}>
                        <div className={Styles.TitleContainer}>
                            <label>Population:</label>
                        </div>
                        <div className={Styles.TextContainer}>
                            <TextField variant="outlined" size="small" type="number" name="population" value={obj.population} onChange={handleChange} />
                        </div>

                    </div>

                    <div className={Styles.List}>
                        <div className={Styles.TitleContainer}>
                            <label>Latitude:</label>
                        </div>
                        <div className={Styles.TextContainer}>
                            <TextField variant="outlined" size="small" type="text" name="lati" value={obj.lati} onChange={handleChange} />
                        </div>

                    </div>

                    <div className={Styles.List}>
                        <div className={Styles.TitleContainer}>
                            <label>Longitude:</label>
                        </div>
                        <div className={Styles.TextContainer}>
                            <TextField variant="outlined" size="small" type="text" name="longi" value={obj.longi} onChange={handleChange} />
                        </div>

                    </div>

                    {/* <div className={Styles.List}>
                        <div className={Styles.TitleContainer}>
                            <label>Route:</label>
                        </div>
                        <div className={Styles.TextContainer}>
                            {
                                obj && obj.route_code && obj.route_code.length !== 0 ? (
                                    <div className={Styles.fileContainer}>
                                        <label>{obj.route_code}</label>
                                        <button className={Styles.fileButton} onClick={handleRemoveFile}><CloseIcon className={Styles.fileButtonIcon} /></button>
                                    </div>
                                ) :
                                    (
                                        <div className={Styles.List}>
                                            <h6 className={Styles.Route}>**No Routes Assigned**</h6>
                                        </div>
                                    )
                            }

                        </div>

                    </div> */}

                    <div className={Styles.AddList}>

                    </div>
                </div>
                <div className={Styles.BulkList}>
                    <RedButton onClick={handleUpdate} size="small">Update</RedButton>
                    <OutLineButton variant='contained' onClick={handleCancel} size="small">Cancel</OutLineButton>
                </div>
            </div>
        </>
    )
}

export default UpdateVillageMgt
