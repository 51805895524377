import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getEmployeeAreaDropdownList, getEmployeeAreaList } from "../../slice/routeSlice";
import useAxiosPrivate from "../useAxiosPrivate";


const useEmployeeAreaDropdownList = () => {
  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();
  const getAllEmployeeAreaDropdownList = async (param) => {
    
    const response = await Axios.post("route_area-list",param);
    const data = await response.data;
    dispatch(getEmployeeAreaDropdownList(data.data));
    return data.data
  };

  return getAllEmployeeAreaDropdownList
};

export default useEmployeeAreaDropdownList;
