import { createSlice } from "@reduxjs/toolkit";
const soSlice = createSlice({
  name: "SO",
  initialState: {
    SOList: null,
  },
  reducers: {
    getSOList: (state, action) => {
      state.SOList = action.payload;
    },
  },
});

export default soSlice.reducer;
export const { getSOList } = soSlice.actions;
