import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
// import { GetUserMgtWholesalerList } from '../../../../actions/UserMgt/UserMgt';
import UserMgtWholesalerTable from "./UserMgtWholesalerTable";
import UserMgtWholesalerUpdateForm from "./UserMgtWholesalerUpdateForm";
import styles from "../styles/UserMgtWholesaler.module.css";
import Button from "@mui/material/Button";
import { FormControl, MenuItem, Select, Stack } from '@mui/material';
import { styled } from "@mui/material/styles";
import { IconButton, InputAdornment, TextField } from "@material-ui/core";
import SearchIcon from "@mui/icons-material/Search";
import Modal from "../../../utility/Modal";
import useDistributorList from "../../../hooks/useDistributorList";
import { Box } from "@mui/material";
import ExcelDownload from "./ExcelDownload";
import ExcelUpload from "./ExcelUpload";

const RedButton = styled(Button)(({ theme }) => ({
  color: "white",
  backgroundColor: "#EF6C1A",
  "&:hover": {
    backgroundColor: "#EF6C1A",
  },
}));

const UserMgtWholesaler = ({ zone }) => {
  const [q, setQ] = useState("");
  
  const userlist = useSelector((state) => state.UserMgt);
  const currentZone = useSelector((state) => state.app.curretDropdownData);
  const [status, setStatus] = useState(true); 

  const handleChange = (event) => {
    const value = event.target.value === 'Active' ? true : false;
    setStatus(value);
  };
  const [Role, setRole] = React.useState("All");
  const [searchColumn, setSearchColumn] = useState([
    "user_id",
    "user_code",
    "name",
    "surname",
    "mobile_no",
  ]);
  const distributorAllList = useSelector(
    (store) => store.distributor.distributorList
  );

  // console.log(distributorAllList, "filterDistributorList");
  const getAllDistributorList = useDistributorList();
  useEffect(() => {
    getAllDistributorList(status);
  }, [currentZone, status]);

  const [filterlist, setFilterlist] = useState([]);
  const [Userdetail, setUserDetail] = useState({});

  React.useEffect(() => {
    if (
      userlist &&
      userlist.wholesalerList &&
      userlist.wholesalerList.length !== 0 &&
      userlist.wholesalerList !== "undefined" &&
      userlist.wholesalerList !== null
    ) {
      setFilterlist(userlist.wholesalerList);
      setUserDetail(userlist.wholesalerList[0]);
    }
  }, [userlist]);

  // React.useEffect(() => {
  //     if (Firsttoken && Firsttoken.token && Firsttoken.token !== null && Firsttoken.token.length !== 0 && Firsttoken !== "undefined") {
  //         setToken(Firsttoken.token);
  //         let param = {
  //             "admin_id": 1,
  //             "zone_name": `${zone}`
  //         }
  //         dispatch(GetUserMgtWholesalerList(Firsttoken.token, param))
  //     }

  // }, [Firsttoken, zone])

  const Searchfilter = (datas) => {
    return datas.filter((row) =>
      searchColumn.some((column) =>
        row[column] !== null &&
        row[column] !== "undefined" &&
        row[column].length !== 0
          ? row[column].toString().toLowerCase().indexOf(q.toLowerCase()) > -1
          : ""
      )
    );
  };

  const handleRoleChange = (e) => {
    setRole(e.target.value);
  };

  const handleSubmit = () => {
    let neo = [];
    let filterRole = Role.toLowerCase();

    if (filterRole.toLocaleLowerCase() !== "all") {
      if (
        userlist.wholesalerList &&
        userlist.wholesalerList.length !== 0 &&
        userlist.wholesalerList !== "undefined" &&
        userlist.wholesalerList !== null
      ) {
        for (let i = 0; i < userlist.wholesalerList.length; i++) {
          if (
            userlist.wholesalerList[i].role_name.toLocaleLowerCase() ===
            filterRole.toLocaleLowerCase()
          ) {
            neo.push(userlist.wholesalerList[i]);
          }
        }
      }

      // Ashish
    } else {
      neo = userlist.wholesalerList;
    }
    //Ashish
    setFilterlist(neo);
    setUserDetail(neo[0]);
  };

  const [isAdd, setIsAdd] = useState(false);

  const handleAdd = () => {
    setIsAdd(true);
  };

  const [query, setQuery] = useState("");
  const handleSearch = (e) => {
    setQuery(e.target.value);
  };
  const [filterDistributorList, setFilterDistributorList] = useState(null);

  useEffect(() => {
    if (distributorAllList) {
      setFilterDistributorList(distributorAllList);
    }
  }, [distributorAllList]);
  useEffect(() => {
    const filteredData = distributorAllList?.filter((distributor) => {
      return (
        distributor?.dist_name?.toLowerCase().includes(query?.toLowerCase()) ||
        distributor?.mobile_no?.toLowerCase().includes(query?.toLowerCase()) ||
        distributor?.dist_code?.toLowerCase().includes(query?.toLowerCase()) ||
        distributor?.zone_name?.toLowerCase().includes(query?.toLowerCase()) ||
        distributor?.shop_name?.toLowerCase().includes(query?.toLowerCase())
      );
    });

    setFilterDistributorList(filteredData);
  }, [query]);

  const handleExcelDownload = () => {};

  return (
    <>
      <div className={styles.UserMgtWholesalerTable}>
        <div className={styles.MidSubContainerOne}>
          <div className={styles.MidSubContainerOneList}>
            {/* <label className={styles.MidSubContainerOneLabel}>Role</label>
            <Box sx={{ minWidth: 120 }}>
              <FormControl fullWidth>
                <Select
                  value={Role}
                  onChange={handleRoleChange}
                  size="small"
                  sx={{ height: "1.8rem" }}
                >
                  {RoleList &&
                    RoleList.map((item, index) => (
                      <MenuItem value={item} key={index}>
                        {item}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Box> */}
          </div>

          <div style={{ width: "100%" }}>
            {/* <label className={styles.MidSubContainerOneLabel}>
              Search User
            </label> */}
            <Box
              sx={{
                display: "flex !important",
                flexDirection: "row !important",
                justifyContent: "space-between !important",
                alignItems: "center !important",
                flexWrap: "wrap !important",
              }}
            >
              <Stack
                sx={{ flexDirection: "row", gap: "8px", alignItems: "center" }}
              >
                <TextField
                  variant="outlined"
                  sx={{
                    width: "300px",
                    "& .MuiOutlinedInput-root": {
                      padding: "0px 4px", // Adjust padding for height reduction
                    },
                    "& .MuiInputBase-input": {
                      fontSize: "0.875rem", // Decrease font size
                      lineHeight: "1.2", // Adjust line height
                    },
                  }}
                  size="small"
                  value={query}
                  onChange={handleSearch}
                  placeholder="Search User"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton>
                          <SearchIcon /> {/* Assume SearchIcon is imported */}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <FormControl
                  fullWidth
                  size="small"
                  // variant="outlined"
                  sx={{
                    minWidth: 140,
                    maxWidth: 140,
                    padding: "8.5px 14px !important",
                    "& .MuiOutlinedInput-root": {
                      padding: "0px 4px !important",
                    },
                    "& .MuiOutlinedInput-input":{
                      padding: "8.5px 14px !important",
                    }
                  }}
                >
                  {/* <InputLabel id="status-label">Status</InputLabel>    */}
                  <Select
                    labelId="status-label"
                    value={status ? "Active" : "Inactive"}
                    onChange={handleChange}
                    label="Status"
                  >
                    <MenuItem value="Active">Active</MenuItem>
                    <MenuItem value="Inactive">Inactive</MenuItem>
                  </Select>
                </FormControl>
              </Stack>
              <Box sx={{ display: "flex", flexDirection: "row", gap: "1rem" }}>
                <ExcelDownload distributorList={filterDistributorList} />
                {/* <ExcelUpload /> */}
              </Box>
            </Box>
          </div>
        </div>
        <UserMgtWholesalerTable
          filterlist={filterDistributorList}
          Searchfilter={Searchfilter}
          detail={
            filterlist && filterlist.length !== 0 && filterlist !== "undefined"
              ? Userdetail
              : {}
          }
          setUserDetail={setUserDetail}
        />
      </div>

      <div className={styles.UserMgtWholesalerUpdateFormScrollViewContainer}>
        <div id="UserMgtWholesalerUpdateFormScrollView">
          {distributorAllList &&
          distributorAllList?.length !== 0 &&
          distributorAllList !== "undefined" ? (
            <UserMgtWholesalerUpdateForm
              detail={
                distributorAllList &&
                distributorAllList?.length !== 0 &&
                distributorAllList !== "undefined"
                  ? Userdetail
                  : {}
              }
              list={
                distributorAllList &&
                distributorAllList?.length !== 0 &&
                distributorAllList !== "undefined"
                  ? distributorAllList
                  : []
              }
            />
          ) : (
            <h5>No Data</h5>
          )}
        </div>
      </div>
    </>
  );
};

export default UserMgtWholesaler;
