// export const baseUrl = "http://13.232.44.195:3092/admin/";

export const baseUrl = "https://selorce.com:3002/admin/";  //production

// export const baseUrl = "http://vitaranlive.com:3092/admin/";  //dev

// export const baseUrl = "http://localhost:3092/admin/";

export const BaseImgUrl =
  "https://selorce.s3.ap-south-1.amazonaws.com/selorce-dev/";
export const ShopBaseImgUrl =
  "https://saathi-prod.s3.ap-south-1.amazonaws.com/shops/";

export const linkedIn = "https://www.linkedin.com/company/echnotek";
// export const WhatsappUrl = `https://api.whatsapp.com/send/?phone=91${ContactNo}&text=I+want+to+know+more+details+about+EchnoTechnologies+Services&app_absent=0`;
