import * as React from "react";
import { useEffect, useState } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useSelector, useDispatch } from "react-redux";
import { getLoginCredential, getValue } from "../slice/loginSlice";
import useLogin from "../hooks/useLogin";
import { loginIsValid } from "../utility/ValidationCheck";
import { Stack } from "@mui/material";
import Swal from "sweetalert2";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://mui.com/">
        Your Website
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

export default function SignIn() {
  const dispatch = useDispatch();
  const loginCredential = useSelector((store) => store.login.loginCredential);
  const [error, setError] = useState("");

  const [obj, setObj] = useState({
    email: null,
    Password: null,
  });
  //Custom Hooks
  const getLogin  = useLogin();

  //UseEffect
  useEffect(() => {
    dispatch(getLoginCredential(obj));
  }, [obj]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const validationResults = loginIsValid(
      loginCredential?.email,
      loginCredential?.Password
    );

    setError(validationResults?.error);
    if (validationResults?.success) {
      await getLogin();
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "email") {
      setObj((prevObj) => ({
        ...prevObj,
        email: value,
      }));
    }
    if (name === "password") {
      setObj((prevObj) => ({
        ...prevObj,
        Password: value,
      }));
    }
  };
  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Enter Email Id"
              name="email"
              autoComplete="email"
              autoFocus
              onChange={handleChange}
            />
            {error.mobile && (
              <Typography variant="caption" sx={{ color: "red" }}>
                {error.mobile}
              </Typography>
            )}

            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={handleChange}
            />
            {error.password && (
              <Typography
                variant="caption"
                sx={{ color: "red", marginLeft: "20px" }}
              >
                {error.password}
              </Typography>
            )}
            <Stack sx={{ alignItems: "center" }}>
              <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Remember me"
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Sign In
              </Button>
            </Stack>
            <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link href="#" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}
