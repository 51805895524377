
import { useSelector } from "react-redux";
import useAxiosPrivate from "./useAxiosPrivate";
import { ToastContainer, toast } from "react-toastify";

const useUpdateShapeFileName = () => {
  const Axios = useAxiosPrivate();

  const setUpdateShapeFileName = async (param) => {
    try {
      const response = await Axios.post("update_shape_file_name", param);
      const data = response.data; 
      if (data.code === 200) {
        toast.success("Updated SuccessFully");
      } else {
        toast.error("Update Failed");
      }
      return data;  
    } catch (error) {
      console.error("Error fetching admin map data:", error);
      throw error;  
    }
  };
  return setUpdateShapeFileName;
};

export default  useUpdateShapeFileName;