import React, { useEffect, useState } from 'react'
import { Button, Stack, TextField, Typography } from '@mui/material';
import * as XLSX from "xlsx";
import DownloadIcon from "@mui/icons-material/Download";
import { ToastContainer, toast } from "react-toastify";
import useEcoNonEcoDownload from '../../hooks/useEcoNonEcoDownload';
import { useSelector } from 'react-redux';
import DownloadLoader from './DownloadLoader';
const MonthlyDownloads = () => {
    const [isDownloading, setIsDownloading] = useState(false);
    const zoneData = useSelector((state) => state.app.curretDropdownData);
    const downloadName = [
        {
          id: 1,
          name: "Eco Non-Eco Report",
        }
      ];
      const getExcelData = useEcoNonEcoDownload();
      const handleDayExcelDownload = async () => {
        setIsDownloading(true);
const param = {
    zone_id: zoneData.zone_id
};
const data = await getExcelData(param);
const months = [...new Set(data.flatMap(item => item.monthly_sales.map(sale => sale.month)))].reverse(); // Reverse the months order

// Define headers
const headers = [
    { header: 'ASM Name', key: 'asm_name', width: 15 },
    { header: 'SO NAME', key: 'so_name', width: 30 },
    { header: 'Employee Area', key: 'area', width: 20 },
    { header: 'SR name', key: 'sr_name', width: 20 },
    { header: 'Retailer', key: 'shop_name', width: 30 },
    { header: 'Retailer Contact', key: 'mobile_no', width: 15 },
    ...months.map(month => ({ header: month, key: month, width: 10 })),
    { header: 'Total Sales', key: 'total_sales', width: 20 } // Add Total Sales header
];

const worksheetData = data.map(item => {
  const row = {
      asm_name: item?.asm_name ?? "",
      so_name: item?.so_name ?? "",
      sr_name: item?.sr_name ?? "",
      area: item?.area ?? "",
      shop_name: item.shop_name,
      mobile_no: item.mobile_no
  };
  let totalSales = 0;
  let allZero = true;
  let anyZero = false;
  let lastMonthPositive = false;
  let allPreviousZero = true;

  item.monthly_sales.forEach((sale, index) => {
      row[sale.month] = sale.total_sales;
      totalSales += sale.total_sales;
      
      if (sale.total_sales > 0) {
          allZero = false;
          if (index === item.monthly_sales.length - 1) {
              lastMonthPositive = true;
          } else {
              allPreviousZero = false;
          }
      } else {
          anyZero = true;
      }
  });

  row.total_sales = totalSales;

  // Determine status
  if (allZero) {
      row.status = "6M";
  } else if (lastMonthPositive && allPreviousZero) {
      row.status = `NC ${item.monthly_sales[5].month}`;
  } else if (!anyZero) {
      row.status = "Regular";
  } else {
      row.status = "Irregular";
  }

  return row;
});
// Create worksheet
const worksheet = XLSX.utils.json_to_sheet(worksheetData, { header: headers.map(h => h.key) });

// Set column widths
worksheet['!cols'] = headers.map(h => ({ wch: h.width }));

// Apply bold style to headers and add header row
const headerStyle = { font: { bold: true } };
const headerRow = headers.map(h => ({ v: h.header, t: 's', s: headerStyle }));
XLSX.utils.sheet_add_aoa(worksheet, [headerRow], { origin: 'A1' });

// Create workbook and append worksheet
const workbook = XLSX.utils.book_new();
XLSX.utils.book_append_sheet(workbook, worksheet, 'Eco_NonEco');

// Write to file
XLSX.writeFile(workbook, 'Eco_NonEco.xlsx');
setIsDownloading(false);
      };
      
  return (
    <div>
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      /> 
      {isDownloading ? <DownloadLoader /> : null}
      <Stack
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          padding: "1rem",
        }}
      >
        <Stack sx={{ flexDirection: "row", justifyContent: "space-between" }}>
          <Typography sx={{ fontSize: "20px", fontWeight: "600" }}>
            Monthly Downloads
          </Typography>
        </Stack>
        <Stack>
          {downloadName?.map((download) => (
            <Stack
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                background: "#FFFFFF",
                boxShadow: 3,
                width: "22rem",
                padding: "1rem",
                borderRadius: "0.6rem",
                justifyContent: "space-between",
              }}
            >
              <Typography>{download?.name}</Typography>
              <Stack
                sx={{ display: "flex", flexDirection: "row", gap: "0.5rem" }}
              >
                <Stack
                  sx={{
                    border: "1px solid #EF6C1A",
                    padding: "0.2rem",
                    borderRadius: "0.2rem",
                  }}
                >
                  <DownloadIcon
                    sx={{ color: "#EF6C1A", cursor: "pointer" }}
                    onClick={() => handleDayExcelDownload()}
                  />
                </Stack>
              </Stack>
            </Stack>
          ))}
        </Stack>
      </Stack>
    </div>
  );
}

export default MonthlyDownloads