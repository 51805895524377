import { useEffect } from "react";
import useAxiosPrivate from "./useAxiosPrivate";
import { useDispatch, useSelector } from "react-redux";
import { getRetailerList } from "../slice/retailerSlice";

const useRetailerList = () => {
  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();
  const currentzone = useSelector((state) => state.assignRoute.zone_id);

  const getAllRetailerList = async (status) => {
    if (currentzone) {
      const param = {
        zone_id: currentzone,
        status: status,
      };
      try {
        const response = await Axios.post("/retailer_list", param);
        const data = await response.data;
        dispatch(getRetailerList(data.body));
        // console.log(data);
      } catch (error) {
        console.error('Error fetching retailer list:', error);
      }
    }
  };
   
  // useEffect(() => {
  //   getAllRetailerList();
  // }, [currentzone]);
  return getAllRetailerList;
};

export default useRetailerList;