import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import {
  FormControl,
  Stack,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import useSkuImageUpload from "../../hooks/useSkuImageUpload";
import { ToastContainer, toast } from "react-toastify";
import useSkuList from "../../hooks/useSkuList";
import { getSkuList, resetSkuFormData } from "../../slice/skuSlice";
import useUpdateSku from "../../hooks/useUpdateSku";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import useSuperCategoryList from "../../hooks/useSuperCategoryList";
// import { getZoneList } from "../slice/appSlice";
// import useZoneList from "../hooks/useZoneList";
// import useStateList from "../hooks/useStateList";
// import useDistributorList from "../hooks/useDistributorList";
// import useAddDistributor from "../hooks/useAddDistributor";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function EditSku({ setOpen, open, skuEditId, skuRowData }) {

  // const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const [form, setForm] = React.useState({});
  const handleClose = () => {
    setOpen(false);
    dispatch(resetSkuFormData());
    setForm(skuRowData);
  };

  const dispatch = useDispatch();

  useEffect(() => {
    setForm((prev) => ({
      ...prev,
      sku_id: skuEditId,
    }));
  }, [skuEditId]);
  useEffect(() => {
    setForm(skuRowData);
    setForm((prev)=>({
      ...prev,
      status:skuRowData?.status === true ? "Active" : "Inactive", 
    }))
  }, [skuRowData]);
  const currentSelectedImage = useSelector((state) => state.sku.skuFormData);
  const categoryList = useSelector((store) => store.category.categoryList);
  const superCategoryList = useSelector(
    (store) => store.superCategory.superCategoryList
  );
  const getSkuImageUpload = useSkuImageUpload();
  const getAllSkuList = useSkuList();
  const statusList = ["Active", "Inactive"];
  const updateSkuDetails = useUpdateSku(form);
  useSuperCategoryList();

  const [error, setError] = React.useState({});

  const handleImageChange = (e) => {
    setForm((prev) => ({
      ...prev,
      [e.target.name]: e.target.files[0],
    }));

    // setDisable(true);
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    getSkuImageUpload(formData);

    if (e.target.files[0]) {
      setForm((prev) => ({
        ...prev,
        selectedImage: e.target.files[0].name,
      }));
    } else {
      setForm((prev) => ({
        ...prev,
        selectedImage: null,
      }));
    }
  };
  const handleChange = (e) => {
    setForm((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = async(e) => {
    e.preventDefault();

    await updateSkuDetails();
    await getAllSkuList();
    setOpen(false);
    setForm({});
  };

  const handleCategory = (e) => {
    const trimmedValue = e.target.value.trim();
    let categoryId = 0;
    let categoryName = null;
    for (let i = 0; i < categoryList?.length; i++) {
      if (trimmedValue === categoryList[i]?.category_name?.trim()) {
        categoryId = categoryList[i]?.category_id;
        categoryName = categoryList[i]?.category_name;
        break;
      }
    }

    setForm((prev) => ({
      ...prev,
      category_id: categoryId,
      category_name: categoryName
    }));
  };

  const handleSuperCategory = (e) => {
    const trimmedValue = e.target.value.trim();
    let scId = 0;
    let sc_name = null;
    for (let i = 0; i < superCategoryList?.length; i++) {
      if (trimmedValue === superCategoryList[i]?.sc_name.trim()) {
        scId = superCategoryList[i]?.sc_id;
        sc_name = superCategoryList[i]?.sc_name;
        break;
      }
    }

    setForm((prev) => ({
      ...prev,
      sc_id: scId,
      sc_name: sc_name,
      category_name: null,
      category_id: null,
    }));
  };

  // console.log(form, "sku_name_showRowData");

  return (
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div>
        <Modal
          open={open}
          // onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              ...style,
              width: "100%", 
              maxWidth: { xs: "300px", md: "600px", lg: "950px" },
              margin: "auto", 
              borderRadius: "10px",
              padding: "1.4rem 2.8rem 1.4rem 2.2rem",
            }}
          >
            <FormControl fullWidth size="small">
              <Stack sx={{ gap: "1rem" }}>
                <Stack
                  sx={{
                    flexDirection: { xs: "column", md: "row" }, // Stack in column for small screens, row for medium and above
                    gap: "5rem",
                  }}
                >
                  {/* Sku Name,Category,Image,SuperCategory */}
                  <Stack sx={{ gap: "1.5rem", flex: 1 }}>
                    <Stack sx={{ marginBottom: "1.5rem" }}>
                      <Typography sx={{ color: "#000000" }}>
                        Edit SKU
                      </Typography>
                    </Stack>
                    <Stack sx={{ gap: "0.4rem" }}>
                      <input
                        variant="outlined"
                        name="sku_name"
                        placeholder="Enter sku name*"
                        onChange={handleChange}
                        value={form?.sku_name}
                        style={{
                          height: "2.4rem",
                          fontSize: "0.9rem",
                          width: "96%",
                          paddingLeft: "0.7rem",
                        }}
                      />
                    </Stack>
                    {/* new build */}
                    <Stack sx={{ gap: "0.4rem" }}>
                      <select
                        style={{
                          height: "2.6rem",
                          fontSize: "0.9rem",
                          cursor: "pointer",
                          width: "100%",
                          paddingLeft: "0.5rem",
                        }}
                        name="super_category"
                        onChange={handleSuperCategory}
                      >
                        <option value="" disabled selected>
                          {form?.sc_name}
                        </option>
                        {superCategoryList?.map(
                          (item) =>
                            item.catalogue_status && (
                              <option key={item.sc_id}>{item.sc_name}</option>
                            )
                        )}
                      </select>
                      {error.super_category && (
                        <p
                          style={{
                            color: "red",
                            fontSize: "0.8rem",
                            margin: "0",
                          }}
                        >
                          {error.super_category}
                        </p>
                      )}
                    </Stack>
                    <Stack sx={{ gap: "0.4rem" }}>
                      <select
                        style={{
                          height: "2.6rem",
                          fontSize: "0.9rem",
                          cursor: "pointer",
                          width: "100%",
                          paddingLeft: "0.5rem",
                        }}
                        name="category"

                        onChange={handleCategory}
                      >
                        <option value="" disabled selected>
                          {form?.category_name}
                        </option>
                        {categoryList?.filter(
                            (item) =>
                              item?.sc_id === form?.sc_id && item?.status
                          )
                          .map((item) => (
                              <option key={item.id}>
                                {item.category_name}
                              </option>
                            )
                        )}
                      </select>
                      {error.category && (
                        <p
                          style={{
                            color: "red",
                            fontSize: "0.8rem",
                            margin: "0",
                          }}
                        >
                          {error.category}
                        </p>
                      )}
                    </Stack>
                    <Stack
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "0.4rem",
                      }}
                    >
                      <label htmlFor="sku_image" style={{ cursor: "pointer" }}>
                        <input
                          id="sku_image"
                          type="file"
                          size="small"
                          accept="image/*"
                          name="sku_image"
                          style={{
                            display: "none",
                          }}
                          onChange={handleImageChange}
                        />

                        <div
                          style={{
                            height: "180px",
                            fontSize: "0.9rem",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            cursor: "pointer",
                            background: "#F2F2F2",
                          }}
                        >
                          {currentSelectedImage ? (
                            <img
                              style={{
                                height: "80%",
                                width: "80%",
                                objectFit: "contain",
                              }}
                              src={currentSelectedImage.full_path}
                              alt="super_category_image"
                            />
                          ) : (
                            <img
                              style={{
                                height: "80%",
                                width: "80%",
                                objectFit: "contain",
                              }}
                              src={form?.sku_image}
                              alt="sku_image"
                            />
                            // <>
                            //   {form?.selectedImage ? (
                            //     form?.selectedImage
                            //   ) : (
                            //     <Stack
                            //       sx={{
                            //         display: "flex",
                            //         flexDirection: "column",
                            //         alignItems: "center",
                            //         justifyContent: "center",
                            //         gap: "0.5rem",
                            //       }}
                            //     >
                            //       <Stack
                            //         sx={{
                            //           background: "#FFFFFF",
                            //           padding: "0.8rem",
                            //           borderRadius: "50%",
                            //         }}
                            //       >
                            //         <PhotoCameraOutlinedIcon />
                            //       </Stack>
                            //       <Typography>Sku Image</Typography>
                            //     </Stack>
                            //   )}
                            // </>
                          )}
                        </div>
                      </label>
                      {error.selectedImage && (
                        <p
                          style={{
                            color: "red",
                            fontSize: "0.8rem",
                            margin: "0",
                          }}
                        >
                          {error.selectedImage}
                        </p>
                      )}
                    </Stack>
                  </Stack>

                  {/* Close Icon */}
                  <Stack
                    sx={{ position: "absolute", right: 0, cursor: "pointer" }}
                  >
                    <CloseOutlinedIcon onClick={handleClose} />
                  </Stack>

                  {/* Piece, Ladi && Carton */}
                  <Stack
                    sx={{
                      gap: { xs: "1rem", md: "1.2rem" },
                      flex: "1", // Take remaining space in medium and above screens
                      marginTop: "2.5rem",
                    }}
                  >
                    <Stack
                      sx={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        gap: "1rem",
                      }}
                    >
                      <Stack sx={{ gap: "0.8rem" }}>
                        <label>Price per Piece*</label>
                        <input
                          variant="outlined"
                          name="price_per_piece"
                          type="number"
                          placeholder="Enter Sale Price"
                          value={form?.price_per_piece}
                          onChange={handleChange}
                          style={{
                            height: "2.4rem",
                            fontSize: "0.9rem",
                            width: "100%",
                            paddingLeft: "0.7rem",
                          }}
                        />
                        {error.price_per_piece && (
                          <p
                            style={{
                              color: "red",
                              fontSize: "0.8rem",
                              margin: "0",
                            }}
                          >
                            {error.price_per_piece}
                          </p>
                        )}
                      </Stack>
                    </Stack>
                    {form?.sc_name === "Wafers" ? 
                    <Stack
                      sx={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        gap: "1rem",
                      }}
                    >
                    
                      <Stack sx={{ gap: "0.8rem" }}>
                        <label>Ladi*</label>
                        <input
                          variant="outlined"
                          name="ladi"
                          type="number"
                          placeholder="No of Pieces"
                          value={form?.ladi}
                          onChange={handleChange}
                          style={{
                            height: "2.4rem",

                            fontSize: "0.9rem",
                            width: "100%",
                            paddingLeft: "0.7rem",
                          }}
                        />
                        {error.ladi && (
                          <p
                            style={{
                              color: "red",
                              fontSize: "0.8rem",
                              margin: "0",
                            }}
                          >
                            {error.ladi}
                          </p>
                        )}
                      </Stack>
                      

                      <Stack sx={{ gap: "0.8rem", alignSelf: "self-end" }}>
                        <input
                          variant="outlined"
                          name="price_per_ladi"
                          type="number"
                          placeholder="Enter Sale Price"
                          value={form?.price_per_ladi}
                          onChange={handleChange}
                          style={{
                            height: "2.4rem",

                            fontSize: "0.9rem",
                            width: "100%",
                            paddingLeft: "0.7rem",
                          }}
                        />
                        {error.price_per_ladi && (
                          <p
                            style={{
                              color: "red",
                              fontSize: "0.8rem",
                              margin: "0",
                            }}
                          >
                            {error.price_per_ladi}
                          </p>
                        )}
                      </Stack>
                    </Stack>
                     : null
                      }
                    <Stack
                      sx={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        gap: "1rem",
                      }}
                    >
                      <Stack sx={{ gap: "0.8rem" }}>
                        <label>Carton*</label>
                        <input
                          variant="outlined"
                          name="carton"
                          type="number"
                          placeholder="No. of Ladi"
                          value={form?.carton}
                          onChange={handleChange}
                          style={{
                            height: "2.4rem",
                            paddingLeft: "0.5rem",
                            fontSize: "0.9rem",
                            width: "100%",
                            paddingLeft: "0.7rem",
                          }}
                        />
                        {error.carton && (
                          <p
                            style={{
                              color: "red",
                              fontSize: "0.8rem",
                              margin: "0",
                            }}
                          >
                            {error.carton}
                          </p>
                        )}
                      </Stack>

                      <Stack sx={{ gap: "0.4rem", alignSelf: "self-end" }}>
                        <input
                          variant="outlined"
                          name="price_per_carton"
                          type="number"
                          placeholder="Enter Sale Price"
                          value={form?.price_per_carton}
                          onChange={handleChange}
                          style={{
                            height: "2.4rem",
                            paddingLeft: "0.5rem",
                            fontSize: "0.9rem",
                            width: "100%",
                            paddingLeft: "0.7rem",
                          }}
                        />
                        {error.price_per_carton && (
                          <p
                            style={{
                              color: "red",
                              fontSize: "0.8rem",
                              margin: "0",
                            }}
                          >
                            {error.price_per_carton}
                          </p>
                        )}
                      </Stack>
                    </Stack>
                    <Stack
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "0.4rem",
                      }}
                    >
                      <label>Status</label>
                      <select
                        style={{
                          height: "2.4rem",
                          paddingLeft: "0.5rem",
                          fontSize: "0.9rem",
                          width: "100%",
                          paddingLeft: "0.7rem",
                        }}
                        name="status"
                        onChange={handleChange}
                      >
                        <option value="" disabled selected>
                          {form?.status}
                        </option>
                        {statusList.map((item, index) => (
                          <option key={index}>{item}</option>
                        ))}
                      </select>
                      {error.category && (
                        <p
                          style={{
                            color: "red",
                            justifyContent: "center",
                            display: "flex",
                            fontSize: "0.8rem",
                            marginTop: "-0.1rem",
                          }}
                        >
                          {error.category}
                        </p>
                      )}
                    </Stack>
                  </Stack>
                </Stack>

                {/* Button */}
                <Stack
                  sx={{
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    gap: "0.4rem",
                  }}
                >
                  <Button
                    sx={{
                      background: "white",
                      color: "#FF5A5A",
                      border: "2px solid #FF5A5A",
                      height: "1.8rem",
                    }}
                    onClick={handleClose}
                  >
                    Discard
                  </Button>
                  <Button
                    sx={{
                      background: "#EF6C1A",
                      color: "white",
                      height: "1.8rem",
                      ":hover": { background: "#EF6C1A" },
                    }}
                    onClick={handleSubmit}
                    // disabled={disable}
                  >
                    Submit
                  </Button>
                </Stack>
              </Stack>
            </FormControl>
          </Box>
        </Modal>
      </div>
    </>
  );
}
