import React, { useEffect, useState } from "react";
import { TextField, MenuItem } from "@material-ui/core";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { Stack, Typography } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import dayjs from "dayjs";

const Dropdown = ({ setSelectedData }) => {

  const months = [
    { value: "January", label: "January", index: 1 },
    { value: "February", label: "February", index: 2 },
    { value: "March", label: "March", index: 3 },
    { value: "April", label: "April", index: 4 },
    { value: "May", label: "May", index: 5 },
    { value: "June", label: "June", index: 6 },
    { value: "July", label: "July", index: 7 },
    { value: "August", label: "August", index: 8 },
    { value: "September", label: "September", index: 9 },
    { value: "October", label: "October", index: 10 },
    { value: "November", label: "November", index: 11 },
    { value: "December", label: "December", index: 12 },
  ];
  const currentMonthIndex = new Date().getMonth() + 1;

  const currentMonth = months.find(
    (month) => month.index === currentMonthIndex
  );
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const [toSelectedDate, setToSelectedDate] = useState(dayjs());
  const [fromSelectedDate, setFromSelectedDate] = useState(dayjs());

  const [formData, setFormData] = useState({
    month: currentMonth.value,
    month_no: currentMonth.index,
  });
  useEffect(() => {
    const year = selectedDate.year();

    const month = selectedDate.month() + 1;
    const currentDate = new Date();

    setFormData((prevData) => ({
      ...prevData,
      year: year,
      month_no: month,

      to: currentDate,
      from: currentDate,

    }));
    setSelectedData((prevData) => ({
      ...prevData,
      year: year,
      month_no: month,

      to: currentDate,
      from: currentDate,
    }));
  }, []);
  const handleDateChange = (name, date) => {
    console.log(name);
    const year = date.year();
    const month = date.month() + 1;


    setFormData((prevData) => ({
      ...prevData,
      year: year,
      month_no: month,

      [name]: date,
    }));

    setSelectedData((prevData) => ({
      ...prevData,
      year: year,
      month_no: month,
      [name]: date,
    }));

    if (name === "to") {
      setToSelectedDate(date);
    } else if (name === "from") {
      setFromSelectedDate(date);
    }
  };
  const minDate = dayjs("2024-06-01");


  return (
    <Stack
      sx={{
        display: "flex",
        flexDirection: "row",
        width: "100%",
        justifyContent: "space-between",
      }}
    >
      <Typography sx={{ fontSize: "20px", fontWeight: "600" }}>
        Range Downloads
      </Typography>
      {/* <TextField
        select
        name="year"
        size="small"
        value={selectedMonth}
        onChange={handleChange}
        variant="outlined"
        style={{ minWidth: "130px", marginRight: "10px" }}
      >
        {months.map((option) => (
          <MenuItem key={option.id} value={option.name}>
            {option.name}
          </MenuItem>
        ))}
      </TextField> */}


      <div style={{ display: "flex", flexDirection: "row", gap: "1rem" }}>
        <div style={{ backgroundColor: "#FFF" }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              // views={["year", "month"]}
              value={fromSelectedDate}
              slotProps={{ textField: { size: "small" } }}
              sx={{ backgroundColor: "#FFF" }}
              onChange={(date) => handleDateChange("from", date)}
              minDate={minDate}
            />
          </LocalizationProvider>
        </div>

        <div style={{ backgroundColor: "#FFF" }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              // views={["year", "month"]}
              value={toSelectedDate}
              slotProps={{ textField: { size: "small" } }}
              sx={{ backgroundColor: "#FFF" }}
              onChange={(date) => handleDateChange("to", date)}
              minDate={minDate}
            />
          </LocalizationProvider>
        </div>

      </div>

      {/* <button style={{ background: "#fff", border: "2px solid #EF6C1A" }}>
        <Stack
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            gap: "0.4rem",
          }}
        >
          <UploadFileIcon sx={{ color: "#EF6C1A" }} />{" "}
          <Typography sx={{ color: "#EF6C1A", fontWeight: "700" }}>
            Upload Template
          </Typography>
        </Stack>
      </button> */}
    </Stack>
  );
};

export default Dropdown;
