import React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import Switch from '@mui/material/Switch';
import Button from '@mui/material/Button';
import styles from '../styles/RouteMgtTable.module.css'
import {useSelector,useDispatch} from 'react-redux';
// import {VILLAGEDATA} from '../../../../constants/RouteMgt/Route'


const RedButton = styled(Button)(({ theme }) => ({
    color: "#731D3A",
    backgroundColor: "white",
    '&:hover': {
        backgroundColor: "#731D3A",
        color: "white",
        border: "2px solid #731D3A"
    },
    border: "2px solid #731D3A"
}));

const useStyles = makeStyles({
    head: {
        borderBottom: "2px solid black"
    }
})
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#E5E5E5",
        color: "black",
        fontSize: "14px",
        fontWeight: "600"
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const VillageTable = () => {
    const dispatch = useDispatch();
    const route = useSelector(state => state.RouteMgt);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const columns = ["Zone", "Village Name", "Route Code","Population","Updated At", "Action"];

    const data = [
        {"zone_name":"Angga","village_name":"sas","route_code":"wdawd","update_at":"2676 7"}
    ]

    let Id = 0;

    
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };


const handleDetails=(row)=>{
//    dispatch({type:VILLAGEDATA,payload:row})
}
 

    return (
        <div className={styles.VillageTableContainer}>
        <Paper>
             <TableContainer sx={{ maxHeight: 440, minWidth: "100%" }}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead className={styles.head} sx={{ backgroundColor: "#c4c4c4" }}>
                        <TableRow className={styles.head} sx={{ backgroundColor: "#c4c4c4" }}>
                            {columns.map((column, index) => (
                                <StyledTableCell
                                    key={index}
                                    align="center"
                                // style={{ minWidth: column.minWidth }}
                                >
                                    {column}
                                </StyledTableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {route.villagelist && route.villagelist.length !== 0 ?
                            (
                                <>{
                                    route.villagelist.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((row, index) => (

                                            <TableRow hover role="checkbox" key={index} sx={row.village_id === route.village.village_id ? ({ backgroundColor: "#faebf0" }) : ({ backgroundColor: "white" })}>

                                                <TableCell align="center">
                                                    {row.zone_name}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {row.village_name}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {row.route_code}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {row.population}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {row.updated_at}
                                                </TableCell>
                                               
                                                <TableCell align="center">
                                                    <RedButton variant="outlined" onClick={()=>handleDetails(row)}>Details</RedButton>
                                                </TableCell>
                                            </TableRow>
                                        )
                                        )
                                }
                                </>
                            ) : (
                                <TableRow>
                                    <TableCell>
                                        No Data
                                    </TableCell>
                                </TableRow>
                            )
                        }

                    </TableBody>
                </Table>
            </TableContainer>
            <TableRow>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={route.villagelist && route.villagelist.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
                <TableCell align="center">

                    {/* <Button style={{ backgroundColor: "#00b300", color: "white" }} onClick={handleDownload}>Export</Button> */}
                </TableCell>
            </TableRow>
</Paper>
        </div>
    )
}

export default VillageTable
