import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Button, Divider, Stack } from "@mui/material";

import CategoryManagementTable from "./CategoryManagement/CategoryManagementTable";
import SKUManagementTable from "./SKU Management/SKUManagementTable";
import AddCategory from "./CategoryManagement/AddCategory";
import AddSku from "./SKU Management/AddSku";
import SuperCategoryManagementTable from "./SuperCategoryManagement/SuperCategoryManagementTable";
import AddSuperCategory from "./SuperCategoryManagement/AddSuperCategory";
import DownloadIcon from "@mui/icons-material/Download";
import useSkuListExcelDownload from "../hooks/useSkuListExcelDownload";
import * as XLSX from "xlsx";
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography >{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs({setDisplay}) {
  const [value, setValue] = React.useState(0);
  const [currentId, setCurrentId] = React.useState(null);
  const [isDownloading, setIsDownloading] = React.useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getAllSkuListExcelDownload = useSkuListExcelDownload();

  const handleDownload= async()=>{
   
      setIsDownloading(true);
      // setCurrentId(id);

      // Define the headers for the new Excel sheet
      const headers = [
        "Sr No.",
        "Category",
        "SKU",
        "Price/PC",
        "No of Pieces in Ladi",
        "No of Pieces in Carton",
        "Ladi Price",
        "No of Ladi in Carton",
        "Carton Price",
      ];

      // Fetch the data from the database
      const data = await getAllSkuListExcelDownload(); // Replace this with your actual data fetching logic

      // Map the database fields to the new headers
      const formattedData = data.map((item, index) => ({
        "Sr No.": index + 1,
        Category: item.category_name,
        SKU: item.sku_name,
        "Price/PC": item.price_per_piece,
        "No of Pieces in Ladi": item?.no_of_pcs_in_ladi,
        "No of Pieces in Carton": item?.no_of_piece_in_carton,
        "Ladi Price": item?.price_per_ladi,
        "No of Ladi in Carton": item?.no_of_ladi_in_carton,
        "Carton Price": item?.price_per_carton,
      }));

      const wb = XLSX.utils.book_new();
      const wsData = [
        headers,
        ...formattedData.map((item) => headers.map((header) => item[header])),
      ];

      const ws = XLSX.utils.aoa_to_sheet(wsData);

      // Define column widths
      const colWidths = headers.map((header, index) => {
        if (header === "Sr No.") return { wch: 10 };
        if (header === "Category" || header === "Product" || header === "SKU")
          return { wch: 20 };
        if (
          header === "Price/PC" ||
          header === "Ladi Price" ||
          header === "Carton Price"
        )
          return { wch: 10 };
        return { wch: 15 };
      });

      ws["!cols"] = colWidths;

      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
      XLSX.writeFile(wb, "Product Catalogue.xlsx");
      setIsDownloading(false);
    
  }

  return (
    <Box
      sx={{
        width: "100%",
        textAlign: "left",
        backgroundColor: "#fff",
        borderRadius: "6px",
        margin: "6px 4px 4px 0px",
        padding: "4px",
        overflowX: "scroll",
        "&::-webkit-scrollbar": {
          display: "none", // Hide the scrollbar in WebKit browsers (Chrome, Safari, etc.)
        },
      }}
    >
      <Typography variant="h6" marginTop="10px" padding="0px 15px">
        Catalogue Management
      </Typography>
      <Box
        sx={{
          // borderBottom: 1,
          // borderColor: "divider",
          justifyContent: "space-between",
          display: "flex",
          width: "98%",
          ".MuiTabs-indicator": {
            backgroundColor: "#EF6C1A",
          },
          ".Mui-selected": {
            color: "#EF6C1A !important",
          },
          
        }}
      >
        <Stack width="100%" flexDirection="row" gap={2}>
          <Stack>
            <Tabs
              sx={{
                ".MuiTabs-flexContainer": {
                  gap: "10px",
                },
              }}
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab
                sx={{
                  color: "#C2C2C2 !important",
                  fontWeight: "500",
                  textTransform: "none",
                  borderBottom: "3px solid #C2C2C2",
                }}
                label="Super Category Management"
                {...a11yProps(0)}
              />
              <Tab
                sx={{
                  color: "#C2C2C2 !important",
                  fontWeight: "500",
                  textTransform: "none",
                  borderBottom: "3px solid #C2C2C2",
                }}
                label="Category Management"
                {...a11yProps(1)}
              />
              <Tab
                sx={{
                  color: "#C2C2C2 !important",
                  fontWeight: "500",
                  textTransform: "none",
                  borderBottom: "3px solid #C2C2C2",
                }}
                label="Sku Management"
                {...a11yProps(2)}
              />
            </Tabs>
          </Stack>
          <Divider
            orientation="vertical"
            flexItem
            sx={{ borderRight: "1px solid #B7B7B7", margin: "0 1px" }}
          />
          <Stack sx={{ alignSelf: "center",display:"flex",flexDirection:"row", gap:"1rem",alignItems:"center" }}>
            <Button
              sx={{
                color: "#EF6C1A !important",
                backgroundColor: "white !important",
                "&:hover": {
                  backgroundColor: "#EF6C1A !important",
                  color: "white !important",
                  border: "2px solid #EF6C1A !important",
                },
                border: "2px solid #EF6C1A !important",
                padding: "18px 18px !important",
                height: "30px !important",
                fontSize: "14px !important",
                borderRadius: "4px !important",
                textTransform: "none",
              }}
              onClick={()=>{setDisplay("inventory")}}
            >
              Inventory
            </Button>
            <Stack
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            background: "#FFFFFF",
            boxShadow: 3,
            height:"0.6rem",
            width: "12rem",
            padding: "1rem",
            borderRadius: "0.6rem",
            justifyContent: "space-between",
          }}
        >
          <Typography>Product Catalogue</Typography>
          <Stack sx={{ display: "flex", flexDirection: "row", gap: "0.5rem" }}>
            {/* <Stack
              sx={{
                border: "1px solid #EF6C1A",
                padding: "0.2rem",
                borderRadius: "0.2rem",
              }}
            >
              <DeleteIcon sx={{ color: "#EF6C1A" }} />
            </Stack> */}
            <Stack
              sx={{
                border: "1px solid #EF6C1A",
                padding: "0.2rem",
                borderRadius: "0.2rem",
              }}
            >
              <DownloadIcon
                sx={{ color: "#EF6C1A", cursor: "pointer" }}
                onClick={() => handleDownload()}
              />
            </Stack>
          </Stack>
        </Stack>
          </Stack>
        </Stack>

        {value === 0 ? (
          <Stack>
            <AddSuperCategory />
          </Stack>
        ) : value === 1 ? (
          <Stack>
            <AddCategory />
          </Stack>
        ) : (
          <Stack>
            <AddSku />
          </Stack>
        )}
      </Box>
      <CustomTabPanel value={value} index={0}>
        <SuperCategoryManagementTable />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <CategoryManagementTable />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <SKUManagementTable />
      </CustomTabPanel>
    </Box>
  );
}
