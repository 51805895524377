import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Typography, Button } from '@mui/material';

const InstructionsPopup = ({ open, handleClose }) => {
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle><b>Excel Upload Validation Guidelines</b></DialogTitle>
      <DialogContent>
        <Typography variant="body1" gutterBottom>
        1) Ensure that the Employee Area Name matches the one added on the web for the zone otherwise upload time will give an error message.
        </Typography>
        <Typography variant="body1" gutterBottom>
        2) Make sure the Beat name is correct. If the Beat name is new it will get added or if already added from the web, it will not be added, but the Retailer for the Beat Name will be added/updated.
        </Typography>
        <Typography variant="body1" gutterBottom>
        3) Retailer ID - if you are uploading a new beat and retailer, please keep this column blank. However, if you have downloaded a dump and are uploading it, the Retailer ID will be required at that time.
        </Typography>
        <Typography variant="body1" gutterBottom>
        4) Mobile no - Make sure mobile no. is correct, unique and 10 digits for each retailer.
        </Typography>
        <Typography variant="body1" gutterBottom>
        5) Make sure the Retailer Shop Name,  Category Name,  Contact Person,  Address,  Latitude ,  Longitude and  Pincode fields cannot be left blank.
        </Typography>
        <Typography variant="body1" gutterBottom>
        6) Do not modify the retailer ID column in the downloaded Excel sheet dump.
        </Typography>
        <Typography variant="body1" gutterBottom>
        7) The status of any retailer must be either 'active' or 'inactive'
        </Typography>
        <Typography variant="body1" gutterBottom>
        8) Ensure that the Distributor Firm Name matches the one added on the web for the zone otherwise new Distributor will get created with the same name.
        </Typography>
        {/* <Typography variant="body1" gutterBottom>
          10) The status of any retailer must be either 'active' or 'inactive'.
        </Typography> */}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default InstructionsPopup;
