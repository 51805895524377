import useAxiosPrivate from "../useAxiosPrivate";

const useAttendanceReport = (selectedData, zoneData) => {
  console.log(selectedData,"getAttendanceReport")

 
  const Axios = useAxiosPrivate();

  const getAttendanceReport = async () => {
    let param = {
      zone_id: zoneData.zone_id,
      start: selectedData?.from,
      end: selectedData?.to,
    };
    const response = await Axios.post("attendance-report-sr-so-asm", param);
    const data = await response.data;
    console.log(data,"getAttendanceReport")
    return data.user_list;
  };
  return getAttendanceReport;
};

export default useAttendanceReport;
