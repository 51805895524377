
import useAxiosPrivate from "./useAxiosPrivate";


const useDeployRoute = () => {
  const Axios = useAxiosPrivate();


  const setDeployRoute = async (param) => {
    try {
      const response = await Axios.post("deploy_route", param);
      console.log(response);
      const data = response.data; 
      return data;  
    } catch (error) {
      console.error("Error fetching admin map data:", error);
      throw error;  
    }
  };
  return setDeployRoute;
};

export default  useDeployRoute;