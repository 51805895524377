import { useSelector } from "react-redux";
import useAxiosPrivate from "./useAxiosPrivate";
import { toast } from "react-toastify";

const useDeletedScheme = () => {
  const Axios = useAxiosPrivate();

  const DeletedScheme = async (param) => {
    try {
      const response = await Axios.post("delete_scheme", param);
      const data = response.data;
      if (data.code === 200) {
        toast.success("Deleted SuccessFully");
      } else {
        toast.error("Update Failed");
      } 
      return data;  
    } catch (error) {
      console.error("Error fetching admin map data:", error);
      throw error;  
    }
  };
  return DeletedScheme;
};

export default  useDeletedScheme;