import { createSlice } from "@reduxjs/toolkit";
const smSlice = createSlice({
  name: "SM",
  initialState: {
    SMList: null,
  },
  reducers: {
    getSMList: (state, action) => {
      state.SMList = action.payload;
    },
  },
});

export default smSlice.reducer;
export const { getSMList } = smSlice.actions;
