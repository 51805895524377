
import useAxiosPrivate from "./useAxiosPrivate";
import { useDispatch } from "react-redux";
import { SEinShapeFile, getAssignedUserData } from "../slice/adminMapSlice";

const useAssgnedUserData = () => {
  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();

  const getUserData = async (param2) => {
    
    try {
      const response = await Axios.post("assigned_users_data", param2); 
      
      const data = response.data;
      dispatch(getAssignedUserData(data.body));
      
      
      return data;  
    } catch (error) {
      console.error("Error fetching admin map data:", error);
      throw error;  
    }
  };
  return getUserData;
};

export default useAssgnedUserData;