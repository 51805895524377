import { useEffect } from "react";
import useAxiosPrivate from "./useAxiosPrivate";
import { useDispatch, useSelector } from "react-redux";
import { getDistributorList } from "../slice/distributorSlice";

const useDistributorList = () => {
  const currentzone = useSelector((state) => state.assignRoute.zone_id);
  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();
  const getAllDistributorList = async (status) => {
    if (currentzone) {
      const param = {
        zone_id: currentzone,
        status: status ?? true
      };
    const response = await Axios.post("/distributor_list", param);
    const data = await response.data;
    dispatch(getDistributorList(data.body));
    }
  };
  // useEffect(() => {
  //   getAllDistributorList();
  // }, []);

  return getAllDistributorList;
};

export default useDistributorList;
