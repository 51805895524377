import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import {
  FormControl,
  Select,
  MenuItem,
  TextField,
  InputLabel,
  Stack,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getZoneList } from "../slice/appSlice";
import useZoneList from "../hooks/useZoneList";
import useStateList from "../hooks/useStateList";
import useDistributorList from "../hooks/useDistributorList";
import useAddDistributor from "../hooks/useAddDistributor";
import useSuperCategoryList from "../hooks/useSuperCategoryList";
import useAddUser from "../hooks/useAddUser";
import useSRList from "../hooks/useSRList";
import useSMList from "../hooks/useSMList";
import useSOList from "../hooks/useSOList";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  borderRadius: "6px",
  boxShadow: 24,
  p: 4,
};

export default function UserModal(type) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const dispatch = useDispatch();
  const getDropDownData = useSelector((state) => state.app.curretDropdownData);
  const [form, setForm] = React.useState({
    zone_id: getDropDownData.zone_id,
  });
  
  useEffect(() => {
    setForm((prevFormData) => ({
      ...prevFormData,
      zone_id: getDropDownData.zone_id,
    }));
  }, [getDropDownData, open]);

  const zoneList = useSelector((store) => store.app.zoneList);
  const stateList = useSelector((store) => store.app.stateList);
  const superCategoryList = useSelector(
    (store) => store.superCategory.superCategoryList
  );
  const getZoneList = useZoneList();
  // useStateList();
  useSuperCategoryList();
  const addUser = useAddUser(form,type);

  const handleZoneChange = (e) => {
    let zoneId = 0;
    let zoneName = "";
    for (let i = 0; i < zoneList?.length; i++) {
      if (e.target.textContent === zoneList[i].zone_name) {
        zoneId = zoneList[i].zone_id;
        zoneName = zoneList[i].zone_name;
      }
    }

    setForm((prev) => ({
      ...prev,
      zone_name: zoneName,
      zone_id: zoneId,
    }));
  };

  const handleStateChange = (e) => {
    let stateId = "";
    let stateName = "";
    for (let i = 0; i < stateList?.length; i++) {
      if (e.target.textContent === stateList[i].state_name) {
        stateId = stateList[i].state_id;
        stateName = stateList[i].state_name;
      }
    }
    setForm((prev) => ({
      ...prev,
      state_name: stateName,
      state_id: stateId,
    }));
    let param = { state_id: stateId };
    getZoneList(param);
  };

  const handleChange = (e) => {
    setForm((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const [error, setError] = React.useState({});
  const isValid = () => {
    let success = true;
    let err = {};

    // if (
    //   !form ||
    //   form.state_id === 0 ||
    //   form.state_id === null ||
    //   form.state_id === undefined ||
    //   !form.state_id
    // ) {
    //   err.state = "Please Select State";
    //   success = false;
    // }

    if (
      !form ||
      form.zone_id === 0 ||
      form.zone_id === null ||
      form.zone_id === undefined ||
      !form.zone_id
    ) {
      err.zone = "Please Select Zone";
      success = false;
    }

    if (
      !form ||
      form.sc_id === 0 ||
      form.sc_id === null ||
      form.sc_id === undefined ||
      !form.sc_id
    ) {
      err.super_category = "Please Select Super Category";
      success = false;
    }

    if (!form || !form?.first_name?.trim()) {
      err.dist = "Please Enter Name";
      success = false;
    }
    
    // if (!form || !form?.last_name?.trim()) {
    //   err.dist_lastName = "Please Enter Last name";
    //   success = false;
    // }

    if (!form || !form?.mobile || form?.mobile?.trim().length !== 10) {
      err.mobile = "Please enter a 10-digit mobile number";
      success = false;
    }
  
    if(type.type === 3){
    if (
      !form ||
      !form.email ||
      !form.email.includes('@') ||
      form.email.indexOf('@') === 0 ||
      form.email.indexOf('@') !== form.email.lastIndexOf('@') ||
      form.email.lastIndexOf('.') <= form.email.indexOf('@') + 1 ||
      form.email.lastIndexOf('.') === form.email.length - 1
    ) {
      err.email = "Please enter a valid email";
      success = false;
    }
    }
    setError(err);
    return success;
  };

  console.log(error);

  const getSRList=useSRList()
  const getSMList=useSMList()
  const getSOList=useSOList()
  const handleSubmit = async (e) => {

    if (isValid()) {
      await addUser();
      await getSMList()
      await getSOList()
      await getSRList()
      setOpen(false);
      setForm({});
    }
  };

  const handleDiscard = (e) => {
    setOpen(false);
    setForm({});
    setError({});
  };

  const handleSuperCategory = (e) => {
    let scId = 0;
    let scIdArray=[]
    for (let i = 0; i < superCategoryList?.length; i++) {
      if (e.target.value === superCategoryList[i]?.sc_name) {
        scId = superCategoryList[i]?.sc_id;
        scIdArray.push(scId)
        break;
      }
    }

    setForm((prev) => ({
      ...prev,
      sc_id: scId,
      scIdArray: scIdArray
    }));
  };

  return (
    <div>
      <Button onClick={handleOpen} sx={{ height: "0.1rem", color: "white" }}>
        Add
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {/* <Typography id="modal-modal-title" variant="h6" component="h2">
            Text in a modal
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
          </Typography> */}

          <FormControl
            fullWidth
            size="small"
            sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}
          >
            <Stack
              sx={{ display: "flex", flexDirection: "column", gap: "0.4rem" }}
            >
              <label htmlFor="zone_id">Zone Name: </label>
              <TextField
                size="small"
                type="text"
                id="zone_id"
                name="zone_id"
                value={getDropDownData?.zone_name}
                fullWidth
                disabled
              />
            </Stack>

            <Stack
              sx={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}
            >
              <label>Super Category</label>
              <Select
                onChange={handleSuperCategory}
                label="Super Category"
                sx={{ height: "1.8rem" }}
              >
                {superCategoryList?.map((item, index) => (
                  <MenuItem
                    sx={{
                      display: "flex !important",
                      flexDirection: "column !important",
                      gap: "5px !important",
                      padding: "4px !important",
                      textAlign: "start",
                    }}
                    value={item.sc_name}
                    key={item.sc_id}
                  >
                    {item.sc_name}
                  </MenuItem>
                ))}
              </Select>

              {error.super_category && (
                <label
                  style={{
                    fontSize: "12px",
                    color: "red",
                    fontWeight: 500,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  {"**" + error.super_category + "**"}
                </label>
              )}
            </Stack>
            <Stack
              sx={{ display: "flex", flexDirection: "column", gap: "0.4rem" }}
            >
              <label>Name</label>
              <input
                variant="outlined"
                // size="small"
                name="first_name"
                onChange={handleChange}
                style={{
                  height: "1.8rem",
                  border: "1px solid rgba(0, 0, 0, 0.3)",
                  borderRadius:"4px"
                }}
              />
              {error.dist && (
                <label
                  style={{
                    fontSize: "12px",
                    color: "red",
                    fontWeight: 500,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  {"**" + error.dist + "**"}
                </label>
              )}
            </Stack>
            <Stack
              sx={{ display: "flex", flexDirection: "column", gap: "0.4rem" }}
            >
              <label>Mobile Number</label>
              <input
                type="tel"
                variant="outlined"
                size="small"
                name="mobile"
                maxLength="10"
                pattern="[0-9]{10}"
                onChange={handleChange}
                style={{
                  height: "1.8rem",
                  border: "1px solid rgba(0, 0, 0, 0.3)",
                  borderRadius:"4px"
                }}
              />
              {error.mobile && (
                <label
                  style={{
                    fontSize: "12px",
                    color: "red",
                    fontWeight: 500,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  {"**" + error.mobile + "**"}
                </label>
              )}
            </Stack>
            {type.type === 3 ? 
            <Stack
              sx={{ display: "flex", flexDirection: "column", gap: "0.4rem" }}
            >
              <label>Email</label>
              <input
                type="email"
                variant="outlined"
                size="small"
                name="email"
                onChange={handleChange}
                style={{
                  height: "1.8rem",
                  border: "1px solid rgba(0, 0, 0, 0.3)",
                  borderRadius:"4px"
                }}
              />
              {error.email && (
                <label
                  style={{
                    fontSize: "12px",
                    color: "red",
                    fontWeight: 500,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  {"**" + error.email + "**"}
                </label>
              )}
            </Stack>
            : null}
            <Stack
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "flex-end",
              }}
            >
              <Stack
                sx={{ display: "flex", flexDirection: "row", gap: "0.5rem" }}
              >
                <Button
                  sx={{
                    background: "#fff",
                    color: "red",
                    height: "1.8rem",
                    border: "2px solid red",
                    width: "max-content",
                  }}
                  onClick={handleDiscard}
                >
                  Discard
                </Button>

                <Button
                  sx={{
                    background: "#EF6C1A",
                    "&:hover": { background: "#f76a11" },
                    color: "white",
                    height: "1.8rem",
                    border: "1px solid rgba(0, 0, 0, 0.2)",
                    width: "max-content",
                  }}
                  onClick={handleSubmit}
                >
                  Submit
                </Button>
              </Stack>
            </Stack>
          </FormControl>
        </Box>
      </Modal>
    </div>
  );
}
