import React, { useState, useEffect } from "react";
import styles from "./styles/midContainerUser.module.css";
import Button from "@mui/material/Button";
import { withStyles } from "@mui/styles";
import { styled } from "@mui/material/styles";
import { red, purple, white } from "@mui/material/colors";
import { style } from "@mui/system";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import UserMgtTable from "./UserMgtTable";
import { HashLink as Link } from "react-router-hash-link";
import { useSelector, useDispatch } from "react-redux";
import UserMgtAsmUpdate from "./UserMgtAsmUpdate";
import TextField from "@mui/material/TextField";
import exportFromJSON from "export-from-json";

const RedButton = styled(Button)(({ theme }) => ({
  color: "white",
  backgroundColor: "#EF6C1A",
  "&:hover": {
    backgroundColor: "#EF6C1A",
  },
}));

const MidContainerUserMgt = ({ asmZone }) => {
  const dispatch = useDispatch();
  // const [zonelist, setZonelist] = React.useState([]);
  const [RoleList, setRoleList] = useState(["All", "ASM", "TSI", "SE"]);
  const [StatusList, setStatusList] = useState([
    "All",
    "ACTIVE",
    "INACTIVE",
    "PENDING",
    "REJECT",
  ]);
  //   const Firsttoken = useSelector((state) => state.Token);
  const [Userdetail, setUserDetail] = useState({});
  const [q, setQ] = useState("");
  const [Role, setRole] = React.useState("All");
  const [Status, setStatus] = React.useState("All");
  const [searchColumn, setSearchColumn] = useState([
    "user_id",
    "user_code",
    "name",
    "surname",
    "mobile_no",
  ]);
  const [filterlist, setFilterlist] = useState([]);
  const userAllList = useSelector((store) => store.user.userList);

  //   const list = useSelector((state) => state.Zone.list);
  //   const userlist = useSelector((state) => state.UserMgt);
  const [token, setToken] = useState("");

  //   React.useEffect(() => {
  //     if (
  //       Firsttoken &&
  //       Firsttoken.token &&
  //       Firsttoken.token !== null &&
  //       Firsttoken.token.length !== 0 &&
  //       Firsttoken !== "undefined"
  //     ) {
  //       setToken(Firsttoken.token);
  //     }
  //   }, [Firsttoken]);

  //   React.useEffect(() => {
  //     if (
  //       userlist &&
  //       userlist.list &&
  //       userlist.list.length !== 0 &&
  //       userlist.list !== "undefined" &&
  //       userlist.list !== null
  //     ) {
  //       setFilterlist(userlist.list);
  //       setUserDetail(userlist.list[0]);
  //     }
  //   }, [userlist]);

  const handleStatusChange = (event) => {
    setStatus(event.target.value);
  };

  const handleRoleChange = (event) => {
    setRole(event.target.value);
  };

  //   const handleSubmit = () => {
  //     let neo = [];
  //     let filterRole = Role.toLowerCase();
  //     let filterStatus = Status.toLowerCase();

  //     // Ashish
  //     if (
  //       userlist.list &&
  //       userlist.list.length !== 0 &&
  //       userlist.list !== "undefined" &&
  //       userlist.list !== null
  //     ) {
  //       for (let i = 0; i < userlist.list.length; i++) {
  //         if (
  //           (userlist.list[i].role_name === filterRole &&
  //             userlist.list[i].status === filterStatus) ||
  //           (filterRole === "all" && userlist.list[i].status === filterStatus) ||
  //           (userlist.list[i].role_name === filterRole &&
  //             filterStatus === "all") ||
  //           (filterRole === "all" && filterStatus === "all")
  //         ) {
  //           neo.push(userlist.list[i]);
  //         }
  //       }
  //     }
  //     //Ashish
  //     setFilterlist(neo);
  //     setUserDetail(neo[0]);
  //     // if (neo && neo !== "undefined" && neo !== null && neo.length !== 0) {
  //     // }else{
  //     // }
  //   };

  const handleSubmit = () => {};
  const Searchfilter = (datas) => {
    return datas.filter((row) =>
      searchColumn.some((column) =>
        row[column] !== null &&
        row[column] !== "undefined" &&
        row[column].length !== 0
          ? row[column].toString().toLowerCase().indexOf(q.toLowerCase()) > -1
          : ""
      )
    );
  };

  const fileName = "ASM/TSI/SE";
  const exportType = "csv";
  const handleDownload = () => {
    let data = filterlist;

    exportFromJSON({ data, fileName, exportType });
  };

  const [query, setQuery] = useState("");
  const handleSearch = (e) => {
    setQuery(e.target.value);
  };
  const [filterUserList, setFilterUserList] = useState(null);

  useEffect(() => {
    if (userAllList) {
      setFilterUserList(userAllList);
    }
  }, [userAllList]);
  useEffect(() => {
    const filteredData = userAllList?.filter((user) => {
      const full_name = `${user?.first_name} ${user?.last_name}`.toLowerCase();
      return (
        full_name?.toLowerCase().includes(query?.toLowerCase()) ||
        user?.mobile_no?.toLowerCase().includes(query?.toLowerCase()) ||
        user?.user_role_name?.toLowerCase().includes(query?.toLowerCase()) ||
        user?.zone_name?.toLowerCase().includes(query?.toLowerCase()) ||
        user?.bank_name?.toLowerCase().includes(query?.toLowerCase())
      );
    });

    setFilterUserList(filteredData);
  }, [query]);

  return (
    <>
      <div
        className={styles.MidContainer}
        style={{ display: "flex", flexDirection: "column" }}
      >
        <div className={styles.MidContainerOne}>
          <div className={styles.MidSubContainerOne}>
            {/* <div className={styles.MidSubContainerOneList}>
              <label className={styles.MidSubContainerOneLabel}>Role</label>
              <Box sx={{ minWidth: 120 }}>
                <FormControl fullWidth>
                  <Select
                    value={Role}
                    onChange={handleRoleChange}
                    size="small"
                    sx={{ height: "1.8rem" }}
                  >
                    {RoleList &&
                      RoleList.map((item, index) => (
                        <MenuItem value={item} key={index}>
                          {item}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Box>
            </div> */}
            {/* <div className={styles.MidSubContainerOneList}>
              <label className={styles.MidSubContainerOneLabel}>Status</label>
              <Box sx={{ minWidth: 120 }}>
                <FormControl fullWidth>
                  <Select
                    value={Status}
                    onChange={handleStatusChange}
                    size="small"
                    sx={{ height: "1.8rem" }}
                  >
                    {StatusList &&
                      StatusList.length !== 0 &&
                      StatusList.map((item, index) => (
                        <MenuItem value={item}>{item}</MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Box>
            </div> */}

            <div
              className={styles.MidSubContainerOneList}
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <label className={styles.MidSubContainerOneLabel}>
                Search User
              </label>
              <input
                variant="outlined"
                // sx={{ minWidth: 200}}
                // size="small"
                value={query}
                onChange={handleSearch}
                placeholder="search...."
                style={{ height: "1.8rem" }}
              />
            </div>
            <div className={styles.MidSubContainerOneList}>
              {/* <Button style={{ backgroundColor: "#00b300", color: "white",marginTop:"-4px" }} onClick={handleDownload}>Export</Button> */}
            </div>
          </div>

          <div className={styles.MidSubContainerTwo}>
            <RedButton variant="contained" onClick={handleSubmit}>
              Submit
            </RedButton>
          </div>
        </div>
        <div className={styles.MidContainerTwo}>
          <UserMgtTable
            filterlist={filterUserList}
            Searchfilter={Searchfilter}
            detail={
              filterlist &&
              filterlist.length !== 0 &&
              filterlist !== "undefined"
                ? Userdetail
                : {}
            }
            setUserDetail={setUserDetail}
          />
        </div>
      </div>

      <div id="AsmUserUpdate">
        <div>
          <div>
            <div>
              <div className={styles.UserMgtBottomContainer}>
                {/* {filterlist &&
                filterlist.length !== 0 &&
                filterlist !== "undefined" ? ( */}
                <UserMgtAsmUpdate
                  asmZone={asmZone}
                  detail={
                    filterlist &&
                    filterlist.length !== 0 &&
                    filterlist !== "undefined"
                      ? Userdetail
                      : {}
                  }
                  list={
                    filterlist &&
                    filterlist.length !== 0 &&
                    filterlist !== "undefined"
                      ? filterlist
                      : []
                  }
                />
                {/* ) : (
                  <h5>No Data</h5>
                )} */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MidContainerUserMgt;
