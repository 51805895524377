import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Typography, Button } from '@mui/material';
import useTargetDumpUpload from '../../../hooks/TargetAssigned/useTargetDumpUpload';
import Swal from "sweetalert2";
import useUsersTargetData from '../../../hooks/useUsersTargetData';
import { SpatialTrackingOutlined } from '@mui/icons-material';

const ValidationPopup = ({ open, handleClose, formData, setIsDownloading, selectedMonth }) => {

  //----------Api Call---------------
  const getTargetDumpUpload = useTargetDumpUpload()
  const gettargetdata = useUsersTargetData(selectedMonth);

  function getMonthName(monthNumber) {
   
    const date = new Date();
    date.setMonth(monthNumber - 1);
    
    return date.toLocaleString('default', { month: 'long' });
  }

  const handleUpload=async()=>{
    setIsDownloading(true);
    const data = await getTargetDumpUpload(formData);
    setIsDownloading(false);
    handleClose()
    if (data.code === 500) {
      Swal.fire({
        title: "Error",
        text: data.message || "An unexpected error occurred.",
        icon: "error",
        showConfirmButton: true,
      });
    } else if (data.code === 200) {
      Swal.fire({
        title: "success",
        text: data.message ,
        icon: "success",
        showConfirmButton: true,
      });
      await gettargetdata();
    }
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>
        <b>Excel Upload</b>
      </DialogTitle>
      <DialogContent>
        {
          <Typography variant="body1"  gutterBottom>
            <>
            Your target will be updated for{" "}
            <span style={{fontWeight:"700"}}>{getMonthName(selectedMonth?.month_no)} {selectedMonth?.year}</span>, Click the
            Upload button to upload the dump.
            </>
          </Typography>
        }
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
        <Button onClick={handleUpload} color="primary">
          Upload
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ValidationPopup;
