import useAxiosPrivate from "./useAxiosPrivate";
import { useDispatch } from "react-redux";
import { getSchemeGraphData} from "../slice/schemeSlice";

const useSchemeSalesData = () => {
  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();
  const getSchemeSalesData = async (param) => {

    const response = await Axios.post("scheme_sales_details", param);
    const data = await response.data;
    console.log(data)
    dispatch(getSchemeGraphData(data));
  };
  return getSchemeSalesData;
};

export default useSchemeSalesData;
