import { createSlice } from "@reduxjs/toolkit";

const routeSlice = createSlice({
  name: "router",
  initialState: {
    saathiRouteList:null,
    selectedRouteDetails:null,
    routeStateRetailerList: null,
    routeZoneRetailerList: null,
    routeRetailerDetails: null,
    map:"",
    employeeAreaList: null,
    selectedEmployeeArea: null,
    beatList:null,
    employeeAreaDropdownList:null
  },
  reducers: {
    getSaathiRouteDetails: (state, action) => {
      state.saathiRouteList = action.payload;
    },
    setSelectedRoute: (state, action) => {
      state.selectedRouteDetails = action.payload;
    },
    getStateRouteRetailerList: (state, action) => {
      state.routeStateRetailerList = action.payload;
      state.map="cluster"
    },
    getZoneRouteRetailerList: (state, action) => {
      state.routeZoneRetailerList = action.payload;
    },
    getRouteRetailerDetails: (state, action) => {
      state.routeRetailerDetails = action.payload;
    },
    // Employee Area
    getEmployeeAreaList: (state, action) => {
      state.employeeAreaList = action.payload;
    },
    getSelectedEmployeeArea: (state, action) => {
      state.selectedEmployeeArea = action.payload;
    },
    getBeatList:(state,action)=>{
      state.beatList=action.payload
    },
    getEmployeeAreaDropdownList:(state,action)=>{
      state.employeeAreaDropdownList=action.payload
    }
  },
});

export default routeSlice.reducer;
export const {
  getSaathiRouteDetails,
  setSelectedRoute,
  getStateRouteRetailerList,
  getZoneRouteRetailerList,
  getRouteRetailerDetails,
  getEmployeeAreaList,
  getSelectedEmployeeArea,
  getBeatList,
  getEmployeeAreaDropdownList
} = routeSlice.actions;
