import * as React from "react";
import { useState, useEffect } from "react";
import useSRSaleTableDetails from "../../../hooks/useSRSaleTableDetails";
import { useSelector } from "react-redux";
import {Box, Stack} from "@mui/material"

const RangeSales = ({searchTerm, setSearchTerm}) => {
  const zoneData = useSelector((state) =>state.app.curretDropdownData);
  const date = new Date();
  const [currentFirstDate,setCurrentFirstDate]=useState(formatDate(new Date(new Date().setDate(new Date().getDate() - 1))));
  
  const [currentLastDate,setCurrentLastDate]=useState(formatDate(date));
  const getSRSalesData = useSRSaleTableDetails(null,currentFirstDate,currentLastDate);
  useEffect(() => {
    getSRSalesData();
  }, [zoneData]);
  const handleFirstDateChange = (e) => {
    // console.log(e.target.value);
    setCurrentFirstDate(e.target.value);
   
  };

  const handleLastDateChange = (e) => {
    // console.log(e.target.value);
    setCurrentLastDate(e.target.value);
   
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };


  return (
    <Box sx={{display:"flex",flexDirection:"row",gap:"0.8rem",alignItems:"center"}}>
      <input
        type="text"
        placeholder="Search"
        style={{
          width: "10rem",
          height: "1.8rem",
          fontSize: "14px",
          fontFamily: "inherit",
          borderRadius: "8px",
          padding: "4px 8px",
          border: "1px solid #c4c4c4",
          outline: "none",
        }}
        onChange={handleSearchChange}
        value={searchTerm}
      />
      <Stack sx={{ display: "flex", flexDirection: "row", gap: "0.8rem" }}>
        <input
          type="date"
          id="dateSale"
          name="first_date"
          style={{ height: "2rem", fontSize: "1rem" }}
          onChange={handleFirstDateChange}
          value={currentFirstDate}
        />
        <input
          type="date"
          id="dateSale"
          name="last_date"
          style={{ height: "2rem", fontSize: "1rem" }}
          onChange={handleLastDateChange}
          value={currentLastDate}
        />
      </Stack>
    </Box>
  );
};

// Function to format date as "YYYY-MM-DD"
const formatDate = (date) => {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  return `${year}-${month}-${day}`;
};

export default RangeSales;
