// mySlice.js

import { createSlice } from '@reduxjs/toolkit';

const initialState = {  
    zone_id: '',
    pincode : '',
    shape_id : '',
    se_ids: [],
    dist_ids: [],
    asm_ids: [],
    so_ids: [],
    clustStatus: false,
    sc_id:[]
};
//admin assign SE
const assignRouteSlice = createSlice({
  name: "assignRoute",
  initialState,
  reducers: {
    setZoneId: (state, action) => {
      state.zone_id = action.payload;
    },
    setRoutePincode: (state, action) => {
      state.pincode = action.payload;
    },
    setRouteShapeId: (state, action) => {
      state.shape_id = action.payload;
    },
    setSeId: (state, action) => {
      state.se_ids = action.payload;
    },
    setDistIds: (state, action) => {
      state.dist_ids = action.payload;
    },
    setAsmId: (state, action) => {
      state.asm_ids = action.payload;
    },
    setSoId: (state, action) => {
      state.so_ids = action.payload;
    },
    setFrequency: (state, action) => {
      state.frequency = action.payload;
    },
    setClusteringStatus: (state, action) => {
      state.clustStatus = action.payload;
    },
    setSuperCategoryId: (state, action) => {
      state.sc_id = action.payload;
    },
    resetData: (state) => {
      return initialState;
    },
  },
});

export const { setZoneId, setSeId, setRouteShapeId, setRoutePincode, setFrequency, setDistIds, setAsmId, setSoId,setClusteringStatus,setSuperCategoryId, resetData } = assignRouteSlice.actions;
export default assignRouteSlice.reducer;
export const selectAssignRoute = (state) => state.assignRoute;
