import React, { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import Switch from "@mui/material/Switch";
import Button from "@mui/material/Button";
import styles from "./styles/EmployeeAreaTable.module.css"

import { useSelector, useDispatch } from "react-redux";
import exportFromJSON from "export-from-json";
import useSaathiRouteDetails from "../../../hooks/useSaathiRouteDetails";
import { getSelectedEmployeeArea, setSelectedRoute } from "../../../slice/routeSlice";
import useUpdateSaathiRouteStatus from "../../../hooks/useUpdateSaathiRouteStatus";
import { InputLabel, Stack, TextField } from "@mui/material";
import DownloadButtons from ".././DownloadButtons";
import useEmployeeAreaList from "../../../hooks/EmployeeArea/useEmployeeAreaList";
const RedButton = styled(Button)(({ theme }) => ({
  color: "#731D3A",
  backgroundColor: "white",
  "&:hover": {
    backgroundColor: "#731D3A",
    color: "white",
    border: "2px solid #731D3A",
  },
  border: "2px solid #731D3A",
}));

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const useStyles = makeStyles({
  head: {
    borderBottom: "2px solid black",
  },
});
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#E5E5E5",
    color: "black",
    fontSize: "14px",
    fontWeight: "600",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const EmployeeAreaTable = ({ rootzone }) => {
    const zoneData = useSelector((state) => state.app.curretDropdownData);
    const routeDetails = useSelector((state) => state.route.saathiRouteList);
    const employeeAreaList = useSelector((state) => state.route.employeeAreaList);
    const dispatch = useDispatch();
    const classes = useStyles();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedSR, setselectedSR] = React.useState({
      sr_id: null,
      sr_name: "",
    });
    const columns = [
      "SI no.",
      "Zone",
      "Employee Area Name",
      "ASM",
      "SO",
      "SR",
      "No of Beats",
      "Status",
      "Action",
    ];
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
  
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
    };
  
    const getAllEmployeeAreaList=useEmployeeAreaList()
    useEffect(() => {
      const param = {
        zone_id: zoneData?.zone_id,
      };
      getAllEmployeeAreaList(param);
    }, [zoneData?.zone_id]);
  
    const handleClick = (row) => {
      // console.log(row,"handleClick")
      dispatch(getSelectedEmployeeArea(row))
      document
        .getElementById("EmployeeAreaUpdate")
        .scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
    };
  
    const updateStatus = useUpdateSaathiRouteStatus();
    const handleSwitchChange = async (row) => {
      const param = {
        status: !row.status,
        route_id: row.route_id,
      };
      await updateStatus(param);
      const param2 = {
        zone_id: zoneData?.zone_id,
      };
      await getAllEmployeeAreaList(param2);
    };

    const filteredEmployeeAreaList = employeeAreaList?.filter(
      (employee) =>
        employee?.route_area_name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        employee?.asm_name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        (employee?.so_name?.toLowerCase().includes(searchQuery.toLowerCase())) ||
        employee?.se_name?.toLowerCase().includes(searchQuery.toLowerCase())
    );
    
  
    return (
      <div id="EmployeeAreaTable">
        <Stack sx={{flexDirection:"row", alignItems:"center", gap:"12px", marginLeft:"5px", marginTop:"-20px", justifyContent:"space-between"}}>
          <Stack flexDirection="row" alignItems="center" gap="12px">
        <InputLabel>Search:</InputLabel>
        <TextField
          variant="outlined"
          placeholder="Search..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          margin="normal"
          size="small"
        />
        </Stack>
        
        </Stack>
        <TableContainer sx={{ maxHeight: 440, minWidth: "100%" }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead className={classes.head} sx={{ backgroundColor: "#c4c4c4" }}>
              <TableRow className={classes.head} sx={{ backgroundColor: "#c4c4c4" }}>
                {columns.map((column, index) => (
                  <StyledTableCell key={index} align="center">
                    {column}
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredEmployeeAreaList?.length !== 0 ? (
                filteredEmployeeAreaList
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <TableRow hover role="checkbox" key={index}>
                      <TableCell align="center">{index+1}</TableCell>
                      <TableCell align="center">{row.zone_name}</TableCell>
                      <TableCell align="center">{row.route_area_name}</TableCell>
                      <TableCell align="center">{row.asm_name}</TableCell>
                      <TableCell align="center">{row.so_name}</TableCell>
                      <TableCell align="center">{row.se_name}</TableCell>
                      <TableCell align="center">{row?.no_of_routes}</TableCell>
                      <TableCell align="center">
                        <IOSSwitch checked={row.status==='active'} 
                        // onChange={() => handleSwitchChange(row)} 
                        />
                      </TableCell>
                      <TableCell align="center">
                        <RedButton variant="outlined" onClick={() => handleClick(row)}>
                          Details
                        </RedButton>
                      </TableCell>
                    </TableRow>
                  ))
              ) : (
                <TableRow>
                  <TableCell colSpan={columns.length} align="center">No Data</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TableRow>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={filteredEmployeeAreaList?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableRow>
      </div>
    );
  };

export default EmployeeAreaTable;
