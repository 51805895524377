import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import {
  FormControl,
  Stack,
} from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import AddIcon from "@mui/icons-material/Add";
import useSkuImageUpload from "../../hooks/useSkuImageUpload";
import useAddSku from "../../hooks/useAddSku";
import { ToastContainer, toast } from "react-toastify";
import useSkuList from "../../hooks/useSkuList";
import { getSkuList, resetSkuFormData } from "../../slice/skuSlice";
import useCategoryList from "../../hooks/useCategoryList";
import useSuperCategoryList from "../../hooks/useSuperCategoryList";
import PhotoCameraOutlinedIcon from "@mui/icons-material/PhotoCameraOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import useSuperCategoryStatus from "../../hooks/useSuperCategoryStatus";


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "fit-content",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function AddSku() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    dispatch(resetSkuFormData());
    setOpen(false);
    setForm({});
    setError({});
  };
  const dispatch = useDispatch();
  const [disable, setDisable] = React.useState(false);
  const [form, setForm] = React.useState({});
  const superCategoryList = useSelector(
    (store) => store.superCategory.superCategoryList
  );
  const categoryList = useSelector((store) => store.category.categoryList);
  const currentSelectedImage = useSelector((store) => store.sku.skuFormData);
  const superCategoryStatus = useSelector(
    (state) => state.superCategory.superCategoryStatus
  );

  const getSkuImageUpload = useSkuImageUpload();

  useSuperCategoryList();
  const getAllCategoryList = useCategoryList();
  const addSku = useAddSku(form);
  const getAllSkuList = useSkuList();
  useSuperCategoryStatus(form);

  const [error, setError] = React.useState({});

  const isValid = () => {
    let success = true;
    let err = {};

    if (
      form.sku_name?.trim() === "" ||
      form.sku_name?.trim() === undefined ||
      form.sku_name?.trim() === null
    ) {
      err.sku_name = "**SKU Name Cannot be Empty**";
      success = false;
    }

    if (
      form.selectedImage?.trim() === "" ||
      form.selectedImage?.trim() === undefined ||
      form.selectedImage?.trim() === null
    ) {
      err.selectedImage = "**Image should be selected**";
      success = false;
    }

    if (
      form.price_per_piece?.trim() === "" ||
      form.price_per_piece?.trim() === undefined ||
      form.price_per_piece?.trim() === null
    ) {
      err.price_per_piece = "**Price Cannot be Empty**";
      success = false;
    }else if (
      form.price_per_piece?.trim() === 0 ||
      form.price_per_piece?.trim() === "0"
    ){
      err.price_per_piece = "**Price Cannot be 0**";
      success = false;
    }

    if (superCategoryStatus?.[0]?.isladi === true) {
      // Validate form.ladi
      if (
        form.ladi?.trim() === "" || 
        form.ladi?.trim() === undefined || 
        form.ladi?.trim() === null
      ) {
        err.ladi = "**Quantity Cannot be Empty**";
        success = false;
      } else if (
        form.ladi?.trim() === "0" || 
        form.ladi?.trim() === 0
      ) {
        err.ladi = "**Quantity Cannot be 0**";
        success = false;
      }
    
      // Validate form.price_per_ladi
      if (
        form.price_per_ladi?.trim() === "" || 
        form.price_per_ladi?.trim() === undefined || 
        form.price_per_ladi?.trim() === null
      ) {
        err.price_per_ladi = "**Price Cannot be Empty**";
        success = false;
      } else if (
        form.price_per_ladi?.trim() === "0" || 
        form.price_per_ladi?.trim() === 0
      ) {
        err.price_per_ladi = "**Price Cannot be 0**";
        success = false;
      }
    }
    

    if (
      form.carton?.trim() === "" ||
      form.carton?.trim() === undefined ||
      form.carton?.trim() === null
    ) {
      err.carton = "**Quantity Cannot be Empty**";
      success = false;
    }else if (
      form.carton?.trim() === 0 ||
      form.carton?.trim() === "0"
    ){
      err.carton = "**Quantity Cannot be 0**";
      success = false;
    }

    if (
      form.price_per_carton?.trim() === "" ||
      form.price_per_carton?.trim() === undefined ||
      form.price_per_carton?.trim() === null
    ) {
      err.price_per_carton = "**Price Cannot be Empty**";
      success = false;
    }else if (
      form.price_per_carton?.trim() === 0 ||
      form.price_per_carton?.trim() === "0"
    ){
      err.price_per_carton = "**Price Cannot be 0**";
      success = false;
    }

    if (
      form.category_id === 0 ||
      form.category_id === undefined ||
      form.category_id === null
    ) {
      err.category = "**Category Cannot be Empty**";
      success = false;
    }

    if (form.sc_id === 0 || form.sc_id === undefined || form.sc_id === null) {
      err.super_category = "**Super Category Cannot be Empty**";
      success = false;
    }
    setError(err);
    return success;
  };

  const handleImageChange = (e) => {
    const selectedImage = e.target.files[0];
    setForm((prev) => ({
      ...prev,
      [e.target.name]: e.target.files[0],
    }));
    setDisable(true);
    if (selectedImage.size > 150 * 1024) {
      setError({ selectedImage: "**Image size should be less than 150KB**" });
    }else{
      setError({ selectedImage: "" });
    }
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    getSkuImageUpload(formData);

    if (e.target.files[0]) {
      setForm((prev) => ({
        ...prev,
        selectedImage: e.target.files[0].name,
      }));
    } else {
      setForm((prev) => ({
        ...prev,
        selectedImage: null,
      }));
    }
  };
  const handleChange = (e) => {
    setForm((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  // useEffect(() => {
  //   getAllCategoryList();
  // }, []);
  useEffect(() => {
    setDisable(false);
  }, [currentSelectedImage]);

  // useEffect(() => {
  //   console.log(form, "price_per_carton");
  // }, [form]);
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (isValid()) {
      await addSku();
      await getAllSkuList();
      dispatch(resetSkuFormData());  
      setOpen(false);
      setForm({});
    }
    
  };
  // useEffect(() =>{
  //   console.log(form , "form");
  // }, [form])

  const handleCategory = (e) => {
    const trimmedValue = e.target.value.trim();
    console.log(trimmedValue);
    let categoryId = 1;
    
    for (let i = 0; i < categoryList?.length; i++) {
      if (trimmedValue === categoryList[i]?.category_name?.trim()) {
        categoryId = categoryList[i]?.category_id;
        break;
      }
    }
  
    setForm((prev) => ({
      ...prev,
      category_id: categoryId,
    }));
  };

  const handleSuperCategory = (e) => {
    let scId = 0;
    for (let i = 0; i < superCategoryList?.length; i++) {
      if (e.target.value === superCategoryList[i]?.sc_name) {
        scId = superCategoryList[i]?.sc_id;
        break;
      }
    }

    setForm((prev) => ({
      ...prev,
      sc_id: scId,
    }));
  };

  return (
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div>
        <Button
          onClick={handleOpen}
          sx={{
            height: "0.6rem",
            color: "white",
            background: "#EF6C1A",
            padding: "1rem",
            textWrap: "nowrap",
            textTransform:"none",
            ":hover": { backgroundColor: "#EF6C1A" },
          }}
        >
          <AddIcon sx={{ fontSize: "14px", fontWeight: "700" }} />
          Add SKU
        </Button>
        <Modal
          open={open}
          // onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              ...style,
              width: "100%", // Set the width to 90% of the viewport width
              maxWidth: { xs: "300px", md: "600px", lg: "950px" },
              margin: "auto", // Center the modal horizontally
              borderRadius: "10px",
              padding: "1.4rem 2.8rem 1.4rem 2.2rem",
            }}
          >
            <FormControl fullWidth size="small">
              <Stack sx={{ gap: "1rem" }}>
                <Stack
                  sx={{
                    flexDirection: { xs: "column", md: "row" }, // Stack in column for small screens, row for medium and above
                    gap: "5rem",
                  }}
                >
                  {/* Sku Name,Category,Image,SuperCategory */}
                  <Stack sx={{ gap: "1.5rem", flex: 1 }}>
                    <Stack sx={{ marginBottom: "1.5rem" }}>
                      <Typography
                        sx={{
                          color: "#000000",
                          fontWeight: "600",
                          fontSize: "16px",
                        }}
                      >
                        Add New SKU
                      </Typography>
                    </Stack>
                    <Stack sx={{ gap: "0.4rem" }}>
                      <input
                        variant="outlined"
                        name="sku_name"
                        placeholder="Enter sku name*"
                        onChange={handleChange}
                        style={{
                          height: "2.4rem",
                          fontSize: "0.9rem",
                          width: "96%",
                          paddingLeft: "0.7rem",
                        }}
                      />
                      {error.sku_name && (
                        <p
                          style={{
                            color: "red",
                            fontSize: "0.8rem",
                            margin: "0",
                          }}
                        >
                          {error.sku_name}
                        </p>
                      )}
                    </Stack>

                    <Stack sx={{ gap: "0.4rem" }}>
                      <select
                        style={{
                          height: "2.6rem",
                          fontSize: "0.9rem",
                          cursor: "pointer",
                          width: "100%",
                          paddingLeft: "0.5rem",
                        }}
                        name="super_category"
                        onChange={handleSuperCategory}
                      >
                        <option value="" disabled selected>
                          Select Super Category*
                        </option>
                        {superCategoryList?.filter(
                            (item) => item.status
                          ).map((item) => (
                          <option key={item.sc_id}>{item.sc_name}</option>
                        ))}
                      </select>
                      {error.super_category && (
                        <p
                          style={{
                            color: "red",
                            fontSize: "0.8rem",
                            margin: "0",
                          }}
                        >
                          {error.super_category}
                        </p>
                      )}
                    </Stack>
                    <Stack sx={{ gap: "0.4rem" }}>
                      <select
                        style={{
                          height: "2.6rem",
                          fontSize: "0.9rem",
                          cursor: "pointer",
                          width: "100%",
                          paddingLeft: "0.5rem",
                        }}
                        name="category"
                        onChange={handleCategory}
                      >
                        <option value="" disabled selected>
                          Select Category*
                        </option>
                        {categoryList
                          ?.filter(
                            (item) =>
                              item.sc_id === form.sc_id && item.status
                          )
                          .map((item) => (
                            <option key={item.id}>{item.category_name}</option>
                          ))}
                      </select>
                      {error.category && (
                        <p
                          style={{
                            color: "red",
                            fontSize: "0.8rem",
                            margin: "0",
                          }}
                        >
                          {error.category}
                        </p>
                      )}
                    </Stack>
                    <Stack
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "0.4rem",
                      }}
                    >
                      <label htmlFor="sku_image" style={{ cursor: "pointer" }}>
                        <input
                          id="sku_image"
                          type="file"
                          size="small"
                          accept="image/*"
                          name="sku_image"
                          style={{
                            display: "none",
                          }}
                          onChange={handleImageChange}
                        />

                        <div
                          style={{
                            height: "180px",
                            fontSize: "0.9rem",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            cursor: "pointer",
                            background: "#F2F2F2",
                          }}
                        >
                          {currentSelectedImage ? (
                            <img
                              style={{
                                height: "100%",
                                width: "100%",
                                objectFit: "contain",
                              }}
                              src={currentSelectedImage.full_path}
                              alt="sku_image"
                            />
                          ) : (
                            <>
                              {form.selectedImage ? (
                                form.selectedImage
                              ) : (
                                <Stack
                                  sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    gap: "0.5rem",
                                  }}
                                >
                                  <Stack
                                    sx={{
                                      background: "#FFFFFF",
                                      padding: "0.8rem",
                                      borderRadius: "50%",
                                    }}
                                  >
                                    <PhotoCameraOutlinedIcon />
                                  </Stack>
                                  <Typography>Sku Image</Typography>
                                </Stack>
                              )}
                            </>
                          )}
                        </div>
                      </label>
                      {error.selectedImage && (
                        <p
                          style={{
                            color: "red",
                            fontSize: "0.8rem",
                            margin: "0",
                          }}
                        >
                          {error.selectedImage}
                        </p>
                      )}
                    </Stack>
                  </Stack>

                  {/* Close Icon */}
                  <Stack
                    sx={{ position: "absolute", right: 0, cursor: "pointer" }}
                  >
                    <CloseOutlinedIcon onClick={handleClose} />
                  </Stack>

                  {/* Piece, Ladi && Carton */}
                  {superCategoryStatus != null &&
                  superCategoryStatus?.length > 0 ? (
                    <Stack
                      sx={{
                        gap: { xs: "1rem", md: "1.2rem" },
                        flex: "1", // Take remaining space in medium and above screens
                        marginTop: "2.5rem",
                      }}
                    >
                      {superCategoryStatus &&
                        superCategoryStatus?.[0]?.ispiece === true && (
                          <Stack
                            sx={{
                              flexDirection: "row",
                              justifyContent: "space-between",
                              gap: "1rem",
                            }}
                          >
                            <Stack sx={{ gap: "0.8rem" }}>
                              <label>Price per Piece*</label>
                              <input
                                variant="outlined"
                                name="price_per_piece"
                                type="number"
                                placeholder="Enter Sale Price"
                                onChange={handleChange}
                                style={{
                                  height: "2.4rem",
                                  fontSize: "0.9rem",
                                  width: "100%",
                                  paddingLeft: "0.7rem",
                                }}
                              />
                              {error.price_per_piece && (
                                <p
                                  style={{
                                    color: "red",
                                    fontSize: "0.8rem",
                                    margin: "0",
                                  }}
                                >
                                  {error.price_per_piece}
                                </p>
                              )}
                            </Stack>
                          </Stack>
                        )}
                      {superCategoryStatus &&
                        superCategoryStatus?.[0]?.isladi === true && (
                          <Stack
                            sx={{
                              flexDirection: "row",
                              justifyContent: "space-between",
                              gap: "1rem",
                            }}
                          >
                            <Stack sx={{ gap: "0.8rem" }}>
                              <label>Ladi*</label>
                              <input
                                variant="outlined"
                                name="ladi"
                                type="number"
                                placeholder="No of Pieces"
                                onChange={handleChange}
                                style={{
                                  height: "2.4rem",

                                  fontSize: "0.9rem",
                                  width: "100%",
                                  paddingLeft: "0.7rem",
                                }}
                              />
                              {error.ladi && (
                                <p
                                  style={{
                                    color: "red",
                                    fontSize: "0.8rem",
                                    margin: "0",
                                  }}
                                >
                                  {error.ladi}
                                </p>
                              )}
                            </Stack>

                            <Stack
                              sx={{ gap: "0.8rem", alignSelf: "self-end" }}
                            >
                              <input
                                variant="outlined"
                                name="price_per_ladi"
                                type="number"
                                placeholder="Enter Sale Price"
                                onChange={handleChange}
                                style={{
                                  height: "2.4rem",

                                  fontSize: "0.9rem",
                                  width: "100%",
                                  paddingLeft: "0.7rem",
                                }}
                              />
                              {error.price_per_ladi && (
                                <p
                                  style={{
                                    color: "red",
                                    fontSize: "0.8rem",
                                    margin: "0",
                                  }}
                                >
                                  {error.price_per_ladi}
                                </p>
                              )}
                            </Stack>
                          </Stack>
                        )}
                      {superCategoryStatus &&
                        superCategoryStatus?.[0]?.iscarton === true && (
                          <Stack
                            sx={{
                              flexDirection: "row",
                              justifyContent: "space-between",
                              gap: "1rem",
                            }}
                          >
                            <Stack sx={{ gap: "0.8rem" }}>
                              <label>Carton*</label>
                              <input
                                variant="outlined"
                                name="carton"
                                type="number"
                                placeholder="No. of Ladi"
                                onChange={handleChange}
                                style={{
                                  height: "2.4rem",
                                  paddingLeft: "0.5rem",
                                  fontSize: "0.9rem",
                                  width: "100%",
                                  paddingLeft: "0.7rem",
                                }}
                              />
                              {error.carton && (
                                <p
                                  style={{
                                    color: "red",
                                    fontSize: "0.8rem",
                                    margin: "0",
                                  }}
                                >
                                  {error.carton}
                                </p>
                              )}
                            </Stack>

                            <Stack
                              sx={{ gap: "0.4rem", alignSelf: "self-end" }}
                            >
                              <input
                                variant="outlined"
                                name="price_per_carton"
                                type="number"
                                placeholder="Enter Sale Price"
                                onChange={handleChange}
                                style={{
                                  height: "2.4rem",
                                  paddingLeft: "0.5rem",
                                  fontSize: "0.9rem",
                                  width: "100%",
                                  paddingLeft: "0.7rem",
                                }}
                              />
                              {error.price_per_carton && (
                                <p
                                  style={{
                                    color: "red",
                                    fontSize: "0.8rem",
                                    margin: "0",
                                  }}
                                >
                                  {error.price_per_carton}
                                </p>
                              )}
                            </Stack>
                          </Stack>
                        )}
                    </Stack>
                  ) : (
                    <Stack
                      sx={{
                        gap: { xs: "1rem", md: "1.2rem" },
                        flex: "1", // Take remaining space in medium and above screens
                        marginTop: "2.5rem",
                      }}
                    >
                      <Stack
                        sx={{
                          flexDirection: "row",
                          justifyContent: "space-between",
                          gap: "1rem",
                        }}
                      >
                        <Stack sx={{ gap: "0.8rem" }}>
                          <label>Price per Piece*</label>
                          <input
                            variant="outlined"
                            name="price_per_piece"
                            type="number"
                            placeholder="Enter Sale Price"
                            onChange={handleChange}
                            style={{
                              height: "2.4rem",
                              fontSize: "0.9rem",
                              width: "100%",
                              paddingLeft: "0.7rem",
                            }}
                          />
                          {error.price_per_piece && (
                            <p
                              style={{
                                color: "red",
                                fontSize: "0.8rem",
                                margin: "0",
                              }}
                            >
                              {error.price_per_piece}
                            </p>
                          )}
                        </Stack>
                      </Stack>

                      <Stack
                        sx={{
                          flexDirection: "row",
                          justifyContent: "space-between",
                          gap: "1rem",
                        }}
                      >
                        <Stack sx={{ gap: "0.8rem" }}>
                          <label>Ladi*</label>
                          <input
                            variant="outlined"
                            name="ladi"
                            type="number"
                            placeholder="No of Pieces"
                            onChange={handleChange}
                            style={{
                              height: "2.4rem",

                              fontSize: "0.9rem",
                              width: "100%",
                              paddingLeft: "0.7rem",
                            }}
                          />
                          {error.ladi && (
                            <p
                              style={{
                                color: "red",
                                fontSize: "0.8rem",
                                margin: "0",
                              }}
                            >
                              {error.ladi}
                            </p>
                          )}
                        </Stack>

                        <Stack sx={{ gap: "0.8rem", alignSelf: "self-end" }}>
                          <input
                            variant="outlined"
                            name="price_per_ladi"
                            type="number"
                            placeholder="Enter Sale Price"
                            onChange={handleChange}
                            style={{
                              height: "2.4rem",

                              fontSize: "0.9rem",
                              width: "100%",
                              paddingLeft: "0.7rem",
                            }}
                          />
                          {error.price_per_ladi && (
                            <p
                              style={{
                                color: "red",
                                fontSize: "0.8rem",
                                margin: "0",
                              }}
                            >
                              {error.price_per_ladi}
                            </p>
                          )}
                        </Stack>
                      </Stack>

                      <Stack
                        sx={{
                          flexDirection: "row",
                          justifyContent: "space-between",
                          gap: "1rem",
                        }}
                      >
                        <Stack sx={{ gap: "0.8rem" }}>
                          <label>Carton*</label>
                          <input
                            variant="outlined"
                            name="carton"
                            type="number"
                            placeholder="No. of Ladi"
                            onChange={handleChange}
                            style={{
                              height: "2.4rem",
                              paddingLeft: "0.5rem",
                              fontSize: "0.9rem",
                              width: "100%",
                              paddingLeft: "0.7rem",
                            }}
                          />
                          {error.carton && (
                            <p
                              style={{
                                color: "red",
                                fontSize: "0.8rem",
                                margin: "0",
                              }}
                            >
                              {error.carton}
                            </p>
                          )}
                        </Stack>

                        <Stack sx={{ gap: "0.4rem", alignSelf: "self-end" }}>
                          <input
                            variant="outlined"
                            name="price_per_carton"
                            type="number"
                            placeholder="Enter Sale Price"
                            onChange={handleChange}
                            style={{
                              height: "2.4rem",
                              paddingLeft: "0.5rem",
                              fontSize: "0.9rem",
                              width: "100%",
                              paddingLeft: "0.7rem",
                            }}
                          />
                          {error.price_per_carton && (
                            <p
                              style={{
                                color: "red",
                                fontSize: "0.8rem",
                                margin: "0",
                              }}
                            >
                              {error.price_per_carton}
                            </p>
                          )}
                        </Stack>
                      </Stack>
                    </Stack>
                  )}
                </Stack>

                {/* Button */}
                <Stack
                  sx={{
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    gap: "0.4rem",
                  }}
                >
                  <Button
                    sx={{
                      background: "white !important",
                      color: "#FF5A5A !important",
                      border: "1px solid #FF5A5A",
                      height: "1.8rem",
                      padding: "18px 10px",
                      fontSize: "12px",
                      fontWeight: 600,
                      textTransform: "none",
                    }}
                    onClick={handleClose}
                  >
                    Discard
                  </Button>
                  <Button
                    sx={{
                      background: "#FF5A5A",
                      color: "white",
                      height: "1.8rem",
                      ":hover": { background: "#FF5A5A" },
                      padding: "18px 10px",
                      fontSize: "12px",
                      fontWeight: 500,
                      textTransform: "none",
                    }}
                    onClick={handleSubmit}
                    disabled={disable}
                  >
                    Add New SKU
                  </Button>
                </Stack>
              </Stack>
            </FormControl>
          </Box>
        </Modal>
      </div>
    </>
  );
}
