import React, { useState, useEffect } from "react";
import { Box } from "@material-ui/core";
import { Stack, Typography } from "@mui/material";
import { useSelector } from "react-redux";

const TopAchievers = () => {
  const SRAllList = useSelector((store) => store.sr.SRList);
  const tableDetails = useSelector((store) => store.sr.SRTimeTableDetails);
  // console.log(tableDetails?.[0]?.user_data, "kdsjfojrfdijf");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const data = [
    {
      rank: "1st",
      color: "#E8E9BD",
    },
    {
      rank: "2nd",
      color: "#D9D9D9",
    },
    {
      rank: "3rd",
      color: "#BE9B99",
    },
  ];

  const [sortedSeDetails, setSortedSeDetails] = useState([]);

  // Simulating fetching JSON data (replace with actual fetch logic)
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Check if user_data exists and is an array
        if (Array.isArray(tableDetails)) {
          // Clone the array before sorting
          const userDataCopy = [...tableDetails];

          // Sort the cloned array
          const sortedDetails = userDataCopy.sort((a, b) => {
            const hourA = a?.hour_difference !== null ? a?.hour_difference : 0;
            const hourB = b?.hour_difference !== null ? b?.hour_difference : 0;
            return hourB - hourA;
          });

          // Log sorted details for debugging
          // console.log(sortedDetails);

          // Set the sorted se_details in state
          setSortedSeDetails(sortedDetails);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [tableDetails]);

  // console.log(sortedSeDetails, "sortedSEDetails");

  return (
    <Box sx={{ width: "calc(100vw - 150px)" }}>
      <Typography>Top Achievers</Typography>
      <Stack
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          gap: "1.5rem",
          width: "100%",
          flexWrap: "wrap",
        }}
      >
        {sortedSeDetails?.slice(0, 3).map((item, index) => (
          <Stack
            key={index}
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                boxShadow: 3,
                width: "1rem",
                paddingTop: "1.7rem",
                paddingBottom: "1.7rem",
                paddingLeft: "1rem",
                paddingRight: "1rem",
                borderTopLeftRadius: "0.6rem",
                borderBottomLeftRadius: "0.6rem",
                background: data[index]?.color,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {data[index]?.rank}
            </Typography>

            <Stack
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                background: "#FFFFFF",
                gap: "0.4rem",
                boxShadow: 3,
                width: "20rem",
                padding: "1rem",
                borderTopRightRadius: "0.6rem",
                borderBottomRightRadius: "0.6rem",
              }}
            >
              <img
                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTSLU5_eUUGBfxfxRd4IquPiEwLbt4E_6RYMw&s"
                alt=""
                style={{ width: "15%", height: "15%" }}
              />
              <Typography>
                {/* {item.se_users?.map((se_name, index) => ( */}
                <>{item?.se_name}</>
                {/* ))} */}
              </Typography>
              <Typography>|</Typography>

              <Typography>
                {parseFloat(item?.hour_difference)?.toFixed(2)??0}
                {" hrs"}
              </Typography>
            </Stack>
          </Stack>
        ))}
      </Stack>
    </Box>
  );
};

export default TopAchievers;
