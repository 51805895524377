import { useEffect } from "react";
import useAxiosPrivate from "./useAxiosPrivate";
import { useDispatch } from "react-redux";
import { getMRPList } from "../slice/schemeSlice";

const useMRPList = () => {
  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();
  const getAllMRPList = async (param) => {

    const response = await Axios.post("mrp_list", param);
    const data = await response.data;
    dispatch(getMRPList(data.result));
  };
  return getAllMRPList;
};

export default useMRPList;
