import { getSelecetedSFdata } from "../slice/dashboardSlice";
import { getHolidayCount } from "../slice/performanceSlice";
import useAxiosPrivate from "./useAxiosPrivate";
import { useDispatch } from "react-redux";

const useHolidaysList = () => {
  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();

  const getHolidayList = async (param) => {
    try {
      const response = await Axios.post("get_holidays_count", param);
      const data = response.data.body;
      dispatch(getHolidayCount(data));
      return data;
    } catch (error) {
      console.error("Error fetching dashboard data:", error);
      throw error;
    }
  };
  return getHolidayList;
};
export default useHolidaysList;
