import { useEffect } from "react";
import useAxiosPrivate from "./useAxiosPrivate";
import { useDispatch } from "react-redux";

import { getCampaignSchemeList, getSchemeList } from "../slice/schemeSlice";

const useCampaignSchemeList = () => {
  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();
  const getSchemeZoneList = async (param) => {

    const response = await Axios.post("get_campaign_scheme_list", param);
    const data = await response.data;

    dispatch(getCampaignSchemeList(data.result));
  };
  return getSchemeZoneList;
};

export default useCampaignSchemeList;
