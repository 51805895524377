import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import { Button } from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

const ExcelDownload = ({ srList }) => {
  const downloadExcel = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("SRList");

    // Define columns for SRList data
    worksheet.columns = [
      { header: "User ID", key: "user_id", width: 10 }, // Not editable
      { header: "User Code", key: "user_code", width: 20 }, // Editable
      { header: "First Name", key: "first_name", width: 20 }, // Editable
      { header: "Last Name", key: "last_name", width: 20 }, // Editable
      { header: "Mobile", key: "mobile_no", width: 15 }, // Editable
      { header: "Address", key: "address", width: 50 }, // Editable
      { header: "Latitude", key: "lat", width: 15 }, // Editable
      { header: "Longitude", key: "lng", width: 15 }, // Editable
      { header: "SC Names", key: "sc_names", width: 20 }, // Editable (comma separated)
      { header: "Zone Name", key: "zone_name", width: 20 }, // Not editable
      { header: "Status", key: "status", width: 10 }, // Editable
      { header: "User Status", key: "user_status", width: 15 }, // Editable
    ];

    // Add data rows from srList
    srList?.forEach((sr) => {
      worksheet.addRow({
        user_id: sr.user_id,
        user_code: sr.user_code,
        first_name: sr.first_name,
        last_name: sr.last_name !== "undefined" ? sr.last_name : "", // Replace 'undefined' with an empty string
        mobile_no: sr.mobile_no,
        address: sr.address || "N/A", // Set address to 'N/A' if null
        lat: sr.lat || "N/A", // Set lat to 'N/A' if null
        lng: sr.lng || "N/A", // Set lng to 'N/A' if null
        sc_names: sr.sc_names?.join(", ") || "N/A", // Convert array to a comma-separated string
        zone_name: sr.zone_name,
        status: sr.status ? "Active" : "Inactive", // Convert boolean to string
        user_status: sr.user_status,
      });
    });

    // Unlock all cells first
    worksheet.eachRow({ includeEmpty: true }, (row) => {
      row.eachCell((cell) => {
        cell.protection = { locked: false };
      });
    });

    // Lock specific columns (1 and 10) and the headers
    worksheet
      .getColumn(1)
      .eachCell({ includeEmpty: true }, (cell, rowNumber) => {
        if (rowNumber > 1) cell.protection = { locked: true }; // Lock User ID cells
      });
    worksheet
      .getColumn(10)
      .eachCell({ includeEmpty: true }, (cell, rowNumber) => {
        if (rowNumber > 1) cell.protection = { locked: true }; // Lock Zone Name cells
      });

    // Lock header row (row 1)
    worksheet.getRow(1).eachCell({ includeEmpty: true }, (cell) => {
      cell.protection = { locked: true }; // Lock header cells
    });

    // Protect the sheet
    worksheet.protect("password", {
      selectLockedCells: false,
      selectUnlockedCells: true,
    });

    // Generate the Excel file and trigger download
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(blob, "SRList.xlsx");
  };

  return (
    <div>
      <Button
        sx={{
          textTransform: "none",
          padding: "14px 12px 14px 12px !important",
          fontSize: "14px !important",
          background: "#097524 !important",
          "&:hover": {
            background: "#097524 !important",
            border: "none !important",
          },
          border: "none !important",
        }}
        startIcon={<FileDownloadIcon />}
        onClick={downloadExcel}
      >
        Download SR List
      </Button>
    </div>
  );
};

export default ExcelDownload;
