import useAxiosPrivate from "./useAxiosPrivate";

const useSOpunchingDetails = (selectedData, zoneData) => {
  const Axios = useAxiosPrivate();

  const getSOpunchingDetails = async () => {
    let param = {
      zone_id: zoneData.zone_id,
      to: selectedData?.to,
      from: selectedData?.from,
    };
    const response = await Axios.post("so_punching_details_download", param);
    const data = await response.data;
    return data.body;
  };
  return getSOpunchingDetails;
};

export default useSOpunchingDetails;
