import { Box, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import InventoryDropdown from "./InventoryDropdown";
import InventoryTable from "./InventoryTable";
const InventoryMgt = ({setDisplay}) => {
  const [dropDown, setdropDown] = useState({});
  const [query, setQuery] = useState("");
  return (

      <Box
        sx={{
          minWidth: 140,
          display: "flex",
          flexDirection: "column",
          gap: "3rem",
          padding: "1rem",
          width: "100%",
          backgroundColor: "#fff",
          height: "fit-content",
          margin: "6px 6px 0px 0px",
          borderRadius: "8px",
        }}
      >
        <Stack
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            flexWrap: "wrap",
          }}
        >
          <Stack
            sx={{
              display: "flex",
              flexDirection: "column",
              flexWrap: "wrap",
              alignItems: "flex-start",
              gap: "2rem",
            }}
          >
            <Stack
              sx={{
                flexDirection: "row",
                alignItems: "center",
                gap: "12px",
                cursor: "pointer",
              }}
            >
              <ArrowBackIcon onClick={()=>{setDisplay("sku")}} />
              <Typography sx={{ fontWeight: 700, fontSize: "1.3rem" }}>
                Inventory
              </Typography>
            </Stack>
          </Stack>
          <Stack flexDirection="column" gap={2}>
          <InventoryDropdown setdropDown={setdropDown} setQuery={setQuery}/>
            <InventoryTable query={query} dropDownData={dropDown}/>
          </Stack>
        </Stack>
      </Box>
  );
};

export default InventoryMgt;
