import { configureStore } from "@reduxjs/toolkit";
import loginSlice from "../slice/loginSlice";
import retailerSlice from "../slice/retailerSlice";
import categorySlice from "../slice/categorySlice";
import userSlice from "../slice/userSlice";
import distributorSlice from "../slice/distributorSlice";
import saleSlice from "../slice/saleSlice";
import appSlice from "../slice/appSlice";
import routeSlice from "../slice/routeSlice";
import adminMapSlice from "../slice/adminMapSlice";
import skuSlice from "../slice/skuSlice";
import assignSEslice from "../slice/assignSEslice";
import superCategorySlice from "../slice/superCategorySlice";
import seSlice from "../slice/seSlice";
import assignRouteSlice from "../slice/assignRouteSlice";
import soSlice from "../slice/soSlice";
import srSlice from "../slice/srSlice";
import smSlice from "../slice/smSlice";
import schemeSlice from "../slice/schemeSlice";
import performanceSlice from "../slice/performanceSlice";
import dashboardSlice from "../slice/dashboardSlice";
import inventorySlice from "../slice/inventorySlice";
import adminViewSlice from "../slice/adminViewSlice";
const store = configureStore({
  reducer: {
    login: loginSlice,
    retailer: retailerSlice,
    category: categorySlice,
    user: userSlice,
    distributor: distributorSlice,
    sale: saleSlice,
    app: appSlice,
    route: routeSlice,
    assignSE: assignSEslice,
    adminMap: adminMapSlice,
    sku: skuSlice,
    superCategory: superCategorySlice,
    se: seSlice,
    assignRoute: assignRouteSlice,
    so: soSlice,
    sr: srSlice,
    sm: smSlice,
    scheme: schemeSlice,
    performance : performanceSlice,
    dashboard: dashboardSlice,
    inventory: inventorySlice,
    adminView: adminViewSlice
  },
});
export default store;
