import { useEffect } from "react";
import useAxiosPrivate from "./useAxiosPrivate";
import { useDispatch, useSelector } from "react-redux";
import { getUserList } from "../slice/userSlice";


const useUserList = () => {
  const currentzone = useSelector((state) => state.assignRoute.zone_id);
  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();
  const getAllUserList = async () => {
    if (currentzone) {
      const param = {
        zone_id: currentzone
      };
    const response = await Axios.post("/user_list", param);
    const data = await response.data;
    dispatch(getUserList(data.body));

    }
  };

  return getAllUserList;

};

export default useUserList;