import React, { useState } from 'react'
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Button, Paper, Stack, Typography } from '@mui/material';
import taskTableData from "./taskTableData.json";
import AddIcon from '@mui/icons-material/Add';
import { useSelector } from 'react-redux';
import AddInventory from './AddInventory';
import { ToastContainer } from "react-toastify";

const InventoryTable = ({ query, dropDownData }) => {
  const inventoryData = useSelector((state) => state.inventory.inventoryList);
  const [open, setOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const getInventoryStatus = (data) => {
    if (data.total_p_a === null || data.alert_pieces === null) {
      return "Out of Stock";
    } else if (data.total_p_a > data.alert_pieces) {
      return "In Stock";
    } else if (data.total_p_a === 0) {
      return "Out of Stock";
    } else if (data.total_p_a <= data.alert_pieces) {
      const text = `${data?.total_p_a} pcs left`;
      return text;
    }
  };

  function getLadiQuantity(a, b, c) {
    if (b === 0 || a === 0) {
      return 0;
    }
    let total_ladi = Math.floor(a / b);
    let total_carton = Math.floor(total_ladi / c);   
    let packed_ladis = total_carton * c;   
    let unpacked_ladi = total_ladi - packed_ladis;
    return unpacked_ladi;
  }
  function getCartonQuantity(a, b, c) {
    if (b === 0 || a === 0 || c === 0) {
      return 0;
    }
    let total_ladi = Math.floor(a / b);
    let total_carton = Math.floor(total_ladi / c);
    return total_carton;
  }
  function getPiecesQuantity(a, b, c) {
    if (b === 0 || a === 0) {
      return 0;
    }
    let total_ladi = Math.floor(a / b);
    let total_carton = Math.floor(total_ladi / c);   
    let packed_ladis = total_carton * c;
    let pieces_in_packed_ladi =   packed_ladis * b;
    let unpacked_ladi = total_ladi - packed_ladis;
    let pieces_in_unpacked_ladi =   unpacked_ladi * b;
    let unpacked_Pieces = a - (pieces_in_packed_ladi + pieces_in_unpacked_ladi);
    return unpacked_Pieces;
  }
  const handleOpen = (rowData) => {
    const updatedRowData = {
      ...rowData,
      sc_name: dropDownData.sc_name,
      dist_name: dropDownData.dist_name,
      category_ids: dropDownData.CategoryIdArray
    };
    setSelectedRow(updatedRowData);
    setOpen(true);
  };
  const filteredData = inventoryData?.filter(rowData =>
    rowData.sku_name.toLowerCase().includes(query.toLowerCase())
  );
  const handleClose = () => setOpen(false);
  return (
    <div>
      <ToastContainer
        position="bottom-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Stack
        sx={{
          backgroundColor: "#F8F8F8",
          //   paddingTop: "10px",
          //   paddingLeft: {xs:"16px", md:"32px"},
          //   paddingRight: {xs:"16px", md:"32px"},
          // paddingBottom: "32px",
        }}
      >
        <TableContainer
          style={{ height: "calc(100vh - 200px)", overflowY: "auto" }}
          elevation={0}
          component={Paper}
        >
          <Table sx={{ border: "1px solid #E8E8E8" }}>
            <TableHead
              sx={{
                color: "#9D9D9D",
                fontSize: "14px",
                fontWeight: "400",
                textAlign: "center",
                position: "sticky",
                top: 0,
                backgroundColor: "#F8F8F8", // Set background color as needed
                zIndex: 1,
              }}
            >
              <TableRow style={{ height: "20px" }}>
                <TableCell
                  sx={{
                    color: "#9D9D9D",
                    fontSize: "14px",
                    fontWeight: "400",
                    border: "none",
                  }}
                ></TableCell>
                <TableCell
                  sx={{
                    color: "#9D9D9D",
                    fontSize: "14px",
                    fontWeight: "400",
                    border: "none",
                  }}
                ></TableCell>
                <TableCell
                  colSpan={3}
                  sx={{
                    color: "#FFFFFF",
                    fontSize: "14px",
                    border: "none",
                    fontWeight: "400",
                    textAlign: "center",
                    borderRadius: "0px 0px 20px 20px",
                    backgroundColor: "#939393",
                    padding: "0px",
                  }}
                >
                  Packed Quantity left in Inventory Today
                </TableCell>
                <TableCell
                  sx={{
                    color: "#9D9D9D",
                    fontSize: "14px",
                    fontWeight: "400",
                    border: "none",
                  }}
                ></TableCell>
                <TableCell
                  colSpan={3}
                  sx={{
                    color: "#5F6868",
                    fontSize: "14px",
                    border: "none",
                    fontWeight: "400",
                    textAlign: "center",
                    borderRadius: "0px 0px 20px 20px",
                    backgroundColor: "#DBDBDB",
                    padding: "0px",
                  }}
                >
                  Quantity Last Added
                </TableCell>

                <TableCell
                  sx={{
                    color: "#9D9D9D",
                    fontSize: "14px",
                    fontWeight: "400",
                    border: "none",
                  }}
                ></TableCell>
                <TableCell
                  sx={{
                    color: "#9D9D9D",
                    fontSize: "14px",
                    fontWeight: "400",
                    border: "none",
                  }}
                ></TableCell>
              </TableRow>

              {/* Second row for individual column headings */}
              <TableRow>
                <TableCell
                  sx={{
                    color: "#5F6868",
                    fontSize: "14px",
                    fontWeight: "400",
                    textAlign: "center",
                    padding: "8px",
                  }}
                >
                  Image
                </TableCell>
                <TableCell
                  sx={{
                    color: "#5F6868",
                    fontSize: "14px",
                    fontWeight: "400",
                    textAlign: "center",
                    padding: "8px",
                  }}
                >
                  SKU Name
                </TableCell>
                <TableCell
                  sx={{
                    color: "#5F6868",
                    fontSize: "14px",
                    fontWeight: "400",
                    textAlign: "center",
                    padding: "8px",
                  }}
                >
                  Pieces
                </TableCell>
                <TableCell
                  sx={{
                    color: "#5F6868",
                    fontSize: "14px",
                    fontWeight: "400",
                    textAlign: "center",
                    padding: "8px",
                  }}
                >
                  Ladi
                </TableCell>
                <TableCell
                  sx={{
                    color: "#5F6868",
                    fontSize: "14px",
                    fontWeight: "400",
                    textAlign: "center",
                    padding: "8px",
                  }}
                >
                  Cartons
                </TableCell>
                <TableCell
                  sx={{
                    color: "#5F6868",
                    fontSize: "14px",
                    fontWeight: "400",
                    textAlign: "center",
                    padding: "8px",
                  }}
                ></TableCell>
                <TableCell
                  sx={{
                    color: "#5F6868",
                    fontSize: "14px",
                    fontWeight: "400",
                    textAlign: "center",
                    padding: "8px",
                  }}
                >
                  Cartons
                </TableCell>
                <TableCell
                  sx={{
                    color: "#5F6868",
                    fontSize: "14px",
                    fontWeight: "400",
                    textAlign: "center",
                    padding: "8px",
                  }}
                >
                  Date
                </TableCell>
                <TableCell
                  sx={{
                    color: "#5F6868",
                    fontSize: "14px",
                    fontWeight: "400",
                    textAlign: "center",
                    padding: "8px",
                  }}
                >
                  Alert pieces
                </TableCell>
                <TableCell
                  sx={{
                    color: "#5F6868",
                    fontSize: "14px",
                    fontWeight: "400",
                    textAlign: "center",
                    padding: "8px",
                  }}
                >
                  Status
                </TableCell>
                <TableCell
                  sx={{
                    color: "#5F6868",
                    fontSize: "14px",
                    fontWeight: "400",
                    textAlign: "center",
                    padding: "8px",
                  }}
                >
                  
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredData?.map((rowData, index) => (
                <React.Fragment key={index}>
                  <TableRow
                    sx={{ fontSize: "14px", fontWeight: "400" }}
                    key={index}
                  >
                    <TableCell
                      sx={{
                        fontSize: "14px",
                        fontWeight: "400",
                        textAlign: "center",
                        padding:"8px"
                        
                      }}
                    >
                      <img src={rowData.sku_image} alt="SKU image" style={{width: "32px", height: "32px"}} />
                    </TableCell>
                    <TableCell
                      sx={{
                        fontSize: "14px",
                        fontWeight: "400",
                        textAlign: "center",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        cursor: "pointer",
                        maxWidth: "100px",
                        overflow: "hidden",
                        padding:"8px"
                      }}
                      title={rowData.sku_name}
                    >
                      {rowData.sku_name}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontSize: "14px",
                        fontWeight: "400",
                        textAlign: "center",
                        backgroundColor: "rgba(231, 89, 0, 0.05)",
                        padding:"8px"
                      }}
                    >
                      {getPiecesQuantity(rowData?.total_p_a,rowData?.pieces_in_ladi, rowData?.ladi_in_carton)}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontSize: "14px",
                        fontWeight: "400",
                        textAlign: "center",
                        backgroundColor: "rgba(231, 89, 0, 0.05)",
                        padding:"8px"
                      }}
                    >
                      {getLadiQuantity(rowData?.total_p_a,rowData?.pieces_in_ladi, rowData?.ladi_in_carton)}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontSize: "14px",
                        fontWeight: "400",
                        textAlign: "center",
                        backgroundColor: "rgba(231, 89, 0, 0.05)",
                        padding:"8px"
                      }}
                    >
                      {getCartonQuantity(rowData.total_p_a, rowData?.pieces_in_ladi, rowData?.ladi_in_carton)}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontSize: "14px",
                        fontWeight: "400",
                        textAlign: "center",
                        padding:"8px"
                      }}
                    ></TableCell>
                    <TableCell
                      sx={{
                        fontSize: "14px",
                        fontWeight: "400",
                        textAlign: "center",
                        backgroundColor: "rgba(231, 89, 0, 0.05)",
                        padding:"8px"
                      }}
                    >
                      {rowData.last_updated_cartons}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontSize: "14px",
                        fontWeight: "400",
                        textAlign: "center",
                        backgroundColor: "rgba(231, 89, 0, 0.05)",
                        padding:"8px"
                      }}
                    >
                      {rowData?.formatted_updated_at}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontSize: "14px",
                        fontWeight: "400",
                        textAlign: "center",
                        backgroundColor: "rgba(231, 89, 0, 0.05)",
                        padding:"8px"
                      }}
                    >
                      {rowData?.alert_pieces}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontSize: "14px",
                        fontWeight: "400",
                        textAlign: "center",
                        padding:"8px"
                      }}
                    >
                      <Typography
                        variant="p"
                        sx={{
                          backgroundColor:
                          getInventoryStatus(rowData) === "Out of Stock"
                              ? "#E50000"
                              : getInventoryStatus(rowData) === "In Stock"
                              ? "#307F00"
                              : "#E58900",
                          color: "#fff",
                          padding: "4px 8px 4px 8px",
                          borderRadius: "100px",
                          fontSize: "14px",
                        }}
                      >
                        {getInventoryStatus(rowData)}
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        fontSize: "14px",
                        fontWeight: "400",
                        textAlign: "center",
                        padding:"8px"
                      }}
                    >
                      <Button
                        variant="outlined"
                        sx={{
                          color: "#EF6C1A !important",
                          fontWeight:"700",
                          backgroundColor: "white !important",
                          "&:hover": {
                            backgroundColor: "#EF6C1A !important",
                            color: "white !important",
                            border: "2px solid #EF6C1A !important",
                          },
                          border: "2px solid #EF6C1A !important",
                          padding: "14px 12px 14px 12px !important",
                          alignItems:"center",
                          height: "30px !important",
                          fontSize: "14px !important",
                          borderRadius: "4px !important",
                          textTransform: "none",
                        }}
                        onClick={() => handleOpen(rowData)}
                      >
                        <AddIcon fontSize='small'/>
                        Add Stock
                      </Button>
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>
      <AddInventory
        open={open}
        handleClose={handleClose}
        inputValues={selectedRow}
      />
    </div>
  );
}

export default InventoryTable