import { useEffect } from "react";
import useAxiosPrivate from "./useAxiosPrivate";
import { useDispatch, useSelector } from "react-redux";
import { getDistributorList } from "../slice/distributorSlice";
import { getSOList } from "../slice/soSlice";
import {
  getSRGraphDetails,
  getSRGraphSaleDetails,
  getSRGraphTimeDetails,
  getSRList,
} from "../slice/srSlice";

const useSRGraphSaleDetails = () => {
  const currentzone = useSelector((state) => state.assignRoute.zone_id);
  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();
  const getAllSRGraphSaleDetails = async (param) => {
    
    const response = await Axios.post("/sr_graph_sale_details_v2", param);
    const data = await response.data;
    dispatch(getSRGraphSaleDetails(data));
  };

  return getAllSRGraphSaleDetails;
};

export default useSRGraphSaleDetails;
