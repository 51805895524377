import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getEmployeeAreaList } from "../../slice/routeSlice";
import useAxiosPrivate from "../useAxiosPrivate";


const useEmployeeAreaList = () => {
  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();
  const getAllEmployeeAreaList = async (param) => {
    
    const response = await Axios.post("employee-area-list",param);
    const data = await response.data;
    dispatch(getEmployeeAreaList(data.data));
    return data.data
  };

  return getAllEmployeeAreaList
};

export default useEmployeeAreaList;
