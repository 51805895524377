import React, { useState, useEffect, useRef } from "react";
import {
  GoogleMap,
  LoadScript,
  Marker,
  InfoWindow,
  Polygon,
  Polyline,
} from "@react-google-maps/api";
import "../assests/mapStyle.css";
import { useDispatch, useSelector } from "react-redux";
import motorCycles from "../assests/motorcycle.png";
import redMarker from "../../Dashboard/assets/markersImg/red.png";
import RED from "../../Dashboard/assets/routeMarker/red.png";
import BLUE from "../../Dashboard/assets/routeMarker/blue.png";
import CYAN from "../../Dashboard/assets/routeMarker/cyan.png";
import GREEN from "../../Dashboard/assets/routeMarker/green.png";
import ORANGE from "../../Dashboard/assets/routeMarker/orange.png";
import PINK from "../../Dashboard/assets/routeMarker/pink.png";
import GRAY from "../assests/gray_marker.png";
import BROWN from "../../Dashboard/assets/routeMarker/sunday_round.png";
import { Box, Card, CardContent, Typography, Stack } from "@mui/material";
import HomeIcon from "../../Dashboard/assets/markersImg/house.png";
import infoWindowMarker from "../assests/info_window_marker.png";
import { makeStyles } from "@mui/styles";
import useSrListBySC from "../../../hooks/useSrListBySC";
import useSelectedSrRoutes from "../../../hooks/useSelectedSrRoutes";
import { Select, MenuItem } from "@mui/material";
import useSuperCategoryList from "../../../hooks/useSuperCategoryList";
import { getSrRoutes } from "../../../slice/adminViewSlice";
import useAdminMarkerView from "../../../hooks/useAdminMarkerView";
const useStyles = makeStyles((theme) => ({
  legendContainer: {
    padding: "10px",
    maxWidth: 200,
    margin: "auto",
  },
  legendItem: {
    display: "flex",
    alignItems: "center",
  },
  colorBox: {
    width: 12,
    height: 12,
    marginRight: "10px",
    borderRadius: "50%",
  },
}));
// import getSelecetedSFdata, {
//   setSelectedPincode,
// } from "../../slice/dashboardSlice";
// import useGetSelectedSFOrders from "../../hooks/useGetSelectedSFOrders";
const MapView = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const zoneData = useSelector((state) => state.app.curretDropdownData);
  const zoneList = useSelector((state) => state.app.zoneList);
  const mapData = useSelector((state) => state.dashboard.dashboardMapData);
  const adminMapData = useSelector((state) => state.adminMap.adminMapDetails);
  const srList = useSelector((state) => state.adminView.srList);
  const selectedSFdata = useSelector((state) => state.dashboard.selectedSFdata);
  const selectedSrRoutes = useSelector((state) => state.adminView.srRoutes);
  const superCategoryList = useSelector(
    (store) => store.superCategory.superCategoryList
  );
  const getSuperCategory = useSuperCategoryList();
  const [form, setForm] = React.useState({
    scIdArray: [1],
    sc_name: "Wafers",
    sc_id: 1,
  });

  // console.log(routeData, "routeData");
  const [center, setCenter] = useState({
    lat: 20.593684,
    lng: 78.96288,
  });
  const [zoomLevel, setZoomLevel] = useState(12);
  const [markerData, setMarkerData] = useState([]);
  const [map, setMap] = useState(null);
  const handleMapLoad = (map) => {
    setMap(map);
  };


  const getRetailerMarkers = useAdminMarkerView();
  useEffect(() => {
    if (zoneList) {
      const selectedZone = zoneList?.find(
        (zone) => zone.zone_id === zoneData?.zone_id
      );

      // If a matching zone is found, update the centroid state
      if (selectedZone) {
        const [lat, lng] = selectedZone.centroid.split(",").map(Number);
        setCenter({ lat, lng });
      } else {
        // Handle the case where no matching zone is found, if needed
        setCenter(null);
      }
    }
    firstApiCall();
  }, [zoneData, zoneList]);

  const firstApiCall = async() =>{
    const param = {
      zone_id: zoneData?.zone_id,
      sc_id: form.scIdArray,
    };

   const data =  await getRetailerMarkers(param);
   setMarkerData(data);
   }
  useEffect(() => {
    if (map && selectedSFdata?.route_cord?.length > 0) {
      const bounds = new window.google.maps.LatLngBounds();
      let validCoordsCount = 0;

      selectedSFdata?.route_cord?.forEach((coord) => {
        if (coord.lat !== null && coord.lng !== null) {
          bounds.extend(new window.google.maps.LatLng(coord.lat, coord.lng));
          validCoordsCount++;
        }
      });
      if (validCoordsCount > 0) {
        map.fitBounds(bounds);
      }
    }
  }, [map, selectedSFdata]);

  const handleZoomChanged = (map) => {
    const currentZoom = map?.getZoom();

    setZoomLevel(currentZoom);
  };
 
  const containerStyle = {
    width: "100%",
    height: "calc(100vh - 60px)",
    borderRadius: "10px",
    border: "1px solid black",
  };
  const mapRef = useRef(null);
  useEffect(() => {
    if (mapRef.current && markerData?.selorce_retailers) {
      const bounds = new window.google.maps.LatLngBounds();
      let hasValidLatLng = false;

      
      markerData?.selorce_retailers.forEach(({ lat, lng }) => {
          if (lat && lng) {
            bounds.extend(
              new window.google.maps.LatLng(parseFloat(lat), parseFloat(lng))
            );
            hasValidLatLng = true;
          }
        });
      
      if (hasValidLatLng) {
        mapRef.current.fitBounds(bounds);
      } else {
        const newCenter = new window.google.maps.LatLng(center);
        mapRef.current.setCenter(newCenter);
        mapRef.current.setZoom(12);
      }
    }
  }, [markerData?.selorce_retailers]);
  useEffect(() => {
    getSuperCategory();
  }, [zoneData]);
  const handleSuperCategory = async (e) => {
    let scId = 0;
    let scIdArray = [];
    let scName = null;

    // Check if "All" is selected
    if (e.target.value === "All") {
      scIdArray = [1, 2];
      scName = "All";
    } else {
      // Proceed with the existing logic
      for (let i = 0; i < superCategoryList?.length; i++) {
        if (e.target.value === superCategoryList[i]?.sc_name) {
          scId = superCategoryList[i]?.sc_id;
          scName = superCategoryList[i]?.sc_name;
          scIdArray.push(scId);
          break;
        }
      }
    }
    // dispatch(getDashboardMapViewScId([scIdArray]));
    // Update the state
    setForm((prev) => ({
      ...prev,
      sc_name: scName,
      sc_id: scId,
      scIdArray: scIdArray,
    }));

    const param = {
      zone_id: zoneData?.zone_id,
      sc_id: scIdArray,
    };

    const data = await getRetailerMarkers(param);
    setMarkerData(data);
  };
  const [selectedRetailer, setSelectedRetailer] = useState(null);

  const handleMarkerClick = (retailer) => {
    setSelectedRetailer(retailer);
  };

  const handleInfoWindowClose = () => {
    setSelectedRetailer(null);
  };
  return (
    <LoadScript googleMapsApiKey={process.env.REACT_APP_MAP_KEY}>
      <GoogleMap
        onZoomChanged={() => handleZoomChanged(map)}
        // onLoad={handleMapLoad}
        mapContainerStyle={containerStyle}
        onLoad={(map) => (mapRef.current = map)}
        center={center}
        zoom={12}
        clickableIcons={false}
        options={{
          styles: [
            {
              featureType: "poi",
              elementType: "labels",
              stylers: [{ visibility: "off" }],
            },
          ],
          zoomControl: false,
          streetViewControl: false,
        }}
      >
        <Stack
          sx={{
            position: "absolute",
            top: "10px",
            left: "208px",
            flexDirection: "row",
            gap: "4px",
            backgroundColor: "#fff",
            padding: "4px",
            borderRadius: "6px",
          }}
        >
          <Select
            onChange={handleSuperCategory}
            label="Super Category"
            sx={{ height: "1.8rem", width: "110px" }}
            value={form?.sc_name}
          >
            {superCategoryList?.map((item, index) => (
              <MenuItem value={item.sc_name} key={item.sc_id}>
                {item.sc_name}
              </MenuItem>
            ))}
          </Select>
        </Stack>

        {markerData?.selorce_retailers?.map((retailer, index) => (
        <Marker
          key={index}
          zIndex={2}
          position={{
            lat: parseFloat(retailer.lat),
            lng: parseFloat(retailer.lng),
          }}
          icon={GRAY}
          onClick={() => handleMarkerClick(retailer)}
        />
      ))}

      {markerData?.saathi_retailers?.map((retailer, index) => (
        <Marker
          key={index}
          zIndex={2}
          position={{
            lat: parseFloat(retailer.lat),
            lng: parseFloat(retailer.lng),
          }}
          icon={GREEN}
          onClick={() => handleMarkerClick(retailer)}
        />
      ))}

      {selectedRetailer && (
        <InfoWindow
          position={{
            lat: parseFloat(selectedRetailer.lat),
            lng: parseFloat(selectedRetailer.lng),
          }}
          onCloseClick={handleInfoWindowClose}
        >
          <div>
            <h3>{selectedRetailer.shop_name}</h3>
          </div>
        </InfoWindow>
      )}
      </GoogleMap>
    </LoadScript>
  );
};

export default MapView;
