import { useEffect } from "react";
import useAxiosPrivate from "./useAxiosPrivate";
import { useDispatch, useSelector } from "react-redux";
import { getUserList } from "../slice/userSlice";
import { getStateList, getZoneList } from "../slice/appSlice";


const useTopSRDashboard = () => {
  const store = useSelector((state) => state.login.refreshToken);
  const Axios = useAxiosPrivate();

  const getTopSRDashboard = async (param) => {
    if(store){
    const response = await Axios.post("top_sr_by_sales", param);
    const data = await response.data;
    return data;
    }
  };
  
  return getTopSRDashboard;  
};

export default useTopSRDashboard;