import React from "react";
import { useState, useEffect } from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Stack,
  Button,
} from "@mui/material";
import { useSelector } from "react-redux";
import useZoneDetailsList from "../../../hooks/useZoneDetailsList";
import { useNavigate } from "react-router";

const ZoneDetails = () => {
  const navigate = useNavigate();
  const [zoneData, setZoneData] = useState([]); // Initialize as an empty array
  const getZoneDetails = useZoneDetailsList();
  const currentzone = useSelector((state) => state.app.curretDropdownData);
  useEffect(() => {
    getZoneData();
  }, [currentzone?.zone_id]);

  const getZoneData = async () => {
    const data = await getZoneDetails();

    if (Array.isArray(data)) {
      setZoneData(data);
    } else {
      setZoneData([]);
    }
  };
  return (
    <div style={{ margin: "8px" }}>
      <Stack
        sx={{
          flexDirection: "row",
          gap: "4px",
          flexWrap: "wrap",
          borderRadius: "6px",
          width: "100%",
          maxHeight: "98vh",
          overflowY:"scroll",
        }}
      >
        {zoneData.length > 0 ? (
          zoneData.map((zone, index) => (
            <Stack
              key={index}
              sx={{
                width: { xs: "100%", sm: "100%", md: "46vw" }, // 100% width for small devices, 46vw for medium and up
                minWidth: "120px", // Min width for better content fitting
                margin: "0 10px",
                marginBottom: "10px",
                backgroundColor: "#fff",
                borderRadius: "10px",
                padding: "8px",
                border: "1px solid gray",
                boxShadow: "-2px 5px 16px -6px rgba(0,0,0,0.66)",
              }}
            >
              <Stack sx={{ padding: "2px" }}>
                <Typography
                  sx={{
                    fontSize: "16px",
                    textAlign: "left",
                    fontWeight: "600",
                  }}
                  variant="h6"
                >
                  {zone.zone_name}
                </Typography>
              </Stack>
              <Stack
                sx={{ flexDirection: "row", flexWrap: "wrap", gap: "4px" }}
              >
                <Stack sx={{ padding: "2px", flexDirection:"row", gap:"3px" }}>
                  <b>Dist: </b>{zone.dist_count}  |
                </Stack>
                <Stack sx={{ padding: "2px", flexDirection:"row", gap:"3px" }}><b>ASM: </b>  {zone.asm_count} |</Stack>
                <Stack sx={{ padding: "2px", flexDirection:"row", gap:"3px" }}><b>SO: </b> {zone.so_count}  | </Stack>
                <Stack sx={{ padding: "2px", flexDirection:"row", gap:"3px" }}><b>SE: </b>  {zone.se_count} |</Stack>
                <Stack sx={{ padding: "2px", flexDirection:"row", gap:"3px" }}>
                <b>Ret:</b> {zone.retailer_count}
                </Stack>
              </Stack>
            </Stack>
          ))
        ) : (
          <Typography>No zones available</Typography> // Show message if zoneData is empty
        )}
      </Stack>
      <Stack
        sx={{
          position: "absolute",
          flexDirection: "row",
          gap: "12px",
          bottom: "16px",
          left: "0",
          right: "0",
          margin: "0 auto",
          justifyContent: "center",
        }}
      > 
        <Button
          sx={{
            color: "#EF6C1A !important",
            backgroundColor: "white !important",
            "&:hover": {
              backgroundColor: "#EF6C1A !important",
              color: "white !important",
              border: "2px solid #EF6C1A !important",
            },
            border: "2px solid #EF6C1A !important",
            height: "30px !important",
            padding: "18px !important",
            fontSize: "16px !important",
            borderRadius: "5px !important",
            textTransform: "none",
          }}
          onClick={()=>navigate("/home/adminMapView")}
        >
            
          Route View
          
        </Button>
        
        <Button
          sx={{
            color: "#EF6C1A !important",
            backgroundColor: "white !important",
            "&:hover": {
              backgroundColor: "#EF6C1A !important",
              color: "white !important",
              border: "2px solid #EF6C1A !important",
            },
            border: "2px solid #EF6C1A !important",
            height: "30px !important",
            padding: "18px !important",
            fontSize: "16px !important",
            borderRadius: "5px !important",
            textTransform: "none",
          }}
          onClick={()=>navigate("/home/adminMarkerView")}
        >
          Marker View
        </Button>
      </Stack>
    </div>
  );
};

export default ZoneDetails;
