
import useAxiosPrivate from "./useAxiosPrivate";

import { ToastContainer, toast } from "react-toastify";
import { getCampaignDetails } from "../slice/schemeSlice";
import { useDispatch } from "react-redux";

const useAddCampaign = () => {
  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();
  const AddCampaign = async (param) => {

    const response = await Axios.post("add_campaign", param);
    const data = await response.data;
    dispatch(getCampaignDetails(data.body));
    
    if (data.code === 200) {
      toast.success("Added SuccessFully");
    } else if (data.code === 400) {
      toast.error(data?.message);
    } else {
      toast.error("Failed to Add");
    }
    return data;
  };
  return AddCampaign;
};

export default useAddCampaign;
