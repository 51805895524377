import { useEffect } from "react";
import useAxiosPrivate from "./useAxiosPrivate";
import { useDispatch } from "react-redux";
import { getCategoryList } from "../slice/categorySlice";
import { getSkuList, getSkuListByCategory } from "../slice/skuSlice";

const useSkuListByCategory = () => {
  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();
  const getAllSkuListByCategory = async (categoryId,scId, CategoryIdArray) => {
    
    let param={
      category_id:categoryId,
      sc_id: scId,
      CategoryIdArray:CategoryIdArray
    }
    const response = await Axios.post("/sku_list_by_category",param);
    const data = await response.data;
    dispatch(getSkuListByCategory(data.body));
    return data.body
  };
  // useEffect(() => {
  //   getAllSkuList();
  // }, []);

  return getAllSkuListByCategory
};

export default useSkuListByCategory;
