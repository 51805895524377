
import useAxiosPrivate from "./useAxiosPrivate";
import { useDispatch } from "react-redux";
import { getRouteClusterList } from "../slice/adminMapSlice";

const useCreateSaathiRoute = () => {
  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();

  const CreateRoute = async (param) => {
    try {
      const response = await Axios.post("create_saathi_route", param);
      // console.log(response, "create_route");
      const data = response.data;
      return data;  
    } catch (error) {
      console.error("Error fetching admin map data:", error);
      throw error;  
    }
  };
  return CreateRoute;
};

export default useCreateSaathiRoute;


