import React,{useEffect} from 'react'
import AddVillage from './AddVillage';
import UpdateVillageMgt from './UpdateVillage'
import VillageTable from './VillageTable';
import { useSelector,useDispatch } from 'react-redux';
// import { GetVillageList } from '../../../../actions/Route/RouteMgt';

const VillageMgt = ({rootzone,Statevalue}) => {
    const Firsttoken = useSelector(state => state.Token);
    const dispatch = useDispatch();

    useEffect(() => {
        if (Firsttoken && Firsttoken.token && Firsttoken.token !== null && Firsttoken.token.length !== 0 && Firsttoken !== "undefined") {

            let param ={
                "admin_id": "1",
                "zone_name": `${rootzone}`
            }
        //   dispatch(GetVillageList(Firsttoken.token,param))
        }
        
        
    }, [Firsttoken,rootzone])
    return (
        <div style={{display:"flex",flexDirection:"column",gap:"5px"}}>
            <VillageTable rootzone={rootzone}  Statevalue={Statevalue}/>
            <UpdateVillageMgt rootzone={rootzone}  Statevalue={Statevalue}/>
            <AddVillage rootzone={rootzone}  Statevalue={Statevalue}/>
        </div>
    )
}

export default VillageMgt
