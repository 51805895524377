
import useAxiosPrivate from "../useAxiosPrivate";
import { useDispatch } from "react-redux";

const useAddEmployeeArea = () => {
  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();

  const addEmployeeArea = async (param) => {
    try {
      const response = await Axios.post("add-employee-area", param);
      // console.log(response, "create_route");
      const data = response.data;
      return data;  
    } catch (error) {
      console.error("Error fetching admin map data:", error);
      throw error;  
    }
  };
  return addEmployeeArea;
};

export default useAddEmployeeArea;


