import React, { useState, useEffect } from "react";
import { Box } from "@material-ui/core";
import { Stack, Typography } from "@mui/material";
import { useSelector } from "react-redux";

const TopAchievers = ({workingDaysInMonth}) => {
  const [sortedSeDetails, setSortedSeDetails] = useState([]);
  const tableDetails = useSelector((store) => store.sr.SRTableDetails);
  const data = [
    {
      rank: "1st",
      color: "#E8E9BD",
    },
    {
      rank: "2nd",
      color: "#D9D9D9",
    },
    {
      rank: "3rd",
      color: "#BE9B99",
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Check if user_data exists and is an array
        if (Array.isArray(tableDetails)) {
          // Clone the array before sorting
          const userDataCopy = [...tableDetails];

          // Sort the cloned array
          const sortedDetails = userDataCopy.sort((a, b) => {
            const hourA =
              a?.total_outlet !== null || a?.total_outlet !== 0
                ? (a?.sale / a?.total_outlet) * 100
                : 0;
            const hourB =
              b?.total_outlet !== null || b?.total_outlet !== 0
                ? (b?.sale / b?.total_outlet) * 100
                : 0;
            return hourB - hourA;
          });

          setSortedSeDetails(sortedDetails);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [tableDetails]);

  return (
    <Box sx={{ width: "calc(100vw - 150px)" }}>
      <Typography>Top Achievers</Typography>
      <Stack
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          gap: "1.5rem",
          width: "100%",
          flexWrap: "wrap",
        }}
      >
        {sortedSeDetails?.slice(0, 3).map((item, index) => (
          <Stack
            key={index}
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                boxShadow: 3,
                width: "1rem",
                paddingTop: "1.7rem",
                paddingBottom: "1.7rem",
                paddingLeft: "1rem",
                paddingRight: "1rem",
                borderTopLeftRadius: "0.6rem",
                borderBottomLeftRadius: "0.6rem",
                background: data[index]?.color,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {data[index]?.rank}
            </Typography>

            <Stack
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                background: "#FFFFFF",
                gap: "0.4rem",
                boxShadow: 3,
                width: "20rem",
                padding: "1rem",
                borderTopRightRadius: "0.6rem",
                borderBottomRightRadius: "0.6rem",
              }}
            >
              <img
                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTSLU5_eUUGBfxfxRd4IquPiEwLbt4E_6RYMw&s"
                alt=""
                style={{ width: "15%", height: "15%" }}
              />
              <Typography>{item?.se_name}</Typography>
              <Typography>|</Typography>
              <Typography>
              {parseFloat(item?.total_outlet) === 0
                        ? "0"
                        : (
                            (parseFloat(item?.sale) /
                              (parseFloat(item?.total_outlet) *
                                workingDaysInMonth)) *
                            100
                          ).toFixed(2)}
                      %
              </Typography>
            </Stack>
          </Stack>
        ))}
      </Stack>
    </Box>
  );
};

export default TopAchievers;
