import { useEffect } from "react";
import useAxiosPrivate from "./useAxiosPrivate";
import { useDispatch } from "react-redux";
import {
  getAttendanceDownloadList,
  getRetailerDownloadList,
  getSkuDetailsExcelDownload,
  getSkuListExcelDownload,
} from "../slice/dashboardSlice";

const useSkuListExcelDownload = (selectedData) => {
  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();
  const getAllSkuListExcelDownload = async () => {
    let param = {
      // month: current_selected_month.month_no,
      // zone_id: zoneData.zone_id
      to: selectedData?.to,
      from: selectedData?.from,
    };
    const response = await Axios.post("/sku-excel-download", param);
    const data = await response.data;
    dispatch(getSkuListExcelDownload(data.body));
    // console.log(data);
    return data.body;
  };


  return getAllSkuListExcelDownload;
};

export default useSkuListExcelDownload;
