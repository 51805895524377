import { useEffect } from "react";
import useAxiosPrivate from "./useAxiosPrivate";
import { useDispatch } from "react-redux";
import { getCategoryList, getCategoryListBySuperCategory } from "../slice/categorySlice";

const useCategoryListBySuperCategory = () => {
  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();
  const getAllCategoryListBySuperCategory = async (scId) => {
    let param={
      sc_id:scId
    }
    const response = await Axios.post("/category_list_by_super_category",param);
    const data = await response.data;
    dispatch(getCategoryListBySuperCategory(data.body));
    // console.log(data);
  };
  // useEffect(() => {
  //   getAllCategoryListBySuperCategory();
  // }, [scId]);

  return getAllCategoryListBySuperCategory;
};

export default useCategoryListBySuperCategory;
