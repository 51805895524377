
import useAxiosPrivate from "../useAxiosPrivate";
import { useDispatch} from "react-redux";

import { ToastContainer, toast } from "react-toastify";
const useSecondarySales = () => {
  
  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();
  const getSecondarySalesReport = async (param) => {
    
    const response = await Axios.post("download_daily_secondary_sales",param);
    const data = await response.data;
    

    if (data?.code === 200) {
      toast.success(data?.message);
    } else {
      toast.error(data?.message);
    }
    return data;
    };

  return getSecondarySalesReport;
};

export default useSecondarySales;
