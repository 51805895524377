import { getSrRoutes } from "../slice/adminViewSlice";
import useAxiosPrivate from "./useAxiosPrivate";
import { useDispatch, useSelector } from "react-redux";


const useAdminMarkerView = () => {
  const Axios = useAxiosPrivate();
  const getAdminMarkerView = async (param) => {

    const response = await Axios.post("/admin_marker_view", param);
    
    const data = await response.data;

    return data.body;

  };

  return getAdminMarkerView;
};

export default useAdminMarkerView;
