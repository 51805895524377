import { useEffect } from "react";
import useAxiosPrivate from "./useAxiosPrivate";
import { useDispatch } from "react-redux";
import { getSaleDetails } from "../slice/saleSlice";

const useSaleDetails = () => {
  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();

  const getSalesTableData = async (param) => {
    const response = await Axios.post("/sale_details", param);
    const data = response.data;
    dispatch(getSaleDetails(data.body));
    console.log(data);
  };

  return getSalesTableData;
};

export default useSaleDetails;
