import { useEffect } from "react";
import useAxiosPrivate from "./useAxiosPrivate";
import { useDispatch, useSelector } from "react-redux";
import { getDistributorList } from "../slice/distributorSlice";
import { getSOList } from "../slice/soSlice";
import { getSRGraphDetails, getSRList } from "../slice/srSlice";

const useSRGraphDetails = () => {
  const currentzone = useSelector((state) => state.assignRoute.zone_id);
  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();
  const getAllSRGraphDetails = async (param) => {
    // console.log(param, "param");
    const response = await Axios.post("/sr_graph_call_details_v2", param);
    const data = await response.data;
    dispatch(getSRGraphDetails(data));
  };
  // useEffect(() => {
  //   getAllSRGraphDetails();
  // }, []);

  return getAllSRGraphDetails;
};

export default useSRGraphDetails;
