import useAxiosPrivate from "./useAxiosPrivate";
import { useDispatch } from "react-redux";


const useSelectedRetailerList = () => {
  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();
  const getretailersList = async (param) => {

    const response = await Axios.post("selected_retailer_list", param);
    const data = await response.data;
    return data;
    // dispatch(getSchemeList(data.result));
  };
  return getretailersList;
};

export default useSelectedRetailerList;
