import React, { useState } from "react";
import styles from "./styles/UserMgtMidContainerTwo.module.css";
import Button from "@mui/material/Button";
import { withStyles } from "@mui/styles";
import { styled } from "@mui/material/styles";
import { red, purple, white } from "@mui/material/colors";
import { style } from "@mui/system";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { IconButton, InputAdornment, TextField } from "@material-ui/core";
// import { GetProductListRetailer, GetUserMgtRetailerList } from '../../../actions/UserMgt/UserMgt';
import { useSelector, useDispatch } from "react-redux";
import UserRetailerMgtTable from "./UserMgtRetailerTable";
import SearchIcon from "@mui/icons-material/Search";
import { useEffect } from "react";
import UploadPopup from './UploadPopup';
import { Stack } from "@mui/material";

const CustomMenuItem = styled(MenuItem)(({ theme }) => ({}));

const RedButton = styled(Button)(({ theme }) => ({
  color: "white",
  backgroundColor: "#EF6C1A",
  "&:hover": {
    backgroundColor: "#EF6C1A",
  },
}));

const UserMgtMidContainerTwo = ({
  setTsiZone,
  setRouteId,
  setCatId,
  setProductId,
  Statevalue,
}) => {
  // const stateList = useSelector(state => state.Zone.StateList);
  // const StateID = stateList.filter(state => state.state_name === Statevalue)
  const dispatch = useDispatch();
  const UserMgt = useSelector((state) => state.UserMgt);
  // const [zonelist, setZonelist] = React.useState([]);
  const Firsttoken = useSelector((state) => state.Token);
  const [status, setStatus] = useState(true); 

  const handleChange = (event) => {
    const value = event.target.value === 'Active' ? true : false;
    setStatus(value);
  };
  const [token, setToken] = useState("");
  // const list = useSelector(state => state.Zone.list);
  const [q, setQ] = useState("");

  const [route, setRoute] = useState("All");
  const [category, setCategory] = useState("All");
  const [RouteList, setRouteList] = useState([]);
  const [searchColumn, setSearchColumn] = useState([
    "retailer_id",
    "name",
    "surname",
    "shop_name",
    "mobile_no",
  ]);
  // const rows = useSelector(state => state.UserMgt.retailerlist);
  const [categoryList, setCategoryList] = useState([]);
  const [salesCategory, setSalesCategory] = useState("All");
  const [productList, setProductList] = useState(["All"]);
  const [product, setProduct] = useState("All");
  const [selectedShapeId, setSelectedShapeId] = useState("");

  const retailerAllList = useSelector((store) => store.retailer.retailerList);


  React.useEffect(() => {
    if (UserMgt?.routeList) {
      let r = [];
      r.push({ route_code: "All", route_id: "" });
      for (let i = 0; i < UserMgt.routeList.length; i++) {
        r.push(UserMgt.routeList[i]);
      }
      setRouteList(r);
    }
    if (UserMgt?.categoryList) {
      let r = [];
      r.push({ rc_name: "All", rc_id: "" });
      for (let i = 0; i < UserMgt.categoryList.length; i++) {
        r.push(UserMgt.categoryList[i]);
      }
      setCategoryList(r);
    }
  }, [UserMgt]);

  // useEffect(() => {
  //     console.log(UserMgt?.productList,"UserMgt?.productList")
  //     if (UserMgt?.productList && UserMgt?.productList?.length!==0) {
  //         let r = [];
  //         r.push({ product_name: "All", product_id: "" });
  //         for (let i = 0; i < UserMgt.productList.length; i++) {
  //             r.push(UserMgt.productList[i])
  //         }
  //         console.log(r, "productList1")
  //         setProductList(r);
  //     }
  // }, [UserMgt.productList])

  React.useEffect(() => {
    if (
      Firsttoken &&
      Firsttoken.token &&
      Firsttoken.token !== null &&
      Firsttoken.token.length !== 0 &&
      Firsttoken !== "undefined"
    ) {
      setToken(Firsttoken.token);
    }
  }, [Firsttoken]);



  const handleRouteChange = (event) => {
    setRoute(event.target.value);

    for (let i = 0; i < RouteList.length; i++) {
      let code = event.target.value;

      if (code === RouteList[i].route_code) {
        setRouteId(RouteList[i].route_id);
      }
    }
  };

  const handleCategoryChange = (event) => {
    setCategory(event.target.value);

    for (let i = 0; i < categoryList.length; i++) {
      let code = event.target.value;

      if (code === categoryList[i].rc_name) {
        setCatId(categoryList[i].rc_id);
      }
    }
  };

  // const handleZoneChange = (event) => {
  //     setTsiZone(event.target.value);
  // };

  const Searchfilter = (datas) => {
    return datas.filter((row) =>
      searchColumn.some((column) =>
        row[column] !== null &&
        row[column] !== "undefined" &&
        row[column].length !== 0
          ? row[column].toString().toLowerCase().indexOf(q.toLowerCase()) > -1
          : ""
      )
    );
  };

  const fileName = "Retailers";
  const exportType = "csv";
  // const handleDownload = () => {
  //     let data = rows

  //     exportFromJSON({ data, fileName, exportType })

  // }

  const SalesArr = ["All", "No Visit", "No Sale", "1 Sale", "1+ Sale"];

  const handleSalesChange = (e) => {
    setSalesCategory(e.target.value);
  };
  const handleProductChange = (e) => {
    // console.log(e.target.value, "handleProductChange");
    const ProductId = productList?.filter(
      (product) => product.product_name === e.target.value
    );
    setProduct(e.target.value);
    setProductId(ProductId[0]?.product_id);
  };

  const [query, setQuery] = useState("");
  const handleSearch = (e) => {
    setQuery(e.target.value);
  };
  const [filterRetailerList, setFilterRetailerList] = useState(null);

  useEffect(() => {
    if (retailerAllList) {
      setFilterRetailerList(retailerAllList);
    }
  }, [retailerAllList]);
  useEffect(() => {
    const filteredData = retailerAllList?.filter((retailer) => {
      const fullName =
        `${retailer?.first_name} ${retailer?.last_name}`.toLowerCase();
      return (
        fullName.includes(query?.toLowerCase()) ||
        retailer?.mobile_no?.toLowerCase().includes(query?.toLowerCase()) ||
        retailer?.shop_name?.toLowerCase().includes(query?.toLowerCase()) ||
        retailer?.zone_name?.toLowerCase().includes(query?.toLowerCase()) ||
        retailer?.address?.toLowerCase().includes(query?.toLowerCase())
      );
    });

    setFilterRetailerList(filteredData);
  }, [query]);
  useEffect(() => {
    const filteredData = selectedShapeId
      ? retailerAllList.filter((item) => item.shape_id === selectedShapeId)
      : retailerAllList;
    setFilterRetailerList(filteredData);
  }, [selectedShapeId]);

  // console.log(filterRetailerList, "filterRetailerList");


  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className={styles.MidContainer}>
      <div className={styles.MidContainerOne}>
        <div className={styles.MidSubContainerOne}>

           <Stack sx={{width:"100%", justifyContent:"space-between", flexDirection:"row"}}>
            <div className={styles.MidSubContainerOneList}>
              <TextField
                variant="outlined"
                sx={{
                  width: "300px",
                  "& .MuiOutlinedInput-root": {
                    padding: "0px 4px", // Adjust padding for height reduction
                  },
                  "& .MuiInputBase-input": {
                    fontSize: "0.875rem", // Decrease font size
                    lineHeight: "1.2", // Adjust line height
                  },
                }}
                size="small"
                value={query}
                onChange={handleSearch}
                placeholder="Search User"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton>
                        <SearchIcon /> {/* Assume SearchIcon is imported */}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <FormControl
              fullWidth
              size="small"
              variant="outlined"
              sx={{
                minWidth: 140,
                maxWidth: 140,
                "& .MuiOutlinedInput-root": { padding: "0px 4px" },
                padding: "8.5px 14px !important",
                    "& .MuiOutlinedInput-root": {
                      padding: "0px 4px !important",
                    },
                    "& .MuiOutlinedInput-input":{
                      padding: "8.5px 14px !important",
                    }
              }}
            >
              <Select
                labelId="status-label"
                value={status ? "Active" : "Inactive"}
                onChange={handleChange}
                label="Status"
                sx={{
                  fontSize: "0.875rem", // Decrease font size for Select
                }}
              >
                <MenuItem value="Active">Active</MenuItem>
                <MenuItem value="Inactive">Inactive</MenuItem>
              </Select>
            </FormControl>
            </div>
            {/* <div className={styles.MidSubContainerOneList}>
              <Button
                variant="outlined"
                color="primary"
                onClick={handleOpen}
                sx={{
                  color: "white !important",
                  backgroundColor: "#EF6C1A !important",
                  "&:hover": {
                    backgroundColor: "#EF6C1A !important",
                  },
                  borderRadius: "8px !important",
                  padding: "18px 10px !important",
                }}
              >
                Upload excel
              </Button>
              <UploadPopup open={open} onClose={handleClose} />
            </div> */}
            </Stack>
        </div>
      </div>
      <div className={styles.MidContainerTwo}>
        <UserRetailerMgtTable
          filterList={filterRetailerList}
          Searchfilter={Searchfilter}
          salesCategory={salesCategory}
          status={status}
        />
      </div>
    </div>
  );
};

export default UserMgtMidContainerTwo;
