import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
  name: "user",
  initialState: {
    userList: null,
    userDetails: null,
    userUpdatedDetails: null
  },
  reducers: {
    getUserList: (state, action) => {
      state.userList = action.payload;
    },
    getUserDetails: (state, action) => {
      state.userDetails = action.payload;
    },
    updateUserDetails: (state,action)=>{
        state.userUpdatedDetails=action.payload
    }
  },
});

export default userSlice.reducer;
export const { getUserList, getUserDetails,updateUserDetails } = userSlice.actions;
