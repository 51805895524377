import React, { useState } from "react";
import Styles from './styles/sales.module.css';
import SalesMidContainer from "./SalesMidContainer";
import { convertDate } from "../Functions/function";
import { useSelector } from "react-redux";
import SalesTopContainer from "./SalesTopContainer";
import Sidebar from "../Sidebar";
import useSaleDetails from "../../hooks/useSaleDetails";
import Typography from '@mui/material/Typography'

const SalesManagement = ({ zone,Statevalue }) => {
    const Firsttoken = useSelector(state => state.Token)
    const currentZoneId = useSelector((state) => state.app.curretDropdownData?.zone_id);
    const [token, setToken] = useState('');
    const [selectedStartDate, setSelectedStartDate] = useState(new Date(new Date().setDate(new Date().getDate() - 1)));
    const [selectedEndDate, setSelectedEndDate] = useState(new Date());

    const handleStartDateChange = (date) => {
        if (date > selectedEndDate) {
            alert("Start date cannot be later than end date.");
        } else {
            setSelectedStartDate(date);
        }
    };

    const handleEndDateChange = (date) => {
        if (date < selectedStartDate) {
            alert("End date cannot be earlier than start date.");
        } else {
            setSelectedEndDate(date);
        }
    };
    
    const [id, setId] = useState({
        "route_id": "",
        "product_id": "",
        "seller_id": ""
    })

    React.useEffect(() => {
        if (Firsttoken && Firsttoken.token && Firsttoken.token !== null && Firsttoken.token.length !== 0 && Firsttoken !== "undefined") {
            setToken(Firsttoken.token)
        }

    }, [Firsttoken])
    const getSalesTableData = useSaleDetails();
    React.useEffect(() => {
    const data = {
        zone_id:currentZoneId,
        from:convertDate(selectedStartDate), 
        to:convertDate(selectedEndDate)
    }
    getSalesTableData(data);
    }, [selectedStartDate, selectedEndDate, currentZoneId ]);

    const IdChange = (RID, PID, SID) => {
        setId({
            ...id,
            "route_id": `${RID}`,
            "product_id": `${PID}`,
            "seller_id": `${SID}`
        })
    }

    const Submit = (RID, PID, SID,check) => {
    }
   
    return (
        <div style={{display:"flex"}}>
            <Sidebar/>        
        <div className={Styles.SalesContainer}>
            
            <div className={Styles.TopContainer}>
            <Typography variant="h1" sx={{fontSize:"28px"}}>Sales Management</Typography>
                <SalesTopContainer selectedStartDate={selectedStartDate} handleStartDateChange={handleStartDateChange} selectedEndDate={selectedEndDate} handleEndDateChange={handleEndDateChange}/>
            </div>

            <SalesMidContainer IdChange={IdChange} Submit={Submit} Statevalue={Statevalue} zone={zone}/>
        </div>
        </div>
    )
}

export default SalesManagement
