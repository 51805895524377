import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { Autocomplete, Button, FormControl, MenuItem, Select, Stack, TextField, Typography } from '@mui/material';
import { makeStyles, styled } from '@mui/styles';
import { createTheme } from '@mui/system';
import './styles/zonemgt.scss';
import InputLabel from '@mui/material/InputLabel';
import { LoadingButton } from '@mui/lab';
import UpdateZoneMgt from './UpdateZoneMgt';
import { useDispatch, useSelector } from 'react-redux';
// import { PerformanceStampDailyReportByZone } from '../../../actions/DailyReports/dailyReport';
// import { AddZoneByState, GetZoneByStateId } from '../../../actions/ZoneMgt/zoneMgtAction';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const theme = createTheme({
    palette: {
        primary: {
            main: "#fff",
        },
        secondary: {
            main: "#000"
        }

    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            ipm: 768,

            md: 960,
            lg: 1280,
            xl: 1920,
        },
    },
});


export const AddLoadingButton = styled(LoadingButton)(({ theme }) => ({
    padding: "25px 25px",
    fontWeight: "bold",
    color: "white",
    backgroundColor: "#731D3A",
    border: "2px solid #731D3A",
    width: "100px",
    height: "40px",
    display: "flex",
    alignItems: "center",
    '&:hover': {
        color: "white",
        backgroundColor: "#731D3A",
        border: "2px solid #731D3A",
    },
}));

const useStyles = makeStyles({
    content: {
        // marginLeft:window.innerWidth < '960'? 0 : 250,
        marginLeft: 0,
        flexGrow: 1,
        padding: "5px 5px",
        paddingRight: "15px",
        backgroundColor: "#E5E5E5",
        height: "100%",
        minHeight: "100vh"

    },

    appBar: {
        position: "fixed",
        height: 60,
        backgroundColor: "#731D3A",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between"
    },
    navIconHide: {
        [theme.breakpoints.up("md")]: {
            display: "none"
        }
    },
    userWrapper: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        marginRight: "-10px",
        gap: "5px"

    },
    userContainer: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between"
    },
    toolbar: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between"

    },
    autocomplete: {
        color: "#000",
        [theme.breakpoints.between("xs", "md")]: {
            width: 100,
            fontSize: "10px"
        },
        [theme.breakpoints.up("md")]: {
            width: 165,
        }
    },
    autocompleteContainer: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: "15px"
    },
    loaderdiv: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        width: "100%"
    },
    zoneEmpty: {
        // display: "flex",
        // flexDirection: "row",
        // justifyContent: "center",
        // width: "100%"
    },
    zoneEmptyTitle: {
        fontSize: "50px",
        color: "black",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh"
    }
});

export default function ZoneMgt() {
    const Firsttoken = useSelector(state => state.Token)
    const dispatch = useDispatch()
    const classes = useStyles();
    const [loading, setLoading] = React.useState(false)
    const [Stateoptions, setStateoptions] = React.useState(["Ahm"]);
    const [Statevalue, setStateValue] = React.useState('');
    const [StateinputValue, setStateInputValue] = React.useState('');
    const [zone, setZone] = React.useState('')
    // const stateList = useSelector(state => state.Zone.StateList);
    const [error, setError] = React.useState({});
    const [token, setToken] = React.useState('');

    const handleStateChange = (e) => {
        setStateValue(e.target.value)
    }


    React.useEffect(() => {
        if (Firsttoken && Firsttoken.token && Firsttoken.token !== null && Firsttoken.token.length !== 0 && Firsttoken !== "undefined") {
            setToken(Firsttoken.token);

        }

    }, [Firsttoken])

    const IsValid = () => {
        let success = true;
        let error = {}

        if (!zone?.trim()) {
            error.zone = '**Please type a zone**';
            success = false
        }

        setError(error);
        return success
    }

    const handleClick = async (e) => {
        e.preventDefault();
        console.log(IsValid(), "IsValid()")
        if (IsValid()) {
            setLoading(true);

            let state_id = 0;
            // for (let i = 0; i < stateList.length; i++) {
            //     if (Statevalue === stateList[i]?.state_name) {
            //         state_id = stateList[i]?.state_id
            //     }
            // }

            let param = {
                "zone_name": zone,
                "status": true,
                "state_id": state_id,
                "frequency": 0,
                "lati": "",
                "longi": "",
                "is_category": false,
                "route_id": 0
            }

            ///Api Call For Add

            // const data = await dispatch(AddZoneByState(token, param))

            // if (data?.code === 200) {
            //     setLoading(false);
            //     setZone('');
            //     for (let i = 0; i < stateList.length; i++) {
            //         if (stateList[i].state_name === Statevalue) {
            //             let param = {
            //                 "state_id": stateList[i].state_id
            //             }
            //             dispatch(GetZoneByStateId(token, param))
            //         }
            //     }

            //     toast.success('Zone Added SuccessFully')
            // }
            // else {
            //     setLoading(false)
            //     toast.error(data?.message)
            // }
        }
    }


    // React.useEffect(() => {

    //     if (stateList == "undefined" || stateList == null || stateList.length == 0) {

    //         setStateValue('');
    //         return console.log('State List Error')
    //     }

    //     let i = 0;
    //     let arr = [];
    //     setStateValue(stateList[0].state_name);
    //     for (i = 0; i < stateList.length; i++) {
    //         arr.push(stateList[i].state_name)
    //     }
    //     setStateoptions(arr)
    // }, [stateList])




    return (
        <>
            <ToastContainer
                position="bottom-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />

            <div className="ZoneMgtContainer">


                <Stack
                    direction={{ xs: "column" }}


                >

                    <Stack
                        direction={{ xs: "column" }}
                        component={Paper}
                        padding={1}
                        spacing={2}
                    >


                        <Stack direction="row" spacing={1} padding={2}>
                            <Typography  sx={{ fontWeight: "bold" }}>Select State</Typography>
                            <FormControl fullWidth sx={{ minWidth: 220,maxWidth: 220 }}>
                                <Select
                                    value={Statevalue}
                                    onChange={handleStateChange}
                                    size="small"
                                // label="Age"
                                >
                                    {
                                        Stateoptions && Stateoptions.map((item, index) => (
                                            <MenuItem value={item} key={index}>{item}</MenuItem>
                                        ))
                                    }
                                </Select>
                            </FormControl>
                        </Stack>
                        <Typography variant="h6" component="h6" paddingLeft={2} sx={{ fontWeight: "bold" }}>Add Zone</Typography>

                        <form onSubmit={handleClick}>
                            <Stack
                                direction={{ xs: "column", md: "row" }}
                                padding={1}
                                alignItems="flex-start"
                                spacing={2}
                            >


                                <Stack direction="row" spacing={1} paddingLeft={2}>
                                    <Typography sx={{ fontWeight: "bold" }}>Zone</Typography>
                                    <TextField size="small" error={error?.zone ? true : false} helperText={error?.zone ? error?.zone : ''} name="zone" value={zone} onChange={(e) => setZone(e.target.value)} />

                                </Stack>

                                <AddLoadingButton
                                    type="submit"
                                    loading={loading}
                                    variant="contained"
                                    size="small"
                                >
                                    Add
                                </AddLoadingButton>

                            </Stack>
                        </form>

                    </Stack>

                </Stack>

                <UpdateZoneMgt token={token} state={Statevalue} />
            </div>
        </>
    );
}