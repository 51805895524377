import { createSlice } from "@reduxjs/toolkit";

const loginSlice = createSlice({
  name: "login",
  initialState: {
    loginData: null,
    loginCredential: null,
    refreshToken: null,
  },
  reducers: {
    getLoginData: (state, action) => {
      state.loginData = action.payload;
    },
    getLoginCredential: (state, action) => {
      state.loginCredential = action.payload;
    },
    getRefreshToken: (state, action) => {
      state.refreshToken = action.payload;
    },
  },
});

export default loginSlice.reducer;
export const { getLoginData, getLoginCredential, getRefreshToken } =
  loginSlice.actions;
