import React from 'react'
import styles from "./styles/EmployeeAreaBottomContainer.module.css"
import UpdateEmployeeArea from './UpdateEmployeeArea'
import AddEmployeeArea from './AddEmployeeArea'

const EmployeeAreaBottomContainer = ({rootzone}) => {
    return (
        <div className={styles.RouteMgtBottomContainer}>
            <UpdateEmployeeArea rootzone={rootzone}/>

            <div className={styles.RouteMgtBottomAddContainer}>
                <AddEmployeeArea rootzone={rootzone}/>
            </div>
        </div>
    )
}

export default EmployeeAreaBottomContainer
