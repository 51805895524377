import React, { useState, useEffect, useRef } from "react";
import {
  GoogleMap,
  LoadScript,
  Marker,
  InfoWindow,
  Polygon,
  Polyline,
} from "@react-google-maps/api";
import "../assests/mapStyle.css";
import { useDispatch, useSelector } from "react-redux";
import motorCycles from "../assests/motorcycle.png";
import redMarker from "../../Dashboard/assets/markersImg/red.png";
import RED from "../../Dashboard/assets/routeMarker/red.png";
import BLUE from "../../Dashboard/assets/routeMarker/blue.png";
import CYAN from "../../Dashboard/assets/routeMarker/cyan.png";
import GREEN from "../../Dashboard/assets/routeMarker/green.png";
import ORANGE from "../../Dashboard/assets/routeMarker/orange.png";
import PINK from "../../Dashboard/assets/routeMarker/pink.png";
import GRAY from "../assests/gray_marker.png";
import BROWN from "../../Dashboard/assets/routeMarker/sunday_round.png";
import { Box, Card, CardContent, Typography, Stack } from "@mui/material";
import HomeIcon from "../../Dashboard/assets/markersImg/house.png";
import infoWindowMarker from "../assests/info_window_marker.png";
import { makeStyles } from "@mui/styles";
import useSrListBySC from "../../../hooks/useSrListBySC";
import useSelectedSrRoutes from "../../../hooks/useSelectedSrRoutes";
import { Select, MenuItem } from "@mui/material";
import useSuperCategoryList from "../../../hooks/useSuperCategoryList";
import { getSrRoutes } from "../../../slice/adminViewSlice";
const useStyles = makeStyles((theme) => ({
  legendContainer: {
    padding: "10px",
    maxWidth: 200,
    margin: "auto",
  },
  legendItem: {
    display: "flex",
    alignItems: "center",
  },
  colorBox: {
    width: 12,
    height: 12,
    marginRight: "10px",
    borderRadius: "50%",
  },
}));
// import getSelecetedSFdata, {
//   setSelectedPincode,
// } from "../../slice/dashboardSlice";
// import useGetSelectedSFOrders from "../../hooks/useGetSelectedSFOrders";
const MapView = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const zoneData = useSelector((state) => state.app.curretDropdownData);
  const zoneList = useSelector((state) => state.app.zoneList);
  const mapData = useSelector((state) => state.dashboard.dashboardMapData);
  const adminMapData = useSelector((state) => state.adminMap.adminMapDetails);
  const srList = useSelector((state) => state.adminView.srList);
  const selectedSFdata = useSelector((state) => state.dashboard.selectedSFdata);
  const selectedSrRoutes = useSelector((state) => state.adminView.srRoutes);
  const superCategoryList = useSelector(
    (store) => store.superCategory.superCategoryList
  );
  const getSuperCategory = useSuperCategoryList();
  const [form, setForm] = React.useState({
    scIdArray: [1],
    sc_name: "Wafers",
    sc_id: 1,
  });
  const [routeData, setRouteData] = useState([]);
  useEffect(() => {
    const newrouteData = selectedSFdata?.route_cord || [];
    setRouteData(newrouteData);
  }, [selectedSFdata]);
  // console.log(routeData, "routeData");
  const [center, setCenter] = useState({
    lat: 20.593684,
    lng: 78.96288,
  });
  const [zoomLevel, setZoomLevel] = useState(12);
  const [selectedUser, setSelectedUser] = useState(null);
  const [map, setMap] = useState(null);
  const handleMapLoad = (map) => {
    setMap(map);
  };
  useEffect(() => {
    dispatch(getSrRoutes([]));
    setSelectedUser(null);
  }, [form.sc_name, zoneData?.zone_id]);
  const [selectedPolygon, setSelectedPolygon] = useState(null);
  const markerIconsByDay = {
    monday: RED,
    tuesday: GREEN,
    wednesday: BLUE,
    thursday: ORANGE,
    friday: CYAN,
    saturday: PINK,
    sunday: BROWN,
  };

  const daysOfWeekColors = {
    monday: "#FF0000",
    tuesday: "#00FF00",
    wednesday: "#0000FF",
    thursday: "#ff8c00",
    friday: "#17EAE3",
    saturday: "#C139C3",
    sunday: "#7A0D0D",
  };
  const getSrListBySc = useSrListBySC();
  useEffect(() => {
    if (zoneList) {
      const selectedZone = zoneList?.find(
        (zone) => zone.zone_id === zoneData?.zone_id
      );

      // If a matching zone is found, update the centroid state
      if (selectedZone) {
        const [lat, lng] = selectedZone.centroid.split(",").map(Number);
        setCenter({ lat, lng });
      } else {
        // Handle the case where no matching zone is found, if needed
        setCenter(null);
      }
    }
    const param = {
      zone_id: zoneData?.zone_id,
      sc_id: form.scIdArray,
    };

    getSrListBySc(param);
  }, [zoneData, zoneList]);

  useEffect(() => {
    if (map && selectedSFdata?.route_cord?.length > 0) {
      const bounds = new window.google.maps.LatLngBounds();
      let validCoordsCount = 0;

      selectedSFdata?.route_cord?.forEach((coord) => {
        if (coord.lat !== null && coord.lng !== null) {
          bounds.extend(new window.google.maps.LatLng(coord.lat, coord.lng));
          validCoordsCount++;
        }
      });
      if (validCoordsCount > 0) {
        map.fitBounds(bounds);
      }
    }
  }, [map, selectedSFdata]);

  const handleZoomChanged = (map) => {
    // Get the current zoom level
    const currentZoom = map?.getZoom();

    // Update the state with the new zoom level
    setZoomLevel(currentZoom);
  };

  const containerStyle = {
    width: "100%",
    height: "calc(100vh - 60px)",
    borderRadius: "10px",
    border: "1px solid black",
  };
  const getSrRoute = useSelectedSrRoutes();
  const onSrClick = async (user) => {
    setSelectedUser(user.first_name);
    const param = {
      sr_id: user.user_id,
      sc_id: form.scIdArray,
    };
    await getSrRoute(param);
  };
  const mapRef = useRef(null);
  useEffect(() => {
    if (mapRef.current && selectedSrRoutes?.route_list) {
      const bounds = new window.google.maps.LatLngBounds();
      let hasValidLatLng = false;

      selectedSrRoutes?.route_list?.forEach((route) => {
        route?.route_cord?.forEach(({ lat, lng }) => {
          if (lat && lng) {
            bounds.extend(
              new window.google.maps.LatLng(parseFloat(lat), parseFloat(lng))
            );
            hasValidLatLng = true;
          }
        });
      });
      if (hasValidLatLng) {
        mapRef.current.fitBounds(bounds);
      } else {
        const newCenter = new window.google.maps.LatLng(center);
        mapRef.current.setCenter(newCenter);
        mapRef.current.setZoom(12);
      }
    }
  }, [selectedSrRoutes?.route_list]);
  useEffect(() => {
    getSuperCategory();
  }, [zoneData]);
  const handleSuperCategory = async (e) => {
    let scId = 0;
    let scIdArray = [];
    let scName = null;

    // Check if "All" is selected
    if (e.target.value === "All") {
      scIdArray = [1, 2];
      scName = "All";
    } else {
      // Proceed with the existing logic
      for (let i = 0; i < superCategoryList?.length; i++) {
        if (e.target.value === superCategoryList[i]?.sc_name) {
          scId = superCategoryList[i]?.sc_id;
          scName = superCategoryList[i]?.sc_name;
          scIdArray.push(scId);
          break;
        }
      }
    }
    // dispatch(getDashboardMapViewScId([scIdArray]));
    // Update the state
    setForm((prev) => ({
      ...prev,
      sc_name: scName,
      sc_id: scId,
      scIdArray: scIdArray,
    }));

    const param = {
      zone_id: zoneData?.zone_id,
      sc_id: scIdArray,
    };

    await getSrListBySc(param);
  };

  return (
    <LoadScript googleMapsApiKey={process.env.REACT_APP_MAP_KEY}>
      <GoogleMap
        onZoomChanged={() => handleZoomChanged(map)}
        // onLoad={handleMapLoad}
        mapContainerStyle={containerStyle}
        onLoad={(map) => (mapRef.current = map)}
        center={center}
        zoom={12}
        clickableIcons={false}
        options={{
          styles: [
            {
              featureType: "poi",
              elementType: "labels",
              stylers: [{ visibility: "off" }],
            },
          ],
          zoomControl: false,
          streetViewControl: false,
        }}
      >
        <Stack
          sx={{
            position: "absolute",
            top: "10px",
            left: "208px",
            flexDirection: "row",
            gap: "4px",
            backgroundColor: "#fff",
            padding: "4px",
            borderRadius: "6px",
          }}
        >
          <Select
            onChange={handleSuperCategory}
            label="Super Category"
            sx={{ height: "1.8rem", width: "110px" }}
            value={form?.sc_name}
          >
            {/* Adding "All" manually */}
            {/* <MenuItem value="All">All</MenuItem> */}
            {/* Mapping over other options */}
            {superCategoryList?.map((item, index) => (
              <MenuItem value={item.sc_name} key={item.sc_id}>
                {item.sc_name}
              </MenuItem>
            ))}
          </Select>
        </Stack>
        {/* {tabValue === "2" ? ( */}
        <Stack
          sx={{
            position: "absolute",
            top: { xs: "60px", md: "60px", lg: "10px" },
            right: { xs: "auto", md: "auto", lg: "70px" },
            left: { xs: "10px", md: "10px", lg: "auto" },
            flexDirection: "row",
            gap: "4px",
            backgroundColor: "#fff",
            padding: "4px",
            borderRadius: "6px",
          }}
        >
          {Object.entries(daysOfWeekColors).map(([day, color]) => (
            <div key={day} className={classes.legendItem}>
              <div
                className={classes.colorBox}
                style={{ backgroundColor: color }}
              />
              <Typography variant="body1">
                {window.innerWidth < 600 ? day.substring(0, 3) : day}
              </Typography>
            </div>
          ))}
        </Stack>
        {/* ) : null} */}
        <Stack
          sx={{
            position: "absolute",
            bottom: "10px",
            left: "10px",
            flexDirection: "row",
            gap: "4px",
            // backgroundColor: "#fff",
            padding: "4px",
            borderRadius: "6px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              overflowX: "scroll",
              width: "100%",
              maxWidth: "calc(100vw - 40px)",
              scrollbarWidth: "none", // Hide scrollbar in Firefox
              "&::-webkit-scrollbar": {
                display: "none", // Hide scrollbar in Chrome
              },
            }}
          >
            {srList?.map((user, index) => (
              <Stack
                key={index}
                sx={{
                  width: "fit-content",
                  minWidth: "160px",
                  margin: "0 10px",
                  backgroundColor:
                    selectedUser === user?.first_name ? "#FF990088" : "#fff",
                  borderRadius: "10px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  onSrClick(user);
                }}
              >
                <Stack sx={{ padding: "8px" }}>
                  <Typography
                    sx={{ fontSize: "14px", textAlign: "left" }}
                    variant="h6"
                  >
                    {" "}
                    {user.first_name.slice(0, 12)}
                  </Typography>
                  <Stack flexDirection="row">
                    <Typography
                      sx={{ fontSize: "14px", marginRight: "4px" }}
                      variant="body2"
                    >
                      <b>{user.calls_count}</b> Calls
                    </Typography>
                    {" | "}
                    <Typography
                      sx={{ fontSize: "14px", marginLeft: "4px" }}
                      variant="body2"
                    >
                      <b>{user.retailer_count}</b> Retl.
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
            ))}
          </Box>
        </Stack>
        {/* {polygonData?.map((coordinates, index) => (
          <Polygon
            key={index}
            paths={coordinates}
            options={{
              fillColor: "#ccdbcf",
              fillOpacity: 0.4,
              strokeColor:
                selectedPolygon === adminMapData?.pincodes[index]?.pin_code
                  ? "#000"
                  : "#3483eb",
              strokeOpacity: 1,
              strokeWeight:
                selectedPolygon === adminMapData?.pincodes[index]?.pin_code
                  ? 4
                  : 2,
              clickable: false,
            }}
            // onClick={() =>
            //   handlePolygonClick(
            //     adminMapData?.pincodes[index]?.pin_code,
            //     adminMapData?.pincodes[index]?.shape_id,
            //     coordinates,
            //     mapData.sf_details[index]
            //   )
            // }
          />
        ))} */}
        {/* {zoomLevel > 12 ? (
          <>
            {mapData?.sf_details?.map((data, index) => (
              <Marker
                icon={{
                  url: infoWindowMarker,
                }}
                key={index}
                position={{
                  lat: parseFloat(data.lat),
                  lng: parseFloat(data.lng),
                }}
                // onClick={() => setSelectedMarker(data)}
              />
            ))}

            {mapData?.sf_details?.map((data, index) => (
              <InfoWindow
                key={index}
                position={{
                  lat: parseFloat(data.lat),
                  lng: parseFloat(data.lng),
                }}
                // onCloseClick={() => setSelectedMarker(null)}
                options={{
                  disableAutoPan: true,
                }}
              >
                <div className="info-container">
                  <div className="marker-label">
                    <div
                      style={{
                        fontWeight: "400",
                        color: "#000",
                        fontSize: "12px",
                      }}
                    >
                      {data?.assignedSR[0]?.first_name}
                    </div>
                    <div
                      style={{
                        fontWeight: "400",
                        color: "#626262",
                        fontSize: "12px",
                      }}
                    >
                      {data?.retailers_count} R |{" "}
                      {data?.active_time_details[0]?.time_spent?.hours} hrs | ₹{" "}
                      {data?.total_sales}
                    </div>
                  </div>
                </div>
              </InfoWindow>
            ))}
          </>
        ) : null} */}

        {adminMapData?.retailers_list?.map((retailer, index) => (
          <Marker
            key={index}
            zIndex={2}
            position={{
              lat: parseFloat(retailer.lat),
              lng: parseFloat(retailer.lng),
            }}
            icon={GRAY}
          />
        ))}
        {/* {routeData?.map((coordinate, index) => (
          <React.Fragment key={index}>
            {index < routeData.length - 1 && (
              <React.Fragment>
                <Polyline
                  path={[
                    {
                      lat: parseFloat(coordinate.lat),
                      lng: parseFloat(coordinate.lng),
                    },
                    {
                      lat: parseFloat(routeData[index + 1].lat),
                      lng: parseFloat(routeData[index + 1].lng),
                    },
                  ]}
                  options={{
                    zIndex: 100,
                    strokeWeight: 3,
                    strokeColor: coordinate.completed ? "#009C19" : "#E40303",
                    icons: [
                      {
                        icon: {
                          path: "M 0,-1 0,1",
                          scale: 2,
                        },
                        offset: "0",
                        repeat: "20px",
                      },
                    ],
                  }}
                />
                {coordinate.completed && !routeData[index + 1].completed && (
                  <Marker
                    position={{
                      lat:
                        (parseFloat(coordinate.lat) +
                          parseFloat(routeData[index + 1].lat)) /
                        2,
                      lng:
                        (parseFloat(coordinate.lng) +
                          parseFloat(routeData[index + 1].lng)) /
                        2,
                    }}
                    icon={motorCycles}
                  />
                )}
              </React.Fragment>
            )}

            <Marker
              position={{
                lat: parseFloat(coordinate.lat),
                lng: parseFloat(coordinate.lng),
              }}
              zIndex={100}
              icon={coordinate.completed ? GREEN : RED}
            />
          </React.Fragment>
        ))} */}
        <div>
          {selectedSrRoutes?.route_list?.map((route, routeIndex) => (
            <React.Fragment key={routeIndex}>
              {route.route_cord?.length > 0 && (
                <React.Fragment>
                  <Polyline
                    path={[
                      {
                        lat: parseFloat(route?.se_home_lat),
                        lng: parseFloat(route?.se_home_lng),
                      },
                      ...route.route_cord?.map((coordinate) => ({
                        lat: parseFloat(coordinate.lat),
                        lng: parseFloat(coordinate.lng),
                      })),
                    ]}
                    options={{
                      strokeWeight: 2,
                      strokeColor: daysOfWeekColors[route.week[0]],
                      icons: [
                        {
                          icon: {
                            path: "M 0,-1 0,1",
                            scale: 2,
                          },
                          offset: "0",
                          repeat: "20px",
                        },
                      ],
                    }}
                  />
                  <Marker
                    position={{
                      lat: parseFloat(route.se_home_lat),
                      lng: parseFloat(route.se_home_lng),
                    }}
                    label={"S"} // You can customize the label as needed
                    icon={markerIconsByDay[route.week[0]]}
                  />
                </React.Fragment>
              )}
              {selectedSrRoutes?.route_list?.map((route, routeIndex) => (
                <React.Fragment key={routeIndex}>
                  {route.route_cord?.length > 0 && (
                    <React.Fragment>
                      <Polyline
                        path={[
                          {
                            lat: parseFloat(route.se_home_lat),
                            lng: parseFloat(route.se_home_lng),
                          },
                          {
                            lat: parseFloat(route.route_cord[0].lat),
                            lng: parseFloat(route.route_cord[0].lng),
                          },
                        ]}
                        options={{
                          strokeWeight: 2,
                          strokeColor: daysOfWeekColors[route.week[0]],
                          icons: [
                            {
                              icon: {
                                path: "M 0,-1 0,1",
                                scale: 2,
                              },
                              offset: "0",
                              repeat: "20px",
                            },
                          ],
                        }}
                      />
                      <Marker
                        position={{
                          lat: parseFloat(route.se_home_lat),
                          lng: parseFloat(route.se_home_lng),
                        }}
                        label={"S"}
                        icon={HomeIcon}
                      />
                    </React.Fragment>
                  )}
                  {route.route_cord?.map((coordinate, index) => (
                    <React.Fragment key={index}>
                      {index < route.route_cord.length - 1 && (
                        <React.Fragment>
                          <Polyline
                            path={[
                              {
                                lat: parseFloat(coordinate.lat),
                                lng: parseFloat(coordinate.lng),
                              },
                              {
                                lat: parseFloat(
                                  route.route_cord[index + 1].lat
                                ),
                                lng: parseFloat(
                                  route.route_cord[index + 1].lng
                                ),
                              },
                            ]}
                            options={{
                              strokeWeight: 3,
                              strokeColor: daysOfWeekColors[route.week[0]],
                              icons: [
                                {
                                  icon: {
                                    path: "M 0,-1 0,1",
                                    scale: 2,
                                  },
                                  offset: "0",
                                  repeat: "20px",
                                },
                              ],
                            }}
                          />
                          {/* {index < route.route_cord.length - 1 &&
                            coordinate.completed &&
                            !route.route_cord[index + 1].completed && (
                              <Marker
                                position={{
                                  lat:
                                    (parseFloat(coordinate.lat) +
                                      parseFloat(
                                        route.route_cord[index + 1].lat
                                      )) /
                                    2,
                                  lng:
                                    (parseFloat(coordinate.lng) +
                                      parseFloat(
                                        route.route_cord[index + 1].lng
                                      )) /
                                    2,
                                }}
                                icon={motorCycles}
                              />
                            )} */}
                        </React.Fragment>
                      )}
                      <Marker
                        position={{
                          lat: parseFloat(coordinate.lat),
                          lng: parseFloat(coordinate.lng),
                        }}
                        icon={markerIconsByDay[route.week[0]]}
                      />
                    </React.Fragment>
                  ))}
                </React.Fragment>
              ))}
            </React.Fragment>
          ))}
        </div>
      </GoogleMap>
    </LoadScript>
  );
};

export default MapView;
