import React from "react";
import BarGraph from "./BarGraph";
import { Box } from "@material-ui/core";
import { Stack } from "@mui/material";
import TopAchievers from "./TopAchievers";
import PotentialGrowth from "./PotentialGrowth";
import TimeStampTable from "./TimeStampTable";
import useSRGraphTimeDetails from "../../../hooks/useSRGraphTimeDetails";

const TimeStamp = () => {
  // useSRGraphTimeDetails();
  return (
    <Stack sx={{ display: "flex", flexDirection: "column", gap: "1.5rem" }}>
      {/* <TopAchievers /> */}
      <BarGraph />
      {/* <PotentialGrowth /> */}
      <TimeStampTable />
    </Stack>
  );
};

export default TimeStamp;
