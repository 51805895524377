import React, { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { makeStyles } from "@mui/styles";
import styles from "./styles/usermgttable.module.css";
import { styled } from "@mui/material/styles";
import { red, purple, white } from "@mui/material/colors";
import Button from "@mui/material/Button";
import { useSelector, useDispatch } from "react-redux";

import {
  getRetailerList,
  getRetailerUserDetails,
} from "../../slice/retailerSlice";
import useRetailerList from "../../hooks/useRetailerList";
import useRetailerDetails from "../../hooks/useRetailerDetails";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#E5E5E5",
    color: "black",
    fontSize: "14px",
    fontWeight: "600",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

//   const StyledbodyTableCell = styled(TableCell)(({ theme }) => ({
//     [`&.${tableCellClasses.head}`]: {
//       backgroundColor: theme.palette.common.black,
//       color: theme.palette.common.white,
//     },
//     [`&.${tableCellClasses.body}`]: {
//       fontSize: 14,
//     },
//   }));

const RedButton = styled(Button)(({ theme }) => ({
  color: "#EF6C1A !important",
  backgroundColor: "white !important",
  "&:hover": {
    backgroundColor: "#EF6C1A !important",
    color: "white !important",
    border: "2px solid #EF6C1A !important",
  },
  border: "2px solid #EF6C1A !important",
  height: "30px !important",
  fontSize: "12px !important",
  borderRadius: "5px !important",
}));

const useStyles = makeStyles({
  head: {
    borderBottom: "2px solid black",
  },
});

const UserMgtRetailerTable = ({ filterList, Searchfilter, salesCategory, status }) => {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  // const rows = useSelector(state => state.UserMgt.retailerlist);
  // const Id = useSelector(state => state.UserMgt.retailerid);
  const dispatch = useDispatch();
  const currentzone = useSelector((state) => state.assignRoute.zone_id);
  const retailerList = useSelector((store) => store.retailer?.retailerList);
  const getRetailerList =  useRetailerList();
  // console.log(retailerList);
  useEffect(() =>{
    getRetailerList(status);
  }, [currentzone, status]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset page to 0 when changing rowsPerPage
  };

  const columns = [
    // { id: "zone", label: "Shape Id", minWidth: 130 },
    { id: "usercode", label: "Retailer code", minWidth: 100 },
    { id: "sc_name", label: "Super Category name", minWidth: 100 },
    { id: "name", label: "OwnerName", minWidth: 100 },
    { id: "surname", label: "ShopName", minWidth: 100 },
    // { id: "sr_name", label: "SR", minWidth: 100 },
    // { id: "so_name", label: "SO", minWidth: 100 },
    // { id: "asm_name", label: "ASM", minWidth: 100 },
    // { id: "dist_name", label: "Distributor", minWidth: 100 },
    { id: "mobile", label: "Ph No", minWidth: 100 },
    { id: "status", label: "Status", minWidth: 100 },
    { id: "action", label: "Action", minWidth: 100 },
  ];
  const getRetailerDetails = useRetailerDetails();
  const handleClick = async(row) => {
    
    const param = {
      retailer_id : row?.retailer_id
    }
    const data = await getRetailerDetails(param);
    dispatch(getRetailerUserDetails(data.body));
    document
      .getElementById("UserMgtRetailerDetail")
      .scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
  };

  return (
    <div style={{ width: "100%", marginTop: "10px" }}>
      <TableContainer sx={{ maxHeight: 700, minWidth: "100%" }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead
            className={styles.head}
            sx={{ backgroundColor: "#c4c4c4" }}
          >
            <TableRow
              className={styles.head}
              sx={{ backgroundColor: "#c4c4c4" }}
            >
              {columns.map((column) => (
                <StyledTableCell
                  key={column.id}
                  align="center"
                  // style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {filterList && filterList.length !== 0 ? (
              <>
                {Searchfilter(filterList)
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        key={index}
                        sx={
                          parseInt(row.visit_c) == 0
                            ? { backgroundColor: "white" }
                            : parseInt(row.visit_c) > 0 &&
                              parseInt(row.sale_c) == 0
                            ? { backgroundColor: "#FFDFDB" }
                            : parseInt(row.visit_c) > 0 &&
                              parseInt(row.sale_c) == 1
                            ? { backgroundColor: "#FEFFB4" }
                            : parseInt(row.visit_c) > 0 &&
                              parseInt(row.sale_c) > 1
                            ? { backgroundColor: "#E4FFC5" }
                            : null
                        }
                      >
                        <>
                          {/* <TableCell align="center">{row.shape_id}</TableCell> */}
                          <TableCell align="center">
                            {row.retailer_code}
                          </TableCell>
                          <TableCell align="center">{row.sc_name}</TableCell>
                          <TableCell align="center">
                            {row.first_name}
                          </TableCell>
                          <TableCell align="center">{row.shop_name}</TableCell>
                          {/* <TableCell align="center">{row.se_name}</TableCell>
                          <TableCell align="center">{row.so_name}</TableCell>
                          <TableCell align="center">{row.asm_name}</TableCell>
                          <TableCell align="center">{row.dist_name}</TableCell> */}
                          <TableCell align="center">{row.mobile_no}</TableCell>
                          <TableCell align="center">
                            {row.status === true ? "Active" : "Inactive"}
                          </TableCell>
                          <TableCell align="center">
                            <RedButton
                              variant="outlined"
                              onClick={() => handleClick(row)}
                            >
                              Details
                            </RedButton>
                          </TableCell>
                        </>
                      </TableRow>
                    );
                  })}
              </>
            ) : (
              <TableRow>
                <TableCell>No Data</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={filterList?.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: "200px", 
              backgroundColor:"#000 !important"
            },
          },
        }}
      />
    </div>
  );
};

export default UserMgtRetailerTable;
