import React, { useState, useEffect } from "react";
import { Card, FormGroup, Stack, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import FormControl from "@mui/material/FormControl";
import Divider from "@mui/material/Divider";
import { TailSpin } from "react-loader-spinner";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import useSuperCategoryList from "../../hooks/useSuperCategoryList";
import { getDashboardData } from "../../slice/appSlice";
import useDashboardMapData from "../../hooks/useDashboardMapData";
import useMainDashboardData from "../../hooks/useMainDashboardData";
import { getdashboardData } from "../../slice/dashboardSlice";
import useAdminMap from "../../hooks/useAdminMap";
import { getAdminMapDetails } from "../../slice/adminMapSlice";
import Stepper from "@mui/material/Stepper";
import { Box, Step, StepLabel } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import { styled } from "@mui/material/styles";
import useSelectedSrOrders from "../../hooks/useSelectedSrOrders";

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  // height: "calc(100% + 30px)", // Increase height to overflow
  // minHeight: "calc(100% + 30px)",
  height: 60,
  "& .MuiStepConnector-line": {
    height: "60px",
    minHeight: "60px",
    borderColor: "#000",
  },
}));

const DashboardSideCard = () => {
  const dispatch = useDispatch();
  const zoneData = useSelector((state) => state.app.curretDropdownData);
  const mapData = useSelector((state) => state.app.dashboardData);
  const adminMap = useSelector((state) => state.adminMap.adminMapDetails);
  const selectedPincode = useSelector(
    (state) => state.dashboard.selectedPincode
  );
  const selectedSFdata = useSelector((state) => state.dashboard.selectedSFdata);
  const superCategory = useSelector(
    (state) => state.superCategory.superCategoryList
  );

  const [expanded, setExpanded] = useState(false);
  const [selectedSCId, setSelectedSCId] = useState([1]);
  const [selectedSR, setSelectedSR] = useState();
  const [showSrRoute, setShowSrRoute] = useState(false);
  const getSuperCategory = useSuperCategoryList();
  const DashboardData = useDashboardMapData();
  const dashboardMapData = useMainDashboardData();
  const getAdminMapData = useAdminMap();

  useEffect(() => {
    if (!zoneData || !zoneData.zone_id) return; // Don't proceed if zone_id is not available
    const fetchData = async () => {
      const param = {
        zone_id: zoneData.zone_id,
        sc_id: selectedSCId,
      };
      try {
        // Fetch data here, assuming useMainDashboardData() fetches data asynchronously
        const data = await dashboardMapData(param);
        dispatch(getdashboardData(data));
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [zoneData]);

  useEffect(() => {
    if (zoneData?.zone_id) {
      getData();
    }
  }, [zoneData, selectedSCId]);

  useEffect(() => {
    getSuperCategory();
  }, [zoneData]);

  const getData = async () => {
    const param = {
      zone_id: zoneData?.zone_id,
      sc_id: selectedSCId,
    };

    try {
      const [mapData, retailersData, dashboardData] = await Promise.all([
        dashboardMapData(param),
        getAdminMapData(param),
        DashboardData(param),
      ]);

      // Dispatch actions after all API calls are completed
      dispatch(getdashboardData(mapData));
      dispatch(getAdminMapDetails(retailersData));
      dispatch(getDashboardData(dashboardData));
    } catch (error) {
      // Handle errors
      console.error("Error fetching data:", error);
    }
  };

  const handleAccChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const handleSCRadioChange = (scId) => {
    setSelectedSCId([scId]);
    // dispatch(setScId([scId]));
  };

  const formatTime = (timestamp) => {
    const date = new Date(timestamp);
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    return `${hours}:${minutes}`;
  };
  const getStepTimeDifference = (time1, time2) => {
    const diffInMs = Math.abs(new Date(time2) - new Date(time1));
    const minutes = Math.floor(diffInMs / 60000);
    return `${minutes}`; // Using <br/> to break into next line
  };
  const getSrRoutes = useSelectedSrOrders();
  const handleCategoryClick = async (se) => {
    setSelectedSR(se);
    const param = {
      se_id: se,
    };
    await getSrRoutes(param);
    setShowSrRoute(true);
  };
  const goBack = () => {
    setShowSrRoute(false);
  };
  return (
    <div>
      <Card
        sx={{
          minWidth: "280px",
          width: { xs: "92%", lg: "300px" },
          minHeight: "75vh",
          display: "flex",
          flexDirection: "column",
          gap: "0.5rem",
          padding: "16px 10px",
          borderRadius: "12px",
          height: { xs: "fit-content", lg: "calc(100vh - 90px)" },
          position: "relative",
        }}
      >
        <Stack sx={{ paddingLeft: "0.8rem" }} gap={0.5}>
          <Typography
            variant="P"
            color="initial"
            sx={{
              fontSize: "18px",
              fontWeight: "700",
              textAlign: "start",
            }}
          >
            {showSrRoute ? selectedPincode : zoneData?.zone_name}
          </Typography>
          {showSrRoute ? (
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: "400",
                textAlign: "start",
                lineHeight: "18px",
              }}
            >
              Today
            </Typography>
          ) : (
            <Typography
              variant="P"
              color="initial"
              sx={{
                fontSize: "14px",
                fontWeight: "400",
                textAlign: "start",
                lineHeight: "18px",
              }}
            >
              <span style={{ color: "#189903", fontWeight: "700" }}>
                {adminMap?.no_of_shapeFiles}
              </span>{" "}
              / {adminMap?.pincodes?.length} Shape files Enabled
            </Typography>
          )}
        </Stack>
        <Divider
          variant="middle"
          sx={{ paddingTop: "6px", borderBottom: "2px Solid #BABABA" }}
        />
        {showSrRoute === true ? (
          <Box
            sx={{
              height: "90%",
              overflowY: "scroll",
              /* Hide scrollbar for Firefox */
              scrollbarWidth: "none",
              /* Hide scrollbar for other browsers */
              "::-webkit-scrollbar": {
                display: "none",
              },
              width: "100%",
            }}
          >
            <Stack
              sx={{
                padding: " 10px 16px",
                flexDirection: "column",
                gap: "12px",
              }}
            >
              <Stack sx={{ flexDirection: "row", gap: "12px" }}>
                <ArrowBackIcon sx={{ cursor: "pointer" }} onClick={goBack} />
                <Typography
                  sx={{
                    fontWeight: "500",
                    fontSize: "20px",
                    textAlign: "start",
                  }}
                >
                  List of Retailers
                </Typography>
              </Stack>
              <Stepper
                orientation="vertical"
                activeStep={-1}
                connector={<QontoConnector />}
              >
                {selectedSFdata?.route_cord?.map((step, index, array) => (
                  <Step
                    key={index}
                    completed={step.completed}
                    sx={{
                      "& .MuiStepIcon-root": {
                        color: step.completed
                          ? "#009C19 !important"
                          : "#E40303",
                        width: "22px",
                        height: "22px",
                      },
                      "& .MuiStepIcon-text": { display: "none" },
                      position: "relative",
                    }}
                  >
                    <StepLabel
                      sx={{ "& .MuiStepIcon-text": { display: "none" } }}
                    >
                      <Stack
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems:"center",
                          justifyContent: "center",
                          gap: "0.3rem",
                          position: "absolute",
                          left: "35px", // Adjust as needed
                          top: "-32px",
                          transform: "translateY(-50%)",
                          zIndex: "1",
                        }}
                      >
                        {step?.distance_to_prev ? (
                          <>
                            {/* <Divider
                                  orientation="vertical"
                                  flexItem
                                  sx={{ borderColor: "rgba(0, 0, 0, 0.5)" }}
                                /> */}
                            <Typography
                              variant="body2"
                              sx={{
                                fontSize: "12px",
                                color: "#060606",
                              }}
                            >
                              {step?.distance_to_prev
                                ? `${step.distance_to_prev.toFixed(1)} km`
                                : ""}
                            </Typography>
                          </>
                        ) : null}
                        {step.completed && <Typography sx={{ fontSize: "12px" }}>|</Typography>}
                        {step.completed && array[index - 1]?.completed_at && (
                          <Typography
                            variant="body2"
                            sx={{
                              fontSize: "12px",
                              color: "#060606",
                              // position: "absolute",
                              // left: "80px", // Adjust as needed
                              // top: "-32px",
                              // transform: "translateY(-50%)",
                              // zIndex: "1",
                            }}
                          >
                            {getStepTimeDifference(
                              step.completed_at,
                              array[index - 1].completed_at
                            )}{" "}
                            {getStepTimeDifference(
                              step.completed_at,
                              array[index - 1].completed_at
                            ) < 2
                              ? "min"
                              : "mins"}
                          </Typography>
                        )}
                      </Stack>
                      <div
                        style={{
                          display: "flex",
                          gap: "8px",
                          flexDirection: "column",
                        }}
                      >
                        <Stack
                          sx={{
                            width: "100%",
                            justifyContent: "space-between",
                            flexDirection: "row",
                          }}
                        >
                          <Typography
                            variant="body1"
                            sx={{ fontWeight: "700" }}
                          >
                            {step.shop_name}
                          </Typography>
                          <Typography
                            variant="body1"
                            sx={{
                              fontWeight: "400",
                              fontSize: "12px",
                              alignSelf: "center",
                            }}
                          >
                            {step.completed_at && formatTime(step.completed_at)}
                          </Typography>
                        </Stack>

                        {step.completed && (
                          <Stack sx={{ flexDirection: "row", gap: "5px" }}>
                            <Typography
                              variant="body2"
                              sx={{
                                fontWeight: "700",
                                borderRadius: "12px",
                                fontSize: "12px",
                                backgroundColor:
                                  step.sale_status === "Sale"
                                    ? "#DCFFE2"
                                    : "#FFDCDC",
                                padding: "3px 8px",
                              }}
                            >
                              {step.sale_status}
                            </Typography>

                            {step.sale_status === "Sale" ? (
                              <>
                                <Divider
                                  orientation="vertical"
                                  flexItem
                                  sx={{ borderColor: "rgba(0, 0, 0, 0.5)" }}
                                />
                                <Typography variant="body2">
                                  ₹{step.sale_amount}
                                </Typography>
                              </>
                            ) : null}
                          </Stack>
                        )}
                      </div>
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Stack>
          </Box>
        ) : (
          <>
            <Stack
              sx={{
                textAlign: "left",
                paddingLeft: "0.8rem",
                flexDirection: "row",
                padding: "10px 0px",
                marginLeft: "0.3rem",
              }}
            >
              {superCategory?.map((data, index) => (
                <Stack sx={{ flexDirection: "row" }} key={index}>
                  <Radio
                    value={data.sc_id}
                    sx={{
                      height: "18px",
                      alignSelf: "center",
                      "& .MuiSvgIcon-root": {
                        color: "#1C1B1F",
                      },
                    }}
                    checked={
                      Array.isArray(selectedSCId)
                        ? selectedSCId.includes(data.sc_id)
                        : selectedSCId === data.sc_id
                    }
                    onChange={() => handleSCRadioChange(data.sc_id)}
                  />

                  <label>{data?.sc_name}</label>
                </Stack>
              ))}
            </Stack>
            <Stack
              sx={{
                height: "90%",
                overflowY: "scroll",
                /* Hide scrollbar for Firefox */
                scrollbarWidth: "none",
                /* Hide scrollbar for other browsers */
                "::-webkit-scrollbar": {
                  display: "none",
                },
              }}
            >
              <Stack>
                {/* <Accordion
                  expanded={expanded === "panel1"}
                  onChange={handleAccChange("panel1")}
                  elevation={0}
                  sx={{ padding: "0" }}
                >
                  <AccordionSummary
                    expandIcon={<ArrowDropDownIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                    sx={{ padding: "0" }}
                  >
                    <Stack
                      sx={{
                        display: "flex",
                        gap: "18px",
                        paddingLeft: "0.8rem",
                        flexDirection: "row",
                        marginBottom: "6px",
                      }}
                    >
                      <Typography sx={{ fontSize: "18px", fontWeight: "600" }}>
                        Distributors
                      </Typography>
                    </Stack>
                  </AccordionSummary>
                  <AccordionDetails sx={{ padding: "0", textAlign: "start" }}>
                    <FormControl
                      sx={{
                        padding: "0px 10px",
                        minHeight: "26vh",
                        height: "38%",
                        width: "90%",
                        maxHeight: "30vh",
                        overflowY: "scroll",
                      }}
                    >
                      {mapData === null ? (
                        <Stack
                          sx={{
                            width: "100%",
                            height: "100%",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <TailSpin
                            visible={true}
                            height="40"
                            width="40"
                            color="#EF6C1A"
                            ariaLabel="tail-spin-loading"
                            radius="1"
                            wrapperStyle={{}}
                            wrapperClass=""
                          />
                        </Stack>
                      ) : (
                        <FormGroup>
                          <Stack sx={{ width: "100%", gap: "10px" }}>
                            {mapData?.distributers_list?.map((data, index) => (
                              <Stack
                                sx={{ width: "100%", gap: "10px" }}
                                key={data.dist_id}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    textAlign: "start",
                                    fontSize: "16px",
                                    fontWeight: "500",
                                  }}
                                >
                                  {data.dist_name}{" "}
                                  <span
                                    style={{
                                      fontWeight: "300",
                                      fontSize: "14px",
                                    }}
                                  >
                                    {data.dist_code}
                                  </span>
                                </div>
                              </Stack>
                            ))}
                          </Stack>
                        </FormGroup>
                      )}
                    </FormControl>
                  </AccordionDetails>
                </Accordion> */}
                {/* <Divider
                  variant="middle"
                  sx={{ borderBottom: "1px Solid black" }}
                /> */}
                <Stack>
                  {/* <Accordion
                    expanded={expanded === "panel2"}
                    onChange={handleAccChange("panel2")}
                    elevation={0}
                    sx={{ padding: "0" }}
                  >
                    <AccordionSummary
                      expandIcon={<ArrowDropDownIcon />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                      sx={{ padding: "0" }}
                    >
                      <Stack
                        sx={{
                          display: "flex",
                          gap: "18px",
                          paddingLeft: "0.8rem",
                          flexDirection: "row",
                          marginBottom: "6px",
                        }}
                      >
                        <Typography
                          sx={{ fontSize: "18px", fontWeight: "600" }}
                        >
                          ASM
                        </Typography>
                      </Stack>
                    </AccordionSummary>
                    <AccordionDetails sx={{ padding: "0", textAlign: "start" }}>
                      <FormControl
                        sx={{
                          padding: "0px 10px",
                          minHeight: "26vh",
                          height: "38%",
                          width: "90%",
                          maxHeight: "30vh",
                          overflowY: "scroll",
                        }}
                      >
                        {mapData === null ? (
                          <Stack
                            sx={{
                              width: "100%",
                              height: "100%",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <TailSpin
                              visible={true}
                              height="40"
                              width="40"
                              color="#EF6C1A"
                              ariaLabel="tail-spin-loading"
                              radius="1"
                              wrapperStyle={{}}
                              wrapperClass=""
                            />
                          </Stack>
                        ) : (
                          <FormGroup>
                            <Stack sx={{ width: "100%", gap: "10px" }}>
                              {mapData?.asm_list?.map((data, index) => (
                                <Stack sx={{ width: "100%", gap: "10px" }}>
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      textAlign: "start",
                                      fontSize: "14px",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {data.first_name}
                                  </div>
                                  <span
                                    style={{
                                      fontWeight: "300",
                                      fontSize: "14px",
                                    }}
                                  >
                                    {data.user_code}
                                  </span>
                                </Stack>
                              ))}
                            </Stack>
                          </FormGroup>
                        )}
                      </FormControl>
                    </AccordionDetails>
                  </Accordion> */}
                  {/* <Divider
                    variant="middle"
                    sx={{ borderBottom: "1px Solid black" }}
                  /> */}
                  <Stack>
                    {/* <Accordion
                      expanded={expanded === "panel3"}
                      onChange={handleAccChange("panel3")}
                      elevation={0}
                      sx={{ padding: "0" }}
                    >
                      <AccordionSummary
                        expandIcon={<ArrowDropDownIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                        sx={{ padding: "0" }}
                      >
                        <Stack
                          sx={{
                            display: "flex",
                            gap: "18px",
                            paddingLeft: "0.8rem",
                            flexDirection: "row",
                            marginBottom: "6px",
                          }}
                        >
                          <Typography
                            sx={{ fontSize: "18px", fontWeight: "600" }}
                          >
                            SO
                          </Typography>
                        </Stack>
                      </AccordionSummary>
                      <AccordionDetails
                        sx={{ padding: "0", textAlign: "start" }}
                      >
                        <FormControl
                          sx={{
                            padding: "0px 10px",
                            minHeight: "26vh",
                            height: "38%",
                            width: "90%",
                            maxHeight: "30vh",
                            overflowY: "scroll",
                          }}
                        >
                          {mapData === null ? (
                            <Stack
                              sx={{
                                width: "100%",
                                height: "100%",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <TailSpin
                                visible={true}
                                height="40"
                                width="40"
                                color="#EF6C1A"
                                ariaLabel="tail-spin-loading"
                                radius="1"
                                wrapperStyle={{}}
                                wrapperClass=""
                              />
                            </Stack>
                          ) : (
                            <FormGroup>
                              <Stack sx={{ width: "100%", gap: "10px" }}>
                                {mapData?.so_list?.map((data, index) => (
                                  <Stack sx={{ width: "100%", gap: "10px" }}>
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        textAlign: "start",
                                        fontSize: "14px",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {data?.first_name}
                                    </div>
                                    <span
                                      style={{
                                        fontWeight: "300",
                                        fontSize: "14px",
                                      }}
                                    >
                                      {data.user_code}
                                    </span>
                                  </Stack>
                                ))}
                              </Stack>
                            </FormGroup>
                          )}
                        </FormControl>
                      </AccordionDetails>
                    </Accordion> */}

                    <Divider
                      variant="middle"
                      sx={{ borderBottom: "1px Solid black" }}
                    />
                    <Stack
                    // sx={{ height: "45%" }}
                    >
                      <Accordion
                        expanded={expanded === "panel4"}
                        onChange={handleAccChange("panel4")}
                        sx={{ padding: "0" }}
                        elevation={0}
                      >
                        <AccordionSummary
                          expandIcon={<ArrowDropDownIcon />}
                          aria-controls="panel2bh-content"
                          id="panel2bh-header"
                          sx={{ padding: "0" }}
                        >
                          <Stack
                            sx={{
                              display: "flex",
                              alignItems: "flex-start",
                              paddingLeft: "0.8rem",
                              flexDirection: "row",
                              gap: "18px",
                              paddingTop: "16px",
                              marginBottom: "8px",
                            }}
                          >
                            <Typography
                              sx={{ fontWeight: "600", fontSize: "18px" }}
                            >
                              SR
                            </Typography>
                          </Stack>
                        </AccordionSummary>
                        <AccordionDetails
                          sx={{ padding: "0", textAlign: "start" }}
                        >
                          <FormControl
                            sx={{
                              padding: "0px 10px",
                              minHeight: "27vh",
                              // height: "35%",
                              // maxHeight: "30vh",
                              width: "90%",
                              overflowY: "scroll",
                              marginBottom: "50px",
                            }}
                          >
                            {mapData === null ? (
                              <Stack
                                sx={{
                                  width: "100%",
                                  height: "100%",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <TailSpin
                                  visible={true}
                                  height="40"
                                  width="40"
                                  color="#EF6C1A"
                                  ariaLabel="tail-spin-loading"
                                  radius="1"
                                  wrapperStyle={{}}
                                  wrapperClass=""
                                />
                              </Stack>
                            ) : (
                              <FormGroup>
                                <Stack sx={{ width: "100%", gap: "10px" }}>
                                  {mapData?.sr_list?.map((se, index) => (
                                    <FormControlLabel
                                      key={se.user_id}
                                      control={
                                        <Radio
                                          sx={{ color: "#1C1B1F" }}
                                          color="default"
                                          onChange={() =>
                                            handleCategoryClick(se.user_id)
                                          }
                                          checked={selectedSR === se.user_id}
                                        />
                                      }
                                      label={
                                        <Stack
                                          sx={{
                                            display: "flex",
                                            flexDirection: "row",
                                            gap: "5px",
                                          }}
                                        >
                                          {/* <img
                                          style={{
                                            width: "24px",
                                            height: "24px",
                                            alignSelf: "center",
                                          }}
                                          src={getMarkerIcon(se?.zone_id)}
                                        /> */}
                                          <Stack
                                            sx={{
                                              display: "flex",
                                              flexDirection: "column",
                                              alignItems: "flex-start",
                                              justifyContent: "center",
                                              cursor: "pointer",
                                            }}
                                          >
                                            <Typography
                                              sx={{ fontSize: "14px" }}
                                            >{`${se?.first_name}`}</Typography>
                                            <span
                                              style={{
                                                fontWeight: "300",
                                                fontSize: "14px",
                                              }}
                                            >
                                              {se?.user_code}
                                            </span>
                                          </Stack>
                                        </Stack>
                                      }
                                    />
                                  ))}
                                </Stack>
                              </FormGroup>
                            )}
                          </FormControl>
                        </AccordionDetails>
                      </Accordion>
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
          </>
        )}
      </Card>
    </div>
  );
};

export default DashboardSideCard;
