import React, { isValidElement, useEffect, useState } from "react";
import Styles from "./styles/AddEmployeeArea.module.css";

import { Button, Radio, Stack, TextField, Typography } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { styled } from "@mui/material/styles";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import useDistributorList from "../../../hooks/useDistributorList";
import useSMList from "../../../hooks/useSMList";
import useSOList from "../../../hooks/useSOList";
import useSRList from "../../../hooks/useSRList";
import { useSelector, useDispatch } from "react-redux";
// import { AddRoute, GetRouteVillageAddList, GetZoneCoordinate } from '../../../actions/Route/RouteMgt';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AddAutoCompleteOne from "../../AddAutoComplete/AddAutoComplete";
import AddAutoCompleteOneUser from "../../AddAutoComplete/AddAutoCompleteUser";
import useCreateSaathiRoute from "../../../hooks/useCreateSaathiRoute";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Checkbox from "@mui/material/Checkbox";
import useSaathiRouteDetails from "../../../hooks/useSaathiRouteDetails";
import useEmployeeAreaList from "../../../hooks/EmployeeArea/useEmployeeAreaList";
import useAddEmployeeArea from "../../../hooks/EmployeeArea/useAddEmployeeArea";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";


// import RouteVillageMap from './GoogleMap/RouteVillageMap';

const RedButton = styled(Button)(({ theme }) => ({
  color: "white",
  backgroundColor: "#731D3A",
  "&:hover": {
    backgroundColor: "#731D3A",
  },
}));

const GrayButton = styled(Button)(({ theme }) => ({
  color: "#404040",
  backgroundColor: "#E5E5E5",
  "&:hover": {
    backgroundColor: "#E5E5E5",
  },
}));

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const AddEmployeeArea = () => {
  const zoneData = useSelector((state) => state.app.curretDropdownData);
  const sc_id=useSelector((state)=>state.assignSE.sc_id)
  const distributorList = useSelector(
    (state) => state.distributor.distributorList
  );
  const asmList = useSelector((state) => state.sm.SMList);
  const soList = useSelector((state) => state.so.SOList);
  const srList = useSelector((state) => state.sr.SRList);
  const dispatch = useDispatch();
  const [edit, setEdit] = useState(true);

  const state = useSelector((state) => state.RouteMgt);
  const [formError, setFormError] = useState({});
  const [token, setToken] = useState("");
  const Firsttoken = useSelector((state) => state.Token);
  const [Dist, setDist] = useState("");
  const [Distid, serDistid] = useState("");

  const [Asm, setAsm] = useState("");
  const [Asmid, setAsmid] = useState("");

  const [Se, setSe] = useState("");
  const [Sr, setSr] = useState("");
  const [Seid, setSeid] = useState("");
  const [rootzone, setRootZone] = useState("");
  const [villagelist, setVillagelist] = useState([]);

  const [addsuccess, setAddsuccess] = useState({
    addSuccess: false,
    addSuccessMessage: "",
  });
  const [failed, setFailed] = useState({
    addFailed: false,
    addFailedMessage: "",
  });

  let initialState = {
    zone_name: `${rootzone}`,
    route_code: "",
    outlets_registered: "",
    outlets_available: "",
    wd_name: "",
    route_desc: "",
    tsi_name: "",
    se_name: "",
    status: "TRUE",
    asm_name: "",
    so_name:"",
    asm_id: null,
    tsi_id: null,
    se_id: null,
    so_id: null,
    is_village: false,
  };

  const [form, setForm] = useState({
    zone_name: "",
    route_code: "",
    outlets_registered: "",
    outlets_available: "",
    wd_name: "",
    route_desc: "",
    tsi_name: "",
    se_name: "",
    status: "TRUE",
    asm_name: "",
    so_name:"",
    asm_id: null,
    tsi_id: null,
    se_id: null,
    so_id: null,
    is_village: false,
  });
  const [zoneCoord, setZoneCoord] = useState({
    lati: "",
    longi: "",
  });
  const [selectedWeekdays, setSelectedWeekdays] = useState([]);

  function capitalizeFirstLetter(str) {
    if (typeof str !== "string" || str.length === 0) return str;
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;

    // setSelectedWeekdays((prev) => {
    //   if (checked) {
    //     return [...prev, name];
    //   } else {
    //     return prev.filter(day => day !== name);
    //   }
    // });

    const capitalizeDay = capitalizeFirstLetter(name);

    // setForm({
    //   ...form,
    //   route_desc: capitalizeDay,
    // });
    setSelectedWeekdays([name]);
  };

  const weekdays = [
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
    "sunday",
  ];
  const getDistributor = useDistributorList();
  const getSMList = useSMList();
  const getSOList = useSOList();
  const getSRList = useSRList();
  useEffect(() => {
    setRootZone(zoneData?.zone_name);
    getSMList();
    getDistributor();
    getSOList();
    getSRList();
  }, [zoneData]);

  const GetData = async () => {
    let param = {
      zone_name: rootzone,
    };
  };

  useEffect(() => {
    GetData();
  }, [rootzone]);

  // useEffect(() => {
  //   console.log(selectedWeekdays);
  // }, [selectedWeekdays])

  React.useEffect(() => {
    if (
      Firsttoken &&
      Firsttoken.token &&
      Firsttoken.token !== null &&
      Firsttoken.token.length !== 0 &&
      Firsttoken !== "undefined"
    ) {
      setToken(Firsttoken.token);
    }
  }, [Firsttoken]);

  const handleOpen = () => {
    setEdit(true);
  };

  const handleClose = () => {
    setEdit(false);
  };

  const IsValid = () => {
    let success = true;
    let error = {};

    if (!form.wd_name.trim()) {
      error.wderror = "**Description Cannot be empty**";
      success = false;
    }

    if (!Asm.trim()) {
      error.asm = "**ASM Cannot be empty**";
      success = false;
    }

    if (!Dist.trim()) {
      error.dist = "**Distributor Cannot be empty**";
      success = false;
    }

    if (!Se.trim()) {
      error.se = "**SO Cannot be empty**";
      success = false;
    }

    if (!Sr.trim()) {
      error.sr = "**SR Cannot be empty**";
      success = false;
    }

    if (!form.route_desc.trim()) {
      error.routedescription = "**route description Cannot be empty**";
      success = false;
    }
    if (selectedWeekdays.length === 0) {
      error.weekDaysError = "**Select a day**";
      success = false;
    }
    setFormError(error);
    return success;
  };

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
    if(e.target.name==="se_name"){
      const selectedSR=srList?.find((sr)=>sr.first_name===e.target.value)
      setForm({
        ...form,
        se_id:selectedSR?.user_id,
        se_name: e.target.value
      })
    }else if(e.target.name==="so_name"){
      const selectedSO=soList?.find((so)=>so.first_name===e.target.value)
      setForm({
        ...form,
        so_id:selectedSO?.user_id,
        so_name: e.target.value
      })
    }else if(e.target.name==="asm_name"){
      const selectedASM=asmList?.find((asm)=>asm.first_name===e.target.value)
      console.log(selectedASM,"handleChangeASM")
      setForm({
        ...form,
        asm_id:selectedASM?.user_id,
        asm_name: e.target.value
      })
    }
  };

  console.log(form,"handleChange")

  const handleDistChange = (item) => {
    if (item && item !== "undefined" && item !== null) {
      // setDist(`${item.name}${item.surname}(${item.user_id})-${item.zone_name}`);
      setDist(`${item.shop_name}-${item.zone_name}`);

      serDistid(item.dist_id);

      setForm({
        ...form,
        shop_name: item.shop_name,
        dist_id: item.dist_id,
      });
    } else {
      setDist("");
      serDistid("0");

      setForm({
        ...form,
        dist_name: "",
        dist_id: null,
      });
    }
  };

  const handleAsmChange = (item) => {
    if (item && item !== "undefined" && item !== null) {
      setAsm(`${item.first_name}-${item.zone_name}`);
      setAsmid(item.user_id);
      setForm({
        ...form,
        asm_first_name: item.first_name,
        asm_id: item.user_id,
        asm_surname: item.last_name,
      });
    } else {
      setAsm(``);
      setAsmid(0);
      setForm({
        ...form,
        asm_first_name: "",
        asm_id: null,
        asm_surname: "",
      });
    }
  };

  const handleSoChange = (item) => {
    if (item && item !== "undefined" && item !== null) {
      setSe(`${item.first_name}-${item.zone_name}`);
      setSeid(item.user_id);
      setForm({
        ...form,
        so_name: item.first_name,
        so_id: item.user_id,
        so_surname: item.last_name,
      });
    } else {
      setSe(``);
      setSeid("");
      setForm({
        ...form,
        so_name: "",
        so_id: null,
        so_surname: "",
      });
    }
  };

  const handleSRChange = (item) => {
    if (item && item !== "undefined" && item !== null) {
      setSr(`${item.first_name}-${item.zone_name}`);
      setSeid(item.user_id);
      setForm({
        ...form,
        sr_name: item.first_name,
        sr_id: item.user_id,
        sr_surname: item.last_name,
      });
    } else {
      setSr(``);
      setSeid("");
      setForm({
        ...form,
        sr_name: "",
        sr_id: "0",
        sr_surname: "",
      });
    }
  };

  const createRoute = useCreateSaathiRoute();
  const addEmployeeArea = useAddEmployeeArea();
  const RouteDetails = useSaathiRouteDetails();
  const getAllEmployeeAreaList=useEmployeeAreaList()
  const handleSubmit = async () => {
    let list = [];

    let param = {
      zone_id: zoneData?.zone_id,
      employee_area_name: form?.wd_name,
      asm_id: form?.asm_id,
      so_id: form?.so_id,
      se_id: form?.se_id,
      sc_id: sc_id?.[0]
    };

    console.log(param,"addEmployeeArea")

    // if (IsValid()) {
      const param2 = {
        zone_id: zoneData?.zone_id,
      };
      const response = await addEmployeeArea(param);
      if (response.code === 200) {
        await getAllEmployeeAreaList(param2);
        document
        .getElementById("EmployeeAreaTable")
        .scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
        setForm(initialState);
        toast.success(response.message);
      } else {
        toast.error(response.message);
      }
    // }
  };

  React.useEffect(() => {
    if (addsuccess.addSuccess) {
      let text = addsuccess.addSuccessMessage;
      toast.success(`${text}`);

      setAddsuccess({
        ...failed,
        addSuccess: false,
        addSuccessMessage: ``,
      });
      setVillagelist([]);

      setForm(initialState);
      setFormError({});

      let param = {
        admin_id: "1",
        zone_name: `${rootzone}`,
      };

      // dispatch(GetRouteVillageAddList(token, param))
    }
  }, [addsuccess]);

  React.useEffect(() => {
    if (failed.addFailed) {
      let text = failed.addFailedMessage;
      toast.error(`${text}`);

      setFailed({
        ...failed,
        addFailed: false,
        addFailedMessage: "",
      });
    }
  }, [failed]);

  React.useEffect(() => {
    setForm(initialState);
  }, [rootzone]);

  const handleClear = () => {
    setForm(initialState);
    setSr("");
    setSe("");
    setAsm("");
    setDist("");
  };

  const handleReset = () => {
    setVillagelist([]);
  };

  return (
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className={Styles.AddRouteMgt}>
        <div className={Styles.AddRouteMgtTopWrapper}>
          <label className={Styles.AddRouteMgtTopWrapperTitle}>
            ADD Employee Area
          </label>
        </div>

        <div className={Styles.AddRouteMgtBottomWrapper}>
          <div className={Styles.AddRouteMgtOneSection}>
            {/* Zone */}
            <div className={Styles.AddRouteMgtOneSectionlist}>
              <div className={Styles.AddRouteMgtOneSectionLabelContainer}>
                <label className={Styles.AddRouteMgtOneSectionLabel}>
                  Zone:
                </label>
              </div>
              <div className={Styles.AddRouteMgtOneSectionTextFieldContainer}>
                <TextField
                  disabled={true}
                  variant="outlined"
                  size="small"
                  // sx={{ width: "100%" }}
                  value={rootzone}
                />
              </div>
            </div>

            {/* Employee Area Name */}
            <div className={Styles.AddRouteMgtOneSectionlist}>
              <div className={Styles.AddRouteMgtOneSectionLabelContainer}>
                <label className={Styles.AddRouteMgtOneSectionLabel}>
                  Employee Area Name:
                </label>
              </div>
              <div className={Styles.AddRouteMgtOneSectionTextFieldContainer}>
                <TextField
                  variant="outlined"
                  size="small"
                  sx={{ width: "100%" }}
                  name="wd_name"
                  value={form?.wd_name}
                  onChange={handleChange}
                />
                {formError.wderror && (
                  <span className={Styles.error}>{formError.wderror}</span>
                )}
              </div>
            </div>

            {/* ASM */}
            <div className={Styles.AddRouteMgtOneSectionlist}>
              <div className={Styles.AddRouteMgtOneSectionLabelContainer}>
                <label className={Styles.AddRouteMgtOneSectionLabel}>
                  ASM:
                </label>
              </div>
              <div className={Styles.AddRouteMgtOneSectionTextFieldContainer}>
                <FormControl
                  sx={{ m: 1, minWidth: 200, maxWidth: 200 }}
                  size="small"
                >
                  <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    name="asm_name"
                    value={form?.asm_name}
                    label="Select ASM"
                    onChange={handleChange}
                    displayEmpty
                  >
                    <MenuItem value="" disabled>
                      Select ASM
                    </MenuItem>
                    {asmList &&
                      asmList
                        ?.filter((asmList) => asmList.status === true)
                        .map((asm) => (
                          <MenuItem key={asm.user_id} value={asm.first_name}>
                            {asm.first_name}
                          </MenuItem>
                        ))}
                  </Select>
                </FormControl>
                {formError.wderror && (
                  <span className={Styles.error}>{formError.wderror}</span>
                )}
              </div>
            </div>

            {/* SO */}
            <div className={Styles.AddRouteMgtOneSectionlist}>
              <div className={Styles.AddRouteMgtOneSectionLabelContainer}>
                <label className={Styles.AddRouteMgtOneSectionLabel}>SO:</label>
              </div>
              <div className={Styles.AddRouteMgtOneSectionTextFieldContainer}>
                <FormControl
                  sx={{ m: 1, minWidth: 200, maxWidth: 200 }}
                  size="small"
                >
                  <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    name="so_name"
                    value={form?.so_name}
                    label="Select SO"
                    onChange={handleChange}
                    displayEmpty
                  >
                    <MenuItem value="" disabled>
                      Select SO
                    </MenuItem>
                    {soList &&
                      soList
                        ?.filter((soList) => soList.status === true)
                        .map((so) => (
                          <MenuItem key={so.user_id} value={so.first_name}>
                            {so.first_name}
                          </MenuItem>
                        ))}
                  </Select>
                </FormControl>
                {formError.wderror && (
                  <span className={Styles.error}>{formError.wderror}</span>
                )}
              </div>
            </div>

            {/* SR */}
            <div className={Styles.AddRouteMgtOneSectionlist}>
              <div className={Styles.AddRouteMgtOneSectionLabelContainer}>
                <label className={Styles.AddRouteMgtOneSectionLabel}>SR:</label>
              </div>
              <div className={Styles.AddRouteMgtOneSectionTextFieldContainer}>
                <FormControl
                  sx={{ m: 1, minWidth: 200, maxWidth: 200 }}
                  size="small"
                >
                  <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    name="se_name"
                    value={form?.se_name}
                    label="Select SR"
                    onChange={handleChange}
                    displayEmpty
                  >
                    <MenuItem value="" disabled>
                      Select SR
                    </MenuItem>
                    {srList &&
                      srList
                        ?.filter((srList) => srList.status === true)
                        .map((sr) => (
                          <MenuItem key={sr.user_id} value={sr.first_name}>
                            {sr.first_name}
                          </MenuItem>
                        ))}
                  </Select>
                </FormControl>
                {formError.wderror && (
                  <span className={Styles.error}>{formError.wderror}</span>
                )}
              </div>
            </div>
          </div>

          {/* <div className={Styles.AddRouteMgtOneSection}>
            <div className={Styles.AddRouteMgtOneSectionlist}>
              <div className={Styles.AddRouteMgtOneSectionLabelContainer}>
                <label className={Styles.AddRouteMgtOneSectionLabel}>
                  Beat:
                </label>
              </div>
              <div className={Styles.AddRouteMgtOneSectionTextFieldContainer}>
                <TextField
                  variant="outlined"
                  size="small"
                  sx={{ width: "100%" }}
                  name="route_desc"
                  value={form.route_desc}
                  onChange={handleChange}
                />
                {formError.routedescription && (
                  <span className={Styles.error}>
                    {formError.routedescription}
                  </span>
                )}
              </div>
            </div>


          </div> */}
        </div>

        <div className={Styles.ButtonContainer}>
          <RedButton variant="contained" onClick={handleSubmit}>
            Add
          </RedButton>

          {form.is_village && villagelist.length !== 0 ? (
            <RedButton variant="contained" onClick={handleReset}>
              Reset Map
            </RedButton>
          ) : null}
          <GrayButton variant="contained" onClick={handleClear}>
            Clear
          </GrayButton>
        </div>
      </div>
    </>
  );
};

export default AddEmployeeArea;
