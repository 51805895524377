import * as React from "react";
import Paper from "@mui/material/Paper";
import { Stack, Typography, Button } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";

import DeleteIcon from "@mui/icons-material/Delete";
import { useSelector } from "react-redux";
import Switch from "@mui/material/Switch";
import useUpdateSchemeStatus from "../../hooks/useUpdateSchemeStatus";
import { ToastContainer } from "react-toastify";
import useSchemeDetailsList from "../../hooks/useSchemeDetailsList";

const CampaignSchemeTable = () => {
    const AntSwitch = styled(Switch)(({ theme }) => ({
        width: 28,
        height: 16,
        padding: 0,
        display: "flex",
        "&:active": {
          "& .MuiSwitch-thumb": {
            width: 12,
          },
          "& .MuiSwitch-switchBase.Mui-checked": {
            transform: "translateX(9px)",
          },
        },
        "& .MuiSwitch-switchBase": {
          padding: 2,
          "&.Mui-checked": {
            transform: "translateX(12px)",
            color: "#fff",
            "& + .MuiSwitch-track": {
              opacity: 1,
              backgroundColor:
                theme.palette.mode === "dark" ? "#177ddc" : "#009C19",
            },
          },
        },
        "& .MuiSwitch-thumb": {
          boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
          width: 12,
          height: 12,
          borderRadius: 6,
          transition: theme.transitions.create(["width"], {
            duration: 200,
          }),
        },
        "& .MuiSwitch-track": {
          borderRadius: 16 / 2,
          opacity: 1,
          backgroundColor:
            theme.palette.mode === "dark"
              ? "rgba(255,255,255,.35)"
              : "rgba(0,0,0,.25)",
          boxSizing: "border-box",
        },
      }));
      const tableDetails = useSelector((store) => store.scheme.campaignSchemeList);
      //   useSRTableDetails();
      const row = [
        "SI No.",
        "Scheme",
        "SKU",
        "Validity",
        "Scope",
        "Date of Creation",
        "Status",
        "",
        // "Action",
      ];
      const zoneData = useSelector((state) => state.app.curretDropdownData);
      const [page, setPage] = React.useState(0);
      const [isConfirmDialogOpen, setIsConfirmDialogOpen] = React.useState(false);
      const [categoryId, setCategoryId] = React.useState(null);
      const [rowsPerPage, setRowsPerPage] = React.useState(10);
    
      const handleChangePage = (event, newPage) => {
        setPage(newPage);
      };
    
      const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
      };
    
      const handleDelete = (data) => {
        setIsConfirmDialogOpen(true);
        setCategoryId(data?.category_id);
      };
    
      const handleConfirmDialogClose = () => {
        setIsConfirmDialogOpen(false);
      };
    
      const handleConfirmApproveClick = () => {
        setIsConfirmDialogOpen(false);
      };
    
    
      const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = date.getDate();
        const month = date.toLocaleString("default", { month: "long" });
        return `${day}-${month}`;
      };
      const updateSchemeStatus = useUpdateSchemeStatus();
      const updateSchemeDetailsList = useSchemeDetailsList();
      const handleStatusChange = async (sku_id ,scheme_id, scheme_type, event) => {
        const param = {
          scheme_id: scheme_id,
          scheme_type: scheme_type,
          status: event.target.checked,
        };
        const param2 = {
          sku_id: sku_id,
          status: !event.target.checked,
          zone_id: zoneData?.zone_id
        }
        await updateSchemeStatus(param);
        await updateSchemeDetailsList(param2);
        // console.log(param, "param");
      };
    
      return (
        <Paper sx={{ width: "100%" }}>
          <ToastContainer
            position="bottom-right"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          <TableContainer sx={{ maxHeight: 440, fontFamily: "Inter, sans-serif" }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {row?.map(
                    (
                      item,
                      index // Added index to map function arguments
                    ) => (
                      <TableCell
                        key={index} // Added key prop with unique value
                        sx={{
                          background: "#F8F8F8",
                          fontSize: "14px",
                          color: "#5F6868",
                          fontWeight: "500",
                          color: "#5F6868",
                        }}
                        align="start"
                        colSpan={1}
                      >
                        {item}
                      </TableCell>
                    )
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {tableDetails
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((data, index) => (
                    <>
                      <TableRow hover role="checkbox" tabIndex={-1} key={data.id}>
                        {/* Render SO Users */}
                        <TableCell
                          sx={{ color: "#5F6868" }}
                          align="start"
                          colSpan={1}
                        >
                          {index + 1}
                        </TableCell>
    
                        <TableCell
                          sx={{ color: "#5F6868" }}
                          align="start"
                          colSpan={1}
                        >
                          <React.Fragment key={index}>
                            {data?.scheme_name}
                          </React.Fragment>
                        </TableCell>
                        <TableCell
                          sx={{ color: "#5F6868" }}
                          align="start"
                          colSpan={1}
                        >
                          {data?.sku_name}
                        </TableCell>
                        <TableCell
                          sx={{ color: "#5F6868" }}
                          align="start"
                          colSpan={1}
                        >
                          {formatDate(data?.start_date)} to{" "}
                          {formatDate(data?.end_date)}
                        </TableCell>
                        <TableCell
                          sx={{ color: "#5F6868" }}
                          align="start"
                          colSpan={1}
                        >
                          {data?.scheme_type}
                        </TableCell>
                        <TableCell
                          sx={{ color: "#5F6868" }}
                          align="start"
                          colSpan={1}
                        >
                          {formatDate(data?.created_at)}
                        </TableCell>
                        <TableCell
                          sx={{ color: "#5F6868" }}
                          align="start"
                          colSpan={1}
                        >
                          <Stack
                            sx={{
                              flexDirection: "row",
                              alignItems: "center",
                              gap: "10px",
                            }}
                          >
                            <AntSwitch
                              checked={data.status}
                              onChange={(event) =>
                                handleStatusChange(
                                  data?.sku_id,
                                  data?.scheme_id,
                                  data?.scheme_type,
                                  event
                                )
                              }
                              inputProps={{ "aria-label": "controlled" }}
                            />
                            <DeleteIcon sx={{ color: "#EF6C1A" }} />
                          </Stack>
                        </TableCell>
    
                        <TableCell align="start" colSpan={1}>
                          <Button
                            sx={{
                              color: "#EF6C1A !important",
                              backgroundColor: "white !important",
                              "&:hover": {
                                backgroundColor: "#EF6C1A !important",
                                color: "white !important",
                                border: "2px solid #EF6C1A !important",
                              },
                              border: "2px solid #EF6C1A !important",
                              height: "30px !important",
                              fontSize: "14px !important",
                              borderRadius: "5px !important",
                              textTransform: "none",
                            }}
                          >
                            Details
                          </Button>
                        </TableCell>
                      </TableRow>
                    </>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={tableDetails?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      );
}

export default CampaignSchemeTable