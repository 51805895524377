import React from "react";
import useAxiosPrivate from "./useAxiosPrivate";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
const useUpdateCategory = (form) => {
  
  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();
  const categoryFormData = useSelector(
    (store) => store.category.categoryFormData
  );
  const updateCategoryDetails = async () => {
   console.log(form, "form");
    let param = {
      category_name:form?.category_name??form?.categoryRowData?.category_name,
      category_image: categoryFormData?.full_path??form?.categoryRowData?.category_image,
      status:form?.status==="Inactive"?false:true??form?.categoryRowData?.status,
      category_id:form?.category_id,
      sc_id:form?.sc_id??form?.categoryRowData?.sc_id,
    };
    console.log(param, "param");
    const response = await Axios.post("/update_category", param);
    const data = await response.data;
    
 
    if (data.code === 200) {
      toast.success("Updated SuccessFully");
    } else {
      toast.error("Update Failed");
    }

   
  };
  // useEffect(() => {
  //   getAllUserList();
  // }, []);
  return updateCategoryDetails;
};

export default useUpdateCategory;
