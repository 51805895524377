import useAxiosPrivate from "../useAxiosPrivate";
import { ToastContainer, toast } from "react-toastify";

const useVisitReport = (selectedData, zoneData) => {
  const Axios = useAxiosPrivate();

  const getVisitReport = async (param) => {
   
    const response = await Axios.post("download_visit_report", param);
    const data = await response.data;

    if (data?.code === 200) {
      toast.success(data?.message);
    } else {
      toast.error(data?.message);
    }
    return data;
  };
  return getVisitReport;
};

export default useVisitReport;
