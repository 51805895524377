import { getSrListBySC } from "../slice/adminViewSlice";
import useAxiosPrivate from "./useAxiosPrivate";
import { useDispatch, useSelector } from "react-redux";


const useSrListBySC = () => {
    const currentzone = useSelector((state) => state.app.curretDropdownData);
  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();
  const getSrsListBySC = async (param) => {
    if (currentzone) {
    const response = await Axios.post("/sr_list_with_routeData", param);
    const data = await response.data;
    dispatch(getSrListBySC(data.body));
    }
  };

  return getSrsListBySC;
};

export default useSrListBySC;
