import React, { useState } from "react";

import { Stack } from "@mui/material";
import AddTarget from "./AddTarget";
import TargetTable from "./TargetTable";
import { ToastContainer } from "react-toastify";

const TargetAssigned = ({selectedMonth, setSelectedMonth}) => {
 
  console.log(selectedMonth, "selectedMonth");
  
  return (
    
    <Stack sx={{ display: "flex", flexDirection: "column" }}>
        <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <AddTarget setSelectedMonth={setSelectedMonth} selectedMonth={selectedMonth}/>
      <TargetTable setSelectedMonth={setSelectedMonth} selectedMonth={selectedMonth}/>
    </Stack>
  );
};

export default TargetAssigned;
