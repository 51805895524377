import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import {
  FormControl,
  Select,
  MenuItem,
  TextField,
  InputLabel,
  Stack,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import AddIcon from "@mui/icons-material/Add";
import { Input } from "@mui/icons-material";
import {
  getCategoryFormData,
  getCategoryList,
} from "../../slice/categorySlice";
import {
  resetCategoryFormData,
} from "../../slice/categorySlice";
import useCategoryImageUpload from "../../hooks/useCategoryImageUpload";
import useAddCategory from "../../hooks/useAddCategory";
import { BaseImgUrl } from "../../utility/BaseURL";
import { ToastContainer, toast } from "react-toastify";
import useCategoryList from "../../hooks/useCategoryList";
import useUpdateCategory from "../../hooks/useUpdateCategory";
// import { getZoneList } from "../slice/appSlice";
// import useZoneList from "../hooks/useZoneList";
// import useStateList from "../hooks/useStateList";
// import useDistributorList from "../hooks/useDistributorList";
// import useAddDistributor from "../hooks/useAddDistributor";

const viewImgPath = `${BaseImgUrl}/category/`;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function EditCategory({setOpen,open,categoryEditId,categoryRowData}) {
  // const [open, setOpen] = React.useState(false);
  const [enable, setEnable] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    dispatch(resetCategoryFormData());
    setForm({});
    setError({});
  };

  useEffect(()=>{
    setForm((prev)=>({
      ...prev,
      category_id:categoryEditId
    }))
  },[categoryEditId, open])


  useEffect(()=>{
    setForm((prev)=>({
      ...prev,
      categoryRowData:categoryRowData,
      category_name:categoryRowData?.category_name,
      category_image:categoryRowData?.category_image,
      status:categoryRowData?.status === true ? "Active" : "Inactive",
      sc_name:categoryRowData?.sc_name,  
      sc_id:categoryRowData?.sc_id   
    }))
  },[categoryRowData, open])
  const dispatch = useDispatch();
  const [form, setForm] = React.useState({});
  const getCategoryImageUpload = useCategoryImageUpload();
  const addCategory = useAddCategory(form);
  const getAllCategoryList = useCategoryList();
  const statusList = ["Active", "Inactive"];
  const superCategoryImage = useSelector(
    (state) => state.category.categoryFormData
  );
  const superCategoryList = useSelector((state)=>state.superCategory.superCategoryList);
  //   const zoneList = useSelector((store) => store.app.zoneList);
  //   const stateList = useSelector((store) => store.app.stateList);
  //   useZoneList();
  //   useStateList();
  //   const addDistributor = useAddDistributor(form);

  const handleChange = (e) => {
    setForm((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const [error, setError] = React.useState({});
  const isValid = () => {
    let success = true;
    let err = {};

    if (
      form.category_name?.trim() === "" ||
      form.category_name?.trim() === undefined ||
      form.category_name?.trim() === null
    ) {
      err.name = "**Category Name Cannot be Empty**";
      success = false;
    }

    // if (
    //   form.selectedImage?.trim() === "" ||
    //   form.selectedImage?.trim() === undefined ||
    //   form.selectedImage?.trim() === null
    // ) {
    //   err.selectedImage = "**Image should be selected**";
    //   success = false;
    // }
    setError(err);
    return success;
  };
  useEffect(() => {
    setEnable(false);
  }, [superCategoryImage]);

  // useEffect(() => {
  //   const formData = new FormData();
  //   formData.append("file", form?.category_image);
  //   getCategoryImageUpload(formData);
  // }, [form?.category_image]);

  const handleImageChange = (e) => {
    setForm((prev) => ({
      ...prev,
      [e.target.name]: e.target.files[0],
    }));
    setEnable(true);
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    getCategoryImageUpload(formData);
    if (e.target.files[0]) {
      setForm((prev) => ({
        ...prev,
        selectedImage: e.target.files[0].name,
      }));
    } else {
      setForm((prev) => ({
        ...prev,
        selectedImage: null,
      }));
    }
  };

  const updateCategoryDetails=useUpdateCategory(form)

  const handleSubmit = async(e) => {
    e.preventDefault(); 
    if (isValid()) {
      updateCategoryDetails();
      dispatch(resetCategoryFormData());
      await getAllCategoryList();
      setOpen(false);
      setForm({});
    }
  };
  
  const handleCategory = (e) => {
    const trimmedValue = e.target.value.trim();
    let categoryId = 0;
    for (let i = 0; i < superCategoryList?.length; i++) {
      if (trimmedValue === superCategoryList[i]?.sc_name?.trim()) {
        categoryId = superCategoryList[i]?.sc_id;
        break;
      }
    }
    setForm((prev) => ({
      ...prev,
      sc_id: categoryId,
      sc_name: e.target.value
    }));
  };
  useEffect(() => {
    console.log(form, "form");
  }, [form]);

  return (
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div>
        
        <Modal
          open={open}
          // onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            {/* <Typography id="modal-modal-title" variant="h6" component="h2">
            Text in a modal
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
          </Typography> */}

            <FormControl
              fullWidth
              size="small"
              sx={{ display: "flex", flexDirection: "column", gap: "0.8rem" }}
            >
              {/* <Stack
              sx={{ display: "flex", flexDirection: "column", gap: "0.4rem" }}
            >
              <label>State</label>
              <Select onClick={handleStateChange} label="State">
                {stateList?.map((item, index) => (
                  <MenuItem value={item.state_name} key={item.state_id}>
                    {item.state_name}
                  </MenuItem>
                ))}
              </Select>
            </Stack>

            <Stack
              sx={{ display: "flex", flexDirection: "column", gap: "0.4rem" }}
            >
              <label>Zone</label>
              <Select onClick={handleZoneChange}  label="Zone">
                {zoneList?.map((item, index) => (
                  <MenuItem value={item.zone_name} key={item.zone_id}>
                    {item.zone_name}
                  </MenuItem>
                ))}
              </Select>
            </Stack> */}
              <Stack
                sx={{ display: "flex", flexDirection: "column", gap: "0.4rem" }}
              >
                <input
                  variant="outlined"
                  name="category_name"
                  value={form?.category_name}
                  onChange={handleChange}
                  style={{
                    height: "1.8rem",
                    paddingLeft: "0.5rem",
                    fontSize: "0.9rem",
                  }}
                />
                {error.name && (
                  <p
                    style={{
                      color: "red",
                      justifyContent: "center",
                      display: "flex",
                      fontSize: "0.8rem",
                      marginTop: "-0.1rem",
                    }}
                  >
                    {error.name}
                  </p>
                )}
              </Stack>
              <Stack
                sx={{ display: "flex", flexDirection: "column", gap: "0.4rem" }}
              >
                <select
                  style={{
                    height: "1.8rem",
                    fontSize: "0.9rem",
                    cursor: "pointer",
                  }}
                  name="category"
                  value={form.sc_name}
                  onChange={handleCategory}
                >
                  <option value="" disabled selected>
                    Choose Category
                  </option>
                  {superCategoryList?.map(
                    (item) =>
                     
                      item.status && (
                        <option key={item.sc_id} value={item.sc_name}>
                          {item.sc_name}
                        </option>
                      )
                  )}
                </select>
                {error.category && (
                  <p
                    style={{
                      color: "red",
                      justifyContent: "center",
                      display: "flex",
                      fontSize: "0.8rem",
                      marginTop: "-0.1rem",
                    }}
                  >
                    {error.category}
                  </p>
                )}
              </Stack>

              <Stack
                sx={{ display: "flex", flexDirection: "column", gap: "0.4rem" }}
              >
                <select
                  style={{
                    height: "1.8rem",
                    fontSize: "0.9rem",
                    cursor: "pointer",
                  }}
                  name="status"
                  onChange={handleChange}
                >
                  <option value="" disabled selected>
                    {form?.status}
                  </option>
                  {statusList.map((item, index) => (
                    <option key={index}>{item}</option>
                  ))}
                </select>
                {error.category && (
                  <p
                    style={{
                      color: "red",
                      justifyContent: "center",
                      display: "flex",
                      fontSize: "0.8rem",
                      marginTop: "-0.1rem",
                    }}
                  >
                    {error.category}
                  </p>
                )}
              </Stack>
              <Stack
                sx={{ display: "flex", flexDirection: "row", gap: "0.4rem" }}
              >
                <img
                  style={{ width: "2rem" }}
                  src={superCategoryImage?.full_path??form?.category_image}
                />
                <label htmlFor="category_image" style={{ cursor: "pointer" }}>
                  <input
                    id="category_image"
                    type="file"
                    size="small"
                    accept="image/*"
                    name="category_image"
                    style={{
                      display: "none",
                    }}
                    onChange={handleImageChange}
                  />
                  <div
                    style={{
                      height: "1.8rem",
                      paddingLeft: "0.5rem",
                      fontSize: "0.9rem",
                      border: "1px solid #ccc",
                      borderRadius: "4px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      cursor: "pointer",
                      width:"100%"
                    }}
                  >
                    Choose Image
                  </div>
                </label>
                {error.selectedImage && (
                  <p
                    style={{
                      color: "red",
                      justifyContent: "center",
                      display: "flex",
                      fontSize: "0.8rem",
                      marginTop: "-0.1rem",
                    }}
                  >
                    {error.selectedImage}
                  </p>
                )}
              </Stack>
              <Stack
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  gap: "0.4rem",
                }}
              >
                <Button
                  sx={{
                    background: "white",
                    color: "#FF5A5A",
                    border: "2px solid #FF5A5A",
                    height: "1.8rem"
                  }}
                  onClick={handleClose}
                >
                  Discard
                </Button>
                <Button
                  sx={{ background: "#EF6C1A", color: "white",height: "1.8rem" }}
                  onClick={handleSubmit}
                  disabled={enable}
                >
                  Submit
                </Button>
              </Stack>
            </FormControl>
          </Box>
        </Modal>
      </div>
    </>
  );
}
