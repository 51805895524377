import { useEffect } from "react";
import useAxiosPrivate from "./useAxiosPrivate";
import { useDispatch } from "react-redux";
import { getUserList } from "../slice/userSlice";
import { getSchemeZoneList, getZoneList } from "../slice/appSlice";

const useSchemeZoneList = () => {
  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();
  const getAllSchemeZoneList = async (stateId) => {
    let param = {
      state_id: stateId,
    };
    const response = await Axios.post("/zone_list", param);
    const data = await response.data;

    dispatch(getSchemeZoneList(data.body));
  };
  return getAllSchemeZoneList;
};

export default useSchemeZoneList;
