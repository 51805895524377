import React from 'react'
import PieChartOrder from './PieChartOrder'
import PieChartAmount from './PieChartAmount'
import { Stack } from "@mui/material";
import PieChartCallConversions from './PieChartCallConversions';
import useSRGraphSaleDetails from "../../../hooks/useSRGraphSaleDetails";
import useSRCallConversion from "../../../hooks/useSRCallConversion";
import useSRAmountChartDetails from "../../../hooks/useSRAmountChartDetails";
import useSROrderChartDetails from '../../../hooks/useSROrderChartDetails';

const PieChart = () => {
    useSRGraphSaleDetails()
    useSRCallConversion()
    useSRAmountChartDetails()
    useSROrderChartDetails()
  return (
    <Stack sx={{display:"flex",flexDirection:"row",flexWrap:"wrap", justifyContent:"space-between"}}>
        <PieChartCallConversions/>
         <PieChartOrder/>
         <PieChartAmount/>
     </Stack>
  )
}

export default PieChart
