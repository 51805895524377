import React, { useEffect, useState } from "react";
import Dropdown from "./Dropdown";
import DownloadFile from "./DownloadFile";
import Sidebar from "../Sidebar";
import { Divider, Stack } from "@mui/material";
import EveryDayDownloads from "./EveryDayDownloads";
import MonthlyDownloads from "./MonthlyDownloads";

const Download = () => {
  const [selectedData, setSelectedData] = useState(null);
  useEffect(() => {
    console.log(selectedData);
  }, [selectedData]);

  return (
    <Stack sx={{ display: "flex", flexDirection: "row" }}>
      <Sidebar />
      <Stack sx={{ width: "100%" }}>
        <Stack
          sx={{
            display: "flex",
            flexDirection: "column",
            padding: "1rem",
            gap: "1rem",
          }}
        >
          <Dropdown setSelectedData={setSelectedData} />
          <DownloadFile selectedData={selectedData} />
          <Divider sx={{ marginTop: "14px" }} />
        </Stack>

        <Stack
        
        >
          <EveryDayDownloads />
          <Divider sx={{ margin: "14px" }} />
        </Stack>

        <Stack>
          <MonthlyDownloads />
          {/* <Divider sx={{ marginTop: "14px" }} /> */}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default Download;
