import React, { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { makeStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import styles from "./styles/SalesTable.module.css";
import { useSelector } from "react-redux";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

const useStyles = makeStyles({
  head: {
    borderBottom: "2px solid black",
  },
  pagination: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignContent: "center",
  },
});

const SalesTable = ({
  retailerText,
  Product,
  route,
}) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [expandedRows, setExpandedRows] = useState([]);
  const columnsOne = [
    "Sale Code",
    // "Shape ID",
    "Completed By",
    "Retailer Name",
    "Status",
    "Amount Paid",
    "Created At",
    "Action",
  ];

  const saleDetails = useSelector((store) => store.sale.saleDetails);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const toggleRowExpansion = (index) => {
    setExpandedRows((prevExpandedRows) =>
      prevExpandedRows.includes(index)
        ? prevExpandedRows.filter((rowIndex) => rowIndex !== index)
        : [...prevExpandedRows, index]
    );
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  React.useEffect(() => {
    setPage(0);
  }, [Product, route, retailerText]);

  return (
    <div>
      <TableContainer sx={{ maxHeight: 800, minWidth: "100%" }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead
            className={styles.head}
            sx={{ backgroundColor: "#c4c4c4" }}
          >
            <TableRow
              className={styles.head}
              sx={{ backgroundColor: "#c4c4c4" }}
            >
              {columnsOne.map((column, index) => (
                <TableCell
                  sx={{ backgroundColor: "#c4c4c4" }}
                  key={index}
                  align="center"
                >
                  {column}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {saleDetails !== "undefined" &&
            saleDetails !== null &&
            saleDetails?.length !== 0 ? (
              <>
                {saleDetails
                  .filter((item) => {
                    const rt_fullName =item?.rt_first_name + " " + item?.rt_last_name
                    return (
                      rt_fullName?.toLowerCase().includes(retailerText.toLowerCase()) 
                    );
                  })
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((item, index) => (
                    <React.Fragment key={index}>
                      <TableRow>
                        <TableCell align="center">{item?.sale_code}</TableCell>
                        <TableCell align="center">
                        {item?.first_name}
                        </TableCell>
                        <TableCell align="center">
                          {item?.rt_first_name + " " + item?.rt_last_name}
                        </TableCell>
                        <TableCell align="center">{item?.sale_status}</TableCell>
                        <TableCell align="center">
                        ₹{item?.final_total_cost}
                        </TableCell>
                        <TableCell align="center">
                          {item?.created_at}
                        </TableCell>
                        <TableCell align="center">
                          <Button
                            sx={{ color: "#000" }}
                            onClick={() => toggleRowExpansion(index)}
                          >
                            {expandedRows.includes(index) ? (
                              <KeyboardArrowDownIcon />
                            ) : (
                              <KeyboardArrowUpIcon />
                            )}
                          </Button>
                        </TableCell>
                      </TableRow>
                      {expandedRows.includes(index) && (
                        <>
                          <TableRow>
                            <TableCell
                              sx={{ padding: "0px" }}
                              colSpan={columnsOne.length}
                            >
                              <TableContainer>
                                <Table>
                                  <TableHead>
                                    <TableRow sx={{ padding: "8px" }}>
                                      <TableCell
                                        sx={{
                                          textAlign: "center",
                                          fontWeight: "600",
                                        }}
                                        colSpan={2}
                                      >
                                        S.No
                                      </TableCell>
                                      <TableCell
                                        sx={{
                                          textAlign: "center",
                                          fontWeight: "600",
                                        }}
                                        colSpan={2}
                                      >
                                        SKU Name
                                      </TableCell>
                                      <TableCell
                                        sx={{
                                          textAlign: "center",
                                          fontWeight: "600",
                                        }}
                                        colSpan={2}
                                      >
                                        Cost
                                      </TableCell>
                                      <TableCell
                                        sx={{
                                          textAlign: "center",
                                          fontWeight: "600",
                                        }}
                                        colSpan={2}
                                      >
                                        Pieces Quantity
                                      </TableCell>
                                      <TableCell
                                        sx={{
                                          textAlign: "center",
                                          fontWeight: "600",
                                        }}
                                        colSpan={2}
                                      >
                                       Carton Quantity
                                      </TableCell>
                                      <TableCell
                                        sx={{
                                          textAlign: "center",
                                          fontWeight: "600",
                                        }}
                                        colSpan={2}
                                      >
                                        Ladi Quantity
                                      </TableCell>
                                      <TableCell
                                        sx={{
                                          textAlign: "center",
                                          fontWeight: "600",
                                        }}
                                        colSpan={2}
                                      >
                                        Final Total Cost
                                      </TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {item?.sku_list?.map((sku, skuIndex) => (
                                      <TableRow
                                        key={skuIndex}
                                        sx={{ padding: "8px" }}
                                      >
                                        <TableCell
                                          sx={{ textAlign: "center" }}
                                          colSpan={2}
                                        >
                                          {skuIndex + 1}
                                        </TableCell>
                                        <TableCell
                                          sx={{ textAlign: "center" }}
                                          colSpan={2}
                                        >
                                          {sku?.sku_name}
                                        </TableCell>
                                        <TableCell
                                          sx={{ textAlign: "center" }}
                                          colSpan={2}
                                        >
                                          {sku?.cost}
                                        </TableCell>
                                        <TableCell
                                          sx={{ textAlign: "center" }}
                                          colSpan={2}
                                        >
                                          {sku?.quantity}
                                        </TableCell>
                                        <TableCell
                                          sx={{ textAlign: "center" }}
                                          colSpan={2}
                                        >
                                          {sku?.carton_qty ?? 0}
                                        </TableCell>
                                        <TableCell
                                          sx={{ textAlign: "center" }}
                                          colSpan={2}
                                        >
                                          {sku?.ladi_qty ?? "NA"}
                                        </TableCell>
                                        <TableCell
                                          sx={{ textAlign: "center" }}
                                          colSpan={2}
                                        >
                                          ₹{sku?.final_total_cost}
                                        </TableCell>
                                      </TableRow>
                                    ))}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </TableCell>
                          </TableRow>
                        </>
                      )}
                    </React.Fragment>
                  ))}
              </>
            ) : (
              <TableRow>
                <TableCell align="center">No Data</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={saleDetails && saleDetails?.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        className={styles.pagination} // Update with your styles
      />
    </div>
  );
};

export default SalesTable;
