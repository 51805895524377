import React, { useEffect, useState } from "react";
import { MenuItem, Stack, Typography, Button } from "@mui/material";
import * as XLSX from 'xlsx';
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import useSuperCategoryList from "../../hooks/useSuperCategoryList";
import { useSelector } from "react-redux";
import useCategoryListBySuperCategory from "../../hooks/useCategoryListBySuperCategory";
import { Link, useNavigate } from "react-router-dom";
import InputLabel from "@mui/material/InputLabel";
import { IconButton, InputAdornment, TextField } from "@material-ui/core";
import SearchIcon from "@mui/icons-material/Search";
import useDistributorBySc from "../../hooks/useDistributorBySc";
import useDistInventoryList from "../../hooks/useDistInventoryList";
import DownloadIcon from "@mui/icons-material/Download";
import useInventoryTemplateDownload from "../../hooks/useInventoryTemplateDownload";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { styled } from '@mui/material/styles';
import useUploadInventoryExcel from "../../hooks/useUploadInventoryExcel";
const InventoryDropdown = ({setdropDown, query, setQuery}) => {
  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });

  const navigate = useNavigate();
  const getSuperCategory = useSuperCategoryList();
  const getCategoryList = useCategoryListBySuperCategory();
  const superCategoryList = useSelector(
    (state) => state.superCategory.superCategoryList
  );
  const categoryList = useSelector(
    (state) => state.category.categoryListBySuperCategory
  );
  const zoneData = useSelector((state) => state.app.curretDropdownData);
  const distributorList = useSelector((state) => state.distributor.distributorListBySC)
  const [form, setForm] = React.useState({
    sc_name: superCategoryList?.[0]?.sc_name || '',
    sc_id: superCategoryList?.[0]?.sc_id || 0,
    scIdArray: superCategoryList?.[0] ? [superCategoryList[0].sc_id] : [],
    status: true,
  });


  const [selectedCategory, setSelectedCategory] = useState('All');
  const [selectedDist, setSelectedDist] = useState("");
  const [categoryListWithAll, setCategoryListWithAll] = useState([]);
  useEffect(() => {
    if(categoryList){
  const NewcategoryList = [{ category_id: 0, category_name: 'All', status :true }, ...categoryList];
  setCategoryListWithAll(NewcategoryList);
    }
  },[categoryList]);

  useEffect(()=>{
    setdropDown(form);
    // console.log(form, "form");
  }, [form])
  

  useEffect(() => {
    getSuperCategory();
  }, []);
  const getDistributorList = useDistributorBySc();
  useEffect(() => {
    if (superCategoryList?.length > 0) {
      const firstCategory = superCategoryList[0];
      setForm((prev) => ({
        ...prev,
        sc_name: firstCategory.sc_name,
        sc_id: firstCategory.sc_id,
        scIdArray: [firstCategory.sc_id]
      }));
      getCategoryList(firstCategory.sc_id);

      const param = {
        zone_id: zoneData.zone_id,
        sc_id: firstCategory.sc_id
      }
      getDistributorList(param);
    }
    
  }, [superCategoryList]);

  useEffect(() => {
    if (distributorList?.length > 0) {
      const firstDistributor = distributorList[0];
      setForm((prev) => ({
        ...prev,
        dist_id: firstDistributor.dist_id,
        dist_name: firstDistributor.shop_name,
      }));
      setSelectedDist(firstDistributor.shop_name)

      const param = {
        dist_id: firstDistributor.dist_id,
        category_ids: form.CategoryIdArray,
      };
      getInventoryList(param);
    }else{
      setForm((prev) => ({
        ...prev,
        dist_id: null,
        dist_name: null,
      }));
      setSelectedDist(null)
    }
    
  }, [distributorList]);

  useEffect(() => {
    if (selectedCategory === "All" && categoryList?.length > 0 && zoneData) {
      const categoryIds = categoryList?.filter((sku) => sku.status === true).map(category => category.category_id);
      setForm((prev) => ({
        ...prev,
        CategoryIdArray: categoryIds,
      }));
      const param = {
        zone_id: zoneData.zone_id,
        sc_id: form?.sc_id
      }
      getDistributorList(param);
    }
   }, [categoryList, zoneData]);
  const handleSuperCategory = async (e) => {
    let scId = 0;
    let scIdArray = [];
    let scName = null;

    for (let i = 0; i < superCategoryList?.length; i++) {
      if (e.target.value === superCategoryList[i]?.sc_name) {
        scId = superCategoryList[i]?.sc_id;
        scName = superCategoryList[i]?.sc_name;
        scIdArray.push(scId);
        break;
      }
    }

    // Update the state
    setForm((prev) => ({
      ...prev,
      sc_name: scName,
      sc_id: scId,
      scIdArray: scIdArray,
    }));
    
    await getCategoryList(scId);

    const param = {
      zone_id: zoneData.zone_id,
      sc_id: scId
    }
    getDistributorList(param);
  };

  const handleCategoryChange = async (e) => {
    const selectedValue = e.target.value;
    let CategoryId = 0;
    let CategoryIdArray = [];
    let CategoryName = selectedValue;

    if (selectedValue !== 'All') {
      for (let i = 0; i < categoryList.length; i++) {
        if (selectedValue === categoryList[i].category_name) {
          CategoryId = categoryList[i].category_id;
          CategoryIdArray.push(CategoryId);
          break;
        }
      }
    } else {
      CategoryIdArray = categoryList.filter((sku) => sku.status === true).map(category => category.category_id);
    }

    // Update the state
    setForm((prev) => ({
      ...prev,
      CategoryName: CategoryName,
      CategoryId: CategoryId,
      CategoryIdArray: CategoryIdArray,
    }));
    const param = {
      dist_id: form.dist_id,
      category_ids: CategoryIdArray,
    };

    await getInventoryList(param);
    // await getSkuList(CategoryId, form.sc_id, CategoryIdArray);
  };

  const getInventoryList = useDistInventoryList();
  const handleDistChange = async (e) => {
    let dist_id = 0;
    
    for (let i = 0; i < distributorList?.length; i++) {
      if (e.target.value === distributorList[i]?.shop_name) {
        dist_id = distributorList[i]?.dist_id;
        break;
      }
    }
    setSelectedDist(e.target.value);
    setForm((prev) => ({
      ...prev,
      dist_name: e.target.value,
      dist_id: dist_id,
    }));
    const param = {
      dist_id: dist_id,
      category_ids: form.CategoryIdArray,
    };

    await getInventoryList(param);
  };

  const handleSearch = (e) => {
    setQuery(e.target.value);
  };
  const getInveotoryTemplate = useInventoryTemplateDownload();
  const handleDownloadTemplate = async() => {
    const param = {
      zone_id: zoneData.zone_id,
      sc_id: form.sc_id,
    };
    let data = [];
    data = await getInveotoryTemplate(param);

    const workbook = XLSX.utils.book_new();

  data.forEach((dist, index) => {
    // Create a new sheet for each distributor
    const sheetName = dist.shop_name || `Sheet${index + 1}`;
    
    // Prepare the data for the sheet
    const sheetData = [];

    // Add distributor details in the first three rows, key and value in separate columns
    sheetData.push(['Distr. Name', dist.dist_name]);
    sheetData.push(['Firm Name', dist.firm_name]);
    sheetData.push(['Distr_id', dist.dist_id]);
    sheetData.push([]); // Empty row before SKU data

    // Add headers for SKU data
    sheetData.push([
      'SKU ID', 
      'SKU Name', 
      'Cartons', 
      'Alert Quantity'
    ]);

    // Add the SKU data
    dist.sku_data.forEach(sku => {
      sheetData.push([
        sku.sku_id,   // Replace with actual SKU field names
        sku.sku_name, 
        sku.cartons,
        sku.alert_qty,
        // Add other SKU fields as needed
      ]);
    });
    
    // Convert the sheet data to a worksheet
    const worksheet = XLSX.utils.aoa_to_sheet(sheetData);
    worksheet['!cols'] = [
      { wch: 20 }, 
      { wch: 20 }, 
    ];
    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
  });

  // Generate and download the Excel file
  XLSX.writeFile(workbook, 'InventoryUploadTemplate.xlsx');
  };
  const uploadInventoryExcel = useUploadInventoryExcel();
  const handleFileChange = async (event) => {
    const inventoryExcel = event.target.files[0];
    const formData = new FormData();
  
    // Append the file and other data to FormData
    formData.append('inventory_dump', inventoryExcel);
    formData.append('sc_id', form.sc_id);
  
    // Upload the form data
    await uploadInventoryExcel(formData);
  
    // Prepare parameters for the next API call
    const param = {
      dist_id: form.dist_id,
      category_ids: form.CategoryIdArray,
    };
  
    // Fetch inventory list
    await getInventoryList(param);
  
    // Reset the file input
    event.target.value = null;
  };

  return (
    <Box
      sx={{
        minWidth: 140,
        display: "flex",
        flexDirection: "row",
        gap: "1rem",
        width: "100%",
        justifyContent: "space-between",
        marginTop: "6px",
      }}
    >
      <Stack
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "1rem",
          alignItems: "center",
        }}
      >
        <div>
          <TextField
            variant="outlined"
            style={{
              borderRadius: "4px",
              width: "185px",
              "& .MuiInputBase-root": {
                height: "35px",
              },
            }}
            size="small"
            // value={query}
            onChange={handleSearch}
            placeholder="Search SKU"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton>
                    <SearchIcon /> {/* Assume SearchIcon is imported */}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </div>
        <FormControl
          sx={{
            minWidth: 160,
            backgroundColor: "#fff",
            "& .MuiSelect-select": { backgroundColor: "#fff" },
          }}
        >
          <InputLabel sx={{ top: "-15px", color: "#989797" }}>
            Super Category
          </InputLabel>
          <Select
            labelId="sc-label"
            id="sc-select"
            value={form.sc_name}
            size="small"
            sx={{ height: "35px", minWidth: 160 }}
            onChange={handleSuperCategory}
          >
            {superCategoryList
              ?.filter((sku) => sku.status === true)
              .map((item, index) => (
                <MenuItem value={item.sc_name} key={item.sc_id}>
                  {item.sc_name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        <FormControl
          sx={{
            minWidth: 160,
            backgroundColor: "#fff",
            "& .MuiSelect-select": { backgroundColor: "#fff" },
          }}
        >
          <InputLabel sx={{ top: "-15px", color: "#989797" }}>
            Category
          </InputLabel>
          <Select
            labelId="shape-id-label"
            id="shape-id-select"
            value={selectedCategory}
            size="small"
            sx={{ height: "35px", minWidth: 160 }}
            onChange={(e) => {
              setSelectedCategory(e.target.value);
              handleCategoryChange(e);
            }}
          >
            {categoryListWithAll
              .filter((sku) => sku.status === true)
              .map((item) => (
                <MenuItem value={item.category_name} key={item.category_id}>
                  {item.category_name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        <FormControl
          sx={{
            minWidth: 160,
            backgroundColor: "#fff",
            "& .MuiSelect-select": { backgroundColor: "#fff" },
          }}
        >
          <InputLabel sx={{ top: "-15px", color: "#989797" }}>
            Distributor
          </InputLabel>
          <Select
            labelId="shape-id-label"
            id="shape-id-select"
            value={selectedDist}
            size="small"
            sx={{ height: "35px", minWidth: 160 }}
            onChange={(e) => {
              handleDistChange(e);
            }}
          >
            {distributorList?.map((item, index) => (
              <MenuItem value={item.shop_name} key={item.dist_id}>
                {item.shop_name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Stack>
      <Stack sx={{ flexDirection: "row", gap: "1rem", alignItems: "center" }}>
        <Button
          variant="outlined"
          sx={{
            color: "#EF6C1A !important",
            fontWeight: "700",
            backgroundColor: "white !important",
            "&:hover": {
              backgroundColor: "#EF6C1A !important",
              color: "white !important",
              border: "2px solid #EF6C1A !important",
            },
            border: "2px solid #EF6C1A !important",
            padding: "14px 12px 14px 12px !important",
            alignItems: "center",
            height: "30px !important",
            fontSize: "14px !important",
            borderRadius: "4px !important",
            textTransform: "none",
          }}
          onClick={() => handleDownloadTemplate()}
        >
          Template <DownloadIcon fontSize="small" />
        </Button>
        
        <Button
          sx={{
            textTransform: "none",
            padding: "14px 12px 14px 12px !important",
            fontSize: "14px !important",
          }}
          component="label"
          role={undefined}
          variant="contained"
          tabIndex={-1}
          startIcon={<CloudUploadIcon />}
        >
          Upload files
          <VisuallyHiddenInput
            type="file"
            accept=".xlsx, .xls"
            onChange={(event) => handleFileChange(event)}
            multiple
          />
        </Button>
      </Stack>
    </Box>
  );
};

export default InventoryDropdown;
