
import useAxiosPrivate from "../useAxiosPrivate";
import { useDispatch } from "react-redux";

const useUpdateEmployeeArea = () => {
  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();

  const UpdateEmployeeArea = async (param) => {
    console.log(param, "update");
    try {
      const response = await Axios.post("update-employee-area", param);
      // console.log(response, "create_route");
      const data = response.data;
      return data;  
    } catch (error) {
      console.error("Error fetching admin map data:", error);
      throw error;  
    }
  };
  return UpdateEmployeeArea;
};

export default useUpdateEmployeeArea;


