import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { useSelector } from "react-redux";

export default function SaleStampTable({searchTerm}) {
  const tableDetails = useSelector((store) => store.sr.SRSaleTableDetails);
  // useSRSaleTableDetails();
  const row = [
    "SE",
    "No Sale Order",
    "On-Loc Orders",
    "On-Loc Orders Amount",
    "Off-Loc Orders",
    "Off-Loc Orders Amount",
  ];

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper sx={{ width: "100%" }}>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {row?.map((item) => (
                <TableCell
                  sx={{
                    background: "#F8F8F8",
                    fontSize: "16px",
                    color: "#5F6868",
                    fontWeight: "500",
                  }}
                  align="start"
                  colSpan={1}
                >
                  {item}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {tableDetails
            ?.filter((sr)=>sr?.first_name?.toLowerCase()?.includes(searchTerm?.toLowerCase()))
              ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((data, index) => {
                return (
                  <>
                    <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                      <TableCell align="start" colSpan={1}>
                        {data?.first_name}
                      </TableCell>
                      <TableCell align="start" colSpan={1}>
                        {data.no_sale_count ?? "N/A"}
                      </TableCell>
                      <TableCell align="start" colSpan={1}>
                        {data.on_location_count ?? "N/A"}
                      </TableCell>
                      <TableCell align="start" colSpan={1}>
                        {data?.on_location_cost ?? "N/A"}
                      </TableCell>
                      <TableCell align="start" colSpan={1}>
                        {data?.off_location_count ?? "N/A"}
                      </TableCell>
                      <TableCell align="start" colSpan={1}>
                        {data?.off_location_cost ?? "N/A"}
                      </TableCell>
                    </TableRow>
                  </>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={tableDetails?.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
