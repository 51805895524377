
import useAxiosPrivate from "./useAxiosPrivate";
import { useDispatch} from "react-redux";

import { ToastContainer, toast } from "react-toastify";
const useEcoNonEcoDownload = () => {
  
  const Axios = useAxiosPrivate();

  const getEcoNonEcoDownload = async (param) => {
    
    const response = await Axios.post("eco_non_eco_download", param);
    const data = await response.data;

    if (data?.code === 200) {
      toast.success(data?.message);
    } else {
      toast.error(data?.message);
    }
    return data?.body;
    };

  return getEcoNonEcoDownload;
};

export default useEcoNonEcoDownload;
