import * as React from "react";
import { useState, useEffect } from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Box from "@mui/material/Box";
import { Stack, Button } from "@mui/material";
import useStateList from "../../hooks/useStateList";
import { useDispatch, useSelector } from "react-redux";
import MenuItem from "@mui/material/MenuItem";
import useSchemeZoneList from "../../hooks/useSchemeZoneList";
import useSchemeRetailerList from "../../hooks/useSchemeRetailerList";
import useAddSchemeState from "../../hooks/useAddSchemeState";
import { ToastContainer } from "react-toastify";
import { getSchemeCurrentData } from "../../slice/schemeSlice";
import { useNavigate } from "react-router";

export default function RadioButton({
  sendDataToParent,
  selectedSkuId,
  selectedFreeSkuId,
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [selectedState, setSelectedState] = useState(null);
  const [selectedZone, setSelectedZone] = useState(null);
  const [obj, setObj] = useState({});
  const [radioButtonStatus, setRadioButtonStatus] = useState({
    state: true,
    zone: false,
    boundary: false,
    outlets: false,
  });

  //----------Redux--------------
  const StateList = useSelector((state) => state.app.stateList);
  const zoneList = useSelector((state) => state.app.schemeZoneList);
  const retailerAllList = useSelector((store) => store.retailer.retailerList);
  //----------Redux--------------

  //----------Api Calls--------------
  useStateList();
  const getAllSchemeZoneList = useSchemeZoneList();
  const getAllSchemeRetailerList = useSchemeRetailerList();
  const addSchemeState = useAddSchemeState(radioButtonStatus);
  //----------Api Calls--------------

  useEffect(() => {
    setSelectedState(StateList?.[0]?.state_name);
    getAllSchemeZoneList(StateList?.[0]?.state_id);
    setObj((prev) => ({
      ...prev,
      state_id: StateList?.[0]?.state_id,
      state_name: StateList?.[0]?.state_name,
    }));
  }, [StateList]);

  useEffect(() => {
    setSelectedZone(zoneList?.[0]?.zone_name);
    setObj((prev) => ({
      ...prev,
      zone_id: zoneList?.[0]?.zone_id,
      zone_name: zoneList?.[0]?.zone_name,
    }));
    getAllSchemeRetailerList(zoneList?.[0]?.zone_id);
  }, [zoneList]);

  useEffect(() => {
    sendDataToParent(radioButtonStatus);
  }, [radioButtonStatus]);

  const handleChange = (e) => {
    const { value } = e.target;
    setRadioButtonStatus((prev) => ({
      state: value === "state",
      zone: value === "zone",
      boundary: value === "boundary",
      outlets: value === "outlets",
    }));
    if (value === "outlets") {
      setTimeout(() => {
        const retailerTable = document.getElementById("retailerTable");
        if (retailerTable) {
          retailerTable.scrollIntoView({
            behavior: "smooth",
            block: "end",
            inline: "nearest",
          });
        } else {
          console.error('Element with ID "retailerTable" not found.');
        }
      }, 200);
    }
  };

  const handleStateChange = (e) => {
    setSelectedState(e.target.value);
    let stateId = 0;
    for (let i = 0; i < StateList?.length; i++) {
      if (e.target.value === StateList?.[i]?.state_name) {
        stateId = StateList?.[i].state_id;
      }
    }

    setObj((prev) => ({
      ...prev,
      [e.target.name]: stateId,
    }));

    getAllSchemeZoneList(stateId);
  };

  const handleZoneChange = (e) => {
    setSelectedZone(e.target.value);
    let zoneId = 0;
    for (let i = 0; i < zoneList?.length; i++) {
      if (e.target.value === zoneList?.[i].zone_name) {
        zoneId = zoneList[i].zone_id;
      }
    }
    getAllSchemeRetailerList(zoneId);

    setObj((prev) => ({
      ...prev,
      zone_id: zoneId,
      zone_name: e.target.value,
    }));
  };

  const handleSubmit = async () => {
    const result = await addSchemeState(obj, selectedSkuId, selectedFreeSkuId);
    if (result?.code === 200) {
      dispatch(getSchemeCurrentData(obj));
    }
  };
  const handleCancle = () => {
    navigate("/home/Schememgt");
  };
  return (
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          flexWrap: "wrap",
        }}
      >
        <FormControl
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            flexWrap: "wrap",
          }}
        >
          {/* <FormLabel id="demo-radio-buttons-group-label">Gender</FormLabel> */}
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue="state"
            name="radio-buttons-group"
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
            onChange={(e) => handleChange(e)}
          >
            <FormControlLabel
              value="state"
              control={
                <Radio
                  sx={{
                    height: "18px",
                    alignSelf: "center",
                    "& .MuiSvgIcon-root": {
                      color: "#1C1B1F",
                    },
                  }}
                />
              }
              label="State"
            />
            <FormControlLabel
              value="zone"
              control={
                <Radio
                  sx={{
                    height: "18px",
                    alignSelf: "center",
                    "& .MuiSvgIcon-root": {
                      color: "#1C1B1F",
                    },
                  }}
                />
              }
              label="Zone"
            />
            {/* <FormControlLabel
              value="boundary"
              control={
                <Radio
                  sx={{
                    height: "18px",
                    alignSelf: "center",
                    "& .MuiSvgIcon-root": {
                      color: "#1C1B1F",
                    },
                  }}
                />
              }
              label="Boundary"
            /> */}
            <FormControlLabel
              value="outlets"
              control={
                <Radio
                  sx={{
                    height: "18px",
                    alignSelf: "center",
                    "& .MuiSvgIcon-root": {
                      color: "#1C1B1F",
                    },
                  }}
                />
              }
              label="Key Outlets"
            />
            {radioButtonStatus.state === true ? (
              <FormControl sx={{ minWidth: 180 }}>
                <Select
                  labelId="state-id-label"
                  id="state-id-select"
                  name="state_id"
                  value={selectedState}
                  size="small"
                  sx={{ height: "2.2rem" }}
                  onChange={(e) => handleStateChange(e)}
                >
                  {StateList?.map((state) => (
                    <MenuItem key={state?.state_id} value={state?.state_name}>
                      {state?.state_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : radioButtonStatus.zone === true ? (
              <Stack
                sx={{ display: "flex", flexDirection: "row", gap: "1rem" }}
              >
                <FormControl sx={{ minWidth: 180 }}>
                  <Select
                    labelId="state-id-label"
                    id="state-id-select"
                    name="state_id"
                    value={selectedState}
                    size="small"
                    sx={{ height: "2.2rem" }}
                    onChange={(e) => handleStateChange(e)}
                  >
                    {StateList?.map((state) => (
                      <MenuItem key={state?.state_id} value={state?.state_name}>
                        {state?.state_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl sx={{ minWidth: 180 }}>
                  <Select
                    labelId="zone-id-label"
                    id="zone-id-select"
                    name="zone_id"
                    value={selectedZone}
                    size="small"
                    sx={{ height: "2.2rem" }}
                    onChange={(e) => handleZoneChange(e)}
                  >
                    {zoneList
                      ? zoneList?.map((zone) => (
                          <MenuItem key={zone?.zone_id} value={zone?.zone_name}>
                            {zone?.zone_name}
                          </MenuItem>
                        ))
                      : []}
                  </Select>
                </FormControl>
              </Stack>
            ) : radioButtonStatus.boundary === true ? (
              <Stack
                sx={{ display: "flex", flexDirection: "row", gap: "1rem" }}
              >
                <FormControl sx={{ minWidth: 180 }}>
                  <Select
                    labelId="shape-id-label"
                    id="shape-id-select"
                    name="state_id"
                    value={selectedState}
                    size="small"
                    sx={{ height: "2.2rem" }}
                    onChange={(e) => handleStateChange(e)}
                  >
                    {StateList?.map((state) => (
                      <MenuItem key={state?.state_id} value={state?.state_name}>
                        {state?.state_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl sx={{ minWidth: 180 }}>
                  <Select
                    labelId="zone-id-label"
                    id="zone-id-select"
                    name="zone_id"
                    value={obj?.zone_name}
                    size="small"
                    sx={{ height: "2.2rem" }}
                    onChange={(e) => handleZoneChange(e)}
                  >
                    {zoneList
                      ? zoneList?.map((zone) => (
                          <MenuItem key={zone?.zone_id} value={zone?.zone_name}>
                            {zone?.zone_name}
                          </MenuItem>
                        ))
                      : []}
                  </Select>
                </FormControl>
                {/* <FormControl sx={{ minWidth: 180 }}>
                  <Select
                    labelId="shape-id-label"
                    id="shape-id-select"
                    name="shape_id"
                    value={selectedShapeId}
                    size="small"
                    sx={{ height: "2.2rem" }}
                    onChange={(e) => handleShapeId(e)}
                  >
                    {Array.from(
                      new Set(retailerAllList?.map((item) => item.shape_id))
                    ).map((shapeId) => (
                      <MenuItem key={shapeId} value={shapeId}>
                        {shapeId}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl> */}
              </Stack>
            ) : (
              <Stack
                sx={{ display: "flex", flexDirection: "row", gap: "1rem" }}
              >
                <FormControl sx={{ minWidth: 180 }}>
                  <Select
                    labelId="shape-id-label"
                    id="shape-id-select"
                    name="state_id"
                    value={selectedState}
                    size="small"
                    sx={{ height: "2.2rem" }}
                    onChange={(e) => handleStateChange(e)}
                  >
                    {StateList?.map((state) => (
                      <MenuItem key={state?.state_id} value={state?.state_name}>
                        {state?.state_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl sx={{ minWidth: 180 }}>
                  <Select
                    labelId="zone-id-label"
                    id="zone-id-select"
                    name="zone_id"
                    value={obj?.zone_name}
                    size="small"
                    sx={{ height: "2.2rem" }}
                    onChange={(e) => handleZoneChange(e)}
                  >
                    {zoneList
                      ? zoneList?.map((zone) => (
                          <MenuItem key={zone?.zone_id} value={zone?.zone_name}>
                            {zone?.zone_name}
                          </MenuItem>
                        ))
                      : []}
                  </Select>
                </FormControl>

                {/* <FormControl sx={{ minWidth: 180 }}>
                  <Select
                    labelId="shape-id-label"
                    id="shape-id-select"
                    name="shape_id"
                    value={selectedShapeId}
                    size="small"
                    sx={{ height: "2.2rem" }}
                    onChange={(e) => handleShapeId(e)}
                  >
                    {Array.from(
                      new Set(retailerAllList?.map((item) => item.shape_id))
                    ).map((shapeId) => (
                      <MenuItem key={shapeId} value={shapeId}>
                        {shapeId}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl> */}
              </Stack>
            )}
          </RadioGroup>
        </FormControl>

        <Stack
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: "1rem",
            flexWrap: "wrap",
          }}
        >
          <Button
            sx={{
              background: "white !important",
              color: "#EF6C1A !important",
              border: "1px solid #FF5A5A",
              height: "44px",
              padding: "18px 16px",
              fontSize: "12px",
              fontWeight: 600,
              textTransform: "none",
            }}
            onClick={handleCancle}
          >
            Cancel
          </Button>
          <Button
            sx={{
              background: "#FF5A5A",
              color: "white",
              paddingLeft: "1.2rem",
              paddingRight: "1.2rem",
              height: "44px",
              padding: "18px 16px",
              "&:hover": { background: "#FF5A5A" },
            }}
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </Stack>
      </Box>
    </>
  );
}
