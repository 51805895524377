import React from "react";
import useAxiosPrivate from "./useAxiosPrivate";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { BaseImgUrl } from "../utility/BaseURL";
const useAddSuperCategory = (form,checked) => {
  console.log(checked,"1111111");
  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();
  const superCategoryFormData = useSelector(
    (store) => store.superCategory.superCategoryFormData
  );
  const addSuperCategory = async () => {
    let param = {
      sc_name: form?.super_category,
      sc_image: superCategoryFormData?.full_path,
      ispiece:checked?.piece,
      isladi:checked?.ladi,
      iscarton:checked?.carton
    };
    // console.log(param, "distributorList");
    const response = await Axios.post("/add_super_category_list", param);
    const data = await response.data;
    // console.log(data, "distributorList");
    if (data.code === 200) {
      toast.success("Added SuccessFully");
    }else if(data.code===400){
      toast.error(data?.message)
    }
     else {
      toast.error("Failed to Add");
    }

    // console.log(data);
  };
  // useEffect(() => {
  //   getAllUserList();
  // }, []);
  return addSuperCategory;
};

export default useAddSuperCategory;
