import React, { useEffect, useState } from 'react';
import { Modal, Box, TextField, Stack, Typography, Button } from '@mui/material';
import useAddInventoryQty from '../../hooks/useAddInventoryQty';
// import useInventoryList from '../../../hooks/useInventoryList';
import useDistInventoryList from "../../hooks/useDistInventoryList";
const AddInventory = ({ open, handleClose, inputValues }) => {
    const [form, setForm] = useState({
        new_inventory: '',
        new_alert:''
    });

    useEffect(() => {
        setForm(prevForm => ({
          ...prevForm,
          ...inputValues
        }));
      }, [inputValues]);
      
      const [errors, setErrors] = useState({
        new_inventory: "",
        new_alert: "",
      });

      const handleInputChange = (event) => {
        const { name, value } = event.target;
        let errorMessage = '';
    
        // Validate the value
        if (value === '' || (Number(value) > 0 && Number.isInteger(Number(value)))) {
          setForm({ ...form, [name]: value });
        } else {
          errorMessage = 'Please enter a positive number';
        }
    
        setErrors({
          ...errors,
          [name]: errorMessage
        });
      };

    const getInventoruList = useDistInventoryList();
    const addInventoryQty = useAddInventoryQty();
    const handleSubmit = async () => {
      await addInventoryQty(form);
      handleClose();
      await getInventoruList(form);
      setForm({
        new_inventory: "",
        new_alert: "",
      });
      setErrors({
        new_inventory: "",
        new_alert: "",
      });
    };
    const handleClosePopUp = async () => {
      handleClose();
      setForm({
        new_inventory: "",
        new_alert: "",
      });
      setErrors({
        new_inventory: "",
        new_alert: "",
      });
    };
    
  return (
    <Modal
      open={open}
      onClose={handleClose}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "& .MuiBackdrop-root": {
          backgroundColor: "rgba(0, 0, 0, 0.1)",
        },
      }}
      BackdropProps={{
        style: { backgroundColor: "rgba(0, 0, 0, 0.1)" },
      }}
    >
      <Box
        sx={{
          bgcolor: "background.paper",
          borderRadius: 1,
          boxShadow: 4,
          p: 4,
          width: "90%",
          maxWidth: "500px",
        }}
      >
        <Typography variant="h6" mb={2}>
          Add Stock
        </Typography>
        <Stack spacing={2}>
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 2 }}>
            <TextField
              value={`${inputValues?.sc_name}`}
              variant="outlined"
              disabled
              size="small"
              sx={{
                flex: "1 1 45%",
                "& .MuiInputBase-root": {
                  height: "35px",
                },
              }}
            />
            <TextField
              value={inputValues?.category_name}
              variant="outlined"
              disabled
              size="small"
              sx={{
                flex: "1 1 45%",
                "& .MuiInputBase-root": {
                  height: "35px",
                },
              }}
            />
            <TextField
              value={inputValues?.sku_name}
              variant="outlined"
              disabled
              size="small"
              sx={{
                flex: "1 1 45%",
                "& .MuiInputBase-root": {
                  height: "35px",
                },
              }}
            />
            <TextField
              value={inputValues?.dist_name}
              variant="outlined"
              disabled
              size="small"
              sx={{
                flex: "1 1 45%",
                "& .MuiInputBase-root": {
                  height: "35px",
                },
              }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 2,
              justifyContent: "end",
            }}
          >
            <Typography>Please select Cartons to be added :</Typography>
            <TextField
              variant="outlined"
              size="small"
              name = "new_inventory"
              type='number' 
              placeholder={inputValues?.last_updated_cartons}
              onChange={handleInputChange}
              sx={{
                flex: "1 1 45%",
                maxWidth: "140px",
                "& .MuiInputBase-root": {
                  height: "35px",
                  width: "140px",
                },
              }}
              error={!!errors.new_inventory}
            helperText={errors.new_inventory}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 2,
              justifyContent: "end",
            }}
          >
            <Typography>Stock Alert @:</Typography>
            <TextField
              variant="outlined"
              size="small"
              name="new_alert"
              type='number' 
              placeholder={inputValues?.alert_pieces}
              onChange={handleInputChange}
              sx={{
                flex: "1 1 45%",
                maxWidth: "140px",
                "& .MuiInputBase-root": {
                  height: "35px",
                  width: "140px",
                },
              }}
              error={!!errors.new_alert}
            helperText={errors.new_alert}
            />
          </Box>
        </Stack>
        <Box
          sx={{ display: "flex", justifyContent: "flex-end", mt: 3, gap: 2 }}
        >
          <Button
            onClick={handleClosePopUp}
            sx={{
              backgroundColor: "#fff !important",
              color: "#000 !important",
              fontWeight: "700 !important",
              padding: "6px 12px 6px 12px !important",
              borderRadius: "6px !important",
              border:"1px solid #000 !important"
            }}
          >
            Close
          </Button>
          <Button
            onClick={() =>handleSubmit()}
            sx={{
              backgroundColor: "#EF6C1A !important",
              color: "#fff !important",
              fontWeight: "700 !important",
              padding: "6px 12px 6px 12px !important",
              borderRadius: "6px !important",
            }}
          >
            Add
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default AddInventory;
