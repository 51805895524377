import React, { useEffect, useState } from "react";
import {
  GoogleMap,
  LoadScript,
  Polygon,
  Marker,
  Polyline,
} from "@react-google-maps/api";
import usePincodeData from "../../hooks/useGetPincodeAssign";
import "./assets/mapStyle.css";
import {
  resetSeId,
  setPincode,
  setSeIdList,
  setShapeId,
} from "../../slice/assignSEslice";
import { useSelector, useDispatch } from "react-redux";
import { markerIcon } from "./MarkerIcon";
import polyDataMarker from "./assets/markersImg/dotBlack.png";
import HomeIcon from "./assets/markersImg/house.png";

import { setRoutePincode, setRouteShapeId } from "../../slice/assignRouteSlice";
import {
  resetRouteClusterList,
  resetAssignedBoundryDetails,
  resetReqBoundryDetails,
  getSelectedPincodeDetails,
} from "../../slice/adminMapSlice";

// Makrer for Router Cluster
import RED from "./assets/routeMarker/red.png";
import BLUE from "./assets/routeMarker/blue.png";
import CYAN from "./assets/routeMarker/cyan.png";
import GREEN from "./assets/routeMarker/green.png";
import ORANGE from "./assets/routeMarker/orange.png";
import PINK from "./assets/routeMarker/pink.png";
import { Stack, Box, Typography } from "@mui/material";
import useAssignedRoute from "../../hooks/useAssignedRoute";
import useGetSEinSP from "../../hooks/useGetSEinShapeFile";
import useAssgnedUserData from "../../hooks/useAssignedUserData";
import blueMarker from "./assets/markersImg/blue.png";
import { Link } from "react-router-dom";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import redMarker from "./assets/markersImg/red.png";
import PersonPinCircleIcon from '@mui/icons-material/PersonPinCircle';
const MapComponent = ({
  pincodeCord,
  retailerCord,
  showDailyRoute,
  assignedBoundryRoutes,
}) => {
  const markerIconsByDay = {
    monday: RED,
    tuesday: GREEN,
    wednesday: BLUE,
    thursday: ORANGE,
    friday: CYAN,
    saturday: PINK,
  };

  const daysOfWeekColors = {
    monday: "#FF0000",
    tuesday: "#00FF00",
    wednesday: "#0000FF",
    thursday: "#ff8c00",
    friday: "#17EAE3",
    saturday: "#C139C3",
  };

  const days = Object.keys(daysOfWeekColors);
  const [center, setCenter] = useState();

  const zoneId = useSelector((state) => state.assignRoute.zone_id);
  const zoneData = useSelector((state) => state.app.zoneList);
  const pincodeDetails = useSelector(
    (state) => state.adminMap.getPincodeDetails
  );
  const assignRouteDetails = useSelector(
    (state) => state.adminMap.getRouteClusterDetails
  );
  const routeDetails = useSelector(
    (state) => state.adminMap.getRouteListDetails
  );
  const sc_id = useSelector((state) => state.assignSE.sc_id);
  const reqBoundryDetails = useSelector(
    (state) => state.adminMap.getReqBoundryDetails
  );

  const dispatch = useDispatch();
  const [selectedPolygon, setSelectedPolygon] = useState(null);
  const [selectedPinMarker, setSelectedPincodeMarker] = useState(null);
  const [map, setMap] = useState(null);
  const [bounds, setBounds] = useState(null);
  const [visible, setVisible] = useState(false);
  const [zoomLevel, setZoomLevel] = useState(12);
  useEffect(() => {
    // Check the condition and update the 'visible' state accordingly
    if (pincodeDetails?.marker_list?.length > 0) {
      setVisible(true);
    } else {
      // If the condition is not true, set 'visible' to false
      setVisible(false);
    }
  }, [pincodeDetails]);
  
  const handleZoomChanged = (map) => {
    // Get the current zoom level
    const currentZoom = map?.getZoom();

    // Update the state with the new zoom level
    setZoomLevel(currentZoom);
  };

  useEffect(() => {
    // Find the data object with the matching zoneId
    const selectedZone = zoneData?.find((zone) => zone.zone_id === zoneId);

    // If a matching zone is found, update the centroid state
    if (selectedZone) {
      const [lat, lng] = selectedZone.centroid.split(",").map(Number);
      setCenter({ lat, lng });
    } else {
      // Handle the case where no matching zone is found, if needed
      setCenter(null);
    }
  }, [zoneId, zoneData]);
  const polygonData = pincodeCord?.map((location) =>
    location.coordinates.map((coord) => ({
      lat: coord[1],
      lng: coord[0],
    }))
  );
  useEffect(() => {
    dispatch(resetRouteClusterList());
    dispatch(resetAssignedBoundryDetails());
    dispatch(resetReqBoundryDetails());
    dispatch(resetSeId());
  }, [selectedPolygon]);

  const getAssignPincodeData = usePincodeData();
  const getAssignedRoute = useAssignedRoute();
  const getSEinShapeFile = useGetSEinSP();
  const AssignedUserData = useAssgnedUserData();

  const handlePolygonClick = async (
    pincode,
    shapeId,
    coordinates,
    pincodeDetails
  ) => {
    setSelectedPolygon(pincode);
    dispatch(setPincode(pincode));
    dispatch(setRoutePincode(pincode));
    dispatch(setShapeId(shapeId));
    dispatch(setRouteShapeId(shapeId));
    dispatch(getSelectedPincodeDetails(pincodeDetails));
    const param = {
      pincode: pincode,
      shape_id: shapeId,
      sc_id: sc_id,
    };
    const param2 = {
      zone_id: zoneId,
      pincode: pincode,
      shape_id: shapeId,
      sc_id: sc_id,
    };
    // const markersInsidePolygon = retailerCord?.filter((data) => {
    //   const markerPosition = {
    //     lat: parseFloat(data.lat),
    //     lng: parseFloat(data.lng),
    //   };

    //   return isMarkerInsidePolygon(markerPosition, coordinates);
    // });
    setSelectedPincodeMarker(coordinates);
    // getSEinShapeFile(param2);
    // console.log("result", markersInsidePolygon);
    try {
      // Fetch data concurrently
      const [pincodeData, data, seData] = await Promise.all([
        getAssignPincodeData(param),
        getAssignedRoute(param2),
        AssignedUserData(param),
        // getSEinShapeFile(param2),
        // getSEinShapeFile(param2)
      ]);
    } catch (error) {
      // Handle errors
      console.error("Error fetching data:", error);
    }
  };

  const getMarkerIcon = (zoneId) => {
    return {
      url: markerIcon[zoneId] || markerIcon.default,
      // scaledSize: new window.google.maps.Size(30 + zoomLevel, 30 + zoomLevel),
    };
  };

  // useEffect(() => {
  //   if (map && selectedPinMarker) {
  //     const bounds = new window.google.maps.LatLngBounds();

  //     selectedPinMarker?.forEach((retailer) => {
  //       bounds.extend(
  //         new window.google.maps.LatLng(
  //           parseFloat(retailer.lat),
  //           parseFloat(retailer.lng)
  //         )
  //       );
  //     });

  //     setBounds(bounds);

  //     // Fit the map to the bounds
  //     map.fitBounds(bounds);
  //   }
  // }, [map, selectedPinMarker]);

  const handleMapLoad = (map) => {
    setMap(map);
  };

  // useEffect(() => {
  //   setSelectedPincodeMarker(retailerCord);
  // }, [retailerCord]);

  const isMarkerInsidePolygon = (markerPosition, polygonCoordinates) => {
    const markerLatLng = new window.google.maps.LatLng(
      markerPosition.lat,
      markerPosition.lng
    );

    const polygon = new window.google.maps.Polygon({
      paths: polygonCoordinates,
    });

    return window.google.maps.geometry.poly.containsLocation(
      markerLatLng,
      polygon
    );
  };


  return (
    <div>
      <LoadScript googleMapsApiKey={process.env.REACT_APP_MAP_KEY}>
        <GoogleMap
          onZoomChanged={() => handleZoomChanged(map)}
          onLoad={handleMapLoad}
          mapContainerStyle={{
            width: "100%",
            height: "calc(100vh - 58px)",
            borderRadius: "16px",
            border: "1px solid black",
          }}
          zoom={12}
          center={center}
          options={{
            styles: [
              {
                featureType: "poi",
                elementType: "labels",
                stylers: [{ visibility: "off" }],
              },
              {
                featureType: "road",
                elementType: "labels",
                stylers: [{ visibility: "off" }],
              },
              {
                featureType: "transit",
                elementType: "labels",
                stylers: [{ visibility: "off" }],
              },
              {
                featureType: "poi",
                elementType: "labels.text",
                stylers: [{ visibility: "off" }],
              },
              {
                featureType: "poi",
                elementType: "labels.icon",
                stylers: [{ visibility: "off" }],
              },
              {
                featureType: "administrative",
                elementType: "labels",
                stylers: [{ visibility: "off" }],
              },
            ],
          }}
        >
          <Stack
            sx={{
              position: "absolute",
              top: "10px",
              left: "200px",
              flexDirection: "row",
              gap: "4px",
              backgroundColor: "#fff",
              padding: "4px",
              borderRadius: "6px",
            }}
          >
            <Link
              to="/home/mapView"
              style={{ textDecoration: "none" }}
            >
              <LocationOnIcon sx={{color:"#EF6C1A"}} color="#EF6C1A"/>
            </Link>
          </Stack>
          <Stack
            sx={{
              position: "absolute",
              top: "10px",
              right: "60px",
              flexDirection: "row",
              gap: "4px",
              backgroundColor: "#fff",
              padding: "4px",
              borderRadius: "6px",
            }}
          >
            {(
              [
                ...(reqBoundryDetails?.route_cluster_list?.length > 0
                  ? reqBoundryDetails.route_cluster_list[0].route_list
                  : []),
                ...(assignRouteDetails?.route_cluster_list?.length > 0
                  ? assignRouteDetails.route_cluster_list[0].route_list
                  : []),
                ...(assignedBoundryRoutes?.route_cluster_list?.length > 0
                  ? assignedBoundryRoutes.route_cluster_list[0].route_list
                  : []),
              ] ?? []
            )?.map((routeItem) => (
              <Stack key={routeItem.route_id}>
                <Stack
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "row",
                  }}
                >
                  <Stack
                    sx={{
                      width: 10,
                      height: 10,
                      borderRadius: "50%",
                      backgroundColor: daysOfWeekColors[routeItem.week[0]], // Assuming the first day is the relevant one
                      marginRight: 1,
                    }}
                  ></Stack>
                  <Stack sx={{ flexDirection: "row" }}>
                    {routeItem.week.map((day, index) => (
                      <span key={index} style={{ marginRight: "2px" }}>
                        {day.slice(0, 1).toUpperCase() +
                          day.slice(1, 3).toLowerCase()}
                        {index < routeItem.week.length - 1 && ", "}{" "}
                      </span>
                    ))}
                    {Array.isArray(routeItem.route_cord) && (
                      <span>({routeItem.route_cord.length})</span>
                    )}
                  </Stack>
                </Stack>
              </Stack>
            ))}
          </Stack>

          {polygonData?.map((coordinates, index) => (
            <Polygon
              key={index}
              paths={coordinates}
              options={{
                fillColor: "#ccdbcf",
                fillOpacity: 0.4,
                strokeColor:
                  selectedPolygon === pincodeCord[index]?.pin_code
                    ? "#000"
                    : "#3483eb",
                strokeOpacity: 1,
                strokeWeight:
                  selectedPolygon === pincodeCord[index]?.pin_code ? 4 : 2,
                clickable: true,
              }}
              onClick={() =>
                handlePolygonClick(
                  pincodeCord[index]?.pin_code,
                  pincodeCord[index]?.shape_id,
                  coordinates,
                  routeDetails.sf_details[index]
                )
              }
            />
          ))}
          {routeDetails?.sf_details?.map((data, index) => (
            <Marker
              icon={polyDataMarker}
              key={index}
              position={{
                lat: parseFloat(data.lat),
                lng: parseFloat(data.lng),
              }}
              // styleName="marker-data"
              label={{
                text: `${data?.office_name?.toString()}\n${data.shape_id?.toString()}\n Retailers: ${data.retailers_count?.toString()}\n `,
                // DTR: ${data.se_name?.toString()}\n SE: ${data.dist_name?.toString()}
                fontSize: `${-5 + zoomLevel}px`,
                className: "poly-Marker",
              }}
            />
          ))}

          {showDailyRoute ? (
            <div>
              {routeDetails?.route_list?.map((route, routeIndex) => (
                <React.Fragment key={routeIndex}>
                  {route.route_cord?.length > 0 && (
                    <React.Fragment>
                      <Polyline
                        path={[
                          {
                            lat: parseFloat(route.se_home_lat),
                            lng: parseFloat(route.se_home_lng),
                          }, // Starting coordinate
                          ...route.route_cord?.map((coordinate) => ({
                            lat: parseFloat(coordinate.lat),
                            lng: parseFloat(coordinate.lng),
                          })),
                        ]}
                        options={{
                          strokeWeight: 2,
                          strokeColor: daysOfWeekColors[route.week[0]], // Use modulo to cycle through colors
                          icons: [
                            {
                              icon: {
                                path: "M 0,-1 0,1",
                                scale: 2,
                              },
                              offset: "0",
                              repeat: "20px",
                            },
                          ],
                        }}
                      />
                      <Marker
                        position={{
                          lat: parseFloat(route.se_home_lat),
                          lng: parseFloat(route.se_home_lng),
                        }}
                        label={"S"} // You can customize the label as needed
                        icon={HomeIcon}
                      />
                    </React.Fragment>
                  )}
                  {route.route_cord?.map((coordinate, index) => (
                    <React.Fragment key={index}>
                      {index < route.route_cord.length - 1 && (
                        <React.Fragment>
                          <Polyline
                            path={[
                              {
                                lat: parseFloat(coordinate.lat),
                                lng: parseFloat(coordinate.lng),
                              },
                              {
                                lat: parseFloat(
                                  route.route_cord[index + 1].lat
                                ),
                                lng: parseFloat(
                                  route.route_cord[index + 1].lng
                                ),
                              },
                            ]}
                            options={{
                              strokeWeight: 3,
                              strokeColor: daysOfWeekColors[route.week[0]], // Use modulo to cycle through colors
                              icons: [
                                {
                                  icon: {
                                    path: "M 0,-1 0,1",
                                    scale: 2,
                                  },
                                  offset: "0",
                                  repeat: "20px",
                                },
                              ],
                            }}
                          />
                          {/* Calculate midpoint between two coordinates */}
                        </React.Fragment>
                      )}
                      <Marker
                      zIndex={100}
                        position={{
                          lat: parseFloat(coordinate.lat),
                          lng: parseFloat(coordinate.lng),
                        }}
                        // label={(index + 1).toString()}
                        icon={markerIconsByDay[route.week[0]]}
                      />
                    </React.Fragment>
                  ))}
                </React.Fragment>
              ))}
            </div>
          ) : (
            <></>
          )}

          {(
            [
              ...(reqBoundryDetails?.route_cluster_list?.length > 0
                ? reqBoundryDetails.route_cluster_list
                : []),
              ...(assignRouteDetails?.route_cluster_list?.length > 0
                ? assignRouteDetails.route_cluster_list
                : []),
              ...(assignedBoundryRoutes?.route_cluster_list?.length > 0
                ? assignedBoundryRoutes.route_cluster_list
                : []),
            ] ?? []
          ).map((cluster, clusterIndex) => (
            <React.Fragment key={clusterIndex}>
              {cluster.route_list.map((route, routeIndex) => (
                <React.Fragment key={routeIndex}>
                  {route.route_cord.length > 0 && (
                    <React.Fragment>
                      <Polyline
                        path={[
                          {
                            lat: parseFloat(cluster.se_home_lat),
                            lng: parseFloat(cluster.se_home_lng),
                          },
                          ...route.route_cord.map((coordinate) => ({
                            lat: parseFloat(coordinate.lat),
                            lng: parseFloat(coordinate.lng),
                          })),
                        ]}
                        options={{
                          strokeWeight: 2,
                          strokeColor: daysOfWeekColors[route.week[0]], // Use modulo to cycle through colors
                          icons: [
                            {
                              icon: {
                                path: "M 0,-1 0,1",
                                scale: 2,
                              },
                              offset: "0",
                              repeat: "20px",
                            },
                          ],
                        }}
                      />
                      <Marker
                        position={{
                          lat: parseFloat(cluster.se_home_lat),
                          lng: parseFloat(cluster.se_home_lng),
                        }}
                        label={"S"}
                        icon={HomeIcon}
                      />
                    </React.Fragment>
                  )}
                  {route.route_cord.map((coordinate, index) => (
                    <React.Fragment key={index}>
                      {index < route.route_cord.length - 1 && (
                        <Polyline
                          path={[
                            {
                              lat: parseFloat(coordinate.lat),
                              lng: parseFloat(coordinate.lng),
                            },
                            {
                              lat: parseFloat(route.route_cord[index + 1].lat),
                              lng: parseFloat(route.route_cord[index + 1].lng),
                            },
                          ]}
                          options={{
                            strokeWeight: 3,
                            strokeColor: daysOfWeekColors[route.week[0]], // Use modulo to cycle through colors
                            icons: [
                              {
                                icon: {
                                  path: "M 0,-1 0,1",
                                  scale: 2,
                                },
                                offset: "0",
                                repeat: "20px",
                              },
                            ],
                          }}
                        />
                      )}
                      <Marker
                      zIndex={100}
                        position={{
                          lat: parseFloat(coordinate.lat),
                          lng: parseFloat(coordinate.lng),
                        }}
                        icon={markerIconsByDay[route.week[0]]}
                      />
                    </React.Fragment>
                  ))}
                </React.Fragment>
              ))}
            </React.Fragment>
          ))}

          {/* strokeDasharray: coordinate.completed === "true" ? "5, 5" : "0", */}
          {/* {selectedCategory.map((category) => (
            markerData[category]?.map((marker, index) => (
              <Marker
                icon={getMarkerIcon(category)}
                key={index}
                position={marker}
                styleName="marker-data"
              />
            ))
          ))} */}
          {retailerCord?.map((retailer, index) => (
            <Marker
            zIndex={10}
              key={index}
              position={{
                lat: parseFloat(retailer.lat),
                lng: parseFloat(retailer.lng),
              }}
              // label={retailer.shop_name}
              icon={retailer?.sf_status ? blueMarker : redMarker}
            />
          ))}
          {/* <div>
            {visible
              ? pincodeDetails?.marker_list?.map((markerArray, arrayIndex) => (
                  <div key={arrayIndex}>
                    {markerArray.map((marker, index) => (
                      <Marker
                        key={index}
                        position={{
                          lat: parseFloat(marker.lat),
                          lng: parseFloat(marker.lng),
                        }}
                        icon={ImagesArr[arrayIndex]}
                      />
                    ))}
                  </div>
                ))
              : selectedPinMarker?.map((retailer, index) => (
                  <Marker
                    key={index}
                    position={{
                      lat: parseFloat(retailer.lat),
                      lng: parseFloat(retailer.lng),
                    }}
                    icon={getMarkerIcon(retailer.zone_id)}
                  />
                ))}
          </div> */}
        </GoogleMap>
      </LoadScript>
    </div>
  );
};

export default MapComponent;
