import { createSlice } from "@reduxjs/toolkit";

const schemeSlice = createSlice({
  name: "scheme",
  initialState: {
    schemeList: null,
    schemeCurrentData: [],
    schemeRetailerId: [],
    campaignDetails: null,
    campaignSchemeList: null,
    schemeGraphData: null,
    mrpList: null
  },
  reducers: {
    getSchemeCurrentData: (state, action) => {
      state.schemeCurrentData = action.payload;
    },
    getSchemeRetailerId: (state, action) => {
      state.schemeRetailerId = action.payload;
    },
    getSchemeList: (state, action) => {
      state.schemeList = action.payload;
    },
    getMRPList: (state, action) => {
      state.mrpList = action.payload;
    },
    getCampaignDetails: (state, action) => {
      state.campaignDetails = action.payload;
    },
    getCampaignSchemeList: (state, action) => {
      state.campaignSchemeList = action.payload;
    },
    getSchemeGraphData: (state, action) => {
      state.schemeGraphData = action.payload;
    },
  },
});

export default schemeSlice.reducer;
export const {
  getSchemeCurrentData,
  getSchemeRetailerId,
  getSchemeList,
  getCampaignDetails,
  getCampaignSchemeList,
  getSchemeGraphData,
  getMRPList
} = schemeSlice.actions;
