import React from 'react'
import styles from './styles/RouteMgtBottomContainer.module.css'
import UpdateRouteMgt from './UpdateRoute'
import AddRouteMgt from './AddRoute'

const RouteMgtBottomContainer = ({rootzone}) => {
    return (
        <div className={styles.RouteMgtBottomContainer}>
            <UpdateRouteMgt rootzone={rootzone}/>

            <div className={styles.RouteMgtBottomAddContainer}>
                <AddRouteMgt rootzone={rootzone}/>
            </div>
        </div>
    )
}

export default RouteMgtBottomContainer
