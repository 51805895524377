import { useEffect } from "react";
import useAxiosPrivate from "./useAxiosPrivate";
import { useDispatch } from "react-redux";
import { getCategoryList } from "../slice/categorySlice";
import { getSkuList } from "../slice/skuSlice";
import { getSuperCategoryStatus } from "../slice/superCategorySlice";

const useSuperCategoryStatus = (form) => {
  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();
  const getAllSuperCategoryStatus = async () => {
    let param = {
      sc_id: form?.sc_id,
    };
    const response = await Axios.post("/super_category_status", param);
    const data = await response.data;
    dispatch(getSuperCategoryStatus(data.body));
  };

  useEffect(() => {
    getAllSuperCategoryStatus();
  }, [form?.sc_id]);
};

export default useSuperCategoryStatus;
