import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  TextField,
  Button,
  MenuItem,
  InputAdornment,
  InputLabel,
} from "@material-ui/core";
import "./style.css";
import { Stack, Typography } from "@mui/material";
import useAddTarget from "../../../hooks/useAddTarget";
import { useSelector } from "react-redux";
import useUsersTargetData from "../../../hooks/useUsersTargetData";
import coinImage from "./coin.png";
import styles from './styles.module.css'; // import the CSS module
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from 'dayjs';

const months = [
  { value: "January", label: "January", index: 1 },
  { value: "February", label: "February", index: 2 },
  { value: "March", label: "March", index: 3 },
  { value: "April", label: "April", index: 4 },
  { value: "May", label: "May", index: 5 },
  { value: "June", label: "June", index: 6 },
  { value: "July", label: "July", index: 7 },
  { value: "August", label: "August", index: 8 },
  { value: "September", label: "September", index: 9 },
  { value: "October", label: "October", index: 10 },
  { value: "November", label: "November", index: 11 },
  { value: "December", label: "December", index: 12 },
];
const AddTarget = ({ selectedMonth, setSelectedMonth }) => {
  const currentzone = useSelector((state) => state.app.curretDropdownData);
  const tableData = useSelector((state) => state.performance.targetTableData);
  const gettargetdata = useUsersTargetData(selectedMonth);
  const [formData, setFormData] = useState({
    sales_target: "",
    time_stamp: "",
    // outlets_coverage: '',
    // lines_per_call: '',
    petrol: "",
    call_incentive: "",
    time_incentive: "",
    sales_incentive: "",
    fixed_salary: "",
    variable_salary: "",
    state_id: "",
  });
  useEffect(() => {
    if (tableData) {
      const saveData = tableData[0];
      setFormData((prevData) => ({
        ...prevData,
        sales_target: saveData?.sales_target ?? "",
        time_stamp: "",
        // outlets_coverage: saveData?.outlets_coverage ?? '',
        // lines_per_call: saveData?.lines_per_call ?? '',
        petrol: saveData?.km_coins ?? "",
        call_incentive: saveData?.call_incentive_coins ?? "",
        time_incentive: saveData?.time_incentive_coins ?? "",
        sales_incentive: saveData?.sales_incentive_coins ?? "",
        fixed_salary: saveData?.fixed_salary_coins ?? "",
        variable_salary: saveData?.variable_salary ?? "",
      }));
    }
  }, [tableData]);
  const currentMonth = new Date().getMonth();
  const [dropDownData, setDropDownData] = useState(dayjs());

  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
      year: dropDownData.year(),
      month_no: dropDownData.month() + 1,
    }));

    setSelectedMonth((prevData) => ({
      ...prevData,
      year: dropDownData.year(),
      month_no: dropDownData.month() + 1,
    }));
  }, []);

  // useEffect(() => {
  //   const month = months.find((month) => month.index === currentMonth + 1);
  //   const monthName = month ? month.value : null;
  //   const monthIndex = month ? month.index : null;

  //   setFormData((prevData) => ({
  //     ...prevData,
  //     month: monthName,
  //     month_no: monthIndex,
  //   }));

  //   setSelectedMonth((prevData) => ({
  //     ...prevData,
  //     month: monthName,
  //     month_no: monthIndex,
  //   }));
  // }, []);
  const [errors, setErrors] = useState({});
  useEffect(() => {
    if (currentzone) {
      setFormData((prevData) => ({
        ...prevData,
        zone_id: currentzone?.zone_id,
        state_id: currentzone?.state_id,
      }));
    }
  }, [currentzone]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const selectedMonth = months.find((month) => month.value === value);
    if (name === "month") {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
        month_no: selectedMonth?.index,
      }));
      setSelectedMonth((prevData) => ({
        ...prevData,
        [name]: value,
        month_no: selectedMonth?.index,
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };
  const handleMonthChange = (date) => {
    setDropDownData(date);
    const year = date.year();
    const month = date.month() + 1; 
    setFormData((prevData) => ({
      ...prevData,
      month_no: month,
      year: year
    }));
    setSelectedMonth((prevData) => ({
      ...prevData,
      month_no: month,
      year: year
    }));
  };
  useEffect(() => {
    console.log(formData)
  }, [formData]);
  const isValid = () => {
    const newErrors = {};
    let valid = true;
    for (const key in formData) {
      if (
        formData[key] === "" ||
        formData[key] === 0 ||
        formData[key] === "0"
      ) {
        newErrors[key] = "This field cannot be empty or zero";
        valid = false;
      }
    }
    setErrors(newErrors);
    return valid;
  };
  console.log(formData, "fajfdsjfdjflds");
  const setTarget = useAddTarget();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isValid()) {
      await setTarget(formData);
      await gettargetdata();
    }
  };

  const handleClear = (e) => {
    e.preventDefault();
    setFormData({});
  };
  const minDate = dayjs('2024-06-01');
  return (
    <Card elevation={0} style={{ overflow: "visible" }}>
      <CardContent
        style={{
          // boxShadow: "0 -2px 4px rgba(0,0,0,0.2), 2px 0 4px rgba(0,0,0,0.2), -2px 0 4px rgba(0,0,0,0.2)",
          borderTop: "1px solid #E8E8E8",
          borderLeft: "1px solid #E8E8E8",
          borderRight: "1px solid #E8E8E8",
          borderBottom: "none",
          borderRadius: "6px 6px 0 0", // Optional: Adjusts the border radius for the top corners
        }}
      >
        <form
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          <Stack
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              flexWrap: "wrap",
            }}
          ></Stack>

          <Stack
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
              flexWrap: "wrap",
            }}
          >
            <Stack
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                flexWrap: "wrap",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginRight: "20px",
                }}
              >
                <InputLabel
                  htmlFor="salesTarget"
                  style={{
                    fontSize: "12px",
                    marginBottom: "6px",
                    color: "#5F6868",
                  }}
                >
                  Sales Target ( Monthly )
                </InputLabel>
                <TextField
                  id="salesTarget"
                  name="sales_target"
                  size="small"
                  type="number"
                  value={formData.sales_target}
                  onChange={handleChange}
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">₹</InputAdornment>
                    ),
                  }}
                  // style={{ minWidth: "240px" }}
                  className={styles.textField}
                  error={!!errors.sales_target}
                  helperText={errors.sales_target}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginRight: "20px",
                }}
              >
                <InputLabel
                  htmlFor="timeStamp"
                  style={{
                    fontSize: "12px",
                    marginBottom: "6px",
                    color: "#5F6868",
                  }}
                >
                  Time Stamp ( Daily )
                </InputLabel>
                <TextField
                  name="time_stamp"
                  size="small"
                  type="number"
                  value={formData.time_stamp}
                  onChange={handleChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">hrs</InputAdornment>
                    ),
                  }}
                  variant="outlined"
                  className={styles.textField}
                  error={!!errors.time_stamp}
                  helperText={errors.time_stamp}
                />
              </div>
              {/* <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginRight: "20px",
            }}
          >
            <InputLabel
              htmlFor="outletsCoverage"
              style={{
                fontSize: "12px",
                marginBottom: "6px",
                color: "#5F6868",
              }}
            >
              Outlets Coverage ( Monthly )
            </InputLabel>
            <TextField
              name="outlets_coverage"
              size="small"
              type="number"
              value={formData.outlets_coverage}
              onChange={handleChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">Outlets</InputAdornment>
                ),
              }}
              variant="outlined"
              style={{ minWidth: "200px", maxWidth: "240px" }}
              error={!!errors.outlets_coverage}
              helperText={errors.outlets_coverage}
            />
          </div> */}
              {/* <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginRight: "20px",
            }}
          >
            <InputLabel
              htmlFor="linesPerCall"
              style={{
                fontSize: "12px",
                marginBottom: "6px",
                color: "#5F6868",
              }}
            >
              Lines per call ( Monthly )
            </InputLabel>
            <TextField
              name="lines_per_call"
              size="small"
              type="number"
              value={formData.lines_per_call}
              onChange={handleChange}
              variant="outlined"
              style={{ minWidth: "240px" }}
              error={!!errors.lines_per_call}
              helperText={errors.lines_per_call}
            />
          </div> */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginRight: "20px",
                }}
              >
                <InputLabel
                  htmlFor="petrol"
                  style={{
                    fontSize: "12px",
                    marginBottom: "6px",
                    color: "#5F6868",
                  }}
                >
                  Petrol / km
                </InputLabel>
                <TextField
                  id="petrol"
                  name="petrol"
                  size="small"
                  type="number"
                  value={formData.petrol}
                  onChange={handleChange}
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <img
                        src={coinImage}
                        style={{ width: "1rem", marginRight: "6px" }}
                      />
                    ),
                  }}
                  className={styles.textField}
                  error={!!errors.petrol}
                  helperText={errors.petrol}
                />
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginRight: "20px",
                }}
              >
                <InputLabel
                  htmlFor="variableSalary"
                  style={{
                    fontSize: "12px",
                    marginBottom: "6px",
                    color: "#5F6868",
                  }}
                >
                  Variable Salary
                </InputLabel>
                <TextField
                  id="variable_salary"
                  name="variable_salary"
                  size="small"
                  type="number"
                  value={formData.variable_salary}
                  onChange={handleChange}
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <img
                        src={coinImage}
                        style={{ width: "1rem", marginRight: "6px" }}
                      />
                    ),
                  }}
                  className={styles.textField}
                  error={!!errors.variable_salary}
                  helperText={errors.variable_salary}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginRight: "20px",
                }}
              >
                <InputLabel
                  htmlFor="salesTarget"
                  style={{
                    fontSize: "12px",
                    marginBottom: "6px",
                    color: "#5F6868",
                  }}
                >
                  Month/Year
                </InputLabel>
                {/* <TextField
                  select
                  name="month"
                  size="small"
                  value={formData.month}
                  onChange={handleChange}
                  variant="outlined"
                  className={styles.textField}
                  error={!!errors.month}
                  helperText={errors.month}
                >
                  {months.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField> */}
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    views={["year", "month"]}
                    value={dropDownData}
                    onChange={handleMonthChange}
                    minDate={minDate}
                    slotProps={{
                      textField: {
                        size: "small",
                        sx: {
                          "& .MuiInputBase-root": {
                            height: "40px", // adjust this value as needed
                          },
                        },
                      },
                    }}
                  />
                </LocalizationProvider>
              </div>
            </Stack>
            <Stack
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                flexWrap: "wrap",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginRight: "20px",
                }}
              >
                <InputLabel
                  htmlFor="callIncentive"
                  style={{
                    fontSize: "12px",
                    marginBottom: "6px",
                    color: "#5F6868",
                  }}
                >
                  Call Incentive / call
                </InputLabel>
                <TextField
                  id="call_incentive"
                  name="call_incentive"
                  size="small"
                  type="number"
                  value={formData.call_incentive}
                  onChange={handleChange}
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <img
                        src={coinImage}
                        style={{ width: "1rem", marginRight: "6px" }}
                      />
                    ),
                  }}
                  className={styles.textField}
                  error={!!errors.call_incentive}
                  helperText={errors.call_incentive}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginRight: "20px",
                }}
              >
                <InputLabel
                  htmlFor="timeIncentive"
                  style={{
                    fontSize: "12px",
                    marginBottom: "6px",
                    color: "#5F6868",
                  }}
                >
                  Time Incentive / hour
                </InputLabel>
                <TextField
                  name="time_incentive"
                  size="small"
                  type="number"
                  value={formData.time_incentive}
                  onChange={handleChange}
                  InputProps={{
                    startAdornment: (
                      <img
                        src={coinImage}
                        style={{ width: "1rem", marginRight: "6px" }}
                      />
                    ),
                  }}
                  variant="outlined"
                  className={styles.textField}
                  error={!!errors.time_incentive}
                  helperText={errors.time_incentive}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginRight: "20px",
                }}
              >
                <InputLabel
                  htmlFor="salesIncentive"
                  style={{
                    fontSize: "12px",
                    marginBottom: "6px",
                    color: "#5F6868",
                  }}
                >
                  Sales Incentive / ₹1000
                </InputLabel>
                <TextField
                  name="sales_incentive"
                  size="small"
                  type="number"
                  value={formData.sales_incentive}
                  onChange={handleChange}
                  InputProps={{
                    startAdornment: (
                      <img
                        src={coinImage}
                        style={{ width: "1rem", marginRight: "6px" }}
                      />
                    ),
                  }}
                  variant="outlined"
                  className={styles.textField}
                  error={!!errors.sales_incentive}
                  helperText={errors.sales_incentive}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginRight: "20px",
                }}
              >
                <InputLabel
                  htmlFor="fixedSalary"
                  style={{
                    fontSize: "12px",
                    marginBottom: "6px",
                    color: "#5F6868",
                  }}
                >
                  Fixed Salary
                </InputLabel>
                <TextField
                  name="fixed_salary"
                  size="small"
                  type="number"
                  value={formData.fixed_salary}
                  onChange={handleChange}
                  InputProps={{
                    startAdornment: (
                      <img
                        src={coinImage}
                        style={{ width: "1rem", marginRight: "6px" }}
                      />
                    ),
                  }}
                  variant="outlined"
                  className={styles.textField}
                  error={!!errors.fixed_salary}
                  helperText={errors.fixed_salary}
                />
              </div>
              <Stack
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "1rem",
                  flexWrap: "wrap",
                  alignSelf: "flex-end",
                }}
              >
                <button
                  onClick={handleSubmit}
                  style={{
                    background: "#FFFFFF",
                    color: "#EF6C1A",
                    padding: "0.8rem",
                    fontWeight: 700,
                    border: "1px solid #EF6C1A",
                    cursor: "pointer",
                  }}
                >
                  Apply to all
                </button>
                <button
                  style={{
                    background: "#FFFFFF",
                    border: "none",
                    color: "#EF6C1A",
                    fontWeight: 700,
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                  onClick={handleClear}
                >
                  Clear All
                </button>
              </Stack>
            </Stack>
          </Stack>
        </form>
      </CardContent>
    </Card>
  );
};

export default AddTarget;
