import React from "react";
import useAxiosPrivate from "./useAxiosPrivate";

import { ToastContainer, toast } from "react-toastify";

const useEditTarget = () => {
  const Axios = useAxiosPrivate();
  const EditTarget = async (param) => {

    console.log(param,"handleEdit")

    const response = await Axios.post("/edit_user_target", param);
    const data = await response.data;
    if (data.code === 200) {
      toast.success(data?.message);
    } else if (data.code === 400) {
      toast.error(data?.message);
    } else {
      toast.error("Failed to Add");
    }
  };
  return EditTarget;
};

export default useEditTarget;
