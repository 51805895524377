import useImageAxiosPrivate from "../useImageAxiosPrivate";
const useRouteDumpUploadValidate = () => {
    const ImageAxiosPrivate = useImageAxiosPrivate();
  const getRouteDumpUploadValidate = async (param) => {
    const response = await ImageAxiosPrivate.post("/route_dump_upload_validate", param);
    const data = await response.data;
    console.log(data,"useRouteDumpUploadValidate")
    return data;
  };
  return getRouteDumpUploadValidate;
};

export default useRouteDumpUploadValidate;
