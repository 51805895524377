import { useEffect } from "react";
import useAxiosPrivate from "./useAxiosPrivate";
import { useDispatch } from "react-redux";
import { getCategoryList } from "../slice/categorySlice";
import { getRetailerDownloadList } from "../slice/dashboardSlice";

const useRetailerDownloadList = (zoneData) => {
  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();

  const getAllRetailerDownloadList = async () => {
    let param={
      zone_id: zoneData.zone_id

    }
    const response = await Axios.post("/retailer-download-list", param);
    const data = await response.data;
    dispatch(getRetailerDownloadList(data.body));
    // console.log(data);
    return data.body;
  };
  // useEffect(() => {
  //   getAllRetailerDownloadList();
  // }, []);

  return getAllRetailerDownloadList;
};

export default useRetailerDownloadList;
