import React from 'react';
import EmployeeAreaTopContainer from './EmployeeAreaTopContainer';
import EmployeeAreaBottomContainer from './EmployeeAreaBottomContainer';

const EmployeeArea = ({ token, setRootZone, rootzone }) => {
    return (
        <div>
            <EmployeeAreaTopContainer rootzone={rootzone} token={token} setRootZone={setRootZone} />
            <EmployeeAreaBottomContainer rootzone={rootzone} />
        </div>
    )
}

export default EmployeeArea
