import { useEffect, useState } from "react";
import { Authaxios } from "../utility/axios";
import { useDispatch, useSelector } from "react-redux";
import { getLoginData } from "../slice/loginSlice";
import { useNavigate } from "react-router";
import Swal from "sweetalert2";
import useAxiosPrivate from "./useAxiosPrivate";

const useLogin = () => {
  // console.log("entered74586");
  const dispatch = useDispatch();
  const Axios = useAxiosPrivate();
  const loginCredential = useSelector((store) => store.login.loginCredential);
  const [counter, setCounter] = useState(0);
  const navigate = useNavigate();
  const [Error, setError] = useState({});
  const getLogin = async (form) => {
    try {
    let param = {
      email: form?.email,
      password: form?.password,
    };

    const response = await Axios.post("adminLogin", param);
    const data = await response.data;
    dispatch(getLoginData(data));
    if (data.code === 200) {
      let refreshToken = data.refreshToken;
      localStorage.setItem("refreshToken", refreshToken);
      localStorage.setItem("data", data.role);
      Swal.fire({
        title: "Success",
        text: "Logged In Successfully",
        icon: "success",
        showConfirmButton: false,
        timer: 2000,
      });
      setTimeout(() => {
        if (
          data.role === "admin" ||
          data.role === "asm" ||
          data.role === "so"
        ) {
          navigate("/home");
        }else{
          navigate("/home/zone_list_view");
        }
        // else navigate("/map");
        // ROLES.LOGIN_MGT.includes(data?.user_data?.admin_role_code)
        //   ? navigate("/home/zonemgt")
        //   : navigate("/home/category");
      }, [2000]);
    } else if (data.code === 401) {
      Swal.fire({
        title: "Error",
        text: "Unauthorized",
        icon: "error",
        showConfirmButton: false,
        timer: 2000,
      });
    }
    else if (data.code === 401) {
      Swal.fire({
        title: "Error",
        text: data.message,
        icon: "error",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  } catch (error) {
    if (error.response && error.response.status === 401) {
      // Handle 401 status code specifically
      console.log("Unauthorized error:", error.response.data.message);
      Swal.fire({
        text: "Email and Password does not match",
        title: "Unauthorized",
        icon: "error",
        showConfirmButton: false,
        timer: 2000,
      });
    }else if(error.response && error.response.status===409){
      Swal.fire({
        title: "User Not Found",
        icon: "error",
        showConfirmButton: false,
        timer: 2000,
      });
    }
     else {
      // Handle other errors that might occur during the request
      console.error("Error in Axios request:", error);
      setError({
        alert: "An error occurred during the login process.",
      });
    }
  }
}
  return getLogin;
};

export default useLogin;
