import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router";

import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import Divider from "@mui/material/Divider";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Checkbox, Chip, ListItemText, Stack, Typography } from "@mui/material";

import RetailerTable from "./RetailerTable";
import RadioButton from "./RadioButton";
import Sidebar from "../Sidebar";

import useSkuList from "../../hooks/useSkuList";
import useSuperCategoryList from "../../hooks/useSuperCategoryList";
import useSkuListByCategory from "../../hooks/useSkuListByCategory";
import useCategoryListBySuperCategory from "../../hooks/useCategoryListBySuperCategory";
import {
  getCampaignDetails,
  getSchemeCurrentData,
} from "../../slice/schemeSlice";
import useMRPList from "../../hooks/useMRPList";
import useSkuListByCategoryMrp from "../../hooks/useSkuListByCategoryMrp";
import useSkuListByMrp from "../../hooks/useSkuListByMrp";

const AddNewSchemeWithPercentage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [dataFromChild, setDataFromChild] = useState(null);
  const [selectedSC, setSelectedSC] = useState(null);
  const [selectedSCId, setSelectedSCId] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [selectedCategoryId, setSelectedCategoryId] = useState([]);
  const [selectedSku, setSelectedSku] = useState([]);
  const [selectedSkuId, setSelectedSkuId] = useState([]);
  const [selectedMrp,setSelectedMrp] = useState([]);
  const [selectedFreeMrp,setSelectedFreeMrp] = useState([]);
  const [selectedFreeSku, setSelectedFreeSku] = useState([]);
  const [selectedFreeSkuId, setSelectedFreeSkuId] = useState(null);
  const [freeSkuScName, setFreeSkuScName] = useState("");
  const [selectedFreeSkuIds,setSelectedFreeSkuIds]=useState([])
  const [obj, setObj] = useState({});
  const qtyType = [
    {
      id: 1,
      name: "ladi",
    },
    {
      id: 2,
      name: "carton",
    },
    {
      id: 3,
      name: "pieces",
    },
  ];
  const schemeType = [
    {
      id: 1,
      name: "Discount Scheme",
    },
    {
      id: 2,
      name: "Quantity Purchase",
    },
    {
      id: 3,
      name: "Text",
    },
  ];

  // --------------Redux--------------
  const allSkuList = useSelector((store) => store.sku.skuListByMrp);
  const skuList = useSelector((store) => store.sku.skuListByCategoryMrp);
  const superCategoryList = useSelector(
    (store) => store.superCategory.superCategoryList
  );
  const categoryList = useSelector(
    (store) => store.category.categoryListBySuperCategory
  );
  const mrpList = useSelector(
    (store) => store.scheme.mrpList
  );
  // --------------Redux--------------

  
  // ------------API Calls-----------
  // useSkuList();
  const getSkuListByMrp=useSkuListByMrp()
  const getAllSuperCategoryList=useSuperCategoryList();
  // const getAllSkuListByCategory = useSkuListByCategory();
  const getAllSkuListByCategoryMrp = useSkuListByCategoryMrp();
  const getAllCategoryListBySuperCategory = useCategoryListBySuperCategory();
  const getAllMRPList=useMRPList()
  // ------------API Calls-----------

  useEffect(()=>{
    getAllMRPList()
  },[])
  useEffect(() => {
    dispatch(getSchemeCurrentData(obj));
    dispatch(getCampaignDetails(null));
  }, [obj]);

  useEffect(()=>{
    getData()
  },[])

  const [skuByCategory,setSkuByCategory]=useState({})
  const [filteredData,setFilteredData]=useState([])

  useEffect(() => {
    const filteredData = skuList?.filter((sku) =>
      (selectedSku.includes(sku.sku_name) && selectedCategory.includes(sku.category_name)) && selectedMrp.includes(sku?.price_per_piece)
    );
    setFilteredData(filteredData)
    console.log(filteredData,"filteredData")
   
    const groupedByCategory = filteredData?.reduce((acc, sku) => {
      const category = sku.category_name;
      if (!acc[category]) {
        acc[category] = [];
      }
      acc[category].push(sku);
      return acc;
    }, {});

    setSkuByCategory(groupedByCategory);
  }, [selectedSku,selectedCategory,selectedMrp]);

  useEffect(() => {
    if (skuByCategory) {
      const validSkuIds = Object.values(skuByCategory)
        .flat()
        .map((item) => item.sku_id);
      const filteredSkuIds = selectedSkuId.filter((id) =>
        validSkuIds.includes(id)
      );

      const selectedNames = filteredSkuIds?.map((selectedSkuId) => {
        const foundSku = skuList?.find((sku) => sku.sku_id === selectedSkuId);
        return foundSku ? foundSku.sku_name : null;
      });

      const updatedSelectedSku = selectedNames.filter(
        (sku_name) => sku_name !== null
      );

      if (JSON.stringify(updatedSelectedSku) !== JSON.stringify(selectedSku)) {
        setSelectedSku(updatedSelectedSku);
      }
      setSelectedSkuId(filteredSkuIds);
    }
  }, [skuByCategory]);

  console.log(selectedSku,"selectedSku", selectedSkuId)
 
  const getData=async()=>{
    const result=await getAllSuperCategoryList()
    if(result){
      setSelectedSC(result?.[0]?.sc_name)
      setSelectedSCId(result?.[0]?.sc_id)
      setObj((prev) => ({
        ...prev,
        sc_id: result?.[0]?.sc_id,
      }));
    }
  }

  const handleSCChange = (e) => {
    setSelectedSC(e.target.value);
    let scId = 0;
    for (let i = 0; i < superCategoryList?.length; i++) {
      if (e.target.value === superCategoryList[i].sc_name) {
        scId = superCategoryList[i].sc_id;
      }
    }

    setSelectedSCId(scId);
    setObj((prev) => ({
      ...prev,
      [e.target.name]: scId,
    }));
    getAllCategoryListBySuperCategory(scId);
  };

  const handleCategoryChange = async (e) => {
    // setSkuByCategory({})
    // setSelectedSku([])
    // setSelectedSkuId([])
    const selectedValue = e.target.value;
    if (selectedValue?.includes("All")) {
      let allCategoryId=[]
      const hasMatchingAllCategory = categoryList
        ?.filter((category) => category.status === true)
        ?.every((category) => selectedValue?.includes(category.category_name));
      if (hasMatchingAllCategory) {
        setSelectedCategory([]);
        setSelectedCategoryId([]);
      } else {
        const allCategoryName = categoryList
          ?.filter((category) => category.status === true)
          ?.map((category) => category.category_name);
        allCategoryId = categoryList
          ?.filter((category) => category.status === true)
          ?.map((category) => category.category_id);
        setSelectedCategory(allCategoryName);
        setSelectedCategoryId(allCategoryId);
      }
      await getAllSkuListByCategoryMrp(allCategoryId, selectedMrp);
    } else {
      const selectedIds = selectedValue?.map((selectedCategory) => {
        const foundCategory = categoryList?.find(
          (category) => category.category_name === selectedCategory
        );
        return foundCategory ? foundCategory.category_id : null;
      });
      setSelectedCategoryId(
        selectedIds.filter((category_id) => category_id !== null)
      );
      setSelectedCategory(selectedValue);
      await getAllSkuListByCategoryMrp(selectedIds, selectedMrp);
    }
  };
  
  const handleSkuChange = (e) => {
    const selectedValue = e.target.value;
    if (selectedValue?.includes("All")) {
      const hasMatchingSku = skuList?.every((sku) =>
        selectedSkuId?.includes(sku.sku_id)
      );
      if (hasMatchingSku) {
        setSelectedSku([]);
        setSelectedSkuId([]);
      } else {
        const allSku = skuList?.map((sku) => sku?.sku_name);
        const allSkuId = skuList?.map((sku) => sku?.sku_id);
        setSelectedSku(allSku);
        setSelectedSkuId(allSkuId);
      }
    } else {
      const selectedIds = selectedValue?.map((selectedSku) => {
        const foundSku = skuList?.find(
          (sku) => sku.sku_name === selectedSku
        );
        return foundSku ? foundSku.sku_id : null;
      });
      setSelectedSkuId(selectedIds.filter((sku_id) => sku_id !== null));
      setSelectedSku(selectedValue);
    }
  };

  const handleMrpChange = async (e) => {
    const selectedValue = e.target.value;
    if (selectedValue?.includes("All")) {
      let allMrpName=[];
      const hasMatchingAllMRP = mrpList?.every((mrp) =>
        selectedValue?.includes(mrp.mrp)
      );
      if (hasMatchingAllMRP) {
        setSelectedMrp([]);
      } else {
        allMrpName = mrpList?.map((mrp) => mrp.mrp);
        setSelectedMrp(allMrpName);
      }
      await getAllSkuListByCategoryMrp(selectedCategoryId, allMrpName);
    } else {
      setSelectedMrp(selectedValue);
      await getAllSkuListByCategoryMrp(selectedCategoryId, selectedValue);
    }
  };

  const handleFreeMrpChange = async(e) => {
    const selectedValue = e.target.value;
    if (selectedValue?.includes("All")) {
      let allMrpName=[];
      const hasMatchingAllMRP = mrpList?.every((mrp) =>
        selectedValue?.includes(mrp.mrp)
      );
      if (hasMatchingAllMRP) {
        setSelectedFreeMrp([]);
      } else {
        allMrpName = mrpList?.map((mrp) => mrp.mrp);
        setSelectedFreeMrp(allMrpName);
      }
      await getSkuListByMrp(allMrpName);
    } else {
      setSelectedFreeMrp(selectedValue);
      await getSkuListByMrp(selectedValue);
    }
  };

  const handleFreeSkuChange = (e) => {
    const freeSkuList = e.target.value;
    if (freeSkuList?.includes("All")) {
      const hasMatchingSku = allSkuList?.every((sku) =>
        selectedFreeSkuIds?.includes(sku.sku_id)
      );
      if (hasMatchingSku) {
        setSelectedFreeSku([]);
        setSelectedFreeSkuIds([]);
      } else {
        const allSku = allSkuList?.map((sku) => sku?.sku_name);
        const allSkuId = allSkuList?.map((sku) => sku?.sku_id);
        setSelectedFreeSku(allSku);
        setSelectedFreeSkuIds(allSkuId);
      }
    }
    else{
      const selectedIds = freeSkuList?.map((freeSku) => {
        const foundSku = allSkuList?.find((sku) => sku.sku_name === freeSku);
        return foundSku ? foundSku.sku_id : null;
      });
      setSelectedFreeSkuIds(selectedIds.filter((sku_id) => sku_id !== null));
  
      setSelectedFreeSku(e.target.value);
    }
  };

  const handleDataFromChild = (data) => {
    setDataFromChild(data);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "scheme_type") {
      setObj((prev) => ({
        ...prev,
        percentage: null,
        min_qty: null,
        qty_type: null,
        free_sku_qty: null,
        free_sku_qty_type: null,
        free_sku_id: null,
        qty: null,
        qty_type: null,
      }));
    }
    setObj((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <Stack
      sx={{
        display: "flex",
        flexDirection: "row",
        backgroundColor: "#E5E5E5",
        gap: "4px",
      }}
    >
      <Sidebar />
      <Box
        sx={{
          minWidth: 140,
          display: "flex",
          flexDirection: "column",
          gap: "3rem",
          padding: "1rem",
          width: "100vw",
          backgroundColor: "#fff",
          height: "fit-content",
          margin: "6px 6px 0px 0px",
          borderRadius: "8px",
        }}
      >
        <Stack
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "3rem",
            flexWrap: "wrap",
          }}
        >
          <Stack
            sx={{
              display: "flex",
              flexDirection: "column",
              flexWrap: "wrap",
              alignItems: "flex-start",
              gap: "2rem",
            }}
          >
            <Stack
              onClick={() => navigate("/home/Schememgt")}
              sx={{
                flexDirection: "row",
                alignItems: "center",
                gap: "12px",
                cursor: "pointer",
              }}
            >
              <ArrowBackIcon />
              <Typography sx={{ fontWeight: 700, fontSize: "1.3rem" }}>
                Create Scheme
              </Typography>
            </Stack>
            <Stack
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "1rem",
                flexWrap: "wrap",
              }}
            >
              <FormControl sx={{ minWidth: 250 }}>
                <InputLabel sx={{ top: "-10px", color: "#989797" }}>
                  Super Category
                </InputLabel>
                <Select
                  labelId="shape-id-label"
                  id="shape-id-select"
                  name="sc_id"
                  value={selectedSC || ""}
                  size="small"
                  displayEmpty
                  sx={{ height: "2.2rem" }}
                  onChange={(e) => handleSCChange(e)}
                >
                  {superCategoryList &&
                    superCategoryList
                      ?.filter((sku) => sku.status === true)
                      .map((sc) => (
                        <MenuItem key={sc.sc_id} value={sc.sc_name}>
                          {sc.sc_name}
                        </MenuItem>
                      ))}
                </Select>
              </FormControl>
              <Divider
                orientation="vertical"
                flexItem
                sx={{ borderRight: "1px solid #B7B7B7", margin: "0 1px" }}
              />
              {/* <FormControl sx={{ minWidth: 250 }}>
                <InputLabel sx={{ top: "-10px", color: "#989797" }}>
                  Category
                </InputLabel>
                <Select
                  labelId="shape-id-label"
                  id="shape-id-select"
                  name="category_id"
                  value={selectedCategory}
                  size="small"
                  sx={{ height: "2.2rem" }}
                  onChange={(e) => handleCategoryChange(e)}
                >
                  {categoryList &&
                    categoryList
                      ?.filter((sku) => sku.status === true)
                      .map((category) => (
                        <MenuItem
                          key={category.category_id}
                          value={category.category_name}
                        >
                          {category.category_name}
                        </MenuItem>
                      ))}
                </Select>
              </FormControl> */}

              <FormControl sx={{ minWidth: 250 }}>
                <InputLabel sx={{ top: "-10px", color: "#989797" }}>
                  Category
                </InputLabel>
                <Select
                  labelId="shape-id-label"
                  id="shape-id-select"
                  name="sku_id"
                  value={selectedCategory}
                  size="small"
                  renderValue={(selected) => {
                    if (selected.length > 2) {
                      return `${selected.slice(0, 2).join(", ")}...`;
                    }
                    return selected.join(", ");
                  }}
                  // renderValue={() => null}
                  multiple
                  sx={{ height: "2.2rem" }}
                  onChange={(e) => handleCategoryChange(e)}
                >
                  <MenuItem
                    value="All"
                    key={0}
                    sx={{
                      padding: "2px",
                      marginTop: "-8px",
                    }}
                  >
                    {" "}
                    <Checkbox
                      checked={categoryList
                        ?.filter((category) => category.status === true)
                        ?.every((category) =>
                          selectedCategoryId?.includes(category.category_id)
                        )}
                    />
                    <ListItemText
                      primary={
                        <span style={{ fontWeight: "bold" }}>Select All</span>
                      }
                    />
                  </MenuItem>
                  {categoryList &&
                    categoryList
                      ?.filter((category) => category.status === true)
                      .map((category) => (
                        <MenuItem
                          key={category?.category_id}
                          value={category?.category_name}
                          sx={{
                            padding: "2px",
                            marginTop: "-8px",
                          }}
                        >
                          <Checkbox
                            checked={
                              selectedCategory.indexOf(
                                category?.category_name
                              ) >= 0
                            }
                          />
                          <ListItemText primary={category?.category_name} />
                        </MenuItem>
                      ))}
                </Select>
              </FormControl>
              <Divider
                orientation="vertical"
                flexItem
                sx={{ borderRight: "1px solid #B7B7B7", margin: "0 1px" }}
              />
              <FormControl sx={{ minWidth: 250 }}>
                <InputLabel sx={{ top: "-10px", color: "#989797" }}>
                  MRP
                </InputLabel>
                <Select
                  labelId="shape-id-label"
                  id="shape-id-select"
                  name="sku_id"
                  value={selectedMrp}
                  size="small"
                  renderValue={(selected) => {
                    if (selected.length > 5) {
                      return `${selected.slice(0, 5).join(", ")}...`;
                    }
                    return selected.join(", ");
                  }}
                  // renderValue={() => null}
                  multiple
                  sx={{ height: "2.2rem" }}
                  onChange={(e) => handleMrpChange(e)}
                >
                  <MenuItem
                    value="All"
                    key={0}
                    sx={{
                      padding: "2px",
                      marginTop: "-8px",
                    }}
                  >
                    {" "}
                    <Checkbox
                      checked={mrpList?.every((mrp) =>
                        selectedMrp?.includes(mrp.mrp)
                      )}
                    />
                    <ListItemText
                      primary={
                        <span style={{ fontWeight: "bold" }}>Select All</span>
                      }
                    />
                  </MenuItem>
                  {mrpList &&
                    mrpList.map((mrp, index) => (
                      <MenuItem
                        key={index}
                        value={mrp.mrp}
                        sx={{
                          padding: "2px",
                          marginTop: "-8px",
                        }}
                      >
                        <Checkbox
                          checked={selectedMrp.indexOf(mrp?.mrp) >= 0}
                        />
                        <ListItemText primary={mrp?.mrp} />
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <Divider
                orientation="vertical"
                flexItem
                sx={{ borderRight: "1px solid #B7B7B7", margin: "0 1px" }}
              />
              <FormControl sx={{ minWidth: 250 }}>
                <InputLabel sx={{ top: "-10px", color: "#989797" }}>
                  SKU
                </InputLabel>
                <Select
                  labelId="shape-id-label"
                  id="shape-id-select"
                  name="sku_id"
                  value={selectedSku}
                  disabled={selectedCategory?.length===0 || selectedMrp?.length===0}
                  size="small"
                  renderValue={(selected) => {
                    if (selected.length > 2) {
                      return `${selected.slice(0, 2).join(", ")}...`;
                    }
                    return selected.join(", ");
                  }}
                  // renderValue={() => null}
                  multiple
                  sx={{ height: "2.2rem" }}
                  onChange={(e) => handleSkuChange(e)}
                >
                  {selectedCategoryId?.length > 0 &&
                    selectedMrp?.length > 0 && (
                      <MenuItem
                        value="All"
                        key={0}
                        sx={{
                          padding: "2px",
                          marginTop: "-8px",
                        }}
                      >
                        {" "}
                        <Checkbox
                          // checked={selectedSku.length === skuList.length}
                          checked={skuList?.every((sku) =>
                            selectedSkuId?.includes(sku.sku_id)
                          )}
                        />
                        <ListItemText
                          primary={
                            <span style={{ fontWeight: "bold" }}>
                              Select All
                            </span>
                          } // Make text bold
                        />
                      </MenuItem>
                    )}
                  {selectedCategoryId?.length > 0 &&
                    selectedMrp?.length > 0 &&
                    skuList &&
                    skuList
                      ?.filter((sku) => sku.status === true)
                      .map((sku) => (
                        <MenuItem
                          key={sku?.sku_id}
                          value={sku?.sku_name}
                          sx={{
                            padding: "2px",
                            marginTop: "-8px",
                          }}
                        >
                          <Checkbox
                            checked={selectedSku.indexOf(sku?.sku_name) >= 0}
                          />
                          <ListItemText primary={sku?.sku_name} />
                        </MenuItem>
                      ))}
                </Select>
              </FormControl>
            </Stack>
            {selectedSku?.length > 0 && (
              <Typography
                variant="h6"
                color="#333"
                sx={{ justifySelf: "flex-start" }}
              >
                Selected SKUs:
              </Typography>
            )}
            {selectedSku?.length > 0 && skuByCategory && (
              <Stack sx={{ marginTop: "-2rem" }}>
                {Object.entries(skuByCategory)?.map(([categoryName, skus]) => (
                  <div
                    key={categoryName}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "1rem",
                      alignItems: "center",
                    }}
                  >
                    <Stack
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        flexWrap: "wrap",
                        gap: "0.8rem",
                        marginTop: "1rem",
                      }}
                    >
                      <h4 style={{ margin: 0 }}>{categoryName} :</h4>{" "}
                      {skus?.map((sku) => (
                        <Chip
                          key={sku.sku_id} // Use sku_id as the key for uniqueness
                          label={sku.sku_name}
                          // onDelete={() => handleDelete(sku)} // Function to handle deletion
                          style={{
                            backgroundColor: "#f0f0f0",
                            color: "#333",
                          }}
                        />
                      ))}
                    </Stack>
                  </div>
                ))}
              </Stack>
            )}

            {/* {selectedSku?.length > 0 && (
              <Stack sx={{ marginTop: "-1rem" }}>
                <Stack
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    flexWrap: "wrap",
                    gap: "0.8rem",
                  }}
                >
                  {selectedSku?.map((sku, index) => (
                    <Chip
                      key={index} // Ideally, use a unique identifier for each SKU
                      label={sku}
                      // onDelete={() => handleDelete(sku)} // Function to handle deletion
                      style={{ backgroundColor: "#f0f0f0", color: "#333" }}
                    />
                  ))}
                </Stack>
              </Stack>
            )} */}
          </Stack>

          <Stack
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "1rem",
              flexWrap: "wrap",
            }}
          >
            <Stack
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "1rem",
                alignItems: "center",
                alignSelf: "self-start",
              }}
            >
              <label
                style={{
                  display: "flex",
                  justifyContent: "center",
                  fontWeight: "500",
                }}
              >
                Name
              </label>

              <input
                variant="outlined"
                name="scheme_name"
                style={{
                  height: "2.2rem",
                  width: "470px",
                  fontSize: "16px",
                  borderRadius: "4px",
                  border: "1px solid #CDCFD4",
                  padding: "0px 8px",
                }}
                size="small"
                // value={query}
                onChange={(e) => handleChange(e)}
                placeholder="Enter Name"
              />
            </Stack>

            <Stack
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "center",
                gap: "1rem",
                flexWrap: "wrap",
              }}
            >
              <Stack
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "1rem",
                  flexWrap: "wrap",
                }}
              >
                <Divider
                  orientation="vertical"
                  flexItem
                  sx={{ borderRight: "1px solid #B7B7B7", margin: "0 1px" }}
                />
                <Stack
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "1rem",
                    flexWrap: "wrap",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <label>Type</label>

                  <FormControl sx={{ minWidth: 200 }}>
                    <Select
                      labelId="shape-id-label"
                      id="shape-id-select"
                      name="scheme_type"
                      value={obj?.scheme_type}
                      size="small"
                      sx={{ height: "2.2rem" }}
                      onChange={(e) => handleChange(e)}
                    >
                      {schemeType &&
                        schemeType?.map((scheme) => (
                          <MenuItem key={scheme.id} value={scheme.name}>
                            {scheme.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Stack>
                <Divider
                  orientation="vertical"
                  flexItem
                  sx={{ borderRight: "1px solid #B7B7B7", margin: "0 1px" }}
                />
                <Stack
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "1rem",
                  }}
                >
                  <label>From</label>
                  <input
                    type="date"
                    id="dateSale"
                    name="first_date"
                    style={{
                      height: "2rem",
                      fontSize: "14px",
                      padding: "4px",
                      borderRadius: "4px",
                      border: "1px solid #CDCFD4",
                    }}
                    onChange={(e) => handleChange(e)}
                    // value={currentSelectedDate} // Use the string format "YYYY-MM-DD" for the value
                  />
                </Stack>
                <Typography sx={{ fontSize: "16px", fontWeight: "700" }}>
                  -
                </Typography>
                <Stack
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "1rem",
                  }}
                >
                  <label>To</label>
                  <input
                    type="date"
                    id="dateSale"
                    name="last_date"
                    style={{
                      height: "2rem",
                      fontSize: "14px",
                      padding: "4px",
                      cursor: "pointer",
                      borderRadius: "4px",
                      border: "1px solid #CDCFD4",
                    }}
                    onChange={(e) => handleChange(e)}
                  />
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
        {obj?.scheme_type === "Discount Scheme" ? (
          <Stack
            sx={{
              display: "flex",
              flexDirection: "row",
              marginLeft: "1rem",
              // alignItems: "center",
              // justifyContent: "center",
              gap: "1rem",
              flexWrap: "wrap",
              padding: "16px 12px",
              backgroundColor: "#F4F4F4",
              borderRadius: "8px",
            }}
          >
            <Stack
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "10px",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <label style={{ fontWeight: "600", fontSize: "14px" }}>
                Percentage
              </label>
              <div
                style={{
                  position: "relative",
                  display: "inline-block",
                  width: "190px",
                }}
              >
                <TextField
                  variant="outlined"
                  name="percentage"
                  sx={{
                    height: "2.2rem",
                    marginTop: "-0.2rem",
                    width: "170px",
                    borderRadius: "4px",
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">%</InputAdornment>
                    ),
                  }}
                  size="small"
                  onChange={(e) => handleChange(e)}
                />
              </div>
            </Stack>

            <Stack
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "1rem",
                alignItems: "center",
              }}
            >
              <label style={{ fontWeight: "600", fontSize: "14px" }}>
                Min Qty
              </label>
              <input
                variant="outlined"
                name="min_qty"
                style={{
                  height: "2.2rem",
                  marginTop: "-0.2rem",
                  width: "6rem",
                  paddingLeft: "6px",
                  border: "1px solid #CDCFD4",
                  justifyContent: "center",
                  borderRadius: "4px",
                }}
                size="small"
                // value={query}
                onChange={(e) => handleChange(e)}
                placeholder="Qty"
              />
            </Stack>
            <FormControl sx={{ minWidth: 180 }}>
              <InputLabel sx={{ top: "-10px", color: "#989797" }}>
                QTY Type
              </InputLabel>
              <Select
                labelId="shape-id-label"
                id="shape-id-select"
                name="qty_type"
                //   value={selectedShapeId}
                size="small"
                sx={{ height: "2.2rem" }}
                onChange={(e) => handleChange(e)}
              >
                {qtyType &&
                  qtyType
                    .filter(
                      (qty) => !(selectedSC === "Oil" && qty.name === "ladi")
                    )
                    .map((qty) => (
                      <MenuItem key={qty?.id} value={qty?.name}>
                        {qty?.name}
                      </MenuItem>
                    ))}
              </Select>
            </FormControl>
          </Stack>
        ) : obj?.scheme_type === "Quantity Purchase" ? (
          <Stack
            sx={{
              display: "flex",
              flexDirection: { md: "column", lg: "row" },
              // justifyContent:{md:"flex-start",lg:"none"},
              marginLeft: "1rem",
              // alignItems: "center",
              // justifyContent: "center",
              gap: "1rem",
              // flexWrap: "wrap",
              padding: "16px 12px",
              backgroundColor: "#F4F4F4",
              borderRadius: "8px",
              width:"100%"
            }}
          >
            <Box
              sx={{ display: "flex", flexDirection: "column", gap: "1.5rem" }}
            >
              <Stack
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "2rem",
                  // alignItems: "start",
                  alignItems:"center"
                }}
              >
                <Stack
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "1rem",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Typography sx={{width:"5rem"}}>Free Qty of</Typography>
                  <input
                    variant="outlined"
                    name="free_sku_qty"
                    style={{
                      height: "2.2rem",
                      marginTop: "-0.2rem",
                      width: "6rem",
                      borderRadius: "4px",
                      border: "1px solid #CDCFD4",
                    }}
                    size="small"
                    // value={query}
                    onChange={(e) => handleChange(e)}
                    placeholder="Qty"
                  />
                </Stack>

                <FormControl sx={{ minWidth: 140 }}>
                  <InputLabel sx={{ top: "-10px", color: "#989797" }}>
                    QTY Type
                  </InputLabel>
                  <Select
                    labelId="shape-id-label"
                    id="shape-id-select"
                    name="free_sku_qty_type"
                    //   value={selectedShapeId}
                    size="small"
                    sx={{ height: "2.2rem" }}
                    onChange={(e) => handleChange(e)}
                  >
                    {qtyType &&
                      qtyType
                        ?.filter(
                          (qty) =>
                            !(freeSkuScName === "Oil" && qty.name === "ladi")
                        )
                        .map((qty) => (
                          <MenuItem key={qty?.id} value={qty?.name}>
                            {qty?.name}
                          </MenuItem>
                        ))}
                  </Select>
                </FormControl>

                <FormControl sx={{ minWidth: 140 }}>
                  <InputLabel sx={{ top: "-10px", color: "#989797" }}>
                    MRP
                  </InputLabel>
                  <Select
                    labelId="shape-id-label"
                    id="shape-id-select"
                    name="sku_id"
                    value={selectedFreeMrp}
                    size="small"
                    renderValue={(selected) => {
                      if (selected.length > 5) {
                        return `${selected.slice(0, 5).join(", ")}...`;
                      }
                      return selected.join(", ");
                    }}
                    // renderValue={() => null}
                    multiple
                    sx={{ height: "2.2rem" }}
                    onChange={(e) => handleFreeMrpChange(e)}
                  >
                    <MenuItem
                      value="All"
                      key={0}
                      sx={{
                        padding: "2px",
                        marginTop: "-8px",
                      }}
                    >
                      {" "}
                      <Checkbox
                        checked={mrpList?.every((mrp) =>
                          selectedFreeMrp?.includes(mrp.mrp)
                        )}
                      />
                      <ListItemText
                        primary={
                          <span style={{ fontWeight: "bold" }}>Select All</span>
                        } // Make text bold
                      />
                    </MenuItem>
                    {mrpList &&
                      mrpList.map((mrp, index) => (
                        <MenuItem
                          key={index}
                          value={mrp.mrp}
                          sx={{
                            padding: "2px",
                            marginTop: "-8px",
                          }}
                        >
                          <Checkbox
                            checked={selectedFreeMrp.indexOf(mrp?.mrp) >= 0}
                          />
                          <ListItemText primary={mrp?.mrp} />
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>

                <FormControl sx={{ minWidth: 180, maxWidth: 180 }}>
                  <InputLabel sx={{ top: "-10px", color: "#989797" }}>
                    Free SKU Options
                  </InputLabel>
                  <Select
                    labelId="shape-id-label"
                    id="shape-id-select"
                    name="free_sku_id"
                    value={selectedFreeSku}
                    size="small"
                    renderValue={(selected) => {
                      if (selected.length > 2) {
                        return `${selected.slice(0, 2).join(", ")}...`;
                      }
                      return selected.join(", ");
                    }}
                    sx={{ height: "2.2rem" }}
                    // renderValue={() => null}
                    multiple
                    onChange={(e) => handleFreeSkuChange(e)}
                  >
                    {selectedFreeMrp?.length > 0 && (
                      <MenuItem
                        value="All"
                        key={0}
                        sx={{
                          padding: "2px",
                          marginTop: "-8px",
                        }}
                      >
                        {" "}
                        <Checkbox
                          // checked={selectedSku.length === skuList.length}
                          checked={allSkuList?.every((sku) =>
                            selectedFreeSkuIds?.includes(sku.sku_id)
                          )}
                        />
                        {/* <ListItemText primary="Select All" /> */}
                        <ListItemText
                          primary={
                            <span style={{ fontWeight: "bold" }}>
                              Select All
                            </span>
                          } // Make text bold
                        />
                      </MenuItem>
                    )}
                    {selectedFreeMrp?.length > 0 &&
                      allSkuList &&
                      allSkuList
                        .filter(
                          (sku) =>
                            sku.sc_id === selectedSCId && sku.status === true
                        )
                        .map((filteredSku) => (
                          // <MenuItem
                          //   key={filteredSku.sku_id}
                          //   value={filteredSku.sku_name}
                          // >
                          //   {filteredSku.sku_name}
                          // </MenuItem>

                          <MenuItem
                            key={filteredSku?.sku_id}
                            value={filteredSku?.sku_name}
                            sx={{
                              padding: "2px",
                              marginTop: "-8px",
                            }}
                          >
                            <Checkbox
                              checked={
                                selectedFreeSku.indexOf(
                                  filteredSku?.sku_name
                                ) >= 0
                              }
                            />
                            <ListItemText primary={filteredSku?.sku_name} />
                          </MenuItem>
                        ))}
                  </Select>
                </FormControl>
              </Stack>

              <Stack
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "2rem",
                  alignItems: "start",
                }}
              >
                <Stack
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "1rem",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Typography sx={{ width: "80px" }}>For Every</Typography>
                  <input
                    variant="outlined"
                    name="qty"
                    style={{
                      height: "2.2rem",
                      marginTop: "-0.2rem",
                      width: "6rem",
                      borderRadius: "4px",
                      border: "1px solid #CDCFD4",
                    }}
                    size="small"
                    // value={query}
                    onChange={(e) => handleChange(e)}
                    placeholder="Qty"
                  />
                </Stack>
                <FormControl sx={{ minWidth: 140 }}>
                  <InputLabel sx={{ top: "-10px", color: "#989797" }}>
                    QTY Type
                  </InputLabel>
                  <Select
                    labelId="shape-id-label"
                    id="shape-id-select"
                    name="qty_type"
                    //   value={selectedShapeId}
                    size="small"
                    sx={{ height: "2.2rem" }}
                    onChange={(e) => handleChange(e)}
                  >
                    {qtyType &&
                      qtyType
                        .filter(
                          (qty) =>
                            !(selectedSC === "Oil" && qty.name === "carton")
                        )
                        .map((qty) => (
                          <MenuItem key={qty?.id} value={qty?.name}>
                            {qty?.name}
                          </MenuItem>
                        ))}
                  </Select>
                </FormControl>
                <FormControl sx={{ minWidth: 120 }}>
                  <input
                    variant="outlined"
                    disabled="true"
                    style={{
                      height: "2.2rem",
                      marginTop: "-0.2rem",
                      borderRadius: "4px",
                      fontSize: "13px",
                      border: "1px solid #CDCFD4",
                      width: "160px",
                    }}
                    size="small"
                    // value={selectedSku}
                    value="Apply for All Selected SKUs"
                  />
                </FormControl>
              </Stack>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "1rem",
                justifyContent: { md: "flex-start", lg: "center" },
              }}
            >
              <Box sx={{ display: "flex", flexDirection: "row" }}>
                {selectedFreeSku?.length > 0 && (
                  <Typography
                    variant="h6"
                    color="#333"
                    sx={{ width: "3rem" }}
                    // sx={{ justifySelf: "flex-start" }}
                  >
                    SKUs:
                  </Typography>
                )}
              </Box>

              {selectedFreeSku?.length > 0 && (
                <Stack>
                  <Stack
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      flexWrap: "wrap",
                      gap: "0.8rem",
                    }}
                  >
                    {selectedFreeSku?.map((sku, index) => (
                      <Chip
                        key={index} // Ideally, use a unique identifier for each SKU
                        label={sku}
                        // onDelete={() => handleDelete(sku)} // Function to handle deletion
                        style={{
                          backgroundColor: "#FFEA9E",
                          color: "#000000",
                          fontWeight: "700",
                        }}
                      />
                    ))}
                  </Stack>
                </Stack>
              )}
            </Box>
          </Stack>
        ) : null}
        <Divider
          variant="middle"
          sx={{ paddingTop: "6px", borderBottom: "1px Solid #BABABA" }}
        />
        <Stack>
          <RadioButton
            setObjParent={setObj}
            sendDataToParent={handleDataFromChild}
            selectedSkuId={selectedSkuId}
            selectedFreeSkuId={selectedFreeSkuIds}
          />
          {dataFromChild?.outlets === true && <RetailerTable />}
        </Stack>
      </Box>
    </Stack>
  );
};

export default AddNewSchemeWithPercentage;
