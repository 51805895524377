import * as React from "react";
import { PieChart } from "@mui/x-charts/PieChart";
import { useSelector } from "react-redux";
import { Typography, Box } from "@mui/material";

export default function PieChartCallConversions() {
  const SRCallConversion = useSelector((state) => state.sr.SRCallConversion);
  const totalValues = SRCallConversion?.reduce((acc, item) => {
    return {
      sale: parseFloat(acc.sale) + parseFloat(item.sale),
      no_sale:  parseFloat(acc.no_sale) +  parseFloat(item.no_sale),
    };
  }, { sale: 0, no_sale: 0 });
  return (
    <Box >
      <PieChart
        series={[
          {
            data: [
              {
                id: 0,
                value: totalValues?.sale || 0,
                label: "Sale",
                color: "#D9D9D9",
              },
              {
                id: 1,
                value: totalValues?.no_sale || 0,
                label: "No Sale",
                color: "#B2B2B2",
              },
            ],
          },
        ]}
        width={400}
        height={250}
        axisHighlight={{
          x: "none",
          y: "none",
        }}
      />
      <Typography
        sx={{
          marginLeft: "4rem",
          marginTop: "0.5rem",
          color: "#000000",
          fontWeight: 500,
          fontSize: "22px",
        }}
      >
        Calls Conversions
      </Typography>
    </Box>
  );
}
