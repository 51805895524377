import React, { useEffect, useState } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useSelector } from "react-redux";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import { Stack } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import useRouteDumpUpload from "../../hooks/useRouteDumpUpload";
import useSrRoutesDownload from "../../hooks/useSrRoutesDownload";
import * as XLSX from 'xlsx';
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import ClearIcon from '@mui/icons-material/Clear';
import InfoIcon from '@mui/icons-material/Info';
import InstructionsPopup from "./InstructionPopUp";
import Loader from "./Loader";
import useEmployeeAreaDropdownList from "../../hooks/Beat/useEmployeeAreaDropdownList";
import ValidationPopup from "./ValidationPopUp";
import useRouteDumpUploadValidate from "../../hooks/Beat/useValidateRouteDumpUpload";
import Swal from "sweetalert2";

const RedButton = styled(Button)(({ theme }) => ({
    color: "#731D3A",
    backgroundColor: "white",
    fontSize:"14px !important",
    "&:hover": {
      backgroundColor: "#731D3A",
      color: "white",
      border: "none !important",
    },
    border: "none !important",
    textTransform:"none"
  }));
const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
});
const DownloadButtons = ({setselectedSR, selectedSR, setSearchQuery, selectedEmployeeArea, setselectedEmployeeArea}) => {
  const currentzone = useSelector((state) => state.assignRoute);
  const currentSC = useSelector((state) => state.assignSE.sc_id);
  const srList = useSelector((state) => state.sr.SRList);
  const [isDownloading, setIsDownloading] = React.useState(false);
  const [loaderText, setLoaderText] = React.useState("");
//   const [selectedSR, setselectedSR] = React.useState({
//     sr_id: null,
//     sr_name: "",
//   });
const zoneData = useSelector((state) => state.app.curretDropdownData);
const employeeAreaDropdownList = useSelector((state) => state.route.employeeAreaDropdownList);

const getAllEmployeeAreaDropdownList=useEmployeeAreaDropdownList()

  useEffect(()=>{
    if(zoneData){
    const param = {
      zone_id: zoneData?.zone_id,
    };
    getAllEmployeeAreaDropdownList(param)
  }
  },[zoneData?.zone_id])

  const handleChange = (event) => {
    let sr_id = null;

    for (let i = 0; i < srList?.length; i++) {
      if (event.target.value === srList[i]?.first_name) {
        sr_id = srList[i]?.user_id;
      }
    }
    setselectedSR({
      sr_id: sr_id,
      sr_name: event.target.value,
    });
    // setSearchQuery(event.target.value)
  };

  const handleEmployeeAreaChange = (event) => {
    let route_area_id = null;

    for (let i = 0; i < employeeAreaDropdownList?.length; i++) {
      if (event.target.value === employeeAreaDropdownList[i]?.route_area_name) {
        route_area_id = employeeAreaDropdownList[i]?.route_area_id;
      }
    }
    setselectedEmployeeArea({
      route_area_id: route_area_id,
      route_area_name: event.target.value,
    });
    // setSearchQuery(event.target.value)
  };
  const [validateData,setValidateData]=useState(null)
  const uploadRouteExcel = useRouteDumpUpload();
  const getRouteDumpUploadValidate=useRouteDumpUploadValidate()
  const [formData,setFormData]=useState({})
  const handleUploadExcel = async(event) => {
    console.log(handleUploadExcel)
    // setIsDownloading(true);
    setLoaderText("Uploading Excel");
    const excelFile = event.target.files[0];
   
    const formData = new FormData();

    formData.append("file", excelFile);
    formData.append("zone_id", currentzone?.zone_id);
    formData.append("sc_id", currentSC?.[0]); 
    setFormData(formData)
    setIsDownloading(true)
    const data = await getRouteDumpUploadValidate(formData)
    console.log(data,"123457")
    setIsDownloading(false)
   if (data.code === 500) {
        Swal.fire({
            title: "Error",
            text: data.message || "An unexpected error occurred.", // Fallback message
            icon: "error",
            showConfirmButton: true,
          });
    } else if(data.code===200) {
      setValidateData(data)
      setValidationOpen(true)
    }
    // await uploadRouteExcel(formData);
    // setIsDownloading(false);
    event.target.value = null;
  };
  const getSrRouteDetails = useSrRoutesDownload();
  const handleDownloadExcel = async() => {
    setIsDownloading(true);
    setLoaderText("Downloading Excel");
    const param = {
        zone_id:zoneData?.zone_id
    }
    const data = await getSrRouteDetails(param);
    console.log(data,"getSrRouteDetails")
    const headers = [
        // { v: 'Route ID' },
        { v: 'Employee Area' },
        { v: 'Beat Name' },
        { v: 'Beat Day' },
        { v: 'Retailer ID' },
        { v: 'Retailer Shop Name' },
        { v: 'Category Name' },
        { v: 'Contact Person' },
        { v: 'Mobile No' },
        { v: 'Address' },
        { v: 'Latitude' },
        { v: 'Longitude' },
        { v: 'Pincode' },
        { v: 'Status' },
        { v: 'Distributor Firm Name' },
      ];
    
      // Format the data for Excel
      const formattedData = data.map(item => ({
        // "Route ID": item.route_id,
        'Employee Area':item.employee_area,
        "Beat Name": item.beat_name,
        "Beat Day": item?.route_day?.map((day) => Array.isArray(day) ? day.join(",") : day).join(", "),
        "Retailer ID": item.retailer_id,
        "Retailer Shop Name": item.shop_name,
        "Category Name": item.category || "N/A", // Handle null category
        "Contact Person": item.contact_person,
        "Mobile No": item.mobile_no,
        "Address": item.address,
        "Latitude": item.lat,
        "Longitude": item.lng,
        "Pincode": item.pincode,
        "Status": item.status,
        'Distributor Firm Name':item.dist_firm_name
      }));
    
      // Create a new worksheet with the formatted data
      const worksheet = XLSX.utils.json_to_sheet(formattedData, { header: headers.map(h => h.v) });
    
      // Add custom column widths
      worksheet['!cols'] = [
        // { wch: 10 },  // Route ID
        { wch: 20 },   // Employee Area
        { wch: 15 },  // Beat Name
        { wch: 15 },  // Route Day
        { wch: 15 },  // Retailer ID
        { wch: 20 },  // Shop Name
        { wch: 15 },  // Category
        { wch: 18 },  // Contact Person
        { wch: 15 },  // Mobile No
        { wch: 25 },  // Address
        { wch: 12 },  // Latitude
        { wch: 12 },  // Longitude
        { wch: 10 },  // Pincode
        { wch: 12 },   // Status
        { wch: 20 }   // Dist Firm Name
      ];
    
      // Create a new workbook and append the worksheet
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "SR_route_details");
    
      // Generate Excel file and download it
      XLSX.writeFile(workbook, "SR_route_details.xlsx");
      setIsDownloading(false);
  };
  const clearDropDown = () =>{
    // setselectedSR({sr_id: null, sr_name: ""});
    setselectedEmployeeArea({route_area_id:null, route_area_name:""})
    // setSearchQuery("")
  }
  const [open, setOpen] = React.useState(false);
  const [validationOpen, setValidationOpen]=useState(false)

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleValidationClose = () => setValidationOpen(false);
  return (
    <Stack flexDirection="row" alignItems="center" justifyContent="center" alignContent="center">
      {isDownloading ? <Loader loaderText={loaderText} /> : null}
      <FormControl sx={{ m: 1, minWidth: 250, maxWidth: 250 }} size="small">
        <Select
          labelId="demo-select-small-label"
          id="demo-select-small"
          name="sr_name"
          value={selectedEmployeeArea.route_area_name}
          label="Select Employee Area"
          onChange={handleEmployeeAreaChange}
          displayEmpty
        >
          <MenuItem value="" disabled>
            Select Employee Area
          </MenuItem>
          {employeeAreaDropdownList &&
                      employeeAreaDropdownList
                        .map((area) => (
                          <MenuItem key={area.route_area_id} value={area.route_area_name}>
                            {area.route_area_name}
                          </MenuItem>
                        ))}
        </Select>
      </FormControl>
      <ClearIcon
        sx={{ color: "#a2a3a2", marginRight: "4px", cursor: "pointer" }}
        onClick={clearDropDown}
      />
      <InfoIcon fontSize="large"
        sx={{ color: "#a2a3a2", marginRight: "4px", cursor: "pointer", alignSelf: "center"}}
        onClick={handleOpen}
      />
      
      <InstructionsPopup open={open} handleClose={handleClose} />
      <ValidationPopup open={validationOpen} handleClose={handleValidationClose} 
      uploadRouteExcel={uploadRouteExcel} formData={formData} setIsDownloading={setIsDownloading}
      validateData={validateData} />
      {/* <FormControl sx={{ m: 1, minWidth: 200, maxWidth: 200 }} size="small">
        <Select
          labelId="demo-select-small-label"
          id="demo-select-small"
          name="sr_name"
          value={selectedSR.sr_name}
          label="Select SR"
          onChange={handleChange}
          displayEmpty
        >
          <MenuItem value="" disabled>
            Select SR
          </MenuItem>
          {srList &&
            srList
              ?.filter((srList) => srList.status === true)
              .map((sr) => (
                <MenuItem key={sr.user_id} value={sr.first_name}>
                  {sr.first_name}
                </MenuItem>
              ))}
        </Select>
      </FormControl> */}
     
      <Stack flexDirection="row" gap="12px">
        <RedButton
          variant="outlined"
          sx={{
            backgroundColor: "#396b31 !important",
            '&:hover':{
              background:"#396b31 !important"
            }
          }}
          // disabled={selectedSR.sr_id !== null ? false : true}
          onClick={() => handleDownloadExcel()}
          endIcon={<FileDownloadIcon />}
        >
          Download Dump
        </RedButton>
        <Button
          sx={{
            textTransform: "none",
            boxShadow: "none",
            ":hover": {
              boxShadow: "none !important",
              border: "none !important",
            },
            //   padding: "14px 12px 14px 12px !important",
            fontSize: "14px !important",
            backgroundColor: "#ba4c4c !important",
            '&:hover':{
              background:"#ba4c4c !important"
            },
            border: "none !important",
          }}
          // disabled={selectedSR.sr_id !== null ? false : true}
          component="label"
          role={undefined}
          variant="contained"
          tabIndex={-1}
          startIcon={<CloudUploadIcon />}
        >
          Upload Excel
          <VisuallyHiddenInput
            type="file"
            accept=".xlsx, .xls"
            onChange={(event) => handleUploadExcel(event)}
            multiple
          />
        </Button>
        <RedButton
          endIcon={<FileDownloadIcon />}
          href="https://selorce.s3.ap-south-1.amazonaws.com/downloads/employe_area_retailer_dump_template.xlsx"
        >
          Download Template
        </RedButton>
      </Stack>
    </Stack>
  );
};

export default DownloadButtons;


