import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { Button, Stack } from "@mui/material";
import { useSelector } from "react-redux";
const useStyles = makeStyles((theme) => ({
  uploadButton: {
    margin: theme.spacing(2),
    color:"#EF6C1A"
  },
  input: {
    display: "none",
  },
}));

const UploadPopup = ({ open, onClose }) => {
  const zoneData = useSelector((state) => state.app.curretDropdownData);
  const classes = useStyles();
  const [file, setFile] = useState(null);

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm" // Set the maximum width to small
      fullWidth // Take up the full width
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Upload File</DialogTitle>
      <DialogContent>
        <Stack sx={{ flexDirection: "row", gap: "14px" }}>
          <Typography variant="subtitle1">
            State: {zoneData?.state_name}
          </Typography>
          <Typography variant="subtitle1">
            Zone: {zoneData?.zone_name}
          </Typography>
        </Stack>
        <Stack
          sx={{ width: "100%", justifyContent: "center", alignItems: "center" }}
        >
          <input
            accept=".xlsx"
            className={classes.input}
            id="contained-button-file"
            type="file"
            onChange={handleFileChange}
          />
          <label htmlFor="contained-button-file">
            <IconButton
              
              component="span"
              className={classes.uploadButton}
            >
              <CloudUploadIcon />
            </IconButton>
            <Typography variant="body1">Choose File</Typography>
          </label>
          {file && (
            <Typography variant="body1">Selected File: {file.name}</Typography>
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={onClose} color="primary">
          Upload
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UploadPopup;
