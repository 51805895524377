import { createSlice } from "@reduxjs/toolkit";

const skuSlice = createSlice({
  name: "sku",
  initialState: {
    skuList: null,
    skuFormData: null,
    skuListByCategory: null,
    skuListByCategoryMrp: null,
    skuListByMrp: null,
  },
  reducers: {
    getSkuList: (state, action) => {
      state.skuList = action.payload;
    },
    getSkuListByCategory: (state, action) => {
      state.skuListByCategory = action.payload;
    },
    getSkuListByCategoryMrp: (state, action) => {
      state.skuListByCategoryMrp = action.payload;
    },
    getSkuListByMrp: (state, action) => {
      state.skuListByMrp = action.payload;
    },
    getSkuFormData: (state, action) => {
      state.skuFormData = action.payload;
    },
    resetSkuFormData: (state, action) => {
      state.skuFormData = null;
    },
  },
});

export default skuSlice.reducer;
export const {
  getSkuList,
  getSkuFormData,
  resetSkuFormData,
  getSkuListByCategory,
  getSkuListByCategoryMrp,
  getSkuListByMrp,
} = skuSlice.actions;
