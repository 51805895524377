import React from "react";
import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  TextField,
  MenuItem,
} from "@material-ui/core";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import useEditTarget from "../../../hooks/useEditTarget";
import { Typography, Stack } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import coinImage from "../TargetAssigned/coin.png";
import dayjs from 'dayjs';
import useCoinDetails from "../../../hooks/useCoinDetails";
import useHolidaysList from "../../../hooks/useHolidaysList";
const CoinManagementTable = () => {

  const headerName = [
    { index: 0, name: "SR" },
    { index: 1, name: "SR Name" },
    { index: 2, name: "Fixed Salary" },
    { index: 3, name: "Variable Salary" },
    { index: 4, name: "Call  Stamp" },
    { index: 5, name: "Time Stamp" },
    { index: 6, name: "Sales Stamp" },
    { index: 7, name: "Kms Travelled" },
    { index: 8, name: "Call Incentive" },
    { index: 9, name: "Time Incentive" },
    { index: 10, name: "Sales Incentive" },
    { index: 11, name: "TOTAL" },
  ];
  const [editModeRowId, setEditModeRowId] = useState(null);
  const [editedValues, setEditedValues] = useState({});
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const getTableData = useCoinDetails();
  const handleDateChange = async(date) => {
    setSelectedDate(date);
    const year = date.year();
    const month = date.month() + 1; 
    const param = {
      zone_id:currentzone?.zone_id,
      year:year,
      month:month
    }
    await getTableData(param);
  };
  const currentzone = useSelector((state) => state.app.curretDropdownData);
  const tableData = useSelector((state) => state.performance.coinTableDetails);
  const holidays_count = useSelector((state) => state.performance.holidayCount);
  const getHolidayCount = useHolidaysList();
  useEffect(() => {
    const year = selectedDate.year();
    const month = selectedDate.month() + 1; 
    const param = {
      zone_id:currentzone?.zone_id,
      year:year,
      month:month
    }
    const param2 = {
      state_id : currentzone?.state_id
    }
    getTableData(param);
    getHolidayCount(param2);
  }, [currentzone]);

  const [workingDaysInMonth, setWorkingDaysInMonth] = useState(0);
  const [workingDaysPassed, setWorkingDaysPassed] = useState(0);

  useEffect(() => {
    const getWorkingDaysInMonth = (year, month) => {
      let totalWorkingDays = 0;
      const daysInMonth = new Date(year, month + 1, 0).getDate();
      for (let day = 1; day <= daysInMonth; day++) {
        const date = new Date(year, month, day);
        const dayOfWeek = date.getDay();
        if (dayOfWeek !== 0) { // Exclude Sundays (0 represents Sunday)
          totalWorkingDays++;
        }
      }
      return totalWorkingDays;
    };
  
    const getWorkingDaysPassedInMonth = (year, month) => {
      const today = new Date();
      if (today.getFullYear() !== year || today.getMonth() !== month) {
        return 0;
      }
      
      let workingDays = 0;
      for (let day = 1; day <= today.getDate(); day++) {
        const date = new Date(year, month, day);
        const dayOfWeek = date.getDay();
        if (dayOfWeek !== 0) { // Exclude Sundays (0 represents Sunday)
          workingDays++;
        }
      }
      return workingDays;
    };
  
    const year = selectedDate.year();
    const month = selectedDate.month();
    // console.log(month, year, "month");
  
    setWorkingDaysInMonth(getWorkingDaysInMonth(year, month));
    setWorkingDaysPassed(getWorkingDaysPassedInMonth(year, month));
  }, [selectedDate, holidays_count]);
  

  const editUserTarget = useEditTarget();
  const handleSave = async () => {
    await editUserTarget(editedValues);
    // await getTableData(formData);
    setEditModeRowId(null);
    setEditedValues({});
  };

  const handleCancel = () => {
    // Cancel edit mode
    setEditModeRowId(null);
    setEditedValues({});
  };

  const handleInputChange = (e, field) => {
    const { value } = e.target;
    setEditedValues((prevValues) => ({
      ...prevValues,
      [field]: value,
    }));
  };

  const [searchTerm, setSearchTerm] = useState('');
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };
  return (
    <>
    <Stack sx={{ display: "flex", flexDirection: "row", alignItems:"center",  gap:"15px"  }}>
    <input
        type="text"
        placeholder="Search"
        style={{
          marginTop:"-5px",
          width:"12rem",
          height: "2.1rem",
          fontSize: "14px",
          fontFamily: "inherit",
          borderRadius: "4px",
          padding: "4px 8px", 
          border: "1px solid #c4c4c4", 
          outline: "none",
        }}
        onChange={handleSearchChange} 
        value={searchTerm} 
      />
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          views={["year", "month"]}
          value={selectedDate}
          slotProps={{ textField: { size: "small" } }}
          onChange={handleDateChange}
        />
      </LocalizationProvider>
      
      <Stack sx={{ display: "flex", flexDirection: "row", gap:"10px" }} >
      <p>MTD Working days :  <span style={{color:"#0C8709", fontWeight:"700"}}>{workingDaysPassed}</span></p>
      <p>Total Working days :  <span style={{color:"#000", fontWeight:"700"}}>{workingDaysInMonth - holidays_count?.total_holiday_days}</span></p>
      </Stack>
      </Stack>
      <TableContainer style={{ maxHeight: 700, minWidth: "100%", fontSize:"14px" }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead style={{ backgroundColor: "#F8F8F8", fontSize:"14px" }}>
            <TableRow>
              {headerName?.map((header) => (
                <TableCell
                  key={header?.index}
                  style={{ textAlign: "center", width: "200px" }}
                >
                  {header?.name}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody >
            {tableData?.filter((sr)=>sr?.first_name?.toLowerCase()?.includes(searchTerm?.toLowerCase())).map((row, index) => (
              <TableRow key={row.sr_id}>
                <TableCell style={{ textAlign: "center" }}>
                  {index + 1}
                </TableCell>
                <TableCell style={{ textAlign: "center" }}>
                  {editModeRowId === row.sr_id ? (
                    <TextField
                      size="small"
                      value={editedValues.sr_name}
                      onChange={(e) => handleInputChange(e, "sr_name")}
                    />
                  ) : (
                    row.first_name
                  )}
                  
                </TableCell>
                <TableCell style={{ textAlign: "center" }}>
                <Stack sx={{alignItems:"center", flexDirection:"row", justifyContent:"center", gap:"4px"}}>
                   <img src={coinImage} style={{ width: "1rem" }} />
                  
                  {row?.total_fixed_salary_coins}
                    </Stack>
                </TableCell>
                <TableCell style={{ textAlign: "center" }}>
                <Stack sx={{alignItems:"center", flexDirection:"row", justifyContent:"center", gap:"4px"}}>
                   <img src={coinImage} style={{ width: "1rem" }} />
                  
                   {row.variable_salary}
                    </Stack>
                </TableCell>
                <TableCell style={{ textAlign: "center" }}>
                <Stack sx={{alignItems:"center", flexDirection:"row", justifyContent:"center", gap:"4px"}}>
                   <img src={coinImage} style={{ width: "1rem" }} />
                  
                  {
                    row?.calls_count
                   }
                  </Stack>
                </TableCell>
                <TableCell style={{ textAlign: "center" }}>
                <Stack sx={{alignItems:"center", flexDirection:"row", justifyContent:"center", gap:"4px"}}>
                   <img src={coinImage} style={{ width: "1rem" }} />
                    <Typography sx={{fontSize:"14px"}}>
                  
                  {row?.total_time_spent_in_hours
                    ? parseFloat(row.total_time_spent_in_hours)
                    : "0"}
                  </Typography>
                  </Stack>
                </TableCell>
                <TableCell style={{ textAlign: "center" }}>
                  <Stack sx={{alignItems:"center", flexDirection:"row", justifyContent:"center", gap:"4px"}}>
                   <img src={coinImage} style={{ width: "1rem" }} />
                   

                    {editModeRowId === row.utt_id ? (
                    <TextField
                      size="small"
                      value={editedValues.outlets_coverage}
                      onChange={(e) => handleInputChange(e, "outlets_coverage")}
                    />
                  ) : (
                    row?.sales_stamp
                  )}
                  </Stack>
                </TableCell>
                <TableCell style={{ textAlign: "center" }}>
                <Stack sx={{alignItems:"center", flexDirection:"row", justifyContent:"center", gap:"4px"}}>
                   <img src={coinImage} style={{ width: "1rem" }} />
                   {editModeRowId === row.utt_id ? (
                    <TextField
                      size="small"
                      value={editedValues.time_stamp}
                      onChange={(e) => handleInputChange(e, "time_stamp")}
                    />
                  ) : (
                    <Typography>
                    {row?.total_distance_km ?? "0"} 
                    </Typography>
                  )}
                    </Stack>
                </TableCell>
                <TableCell style={{ textAlign: "center" }}>
                <Stack sx={{alignItems:"center", flexDirection:"row", justifyContent:"center", gap:"4px"}}>
                   <img src={coinImage} style={{ width: "1rem" }} />
                  {
                    row?.total_call_stamp_incentive_coins
                  }
                  </Stack>
                </TableCell>

                <TableCell style={{ textAlign: "center" }}>
                <Stack sx={{alignItems:"center", flexDirection:"row", justifyContent:"center", gap:"4px"}}>
                   <img src={coinImage} style={{ width: "1rem" }} />
                    {row?.total_time_stamp_incentive_coins}
                    </Stack>
                </TableCell>
                <TableCell style={{ textAlign: "center" }}>
                <Stack sx={{alignItems:"center", flexDirection:"row", justifyContent:"center", gap:"4px"}}>
                   <img src={coinImage} style={{ width: "1rem" }} />
                  {
                    row?.total_sales_stamp_incentive_coins
                  }
                  </Stack>
                </TableCell>
                <TableCell style={{ textAlign: "center" }}>
                <Stack sx={{alignItems:"center", flexDirection:"row", justifyContent:"center", gap:"4px"}}>
                   <img src={coinImage} style={{ width: "1rem" }} />
                    {/* {row?.total_coins} */}
                    {row?.total_fixed_salary_coins + row.variable_salary + row?.total_call_stamp_incentive_coins + row?.total_time_stamp_incentive_coins + row?.total_sales_stamp_incentive_coins + row?.total_distance_km}
                    </Stack>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default CoinManagementTable;
