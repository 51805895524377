import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Stack, TextField } from "@mui/material";
import SaleStampTable from "./SaleStampTable";
import SaleStampRangeTable from "./SaleStampRangeTable";
import DateSales from "./DateSales";
import RangeSales from "./RangeSales";
import { useState } from "react";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function SaleStampTab() {
  const [value, setValue] = React.useState(0);
  const [searchTerm, setSearchTerm] = useState('');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

 

  return (
    <Box
      sx={{
        width: "100%",
        textAlign: "left",
        backgroundColor: "#fff",
        margin: "8px 8px 8px 0px",
        padding: "4px",
        overflowX: "scroll",
        "&::-webkit-scrollbar": {
          display: "none",
        },
      }}
    >
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          justifyContent: "space-between",
          display: "flex",
          width: "98%",
          ".MuiTabs-indicator": {
            backgroundColor: "#EF6C1A",
          },
        }}
      >
        <Stack width="100%">
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab
              sx={{ color: "#EF6C1A !important", fontWeight: "600" }}
              label="Date"
              {...a11yProps(0)}
            />
            <Tab
              sx={{ color: "#EF6C1A !important", fontWeight: "600" }}
              label="Range"
              {...a11yProps(1)}
            />
          </Tabs>
        </Stack>

        {value === 0 ? (
          <Stack>
            <DateSales searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
          </Stack>
        ) : value === 1 ? (
          <Stack>
            <RangeSales searchTerm={searchTerm} setSearchTerm={setSearchTerm}/>
          </Stack>
        ) : (
          <Stack></Stack>
        )}
      </Box>
      <CustomTabPanel value={value} index={0}>
        <SaleStampTable searchTerm={searchTerm}/>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <SaleStampRangeTable searchTerm={searchTerm}/>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}></CustomTabPanel>
    </Box>
  );
}
