import React from "react";
import useAxiosPrivate from "./useAxiosPrivate";
import { useDispatch,useSelector } from "react-redux";
import { toast } from "react-toastify";
const useUpdateSuperStockist = (form) => {
  
  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();
  const ssData=useSelector((store)=>store.distributor.superStockistDetails)
  const UpdateSuperStockist = async () => {
   
    let param = {
      ss_name:form?.ss_name,
      address: form?.address,
      status:form?.status==="Inactive"?false:true??form?.categoryRowData?.status,
      ss_id: ssData?.ss_id,
      email: form?.email,
      mobile_no: form?.mobile_no,
      firm_name: form?.shop_name,
    };
    
    const response = await Axios.post("/update_super_stockist", param);
    const data = await response.data;

    if (data.code === 200) {
      toast.success("Updated SuccessFully");
    } else {
      toast.error("Update Failed");
    }

  };

  return UpdateSuperStockist;
};

export default useUpdateSuperStockist;
