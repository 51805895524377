import { TextField } from '@mui/material';
import { StylesContext } from '@mui/styles'
import React, { useState, useEffect } from 'react';
import Styles from './styles/ProductUpdate.module.css'
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import { ClassNames } from '@emotion/react';
import CreateIcon from '@mui/icons-material/Create';
import { useSelector, useDispatch } from 'react-redux';
// import { UpdateProduct } from '../../../actions/ProductMgt/ProductMgt';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const RedButton = styled(Button)(({ theme }) => ({
    color: "white",
    backgroundColor: "#731D3A",
    '&:hover': {
        backgroundColor: "#731D3A",
    },
}));


const GrayButton = styled(Button)(({ theme }) => ({
    color: "#404040",
    backgroundColor: "#E5E5E5",
    '&:hover': {
        backgroundColor: "#E5E5E5",
    },
}));

const UpdateProductMgt = () => {
    const Firsttoken = useSelector(state => state.Token)
    const dispatch = useDispatch()
    const state = useSelector(state => state.ProductMgt)
    const [edit, setEdit] = useState(false);
    const [root, setRoot] = useState({})
    const [form, setForm] = useState({});
    const [formError, setFormError] = useState({});
    const [token, setToken] = useState('');
    const [addSuccess, setAddSuccess] = useState(false);
    const [failed, setFailed] = useState({
        addFailed: false,
        addFailedMessage: ""
    })

    React.useEffect(() => {
        if(Firsttoken && Firsttoken.token && Firsttoken.token!==null && Firsttoken.token.length!==0 && Firsttoken!=="undefined"){
          setToken(Firsttoken.token)  
        }
        
    }, [Firsttoken])


    // useEffect(() => {
    //     if (state.list && state.list.length !== 0 && state.list !== "undefined" && state.list !== null) {
    //         setForm(state.detail);
    //         setRoot(state.detail)
    //     }
    // }, [state])

    const handleEdit = () => {
        setEdit(true)
    }

    const handleNoEdit = () => {
        setEdit(false);
        setForm(root);
        setFormError({})
    }

    const handleChange = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }


    const IsValid = () => {
        let success = true;
        let error = {};

        if (!form.product_name.trim()) {
            error.productname = "*Product Name Cannot be Empty*";
            success = false
        }

        if (!form.cost.toString().trim()) {
            error.cost = "*cost Cannot be Empty*";
            success = false
        }
        setFormError(error)
        return success
    }

    const handleSubmit = () => {
        // if (IsValid()) {
        //     let param = {
        //         "admin_id": "1",
        //         "product_id": `${form.product_id}`,
        //         "product_name": `${form.product_name}`,
        //         "cost": `${form.cost}`,
        //         "status": `${form.status}`
        //     }
        //     dispatch(UpdateProduct(token, param, form, setEdit, setAddSuccess, failed, setFailed))
        // }

    }


    React.useEffect(() => {
        if (addSuccess) {
            toast.success('Updated SuccessFully');
            setAddSuccess(false)
        }
    }, [addSuccess])



    React.useEffect(() => {
        if (failed.addFailed) {
            let text = failed.addFailedMessage.toString();
            toast.error(`${text}`);

            setFailed({
                addFailed: false,
                addFailedMessage: ""

            })
        }
    }, [failed])

    return (
        <>
            <ToastContainer
                position="bottom-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <div id="ProductMgtDetail">

            {
                state && state.list && state.list.length !== 0 && state.list !== "undefined" && state.list !== null ? (
                    <div className={Styles.Container}>
                        <div className={Styles.TitleContainer}>
                            <label className={Styles.Title}>UpdateForm</label>
                            {!edit ? (
                                <div className={Styles.EditContainer} onClick={handleEdit}>
                                    <CreateIcon className={Styles.EditIcon} />
                                </div>
                            ) : null}
                        </div>

                        <div className={Styles.SubContainer}>
                            <div className={Styles.SubContainerOne}>

                                <div className={Styles.SubContainerList}>
                                    <div className={Styles.LabelContainer}>
                                        <label className={Styles.Label}>Product Name</label>
                                    </div>
                                    <div className={Styles.FieldContainer}>
                                        <TextField variant="outlined" size="small" disabled={!edit} sx={{ width: "90%", backgroundColor: "white" }} onChange={handleChange} name="product_name" value={form.product_name} />
                                        {formError.productname && <span className={Styles.error}>{formError.productname}</span>}
                                    </div>

                                </div>

                                <div className={Styles.SubContainerList}>
                                    <div className={Styles.LabelContainer}>
                                        <label className={Styles.Label}>Product ID</label>
                                    </div>
                                    <div className={Styles.FieldContainer}>
                                        <TextField variant="outlined" disabled={true} size="small" sx={{ width: "90%", backgroundColor: "white" }} onChange={handleChange} name="product_id" value={form.product_id} />
                                    </div>

                                </div>


                            </div>
                            <div className={Styles.SubContainerTwo}>
                               
                                <div className={Styles.SubContainerList}>
                                    <div className={Styles.LabelContainer}>
                                        <label className={Styles.Label}>Cost</label>
                                    </div>
                                    <div className={Styles.FieldContainer}>
                                        <TextField variant="outlined" disabled={!edit} size="small" sx={{ width: "90%", backgroundColor: "white" }} onChange={handleChange} name="cost" value={form.cost} />
                                        {formError.cost && <span className={Styles.error}>{formError.cost}</span>}
                                    </div>

                                </div> 
{/*                                 
                                <div className={Styles.SubContainerList}>
                                    <div className={Styles.LabelContainer}>
                                        <label className={Styles.Label}>Zone</label>
                                    </div>
                                    <div className={Styles.FieldContainer}>
                                        <TextField variant="outlined" disabled={true} size="small" sx={{ width: "90%", backgroundColor: "white" }} onChange={handleChange} name="zone_name" value={form.zone_name} />
                                    </div>

                                </div> */}

                            </div>
                        </div>

                        {
                            edit ? (
                                <div className={Styles.ButtonContainer}>
                                    <RedButton variant="contained" onClick={handleSubmit}>Update</RedButton>
                                    <GrayButton variant="contained" onClick={handleNoEdit}>Cancel</GrayButton>
                                </div>
                            ) :
                                null
                        }


                    </div>
                    
                ) :
                    (
                        <label>No Data </label>
                    )
            }

            </div>
        </>
    )
}

export default UpdateProductMgt
