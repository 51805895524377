import React from 'react'
import CoinManagementTable from './CoinManagementTable'

const CoinManagement = () => {
  return (
    <CoinManagementTable/>
  )
}

export default CoinManagement
