import * as React from "react";
import Paper from "@mui/material/Paper";
import { Stack, Typography, TabPanel } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import skuData from "./data.json";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useSelector } from "react-redux";
import useSkuList from "../../hooks/useSkuList";
import ConfirmationDialog from "../../utility/ConfirmationDialog";
import { useState, useEffect } from "react";
import useDeleteSku from "../../hooks/useDeleteSku";
import EditSku from "./EditSku";
import useUpdateSku from "../../hooks/useUpdateSku";

export default function SKUManagementTable() {
  const skuList = useSelector((store) => store.sku.skuList);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = React.useState(false);
  const [skuId, setSkuId] = useState(null);
  const [skuEditId, setSkuEditId] = useState(null);
  const [skuRowData,setSkuRowData]=useState(null)
  const [open,setOpen]=useState(false)
  const getAllSkuList = useSkuList();
  const getSkuList = useSkuList();
  const deleteSku = useDeleteSku(skuId);
  
   
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleDelete = (data) => {
    setIsConfirmDialogOpen(true);
    setSkuId(data?.sku_id);

  };

  const handleConfirmDialogClose = () => {
    setIsConfirmDialogOpen(false);
  };

  const handleConfirmApproveClick = () => {
    deleteSku();
    getAllSkuList();
    setIsConfirmDialogOpen(false);
  };
  useEffect(() => {
    getSkuList();
  }, [open]);
  
  const handleEditClick=(data)=>{
    setOpen(true)
    setSkuEditId(data?.sku_id)
    setSkuRowData(data)

  }

  return (
    <Paper sx={{ width: "100%", padding:"0", boxShadow:"none" }}>
      <TableContainer sx={{ maxHeight: 440, padding:"0px"}}>

        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  background: "#F8F8F8",
                  fontSize: "16px",
                  color: "#5F6868",
                  fontWeight: "500",
                }}
                align="start"
                colSpan={1}
              >
                SI no.
              </TableCell>
              <TableCell
                sx={{
                  background: "#F8F8F8",
                  fontSize: "16px",
                  color: "#5F6868",
                  fontWeight: "500",
                }}
                align="start"
                colSpan={1}
              >
                Image
              </TableCell>
              <TableCell
                sx={{
                  background: "#F8F8F8",
                  fontSize: "16px",
                  color: "#5F6868",
                  fontWeight: "500",
                }}
                align="start"
                colSpan={3}
              >
                SKU Name
              </TableCell>
              
              <TableCell
                sx={{
                  background: "#F8F8F8",
                  fontSize: "16px",
                  color: "#5F6868",
                  fontWeight: "500",
                }}
                align="start"
                colSpan={1}
              >
                Category Name
              </TableCell>
              <TableCell
                sx={{
                  background: "#F8F8F8",
                  fontSize: "16px",
                  color: "#5F6868",
                  fontWeight: "500",
                }}
                align="start"
                colSpan={1}
              >
                Pieces
              </TableCell>
              <TableCell
                sx={{
                  background: "#F8F8F8",
                  fontSize: "16px",
                  color: "#5F6868",
                  fontWeight: "500",
                }}
                align="start"
                colSpan={1}
              >
                Ladi
              </TableCell>
              <TableCell
                sx={{
                  background: "#F8F8F8",
                  fontSize: "16px",
                  color: "#5F6868",
                  fontWeight: "500",
                }}
                align="start"
                colSpan={1}
              >
                Price / Ladi
              </TableCell>
              <TableCell
                sx={{
                  background: "#F8F8F8",
                  fontSize: "16px",
                  color: "#5F6868",
                  fontWeight: "500",
                }}
                align="start"
                colSpan={1}
              >
                Carton
              </TableCell>
              <TableCell
                sx={{
                  background: "#F8F8F8",
                  fontSize: "16px",
                  color: "#5F6868",
                  fontWeight: "500",
                }}
                align="start"
                colSpan={1}
              >
                Price / Carton
              </TableCell>
              <TableCell
                sx={{
                  background: "#F8F8F8",
                  fontSize: "16px",
                  color: "#5F6868",
                  fontWeight: "500",
                }}
                align="start"
                colSpan={1}
              >
                Status
              </TableCell>
              <TableCell
                sx={{
                  background: "#F8F8F8",
                  fontSize: "16px",
                  color: "#5F6868",
                  fontWeight: "500",
                }}
                align="center"
                colSpan={1}
              >
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {skuList
              ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              ?.map((data, index) => {
                const serialNumber = page * rowsPerPage + index + 1;
                return (
                  <>
                    <TableRow hover role="checkbox" tabIndex={-1} key={data.id}>
                      <TableCell align="start">{serialNumber}</TableCell>
                      <TableCell align="start" colSpan={1}>
                        <img style={{width:"3rem"}} src={data?.sku_image} />
                      </TableCell>
                      <TableCell align="start" colSpan={3}>
                        {data.sku_name}
                      </TableCell>
                      <TableCell align="start">
                        {data.category_name ?? "N/A"}
                      </TableCell>
                      <TableCell align="start" colSpan={1}>
                      ₹{data.price_per_piece}
                      </TableCell>
                      <TableCell align="start" colSpan={1}>
                        {data.ladi}
                      </TableCell>
                      <TableCell align="start" colSpan={1}>
                      ₹{data.price_per_ladi}
                      </TableCell>
                      <TableCell align="start" colSpan={1}>
                        {data.carton}
                      </TableCell>
                      <TableCell align="start" colSpan={1}>
                      ₹{data.price_per_carton}
                      </TableCell>
                      <TableCell align="start">
                        {data.status === true ? (
                          <Typography
                            sx={{
                              background: "#6EB443",
                              width: "max-content",
                              padding: "2px 10px 2px 12px",
                              borderRadius: "32px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              color: "#FFFFFF",
                              fontSize: "9px",
                              fontWeight: "400",
                            }}
                          >
                            Active
                          </Typography>
                        ) : (
                          <Typography
                            sx={{
                              background: "#58585A",
                              width: "max-content",
                              padding: "2px 10px 2px 12px",
                              borderRadius: "32px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              color: "#FFFFFF",
                              fontSize: "9px",
                              fontWeight: "400",
                            }}
                          >
                            Inactive
                          </Typography>
                        )}
                      </TableCell>
                      <TableCell align="center">
                        <Stack
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            width: "100%",
                            justifyContent: "center",
                            gap: "0.5rem",
                          }}
                        >
                          <EditIcon
                            sx={{
                              width: "1.2rem",
                              color: "#FF5A5A",
                              cursor: "pointer",
                            }}
                            onClick={()=>handleEditClick(data)}
                          />
                          {/* <DeleteIcon
                            sx={{
                              width: "1.2rem",
                              color: "#FF5A5A",
                              cursor: "pointer",
                            }}
                            onClick={() => handleDelete(data)}
                          /> */}
                        </Stack>
                      </TableCell>
                    </TableRow>
                  </>
                );
              })}
          </TableBody>
        </Table>
        <EditSku setOpen={setOpen} open={open} skuEditId={skuEditId} skuRowData={skuRowData}/>
        <ConfirmationDialog
          open={isConfirmDialogOpen}
          onClose={handleConfirmDialogClose}
          onConfirm={handleConfirmApproveClick}
          sku_id={skuId}
          type={2}
        />
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={skuList?.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
