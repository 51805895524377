import React from "react";
import useAxiosPrivate from "./useAxiosPrivate";
import { useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
const useAddUser = (form,type) => {
  // console.log(type.type,"typeee");
  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();
  const addUser = async () => {
    let param = {
      first_name: `${form.first_name}`,
      last_name: `${form.last_name}`,
      mobile_no: `${form.mobile}`,
      zone_id: parseInt(`${form.zone_id}`, 10) || 0,
      sc_id: form?.scIdArray,
      role_key: type?.type === 1 ? "se" : type?.type === 2 ? "so" : "asm",
      email: form?.email
    };
    // console.log(param, "distributorList");
    const response = await Axios.post("/add_user", param);
    const data = await response.data;
    // console.log(data, "distributorList");
    if (data.code === 200) {
      toast.success(data.message);
    } else {
      toast.error(data.message);
    }

    // console.log(data);
  };
  // useEffect(() => {
  //   getAllUserList();
  // }, []);
  return addUser;
};

export default useAddUser;
