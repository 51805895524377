import React from "react";
import BarGraph from "./BarGraph";
import { Stack } from "@mui/material";
import TopAchievers from "./TopAchievers";
import PotentialGrowth from "./PotentialGrowth";
import CallStampTable from "./CallStampTable";
import BarGraphNew from "./BarGraphNew";

const CallStamp = () => {

  const [workingDaysInMonth, setWorkingDaysInMonth] = React.useState(0);
  const [workingDaysPassed, setWorkingDaysPassed] = React.useState(0);

  React.useEffect(() => {
    const getWorkingDaysInMonth = (year, month) => {
      let totalWorkingDays = 0;
      const daysInMonth = new Date(year, month + 1, 0).getDate();
      for (let day = 1; day <= daysInMonth; day++) {
        const date = new Date(year, month, day);
        const dayOfWeek = date.getDay();
        if (dayOfWeek !== 0) { // Exclude Sundays (0 represents Sunday)
          totalWorkingDays++;
        }
      }
      return totalWorkingDays;
    };

    const getWorkingDaysPassedInMonth = () => {
      const today = new Date();
      let workingDays = 0;
      for (let day = 1; day <= today.getDate(); day++) {
        const date = new Date(today.getFullYear(), today.getMonth(), day);
        const dayOfWeek = date.getDay();
        if (dayOfWeek !== 0) { // Exclude Sundays (0 represents Sunday)
          workingDays++;
        }
      }
      return workingDays;
    };

    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth();

    setWorkingDaysInMonth(getWorkingDaysInMonth(year, month));
    setWorkingDaysPassed(getWorkingDaysPassedInMonth());
  }, []);

  return (
    <Stack sx={{ display: "flex", flexDirection: "column", gap: "1.5rem" }}>
      {/* <TopAchievers workingDaysInMonth={workingDaysInMonth} /> */}
      {/* <BarGraph /> */}
      <BarGraphNew/>
      {/* <PotentialGrowth /> */}
      <CallStampTable workingDaysInMonth={workingDaysInMonth}/>
    </Stack>
  );
};

export default CallStamp;
