import React, { useState } from "react";
import * as XLSX from "xlsx";
import axios from "axios";
import useUploadDistributorExcel from "../../../hooks/useUploadDistributorExcel";
import { useSelector } from "react-redux";
import { Button, Input, Stack } from "@mui/material";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { styled } from "@mui/material/styles";
import useDistributorList from "../../../hooks/useDistributorList";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const ExcelUpload = () => {
  const getUploadDistributorExcel = useUploadDistributorExcel();
  const getAllDistributorList = useDistributorList();
  const currentzone = useSelector((state) => state.assignRoute);

  // Handle file input change
  const handleFileChange = async (event) => {
    const distibutorExcel = event.target.files[0];
    const formData = new FormData();

    // Append the file and other data to FormData
    formData.append("file", distibutorExcel);
    formData.append("zone_id", currentzone?.zone_id);

    // Upload the form data
    getUploadDistributorExcel(formData);

    // Fetch distributor list
    getAllDistributorList();

    // Reset the file input
    event.target.value = null;
  };

  return (
    <div>
      <Button
        sx={{
          textTransform: "none",
          padding: "14px 12px 14px 12px !important",
          fontSize: "14px !important",
          background:""
        }}
        component="label"
        role={undefined}
        variant="contained"
        tabIndex={-1}
        startIcon={<CloudUploadIcon />}
      >
        Upload SS
        <VisuallyHiddenInput
          type="file"
          accept=".xlsx, .xls"
          onChange={(event) => handleFileChange(event)}
          multiple
        />
      </Button>
    </div>
  );
};

export default ExcelUpload;
