import { useEffect } from "react";
import useAxiosPrivate from "./useAxiosPrivate";
import { useDispatch } from "react-redux";

const useDashboard = () => {
  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();

  const getDashboardMapData = async (param) => {
    try {
      const response = await Axios.post("dashboard", param);
      const data = response.data;
      return data;
    } catch (error) {
      // console.error("Error fetching admin map data:", error);
      throw error;
    }
  };
  return getDashboardMapData;
};

export default useDashboard;
