import React from "react";
import {Stack} from "@mui/material"
import Sidebar from "../Sidebar";
import DashboardMapView from "./DashboardMapView";
import DashboardSideCard from "./DashboardSideCard";

const index = () => {

  return (
    <Stack sx={{display:"flex",flexDirection:"column",justifyContent:"center", backgroundColor:"#E5E5E5"}}>
      <Stack sx={{display:"flex",flexDirection:"row", width:"100vw", maxWidth:"100vw", gap:"5px"}}>
        <Sidebar/>
        <Stack sx={{height:{ xs: "100%", lg:  "calc(100vh - 55px)"}, width:"100%", margin:"4px 4px 0px 0px", borderRadius:"4px", flexDirection: { xs: "column", lg: "row" }, gap:"5px"}}>
        <Stack sx={{ width: "100%", height: "100%" }}>
        <DashboardMapView/>
        </Stack>
        <DashboardSideCard/>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default index;
