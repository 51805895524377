import React, { useState, useEffect } from 'react';
import Styles from './styles/index.module.css';
import ApproveMobileReplacement from './ApproveMobileReplacement';
import ApproveCoinTransaction from './ApproveCoinTransaction';
import { useSelector, useDispatch } from 'react-redux';
// import { GetCoinTransactionListApproval, GetMobileUUIDListApproval, GetOutletStatusListApproval, GetUpdateLocationListApproval } from '../../../actions/Approval/approval';
import { Card, Paper, Stack, Typography } from '@mui/material';
import ApprovalUpdateLocation from './ApprovalUpdateLocation';
import ApprovalOutletStatus from './ApprovalOutletStatus';
import {GrCircleAlert} from 'react-icons/gr'
import useUserList from '../../hooks/useUserList';

const ApprovalsMgt = ({ zone }) => {
    const approval = useSelector(state => state.user)
    useUserList()
    const [tab,setTab] = useState('coin')
//   React.useEffect(() => {
//     if (localStorage.getItem('Login2')) {
//       let Obj = localStorage.getItem('Login2');
//       setUser(JSON.parse(Obj))
//     }

//   }, [localStorage]);

    const dispatch = useDispatch();
    const Firsttoken = useSelector(state => state.Token);
    const [token, setToken] = useState('');

     let Data = localStorage.getItem('Login2') ?? {}
    // let Admin = JSON.parse(Data)
    // useEffect(() => {
    //     if (Firsttoken && Firsttoken.token && Firsttoken.token !== null && Firsttoken.token.length !== 0 && Firsttoken !== "undefined") {
    //         setToken(Firsttoken.token);

    //         let param = {
    //             role : Admin.role,
    //             id : Admin.id,
    //             zone_name : zone
    //         }
    //         dispatch(GetCoinTransactionListApproval(Firsttoken.token,param))
    //         dispatch(GetMobileUUIDListApproval(Firsttoken.token,param))
    //         dispatch(GetUpdateLocationListApproval(Firsttoken.token,param))
    //         dispatch(GetOutletStatusListApproval(Firsttoken.token,param))
    //     }
    // }, [Firsttoken, zone])

    return (
        <>
        <Stack direction="column" gap={1} sx={{width:"100vw"}}>

       
        <Stack direction={{xs:"column",sm:"column",md:"row"}} gap={1}>
        <Card component={Paper} sx={{flex:1,padding:"10px",display:"flex",justifyContent:"center",gap:"10px",alignItems:"center",cursor:"pointer",color: `${tab==="coin" ? "#731D3A" : "black"}`,borderBottom : `5px solid ${tab==="coin" ? "#731D3A" : "white"}`}} onClick={()=>setTab("coin")}>
             <Typography variant="body2" sx={{fontWeight:"bold"}}>CoinTransaction</Typography>
             <Stack>
               
                <Card component={Paper} sx={{display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"row",backgroundColor:"#731D3A",padding:"2px 5px",color:"white"}}>
                <Typography >{approval?.ApprovalListCoin?.length ?? 0}</Typography>
                {/* <GrCircleAlert color="white" fontSize="1.5em"/> */}
                </Card>
             </Stack>
        </Card>
        <Card component={Paper} sx={{flex:1,padding:"10px",display:"flex",justifyContent:"center",gap:"10px",alignItems:"center",cursor:"pointer",color: `${tab==="mobile" ? "#731D3A" : "black"}`,borderBottom : `5px solid ${tab==="mobile" ? "#731D3A" : "white"}`}} onClick={()=>setTab("mobile")}>
        <Typography variant="body2" sx={{fontWeight:"bold"}}>Mobile Number Replacement</Typography>
        <Stack>
               
                <Card component={Paper} sx={{display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"row",backgroundColor:"#731D3A",padding:"2px 5px",color:"white"}}>
                <Typography >{approval?.ApprovalListUUID?.length ?? 0}</Typography>
                {/* <GrCircleAlert color="white" fontSize="1.5em"/> */}
                </Card>
             </Stack>
        </Card>
        <Card component={Paper} sx={{flex:1,padding:"10px",display:"flex",justifyContent:"center",gap:"10px",alignItems:"center",cursor:"pointer",color: `${tab==="location" ? "#731D3A" : "black"}`,borderBottom : `5px solid ${tab==="location" ? "#731D3A" : "white"}`}} onClick={()=>setTab("location")}>
        <Typography variant="body2" sx={{fontWeight:"bold"}}>Update Location</Typography>
        <Stack>
               
                <Card component={Paper} sx={{display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"row",backgroundColor:"#731D3A",padding:"2px 5px",color:"white"}}>
                <Typography >{approval?.ApprovalListLocation?.length ?? 0}</Typography>
                {/* <GrCircleAlert color="white" fontSize="1.5em"/> */}
                </Card>
             </Stack>
        </Card>
        <Card component={Paper} sx={{flex:1,padding:"10px",display:"flex",justifyContent:"center",gap:"10px",alignItems:"center",cursor:"pointer",color: `${tab==="outlet" ? "#731D3A" : "black"}`,borderBottom : `5px solid ${tab==="outlet" ? "#731D3A" : "white"}`}} onClick={()=>setTab("outlet")}>
        <Typography variant="body2" sx={{fontWeight:"bold"}}>Outlet status</Typography>
        <Stack>
               
                <Card component={Paper} sx={{display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"row",backgroundColor:"#731D3A",padding:"2px 5px",color:"white"}}>
                <Typography >{approval?.ApprovalListOutlet?.length ?? 0}</Typography>
                {/* <GrCircleAlert color="white" fontSize="1.5em"/> */}
                </Card>
             </Stack>
        </Card>
        </Stack>
        
        <div className={Styles.container}>
            {
                tab==="coin" ? <ApproveCoinTransaction token={token}/>
                      :   tab==="mobile" ? <ApproveMobileReplacement token={token} zone={zone}/>
                      : tab ==="location" ? <ApprovalUpdateLocation token={token} zone={zone}/> : <ApprovalOutletStatus token={token} zone={zone}/>}
        </div>
        </Stack>
        </>
    );
};

export default ApprovalsMgt; 
