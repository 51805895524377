import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { Typography } from "@mui/material";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
      //   position: "top",
    },
    title: {
      display: true,
      text: "Sales Chart",
    },
  },
  elements: {
    line: {
      backgroundColor: "rgba(0, 0, 0, 0)", // Set line background to transparent
    },
    point: {
      backgroundColor: "rgba(0, 0, 0, 0)", // Set point background to transparent
    },
  },
  layout: {
    backgroundColor: "rgba(0, 0, 0, 0)", // Set layout background to transparent
  },
  scales: {
    x: {
      grid: {
        color: "rgba(0, 0, 0, 0)", // Set x-axis grid lines to transparent
      },
    },
    y: {
      grid: {
        color: "rgba(0, 0, 0, 0)", // Set y-axis grid lines to transparent
      },
    },
  },
};

export function SalesLineChart(TopSRData) {
  const labels = TopSRData?.TopSRData?.sales_graph_data?.map(item => item.date);
  const salesData = TopSRData?.TopSRData?.sales_graph_data?.map(item => item.total_sales);
  const data = {
    labels,
    datasets: [
      {
        label: "Total Sales",
        data: salesData,
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
    ],
  };
  return (
    <div style={{ height: "300px", background: "white", padding:"8px 8px 8px 12px", borderRadius:"8px", border:"1px solid #C0C0C0" }}>   
      <Line options={options} data={data} />
    </div>
  );
}
export default SalesLineChart
