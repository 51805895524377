import logo from "./logo.svg";
import "./App.css";
import SignIn from "./component/SignIn";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { ThemeProvider } from "@material-ui/styles";
import { createMuiTheme } from "@material-ui/core/styles";
import "./index.css";
import ReactDOM from "react-dom/client";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import PersistLogin from "./utility/PersistLogin";
import SkuMgmt from "./component/SkuMgmt";
import SalesMgt from "./component/SalesMngt/index";
import ProductMgt from "./component/ProductManagement/index.js";

import ZoneMgt from "./component/ZoneManagement/ZoneMgt.js";

import { useSelector } from "react-redux";
import Dashboard from "./component/Dashboard/Dashboard.js";

import SkuCategoryPage from "./component/SkuCategoryPage.js";

import UserManagementPage from "./component/UserManagement.js/UserManagementPage.js";
import ApprovalManagementPage from "./component/ApprovalManagement/ApprovalManagementPage.js";

import MainDashboard from "./component/MainDashboard/MainDasboard";
import UnauthorizedPage from "./UnauthorizedPage.js";
import MissingRoute from "./utility/MissingRoute";
import Login from "./component/Login/index.js";
import DashboardHeader from "./component/Dashboard/AdminHeader";
import BarGraph from "./component/PerformanceManagement/CallStamp/BarGraph.js";
import PerformanceMgt from "./component/PerformanceManagement/PerformanceMgt.js";
import SchemeManagement from "./component/SchemeManagement/SchemeManagement.js";
import AddNewScheme from "./component/SchemeManagement/AddNewScheme.js";
import AddNewSchemeWithPercentage from "./component/SchemeManagement/AddNewSchemeWithPercentage.js";
import AddNewSchemeWithFreeQty from "./component/SchemeManagement/AddNewSchemeWithFreeQty.js";
import LandingPage from "./component/HomePage/LandingPage";
import ContactUs from "./component/HomePage/ContactUs";
import VitranPartnerForm from "./component/HomePage/VitranPartnerForm";
import TermsAndConditions from "./component/HomePage/TermsandConditon";
import PrivacyPolicy from "./component/HomePage/PrivacyPolicy";
// import DashboardMapView from "./component/MapView/index";
import MainDashboardMapView from "./component/MainDashboard/RealTimeMap";
import CoinManagementDetails from "./component/PerformanceManagement/CoinManagement/CoinManagementDetails.js";
import AddCampaign from "./component/SchemeManagement/AddCampaign";
import RouteManagement from "./component/RouteManagement/index.js";
import ZoneListView from "./component/MainDashboard/AdminMapView/ZoneDetails";
import Download from "./component/DownloadFile/Download.js";
import { ToastContainer } from "react-toastify";
import AdminMapView from "./component/MainDashboard/AdminMapView/AdminMapView";
import AdminMarkerView from "./component/MainDashboard/AdminMarkerView/AdminMarkerView";
function App() {
  const THEME = createMuiTheme({
    typography: {
      fontFamily: `'Roboto', sans-serif"`,
    },
  });

  const loginData = useSelector((store) => store.login.loginData);
  const currentRole = localStorage.getItem("data");
  return (
    <ThemeProvider theme={THEME}>
      <div className="App">
        <Router>
          <Routes>
            <Route path="*" element={<MissingRoute />} />
            <Route path="/" element={<LandingPage />} />
            <Route
              exact
              path="/vitaranpartner"
              element={<VitranPartnerForm />}
            />
            <Route exact path="/contactus" element={<ContactUs />} />
            <Route
              exact
              path="/termsandconditions"
              element={<TermsAndConditions />}
            />
            <Route path="/privacypolicy" element={<PrivacyPolicy />} />
            <Route exact path="/login" element={<Login />} />
            <Route path="/home" element={<DashboardHeader />}>
            <Route exact index element={<MainDashboard />} />
              <Route path="/home/usermgt" element={<UserManagementPage />} />
              <Route path="/home/category" element={<SkuCategoryPage />} />


              <Route  path="/home/Schememgt" element={<SchemeManagement />} />
              <Route  path="/home/addScheme" element={<AddNewSchemeWithPercentage />} />
              <Route  path="/home/addcampaign" element={<AddCampaign />} />
              {/* <Route  path="/home/addSchemeFreeQty" element={<AddNewSchemeWithFreeQty />} /> */}
              <Route  path="/home/salesmgt" element={<SalesMgt />} />
              <Route  path="/home/zonemgt" element={<ZoneMgt />} />
              <Route  path="/home/performanceMgt" element={<PerformanceMgt />} />
              <Route
                path="/home/coinMgtDetails"
                element={<CoinManagementDetails />}
              />
              <Route path="/home/approval" element={<ApprovalManagementPage />}/>
              <Route path="/home/routemgtmap" element={currentRole === "admin" ? <Dashboard /> : <Login />}/>


              <Route exact path="/home/dashboard" element={<MainDashboard />} />
              <Route exact path="/home/downloadDetails" element={<Download/>} />
              <Route exact path="/home/dashboardMapView" element={<MainDashboardMapView />} />
              <Route exact path="/home/adminMapView" element={<AdminMapView />} />
              <Route exact path="/home/adminMarkerView" element={<AdminMarkerView/>} /> 
              <Route exact path="/home/zone_list_view" element={<ZoneListView />} />

              <Route exact path="/home/routemgt" element={<RouteManagement />} />
            </Route>

            {/* <Route exact path="/dashboard" element={<MainDashboard />} /> */}
            <Route exact path="/unauthorized" element={<UnauthorizedPage />} />
            <Route element={<PersistLogin />}></Route>
          </Routes>
        </Router>
        <ToastContainer
          position="bottom-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </div>
    </ThemeProvider>
  );
}

export default App;
