import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import {
  FormControl,
  Select,
  MenuItem,
  TextField,
  InputLabel,
  Stack,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import AddIcon from "@mui/icons-material/Add";
import { Input } from "@mui/icons-material";
import {
  getCategoryFormData,
  getCategoryList,
} from "../../slice/categorySlice";
import useCategoryImageUpload from "../../hooks/useCategoryImageUpload";
import useAddCategory from "../../hooks/useAddCategory";
import { BaseImgUrl } from "../../utility/BaseURL";
import { ToastContainer, toast } from "react-toastify";
import useSuperCategoryList from "../../hooks/useSuperCategoryList";
import useUpdateSuperCategoryList from "../../hooks/useUpdateSuperCategory";
import useSuperCategoryImageUpload from "../../hooks/useSuperCategoryImageUpload";
import { resetSuperCategoryFormData } from "../../slice/superCategorySlice";
import PhotoCameraOutlinedIcon from "@mui/icons-material/PhotoCameraOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
// import { getZoneList } from "../slice/appSlice";
// import useZoneList from "../hooks/useZoneList";
// import useStateList from "../hooks/useStateList";
// import useDistributorList from "../hooks/useDistributorList";
// import useAddDistributor from "../hooks/useAddDistributor";

const viewImgPath = `${BaseImgUrl}/category/`;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function EditSuperCategory({
  setOpen,
  open,
  superCategoryEditId,
  superCategoryRowData,
}) {
  const [enable, setEnable] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const [form, setForm] = React.useState({});
  // const [checked, setChecked] = React.useState({
  //   ladi: false,
  //   carton: false,
  //   piece: false,
  // });
  const handleClose = () => {
    setOpen(false);
    // setForm({});
    // setError({});
    dispatch(resetSuperCategoryFormData());
    setForm(superCategoryRowData);
  };

  const dispatch = useDispatch();

  const currentSelectedImage = useSelector(
    (state) => state.superCategory.superCategoryFormData
  );
  const getSuperCategoryImageUpload = useSuperCategoryImageUpload();
  const addCategory = useAddCategory(form);
  const getAllSuperCategoryList = useSuperCategoryList();
  const statusList = ["Active", "Inactive"];

  useEffect(() => {
    setForm((prev) => ({
      ...prev,
      sc_id: superCategoryRowData?.sc_id,
    }));
  }, [superCategoryEditId]);

  useEffect(() => {
    setForm(superCategoryRowData);
  }, [superCategoryRowData]);
  
  useEffect(() => {
    setForm((prev) => ({
      ...prev,
      sc_image: currentSelectedImage?.full_path,
    }));
  }, [currentSelectedImage]);

  const handleChange = (e) => {
    setForm((prev) => ({
      ...prev,
      [e.target.name]: e.target.value ,
    }));
  };

  const [error, setError] = React.useState({});
  const isValid = () => {
    let success = true;
    let err = {};

    if (
      form.sc_name?.trim() === "" ||
      form.sc_name?.trim() === undefined ||
      form.sc_name?.trim() === null
    ) {
      err.name = "**Super Category Name Cannot be Empty**";
      success = false;
    }

    // if (
    //   form.selectedImage?.trim() === "" ||
    //   form.selectedImage?.trim() === undefined ||
    //   form.selectedImage?.trim() === null
    // ) {
    //   err.selectedImage = "**Image should be selected**";
    //   success = false;
    // }
    setError(err);
    return success;
  };

  const handleImageChange = (e) => {
    setForm((prev) => ({
      ...prev,
      [e.target.name]: e.target.files[0],
    }));
    // setEnable(true);
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    getSuperCategoryImageUpload(formData);
    if (e.target.files[0]) {
      setForm((prev) => ({
        ...prev,
        selectedImage: e.target.files[0].name,
      }));
    } else {
      setForm((prev) => ({
        ...prev,
        selectedImage: null,
      }));
    }
  };

  const updateCategoryDetails = useUpdateSuperCategoryList(form);

  const handleSubmit = async(e) => {
    e.preventDefault();
    if (isValid()) {
      dispatch(resetSuperCategoryFormData());
      updateCategoryDetails();

      await getAllSuperCategoryList();
      setOpen(false);
      setForm({});
    }
  };

  const handleCheckbox = (e) => {
    setForm((prev) => ({
      ...prev,
      [e.target.name]: e.target.checked,
    }));
  };

  return (
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div>
        
        <Modal
          open={open}
          // onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <FormControl
              fullWidth
              size="small"
              sx={{ display: "flex", flexDirection: "column", gap: "0.8rem" }}
            >
              <Stack
                sx={{ display: "flex", flexDirection: "column", gap: "0.8rem" }}
              >
                <Stack sx={{ marginBottom: "1.5rem" }}>
                  <Typography sx={{ color: "#000000" }}>
                    Add New Super Category
                  </Typography>
                </Stack>
                <input
                  variant="outlined"
                  name="sc_name"
                  placeholder="Enter super category name"
                  value={form?.sc_name}
                  onChange={handleChange}
                  style={{
                    height: "2.4rem",
                    paddingLeft: "0.5rem",
                    fontSize: "0.9rem",
                  }}
                />
                {error.name && (
                  <p
                    style={{
                      color: "red",
                      justifyContent: "center",
                      display: "flex",
                      fontSize: "0.8rem",
                      marginTop: "-0.1rem",
                    }}
                  >
                    {error.name}
                  </p>
                )}
              </Stack>

              <Stack>
                <Typography>Choose a Quantity Type :</Typography>
                <FormGroup sx={{ display: "flex", flexDirection: "row" }}>
                  {form?.ispiece !== undefined && (
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={(e) => handleCheckbox(e)}
                          checked={form?.ispiece}
                        />
                      }
                      label="Piece"
                      name="ispiece"
                    />
                  )}
                  {form?.isladi !== undefined && (
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={(e) => handleCheckbox(e)}
                          checked={form?.isladi}
                        />
                      }
                      label="Ladi"
                      name="isladi"
                    />
                  )}
                  {form?.iscarton !== undefined && (
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={(e) => handleCheckbox(e)}
                          checked={form?.iscarton}
                        />
                      }
                      label="Carton"
                      name="iscarton"
                    />
                  )}
                </FormGroup>
              </Stack>
              <Stack
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "0.4rem",
                }}
              >
                <label htmlFor="sku_image" style={{ cursor: "pointer" }}>
                  <input
                    id="sku_image"
                    type="file"
                    size="small"
                    accept="image/*"
                    name="sku_image"
                    style={{
                      display: "none",
                    }}
                    onChange={handleImageChange}
                  />

                  <div
                    style={{
                      height: "180px",
                      fontSize: "0.9rem",
                      border: "1px solid #ccc",
                      borderRadius: "4px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      cursor: "pointer",
                      background: "#F2F2F2",
                    }}
                  >
                    {currentSelectedImage ? (
                      <img
                        style={{
                          height: "80%",
                          width: "80%",
                          objectFit: "contain",
                        }}
                        src={currentSelectedImage.full_path}
                        alt="super_category_image"
                      />
                    ) : (
                      <img
                        style={{
                          height: "80%",
                          width: "80%",
                          objectFit: "contain",
                        }}
                        src={form?.sc_image}
                        alt="sc_image"
                      />
                    )}
                  </div>
                </label>
              </Stack>

              <Stack
                sx={{ display: "flex", flexDirection: "column", gap: "0.4rem" }}
              >
                <select
                  style={{
                    height: "1.8rem",
                    fontSize: "0.9rem",
                    cursor: "pointer",
                  }}
                  name="status"
                  onChange={handleChange}
                >
                  <option value="" disabled selected>
                    {form?.status === true ? "Active" : "InActive"}
                  </option>
                  {statusList.map((item, index) => (
                    <option key={index}>{item}</option>
                  ))}
                </select>
                {error.category && (
                  <p
                    style={{
                      color: "red",
                      justifyContent: "center",
                      display: "flex",
                      fontSize: "0.8rem",
                      marginTop: "-0.1rem",
                    }}
                  >
                    {error.category}
                  </p>
                )}
              </Stack>
              {/* Close Icon */}
              <Stack sx={{ position: "absolute", right: 0, cursor: "pointer" }}>
                <CloseOutlinedIcon onClick={handleClose} />
              </Stack>
              <Stack
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  gap: "0.4rem",
                }}
              >
                <Button
                  sx={{
                    background: "white",
                    color: "#FF5A5A",
                    border: "2px solid #FF5A5A",
                    height: "1.8rem",
                  }}
                  onClick={handleClose}
                >
                  Discard
                </Button>
                <Button
                  sx={{
                    background: "#EF6C1A",
                    color: "white",
                    height: "1.8rem",
                  }}
                  onClick={handleSubmit}
                  disabled={enable}
                >
                  Submit
                </Button>
              </Stack>
            </FormControl>
          </Box>
        </Modal>
      </div>
    </>
  );
}
