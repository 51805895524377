import {
  Stack,
  Card,
  CardContent,
  CardMedia,
  Typography,
  Button,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import VitaranLogo from "../../assets/landing_page_images/one.png";
import lobo_img from "../../assets/landing_page_images/lobo.png";
import delivery_img from "../../assets/cards/delivery-img.png";
import gift_img from "../../assets/cards/gift_img.png";
import b2b_img from "../../assets/cards/b2b-img.png";
import voucher_redeme from "../../assets/landing_page_images/voucher_redemption.png";
import real_time from "../../assets/landing_page_images/real_time_t.png";
// import delivery_img from "../../../assets/cards/delivery-img.jpg";
// import delivery_img from "../../../assets/cards/delivery-img.jpg";
// import delivery_img from "../../../assets/cards/delivery-img.jpg";
import AdbIcon from "@mui/icons-material/Adb";
import ten from "../../assets/landing_page_images/ten.png";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import FooterHero from "./FooterHero";
import Footer from "./Footer";

import aboutUs from "../../assets/landing_page_images/about.png";
import giftImg from "../../assets/gift_vouchers.png";
import instant_Delivery from "../../assets/landing_page_images/instant_delivery.png";
import {
  Navigate,
  Link as RouterLink,
  useLocation,
  useNavigate,
} from "react-router-dom";
import NavBar from "./NavBar";
import NavDrawer from "./NavDrawer";
import MenuIcon from "@mui/icons-material/Menu";
import axios from "axios";
import { baseUrl } from "../../utility/BaseURL";

const LandingPage = () => {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState(null);

  const handleDetails = () => {
    var elmntToView = document.getElementById("ScrollView");
    elmntToView.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "end",
    });
  };
  const cardsData = [
    {
      id: 1,
      imgSrc: delivery_img,
      heading: "Dashboard and Map view",
      description:
        "Comprehensive sales and performance insights for the entire business territory as well as a grid-based overview of retailers and Distributors.",
    },
    {
      id: 1,
      imgSrc: gift_img,
      heading: "Zone & Route management ",
      description:
        "divide their territory into zones and routes, with zone-level access for managers and graphical route allocation.",
    },
    {
      id: 1,
      imgSrc: b2b_img,
      heading: "Geofencing",
      description:
        "Simplified retailer onboarding, enabling Sales Executives to create sales or mark no sales within designated retailer locations for efficient operations.",
    },
    {
      id: 1,
      imgSrc: voucher_redeme,
      heading: "Offline Sales",
      description:
        "ensures uninterrupted work for SRs in no or low network areas, with automatic data upload when network is restored.",
    },
    {
      id: 1,
      imgSrc: real_time,
      heading: "Coin Management",
      description:
        "Coupons and Redeemable coins provided to SRs as incentives based on performance",
    },
    {
      id: 1,
      imgSrc: lobo_img,
      heading: "Performance Management - Stamps",
      description:
        "Navigate efficiently with optimized routes, maximizing productivity in the field.",
    },
    // Add similar objects for the remaining cards
  ];
  const refreshToken = localStorage.getItem("refreshToken");
  const apiUrl = `${baseUrl}/get_app_link`;
  const updateUrl = `${baseUrl}update_download_count`;
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Make a GET request to the specified URL
        const response = await axios.post(apiUrl);
        console.log(response.data);
        // Set the data in the state
        setData(response.data?.result[0]);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    // Call the fetchData function
    fetchData();
  }, [apiUrl]);
  const releaseDate = new Date(data?.release_date);
  const UpdateCount = async () => {
    try {
      // Make a GET request to the specified URL
      const response = await axios.post(updateUrl);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const formattedDate = releaseDate.toLocaleDateString("en-US", {
    day: "numeric",
    month: "short",
    year: "numeric",
  });

  if (refreshToken) {
    // Redirect to / if refreshToken exists
    return <Navigate to="/home" />;
  }
  return (
    <>
      <Stack sx={{ backgroundColor: "white", overflowX: "hidden" }}>
        <Stack sx={{ backgroundColor: "#f27d33" }} height={{ lg: "100vh" }}>
          <Stack sx={{ display: { xs: "none", lg: "flex" } }}>
            <NavBar open={open} />
          </Stack>
          <Stack
            sx={{
              display: { xs: "flex", lg: "none" },
              justifyContent: "space-between",
            }}
            mt={"1rem"}
            ml={"1rem"}
            mr={"1rem"}
            direction={"row"}
            alignItems={"center"}
            gap={5}
          >
            <Stack onClick={() => setOpen(true)}>
              <MenuIcon sx={{ color: "white", fontSize: "2rem" }} />
            </Stack>
            {/* <Stack >
              <img src={ten} alt="logo" />
            </Stack> */}
            <Stack
              sx={{
                display: { xs: "flex", lg: "none" },
                borderRadius: "6px",
              }}
            >
              <RouterLink
                style={{ textDecoration: "none", backgroundColor: "#f27d33" }}
                to={"/login"}
              >
                <Button
                  sx={{
                    color: "#000 !important",
                    backgroundColor: "#fff !important",
                    padding: "5px 2px",
                    width: "60px !important",
                    height: "40px !important",
                    "&:hover": {
                      backgroundColor: "#fff !important",
                      color: "#000 !important",
                    },
                  }}
                >
                  Login
                </Button>
              </RouterLink>
            </Stack>
          </Stack>
          <Stack sx={{ display: { xs: "none", lg: "flex" } }}>
            <NavDrawer open={open} setOpen={setOpen} />
          </Stack>
          <Stack
            direction={{ sm: "column", lg: "row" }}
            sx={{
              width: "90%",
              margin: "auto",
              alignItems: "center",
              display: "flex",
            }}
          >
            <Stack sx={{ color: "#fff", flex: 2 }}>
              <Typography
                sx={{ color: "#fff", fontWeight: "bold", textAlign: "left" }}
                variant="h3"
                gutterBottom
              >
                Streamlining Sales, Empowering Growth
              </Typography>
              <Stack>
                <Typography sx={{ fontSize: "24px", textAlign: "left" }}>
                  A comprehensive sales optimization app designed to
                  revolutionize the way businesses manage their sales teams.
                </Typography>
              </Stack>
              <Stack sx={{ color: "#000", marginTop: "1rem" }}>
                <Button
                  // startIcon={<AdbIcon fontSize="large" />}
                  variant="contained"
                  sx={{
                    width: "165px",
                    backgroundColor: "#fff !important",
                    color: "#DC1D13",
                    ":hover": { backgroundColor: "#fff !important" },
                    textWrap: "nowrap",
                  }}
                  onClick={UpdateCount}
                >
                  <a
                    style={{
                      textDecoration: "none",
                      color: "#000",
                      fontWeight: "600",
                    }}
                    href={data?.apk_url}
                    target="_blank"
                  >
                    {" "}
                    Download App
                  </a>
                </Button>

                {data?.version_code && (
                  <Typography sx={{ textAlign: "start" }}>
                    v{data.version_code} @ {formattedDate}
                  </Typography>
                )}
              </Stack>
            </Stack>
            <Stack
              sx={{
                width: "24rem",
                flex: 1,
              }}
            >
              <img src={VitaranLogo} alt="img" />
            </Stack>
          </Stack>

          <Stack
            sx={{ color: "white", margin: "auto" }}
            onClick={handleDetails}
            style={{ cursor: "pointer" }}
          >
            <Stack>
              <Typography>Explore</Typography>
            </Stack>
            <Stack
              mb={"2rem"}
              sx={{ paddingLeft: "15px" }}
              onClick={handleDetails}
            >
              <KeyboardDoubleArrowDownIcon />
            </Stack>
          </Stack>
        </Stack>
        <Stack sx={{ marginTop: "80px" }}>
          <Stack
            sx={{
              padding: "0% 10%",
              marginBottom: "58px",
              alignItems: "center",
            }}
          >
            <Typography
              maxWidth="lg"
              sx={{ fontSize: "20px", textAlign: "start" }}
            >
              Introducing Selorce, the ultimate sales optimization app designed
              to transform how businesses manage their sales teams. Tailored for
              Sales Representatives (SRs), Sales Officers (SOs), and Assistant
              Sales Managers (ASMs), Selorce offers a comprehensive solution for
              boosting efficiency, productivity, and driving business growth.
            </Typography>
          </Stack>
          <Stack
            sx={{
              padding: "0% 10%",
              flexDirection: { xs: "column", lg: "row" },
              maxWidth: "100%",
            }}
          >
            <Stack
              sx={{
                width: "100%",
                marginBottom: "20px",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <img
                src={instant_Delivery}
                alt="deliveryImg"
                style={{
                  width: "100%",
                  maxWidth: "400px",
                  height: "auto",
                  maxHeight: "480px",
                  display: "block",
                  borderRadius: "14px",
                }}
              />
              <Typography
                sx={{
                  fontSize: "32px",
                  fontWeight: "600",
                  textAlign: "center",
                }}
              >
                Optimized Routes
              </Typography>
              <Typography
                sx={{ fontSize: "24px", textAlign: "center", color: "gray" }}
              >
                Navigate Smarter, Sell Faster
              </Typography>
            </Stack>
            <Stack
              id="ScrollView"
              sx={{
                width: "100%",
                marginBottom: "20px",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <img
                src={giftImg}
                alt="giftImg"
                style={{
                  width: "100%",
                  maxWidth: "400px",
                  height: "auto",
                  display: "block",
                  maxHeight: "480px",
                  borderRadius: "14px",
                }}
              />
              <Typography
                sx={{
                  fontSize: "32px",
                  fontWeight: "600",
                  textAlign: "center",
                }}
              >
                Real-time Analytics
              </Typography>
              <Typography
                sx={{ fontSize: "24px", textAlign: "center", color: "gray" }}
              >
                Data-Driven Decisions, Instant Impact
              </Typography>
            </Stack>
          </Stack>
        </Stack>
        <Stack
          alignItems="center"
          textAlign="center"
          sx={{
            marginTop: "40px",
            backgroundColor: "#303030",
            paddingTop: "40px",
          }}
        >
          <Typography sx={{ fontWeight: "700", color: "#fff" }} variant="h3">
            Features
          </Typography>

          <Stack
            spacing={1}
            direction="row"
            flexWrap="wrap"
            padding="0% 5%"
            justifyContent="space-between"
            sx={{ marginTop: "80px" }}
          >
            {cardsData.map((card) => (
              <Card
                key={card.id}
                elevation={0}
                sx={{
                  backgroundColor: "#303030",
                  marginBottom: "40px !important",
                  maxWidth: "500px",
                  minWidth: "120px",
                  alignItems: "center",
                  display: "flex",
                  flexDirection: "column",
                  color: "#fff",
                }}
              >
                <CardMedia
                  component="img"
                  height="400"
                  width="200"
                  image={card.imgSrc}
                  alt={card.heading}
                  sx={{
                    // width: '100%',
                    objectFit: "cover",
                    maxWidth: "420px",
                    borderRadius: "14px",
                  }}
                />
                <CardContent>
                  <Typography
                    variant="h6"
                    sx={{ fontWeight: "600", fontSize: "20px" }}
                  >
                    {card.heading}
                  </Typography>
                  <Typography variant="body2" sx={{ fontSize: "16px" }}>
                    {card.description}
                  </Typography>
                </CardContent>
              </Card>
            ))}
          </Stack>
        </Stack>
        <Stack
          padding="0% 5%"
          sx={{
            flexDirection: "row",
            "@media (max-width: 600px)": { flexDirection: "column" },
            width: "90vw",
            marginTop: "80px",
            marginBottom: "80px",
            // gap: "20px",
            justifyContent: "space-between",
          }}
        >
          <Stack
            sx={{
              width: "60%",
              "@media (max-width: 600px)": { width: "100%" },
            }}
          >
            <Typography
              sx={{
                fontWeight: "700",
                textAlign: "start",
              }}
              variant="h3"
            >
              About Us
            </Typography>
            <Typography
              variant="body2"
              sx={{
                fontSize: "28px",
                lineHeight: "40px",
                marginTop: "20px",
                textAlign: "left",
              }}
            >
              Welcome to Selorce, India's top sales optimization platform. We
              offer optimized routes, real-time analytics, and streamlined order
              generation for businesses looking to streamline their sales
              operations. Join us in transforming the B2B sales landscape and
              empowering businesses to succeed.
            </Typography>
            {/* <Typography
              variant="body2"
              sx={{ fontSize: "18px", lineHeight: "45px", textAlign: "left" }}
            >
              With a focus on innovation and customer satisfaction, our platform
              is designed to enhance the shopping experience for both buyers and
              sellers. Join us on this journey towards a more connected and
              efficient B2B e-commerce ecosystem.
            </Typography> */}
          </Stack>
          <Stack
            sx={{
              width: "30%",
              "@media (max-width: 600px)": { width: "100%" },
            }}
          >
            <img
              src={aboutUs}
              alt="deliveryImg"
              style={{
                width: "100%",
                minHeight: "400px",
                maxWidth: "500px",
                height: "auto",
                maxHeight: "480px",
                display: "block",
                borderRadius: "16px",
              }}
            />
          </Stack>
        </Stack>
        {/* <FooterHero /> */}
        <Footer />
      </Stack>
    </>
  );
};

export default LandingPage;
