import { useEffect } from "react";
import useAxiosPrivate from "./useAxiosPrivate";
import { useDispatch, useSelector } from "react-redux";
import { getUserList } from "../slice/userSlice";
import { getStateList, getZoneList } from "../slice/appSlice";


const useStateList = () => {
  // const store = useSelector((state) => state.login.refreshToken);
  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();

  const getAllStateList = async () => {
    // if(store){
    const response = await Axios.post("/state_list");
    const data = await response.data;
    dispatch(getStateList(data.body));
    // }
  };
  
  return getAllStateList;  
};

export default useStateList;