import React from "react";
import useAxiosPrivate from "./useAxiosPrivate";
import { useDispatch,useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
const useUpdateUserDetails = (form) => {
  
  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();
  const distributorId=useSelector((store)=>store.distributor.distributorDetails)
  const updateUserData = async () => {
   
    let param = {
      first_name:form?.first_name,
      last_name:form?.last_name,
      address:form?.address,
      status:form?.status==="Inactive"?false:true??form?.categoryRowData?.status,
      user_id: distributorId?.user_id,
      lat:form.lat,
      lat:form.lng,
      mobile_no:form.mobile_no,
    };
    
    const response = await Axios.post("update_user_details", param);
    const data = await response.data;
    
    // console.log(data,"parameter")
    if (data.code === 200) {
      toast.success("Updated SuccessFully");
    } else {
      toast.error("Update Failed");
    }

    // console.log(data);
  };
  // useEffect(() => {
  //   getAllUserList();
  // }, []);
  return updateUserData;
};

export default useUpdateUserDetails;
