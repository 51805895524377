import { useEffect } from "react";
import useAxiosPrivate from "./useAxiosPrivate";
import { useDispatch, useSelector } from "react-redux";
import { getDistributorList } from "../slice/distributorSlice";
import { getSOList } from "../slice/soSlice";
import { getSMList } from "../slice/smSlice";

const useSMList = () => {
  const currentzone = useSelector((state) => state.assignRoute.zone_id);
  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();
  const getAllSMList = async (status) => {
    if (currentzone) {
      const param = {
        zone_id: currentzone,
        status: status
      };
    const response = await Axios.post("/sm_list", param);
    const data = await response.data;
    dispatch(getSMList(data.body));
    }
  };
  // useEffect(() => {
  //   getAllDistributorList();
  // }, []);

  return getAllSMList;
};

export default useSMList;
