import React, { useEffect, useState } from "react";
import useSRGraphSaleDetails from "../../../hooks/useSRGraphSaleDetails";
import useSRCallConversion from "../../../hooks/useSRCallConversion";
import useSRAmountChartDetails from "../../../hooks/useSRAmountChartDetails";
import useSROrderChartDetails from "../../../hooks/useSROrderChartDetails";
import { useSelector } from "react-redux";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from 'dayjs';
const SaleDropDown = () => {
  const data = [
    { id: 1, month_name: "Current Month" },
    { id: 2, month_name: "Previous Month" },
  ];
  const months = [
    { value: "January", label: "January", index: 1 },
    { value: "February", label: "February", index: 2 },
    { value: "March", label: "March", index: 3 },
    { value: "April", label: "April", index: 4 },
    { value: "May", label: "May", index: 5 },
    { value: "June", label: "June", index: 6 },
    { value: "July", label: "July", index: 7 },
    { value: "August", label: "August", index: 8 },
    { value: "September", label: "September", index: 9 },
    { value: "October", label: "October", index: 10 },
    { value: "November", label: "November", index: 11 },
    { value: "December", label: "December", index: 12 },
  ];
  const zoneData = useSelector((state) => state.app.curretDropdownData);
  const currentDate = new Date();
  const currentMonth = new Date().getMonth();
  currentDate.setMonth(currentMonth - 1);
  const previousMonth = currentDate.getMonth();
  const [dropDownData, setDropDownData] = useState(currentMonth + 1);
  const [selectedMonth, setSelectedMonth] = useState(dayjs());

  const getSRGraphDetails = useSRGraphSaleDetails();
  const getSROrderDetails = useSROrderChartDetails();
  const getSRAmountDetails = useSRAmountChartDetails();
  const getSRSRCallConversion = useSRCallConversion();
  useEffect(() => {
    const fetchData = async () => {
      const param = {
        month: selectedMonth.month() + 1,
        year: selectedMonth.year(),
        zone_id: zoneData.zone_id,
      };
      await Promise.all([
        getSRGraphDetails(param),
        getSROrderDetails(param),
        getSRAmountDetails(param),
        getSRSRCallConversion(param),
      ]);
    };

    fetchData();
  }, [zoneData, dropDownData]);

  // useEffect(() => {
  //   const month = months.find((month) => month.index === currentMonth + 1);
  //   const value = month ? month.value : null;
  //   setSelectedMonth(value);
  // }, []);
  const handleMonthChange = (date) => {
    // const { value } = e.target;
    // const selectedMonth = months.find((month) => month.value === value);
    // setDropDownData(selectedMonth.index);
    // setSelectedMonth(selectedMonth.value);
    setSelectedMonth(date);
    const year = date.year();
    const month = date.month() + 1; 
    setDropDownData(month);
  };
  const minDate = dayjs('2024-06-01');

  return (
    // <select
    //   name="month"
    //   style={{
    //     width: "8rem",
    //     height: "40px",
    //     border: "1px solid #BBBABA",
    //     padding: "12px",
    //     cursor: "pointer",
    //     borderRadius: "8px",
    //   }}
    //   value={selectedMonth}
    //   onChange={(event) => handleMonthChange(event)}
    // >
    //   {months &&
    //     months?.map((month) => (
    //       <option key={month.index} value={month.value}>
    //         {month.value}
    //       </option>
    //     ))}
    // </select>
    
    <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          views={["year", "month"]}
          value={selectedMonth}
          slotProps={{ textField: { size: "small" } }}
          onChange={handleMonthChange}
          minDate={minDate}
        />
      </LocalizationProvider>
  );
};

export default SaleDropDown;
