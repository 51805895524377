import useAxiosPrivate from "./useAxiosPrivate";

const useASMpunchingDetails = (selectedData, zoneData) => {
  const Axios = useAxiosPrivate();

  const getASMpunchingDetails = async () => {
    let param = {
      zone_id: zoneData.zone_id,
      to: selectedData?.to,
      from: selectedData?.from,
    };
    const response = await Axios.post("asm_punching_details_download", param);
    const data = await response.data;
    return data.body;
  };
  return getASMpunchingDetails;
};

export default useASMpunchingDetails;
