import { getSrRoutes } from "../slice/adminViewSlice";
import useAxiosPrivate from "./useAxiosPrivate";
import { useDispatch, useSelector } from "react-redux";


const useZoneDetailsList = () => {
  const currentzone = useSelector((state) => state.app.curretDropdownData);
  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();
  const getZoneDetailsList = async () => {

    const response = await Axios.post("/zone_details_list");
    
    const data = await response.data;
    
    // dispatch(getSrRoutes(data.body));
    return data.body;

  };

  return getZoneDetailsList;
};

export default useZoneDetailsList;
