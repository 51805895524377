import React from 'react';
import Styles from './footer.module.css'
import {FaFacebookF,FaInstagram} from 'react-icons/fa';
import {AiFillLinkedin,AiOutlineGooglePlus} from 'react-icons/ai';
import {IoLogoWhatsapp} from 'react-icons/io5';
// import { WhatsappUrl } from '../../utility/BaseURL';
import { linkedIn } from '../../utility/BaseURL';
import {useNavigate } from 'react-router-dom';

const Footer = () => {
    const navigate = useNavigate();

    const handleClick =(to)=> {
        navigate(to)
    }
  return (
    <div className={Styles.container}>
      <div className={Styles.section}>
          <div className={Styles.ContactTitleContainer}>
              <label className={Styles.ContactTitle}>Contact Us</label>
          </div>

          <div className={Styles.ContactAddressContainer}>
              <label className={Styles.ContactAddress}>
              Echno Technologies Pvt Ltd,<br></br>
               Indraprastha, Gubbi cross, 1st Main Rd,<br>

               </br> Hennur Gardens, Bengaluru,
               Karnataka 560077
              </label>
          </div>

          <div className={Styles.NumberContainer}>
              <label className={Styles.NumberTitle}>+91-9611911992</label>
              <label className={Styles.NumberTitle}>contactus@selorce.com</label>
          </div>
      </div>

      <div className={Styles.sectionTwo}>
         <div className={Styles.ContactTitleContainer}>
              <label className={Styles.ContactTitle}>Quick Links</label>
          </div>

          <div className={Styles.ListContainer}>
              <label className={Styles.List} >Services</label>
              {/* <label className={Styles.List}>Terms and Conditions</label>
              <label className={Styles.List}>Privacy Policy</label> */}
              <label className={Styles.List} >Career</label>
              <label className={Styles.List} onClick={()=>handleClick("/contactus")}>Contact Us</label>
              <label className={Styles.List} onClick={()=>handleClick("/privacypolicy")}>Privacy Policy</label>
          </div>
      </div>

      {/* <div className={Styles.section}>
         <div className={Styles.ContactTitleContainer}>
              <label className={Styles.ContactTitle}>Products</label>
          </div>
          <div className={Styles.ListContainer}>
              <label className={Styles.List}>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</label>
              <label className={Styles.List}>Pellentesque et pulvinar enim. Quisque at tempor ligula</label>
              <label className={Styles.List}>Natus error sit voluptatem accusantium doloremque</label>
       
          </div>
      </div> */}

      <div className={Styles.section}>
         <div className={Styles.ContactTitleContainer}>
              <label className={Styles.ContactTitle}>Social</label>
          </div>
          <div className={Styles.socialContainer}>
              <div className={Styles.IconContainer}><FaFacebookF className={Styles.Icon}/></div>
              <div className={Styles.IconContainer}><FaInstagram className={Styles.Icon}/></div>
              <div className={Styles.IconContainer}><a href={linkedIn} target="_blank"><AiFillLinkedin className={Styles.Icon}/></a></div>
              {/* <div className={Styles.IconContainer}><AiOutlineGooglePlus className={Styles.Icon}/></div> */}
              <div className={Styles.IconContainer}><a  target="_blank"><IoLogoWhatsapp className={Styles.Icon}/></a></div>
              {/* href={WhatsappUrl} */}
          </div>
      </div>
    </div>
  )
}

export default Footer