import React, { useState } from "react";
import Styles from "./styles/login.module.css";
import { Stack, Button } from "@mui/material";
import { styled } from "@mui/material/styles";

const RedButton = styled(Button)(({ theme }) => ({
  color: "white",
  backgroundColor: "#F2843F",
  "&:hover": {
    backgroundColor: "#F2843F",
  },
}));

const ResetPassword = ({ setIsResetPassword }) => {
  const [form, setForm] = useState({});
  const [error, setError] = useState({});


  const handleBackToLogin = () => {
    setIsResetPassword(false);
  };

  const isValid = () => {
    let success = true;
    let err = {};

    if (!form?.otp?.trim()) {
      err.otp = "OTP is mandatory to reset password";
      success = false;
    }

    if (!form?.new_password?.trim()) {
      err.new_password = "New Password cannot be empty";
      success = false;
    }
    if (!form?.confirm_password?.trim()) {
      err.confirm_password = "Confirm Password cannot be empty";
      success = false;
    }

    setError(err);
    return success;
  };

  const handleSubmit = () => {
    if (isValid()) {
    }
  };

  const handleChange = (e) => {
    setForm((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "0.5rem",
        alignItems: "center",
        justifyContent: "center",
        padding: "1.6rem",
      }}
    >
      <Stack sx={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}>
        <label className={Styles.NumberTitle}>
          Enter the OTP sent to your email id
        </label>
        <div className={Styles.TextContainer}>
          <input
            placeholder="Enter OTP"
            name="otp"
            type="number"
            className={Styles.TextField}
            style={{ width: "14rem" }}
            onChange={handleChange}
          />
        </div>
        {error.otp && (
          <label className={Styles.errorMessage}>
            {"**" + error.otp + "**"}
          </label>
        )}
      </Stack>

      <Stack sx={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}>
        <Stack sx={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}>
          <label className={Styles.NumberTitle}>Enter new password</label>
          <div className={Styles.TextContainer}>
            <input
              placeholder="New Password"
              name="new_password"
              type="text"
              className={Styles.TextField}
              style={{ width: "14rem" }}
              onChange={handleChange}
            />
          </div>
          {error.new_password && (
          <label className={Styles.errorMessage}>
            {"**" + error.new_password + "**"}
          </label>
        )}
        </Stack>
        <Stack sx={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}>
          <label className={Styles.NumberTitle}>Enter confirm password</label>

          <div className={Styles.TextContainer}>
            <input
              placeholder="Confirm Password"
              name="confirm_password"
              type="text"
              className={Styles.TextField}
              style={{ width: "14rem" }}
              onChange={handleChange}
            />
          </div>
          {error.confirm_password && (
          <label className={Styles.errorMessage}>
            {"**" + error.confirm_password + "**"}
          </label>
        )}
        </Stack>

        <Stack sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
         
          <div style={{marginTop:"0.6rem"}}>
            <RedButton variant="contained" onClick={handleSubmit}>
              Submit
            </RedButton>
          </div>
          <Stack
            sx={{
              color: "#F2843F",
              textDecoration: "underline",
              fontSize: "15px",
              cursor: "pointer",
              justifyContent:"flex-end",
              alignItems:"flex-end",
              display:"flex"
            }}
            variant="body2"
            onClick={handleBackToLogin}
          >
            Back to login
          </Stack>
        </Stack>
      </Stack>

      {/* <div className={Styles.TextContainer}>
          <input
            placeholder="Enter Email Id"
            name="email"
            type="text"
            className={Styles.TextField}
            style={{ width: "14rem" }}
            // onChange={handleChange}
          />
        </div>
        <div className={Styles.TextContainer}>
          <input
            placeholder="Enter Password"
            name="password"
            type="text"
            className={Styles.TextField}
            style={{ width: "14rem" }}
            // onChange={handleChange}
          />
        </div> */}
    </div>
  );
};

export default ResetPassword;
