import { useEffect } from "react";
import useAxiosPrivate from "./useAxiosPrivate";
import { useDispatch } from "react-redux";
import { getSaathiRouteDetails } from "../slice/routeSlice";


const useSaathiRouteDetails = () => {
  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();
  const getRouteDetails = async (param) => {
    
    const response = await Axios.post("get_saathi_route",param);
    const data = await response.data;
    dispatch(getSaathiRouteDetails(data.body));

  };

  return getRouteDetails
};

export default useSaathiRouteDetails;
