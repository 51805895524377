import React from "react";
import useAxiosPrivate from "./useAxiosPrivate";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
const useSuperUpdateCategory = (form) => {

  const currentSelectedImage = useSelector(
    (state) => state.superCategory.superCategoryFormData
  );
  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();
  const updateSuperCategoryDetails = async () => {
    let param = {
      sc_name: form?.sc_name,
      status:form?.status==="Inactive"?false:true??form?.status,
      sc_id: form?.sc_id,
      sc_image: currentSelectedImage?.full_path ?? form?.sc_image,
      ispiece: form?.ispiece,
      isladi: form?.isladi,
      iscarton: form?.iscarton,
    };

    const response = await Axios.post("/update_super_category_list", param);
    const data = await response.data;

    if (data.code === 200) {
      toast.success("Updated SuccessFully");
    } else {
      toast.error("Update Failed");
    }
  };
  return updateSuperCategoryDetails;
};

export default useSuperUpdateCategory;
