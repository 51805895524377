// mySlice.js

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  dist_id: [],
  se_id_list: [],
  asm_id_list: [],
  so_id_list: [],
  pincode: '',
  shape_id: '',
  sc_id:[1],
};
//admin assign SE
const assignSEslice = createSlice({
  name: 'assignSE',
  initialState,
  reducers: {
    setDistId: (state, action) => {
      state.dist_id = action.payload;
    },
    setSeIdList: (state, action) => {
      state.se_id_list = action.payload;
    },
    setAsmIdList: (state, action) => {
      state.asm_id_list = action.payload;
    },
    setSoIdList: (state, action) => {
      state.so_id_list = action.payload;
    },
    setPincode: (state, action) => {
      state.pincode = action.payload;
    },
    setShapeId:(state, action) =>{
      state.shape_id =action.payload;
    },
    setScId:(state, action)=>{
      state.sc_id = action.payload;
    },
    resetSeId: (state) => {
      state.se_id_list = [];
      state.dist_id = [];
      state.asm_id_list = [];
      state.so_id_list = [];
    },
    resetData: (state) => {
      return initialState;
    },
  },
});

export const { setDistId, setSeIdList, setAsmIdList, setSoIdList, setPincode, setShapeId, setScId, resetData, resetSeId } = assignSEslice.actions;
export default assignSEslice.reducer;
export const selectAssignSEData = (state) => state.assignSE;
