import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Button, Stack } from "@mui/material";
import CallStamp from "./CallStamp/CallStamp";
import TimeStampTable from "./TimeStamp/TimeStampTable";
import TimeStamp from "./TimeStamp/TimeStamp";
import SalesStamp from "./SalesStamp/SalesStamp";
import DropDown from "./CallStamp/DropDown";
import TimeDropDown from "./TimeStamp/TimeDropDown";
import SaleDropDown from "./SalesStamp/SaleDropDown";
import TargetAssigned from "./TargetAssigned/TargetAssigned";
import PerformanceIndex from "./PerformanceIndex/PerformanceIndex";
import CoinManagement from "./CoinManagement/CoinManagement";
import DownloadButtons from "./TargetAssigned/DownloadButtons";


function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function StampTab() {
  const [value, setValue] = React.useState(0);
  const [selectedMonth, setSelectedMonth] = React.useState({})

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

 
  return (
    <Box
      sx={{
        width: "100%",
        textAlign: "left",
        backgroundColor: "#fff",
        margin: "8px 8px 8px 0px",
        padding: "4px",
        overflowX: "scroll",
        "&::-webkit-scrollbar": {
          display: "none", // Hide the scrollbar in WebKit browsers (Chrome, Safari, etc.)
        },
      }}
    >
      <Typography variant="h6" marginTop="10px" padding="0px 15px">
        Performance Management
      </Typography>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          justifyContent: "space-between",
          display: "flex",
          width: "98%",
          ".MuiTabs-indicator": {
            backgroundColor: "#EF6C1A",
          },
          ".Mui-selected":{
            color:"#EF6C1A !important"
          }
        }}
      >
        <Stack width="100%">
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            sx={{".MuiTabs-flexContainer":{
              gap:"14px"
            }}}
          >
            <Tab
              sx={{ color: "#C2C2C2 !important", fontWeight: "600", textTransform:"none", borderBottom:"3px solid #C2C2C2" }}
              label="Call Stamp"
              {...a11yProps(0)}
            />
            <Tab
              sx={{ color: "#C2C2C2 !important", fontWeight: "600", textTransform:"none", borderBottom:"3px solid #C2C2C2"  }}
              label="Time Stamp"
              {...a11yProps(1)}
            />
            <Tab
              sx={{ color: "#C2C2C2 !important", fontWeight: "600", textTransform:"none", borderBottom:"3px solid #C2C2C2"  }}
              label="Sales Stamp"
              {...a11yProps(2)}
            />
             {/* <Tab
              sx={{ color: "#C2C2C2 !important", fontWeight: "600", textTransform:"none", borderBottom:"3px solid #C2C2C2"  }}
              label="Performance Index"
              {...a11yProps(3)}
            /> */}
            <Tab
              sx={{ color: "#C2C2C2 !important", fontWeight: "600", textTransform:"none", borderBottom:"3px solid #C2C2C2"  }}
              label="Target Assignement"
              {...a11yProps(3)}
            />
            <Tab
              sx={{ color: "#C2C2C2 !important", fontWeight: "600", textTransform:"none", borderBottom:"3px solid #C2C2C2"  }}
              label="Coin Management"
              {...a11yProps(4)}
            />
          </Tabs>
        </Stack>

        {value === 0 ? (
          <Stack>
            {/* <AddSuperCategory /> */}
           <DropDown/>
          </Stack>
        ) : value === 1 ? (
          <Stack>
            {/* <AddCategory /> */}
            <TimeDropDown/>
          </Stack>
        ) : value === 2 ? (
          <Stack>
            {/* <AddSku /> */}
            <SaleDropDown/>
          </Stack>
        ):value===3 ?
        <DownloadButtons selectedMonth={selectedMonth}/>
        :(
          null
        )
        }
      </Box>
      <CustomTabPanel value={value} index={0}>
        <CallStamp/>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <TimeStamp/>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <SalesStamp/>
      </CustomTabPanel>
      {/* <CustomTabPanel value={value} index={3}>
      <PerformanceIndex/>
      </CustomTabPanel> */}
      <CustomTabPanel value={value} index={3}>
      <TargetAssigned selectedMonth={selectedMonth} setSelectedMonth={setSelectedMonth}/>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={4}>
      <CoinManagement/>
      </CustomTabPanel>
      
    </Box>
  );
}
