import React from "react";
import { Stack } from "@mui/material";
import { TailSpin } from "react-loader-spinner";
import { Typography } from "@material-ui/core";

const Loader = ({loaderText}) => {
  return (
    <Stack
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.5)', 
        backdropFilter: 'blur(5px)', 
        zIndex: 9999, 
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <TailSpin
        visible={true}
        height="80"
        width="80"
        color="#EF6C1A"
        ariaLabel="tail-spin-loading"
        radius="1"
      />
      <Typography style={{color:"#FFF", fontSize:"18px", fontWeight:"700", marginTop:"14px"}}>{loaderText}</Typography>
    </Stack>
  );
};

export default Loader;