import { useEffect } from "react";
import useAxiosPrivate from "./useAxiosPrivate";
import { useDispatch, useSelector } from "react-redux";

import {
  getSRCallConversion,

} from "../slice/srSlice";

const useSRCallConversion = () => {

  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();
  const getAllSRCallConversion = async (param) => {

      const response = await Axios.post("/sr_call_conversions_details", param);
      const data = await response.data;
      console.log(data, "SRCallConversion")
      dispatch(getSRCallConversion(data.body));
    
  };


  return getAllSRCallConversion;
};

export default useSRCallConversion;
