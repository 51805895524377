import React, { useState } from "react";
import styles from "./styles/index.module.css";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./styles/custom.css";
import MidContainerUserMgt from "./UserMgtMidContainer";
import UserMgtBottomContainer from "./UserMgtBottomContainer";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import UserMgtMidContainerTwo from "./UserMgtMidContainerTwo";
import UserMgtBottomContainerTwo from "./UserMgtBottomContainerTwo";
// import { GetToken, GetZoneList } from "../../../actions/login/login";

import { USERMGTERRORNULL } from "../constants/UserMgt/UserMgt";

import UserMgtWholesaler from "./wholesaler/UserMgtWholesaler";
import UserMgtSO from "./SalesOfficer/UserMgtSO";
import UserMgtSR from "./SalesRepresentatives/UserMgtSR";
import UserMgtSM from "./SalesManagers/UserMgtSM";
import SuperStockist from "./SuperStockist/SuperStockist";
import { getDistributorDetails } from "../../slice/distributorSlice";
import useSuperCategoryList from "../../hooks/useSuperCategoryList";
const UserManagement = ({ zone, Statevalue }) => {
  const userData = useSelector(
    (state) => state?.login?.refreshToken?.user_data
  );
  const dispatch = useDispatch();
  const Firsttoken = useSelector((state) => state.Token);
  const Item = [
    "Retailers",
    "SR",
    "SO",
    "ASM",
    "Super Stockist",
    "Distributors",
  ];
  const filteredItem = 
  userData?.role === "asm"
    ? Item.filter(item => item !== "ASM")
    : userData?.role === "so"
    ? Item.filter(item => item !== "ASM" && item !== "SO")
    : Item;

  const [role, setRole] = useState("Retailers");
  const [token, setToken] = useState("");
  const [routeId, setRouteId] = useState("");
  const [catId, setCatId] = useState("");
  const [productId, setProductId] = useState("");
  const getSuperCategory = useSuperCategoryList();
  React.useEffect(() => {
    dispatch({ type: USERMGTERRORNULL });
    getSuperCategory();
  }, []);

  
  

  React.useEffect(() => {
    if (
      Firsttoken &&
      Firsttoken.token &&
      Firsttoken.token !== null &&
      Firsttoken.token.length !== 0 &&
      Firsttoken !== "undefined"
    ) {
      setToken(Firsttoken.token);
    }
  }, [Firsttoken]);

  const handleChangeCarousel = (item) =>{
    dispatch(getDistributorDetails(null));
    setRole(item);
  }

  return (
    <div className={styles.UserManagementContainer}>
      <Carousel responsive={responsive} arrows={false} swipeable={true}>
        {filteredItem.map((item, index) => (
          <div
            className={styles.UserManagementCarousel}
            onClick={() => handleChangeCarousel(item)}
          >
            <div
              key={index}
              className={
                role === `${item}`
                  ? styles.UserManagementCarouselLabelWrapperOne
                  : styles.UserManagementCarouselLabelWrapperTwo
              }
            >
              <label
                className={
                  role === `${item}`
                    ? styles.UserManagementCarouselLabelOne
                    : styles.UserManagementCarouselLabelTwo
                }
              >
                {item}
              </label>
            </div>
          </div>
        ))}
      </Carousel>

      {/* <SaathiLogoLoader/> */}

      <>
        {/* {user.PageError ? (
          <SaathiError />
        ) : ( */}
        <>
          {/* {user.PageLoading ? (
              <SaathiLogoLoader />
            ) : ( */}
          <>
            {role === "Retailers" ? (
              <>
                <UserMgtMidContainerTwo
                  tsiZone={zone}
                  setRouteId={setRouteId}
                  setCatId={setCatId}
                  setProductId={setProductId}
                  Statevalue={Statevalue}
                />
                <UserMgtBottomContainer tsiZone={zone} />
              </>
            ) : (
              <>
                {role === "Distributors" ? (
                  <UserMgtWholesaler zone={zone} />
                ) : role === "SO" ? (
                  <UserMgtSO />
                ) : role === "SR" ? (
                  <UserMgtSR />
                ) : role === "Super Stockist" ? (
                  <SuperStockist />
                ) :  (
                  <>
                    <UserMgtSM />
                  </>
                )}
              </>
            )}
          </>
          {/* )} */}
        </>
        {/* )} */}
      </>
    </div>
  );
};

export default UserManagement;

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 5,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};
