import { useEffect } from "react";
import useAxiosPrivate from "./useAxiosPrivate";
import { useDispatch, useSelector } from "react-redux";
import { getDistributorList } from "../slice/distributorSlice";
import { getSOList } from "../slice/soSlice";
import {
  getSRGraphDetails,
  getSRGraphTimeDetails,
  getSRList,
} from "../slice/srSlice";

const useSRGraphTimeDetails = () => {
  const currentzone = useSelector((state) => state.assignRoute.zone_id);
  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();
  const getAllSRGraphTimeDetails = async (param) => {

      const response = await Axios.post("/sr_graph_time_details", param);
      const data = await response.data;
      dispatch(getSRGraphTimeDetails(data));
    
  };
  // useEffect(() => {
  //   getAllSRGraphTimeDetails();
  // }, [month]);

  return getAllSRGraphTimeDetails;
};

export default useSRGraphTimeDetails;
