import React from "react";
// import useAxiosPrivate from "./useAxiosPrivate";
import useImageAxiosPrivate from "./useImageAxiosPrivate";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
const useRouteDumpUpload = () => {
    const ImageAxiosPrivate = useImageAxiosPrivate();
  const getRouteDumpUpload = async (param) => {

    const response = await ImageAxiosPrivate.post("/route_dump_upload", param);
    const data = await response.data;
    console.log("route_dump_upload", data);
    if (data.code === 200) {
      toast.success(data?.message);
    } else if (data.code === 400) {
        Swal.fire({
            title: "Error",
            text: data.message || "An unexpected error occurred.", // Fallback message
            icon: "error",
            showConfirmButton: true,
          });
    } else {
        Swal.fire({
            title: "Error",
            text: data.message || "An unexpected error occurred.", // Fallback message
            icon: "error",
            showConfirmButton: true,
          });
    }
  };
  return getRouteDumpUpload;
};

export default useRouteDumpUpload;
