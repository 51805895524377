import React from "react";
import useAxiosPrivate from "./useAxiosPrivate";
import { useDispatch,useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
const useUpdateDistributor = (form) => {
  
  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();
  const distributorId=useSelector((store)=>store.distributor.distributorDetails)
  const updateDistributorDetails = async () => {
   
    let param = {
      dist_name:form?.dist_name,
      address: form?.address,
      status:form?.status==="Inactive"?false:true??form?.categoryRowData?.status,
      ss_id:form?.ss_id,
      shop_name:form?.shop_name,
      mobile_no: form?.mobile_no,
      lat: form?.lat,
      lng:form?.lng,
      dist_id: distributorId?.dist_id,
      email:form?.email
    };
    
    const response = await Axios.post("/update_distributor", param);
    const data = await response.data;
    
    console.log(data,"parameter")
    if (data.code === 200) {
      toast.success("Updated SuccessFully");
    }else if(data.code===409){
      toast.error(data.message)
    } else {
      toast.error("Update Failed");
    }

    console.log(data);
  };
  // useEffect(() => {
  //   getAllUserList();
  // }, []);
  return updateDistributorDetails;
};

export default useUpdateDistributor;
