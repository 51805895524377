import React from "react";
import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  TextField,
  MenuItem,
  InputLabel,
} from "@material-ui/core";
import { useEffect, useState } from "react";
import useUsersTargetData from "../../../hooks/useUsersTargetData";
import { useSelector } from "react-redux";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import useEditTarget from "../../../hooks/useEditTarget";
import { Typography, Stack, Box } from "@mui/material";
import image from "./Vector.png";
import useHolidaysList from "../../../hooks/useHolidaysList";
const DataTable = ({selectedMonth, setSelectedMonth}) => {
  const [editModeRowId, setEditModeRowId] = useState(null);
  const [editedValues, setEditedValues] = useState({});
  const [formData, setFormData] = useState({
    month: "January",
    month_no: 1,
  });
  const getTableData = useUsersTargetData(selectedMonth);
  const currentzone = useSelector((state) => state.app.curretDropdownData);
  const holidays_count = useSelector((state) => state.performance.holidayCount);
  const months = [
    { value: "January", label: "January", index: 1 },
    { value: "February", label: "February", index: 2 },
    { value: "March", label: "March", index: 3 },
    { value: "April", label: "April", index: 4 },
    { value: "May", label: "May", index: 5 },
    { value: "June", label: "June", index: 6 },
    { value: "July", label: "July", index: 7 },
    { value: "August", label: "August", index: 8 },
    { value: "September", label: "September", index: 9 },
    { value: "October", label: "October", index: 10 },
    { value: "November", label: "November", index: 11 },
    { value: "December", label: "December", index: 12 },
  ];

  const headerName = [
    { index: 0, name: "SR" },
    { index: 1, name: "SR Name" },
    { index: 2, name: "Call Stamp " },
    { index: 3, name: "Sales Target" },
    { index: 4, name: "Time Stamp" },
    // { index: 5, name: "Outlets Coverage" },
    // { index: 6, name: "Lines Per Call" },
    { index: 5, name: "Petrol / km" },
    { index: 6, name: "Call Incentive / call" },
    { index: 7, name: "Time Incentive / hour" },
    { index: 8, name: "Sales Incentive / 1000" },
    { index: 9, name: "Fixed Salary" },
    { index: 10, name: "Variable Salary" },
    { index: 11, name: "Actions" },
  ];

  const getHolidayCount = useHolidaysList();
  useEffect(() => {
    const param2 = {
      state_id : currentzone.state_id
    }
    getHolidayCount(param2);
  }, [currentzone]);
   



  const [workingDaysInMonth, setWorkingDaysInMonth] = useState(0);
  const [workingDaysPassed, setWorkingDaysPassed] = useState(0);
  useEffect(() => {
    // console.log(workingDaysInMonth,workingDaysPassed , "workingDaysInMonth");
    const anser = (workingDaysInMonth - parseFloat(holidays_count?.holiday_count)) ;
    // console.log( anser, parseFloat(holidays_count?.holiday_count) , "workingDaysInMonth");
  }, [workingDaysInMonth]);
  useEffect(() => {
    const getWorkingDaysInMonth = (year, month) => {
      let totalWorkingDays = 0;
      const daysInMonth = new Date(year, month + 1, 0).getDate();
      for (let day = 1; day <= daysInMonth; day++) {
        const date = new Date(year, month, day);
        const dayOfWeek = date.getDay();
        if (dayOfWeek !== 0) { // Exclude Sundays (0 represents Sunday)
          totalWorkingDays++;
        }
      }
      return totalWorkingDays;
    };

    const getWorkingDaysPassedInMonth = () => {
      const today = new Date();
      let workingDays = 0;
      for (let day = 1; day <= today.getDate(); day++) {
        const date = new Date(today.getFullYear(), today.getMonth(), day);
        const dayOfWeek = date.getDay();
        if (dayOfWeek !== 0) { // Exclude Sundays (0 represents Sunday)
          workingDays++;
        }
      }
      return workingDays;
    };

    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth();
    // console.log(month,year, "month");
    setWorkingDaysInMonth(getWorkingDaysInMonth(year, selectedMonth?.month_no));
    setWorkingDaysPassed(getWorkingDaysPassedInMonth());
  }, [selectedMonth]);

  const tableData = useSelector((state) => state.performance.targetTableData);

    useEffect(() => {
      setFormData({
        month: "January",
        month_no: 1,
      });
      // setSelectedMonth({
      //   month: "January",
      //   month_no: 1,
      // });
    }, []);

  // console.log(formData,"888888")

  const handleChange = (e) => {
    const { name, value } = e.target;
    const selectedMonth = months.find((month) => month.value === value);
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
      month_no: selectedMonth?.index,
    }));
    setSelectedMonth((prevData) => ({
      ...prevData,
      [name]: value,
      month_no: selectedMonth?.index,
    }));
  };

  const handleEdit = (row) => {
    console.log(row,"handleEdit")
    setEditModeRowId(row.user_id);
    setEditedValues({
      user_id:row.user_id,
      utt_id: row.utt_id,
      month_no: row.month,
      new_month:formData.month_no,
      sr_name:row.first_name,
      sales_target: row.sales_target,
      time_stamp: row.daily_time_stamp,
      call_stamp:row.call_stamp,
      outlets_coverage: row.outlets_coverage,
      lines_per_call: row.lines_per_call,
      petrol:row.km_coins,
      call_incentive: row.call_incentive_coins,
      time_incentive:row.time_incentive_coins,
      sale_incentive:row.sales_incentive_coins,
      fixed_salary:row.fixed_salary_coins,
      variable_salary:row.variable_salary,
      year:row?.year,
    });
  };
  const editUserTarget = useEditTarget();
  const handleSave = async () => {
    await editUserTarget(editedValues);
    await getTableData();
    setEditModeRowId(null);
    setEditedValues({});
  };

  const handleCancel = () => {
    // Cancel edit mode
    setEditModeRowId(null);
    setEditedValues({});
  };

  const handleInputChange = (e, field) => {
    const { value } = e.target;
    setEditedValues((prevValues) => ({
      ...prevValues,
      [field]: value,
    }));
  };

  const [searchTerm, setSearchTerm] = useState('');
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "0.8rem" }}>
      {/* <Stack
        style={{
          justifyContent: "space-between",
          flexDirection: "row",
          margin: "10px 4px",
          flexWrap: "wrap",
        }}
      >
        <Typography style={{ fontWeight: "700", color: "#4E4E4E" }}>
          Set Targets
        </Typography>
        <Stack
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: "1rem",
            flexWrap: "wrap",
          }}
        >
          <TextField
            select
            label="Month"
            name="month"
            size="small"
            value={formData.month}
            onChange={handleChange}
            variant="outlined"
            style={{ minWidth: "140px", marginRight: "10px" }}
          >
            {months.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          <Button size="large" style={{ padding: "1rem", fontWeight: 700 }}>
            <Stack
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "0.2rem",
              }}
            >
              <DriveFolderUploadOutlinedIcon />
              Upload Excel
            </Stack>
          </Button>
        </Stack>
      </Stack> */}
      <input
        type="text"
        placeholder="Search"
        style={{
          marginTop:"1rem",
          width:"15rem",
          height: "2rem",
          fontSize: "14px",
          fontFamily: "inherit",
          borderRadius: "8px",
          padding: "4px 8px", 
          border: "1px solid #c4c4c4", 
          outline: "none",
        }}
        onChange={handleSearchChange} 
        value={searchTerm} 
      />
      <TableContainer style={{ maxHeight: 700, minWidth: "100%" }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead style={{ backgroundColor: "#F8F8F8" }}>
            <TableRow>
              {headerName?.map((header) => (
                <TableCell key={header?.index} style={{ textAlign: "center" }}>
                  {header?.name}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData
              ?.filter((sr) =>
                sr.first_name?.toLowerCase()?.includes(searchTerm?.toLowerCase())
              )
              ?.map((row, index) => (
                <TableRow key={row.user_id}>
                  <TableCell style={{ textAlign: "center" }}>
                    {index + 1}
                  </TableCell>
                  <TableCell
                    title={row.first_name}
                    style={{ textAlign: "center" }}
                  >
                    {row.first_name.length > 8
                      ? `${row.first_name.slice(0, 8)}...`
                      : row.first_name}
                  </TableCell>

                  <TableCell style={{ textAlign: "center" }}>
                    {/* {editModeRowId === row.user_id ? (
                    <TextField
                      size="small"
                      value={editedValues.call_stamp}
                      onChange={(e) => handleInputChange(e, "call_stamp")}
                    />
                  ) : (
                    row.call_stamp
                  )} */}
                    {row.outlets_coverage}
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    {editModeRowId === row.user_id ? (
                      <TextField
                        size="small"
                        value={editedValues.sales_target}
                        onChange={(e) => handleInputChange(e, "sales_target")}
                      />
                    ) : (
                      row.sales_target
                    )}
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    {editModeRowId === row.user_id ? (
                      <TextField
                        size="small"
                        value={editedValues.time_stamp}
                        onChange={(e) => handleInputChange(e, "time_stamp")}
                      />
                    ) : (
                      row.daily_time_stamp
                    )}
                  </TableCell>
                  {/* <TableCell style={{ textAlign: "center" }}>
                  {editModeRowId === row.user_id ? (
                    <TextField
                      size="small"
                      value={editedValues.outlets_coverage}
                      onChange={(e) => handleInputChange(e, "outlets_coverage")}
                    />
                  ) : (
                    row.outlets_coverage
                  )}
                </TableCell>
                <TableCell style={{ textAlign: "center" }}>
                  {editModeRowId === row.user_id ? (
                    <TextField
                      size="small"
                      value={editedValues.lines_per_call}
                      onChange={(e) => handleInputChange(e, "lines_per_call")}
                    />
                  ) : (
                    row.lines_per_call
                  )}
                </TableCell> */}
                  <TableCell style={{ textAlign: "center" }}>
                    {editModeRowId === row.user_id ? (
                      <TextField
                        size="small"
                        value={editedValues.petrol}
                        onChange={(e) => handleInputChange(e, "petrol")}
                      />
                    ) : (
                      row.km_coins
                    )}
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    {editModeRowId === row.user_id ? (
                      <TextField
                        size="small"
                        value={editedValues.call_incentive}
                        onChange={(e) => handleInputChange(e, "call_incentive")}
                      />
                    ) : (
                      row.call_incentive_coins
                    )}
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    {editModeRowId === row.user_id ? (
                      <TextField
                        size="small"
                        value={editedValues.time_incentive}
                        onChange={(e) => handleInputChange(e, "time_incentive")}
                      />
                    ) : (
                      row.time_incentive_coins
                    )}
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    {editModeRowId === row.user_id ? (
                      <TextField
                        size="small"
                        value={editedValues.sale_incentive}
                        onChange={(e) => handleInputChange(e, "sale_incentive")}
                      />
                    ) : (
                      row.sales_incentive_coins
                    )}
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    {editModeRowId === row.user_id ? (
                      <TextField
                        size="small"
                        value={editedValues.fixed_salary}
                        onChange={(e) => handleInputChange(e, "fixed_salary")}
                      />
                    ) : (
                      row.fixed_salary_coins
                    )}
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    {editModeRowId === row.user_id ? (
                      <TextField
                        size="small"
                        value={editedValues.variable_salary}
                        onChange={(e) =>
                          handleInputChange(e, "variable_salary")
                        }
                      />
                    ) : (
                      row.variable_salary
                    )}
                  </TableCell>
                  <TableCell
                    style={{
                      textAlign: "center",
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                      justifyContent: "center",
                    }}
                  >
                    {editModeRowId === row.user_id ? (
                      <Stack
                        sx={{
                          gap: "5px",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <SaveOutlinedIcon
                          style={{
                            backgroundColor: "#EF6C1A",
                            color: "#fff",
                            cursor: "pointer",
                            padding: "4px",
                            borderRadius: "4px",
                          }}
                          onClick={() => handleSave(row)}
                        />
                        <CloseOutlinedIcon
                          style={{ color: "#EF6C1A", cursor: "pointer" }}
                          onClick={handleCancel}
                        />
                      </Stack>
                    ) : (
                      <img
                        src={image}
                        style={{
                          color: "#EF6C1A",
                          cursor: "pointer",
                          width: "1.25rem",
                          cursor: row?.utt_id ? "pointer" : "not-allowed",
                          opacity: row?.utt_id ? 1 : 0.5,
                        }}
                        onClick={row?.utt_id ? () => handleEdit(row) : null}
                      />
                    )}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default DataTable;
