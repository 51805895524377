import React from "react";
import CampaignSchemeTable from "./CampaignSchemeTable";
import { Stack, Typography } from "@mui/material";
import AddCampaignScheme from "./AddCampaignScheme";
import Sidebar from "../Sidebar";
import { useSelector } from "react-redux";
import DriveFileRenameOutlineRoundedIcon from '@mui/icons-material/DriveFileRenameOutlineRounded';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from "react-router";
const AddCampaign = () => {
  const navigate = useNavigate();
  const campaignDetails = useSelector((state) => state.scheme.campaignDetails)
  return (
    <Stack
      sx={{ display: "flex", flexDirection: "row", backgroundColor: "#E5E5E5", width: "-webkit-fill-available" }}
    >
      <Sidebar />
      <Stack
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          width:"100vw",
          padding: "1rem",
        }}
      >
        <Stack sx={{flexDirection:"row", alignItems:"center", gap:"8px"}}>
        <ArrowBackIcon onClick={()=>navigate("/home/Schememgt")} sx={{ cursor:"pointer"}}/>
        <Typography sx={{fontSize:"20px", textAlign:"left"}}>{campaignDetails?.campaign_name}</Typography><DriveFileRenameOutlineRoundedIcon/>
        </Stack>
        <CampaignSchemeTable />
        <AddCampaignScheme />
      </Stack>
    </Stack>
  );
};

export default AddCampaign;
