import React, { useState, useEffect } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { useSelector } from "react-redux";
// import SRGraphTimeDetails from "./data.json";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
      position: "bottom",
      labels: {
        boxWidth: 10,
        padding: 10,
        font: {
          size: 12,
        },
      },
    },
    title: {
      display: true,
      text: "Time Chart",
    }
  },
  scales: {
    x: {
      stacked: true,
      ticks: {
        autoSkip: false, 
      },
    },
    y: {
      stacked: false,
    },
  },
};


function getRandomColors(count) {
  const colors = [];
  for (let i = 0; i < count; i++) {
    const r = Math.floor(Math.random() * 64);
    const g = Math.floor(Math.random() * 64);
    const b = Math.floor(Math.random() * 64);
    colors.push(`rgba(${r}, ${g}, ${b}, 0.8)`);
  }
  return colors;
}

export default function BarGraph() {
  const SRGraphTimeDetails = useSelector(
    (state) => state.sr.SRGraphTimeDetails
  );

  const [randomColors,setRandomColors]=useState([])

  useEffect(()=>{
    const randomColors = getRandomColors(SRGraphTimeDetails?.length);
    setRandomColors(randomColors)
  },[SRGraphTimeDetails]);


  // Utility function to convert hh:mm to total minutes
  const convertToMinutes = (timeStr) => {
    if (!timeStr) return 0; // Handle null, undefined, or empty string
    const [hours, minutes] = timeStr.split(":").map(Number);
    return hours * 60 + minutes;
  };

  useEffect(() => {
    if (SRGraphTimeDetails?.body?.length) {
      const randomColors = getRandomColors(SRGraphTimeDetails?.body?.length);
      setRandomColors(randomColors);
    }
  }, [SRGraphTimeDetails]);
  const labels = SRGraphTimeDetails?.srList || [];
  const dataPoints = SRGraphTimeDetails?.body?.map((item) => convertToMinutes(item.hour_difference)) || [];

  const data = {
    labels,
    datasets: [
      {
        label: "Time(min)",
        data: dataPoints,
        backgroundColor: randomColors,
      },
    ],
  };

  return (
    <div style={{ height: "500px", width: "100%" }}>
      <Bar options={options} data={data} />
    </div>
  );
};
