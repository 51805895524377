import { useEffect } from "react";
import useAxiosPrivate from "./useAxiosPrivate";
import { useDispatch } from "react-redux";
import { getUserList } from "../slice/userSlice";
import { getSchemeZoneList, getZoneList } from "../slice/appSlice";
import { getSchemeList } from "../slice/schemeSlice";

const useSchemeDetailsList = () => {
  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();
  const getSchemeZoneList = async (param) => {

    const response = await Axios.post("scheme_table_list", param);
    const data = await response.data;
    console.log(data.result,"useSchemeDetailsList")

    dispatch(getSchemeList(data.result));
  };
  return getSchemeZoneList;
};

export default useSchemeDetailsList;
