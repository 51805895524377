import React from "react";
import DashboardHeader from "./AdminHeader";
import DashboardContent from "./DashboardContent";

// import DashboardDropdown from "./DashboardDropdown";
const Dashboard = () => {
  return (
    <div style={{position: "relative", backgroundColor:"#E5E5E5", fontFamily:"'Roboto', sans-serif" }}>
      <DashboardContent />
    </div>
  );
};

export default Dashboard;
