import useAxiosPrivate from "../useAxiosPrivate";
import { ToastContainer, toast } from "react-toastify";

const useSRPerformanceMetrix = (selectedData, zoneData) => {
  const Axios = useAxiosPrivate();

  const getSRPerformanceMetrix = async () => {
    let param = {
      zone_id: zoneData.zone_id,
      to: selectedData?.to,
      from: selectedData?.from,
    };
    const response = await Axios.post("sr_performance_metrix_download", param);
    const data = await response.data;
    if (data?.code === 200) {
        toast.success('Downloaded Successfully');
      } else {
        toast.error(data?.message);
      }
    return data.body;
  };
  return getSRPerformanceMetrix;
};

export default useSRPerformanceMetrix;
