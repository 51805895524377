import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { useSelector } from "react-redux";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: "bottom",
      labels: {
        boxWidth: 10,
        padding: 10,
        font: {
          size: 12,
        },
      },
    },
    title: {
      display: true,
      text: "Sales Chart",
    },
  },
};

function getDaysInMonth(month, year) {
  return new Date(year, month, 0).getDate();
}

function getCurrentMonthWithDays() {
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth() + 1;
  const currentYear = currentDate.getFullYear();
  const daysInMonth = getDaysInMonth(currentMonth, currentYear);

  return {
    month: currentMonth,
    labels: Array.from({ length: daysInMonth }, (_, i) => i + 1),
  };
}

function getRandomColors(count) {
  const colors = [];
  for (let i = 0; i < count; i++) {
    const r = Math.floor(Math.random() * 64);
    const g = Math.floor(Math.random() * 64);
    const b = Math.floor(Math.random() * 64);
    colors.push(`rgba(${r}, ${g}, ${b}, 0.8)`);
  }
  return colors;
}

export default function BarGraph() {
  const SRGraphSaleDetails = useSelector(
    (state) => state.sr.SRGraphSaleDetails
  );
  const [labels, setLabels] = useState([]);
  const [randomColors, setRandomColors] = useState([]);

  useEffect(() => {
    const { labels } = getCurrentMonthWithDays();
    setLabels(labels);
  }, []);

  useEffect(() => {
    const randomColors = getRandomColors(SRGraphSaleDetails?.length);
    setRandomColors(randomColors);
  }, [SRGraphSaleDetails]);

  const transformedSeries = SRGraphSaleDetails
    ? SRGraphSaleDetails?.map((item, index) => ({
        label: `${item?.first_name}`,
        data: labels?.map((label) =>
          label === item?.sales_by_day?.[0]?.sale_day
            ? item?.sales_by_day?.[0]?.total_cost
            : 0
        ),
        backgroundColor: randomColors[index],
      }))
    : [];

  const data = {
    labels,
    datasets: transformedSeries,
  };

  return (
    <div style={{ height: "500px", width: "100%" }}>
      <Bar options={options} data={data} />
    </div>
  );
}
