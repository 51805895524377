import { useEffect } from "react";
import useAxiosPrivate from "./useAxiosPrivate";
import { useDispatch } from "react-redux";
import { getCategoryList } from "../slice/categorySlice";

const useCategoryList = () => {
  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();
  const getAllCategoryList = async () => {
    const response = await Axios.post("/category_list");
    const data = await response.data;
    dispatch(getCategoryList(data.body));
    // console.log(data);
  };
  useEffect(() => {
    getAllCategoryList();
  }, []);

  return getAllCategoryList;
};

export default useCategoryList;
