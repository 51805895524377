import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Typography, Button } from '@mui/material';
import useBeatList from '../../hooks/Beat/useBeatList';
import { useSelector } from 'react-redux';


const ValidationPopup = ({ open, handleClose, uploadRouteExcel, formData, setIsDownloading,validateData }) => {

  const zoneData = useSelector((state) => state.app.curretDropdownData);
  const getAllBeatList=useBeatList()

  const handleUpload=async()=>{
    setIsDownloading(true)
    await uploadRouteExcel(formData)
    setIsDownloading(false)
    handleClose()
    const param2 = {
      zone_id: zoneData?.zone_id,
    };
    await getAllBeatList(param2);
  }
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle><b>Excel Upload Validation Guidelines</b></DialogTitle>
      <DialogContent>
      {
  !validateData?.new_beat_dump && !validateData?.new_dist_dump ? (
    <Typography variant="body1" gutterBottom>
      Validate Successfully, Click Upload button to upload the dump.
    </Typography>
  ) : (
    <>
      {validateData?.new_beat_dump && (
        <>
          <Typography variant="body1" gutterBottom>
            Below Beat Name will be created new:
          </Typography>
         
          <Typography variant="body1" gutterBottom>
            {validateData?.beat_list}
          </Typography>
        </>
      )}

      {validateData?.new_dist_dump && (
        <>
          <Typography variant="body1" gutterBottom>
            Below Distributor Firm Name will be created new:
          </Typography>
          
          <Typography variant="body1" gutterBottom>
            {validateData?.dist_list}
          </Typography>
        </>
      )}
    </>
  )
}


      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
        <Button onClick={handleUpload} color="primary">
          Upload
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ValidationPopup;
