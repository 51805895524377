import useAxiosPrivate from "./useAxiosPrivate";
import { useDispatch, useSelector } from "react-redux";
import { getSkuDetailsExcelDownload } from "../slice/dashboardSlice";

const useSkuDetailsExcelDownload = (selectedData) => {
  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();
  const zoneData = useSelector((state) => state.app.curretDropdownData);
  const getAllSkuDetailsExcelDownload = async () => {
    let param = {
      // month: current_selected_month.month_no,
      zone_id: zoneData.zone_id,
      to: selectedData?.to,
      from: selectedData?.from,
    };
    const response = await Axios.post(
      "/sku-total-details-excel-download",
      param
    );
    const data = await response.data;
    dispatch(getSkuDetailsExcelDownload(data.body));
    // console.log(data);
    return data.body;
  };

  return getAllSkuDetailsExcelDownload;
};

export default useSkuDetailsExcelDownload;
