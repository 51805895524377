
import useAxiosPrivate from "./useAxiosPrivate";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";


const useSrRoutesDownload = () => {
  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();

  const getSrRoutesDownload = async (param) => {

    const response = await Axios.post("/sr_route_retailer_download", param);
    const data = await response.data;
    // console.log(data);
    if (data.code === 200) {
        toast.success(data?.message);
      } else if (data.code === 400) {
        toast.error(data?.message);
      } else {
        toast.error(data?.message);
      }
    // dispatch(getRetailerDownloadList(data.body));
    
    return data.data;
  };

  return getSrRoutesDownload;
};

export default useSrRoutesDownload;
