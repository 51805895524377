import { useEffect } from "react";
import useAxiosPrivate from "./useAxiosPrivate";
import { useDispatch, useSelector } from "react-redux";
import { getSuperStockistList } from "../slice/distributorSlice";

const useSuperStockistList = () => {
  const currentzone = useSelector((state) => state.assignRoute.zone_id);
  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();
  const getAllSuperStockistList = async (status) => {
    if (currentzone) {
      const param = {
        zone_id: currentzone,
        status: status
      };
    const response = await Axios.post("/super_stockist_list", param);
    const data = await response.data;
    dispatch(getSuperStockistList(data.body));
    }
  };

  return getAllSuperStockistList;
};

export default useSuperStockistList;
