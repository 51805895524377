
import useAxiosPrivate from "../useAxiosPrivate";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";


const useSrTargetDownload = () => {
  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();

  const getSrTargetDownload = async (param) => {

    const response = await Axios.post("/user_target_data_download", param);
    const data = await response.data;
    // console.log(data);
    if (data.code === 200) {
        toast.success(data?.message);
      } else if (data.code === 400) {
        toast.error(data?.message);
      } else {
        toast.error(data?.message);
      }
    // dispatch(getRetailerDownloadList(data.body));
    
    return data.body;
  };

  return getSrTargetDownload;
};

export default useSrTargetDownload;
