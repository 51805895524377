import React from 'react';
import RouteMgtBottomContainer from './RouteMgtBottomContainer'
import RouteMgtTopContainer from './TopContainer';

const Route = ({ token, setRootZone, rootzone }) => {
    return (
        <div>
            <RouteMgtTopContainer rootzone={rootzone} token={token} setRootZone={setRootZone} />
            <RouteMgtBottomContainer rootzone={rootzone} />
        </div>
    )
}

export default Route
