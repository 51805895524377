
import useAxiosPrivate from "./useAxiosPrivate";
import { useDispatch } from "react-redux";
import { getRouteClusterList } from "../slice/adminMapSlice";

const useCreateRoute = () => {
  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();

  const setCreateRoute = async (param) => {
    try {
      const response = await Axios.post("create_route", param);
      // console.log(response, "create_route");
      const data = response.data;
      console.log("create route", data)
      dispatch(getRouteClusterList(data));
      return data;  
    } catch (error) {
      console.error("Error fetching admin map data:", error);
      throw error;  
    }
  };
  return setCreateRoute;
};

export default useCreateRoute;


