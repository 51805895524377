import { useEffect } from "react";
import useAxiosPrivate from "./useAxiosPrivate";
import { useDispatch, useSelector } from "react-redux";

import {

  getSROrderChartDetails,
} from "../slice/srSlice";

const useSROrderChartDetails = () => {
  const currentzone = useSelector((state) => state.assignRoute.zone_id);
  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();
  const getAllSROrderChartDetails = async (param) => {

      const response = await Axios.post("/sr_order_chart_details", param);
      const data = await response.data;
      dispatch(getSROrderChartDetails(data.body));
    
  };

  return getAllSROrderChartDetails;
};

export default useSROrderChartDetails;
