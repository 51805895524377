import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
// import { GetUserMgtWholesalerList } from '../../../../actions/UserMgt/UserMgt';
import UserMgtSOTable from "./UserMgtSOTable";
import UserMgtSOUpdateForm from "./UserMgtSOUpdateForm";
import styles from "../styles/UserMgtWholesaler.module.css";
import Button from "@mui/material/Button";
import { withStyles } from "@mui/styles";
import { styled } from "@mui/material/styles";
import { red, purple, white } from "@mui/material/colors";
import { style } from "@mui/system";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Modal from "../../../utility/UserModal";
import useDistributorList from "../../../hooks/useDistributorList";
import useSRList from "../../../hooks/useSRList";
import useSOList from "../../../hooks/useSOList";
import { IconButton, InputAdornment } from "@material-ui/core";
import SearchIcon from "@mui/icons-material/Search";
const RedButton = styled(Button)(({ theme }) => ({
  color: "white !important",
  backgroundColor: "#EF6C1A !important",
  "&:hover": {
    backgroundColor: "#EF6C1A !important",
  },
  borderRadius:"8px !important",
  padding:"8px 10px !important",
}));

const UserMgtSO = ({ zone }) => {
  const [q, setQ] = useState("");
  const userlist = useSelector((state) => state.UserMgt);
  const currentZone = useSelector((state) => state.app.curretDropdownData);
  const [status, setStatus] = useState(true); 

  const handleChange = (event) => {
    const value = event.target.value === 'Active' ? true : false;
    setStatus(value);
  };
  const [Role, setRole] = React.useState("All");
  const [searchColumn, setSearchColumn] = useState([
    "user_id",
    "user_code",
    "name",
    "surname",
    "mobile_no",
  ]);
  const SOAllList = useSelector((store) => store.so.SOList);

  const getAllSOList = useSOList();
  useEffect(() => {
    getAllSOList(status);
  }, [currentZone, status]);

  const [filterlist, setFilterlist] = useState([]);
  const [Userdetail, setUserDetail] = useState({});

  React.useEffect(() => {
    if (
      userlist &&
      userlist.wholesalerList &&
      userlist.wholesalerList.length !== 0 &&
      userlist.wholesalerList !== "undefined" &&
      userlist.wholesalerList !== null
    ) {
      setFilterlist(userlist.wholesalerList);
      setUserDetail(userlist.wholesalerList[0]);
    }
  }, [userlist]);

  // React.useEffect(() => {
  //     if (Firsttoken && Firsttoken.token && Firsttoken.token !== null && Firsttoken.token.length !== 0 && Firsttoken !== "undefined") {
  //         setToken(Firsttoken.token);
  //         let param = {
  //             "admin_id": 1,
  //             "zone_name": `${zone}`
  //         }
  //         dispatch(GetUserMgtWholesalerList(Firsttoken.token, param))
  //     }

  // }, [Firsttoken, zone])

  const Searchfilter = (datas) => {
    return datas.filter((row) =>
      searchColumn.some((column) =>
        row[column] !== null &&
        row[column] !== "undefined" &&
        row[column].length !== 0
          ? row[column].toString().toLowerCase().indexOf(q.toLowerCase()) > -1
          : ""
      )
    );
  };

  const handleRoleChange = (e) => {
    setRole(e.target.value);
  };

  const handleSubmit = () => {
    let neo = [];
    let filterRole = Role.toLowerCase();

    if (filterRole.toLocaleLowerCase() !== "all") {
      if (
        userlist.wholesalerList &&
        userlist.wholesalerList.length !== 0 &&
        userlist.wholesalerList !== "undefined" &&
        userlist.wholesalerList !== null
      ) {
        for (let i = 0; i < userlist.wholesalerList.length; i++) {
          if (
            userlist.wholesalerList[i].role_name.toLocaleLowerCase() ===
            filterRole.toLocaleLowerCase()
          ) {
            neo.push(userlist.wholesalerList[i]);
          }
        }
      }

      // Ashish
    } else {
      neo = userlist.wholesalerList;
    }
    //Ashish
    setFilterlist(neo);
    setUserDetail(neo[0]);
  };

  const [isAdd, setIsAdd] = useState(false);

  const handleAdd = () => {
    setIsAdd(true);
  };

  const [query, setQuery] = useState("");
  const handleSearch = (e) => {
    setQuery(e.target.value);
  };
  const [filterDistributorList, setFilterDistributorList] = useState(null);

  useEffect(() => {
    if (SOAllList) {
      setFilterDistributorList(SOAllList);
    }
  }, [SOAllList]);
  useEffect(() => {
    const filteredData = SOAllList?.filter((so) => {
      return (
        so?.first_name?.toLowerCase().includes(query?.toLowerCase()) ||
        so?.last_name?.toLowerCase().includes(query?.toLowerCase()) ||
        so?.mobile_no?.toLowerCase().includes(query?.toLowerCase()) ||
        so?.zone_name?.toLowerCase().includes(query?.toLowerCase())
      );
    });

    setFilterDistributorList(filteredData);
  }, [query]);

  return (
    <>
      <div className={styles.UserMgtWholesalerTable}>
        <div className={styles.MidSubContainerOne}>
          <div className={styles.MidSubContainerOneList}>

          </div>

          <div className={styles.MidSubContainerOneList}>

            <TextField
              variant="outlined"
              sx={{
                width: "300px",
                "& .MuiOutlinedInput-root": {
                  padding: "0px 4px", // Adjust padding for height reduction
                },
                "& .MuiInputBase-input": {
                  fontSize: "0.875rem", // Decrease font size
                  lineHeight: "1.2", // Adjust line height
                },
              }}
              size="small"
              value={query}
              onChange={handleSearch}
              placeholder="Search User"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton>
                      <SearchIcon /> {/* Assume SearchIcon is imported */}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <FormControl
              fullWidth
              size="small"
              variant="outlined"
              sx={{
                minWidth: 140,
                maxWidth: 140,
                "& .MuiOutlinedInput-root": { padding: "0px 4px" },
              }}
            >
              <Select
                labelId="status-label"
                value={status ? "Active" : "Inactive"}
                onChange={handleChange}
                label="Status"
                sx={{
                  fontSize: "0.875rem", // Decrease font size for Select
                }}
              >
                <MenuItem value="Active">Active</MenuItem>
                <MenuItem value="Inactive">Inactive</MenuItem>
              </Select>
            </FormControl>
          </div>

          <div
            className={styles.MidSubContainerTwo}
            style={{ display: "flex", flexDirection: "row", gap: "1rem" }}
          >
            <RedButton onClick={handleAdd} variant="contained">
              <Modal type={2} />
            </RedButton>
            {/* <RedButton
              variant="contained"
              onClick={handleSubmit}
              sx={{ height: "2.2rem" }}
            >
              Submit
            </RedButton> */}
          </div>
        </div>
        <UserMgtSOTable
          filterlist={filterDistributorList}
          Searchfilter={Searchfilter}
          detail={
            filterlist && filterlist.length !== 0 && filterlist !== "undefined"
              ? Userdetail
              : {}
          }
          setUserDetail={setUserDetail}
        />
      </div>

      <div className={styles.UserMgtWholesalerUpdateFormScrollViewContainer}>
        <div id="UserMgtWholesalerUpdateFormScrollView">
          {SOAllList && SOAllList?.length !== 0 && SOAllList !== "undefined" ? (
            <UserMgtSOUpdateForm
              detail={
                SOAllList &&
                SOAllList?.length !== 0 &&
                SOAllList !== "undefined"
                  ? Userdetail
                  : {}
              }
              list={
                SOAllList &&
                SOAllList?.length !== 0 &&
                SOAllList !== "undefined"
                  ? SOAllList
                  : []
              }
            />
          ) : (
            <h5>No Data</h5>
          )}
        </div>
      </div>
    </>
  );
};

export default UserMgtSO;
