import { useEffect } from "react";
import useAxiosPrivate from "./useAxiosPrivate";
import { useDispatch, useSelector } from "react-redux";
import { getDistributorListBySc } from "../slice/distributorSlice";

const useDistributorBySc = () => {
  const currentzone = useSelector((state) => state.assignRoute.zone_id);
  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();
  const getDistributorBySc = async (param) => {
    if (currentzone) {
    const response = await Axios.post("/distributor_list_by_sc", param);
    const data = await response.data;
    dispatch(getDistributorListBySc(data.body));
    }
  };
  // useEffect(() => {
  //   getAllDistributorList();
  // }, []);

  return getDistributorBySc;
};

export default useDistributorBySc;
