import React from "react";
import useAxiosPrivate from "./useAxiosPrivate";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { BaseImgUrl } from "../utility/BaseURL";
const useAddSku = (form) => {
  // console.log(form);
  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();
  const skuFormData = useSelector((store) => store.sku.skuFormData);
  const addSku = async () => {
    let param = {
      sku_name: form?.sku_name,
      sku_image: skuFormData?.full_path,
      sc_id: form?.sc_id,
      category_id: form?.category_id,
      price_per_piece: form?.price_per_piece,
      ladi: form?.ladi,
      price_per_ladi: form?.price_per_ladi,
      carton: form?.carton,
      price_per_carton: form?.price_per_carton,
    };
    // console.log(param, "distributorList");
    const response = await Axios.post("/add_sku", param);
    const data = await response.data;
    console.log(data, "distributorList");
    if (data.code === 200) {
      toast.success(data?.message);
    } else if (data.code === 400) {
      toast.error(data?.message);
    } else {
      toast.error("Failed to Add");
    }

    // console.log(data);
  };
  // useEffect(() => {
  //   getAllUserList();
  // }, []);
  return addSku;
};

export default useAddSku;
