import React from "react";
import BarGraph from "./BarGraph";
import { Stack } from "@mui/material";
import PieChart from "./PieChart";
import useSRGraphSaleDetails from "../../../hooks/useSRGraphSaleDetails";
import useSRCallConversion from "../../../hooks/useSRCallConversion";
import useSRAmountChartDetails from "../../../hooks/useSRAmountChartDetails";
import SaleStampTab from "./SaleStampTab";
import TopAchievers from "./TopAchievers";
import BarGraphNew from "./BarGraphNew";

const SalesStamp = () => {
  useSRGraphSaleDetails();
  useSRCallConversion();
  useSRAmountChartDetails();
  return (
    <Stack sx={{ display: "flex", flexDirection: "column", gap: "1.5rem" }}>
      {/* <TopAchievers /> */}
      {/* <BarGraph /> */}
      <BarGraphNew />
      <PieChart />
      {/* <PotentialGrowth /> */}
      {/* <SaleStampTable/> */}
      <SaleStampTab />
    </Stack>
  );
};

export default SalesStamp;
