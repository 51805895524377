
import useAxiosPrivate from "./useAxiosPrivate";
import { useDispatch } from "react-redux";

import { toast } from "react-toastify";
const useUpdateSRswitchRouteStatus = () => {
  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();

  const UpdateRouteStatus = async (param) => {
    try {
      const response = await Axios.post("update_sr_switch_status", param);
      const data = response.data;
      if (data.code === 200) {
        toast.success(data?.message);
      } else {
        toast.error(data?.messag);
      } 
      return data;  
    } catch (error) {
      console.error("Error updating route status:", error);
      throw error;  
    }
  };
  return UpdateRouteStatus;
};

export default useUpdateSRswitchRouteStatus;


