import React, { useEffect } from "react";
import useImageAxiosPrivate from "./useImageAxiosPrivate";
import Swal from "sweetalert2";
import { getCategoryFormData } from "../slice/categorySlice";
import { useDispatch } from "react-redux";
import { getSuperCategoryFormData } from "../slice/superCategorySlice";

const useSuperCategoryImageUpload = () => {
  const ImageAxiosPrivate = useImageAxiosPrivate();
  const dispatch = useDispatch();
  const getSuperCategoryImageUpload = async (formData) => {
    
    const response = await ImageAxiosPrivate.post(
      "/super_category_image_upload",
      formData
    );
    const data = await response.data;
    // console.log(data, "superCategoryImage");
    dispatch(getSuperCategoryFormData(data));

  };
  return getSuperCategoryImageUpload;
};

export default useSuperCategoryImageUpload;
