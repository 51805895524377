import React from "react";
import useAxiosPrivate from "./useAxiosPrivate";
import { useDispatch } from "react-redux";
import {  toast } from "react-toastify";
const useAddSuperStockist = (form) => {

  const Axios = useAxiosPrivate();
  const addSuperStockist = async () => {
    let param = {
      dist_name: `${form.name}`,
      mobile_no: `${form.mobile}`,
      shop_name: form.shop_name,
      zone_id: form.zone_id,
      sc_id: form?.scIdArray,
      email: form.email
    };

    const response = await Axios.post("/add_super_stockist", param);
    const data = await response.data;

    if (data.code === 200) {
      toast.success(data.message);
    } else {
      toast.error(data.message);
    }
  };
  return addSuperStockist;
};

export default useAddSuperStockist;
