import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  List,
  ListItem,
  ListItemText,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const RetailerPopup = ({ isOpen, onClose, retailerData }) => {
  // Function to format retailer information as desired
  const formatRetailerInfo = (retailer, index) => {
    return (
      <span>
       {index + 1}. <strong>{retailer.first_name} {retailer.last_name}</strong> | <span style={{ fontWeight: 'lighter' }}>{retailer.shop_name}</span>
      </span>
    );
  };

  return (
    <Dialog open={isOpen} onClose={onClose} sx={{ '& .MuiPaper-root': { maxWidth: 800, minWidth:300 } }}>
      <DialogTitle>
        Retailers
        <IconButton aria-label="close" onClick={onClose} sx={{ position: 'absolute', right: 0, top: 0 }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          {retailerData?.map((retailer, index) => (
            <Grid item xs={12} sm={6} md={4} key={index} sx={{paddingTop:"0px"}}>
              <List>
                <ListItem sx={{padding:"0px"}}>
                  <ListItemText
                    primary={formatRetailerInfo(retailer, index)}
                  />
                </ListItem>
              </List>
            </Grid>
          ))}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default RetailerPopup;
