import React, { useEffect, useRef, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler, TimeScale } from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
import { useSelector } from 'react-redux';
import 'chartjs-adapter-moment'; // Import moment adapter
import styles from './assests/style.module.css';
import { Stack, Typography } from '@mui/material';
import ComingSoonImg from "../../assets/data_not_available.png";
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler, TimeScale, annotationPlugin);

const SalesChart = () => {
  const [loading, setLoading] = useState(true);
  const graphData = useSelector((state) => state.scheme.schemeGraphData);
  const gradient = {
    colors: [
      { offset: 0, color: '#FFE0CD' },
      { offset: 0.8844, color: '#EF6C1A' },
    ]
  };

  const chartRef = useRef(null);

  useEffect(() => {
    if (graphData) {
      setLoading(false);
    }
  }, [graphData]);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      const canvas = chartRef.current.chart.canvas;
      if (canvas) {
        canvas.style.height = '500px';
      }
    });

    if (chartRef.current) {
      resizeObserver.observe(chartRef.current.chart.canvas);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  const labels = graphData?.graph_data?.map(item => new Date(item.created_at));
  const startDate = new Date(graphData.start_date).getTime();

  const data = {
    labels: labels,
    datasets: [
      {
        label: 'Sales Amount (in Rupees)',
        data: graphData?.graph_data?.map(item => ({ x: new Date(item.created_at), y: item.final_total_cost })),
        fill: true,
        backgroundColor: createGradientFill(gradient),
        borderColor: '#EF6C1A',
        tension: 0.4,
        pointRadius: 0,
        pointHoverRadius: 0
      }
    ]
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false, // Set to false to allow resizing without maintaining aspect ratio
    plugins: {
      legend: {
        display: false,
        position: 'top'
      },
      title: {
        display: false,
        text: 'Daily Sales'
      },
      annotation: {
        annotations: {
          line1: {
            type: 'line',
            xMin: startDate,
            xMax: startDate,
            borderColor: 'red',
            borderWidth: 2,
            label: {
              content: 'Start Date',
              enabled: true,
              position: 'top'
            }
          }
        }
      }
    },
    scales: {
      x: {
        type: 'time',
        title: {
          display: true,
          text: 'Date'
        }
      },
      y: {
        title: {
          display: true,
          text: 'Sales Amount (in Rupees)'
        },
        beginAtZero: true,
        ticks: {
          callback: function(value, index, values) {
            return `${String.fromCharCode(0x20B9)}${value} `;
          }
        }
      }
    }
  };

  return (
    <div className={styles.myComponent} style={{ width: '100%' }}>
      {/* <Line ref={chartRef} data={data} options={options} /> */}
      <Stack
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent:"center",
              alignItems:"center",
              // gap: "5rem",
              // background: "white",
              // padding: "1rem 2.5rem 1rem 2.5rem",
              width:"100%",
              height:"100%",
            }}
          >
            <img style={{objectFit:"contain", width:"300px", height:"300px"}} src={ComingSoonImg} alt="coming soon"/>
            <Typography sx={{fontSize:"18px", color:"#6a6b6a", fontWeight:"600"}}>Sufficent Data is not Available</Typography>
            </Stack>
      <div id="SchemeGraphDetails"></div>
    </div>
  );
};

const createGradientFill = (gradient) => {
  const ctx = document.createElement('canvas').getContext('2d');
  const gradientFill = ctx.createLinearGradient(0, 0, 0, 400);
  gradient.colors.forEach(color => {
    gradientFill.addColorStop(color.offset, color.color);
  });
  return gradientFill;
};

export default SalesChart;
