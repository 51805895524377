import { createSlice } from "@reduxjs/toolkit";

const inventorySlice = createSlice({
  name: "inventory",
  initialState: {
    inventoryList: null,
  },
  reducers: {
    getInventoryList: (state, action) => {
      state.inventoryList = action.payload;
    }
  },
});

export default inventorySlice.reducer;
export const { getInventoryList } =
inventorySlice.actions;
