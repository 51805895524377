import React from 'react'
import Sidebar from '../../Sidebar'
import { Stack } from '@mui/material'
import MapView from './MapView'

const AdminMapView = () => {
  return (

    <Stack sx={{flexDirection:"row"}}>

        <Stack sx={{height:{ xs: "calc(100vh - 115px)", lg:  "calc(100vh - 55px)"}, width:"100%", margin:"4px 4px 0px 0px", borderRadius:"4px", flexDirection: { xs: "column", lg: "row" }, gap:"5px"}}>
            <MapView/>
        </Stack>
    </Stack>
  )
}

export default AdminMapView