
import useAxiosPrivate from "./useAxiosPrivate";
import { useDispatch } from "react-redux";
// import { SEinShapeFile } from "../slice/adminMapSlice";

const useGetSEinSP = () => {
  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();

  const getSEinSP = async (param2) => {
    try {
      const response = await Axios.post("get_se_inside_shapefile", param2); 
      const data = response.data;
      // dispatch(SEinShapeFile(data));
      
      return data;  
    } catch (error) {
      console.error("Error fetching admin map data:", error);
      throw error;  
    }
  };
  return getSEinSP;
};

export default useGetSEinSP;