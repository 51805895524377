import { getSrRoutes } from "../slice/adminViewSlice";
import useAxiosPrivate from "./useAxiosPrivate";
import { useDispatch, useSelector } from "react-redux";


const useSelectedSrRoutes = () => {
  const currentzone = useSelector((state) => state.app.curretDropdownData);
  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();
  const getSelectedSrRoutes = async (param) => {
    if (currentzone) {
    const response = await Axios.post("/get_all_se_routes", param);
    const data = await response.data;
    dispatch(getSrRoutes(data.body));
    }
  };

  return getSelectedSrRoutes;
};

export default useSelectedSrRoutes;
