import { useSelector } from "react-redux";
import useAxiosPrivate from "./useAxiosPrivate";
import { toast } from "react-toastify";

const useUpdateSchemeStatus = () => {
  const Axios = useAxiosPrivate();

  const setUpdateSchemeStatus = async (param) => {
    // console.log(param);
    try {
      const response = await Axios.post("update_scheme_status_v2", param);
      const data = response.data;
      if (data.code === 200) {
        if(param?.status){
          toast.success("Updated successFully moved to active");
        }else{
        toast.success("Updated successFully moved to inactive");
        }
      } else {
        toast.error("Update Failed");
      } 
      return data;  
    } catch (error) {
      console.error("Error fetching admin map data:", error);
      throw error;  
    }
  };
  return setUpdateSchemeStatus;
};

export default  useUpdateSchemeStatus;