import React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { makeStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import { useSelector, useDispatch } from "react-redux";
import { styled } from "@mui/material/styles";
import styles from "../styles/UserMgtWholesalerTable.module.css";
import { USERMGTGETWHOLESALERDETAILS } from "../../constants/UserMgt/UserMgt";
import useDistributorList from "../../../hooks/useDistributorList";
import { getDistributorDetails } from "../../../slice/distributorSlice";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#E5E5E5",
    color: "black",
    fontSize: "14px",
    fontWeight: "600",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const RedButton = styled(Button)(({ theme }) => ({
  color: "#EF6C1A !important",
  backgroundColor: "white !important",
  "&:hover": {
    backgroundColor: "#EF6C1A !important",
    color: "white !important",
    border: "2px solid #EF6C1A !important",
  },
  border: "2px solid #EF6C1A !important",
  height: "30px !important",
  fontSize: "12px !important",
  borderRadius: "5px !important"
}));

const useStyles = makeStyles({
  head: {
    borderBottom: "2px solid black",
  },
});

const UserMgtSOTable = ({
  filterlist,
  Searchfilter,
  detail,
  setUserDetail,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  useDistributorList();
  const distributorAllList = useSelector(
    (store) => store.distributor.distributorList
  );

  // console.log(distributorAllList)

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const columns = [
    { id: "zone", label: "Zone", minWidth: 130 },
    { id: "userid", label: "User code", minWidth: 100 },
    { id: "scName", label: "Super Category", minWidth: 100 },
    { id: "name", label: "Name", minWidth: 100 },
    // { id: "surname", label: "Surname", minWidth: 100 },
    { id: "mobile", label: "Mobile No", minWidth: 100 },
    { id: "status", label: "Status", minWidth: 100 },
    { id: "action", label: "Action", minWidth: 100 },
  ];

  const handleDetails = (row) => {
    dispatch(getDistributorDetails(row))
    document
      .getElementById("UserMgtWholesalerUpdateFormScrollView")
      .scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
    setUserDetail(row);
  };

  return (
    <div style={{ width: "100%", marginTop: "10px" }}>
      <Paper>
        <TableContainer sx={{ maxHeight: 700, minWidth: "100%" }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead
              className={styles.head}
              sx={{ backgroundColor: "#c4c4c4" }}
            >
              <TableRow
                className={styles.head}
                sx={{ backgroundColor: "#c4c4c4" }}
              >
                {columns.map((column) => (
                  <StyledTableCell
                    key={column.id}
                    align="center"
                    // style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {filterlist &&
              filterlist !== "undefined" &&
              filterlist?.length !== 0 ? (
                <>
                  {filterlist
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          key={index}
                          sx={
                            detail && detail.user_id === row.user_id
                              ? { backgroundColor: "#faebf0" }
                              : { backgroundColor: "white" }
                          }
                        >
                          <>
                            <TableCell align="center">
                              {row?.zone_name}
                            </TableCell>
                            <TableCell align="center">
                              {row?.user_code??"N/A"}
                            </TableCell>
                            <TableCell align="center">
                              {row?.sc_names[0]??"N/A"}
                            </TableCell>
                            {/* <TableCell align="center">
                              {row.dist_code?.toUpperCase()}
                            </TableCell> */}
                            <TableCell align="center">{row?.first_name}</TableCell>
                            {/* <TableCell align="center">{row?.last_name}</TableCell> */}
                            <TableCell align="center">
                              {row.mobile_no}
                            </TableCell>
                            <TableCell align="center">{row.status===true?'active':'inactive'}</TableCell>
                            <TableCell align="center">
                              <RedButton
                                variant="outlined"
                                onClick={() => handleDetails(row)}
                              >
                                Details
                              </RedButton>
                            </TableCell>
                          </>
                        </TableRow>
                      );
                    })}
                </>
              ) : (
                <TableRow>
                  <TableCell align="center" colSpan={12}>
                    No Data
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={
            filterlist?.length
          }
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
};

export default UserMgtSOTable;
