
import useAxiosPrivate from "./useAxiosPrivate";


const useRedeployRoute = () => {
  const Axios = useAxiosPrivate();
  

  const setRedeployRoute = async (param) => {
    
    try {
      const response = await Axios.post("deploy_route_request", param);
      const data = response.data; 
      console.log(data);
      return data;  
    } catch (error) {
      console.error("Error fetching admin map data:", error);
      throw error;  
    }
  };
  return setRedeployRoute;
};

export default  useRedeployRoute;