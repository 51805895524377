import { createSlice } from "@reduxjs/toolkit";

const performanceSlice = createSlice({
  name: "performance",
  initialState: {
    targetTableData: null,
    coinTableDetails:null,
    holidayCount:null
  },
  reducers: {
    getTargetTableData: (state, action) => {
      state.targetTableData = action.payload;
    },
    getCoinTableData: (state, action) => {
      state.coinTableDetails = action.payload;
    },
    getHolidayCount: (state, action) =>{
      state.holidayCount = action.payload;
    }    
  },
});

export default performanceSlice.reducer;
export const { getTargetTableData, getCoinTableData, getHolidayCount } =
performanceSlice.actions;
