import * as React from "react";
import { useState, useEffect } from "react";
import useSRSaleTableDetails from "../../../hooks/useSRSaleTableDetails";
import { useSelector } from "react-redux";
import { Box, TextField } from "@mui/material";

const DateSales = ({searchTerm,setSearchTerm}) => {
  const zoneData = useSelector((state) =>state.app.curretDropdownData);
  const date = new Date();
  const [currentSelectedDate, setCurrentSelectedDate] = useState(
    formatDate(date)
  );
  const getSRSalesData = useSRSaleTableDetails(currentSelectedDate, null, null);
  useEffect(() => {
    getSRSalesData();
  }, [zoneData]);
  const handleDateChange = (e) => {
    // console.log(e.target.value);
    setCurrentSelectedDate(e.target.value);
  };

  
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        gap: "0.5rem",
        alignItems: "center",
        width:"100%"
      }}
    >
      <input
        type="text"
        placeholder="Search"
        style={{
          width:"10rem",
          height: "1.8rem",
          fontSize: "14px",
          fontFamily: "inherit",
          borderRadius: "8px",
          padding: "4px 8px", 
          border: "1px solid #c4c4c4", 
          outline: "none",
        }}
        onChange={handleSearchChange} 
        value={searchTerm} 
      />
      <input
        type="date"
        id="dateSale"
        name="dateSale"
        style={{
          height: "2rem",
          fontSize: "14px",
          fontFamily: "inherit",
          borderRadius: "8px",
        }}
        onChange={handleDateChange}
        value={currentSelectedDate}
      />
      
    </Box>
  );
};

// Function to format date as "YYYY-MM-DD"
const formatDate = (date) => {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  return `${year}-${month}-${day}`;
};

export default DateSales;
