import React from "react";
import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  TextField,
  MenuItem,
} from "@material-ui/core";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import useEditTarget from "../../../hooks/useEditTarget";
import { Typography, Stack } from "@mui/material";
import DriveFolderUploadOutlinedIcon from "@mui/icons-material/DriveFolderUploadOutlined";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import coinData from "./data.json";
import Sidebar from "../../Sidebar";
import coinImage from "./coin.png";

const CoinManagementDetails = () => {
  const [editModeRowId, setEditModeRowId] = useState(null);
  const [editedValues, setEditedValues] = useState({});
  const [currentMonthDay, setCurrentMonthDay] = useState(null);
  const [currentMonth, setCurrentMonth] = useState(null);
  const headerName = [
    { index: 0, name: "Date" },
    { index: 1, name: "Call Stamp" },
    { index: 2, name: "Time Stamp" },
    { index: 3, name: "Kms Travelled" },
    { index: 4, name: "Sales Stamp" },
    { index: 5, name: "Fixed Salary" },
    { index: 6, name: "Variable Salary" },
    { index: 8, name: "Call Incentive" },
    { index: 9, name: "Time Incentive" },
    { index: 10, name: "Sales Incentive" },
    { index: 11, name: "TOTAL" },
  ];

  const months = [
    { value: "January", label: "January", index: 1 },
    { value: "February", label: "February", index: 2 },
    { value: "March", label: "March", index: 3 },
    { value: "April", label: "April", index: 4 },
    { value: "May", label: "May", index: 5 },
    { value: "June", label: "June", index: 6 },
    { value: "July", label: "July", index: 7 },
    { value: "August", label: "August", index: 8 },
    { value: "September", label: "September", index: 9 },
    { value: "October", label: "October", index: 10 },
    { value: "November", label: "November", index: 11 },
    { value: "December", label: "December", index: 12 },
  ];

  const currentzone = useSelector((state) => state.assignRoute.zone_id);
  const tableData = useSelector((state) => state.performance.targetTableData);
  const [formData, setFormData] = useState({
    month: "January",
    month_no: 1,
  });
  useEffect(() => {
    // getTableData(formData);
  }, [currentzone, formData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    // const selectedMonth = months.find((month) => month.value === value);
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
      //   month_no: selectedMonth?.index,
    }));
  };

  const handleEdit = (row) => {
    setEditModeRowId(row.utt_id);
    setEditedValues({
      utt_id: row.utt_id,
      month_no: row.month,
      sr_name: row.sr_name,
      sales_target: row.sales_target,
      time_stamp: row.time_stamp,
      call_stamp: row.call_stamp,
      outlets_coverage: row.outlets_coverage,
      lines_per_call: row.lines_per_call,
      petrol: row.petrol,
      call_incentive: row.call_incentive,
      time_incentive: row.time_incentive,
      sale_incentive: row.sale_incentive,
      fixed_salary: row.fixed_salary,
      variable_salary: row.variable_salary,
    });
  };
  const editUserTarget = useEditTarget();
  const handleSave = async () => {
    await editUserTarget(editedValues);
    // await getTableData(formData);
    setEditModeRowId(null);
    setEditedValues({});
  };

  const handleCancel = () => {
    // Cancel edit mode
    setEditModeRowId(null);
    setEditedValues({});
  };

  const handleInputChange = (e, field) => {
    const { value } = e.target;
    setEditedValues((prevValues) => ({
      ...prevValues,
      [field]: value,
    }));
  };
  return (
    <>
      <Stack sx={{ display: "flex", flexDirection: "row" }}>
        <Sidebar />
        <Stack
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "3rem",
            marginTop: "1rem",
            marginLeft: "1rem",
            flexWrap:"wrap"
          }}
        >
          <Stack sx={{ display: "flex", flexDirection: "row", gap: "0.5rem",flexWrap:"wrap" }}>
            <Typography sx={{ color: "#3E3E3E", fontWeight: 700 }}>
              Coin Management
            </Typography>
            <Typography sx={{ color: "#3E3E3E", fontWeight: 700 }}>
              
            </Typography>
            <Typography sx={{ color: "#3E3E3E", fontWeight: 400 }}>
              Details
            </Typography>
          </Stack>

          <Stack
            sx={{ display: "flex", flexDirection: "column", gap: "0.8rem",flexWrap:"wrap" }}
          >
            <Stack
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "1rem",
                alignItems: "center",
                flexWrap:"wrap"
              }}
            >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker sx={{ height: "4rem" }} />
              </LocalizationProvider>
              <TextField
                select
                name="month"
                size="medium"
                //   value={formData.month}
                //   onChange={handleChange}
                variant="outlined"
                style={{
                  minWidth: "230px",
                  marginRight: "10px",
                  marginBottom: "0.5rem",
                }}
              >
                {months.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              <Stack
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "0.5rem",
                  alignItems: "center",
                  flexWrap:"wrap"
                }}
              >
                <Typography sx={{ fontSize: "14px", fontWeight: 400 }}>
                  MTD Working days
                </Typography>
                <Typography sx={{ fontSize: "14px", fontWeight: 400 }}>
                  :
                </Typography>
                <Typography
                  sx={{ fontSize: "14px", fontWeight: 700, color: "#0C8709" }}
                >
                  19
                </Typography>
                <Typography>|</Typography>
                <Typography sx={{ fontSize: "14px", fontWeight: 400 }}>
                  MTD Working days
                </Typography>
                <Typography sx={{ fontSize: "14px", fontWeight: 400 }}>
                  :
                </Typography>
                <Typography
                  sx={{ fontSize: "14px", fontWeight: 700, color: "#000000" }}
                >
                  28
                </Typography>
              </Stack>
            </Stack>

            <TableContainer style={{ maxHeight: 700, minWidth: "100%" }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead style={{ backgroundColor: "#F8F8F8" }}>
                  <TableRow>
                    {headerName?.map((header) => (
                      <TableCell
                        key={header?.index}
                        style={{ textAlign: "left", width: "200px" }}
                      >
                        {header?.name}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {coinData?.coinManagementDetails?.map((row, index) => (
                    <TableRow key={row.sr_id}>
                      <TableCell style={{ textAlign: "left" }}>
                        {row.date}
                      </TableCell>
                      <TableCell style={{ textAlign: "left" }}>
                        {row.call_stamp}
                        <Stack sx={{ display: "flex", flexDirection: "row" }}>
                          <Typography
                            sx={{
                              color: "#696969",
                              fontSize: "14px",
                              fontWeight: 500,
                            }}
                          >
                            Tgt :
                          </Typography>
                          <Typography
                            sx={{
                              color: "#696969",
                              fontSize: "14px",
                              fontWeight: 500,
                            }}
                          >
                            40
                          </Typography>
                        </Stack>
                      </TableCell>
                      <TableCell style={{ textAlign: "left" }}>
                        {row.time_stamp}
                        <Stack sx={{ display: "flex", flexDirection: "row" }}>
                          <Typography
                            sx={{
                              color: "#696969",
                              fontSize: "14px",
                              fontWeight: 500,
                            }}
                          >
                            Tgt :
                          </Typography>
                          <Typography
                            sx={{
                              color: "#696969",
                              fontSize: "14px",
                              fontWeight: 500,
                            }}
                          >
                            40
                          </Typography>
                        </Stack>
                      </TableCell>
                      <TableCell style={{ textAlign: "left" }}>
                        {row.kms_travelled}
                      </TableCell>
                      <TableCell style={{ textAlign: "left" }}>
                        {row.sales_travelled}
                        <Stack sx={{ display: "flex", flexDirection: "row" }}>
                          <Typography
                            sx={{
                              color: "#696969",
                              fontSize: "14px",
                              fontWeight: 500,
                            }}
                          >
                            Tgt :
                          </Typography>
                          <Typography
                            sx={{
                              color: "#696969",
                              fontSize: "14px",
                              fontWeight: 500,
                            }}
                          >
                            40
                          </Typography>
                        </Stack>
                      </TableCell>
                      <TableCell style={{ textAlign: "left" }}>
                        <Stack
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            gap: "0.1rem",
                          }}
                        >
                          <img src={coinImage} alt="CoinImage" style={{ width: "0.7rem" }} />
                          {row.fixed_salary}
                        </Stack>
                      </TableCell>
                      <TableCell style={{ textAlign: "left" }}>
                      <Stack
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            gap: "0.1rem",
                          }}
                        >
                          <img src={coinImage} alt="CoinImage" style={{ width: "0.7rem" }} />
                          {row.variable_salary}
                        </Stack>
                      </TableCell>
                      <TableCell style={{ textAlign: "center" }}>
                      <Stack
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            gap: "0.1rem",
                          }}
                        >
                          <img src={coinImage} alt="CoinImage" style={{ width: "0.7rem" }} />
                          {row.call_incentive}
                        </Stack>
                      </TableCell>

                      <TableCell style={{ textAlign: "center" }}>
                      <Stack
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            gap: "0.1rem",
                          }}
                        >
                          <img src={coinImage} alt="CoinImage" style={{ width: "0.7rem" }} />
                          {row.time_incentive}
                        </Stack>
                      </TableCell>
                      <TableCell style={{ textAlign: "left" }}>
                      <Stack
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            gap: "0.1rem",
                          }}
                        >
                          <img src={coinImage} alt="CoinImage" style={{ width: "0.7rem" }} />
                          {row.sale_incentive}
                        </Stack>
                      </TableCell>
                      <TableCell style={{ textAlign: "left" }}>
                      <Stack
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            gap: "0.1rem",
                          }}
                        >
                          <img src={coinImage} alt="CoinImage" style={{ width: "0.7rem" }} />
                          {row.total}
                        </Stack>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};

export default CoinManagementDetails;
