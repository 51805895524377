import React, { useEffect, useState } from 'react'
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from 'dayjs';
import { Button, Stack, TextField, Typography } from '@mui/material';
import useDistributorReportData from "../../hooks/useDistributorReportData";
import * as XLSX from "xlsx";
import { saveAs } from 'file-saver';
import DownloadIcon from "@mui/icons-material/Download";
import DeleteIcon from "@mui/icons-material/Delete";
import { ToastContainer, toast } from "react-toastify";
import DownloadLoader from "./DownloadLoader";
import useSecondarySales from '../../hooks/DownloadDailyFile/useSecondarySales';
import useSRPerformanceEcoCallReport from '../../hooks/useSRPerformanceEcoCallReport';
import { useSelector } from 'react-redux';
import useVisitReport from '../../hooks/DownloadRangeFile/useVisitReport';
const EveryDayDownloads = () => {
  const zoneData = useSelector((state)=>state.app.curretDropdownData);
    const [selectedDate, setSelectedDate] = useState(dayjs());
    const [isDownloading, setIsDownloading] = useState(false);
    const [param, setParam] =  useState({
        date: dayjs().format('YYYY/MM/DD'),
    });
    useEffect(()=>{
      setParam({
        date: dayjs().format('YYYY/MM/DD'),
        zone_id: zoneData?.zone_id,
      });
    }, [zoneData?.zone_id])
    
    const downloadName = [
      {
        id: 1,
        name: "Daily Distribution Report",
      },
      {
        id: 2,
        name: "SR Performance ECO Sale",
      },
      {
        id: 3,
        name: "Daily Secondary Sales Format",
      },
      {
        id: 4,
        name: "Visit Report",
      },
    ];
    const getVisitReport = useVisitReport()
    const handleDateChange = (newDate) => {
      if (newDate) {
        setSelectedDate(newDate);
        //   const year = newDate.year();
        //   const month = newDate.month() + 1;
        //   const date = newDate.date();

        const formattedDate = newDate.format("YYYY/MM/DD");
        setParam({
          date: formattedDate,
          zone_id: zoneData?.zone_id,
        });
      }
    };
    const getDistributorReportData = useDistributorReportData();
    const getSecondarySalesReport=useSecondarySales()


    const handleExcelDownload = (id) => {
      console.log(id)
      switch (id) {
        case 1:
          handleDayExcelDownload();
          break;
        case 2:
          handleMonthExcelDownload();
          break;
        case 3:
          handleDailySecondarySalesExcelDownload();
          break;
        case 4:
          handleVisitReportDownload();
          break;
        default:
          console.log('Invalid id');
      }
    };

    const handleVisitReportDownload=async()=>{
     
        setIsDownloading(true);
        
      
        // Define the new headers
        const headers = [
          "Sr No.",
          "Date",
          "Location",
          "Beat",
          "ASM Name",
          "SO Name",
          "SR Name",
          "SR Emp ID",
          "Retailer",
          "Retailer Contact Person",
          "Retailer Contact Person Number",
          "Category",
          "Visit Start Date",
          "Visit End Date",
          "Total Time",
          "Status (visit status)",
          "Visit Start Address",
          "Visit End Address",
          "Rescheduled Visit Date",
          "Visit Travel KM",
          "Visit Added By",
        ];

        const data = await getVisitReport(param)
      
        // Map the static data to the new headers
        const formattedData = data?.data?.map((item, index) => ({
          "Sr No.": index + 1,
          Date: item.date,
          Location: item.location,
          "Beat": item.route_name,
          "ASM Name": item.asm_name,
          "SO Name": item.so_name,
          "SR Name": item.sr_name,
          "SR Emp ID": item.sr_emp_id,
          Retailer: item.r_name,
          "Retailer Contact Person": item.r_c_person,
          "Retailer Contact Person Number": item.contact,
          Category: item.category,
          "Visit Start Date": item.visit_start,
          "Visit End Date": item.visit_end,
          "Total Time": item.total_time,
          "Status (visit status)": item.status,
          "Visit Start Address": item.v_start_add,
          "Visit End Address": item.v_end_add,
          "Rescheduled Visit Date": item.rescheduled_visit,
          "Visit Travel KM": Number(item.visit_km_travel) || 0,
          "Visit Added By": item.added_by,
        }));
      
        const wb = XLSX.utils.book_new();
        const wsData = [
          headers,
          ...formattedData.map((item) => headers.map((header) => item[header])),
        ];
      
        const ws = XLSX.utils.aoa_to_sheet(wsData);
      
        // Define column widths
        const colWidths = headers.map((header) => {
          if (header === "Sr No.") return { wch: 10 };
          if (header === "Date" || header === "Location" || header === "Beat") return { wch: 20 };
          if (
            header === "ASM Name" ||
            header === "SO Name" ||
            header === "SR Name" ||
            header === "SR Emp ID" ||
            header === "Retailer" ||
            header === "Retailer Contact Person" ||
            header === "Retailer Contact Person Number" ||
            header === "Visit Start Date" ||
            header === "Visit End Date" ||
            header === "Rescheduled Visit Date"
          )
            return { wch: 25 };
          else if( header === "Visit Start Address" ||
            header === "Visit End Address")
            return {wch:60};
          return { wch: 18 }; // Default width
        });
      
        ws["!cols"] = colWidths;
      
        XLSX.utils.book_append_sheet(wb, ws, "Visit Details");
        XLSX.writeFile(wb, "VisitDetails.xlsx");
      
        setIsDownloading(false);
      
    }

    // Write the workbook to buffer
    const writeExcelBuffer=(workbook, excelName)=>{
      const excelBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "buffer",
      });
      const excelBlob = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      saveAs(excelBlob, `${excelName}.xlsx`);
    }

    const handleDayExcelDownload = async () => {
      setIsDownloading(true);
      const data = await getDistributorReportData(param);
      const workbook = XLSX.utils.book_new();
      setIsDownloading(false);
      // Iterate through each report in the dist_report array
      data?.dist_report?.forEach((report, reportIndex) => {
        if (report?.dist_report?.length > 0) {
          const sheetData = [];

          // Calculate total orders and total value
          const totalOrders = report.dist_report.length;
          const totalValue = report.dist_report.reduce(
            (acc, reportItem) => acc + reportItem.final_total_cost,
            0
          );

          // Add the additional details at the top
          sheetData.push(["Order Date", param.date]);
          sheetData.push(["Total Orders", totalOrders]);
          sheetData.push(["Total Value", totalValue]);
          sheetData.push(["Distributor Name", report?.dist_name]);
          sheetData.push(["Zone", report?.zone_name]);
          sheetData.push([]);
          // Prepare the header row
          const header = [
            "Sl_No",
            "Bill_No",
            "Route",
            "Retailer_Name",
            "Retailer_Address",
            "Retailer_Mobile_No",
          ];

          // Add SKU headers with merged cells for piece, ladi, and carton
          if (report.dist_report.length > 0) {
            const sampleReport = report.dist_report[0];
            sampleReport.sku_details.forEach((sku) => {
              const matchingSku = data.sku_list.find(
                (skuItem) => skuItem.sku_id === sku.sku_id
              );
              const skuName = matchingSku
                ? matchingSku.sku_name
                : `SKU_${sku.sku_id}_Name`;

              header.push(skuName, "", "");
            });
          }

          // Add additional columns
          header.push(
            "Total SKU's",
            "Total Amount",
            "Discount",
            "Billing Amount",
            "Benefit"
          );
          sheetData.push(header);

          // Prepare the second header row for piece, ladi, and carton
          const subHeader = ["", "", "", "", "", ""];
          if (report.dist_report.length > 0) {
            const sampleReport = report.dist_report[0];
            sampleReport.sku_details.forEach(() => {
              subHeader.push("piece", "ladi", "carton");
            });
          }
          subHeader.push("", "", "");
          sheetData.push(subHeader);

          // Initialize totals for each SKU
          const skuTotals =
            report.dist_report.length > 0
              ? report.dist_report[0].sku_details.map((sku) => ({
                  piece: 0,
                  ladi: 0,
                  carton: 0,
                }))
              : [];

          // Add data rows
          report.dist_report.forEach((reportItem, index) => {
            const row = [
              index + 1,
              reportItem.sale_code,
              reportItem.route_name,
              reportItem.retailer_name,
              reportItem.retailer_address,
              reportItem.retailer_mobile_no,
            ];

            // Add SKU details
            if (reportItem.sku_details) {
              reportItem.sku_details.forEach((sku, skuIndex) => {
                const skuDetail = reportItem.sku_details.find(
                  (detail) => detail.sku_id === sku.sku_id
                );
                if (skuDetail) {
                  row.push(skuDetail.piece, skuDetail.ladi, skuDetail.carton);

                  if (!skuTotals[skuIndex]) {
                    skuTotals[skuIndex] = { piece: 0, ladi: 0, carton: 0 };
                  }

                  skuTotals[skuIndex].piece += Number(skuDetail.piece) || 0;
                  skuTotals[skuIndex].ladi += Number(skuDetail.ladi) || 0;
                  skuTotals[skuIndex].carton += Number(skuDetail.carton) || 0;
                } else {
                  row.push(0, 0, 0);
                }
              });
            }

            // Add additional columns
            row.push(
              reportItem.total_skus,
              reportItem.all_total_cost,
              reportItem.discount,
              reportItem.final_total_cost,
              Array.isArray(reportItem.free_sku) &&
                reportItem.free_sku.length > 0
                ? reportItem.free_sku.join(", ")
                : "No free SKU"
            );
            sheetData.push(row);
          });

          // Add a horizontal line
          sheetData.push([]);

          // Add totals row
          const totalsRow = ["Total", "", "", "", "", ""];
          skuTotals.forEach((total) => {
            totalsRow.push(total.piece, total.ladi, total.carton);
          });
          // Add empty cells for the additional columns
          totalsRow.push("", "", "");
          sheetData.push(totalsRow);

          // Create worksheet and append it to the workbook
          const worksheet = XLSX.utils.aoa_to_sheet(sheetData);
          const colWidths = [
            { wch: 15 }, // Sl_No
            { wch: 15 }, // Bill_No
            { wch: 20 }, // Route
            { wch: 25 }, // Retailer_Name
            { wch: 30 }, // Retailer_Address
            { wch: 20 }, // Retailer_Mobile_No
          ];
          // Add custom widths for SKU columns
          if (report.dist_report.length > 0) {
            report.dist_report[0].sku_details.forEach(() => {
              colWidths.push({ wch: 10 }, { wch: 10 }, { wch: 10 }); // piece, ladi, carton
            });
          }

          // Add custom widths for additional columns
          colWidths.push(
            { wch: 15 }, // Total SKU's
            { wch: 15 }, // Total Amount
            { wch: 10 }, // Discount
            { wch: 15 }, // Billing Amount
            { wch: 20 } // Benefit
          );

          worksheet["!cols"] = colWidths;

          // Merge SKU name cells
          let colIndex = 6;
          if (report.dist_report.length > 0) {
            report.dist_report[0].sku_details.forEach(() => {
              worksheet["!merges"] = worksheet["!merges"] || [];
              worksheet["!merges"].push({
                s: { r: 4, c: colIndex },
                e: { r: 4, c: colIndex + 2 },
              });
              colIndex += 3;
            });
          }

          // Append worksheet to workbook with a unique name
          XLSX.utils.book_append_sheet(
            workbook,
            worksheet,
            `${report.dist_name}`
          );
        }
      });

      
      writeExcelBuffer(workbook, "Distributor_Report")
    };



    //Daily Secondary Sales Format
    const generateDailySecondarySalesData = (data) => {

      console.log(data,"generateDailySecondarySalesData")
      const sheetData = [];
    
      sheetData.push(["Daily Secondary Data"]);

      sheetData.push([]);



      // Add the header row
      const header = [
        "ASM Name",
        "SO NAME",
        "Total",
        "Present",
        "Absent",
        "MDL",
        "TC",
        "PC",
        "Sales",
        "Avg TC",
        "Avg PC",
        "Avg Sale",
        "Prod. %",
      ];
      sheetData.push(header);
    
      // Process each ASM
      data?.data?.forEach((asm) => {
        let firstSoForAsm = true; // To track the first SO under each ASM
    
        asm.so_list.forEach((so) => {
          const row = [
            firstSoForAsm ? asm.asm_name : "", // Only show ASM Name for the first row under each ASM
            so.so_name, // SO Name
            so.sr_data.se_count, // Total
            so.sr_data.present, // Present
            so.sr_data.absent, // Absent
            so.sr_data.mdl, // MDL
            so.sr_data.tc, // TC
            so.sr_data.pc, // PC
            so.sr_data.sales, // Sales
            so.sr_data.avg_tc, // Avg TC
            so.sr_data.avg_pc, // Avg PC
            so.sr_data.avg_sale, // Avg Sale
            so.sr_data.prod, // Prod. %
          ];
          sheetData.push(row);
          firstSoForAsm = false; // After the first SO, set this to false
        });
    
        // Add the total row for each ASM
        const totalRow = [
          asm.asm_name + " Total", // Indicating total for this ASM
          "", // No SO Name for the total row
          asm.total_data.se_count, // Total
          asm.total_data.present, // Present
          asm.total_data.absent, // Absent
          asm.total_data.mdl, // MDL
          asm.total_data.tc, // TC
          asm.total_data.pc, // PC
          asm.total_data.sales, // Sales
          asm.total_data.avg_tc, // Avg TC
          asm.total_data.avg_pc, // Avg PC
          asm.total_data.avg_sale, // Avg Sale
          asm.total_data.prod, // Prod. %
        ];
        sheetData.push(totalRow);
      });
    
      // Add the grand total row
      const grandTotalRow = [
        "Grand Total",
        "",
        data.grand_total.se_count,
        data.grand_total.present,
        data.grand_total.absent,
        data.grand_total.mdl,
        data.grand_total.tc,
        data.grand_total.pc,
        data.grand_total.sales,
        data.grand_total.avg_tc,
        data.grand_total.avg_pc,
        data.grand_total.avg_sale,
        data.grand_total.prod,
      ];
      sheetData.push(grandTotalRow);
    
      return sheetData;
    };    

    const handleDailySecondarySalesExcelDownload= async()=>{
    setIsDownloading(true);
    const data= await getSecondarySalesReport(param)
    const sheetData = generateDailySecondarySalesData(data);
    setIsDownloading(false);
    const worksheet = XLSX.utils.aoa_to_sheet(sheetData);
    const workbook = XLSX.utils.book_new();

           // Define the merge for C2 to F2
           worksheet["!merges"] = [
            { s: { r: 1, c: 2 }, e: { r: 1, c: 5 } }, 
            { s: { r: 1, c: 9 }, e: { r: 1, c:11 } }
            ];
            const centeredStyle = {
              alignment: { horizontal: "center", vertical: "center" }
          };
          
          // Set the text and apply the style in the merged cell C2-F2
          worksheet["C2"] = { v: "SR", s: centeredStyle };
            // worksheet["C2"] = { v: "SR" };
          worksheet["J2"] = { v: "Average Per SR" };


    XLSX.utils.book_append_sheet(workbook, worksheet, "Daily Secondary Sales Format");
    
    // Adjust column widths
    worksheet["!cols"] = [
      { wch: 20 }, // ASM Name
      { wch: 20 }, // SO Name
      { wch: 10 }, // Total
      { wch: 10 }, // Present
      { wch: 10 }, // Absent
      { wch: 10 }, // MDL
      { wch: 10 }, // TC
      { wch: 10 }, // PC
      { wch: 15 }, // Sales
      { wch: 10 }, // Avg TC
      { wch: 10 }, // Avg PC
      { wch: 15 }, // Avg Sale
      { wch: 10 }, // Prod. %
    ];
    
    // Export to Excel file
    XLSX.writeFile(workbook, "Daily Secondary Sales Format.xlsx");
    }
    const getSRPerformanceEcoCall = useSRPerformanceEcoCallReport();
    const handleMonthExcelDownload= async()=>{
    setIsDownloading(true);
    const data = await getSRPerformanceEcoCall(param.date, zoneData.zone_id);
    setIsDownloading(false);
      // Define the data headers (headers under merged ECO and SalE)
      const mainHeaders = [
        "", "", "", "",
        "", "", "", "                   ECO                  ", "", "                  Sale                     ", ""
      ];
      const subHeaders = [
        "Super Distributor", "ASM Name", "SO Name", "City",
        "Distributor", "Name of SR", "Employee ID", "LM MTD Ach", "CM MTD Ach", "LM MTD Sale", "CM MTD Sale"
      ];
    
      // Format your data
      const formattedData = data.map(item => ({
        "Super Distributor": item.ss_name || "",
        "ASM Name": item.asm_name || "",
        "SO Name": item.so_name || "",
        "City": item.zone_name || "",
        "Distributor": item.dist_name || "",
        "Name of SR": item.sr_name || "",
        "Employee ID": item.emp_area || "",
        "LM MTD Ach": item.lm_total_calls || "0",
        "CM MTD Ach": item.cm_total_calls || 0,
        "LM MTD Sale": item.lm_total_cost || 0,
        "CM MTD Sale": item.cm_total_sales || 0
      }));
    
      // Create a new worksheet
      const worksheet = XLSX.utils.aoa_to_sheet([mainHeaders, subHeaders]);
      XLSX.utils.sheet_add_json(worksheet, formattedData, { origin: 'A3', skipHeader: true });
    
      // Merge cells for main headers
      worksheet['!merges'] = [
        { s: { r: 0, c: 7 }, e: { r: 0, c: 8 } }, // Merge columns 8 and 9 for "ECO"
        { s: { r: 0, c: 9 }, e: { r: 0, c: 10 } } // Merge columns 10 and 11 for "Sale"
      ];
    
      // Set styles for headers
      const headerStyle = { alignment: { vertical: "center", horizontal: "center" }, font: { bold: true } };
      
      // Apply styles to main headers
      for (let i = 0; i < mainHeaders.length; i++) {
        const cellRef = XLSX.utils.encode_cell({ r: 0, c: i });
        worksheet[cellRef].s = headerStyle;
      }
    
      // Apply styles to sub-headers
      for (let i = 0; i < subHeaders.length; i++) {
        const cellRef = XLSX.utils.encode_cell({ r: 1, c: i });
        worksheet[cellRef].s = headerStyle;
      }
    
      // Set custom column widths
      worksheet["!cols"] = [
        { wch: 20 },  // Super Distributor
        { wch: 20 },  // ASM Name
        { wch: 25 },  // SO Name
        { wch: 20 },  // City
        { wch: 20 },  // Distributor
        { wch: 25 },  // Name of SR
        { wch: 18 },  // Employee ID
        { wch: 12 },  // LM MTD Ach
        { wch: 12 },  // CM MTD Ach
        { wch: 12 },  // LM MTD Sale
        { wch: 12 }   // CM MTD Sale
      ];
    
      // Set center alignment for all data cells
      for (let rowIndex = 2; rowIndex < formattedData.length + 2; rowIndex++) {
        for (let colIndex = 0; colIndex < subHeaders.length; colIndex++) {
          const cellRef = XLSX.utils.encode_cell({ r: rowIndex, c: colIndex });
          if (!worksheet[cellRef]) worksheet[cellRef] = {};
          worksheet[cellRef].s = { alignment: { vertical: "center", horizontal: "center" } };
        }
      }
    
      // Create a new workbook
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "SR_Performance_Eco_sales");
    
      // Export the Excel file
      XLSX.writeFile(workbook, "SR_Performance_Eco_sales_Report.xlsx");
      
    }

    return (
      <div>
        <ToastContainer
          position="bottom-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />

        {isDownloading ? <DownloadLoader /> : null}
        <Stack
          sx={{
            display: "flex",
            flexDirection: "column",
            //   alignItems: "center",
            gap: "10px",
            padding: "1rem",
          }}
        >
          <Stack sx={{ flexDirection: "row", justifyContent: "space-between" }}>
            <Typography sx={{ fontSize: "20px", fontWeight: "600" }}>
              Daily Downloads
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                sx={{ width: "180px", alignSelf: "flex-end" }}
                views={["year", "month", "day"]}
                value={selectedDate}
                onChange={handleDateChange}
                renderInput={(params) => <TextField {...params} size="small" />}
                slotProps={{
                  textField: {
                    size: "small",
                    sx: {
                      "& .MuiInputBase-root": {
                        height: "40px",
                        width: "180px",
                      },
                    },
                  },
                }}
              />
            </LocalizationProvider>
          </Stack>
          <Stack>
            {/* <Button onClick={handleDayExcelDownload}>Download dIST</Button>*/}
            <Stack
              sx={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                gap: "1.5rem",
              }}
            >
              {downloadName?.map((download) => (
                <Stack
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    background: "#FFFFFF",
                    boxShadow: 3,
                    width: "22rem",
                    padding: "1rem",
                    borderRadius: "0.6rem",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography>{download?.name}</Typography>
                  <Stack
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "0.5rem",
                    }}
                  >
                    {/* <Stack
                  sx={{
                    border: "1px solid #EF6C1A",
                    padding: "0.2rem",
                    borderRadius: "0.2rem",
                  }}
                >
                  <DeleteIcon sx={{ color: "#EF6C1A" }} />
                </Stack> */}
                    <Stack
                      sx={{
                        border: "1px solid #EF6C1A",
                        padding: "0.2rem",
                        borderRadius: "0.2rem",
                      }}
                    >
                      <DownloadIcon
                        sx={{ color: "#EF6C1A", cursor: "pointer" }}
                        onClick={() => handleExcelDownload(download.id)}
                      />
                    </Stack>
                  </Stack>
                </Stack>
              ))}
            </Stack>
          </Stack>
        </Stack>
      </div>
    );
}

export default EveryDayDownloads