
import useAxiosPrivate from "./useAxiosPrivate";
import { useDispatch } from "react-redux";
import {reqBoundryDetails}from "../slice/adminMapSlice"

const useReqBoundryDetails = () => {
  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();

  const getReqBoundryDetails = async (param) => {
 console.log("hi")
    try {
      const response = await Axios.post("get_boundary_data_request", param);
      const data = response.data;
      console.log(data);
      dispatch(reqBoundryDetails(data));
      return data;  
    } catch (error) {
      console.error("Error fetching admin map data:", error);
      throw error;  
    }
  };
  return getReqBoundryDetails;
};

export default useReqBoundryDetails;