import { createSlice } from "@reduxjs/toolkit";

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: {
    dashboardMapData: null,
    selectedSFdata: null,
    selectedPincode: null,
    retailerDownloadList: null,
    attendanceDownloadList: null,
    srPerformanceDownloadList: null,
    srSalesDownloadList: null,
    skuDetailsExcelDownload: null,
    skuListExcelDownload: null,
  },
  reducers: {
    getdashboardData: (state, action) => {
      state.dashboardMapData = action.payload;
    },
    getSelecetedSFdata: (state, action) => {
      state.selectedSFdata = action.payload;
    },
    setSelectedPincode: (state, action) => {
      state.selectedPincode = action.payload;
    },
    getRetailerDownloadList: (state, action) => {
      state.retailerDownloadList = action.payload;
    },
    getSkuDetailsExcelDownload: (state, action) => {
      state.skuDetailsExcelDownload = action.payload;
    },
    getSkuListExcelDownload: (state, action) => {
      state.skuListExcelDownload = action.payload;
    },
    getAttendanceDownloadList: (state, action) => {
      state.attendanceDownloadList = action.payload;
    },
    getSRPerformanceDownloadList: (state, action) => {
      state.srPerformanceDownloadList = action.payload;
    },
    getSRSalesDownloadList: (state, action) => {
      state.srSalesDownloadList = action.payload;
    },
  },
});

export default dashboardSlice.reducer;
export const {
  getdashboardData,
  getSelecetedSFdata,
  setSelectedPincode,
  getRetailerDownloadList,
  getAttendanceDownloadList,
  getSRPerformanceDownloadList,
  getSRSalesDownloadList,
  getSkuDetailsExcelDownload,
  getSkuListExcelDownload,
} = dashboardSlice.actions;
