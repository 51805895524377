import { useEffect } from "react";
import useAxiosPrivate from "./useAxiosPrivate";
import { useDispatch } from "react-redux";
import { getUserList } from "../slice/userSlice";
import { getZoneList } from "../slice/appSlice";


const useZoneList = () => {
  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();
  const getAllZoneList = async (param) => {
    
    const response = await Axios.post("/zone_list", param);
    const data = await response.data;
    
    dispatch(getZoneList(data.body));
    
  };
  return getAllZoneList;
};

export default useZoneList;