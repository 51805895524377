import React, { useState } from 'react';
import Styles from './styles/salesmidcontainer.module.css';
import { TextField } from '@mui/material';
import SalesTable from './SalesTable';
import { useSelector } from 'react-redux'


const SalesMidContainer = ({ IdChange, Submit,Statevalue,zone }) => {
    const [route, setRoute] = useState("All");
    const [routeList, setRouteList] = useState([])
    const state = useSelector(state => state.SalesMgt);
    const [change, setChange] = useState(false)
    const [Product, setProduct] = useState('All');
    const [ProductList, setProductList] = useState([]);
    const [retailerText,setRetailerText] = useState('')


    React.useEffect(() => {
        if (state && state !== null && state !== "undefined") {
            if (state.routeList && state.routeList.length !== 0 && state.routeList !== "undefined" && state.routeList !== null) {
                let r = [];
                r.push({ route_code: "All", route_id: "" });
                for (let i = 0; i < state.routeList.length; i++) {
                    r.push(state.routeList[i])
                }
                setRouteList(r);

            }
            if (state.productList && state.productList.length !== 0 && state.productList !== "undefined" && state.productList !== null) {

                let r = [];
                r.push({ product_name: "All", product_id: "" });
                for (let i = 0; i < state.productList.length; i++) {
                    r.push(state.productList[i])
                }

                setProductList(r);
            }
        }
    }, [state])

    return (
        <div className={Styles.SalesMidContainer}>

            <div className={Styles.SalesRouteContainer}>

            <div>
            <div className={Styles.SalesRouteList}>
                    <label>Search</label>
                    <TextField placeholder="Name..." size="small" value={retailerText} onChange={(e)=>setRetailerText(e.target.value)}/>
                </div>
            </div>
            </div>
            

            <div>
                <SalesTable change={change} retailerText={retailerText} Statevalue={Statevalue} zone={zone} Product={Product} route={route}/>
            </div>

        </div>
    )
}

export default SalesMidContainer


