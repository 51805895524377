import { useEffect } from "react";
import useAxiosPrivate from "./useAxiosPrivate";
import { useDispatch } from "react-redux";
import { getCategoryList } from "../slice/categorySlice";
import { getSkuList, getSkuListByCategory, getSkuListByCategoryMrp } from "../slice/skuSlice";

const useSkuListByCategoryMrp = () => {
  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();
  const getAllSkuListByCategoryMrp = async (categoryId,mrp) => {
    console.log(mrp,"useSkuListByCategoryMrp")
    const filteredMrp=mrp?.filter((mrp)=>mrp!='All')
    console.log(filteredMrp,"useSkuListByCategoryMrp")
    let param={
      category_id:categoryId,
      mrp: filteredMrp,
    }
    const response = await Axios.post("/sku_list_by_category_mrp",param);
    const data = await response.data;
    dispatch(getSkuListByCategoryMrp(data.body));
    return data.body
  };
  // useEffect(() => {
  //   getAllSkuList();
  // }, []);

  return getAllSkuListByCategoryMrp
};

export default useSkuListByCategoryMrp;
