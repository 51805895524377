import { Card, Fab, FormControl, Grid, MenuItem, Paper, Select, Stack, TextField, Typography } from '@mui/material'
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Divider from '@mui/material/Divider';
import { IOSSwitch } from '../../utility/IoSwitch';
// import { GetZoneByStateId, UpdateZoneByZoneId } from '../../../actions/ZoneMgt/zoneMgtAction';
import { LoadingButton } from '@mui/lab';
import styled from 'styled-components';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {HiPencilAlt} from 'react-icons/hi';
import {GrClose} from 'react-icons/gr'


export const AddLoadingButton = styled(LoadingButton)(({ theme }) => ({
    padding: "15px 15px",
    fontWeight: "bold",
    color: "white",
    backgroundColor: "#731D3A",
    border: "2px solid #731D3A",
    width: "100px",
    height: "40px",
    display: "flex",
    alignItems: "center",
    '&:hover': {
        color: "white",
        backgroundColor: "#731D3A",
        border: "2px solid #731D3A",
    },
    '&:disabled':{
        color:"white"
    }
}));

const UpdateZoneMgt = ({ token,state }) => {
const [edit,setEdit]=useState(false)
    const store = useSelector(state => state.ZoneMgt)
    const dispatch = useDispatch();
    const [error, setError] = useState({})
    const [Stateoptions, setStateoptions] = React.useState(["Ahm"]);
    const [Statevalue, setStateValue] = React.useState('');
    const [StateinputValue, setStateInputValue] = React.useState('');
    const [zone, setZone] = React.useState('');
    const [zoneList, setZoneList] = useState([])
    // const stateList = useSelector(state => state.Zone.StateList);
    const [loading, setLoading] = useState(false)
    const [zoneDetail, setZoneDetail] = React.useState({
        "zone_id": 3,
        "zone_name": "Cochin",
        "status": true,
        "created_at": "2022-10-21 17:38:45",
        "updated_at": "2022-10-21 17:38:45",
        "state_id": 1,
        "frequency": 0,
        "lati": "",
        "longi": "",
        "is_category": false,
        "route_id": 10
    })


    // React.useEffect(() => {

    //     if (stateList == "undefined" || stateList == null || stateList.length == 0) {

    //         setStateValue('');
    //         return console.log('State List Error')
    //     }

    //     let i = 0;
    //     let arr = [];
    //     setStateValue(stateList[0].state_name);
    //     let param = {
    //         "state_id": stateList[0].state_id
    //     }
    //     dispatch(GetZoneByStateId(token, param))
    //     for (i = 0; i < stateList.length; i++) {
    //         arr.push(stateList[i].state_name)
    //     }
    //     setStateoptions(arr)
    // }, [stateList])

    React.useEffect(() => {

        if (store?.zoneList == "undefined" || store?.zoneList == null || store?.zoneList.length == 0) {
            return console.log('State List Error')
        }
        setZoneList(store?.zoneList);
        setZoneDetail(store?.zoneList && store?.zoneList?.length !== 0 ? store?.zoneList[0] : {})
    }, [store?.zoneList])

    // useEffect(() => {

    //     for (let i = 0; i < stateList.length; i++) {
    //         if (stateList[i].state_name === Statevalue) {
    //             let param = {
    //                 "state_id": stateList[i].state_id
    //             }
    //             dispatch(GetZoneByStateId(token, param))
    //         }
    //     }

    // }, [Statevalue])

    // useEffect(()=>{
    //     setStateValue(state)
    //     for (let i = 0; i < stateList.length; i++) {
    //         if (stateList[i].state_name === state) {
    //             let param = {
    //                 "state_id": stateList[i].state_id
    //             }
    //             dispatch(GetZoneByStateId(token, param))
    //         }
    //     }
    // },[state])

    // const handleStateChange = (e) => {
    //     setStateValue(e.target.value);

    //     for (let i = 0; i < stateList.length; i++) {
    //         if (stateList[i].state_name === e.target.value) {
    //             let param = {
    //                 "state_id": stateList[i].state_id
    //             }
    //             // dispatch(GetZoneByStateId(token, param))
    //         }
    //     }

    // }

    const handleDetails = (row) => {
        setZoneDetail(row)
        document.getElementById("ZoneDetail").scrollIntoView({ behavior: 'smooth', block: "end", inline: "nearest" });
        // setUserDetail(row);

    }

    const handleChange = (e) => {
        if (e.target.name === 'status' || e.target.name === 'is_category') {
            setZoneDetail({
                ...zoneDetail,
                [e.target.name]: e.target.checked
            })
        }
        else {
            setZoneDetail({
                ...zoneDetail,
                [e.target.name]: e.target.value
            })
        }
    }


    const IsValid = () => {
        let success = true;
        let error = {};

        if (zoneDetail?.lati?.length !== 0 && !(/^(0|[1-9]\d*)?(\.\d+)?(?<=\d)$/g).test(zoneDetail?.lati)) {
            error.lati = "Please Enter valid latitude";
            success = false
        }
        if (zoneDetail?.lati?.length !== 0 && !(/^(0|[1-9]\d*)?(\.\d+)?(?<=\d)$/g).test(zoneDetail?.longi)) {
            error.longi = "Please Enter valid langitude";
            success = false
        }
        if (zoneDetail?.frequency !== 0 && !(/^(0|[1-9]\d*)$/).test(zoneDetail?.frequency)) {
            error.frequency = "Please Enter valid number";
            success = false
        }

        setError(error)
        return success
    }


    const handleSubmit = async (e) => {
        e.preventDefault()
        if (IsValid()) {
            setLoading(true);
            let state_id;
            // for (let i = 0; i < stateList.length; i++) {
            //     if (stateList[i].state_name === Statevalue) {
            //         state_id = stateList[i].state_id

            //     }
            // }
            let param = {

                "zone_name": zoneDetail?.zone_name,
                "zone_id": zoneDetail?.zone_id,
                "status": zoneDetail?.status,
                "frequency": zoneDetail?.frequency,
                "lati": zoneDetail?.lati,
                "longi": zoneDetail?.longi,
                "is_category": zoneDetail?.is_category,
                "route_id": 0,
                "state_id": state_id
            }
            // const data = await dispatch(UpdateZoneByZoneId(token, param));

            // if (data.code === 200) {
            //     setLoading(false)
            //     toast.success('Zone Updated SuccessFully');
            //     setEdit(false)
            // }
            // else {
            //     toast.error(data?.message)
            // }
        }
    }

    const hanldeToggle=()=>{
        setEdit(!edit)
    }

    return (
        <>
            <ToastContainer
                position="bottom-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <form onSubmit={handleSubmit} >
                <div>
                    <Stack
                        direction={{ xs: "column" }}
                        component={Paper}
                        padding={1}
                        spacing={2}
                        marginTop={1}
                    >
                        <Typography variant='body1' paddingLeft={1} sx={{ fontWeight: "bold" }}>Update Zones</Typography>

                        {/* <div sx={{ minHeight: "10px" }}>

                        </div> */}
{/* 
                        <Stack direction="row" paddingLeft={1} spacing={1} >
                            <Typography paddingLeft={2} sx={{ fontWeight: "bold" }}>State</Typography>
                            <FormControl fullWidth sx={{ minWidth: 220, maxWidth: 220 }}>
                                <Select
                                    value={Statevalue}
                                    onChange={handleStateChange}
                                    size="small"
                                // label="Age"
                                >
                                    {
                                        Stateoptions && Stateoptions.map((item, index) => (
                                            <MenuItem value={item} key={index}>{item}</MenuItem>
                                        ))
                                    }
                                </Select>
                            </FormControl>
                        </Stack> */}
                        <Divider />



                        <Grid container spacing={2} sx={{ flex: 2 }} padding={2.4}>
                            {
                               zoneList?.length!==0 ?  zoneList?.map((item, index) =>
                                    <Grid item xs={2} sm={4} md={4} key={index} onClick={() => handleDetails(item)}>
                                        <Card component={Paper} sx={{ padding: "8px", cursor: "pointer", backgroundColor: `${zoneDetail?.zone_id === item?.zone_id ? "#f0c2d1" : "white"}` }}>
                                            <Typography align='center'>{item?.zone_name}</Typography>
                                        </Card>

                                    </Grid>
                                )

                                :

                                <Grid item xs={2} sm={4} md={4}>
                                        <Card component={Paper} sx={{ padding: "8px", cursor: "pointer", backgroundColor: "white"}}>
                                            <Typography align='center'>**No Zones**</Typography>
                                        </Card>

                                    </Grid>
                            }
                        </Grid>






                    </Stack>

                    <Stack marginTop={1}
                        component={Paper} id="ZoneDetail" padding={2}>
                        <Stack direction="row" justifyContent="space-between">
                            <Typography variant='body1' sx={{ fontWeight: "bold" }}>Zone Details</Typography>
                            <Fab sx={{backgroundColor:"white"}} onClick={hanldeToggle} size="small">
                             {edit ?  <GrClose style={{width:"10px",height:"10px"}}/>: <HiPencilAlt  style={{width:"20px",height:"20px"}}/>}
                            </Fab>
                        </Stack>


                        <Grid container spacing={2} sx={{ flex: 2, marginTop: "15px" }} padding={2.4}>
                            <Grid item xs={2} sm={4} md={4}>
                                <Stack direction="row" spacing={2}><Typography variant='body2' sx={{ fontWeight: "bold", minWidth: "80px" }}>Zone Name</Typography><TextField size="small"  value={zoneDetail?.zone_name} disabled name='zone_name' onChange={handleChange} /></Stack>
                            </Grid>
                            <Grid item xs={2} sm={4} md={4}>
                                <Stack direction="row" spacing={2}><Typography variant='body2' sx={{ fontWeight: "bold", minWidth: "80px" }}>Status</Typography><IOSSwitch size="small" disabled={!edit} checked={zoneDetail?.status ? true : false} name='status' onChange={handleChange} /></Stack>
                            </Grid>
                            <Grid item xs={2} sm={4} md={4}>
                                <Stack direction="row" spacing={2}><Typography variant='body2' sx={{ fontWeight: "bold", minWidth: "80px" }}>latitude</Typography><TextField size="small" disabled={!edit} value={zoneDetail?.lati} name='lati' onChange={handleChange} error={error?.lati} helperText={error?.lati} /></Stack>
                            </Grid>
                            <Grid item xs={2} sm={4} md={4}>
                                <Stack direction="row" spacing={2}><Typography variant='body2' sx={{ fontWeight: "bold", minWidth: "80px" }}>longitude</Typography><TextField size="small" disabled={!edit} value={zoneDetail?.longi} name='longi' onChange={handleChange} error={error?.longi} helperText={error?.longi} /></Stack>
                            </Grid>
                            <Grid item xs={2} sm={4} md={4}>
                                <Stack direction="row" spacing={2}><Typography variant='body2' sx={{ fontWeight: "bold", minWidth: "80px" }}>Frequency</Typography><TextField size="small" value={zoneDetail?.frequency} type='number' name='frequency' error={error?.frequency} helperText={error?.frequency} disabled={!edit} onChange={handleChange} /></Stack>
                            </Grid>
                            <Grid item xs={2} sm={4} md={4}>
                                <Stack direction="row" spacing={2}><Typography variant='body2' sx={{ fontWeight: "bold", minWidth: "80px" }}>Is Category</Typography><IOSSwitch disabled={!edit} size="small" checked={zoneDetail?.is_category ? true : false} name='is_category' onChange={handleChange} /></Stack>
                            </Grid>

                        </Grid>



                        <Stack alignItems="center" direction="column" marginTop={1}>

                           {edit &&  <AddLoadingButton disabled={!edit} size="small" type="submit" loading={loading}>Update</AddLoadingButton>}
                        </Stack>
                    </Stack>
                </div>
            </form>
        </>
    )
}

export default UpdateZoneMgt