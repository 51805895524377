import { useEffect } from "react";
import useAxiosPrivate from "./useAxiosPrivate";
import { useDispatch, useSelector } from "react-redux";
import { getTargetTableData } from "../slice/performanceSlice";


const useUsersTargetData = (json) => {
  const currentzone = useSelector((state) => state.assignRoute.zone_id);
  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();
  const usersTargetData = async () => {
    if (currentzone) {
      const param = {
        zone_id: currentzone,
        month_no: json?.month_no,
        year:json?.year
      };
    const response = await Axios.post("/get_user_target_list", param);
    const data = await response.data;
    dispatch(getTargetTableData(data.body));
    }
  };

  useEffect(()=>{
    if(currentzone && json?.month_no)
    usersTargetData();
  },[currentzone,json?.month_no])

  return usersTargetData;

};

export default useUsersTargetData;