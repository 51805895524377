import { createSlice } from "@reduxjs/toolkit";

const superCategorySlice = createSlice({
  name: "superCategory",
  initialState: {
    superCategoryList: null,
    superCategoryFormData: null,
    superCategoryStatus: null,
  },
  reducers: {
    getSuperCategoryList: (state, action) => {
      state.superCategoryList = action.payload;
    },
    getSuperCategoryFormData: (state, action) => {
      state.superCategoryFormData = action.payload;
    },
    resetSuperCategoryFormData: (state, action) => {
      state.superCategoryFormData = null;
    },
    getSuperCategoryStatus: (state, action) => {
      state.superCategoryStatus = action.payload;
    },
  },
});

export default superCategorySlice.reducer;
export const {
  getSuperCategoryList,
  getSuperCategoryFormData,
  resetSuperCategoryFormData,
  getSuperCategoryStatus,
} = superCategorySlice.actions;
