import { createSlice } from "@reduxjs/toolkit";

const distributorSlice = createSlice({
  name: "distributor",
  initialState: {
    distributorList: null,
    distributorDetails: null,
    distributorListBySC:null,
    superStockistList:null,
    superStockistDetails: null,
  },
  reducers: {
    getDistributorList: (state, action) => {
      state.distributorList = action.payload;
    },
    getDistributorDetails: (state, action) => {
      state.distributorDetails = action.payload;
    },
    getDistributorListBySc: (state, action) => {
      state.distributorListBySC = action.payload;
    },
    getSuperStockistList: (state, action) => {
      state.superStockistList = action.payload;
    },
    getSuperStockistDetails: (state, action) => {
      state.superStockistDetails = action.payload;
    },
  },
});

export default distributorSlice.reducer;
export const { getDistributorList, getDistributorDetails, getDistributorListBySc, getSuperStockistList, getSuperStockistDetails } =
  distributorSlice.actions;
