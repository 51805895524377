
import * as React from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import {LocalizationProvider} from '@mui/x-date-pickers';
import { makeStyles } from '@mui/styles';
import Styles from './styles/sales.module.css';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


const useStyles = makeStyles({
    date: {
        width: 180,
        height: "10px"
    }
});


const SalesTopContainer = ({ selectedStartDate, handleStartDateChange, selectedEndDate, handleEndDateChange }) => {


    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <div className={Styles.DateContainer}>
                
                <div className={Styles.DateList}>
                    <label className={Styles.DateText}>From Date</label>
                    <div style={{zIndex:"999"}}>
                    <DatePicker selected={selectedStartDate} onChange={(date) => handleStartDateChange(date) } dateFormat="dd/MM/yyyy" />
                    </div>
                </div>

                <div className={Styles.DateList}>
                    <label className={Styles.DateText}>To Date</label>
                         <div style={{zIndex:"999"}}>
                    <DatePicker selected={selectedEndDate} onChange={(date) => handleEndDateChange(date) } dateFormat="dd/MM/yyyy" />
                    </div>
                </div>

            </div>
         </LocalizationProvider>
    )
}

export default SalesTopContainer
