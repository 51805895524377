import { StylesContext } from "@mui/styles";
import React, { useEffect, useState } from "react";
import Styles from "./styles/AddRouteMgt.module.css";
import CreateIcon from "@mui/icons-material/Create";
import { Button, Radio, TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Stack from "@mui/material/Stack";
import { useSelector, useDispatch } from "react-redux";
// import { GetZoneCoordinate, UpdateRouteDetails } from '../../../actions/Route/RouteMgt';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import { TableconvertDate } from '../../Functions/function';
// import AutoCompleteOne from '../../Autocomplete/AutoComplete';
import { id, vi } from "date-fns/locale";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Checkbox from "@mui/material/Checkbox";
import AddAutoCompleteOneUserEdit from "../AddAutoComplete/AddAutoCompleteUserEdit";
import useUpdateSaathiRoute from "../../hooks/useUpdateSaathiRoute";
import useSaathiRouteDetails from "../../hooks/useSaathiRouteDetails";
import AddAutoCompleteEdit from "../AddAutoComplete/AddAutoCompleteEdit";
import { setSelectedRoute } from "../../slice/routeSlice";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import useUpdateBeat from "../../hooks/Beat/useUpdateBeat";
import useBeatList from "../../hooks/Beat/useBeatList";
// const Option = (props) => {
//     return (
//         <div>
//             <components.Option {...props} controlShouldRenderValue={props.disabled}>
//                 <input
//                     type="checkbox"
//                     checked={props.isSelected}
//                     onChange={() => null}
//                 />{" "}
//                 <label>{props.label}</label>
//             </components.Option>
//         </div>
//     )
// }

const RedButton = styled(Button)(({ theme }) => ({
  color: "white",
  backgroundColor: "#731D3A",
  "&:hover": {
    backgroundColor: "#731D3A",
  },
}));

const GrayButton = styled(Button)(({ theme }) => ({
  color: "#404040",
  backgroundColor: "#E5E5E5",
  "&:hover": {
    backgroundColor: "#E5E5E5",
  },
}));

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const UpdateRouteMgt = ({ rootzone }) => {
  const dispatch = useDispatch();
  const [edit, setEdit] = useState(false);
  const zoneData = useSelector((state) => state.app.curretDropdownData);
  const Route = useSelector((state) => state.route.selectedRouteDetails);
  const asmList = useSelector((state) => state.sm.SMList);
  const soList = useSelector((state) => state.so.SOList);
  const srList = useSelector((state) => state.sr.SRList);
  const distributorList = useSelector(
    (state) => state.distributor.distributorList
  );

  // const state = useSelector(state => state.Zone.list);
  // const list = useSelector(list => list.Login);

  const [rootAsm, setRootAsm] = useState("");
  const [rootTsi, setRootTsi] = useState("");
  const [rootSe, setRootSe] = useState("");
  const [Dist, setDist] = useState("");
  const [Distid, serDistid] = useState("");

  const [zone, setZone] = useState("");
  const [form, setForm] = useState({
    dist_firm_name: ""
  });
  const [formError, setFormError] = useState({});
  const [active, setActive] = useState(false);

  const [Updatesuccess, setUpdatesuccess] = useState(false);
  const [failed, setFailed] = useState({
    UpdateFailed: false,
    UpdateFailedMessage: "",
  });
  const [Asm, setAsm] = useState("");
  const [Tsi, setTsi] = useState("");
  const [Se, setSe] = useState("");
  const [Sr, setSr] = useState("");
  const [Asmid, setAsmid] = useState("");
  const [Tsiid, setTsiid] = useState("");
  const [Seis, setSeid] = useState("");
  const [token, setToken] = useState("");
  const Firsttoken = useSelector((state) => state.Token);
  const [rootForm, setRootForm] = useState({});

  const [selectedValues, setSelectedValues] = useState([]);
  const weekdays = [
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
    "sunday",
  ];
  const [selectedWeekdays, setSelectedWeekdays] = useState([]);
  React.useEffect(() => {
    if (
      Firsttoken &&
      Firsttoken.token &&
      Firsttoken.token !== null &&
      Firsttoken.token.length !== 0 &&
      Firsttoken !== "undefined"
    ) {
      setToken(Firsttoken.token);
    }
  }, [Firsttoken]);

  useEffect(() => {
    setForm(Route);
    setSelectedWeekdays(Route?.week);
  }, [Route]);

  React.useEffect(() => {
    setZone(rootzone);
  }, [rootzone]);

  const handleOpen = async () => {
    let param = {
      zone_name: rootzone,
    };
    // const data = await dispatch(GetZoneCoordinate(Firsttoken.token, param, "updateroute"))
    // setZoneCoord(data)

    setTimeout(() => {
      setEdit(true);
    }, [100]);
  };

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });

      if(e.target.name==="shop_name"){
        const selectedShopName=distributorList?.find((dist)=>dist.shop_name===e.target.value)
        console.log(selectedShopName,"formData111")
        setForm({
          ...form,
          dist_id:selectedShopName?.dist_id,
          dist_firm_name: e.target.value
        })
      }
    
  };

  const handleDistChange = (item) => {
    if (item && item !== "undefined" && item !== null) {
      // setDist(`${item.name}${item.surname}(${item.user_id})-${item.zone_name}`);
      setDist(`${item.shop_name}-${item.zone_name}`);

      serDistid(item.dist_id);

      setForm({
        ...form,
        shop_name: item.shop_name,
        dist_id: [item.dist_id],
      });
    } else {
      setDist("");
      serDistid("0");

      setForm({
        ...form,
        dist_name: "",
        dist_id: null,
      });
    }
  };
  const handleAsmChange = (item) => {
    if (item && item !== "undefined" && item !== null) {
      setAsm(`${item.first_name}-${item?.zone_name}`);
      setAsmid(item.user_id);
      setForm({
        ...form,
        asm_name: item.first_name,
        asm_id: [item.user_id],
        asm_surname: item.last_name,
      });
    } else {
      setAsm(``);
      setAsmid(0);
      setForm({
        ...form,
        asm_first_name: "",
        asm_id: null,
        asm_surname: "",
      });
    }
  };

  const handleSoChange = (item) => {
    if (item && item !== "undefined" && item !== null) {
      setSe(`${item.first_name}-${item.zone_name}`);
      setSeid(item.user_id);
      setForm({
        ...form,
        so_name: item.first_name,
        so_id: [item.user_id],
        so_surname: item.last_name,
      });
    } else {
      setSe(``);
      setSeid("");
      setForm({
        ...form,
        so_name: "",
        so_id: null,
        so_surname: "",
      });
    }
  };
  const handleSRChange = (item) => {
    if (item && item !== "undefined" && item !== null) {
      setSr(`${item.first_name}-${item.zone_name}`);
      setSeid(item.user_id);
      setForm({
        ...form,
        sr_name: item.first_name,
        se_id: [item.user_id],
        sr_surname: item.last_name,
      });
    } else {
      setSr(``);
      setSeid("");
      setForm({
        ...form,
        sr_name: "",
        sr_id: [],
        sr_surname: "",
      });
    }
  };

  const handleStatus = (e) => {
    setActive(!active);
    setForm({
      ...form,
      status: !form.status,
    });
  };

  const IsValid = () => {
    let success = true;
    let error = {};
    if (!form.area && form?.area?.trim()) {
      error.wdname = "*please enter a valid area name";
      success = false;
    }

    if (!form.route_name && form.route_name.trim()) {
      error.routedesc = "*please enter a Name";
      success = false;
    }

    setFormError(error);
    return success;
  };

  React.useEffect(() => {
    if (failed.UpdateFailed) {
      let text = failed.UpdateFailedMessage;
      toast.error(`${text}`);

      setFailed({
        ...failed,
        UpdateFailed: false,
        UpdateFailedMessage: "",
      });
    }
  }, [failed]);

  const IntitalRoles = () => {
    if (rootAsm.length !== 0 && rootAsm !== "undefined" && rootAsm !== null) {
      //    setAsm(`${form.asm_name}${form.asm_surname}(${form.asm_id})`);

      setAsm(rootAsm);
    } else {
      setAsm("");
    }

    if (
      rootTsi.toString().length !== 0 &&
      rootTsi !== "undefined" &&
      rootTsi !== null
    ) {
      setTsi(rootTsi);
    } else {
      setTsi("");
    }

    if (
      rootSe.toString().length !== 0 &&
      rootSe !== "undefined" &&
      rootSe !== null
    ) {
      setSe(rootSe);
    } else {
      setSe("");
    }
  };

  const handleClose = () => {
    document
        .getElementById("RouteManagementTable")
        .scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
    setEdit(false);
    // setForm(rootForm);
    setForm(Route);
    setFormError({});
    setZone(rootForm.zone_name);
    IntitalRoles();
    // setSr("")
    // setSe("")
    // setAsm("")
    // setDist("")
    // setVillageStatus(Route.route.is_village)
    // setTsi(rootForm.tsi_name);
    // setSe(rootForm.se_name);

    setActive(rootForm.status);
  };

  function capitalizeFirstLetter(str) {
    if (typeof str !== "string" || str.length === 0) return str;
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    console.log(name, "name");
    // setSelectedWeekdays((prev) => {
    //   if (checked) {
    //     return [...prev, name];
    //   } else {
    //     return prev.filter(day => day !== name);
    //   }
    // });
    const capitalizeDay = capitalizeFirstLetter(name);
    setForm({
      ...form,
      week: name,
      // route_name: capitalizeDay,
    });
    setSelectedWeekdays([name]);
  };

  React.useEffect(() => {
    // dispatch(setSelectedRoute(null));
    // setForm({
    //   area:'',
    //   route_name:''
    // })
    setEdit(false);
  }, [zoneData?.zone_id]);
  // useEffect(() =>{
  //   console.log(form , "1321313213")
  // }, [form])
  // const UpdateRouteDetails = useUpdateSaathiRoute();
  const UpdateBeat=useUpdateBeat()
  // const RouteList = useSaathiRouteDetails();
  const getAllBeatList=useBeatList()
  const handleSubmit = async () => {
    const param = {
      zone_id: zoneData?.zone_id,
    };
    // if (IsValid()) {

    const param2={
      zone_id: zoneData?.zone_id,
      route_id : form?.route_id,
      beat_name: form?.route_name,
      week_day: [form?.week],
      status : form?.status,
      dist_id: form?.dist_id
    }

    console.log(param2,"updateRouteDetails")
      const response = await UpdateBeat(param2);
      if (response.code === 200) {
        await getAllBeatList(param);
        document
        .getElementById("RouteManagementTable")
        .scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
        toast.success(response.message);
        setEdit(false);
        setForm({
          se_name: "",
          zone_name:"",
          so_name:"",
          asm_name:"",
          route_name:"",
          area:"",
          route_code:"",
          shop_name:"",
        });
        setSelectedWeekdays([]);
      } else {
        toast.error(response.message);
      }
    // }
  };

  const handleStatusChange=(e)=>{
    setForm({
      ...form,
      status:e.target.checked 
    })
  }

  const handleReset = () => {
    setSelectedValues([]);
  };
  return (
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <div id="RouteMgtDetail" className={Styles.AddRouteMgt}>
        <div className={Styles.AddRouteMgtTopWrapper}>
          <label className={Styles.AddRouteMgtTopWrapperTitle}>
            Update Beat
          </label>
        </div>

        <div className={Styles.AddRouteMgtBottomWrapper}>
          <div className={Styles.AddRouteMgtOneSection}>
            {/* Zone */}
            <div className={Styles.AddRouteMgtOneSectionlist}>
              <div className={Styles.AddRouteMgtOneSectionLabelContainer}>
                <label className={Styles.AddRouteMgtOneSectionLabel}>
                  Zone:
                </label>
              </div>
              <div className={Styles.AddRouteMgtOneSectionTextFieldContainer}>
                <TextField
                  disabled={true}
                  variant="outlined"
                  size="small"
                  sx={{ width: "100%" }}
                  value={form?.zone_name}
                />
              </div>
            </div>

            {/* Route Code */}
            <div className={Styles.AddRouteMgtOneSectionlist}>
              <div className={Styles.AddRouteMgtOneSectionLabelContainer}>
                <label className={Styles.AddRouteMgtOneSectionLabel}>
                  Code:
                </label>
              </div>
              <div className={Styles.AddRouteMgtOneSectionTextFieldContainer}>
                <TextField
                  variant="outlined"
                  size="small"
                  disabled={true}
                  sx={{ width: "100%" }}
                  name="route_code"
                  value={form?.route_code}
                  // onChange={handleChange}
                />
                {formError.wderror && (
                  <span className={Styles.error}>{formError.wderror}</span>
                )}
              </div>
            </div>

            {/* Employee Area Name */}
            <div className={Styles.AddRouteMgtOneSectionlist}>
              <div className={Styles.AddRouteMgtOneSectionLabelContainer}>
                <label className={Styles.AddRouteMgtOneSectionLabel}>
                  Employee Area Name:
                </label>
              </div>
              <div className={Styles.AddRouteMgtOneSectionTextFieldContainer}>
                <TextField
                  variant="outlined"
                  size="small"
                  sx={{ width: "100%" }}
                  disabled={true}
                  name="route_area_name"
                  value={form?.area}
                  // onChange={handleChange}
                />
                {formError.wderror && (
                  <span className={Styles.error}>{formError.wderror}</span>
                )}
              </div>
            </div>

            {/* Beat Name */}
            <div className={Styles.AddRouteMgtOneSectionlist}>
              <div className={Styles.AddRouteMgtOneSectionLabelContainer}>
                <label className={Styles.AddRouteMgtOneSectionLabel}>
                  Beat Name:
                </label>
              </div>
              <div className={Styles.AddRouteMgtOneSectionTextFieldContainer}>
                <TextField
                  variant="outlined"
                  size="small"
                  sx={{ width: "100%" }}
                  name="route_name"
                  value={form?.route_name}
                  onChange={handleChange}
                />
                {formError.wderror && (
                  <span className={Styles.error}>{formError.wderror}</span>
                )}
              </div>
            </div>

              {/* Distributor Firm Name */}
              <div className={Styles.AddRouteMgtOneSectionlist}>
              <div className={Styles.AddRouteMgtOneSectionLabelContainer}>
                <label className={Styles.AddRouteMgtOneSectionLabel}>
                  Distributor Firm Name:
                </label>
              </div>
              <div className={Styles.AddRouteMgtOneSectionTextFieldContainer}>
                <FormControl
                  sx={{ m: 1, minWidth: 280, maxWidth: 280 }}
                  size="small"
                >
                  <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    name="shop_name"
                    value={form?.dist_firm_name}
                    label="Select Distributor Firm Name"
                    onChange={handleChange}
                    displayEmpty
                  >
                    <MenuItem value="" disabled>
                      Select Distributor Firm Name
                    </MenuItem>
                    {distributorList &&
                      distributorList
                        .map((dist) => (
                          <MenuItem key={dist.dist_id} value={dist.shop_name}>
                            {dist.shop_name}
                          </MenuItem>
                        ))}
                  </Select>
                </FormControl>
                {formError.wderror && (
                  <span className={Styles.error}>{formError.wderror}</span>
                )}
              </div>
            </div>

            <div className={Styles.AddRouteMgtOneSectionlist}>
              <div className={Styles.AddRouteMgtOneSectionLabelContainer}>
                <label className={Styles.AddRouteMgtOneSectionLabel}>
                  Status:
                </label>
              </div>
              <div className={Styles.AddRouteMgtOneSectionTextFieldContainerl}>
                <IOSSwitch
                  sx={{ m: 1 }}
                  checked={form?.status}
                  onChange={handleStatusChange}
                />
              </div>
            </div>
          </div>

          {/* <div className={Styles.AddRouteMgtOneSection}>
            <div className={Styles.AddRouteMgtOneSectionlist}>
              <div className={Styles.AddRouteMgtOneSectionLabelContainer}>
                <label className={Styles.AddRouteMgtOneSectionLabel}>
                  Beat:
                </label>
              </div>
              <div className={Styles.AddRouteMgtOneSectionTextFieldContainer}>
                <TextField
                  variant="outlined"
                  size="small"
                  sx={{ width: "100%" }}
                  name="route_desc"
                  value={form.route_desc}
                  onChange={handleChange}
                />
                {formError.routedescription && (
                  <span className={Styles.error}>
                    {formError.routedescription}
                  </span>
                )}
              </div>
            </div>


          </div> */}
        </div>

        <FormGroup sx={{ alignItems: "flex-start", marginTop: "1rem" }}>
          <Stack
            sx={{ flexDirection: "row", alignItems: "center", gap: "14px" }}
          >
            <label className={Styles.AddRouteMgtOneSectionLabel}>Days:</label>
            <Stack sx={{ flexDirection: "row" }}>
              {weekdays.map((day) => (
                <FormControlLabel
                  key={day}
                  control={
                    <Radio
                      name={day}
                      // disabled={true}
                      checked={selectedWeekdays?.includes(day)}
                      onChange={handleCheckboxChange}
                    />
                  }
                  label={day.charAt(0).toUpperCase() + day.slice(1)}
                />
              ))}
            </Stack>
          </Stack>
        </FormGroup>

        <div className={Styles.ButtonContainer}>
          <RedButton
            variant="contained"
            // sx={{
            //   background: "red !important",
            //   border: "none !important",
            //   "&:hover": {
            //     background: "red !important",
            //     border: "none !important",
            //   },
            // }}
            onClick={handleSubmit}
          >
            Update
          </RedButton>
          {/* <GrayButton variant="contained" onClick={handleReset}>
                    Reset
                  </GrayButton> */}
          <GrayButton
            variant="contained"
            sx={{
              background: "white !important",
              color: "red !important",
              "&:hover": {
                background: "white !important",
                color: "red !important",
                border: "2px solid red !important",
              },
              border: "2px solid red !important",
            }}
            onClick={handleClose}
          >
            Cancel
          </GrayButton>
        </div>
      </div>
    </>
  );
};

export default UpdateRouteMgt;
