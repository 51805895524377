import * as React from "react";
import { useSelector } from "react-redux";
import { PieChart, pieArcLabelClasses } from "@mui/x-charts/PieChart";
import { Typography, Box } from "@mui/material";

export default function PieChartOrder() {
  const SROrderChart = useSelector((state) => state.sr.SROrderChartDetails);
  return (
    <Box>
      <PieChart
        series={[
          {
            data: SROrderChart
              ? SROrderChart?.map(
                  (item) =>
                    item?.order_details &&
                    item?.order_details.map((sr, index) => ({
                      id: index,
                      value: sr?.order_count,
                      label: `${sr?.first_name?.substring(0, 12)}...`,
                    }))
                ).flat()
              : [],
          },
        ]}
        sx={{
          [`& .${pieArcLabelClasses.root}`]: {
            fill: "white",
            fontSize: 14,
          },
        }}
        width={400}
        height={250}
        slotProps={{
          legend: {
            direction: "column",
            position: { vertical: "middle", horizontal: "right" },
            padding: 0,
            hidden:true,
          },
        }}
      />
      <Typography
        sx={{
          marginLeft: "5.5rem",
          marginTop: "0.5rem",
          color: "#000000",
          fontWeight: 500,
          fontSize: "22px",
        }}
      >
        No of Orders
      </Typography>
    </Box>
  );
}
