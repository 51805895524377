import React from "react";
import useAxiosPrivate from "./useAxiosPrivate";
import { useDispatch,useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
const useUpdateRetailer = (form) => {
  
  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();
  const retailerData=useSelector((store)=>store.retailer.retailerUserDetails)
  const updateRetailerData = async () => {
   
    let param = {
      first_name:form?.first_name,
      last_name:form?.last_name,
      address:form?.address,
      status:form?.status==="Inactive"?false:true??form?.categoryRowData?.status,
      retailer_id: retailerData?.retailer_id,
      whatsapp_no:form?.whatsapp_no,
      credit_limit:form?.credit_limit,
      credit_days:form?.credit_days

    };
    
    const response = await Axios.post("update_retailer", param);
    const data = await response.data;
    
    console.log(data,"parameter")
    if (data.code === 200) {
      toast.success("Updated SuccessFully");
    } else {
      toast.error("Update Failed");
    }

    console.log(data);
  };
  // useEffect(() => {
  //   getAllUserList();
  // }, []);
  return updateRetailerData;
};

export default useUpdateRetailer;
