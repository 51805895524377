import React from "react";
import useAxiosPrivate from "./useAxiosPrivate";

import { ToastContainer, toast } from "react-toastify";

const useAddTarget = () => {
  const Axios = useAxiosPrivate();
  const addTarget = async (param) => {

    const response = await Axios.post("/set_user_target", param);
    const data = await response.data;
    if (data.code === 200) {
      toast.success("Added SuccessFully");
    } else if (data.code === 400) {
      toast.error(data?.message);
    } else {
      toast.error("Failed to Add");
    }
  };
  return addTarget;
};

export default useAddTarget;
