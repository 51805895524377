import React, { useState, useEffect } from "react";
import {
  GoogleMap,
  LoadScript,
  Marker,
  InfoWindow,
  Polygon,
  Polyline,
} from "@react-google-maps/api";
import "./assests/mapStyle.css";
import { useDispatch, useSelector } from "react-redux";
import motorCycles from "./assests/motorcycle.png";
import redMarker from "../Dashboard/assets/markersImg/red.png";
import RED from "../Dashboard/assets/routeMarker/red.png";
import BLUE from "../Dashboard/assets/routeMarker/blue.png";
import CYAN from "../Dashboard/assets/routeMarker/cyan.png";
import GREEN from "../Dashboard/assets/routeMarker/green.png";
import ORANGE from "../Dashboard/assets/routeMarker/orange.png";
import PINK from "../Dashboard/assets/routeMarker/pink.png";
import GRAY from "./assests/gray_marker.png";
import HomeIcon from "../Dashboard/assets/markersImg/house.png";
import infoWindowMarker from "./assests/info_window_marker.png";

import getSelecetedSFdata, {
  setSelectedPincode,
} from "../../slice/dashboardSlice";
import useGetSelectedSFOrders from "../../hooks/useGetSelectedSFOrders";
const DashboardMapView = () => {
  const dispatch = useDispatch();
  const zoneData = useSelector((state) => state.app.curretDropdownData);
  const zoneList = useSelector((state) => state.app.zoneList);
  const mapData = useSelector((state) => state.dashboard.dashboardMapData);
  const adminMapData = useSelector((state) => state.adminMap.adminMapDetails);
  const selectedSFdata = useSelector((state) => state.dashboard.selectedSFdata);
  const [routeData, setRouteData] = useState([]);
  useEffect(() =>{
    const newrouteData = selectedSFdata?.route_cord || [];
    setRouteData(newrouteData);
  }, [selectedSFdata]);
  // console.log(routeData, "routeData");
  const [center, setCenter] = useState({
    lat: 20.593684,
    lng: 78.96288,
  });
  const [zoomLevel, setZoomLevel] = useState(12);
  const [map, setMap] = useState(null);
  const handleMapLoad = (map) => {
    setMap(map);
  };
  const [selectedPolygon, setSelectedPolygon] = useState(null);
  const markerIconsByDay = {
    monday: RED,
    tuesday: GREEN,
    wednesday: BLUE,
    thursday: ORANGE,
    friday: CYAN,
    saturday: PINK,
  };

  const daysOfWeekColors = {
    monday: "#FF0000",
    tuesday: "#00FF00",
    wednesday: "#0000FF",
    thursday: "#ff8c00",
    friday: "#17EAE3",
    saturday: "#C139C3",
  };

  useEffect(() => {
    if (zoneList) {
      const selectedZone = zoneList?.find(
        (zone) => zone.zone_id === zoneData?.zone_id
      );

      // If a matching zone is found, update the centroid state
      if (selectedZone) {
        const [lat, lng] = selectedZone.centroid.split(",").map(Number);
        setCenter({ lat, lng });
      } else {
        // Handle the case where no matching zone is found, if needed
        setCenter(null);
      }
    }
  }, [zoneData, zoneList]);

  useEffect(() => {
    if (map && selectedSFdata?.route_cord?.length > 0) {
      const bounds = new window.google.maps.LatLngBounds();
      let validCoordsCount = 0;

      selectedSFdata?.route_cord?.forEach((coord) => {
        if (coord.lat !== null && coord.lng !== null) {
          bounds.extend(new window.google.maps.LatLng(coord.lat, coord.lng));
          validCoordsCount++;
        }
      });
      if (validCoordsCount > 0) {
        map.fitBounds(bounds);
      }
    }
  }, [map, selectedSFdata]);

  const handleZoomChanged = (map) => {
    // Get the current zoom level
    const currentZoom = map?.getZoom();

    // Update the state with the new zoom level
    setZoomLevel(currentZoom);
  };

  const containerStyle = {
    width: "100%",
    height: "calc(100vh - 60px)",
    borderRadius: "10px",
    border: "1px solid black",
  };

  const getSelectedSForders = useGetSelectedSFOrders();
  const handlePolygonClick = async (
    pincode,
    shapeId,
    coordinates,
    pincodeDetails
  ) => {
    setSelectedPolygon(pincode);
    dispatch(setSelectedPincode(shapeId));
    const param = {
      shape_id: shapeId,
    };
    const data = await getSelectedSForders(param);
    //  dispatch(getSelecetedSFdata(data));
  };
  const polygonData = adminMapData?.pincodes?.map((location) =>
    location.coordinates.map((coord) => ({
      lat: coord[1],
      lng: coord[0],
    }))
  );
  return (
    <LoadScript googleMapsApiKey={process.env.REACT_APP_MAP_KEY}>
      <GoogleMap
        onZoomChanged={() => handleZoomChanged(map)}
        onLoad={handleMapLoad}
        mapContainerStyle={containerStyle}
        center={center}
        zoom={12}
        clickableIcons={false}
        options={{
          styles: [
            {
              featureType: "poi",
              elementType: "labels",
              stylers: [{ visibility: "off" }],
            },
          ],
        }}
      >
        {polygonData?.map((coordinates, index) => (
          <Polygon
            key={index}
            paths={coordinates}
            options={{
              fillColor: "#ccdbcf",
              fillOpacity: 0.4,
              strokeColor:
                selectedPolygon === adminMapData?.pincodes[index]?.pin_code
                  ? "#000"
                  : "#3483eb",
              strokeOpacity: 1,
              strokeWeight:
                selectedPolygon === adminMapData?.pincodes[index]?.pin_code
                  ? 4
                  : 2,
              clickable: false,
            }}
            // onClick={() =>
            //   handlePolygonClick(
            //     adminMapData?.pincodes[index]?.pin_code,
            //     adminMapData?.pincodes[index]?.shape_id,
            //     coordinates,
            //     mapData.sf_details[index]
            //   )
            // }
          />
        ))}
        {/* {zoomLevel > 12 ? (
          <>
            {mapData?.sf_details?.map((data, index) => (
              <Marker
                icon={{
                  url: infoWindowMarker,
                }}
                key={index}
                position={{
                  lat: parseFloat(data.lat),
                  lng: parseFloat(data.lng),
                }}
                // onClick={() => setSelectedMarker(data)}
              />
            ))}

            {mapData?.sf_details?.map((data, index) => (
              <InfoWindow
                key={index}
                position={{
                  lat: parseFloat(data.lat),
                  lng: parseFloat(data.lng),
                }}
                // onCloseClick={() => setSelectedMarker(null)}
                options={{
                  disableAutoPan: true,
                }}
              >
                <div className="info-container">
                  <div className="marker-label">
                    <div
                      style={{
                        fontWeight: "400",
                        color: "#000",
                        fontSize: "12px",
                      }}
                    >
                      {data?.assignedSR[0]?.first_name}
                    </div>
                    <div
                      style={{
                        fontWeight: "400",
                        color: "#626262",
                        fontSize: "12px",
                      }}
                    >
                      {data?.retailers_count} R |{" "}
                      {data?.active_time_details[0]?.time_spent?.hours} hrs | ₹{" "}
                      {data?.total_sales}
                    </div>
                  </div>
                </div>
              </InfoWindow>
            ))}
          </>
        ) : null} */}

        {adminMapData?.retailers_list?.map((retailer, index) => (
          <Marker
            key={index}
            zIndex={2}
            position={{
              lat: parseFloat(retailer.lat),
              lng: parseFloat(retailer.lng),
            }}
            icon={GRAY}
          />
        ))}
         {routeData?.map((coordinate, index) => (
          <React.Fragment key={index}>
            {index < routeData.length - 1 && (
              <React.Fragment>
                <Polyline
                  path={[
                    {
                      lat: parseFloat(coordinate.lat),
                      lng: parseFloat(coordinate.lng),
                    },
                    {
                      lat: parseFloat(routeData[index + 1].lat),
                      lng: parseFloat(routeData[index + 1].lng),
                    },
                  ]}
                  options={{
                    zIndex:100,
                    strokeWeight: 3,
                    strokeColor: coordinate.completed
                      ? "#009C19"
                      : "#E40303",
                    icons: [
                      {
                        icon: {
                          path: "M 0,-1 0,1",
                          scale: 2,
                        },
                        offset: "0",
                        repeat: "20px",
                      },
                    ],
                  }}
                />
                {coordinate.completed &&
                  !routeData[index + 1].completed && (
                    <Marker
                      position={{
                        lat:
                          (parseFloat(coordinate.lat) +
                            parseFloat(routeData[index + 1].lat)) / 2,
                        lng:
                          (parseFloat(coordinate.lng) +
                            parseFloat(routeData[index + 1].lng)) / 2,
                      }}
                      icon={motorCycles}
                    />
                  )}
              </React.Fragment>
            )}
            
              <Marker
                position={{
                  lat: parseFloat(coordinate.lat),
                  lng: parseFloat(coordinate.lng),
                }}
                zIndex={100}
                icon={coordinate.completed ? GREEN : RED}
              />
          </React.Fragment>
        ))}
        {/* <div>
          {mapData?.route_list?.map((route, routeIndex) => (
            <React.Fragment key={routeIndex}>
              {route.route_cord?.length > 0 && (
                <React.Fragment>
                  <Polyline
                    path={[
                      {
                        lat: parseFloat(route.se_home_lat),
                        lng: parseFloat(route.se_home_lng),
                      },
                      ...route.route_cord?.map((coordinate) => ({
                        lat: parseFloat(coordinate.lat),
                        lng: parseFloat(coordinate.lng),
                      })),
                    ]}
                    options={{
                      strokeWeight: 2,
                      strokeColor: route.route_cord?.every(
                        (coord) => coord.completed
                      )
                        ? "#009C19"
                        : "#E40303", // Use modulo to cycle through colors
                      icons: [
                        {
                          icon: {
                            path: "M 0,-1 0,1",
                            scale: 2,
                          },
                          offset: "0",
                          repeat: "20px",
                        },
                      ],
                    }}
                  />
                  <Marker
                    position={{
                      lat: parseFloat(route.se_home_lat),
                      lng: parseFloat(route.se_home_lng),
                    }}
                    label={"S"} // You can customize the label as needed
                    icon={HomeIcon}
                  />
                </React.Fragment>
              )}
              {mapData?.route_list?.map((route, routeIndex) => (
                <React.Fragment key={routeIndex}>
                  {route.route_cord?.length > 0 && (
                    <React.Fragment>
                      <Polyline
                        path={[
                          {
                            lat: parseFloat(route.se_home_lat),
                            lng: parseFloat(route.se_home_lng),
                          },
                          {
                            lat: parseFloat(route.route_cord[0].lat),
                            lng: parseFloat(route.route_cord[0].lng),
                          },
                        ]}
                        options={{
                          strokeWeight: 2,
                          strokeColor: route.route_cord[0].completed
                            ? "#009C19"
                            : "#E40303",
                          icons: [
                            {
                              icon: {
                                path: "M 0,-1 0,1",
                                scale: 2,
                              },
                              offset: "0",
                              repeat: "20px",
                            },
                          ],
                        }}
                      />
                      <Marker
                        position={{
                          lat: parseFloat(route.se_home_lat),
                          lng: parseFloat(route.se_home_lng),
                        }}
                        label={"S"}
                        icon={HomeIcon}
                      />
                    </React.Fragment>
                  )}
                  {route.route_cord?.map((coordinate, index) => (
                    <React.Fragment key={index}>
                      {index < route.route_cord.length - 1 && (
                        <React.Fragment>
                          <Polyline
                            path={[
                              {
                                lat: parseFloat(coordinate.lat),
                                lng: parseFloat(coordinate.lng),
                              },
                              {
                                lat: parseFloat(
                                  route.route_cord[index + 1].lat
                                ),
                                lng: parseFloat(
                                  route.route_cord[index + 1].lng
                                ),
                              },
                            ]}
                            options={{
                              strokeWeight: 3,
                              strokeColor: coordinate.completed
                                ? "#009C19"
                                : "#E40303",
                              icons: [
                                {
                                  icon: {
                                    path: "M 0,-1 0,1",
                                    scale: 2,
                                  },
                                  offset: "0",
                                  repeat: "20px",
                                },
                              ],
                            }}
                          />
                          {index < route.route_cord.length - 1 &&
                            coordinate.completed &&
                            !route.route_cord[index + 1].completed && (
                              <Marker
                                position={{
                                  lat:
                                    (parseFloat(coordinate.lat) +
                                      parseFloat(
                                        route.route_cord[index + 1].lat
                                      )) /
                                    2,
                                  lng:
                                    (parseFloat(coordinate.lng) +
                                      parseFloat(
                                        route.route_cord[index + 1].lng
                                      )) /
                                    2,
                                }}
                                icon={motorCycles}
                              />
                            )}
                        </React.Fragment>
                      )}
                      <Marker
                        position={{
                          lat: parseFloat(coordinate.lat),
                          lng: parseFloat(coordinate.lng),
                        }}
                        icon={coordinate.completed ? GREEN : RED}
                      />
                    </React.Fragment>
                  ))}
                </React.Fragment>
              ))}
            </React.Fragment>
          ))}
        </div> */}
      </GoogleMap>
    </LoadScript>
  );
};

export default DashboardMapView;
