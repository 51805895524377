import React, { useState } from 'react'
import Styles from '../styles/AddVillage.module.css';
import { Button, TextField } from '@mui/material';
import { styled } from '@mui/material/styles'
import CloseIcon from '@mui/icons-material/Close';
// import { AddVillageMgt, BulkVillageUpload } from '../../../../actions/Route/RouteMgt'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux';

const RedButton = styled(Button)(({ theme }) => ({
    color: "white",
    backgroundColor: "#731D3A",
    border: "2px solid #731D3A",
    '&:hover': {
        backgroundColor: "#731D3A",
        color: "white",
        border: "2px solid #731D3A"
    },
}));

const RedButtonUpload = styled(Button)(({ theme }) => ({

    height: "35px",
    color: "white",
    backgroundColor: "#731D3A",
    border: "2px solid #731D3A",
    '&:hover': {
        backgroundColor: "#731D3A",
        color: "white",
        border: "2px solid #731D3A"
    },
}));


const OutLineButton = styled(Button)(({ theme }) => ({
    color: "#731D3A",
    backgroundColor: "white",
    border: "2px solid #731D3A",
    '&:hover': {
        backgroundColor: "white",
        color: "#731D3A",
        border: "2px solid #731D3A"
    },
}));

const AddVillage = ({ rootzone, Statevalue }) => {
    const Firsttoken = useSelector(state => state.Token);
    const dispatch = useDispatch();
    const [file, setFile] = useState('');
    const [village, setVillage] = useState('');
    const [addSuccess, setAddsuccess] = useState(false);
    const [addFailed, setAddFailed] = useState({
        failed: false,
        message: ''
    })
    const [population, setPopulation] = useState(0);
    const [lati,setLati] = useState(0);
    const [longi,setLongi] = useState(0);
    const [BulkaddSuccess, setBulkAddsuccess] = useState(false);
    const [BulkaddFailed, setBulkAddFailed] = useState({
        failed: false,
        message: ''
    })


    const handleChange = (e) => {
        setVillage(e.target.value)
    }

    const onFileSelect = (e) => {
        let xl = e.target.files[0];
        setFile(xl)
    }

    const handleRemoveFile = () => {
        setFile('')
    }

    const IsValid = () => {
        let success = true;

        if (!village.trim()) {
            success = false;
            toast.error('village cannot be empty')
        }

        if (!population.toString().trim()) {
            success = false;
            toast.error('Population cannot be empty')
        }

        if (!lati.toString().trim()) {
            success = false;
            toast.error('Latitude cannot be empty')
        }

        if (!longi.toString().trim()) {
            success = false;
            toast.error('Longitude cannot be empty')
        }

      

        if (parseInt(population)<0) {
            success = false;
            toast.error('Population cannot be Negative')
        }

        if (parseFloat(lati)<=0) {
            success = false;
            toast.error('Latitude cannot be Negative OR zero!! Please Enter a valid One')
        }

        if (parseFloat(longi)<=0) {
            success = false;
            toast.error('Longitude cannot be Negative OR zero!! Please Enter a valid One')
        }

    

        return success
    }


    const handleAdd = () => {
        if (IsValid()) { 
            if (Firsttoken && Firsttoken.token && Firsttoken.token !== null && Firsttoken.token.length !== 0 && Firsttoken !== "undefined") {
                let param = {
                    "village_name": `${village}`,
                    "zone_name": `${rootzone}`,
                    "population" : `${population}`,
                    "lati":lati,
                    "longi":longi
                }
                // dispatch(AddVillageMgt(Firsttoken.token, param, setAddsuccess, addFailed, setAddFailed))

            }
        }
    }

    React.useEffect(() => {
        if (addSuccess) {
            toast.success("Added Village SuccessFully");
            setAddsuccess(false);
            setVillage('');
            setPopulation(0);
            setLati('');
            setLongi('');
        }
    }, [addSuccess])

    React.useEffect(() => {
        if (addFailed.failed) {
            toast.error(`${addFailed.message}`);
            setAddFailed({
                ...addFailed,
                failed: false,
                message: ''

            })

        }
    }, [addFailed])


    React.useEffect(() => {
        if (BulkaddSuccess) {
            toast.success("Bulk Upload SuccessFully");
            setBulkAddsuccess(false);
            setFile('')
        }
    }, [BulkaddSuccess])

    React.useEffect(() => {
        if (BulkaddFailed.failed) {
            toast.error(`${BulkaddFailed.message}`);
            setBulkAddFailed({
                ...addFailed,
                failed: false,
                message: ''

            })

        }
    }, [BulkaddFailed])

    const handlePopulation = (e) => {
        setPopulation(e.target.value)
    }


    const handleBulkUpload = () => {
        if (Firsttoken && Firsttoken.token && Firsttoken.token !== null && Firsttoken.token.length !== 0 && Firsttoken !== "undefined") {
            const formData = new FormData();
            formData.append('file', file);
            // dispatch(BulkVillageUpload(Firsttoken.token, formData, setBulkAddsuccess, BulkaddFailed, setBulkAddFailed, rootzone));

        }
    }

   
    return (
        <>
            <ToastContainer
                position="bottom-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />



            <div className={Styles.Container}>


                <div className={Styles.RootContainer}>
                    <div className={Styles.TopContainer}>
                        <div className={Styles.List}>
                            <div className={Styles.TitleContainer}>
                                <label className={Styles.TopLabel}>State:</label>
                            </div>
                            <div className={Styles.TextContainer}>
                                <TextField variant="outlined" size="small" value={Statevalue} disabled={true} />
                            </div>

                        </div>

                        <div className={Styles.List}>
                            <div className={Styles.TitleContainer}>
                                <label className={Styles.TopLabel}>Zone:</label>
                            </div>
                            <div className={Styles.TextContainer}>
                                <TextField variant="outlined" size="small" value={rootzone} disabled={true} />
                            </div>

                        </div>

                        <div className={Styles.List}>
                            <div className={Styles.TitleContainer}>
                                <label className={Styles.TopLabel}>Village:</label>
                            </div>
                            <div className={Styles.TextContainer}>
                                <TextField variant="outlined" size="small" name="village" value={village} onChange={handleChange} />
                            </div>

                        </div>

                        <div className={Styles.List}>
                            <div className={Styles.TitleContainer}>
                                <label className={Styles.TopLabel}>Population:</label>
                            </div>
                            <div className={Styles.TextContainer}>
                                <TextField variant="outlined" size="small" type="number" name="population" value={population} onChange={handlePopulation} />
                            </div>

                        </div>

                        <div className={Styles.List}>
                            <div className={Styles.TitleContainer}>
                                <label className={Styles.TopLabel}>Latitude:</label>
                            </div>
                            <div className={Styles.TextContainer}>
                                <TextField variant="outlined" size="small" type="text" value={lati}  onChange={(e)=>setLati(e.target.value)}/>
                            </div>

                        </div>

                        <div className={Styles.List}>
                            <div className={Styles.TitleContainer}>
                                <label className={Styles.TopLabel}>Longitude:</label>
                            </div>
                            <div className={Styles.TextContainer}>
                                <TextField variant="outlined" size="small" type="text" value={longi}  onChange={(e)=>setLongi(e.target.value)}/>
                            </div>

                        </div>



                    </div>
                    <div className={Styles.AddList}>
                        <RedButton onClick={handleAdd}>Add</RedButton>
                    </div >

                </div>

                <div className={Styles.BulkList}>
                    <div className={Styles.BulkSubList}>
                        <div>
                            <label className={Styles.BulkTitle}>BulkUpload:</label>
                        </div>
                        {
                            file && file.length !== 0 ? (
                                <div className={Styles.fileContainer}>
                                    <label>{file.name}</label>
                                    <button className={Styles.fileButton} onClick={handleRemoveFile}><CloseIcon className={Styles.fileButtonIcon} /></button>
                                </div>

                            ) :
                                (
                                    <div>
                                        <input type="file" id="ADDVILLAGEBULK" accept=".csv" className={Styles.BulkInput} onChange={onFileSelect} />
                                        <label htmlFor='ADDVILLAGEBULK' className={Styles.BulkUploadButton}>Select File</label>

                                    </div>
                                )
                        }

                    </div>
                    {
                        file && file.length !== 0 ? (
                            <div className={Styles.UploadContainer}>
                                <RedButtonUpload onClick={handleBulkUpload}>Upload</RedButtonUpload>
                            </div>
                        )
                            :
                            (
                                <div className={Styles.UploadContainer}>
                                    <Button disabled={true} variant="contained">Upload</Button>
                                </div>
                            )
                    }
                </div>

            </div>



        </>
    )
}

export default AddVillage


